import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as BuzzItStore from "../../../store/games/BuzzItStore";
import * as GamesStore from "../../../store/games/Games";
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {SelectList, Slider, SubmitButton} from "../../forms/FormGroup";
import {AppState} from "../../../store/configureStore";

interface Props {
    gamesActions: typeof GamesStore.actionCreators;
    buzzItState: BuzzItStore.BuzzItState;
    buzzItActions: typeof BuzzItStore.actionCreators;
}

const BuzzItSettings = (props: Props) => {
    if(!props.buzzItState.ui.showSettings) return null;
    const game = props.buzzItState.game;
    if(!game) return null;
    const settings = {
        skin: game.skin || 'Default',
        readTime: game.readTime || 5,
        answerTime: game.answerTime || 15
    };

    return(
        <Dialog className='settings' onClose={() => props.gamesActions.showSettings(game.gameType, false)} loading={props.buzzItState.isLoading.updateSettings}>
            <h2><Translate id='settings'/></h2>
            <form onSubmit={e => e.preventDefault()}>
                <label><Translate id='skin'/></label>
                <SelectList name='skin' defaultValue={settings.skin} options={[{name: "Default", value: "Default"}]} onChange={props.buzzItActions.setSetting} noLabel/>
                <label><Translate id='buzz_it_read_time'/></label>
                <Slider name='readTime' min={0} max={10} value={settings.readTime} onChange={props.buzzItActions.setSetting} hoverMsg='buzz_it_read_time_hint'/>
                <label><Translate id='buzz_it_answer_time'/></label>
                <Slider name='answerTime' min={5} max={60} value={settings.answerTime} onChange={props.buzzItActions.setSetting} hoverMsg='buzz_it_answer_time_hint'/>
                <h3><Translate id='preview_skin'/></h3>
                <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
                <div className='clear-fix' />
                <SubmitButton
                    text='submit'
                    onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ buzzItState: state[BuzzItStore.reducerName] }),
    dispatch => ({
        buzzItActions: bindActionCreators(BuzzItStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(BuzzItSettings);