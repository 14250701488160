import React from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import * as GamesStore from "../../../store/games/Games";
import * as QuizNinjaStore from '../../../store/games/QuizNinjaStore';
import {bindActionCreators} from "redux";
import {SelectList, SubmitButton} from "../../forms/FormGroup";
import {Translate} from "react-localize-redux";
import {AppState} from "../../../store/configureStore";

interface Props {
    quizNinjaState: QuizNinjaStore.QuizNinjaState;
    quizNinjaActions: typeof QuizNinjaStore.actionCreators;
    gamesActions: typeof GamesStore.actionCreators;
}

const QuizNinjaSettings = (props: Props) => {
    if(!props.quizNinjaState.ui.showSettings) return null;
    const game = props.quizNinjaState.game;
    if(!game) return null;
    const settings = {
        skin: game.skin || 'Default'
    };

    const skinOptions = [
        {name: "Default",     value: "Default"    },
        {name: "Quadroworld", value: "Quadroworld"},
        {name: "Pahlawan",    value: "Pahlawan"   },
        {name: "Bullseye",    value: "Bullseye"   }
    ];

    return(
        <Dialog className='settings' onClose={() => {props.gamesActions.showSettings(game.gameType, false)}} loading={props.quizNinjaState.isLoading.updateSettings} >
            <h2><Translate id='settings'/></h2>
            <form onSubmit={e => e.preventDefault()}>
                <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.quizNinjaActions.setField}/>
                <h3><Translate id='preview_skin'/></h3>
                <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
                <div className='clear-fix' />
                <SubmitButton
                    text='submit'
                    onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ quizNinjaState: state.quizninja }),
    dispatch => ({
        quizNinjaActions: bindActionCreators(QuizNinjaStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(QuizNinjaSettings);