import React, { ReactNode } from "react";
import { Translate } from "react-localize-redux";
import { FetchError } from "../../services/FetchHelper";
import { FormGeneralError } from "../Error/FormGeneralError";
import { Loading } from "../Loading";

interface Props<T>{
    loading: boolean;
    items: T[];
    heading: ReactNode; 
    itemRender: (t: T) => ReactNode;
    className?: string;
    error?: FetchError;
}

const ItemContainer = <T extends unknown>(props: Props<T>) => {

    const {loading, items, heading, itemRender, className, error} = props;

    return(
        <div className={`item-container${className ? ` ${className}` : ''}`}>
            <div className='item-container-heading'>{heading}</div>
            <div className='list'>
                <Loading visible={loading} />
                {error && <FormGeneralError fetchError={error} />}
                {items && items.map(itemRender)}
                {!error && items.length === 0 && <div className='secondary'><Translate id={'empty_list'} /></div>}
            </div>
        </div>
    )
};

export default ItemContainer;