export type PermissionType = 
    | 'Admin'
    | 'Organization'
    | 'Floor'
    | 'Users'
    | 'ChildOrganizations'
    | 'Public'
    | 'Publish'
    | 'Editor'
    | 'License'
    | 'Deal';
    

export const allPermissions: PermissionType[] = ["Admin", "Organization", "Floor", "Users", "ChildOrganizations", "Editor", "Public", "Publish", "License", "Deal"];