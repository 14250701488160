import React from 'react';
import {actionCreators as pbActions, PictureBookState} from "../../../store/games/PictureBook";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Dialog} from "../../Dialog";
import {Translate} from "react-localize-redux";
import {AudioFormButton, CheckBox, ColorPicker, ImageFormButton, SubmitButton} from "../../forms/FormGroup";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import SoundForm from "../../forms/games/SoundForm";
import {PictureBookSlidePreview} from './PictureBookSlidePreview';
import {AppState} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";

interface Props {
    picturebook: PictureBookState;
    pbActions: typeof pbActions;
    errors: ErrorState['picturebook'];
}

const SlideEditForm = (props: Props) => {
    const slide = props.picturebook.slide;

    const confirmDelete = <ConfirmationForm
        text={"picturebook_slide_delete_confirm"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => {props.pbActions.deleteSlide(slide)}}
        onCancel={() => props.pbActions.showConfirmDelete(false)}/>;

    const submitFunc = () => {
        if (slide.new){
            props.pbActions.createSlide(slide);
        }
        else{
            props.pbActions.updateSlide(slide);
        }

    };

    let validForm = true;
    if (slide.image == null){
        validForm = false;
    }
    
    const soundForm = (
        <Dialog className='picturebook-slide-edit-dialog' onClose={() => {props.pbActions.onRequestSound(false)}}>
            <SoundForm onSubmit={(sound) => {props.pbActions.setSound(sound); props.pbActions.onRequestSound(false);}}/>
        </Dialog>
    );

    const mainForm = (
        <Dialog
            loading={props.picturebook.isLoading.createSlide || props.picturebook.isLoading.deleteSlide || props.picturebook.isLoading.updateSlide}
            className='picturebook-slide-edit-dialog'
            onClose={() => props.pbActions.showSlideEdit(false)}
            icon={slide.new ? undefined : 'trash'}
            onIconClick={() => props.pbActions.showConfirmDelete(true)} >
            {props.picturebook.ui.showConfirmDeleteSlide && confirmDelete}
            <h1><Translate id='picturebook_slide' /></h1>
            <form className='picturebook-slide-form' onSubmit={(e) => e.preventDefault()}>
                <ImageFormButton
                    name={'image'}
                    imageSubmit={props.pbActions.setImage}
                    previewWidth={640}
                    previewHeight={400}
                    unsavedChange={slide.image !== slide._o.image}
                />
                <AudioFormButton
                    name={'sound'}
                    audioSubmit={(sound) => {props.pbActions.setSound(sound); props.pbActions.onRequestSound(false);}}
                    unsavedChange={slide.sound !== slide._o.sound}
                />
                <CheckBox
                    name='autoplay'
                    active={slide.autoPlay}
                    onChange={e => {props.pbActions.setAutoPlay(e)}}
                    original={slide._o.autoPlay}
                />
                <CheckBox
                    name='loopsound'
                    active={slide.loopSound}
                    onChange={e => {props.pbActions.setLoopSound(e)}}
                    original={slide._o.loopSound}
                />
                <ColorPicker
                    colorValue={slide.backgroundColor}
                    onColorChange={(e) => {props.pbActions.setColor(e)}}
                    original={slide._o.backgroundColor}
                />
                <SubmitButton text={'submit'} onClick={submitFunc} split disabled={!validForm}/>
            </form>

            <div className='preview'>
                <PictureBookSlidePreview slide={slide} />
            </div>
            {(props.errors.createSlide || props.errors.updateSlide || props.errors.deleteSlide) &&
                <div className='form-error'><Translate id='picturebook_unknown_error' /></div>
            }
        </Dialog>
    );

    let content = null;
    if (props.picturebook.ui.showSoundForm){
        content = soundForm;
    } 
    else if (props.picturebook.ui.showSlideEdit) {
        content = mainForm;
    }
    

    return content;
};


export default connect(
    (state: AppState) => ({picturebook: state.picturebook, errors: state.errors.picturebook}),
    dispatch => ({ pbActions: bindActionCreators(pbActions, dispatch) })
)(SlideEditForm)