import React from 'react';
import {MemoryCard} from '../../../model/Game/Memory/MemoryCard';
import {DbOrUploadImage, DbOrUploadSound} from "../../../services/ImageHelper";
import TextView from '../../TextView';

interface Props {
    card: MemoryCard;
    className?: string;
}

export const CardPreview = (props: Props) => {

    return(
        <div className={`card-preview ${props.className || ''}`}>
            <div className={`card${!props.card.image ? ' only-text' : ''}`}>
                <DbOrUploadImage className='card-image' image={props.card.image} alt='' />
                <TextView element='div' text={props.card.text} />
            </div>
            <DbOrUploadSound audio={props.card.sound} />
        </div>
    );
};