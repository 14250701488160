import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate} from "react-localize-redux";
import {history} from "../../index";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as navActions} from "../../store/Navigation";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface Props{
    navActions: typeof navActions;
    icon: IconProp;
    path: string;
}

class NavMenuItem extends Component<Props>{

    constructor(props: Props) {
        super(props);
        history.listen(() => this.forceUpdate());
    }

    render(){
        const activeMenu = (subString: string) => {
            if(!subString){
                return history.location.pathname === "/" ? ' active' : '';
            }
            if(history.location.pathname.indexOf(subString) !== -1)
                return ' active';
            return '';
        };

        const props = this.props;
        return(
            <div className={`nav-item ${props.path}${activeMenu(props.path)}`}>
                <Link to={`/${props.path}`} onClick={() => props.navActions.showMainNav(false)}>
                    <FontAwesomeIcon icon={props.icon} className='nav-icon'/> <Translate id={`menu_${props.path ? props.path : 'mywall'}`}/>
                </Link>
            </div>
        )
    }
}

export default connect(
    () => ({}),
    dispatch => ({ navActions : bindActionCreators(navActions, dispatch)})
)(NavMenuItem);