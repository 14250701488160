import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import {Loading} from "../Loading";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {AccountState, actionCreators as accountActions} from "../../store/Account";
import * as GameStore from '../../store/games/Games';
import {GamesState, isTempGame} from '../../store/games/Games';
import * as LibraryStore from '../../store/LibraryStore';
import {LibraryState} from '../../store/LibraryStore';
import * as GlobalStore from '../../store/GlobalComponentStore';
import {PlayListState} from '../../store/PlayListStore';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {AppState} from "../../store/configureStore";
import GameDetailed from "../ModelPreview/GameDetailed";

interface Props {
    accountActions: typeof accountActions;
    libraryState: LibraryState;
    gamesActions: typeof GameStore.actionCreators;
    globalActions: typeof GlobalStore.actionCreators;
    gamesState: GamesState;
    closeFunction: () => void;
    accountState: AccountState;
    playlist: PlayListState;
    scrollToOnMount?: string;
}

class GameSliderPreview extends Component<Props>{

    constructor(props: Props) {
        super(props);
        this.state = {
            playlistChooserGame: null,
        }
    }


    componentDidMount(){
        this.fetchGame();
        if(this.props.scrollToOnMount){
            const element = document.getElementById(this.props.scrollToOnMount);
            element && element.scrollIntoView({behavior: "smooth", block: "center"});
        }

    }

    componentDidUpdate(){
        this.fetchGame();
    }

    fetchGame = () => {
        const gameId = this.props.libraryState.gamePreview && this.props.libraryState.gamePreview.id;
        if(!gameId) return;

        const game = this.props.gamesState.games[gameId];
        if(!game){
            this.props.gamesActions.getGame(gameId, false);
        }

        if(!isTempGame(game)){
            if(game.ownerId){
                this.props.accountActions.get(game.ownerId, false);
            }
            if(game.authorIds){
                game.authorIds.forEach(x => this.props.accountActions.get(x, false));
            }
        }
    };

    displayLoading = () => {
        return(
            <div className='slider-item-preview'>
                <div className='game-detailed-preview'>
                    <Loading visible={true}/>
                </div>
            </div>
        );
    };

    render(){
        const props = this.props;
        const game = props.libraryState.gamePreview;
        if(!game) return null;
        if(isTempGame(game)) return this.displayLoading();

        return(
            <div className='slider-item-preview'>
                <span className='exit-icon' onClick={this.props.closeFunction}>
                    <FontAwesomeIcon icon='times' />
                </span>

                <GameDetailed
                    game={game}
                    onClick={() => {}}
                    gameType={game.gameType}
                />

                <div className='clear-fix'/>
                <div className='btn' onClick={() => props.globalActions.playlistAddItemForm(game)}><Translate id='playlist_add_to' /></div>
                <Link to={`/workshop/${game.gameType}/edit/${game.id}`} onClick={e => e.stopPropagation()}>
                    <div className='btn'>
                        <span>
                            <Translate id='game_content'/>
                        </span>
                    </div>
                </Link>
            </div>
        )
    }
}

export default connect(
    (state: AppState) => ({
        accountState: state.account,
        gamesState: state[GameStore.reducerName],
        libraryState: state[LibraryStore.reducerName],
        playlist: state.playlist
    }),
    dispatch => ({
        accountActions: bindActionCreators(accountActions, dispatch),
        gamesActions: bindActionCreators(GameStore.actionCreators, dispatch),
        globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
    })
)(GameSliderPreview);