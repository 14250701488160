import React, {useEffect, useState} from 'react';
import {Translate} from 'react-localize-redux';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {AccountState, actionCreators as accountActions} from "../../store/Account";
import {AppState} from '../../store/configureStore';
import Dialog from "../Dialog";
import {Loading} from "../Loading";
import Author from "../ModelPreview/Author";
import {InputField} from "./FormGroup";
import ConfirmationForm from "./games/ConfirmationForm";
import {isAccountType} from "../../model/AccountType";

interface Props {
    accountState: AccountState;
    accountActions: typeof accountActions;
    chooseFunction: (accId: string) => void;
    loading?: boolean;
    visible?: boolean;
    onClose: () => void;
    ownerId: string;
}

const TransferOwner = (props: Props) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [searchFire, setSearchFire] = useState<ReturnType<typeof setTimeout>>();
    const [confirmAccountId, setConfirmAccountId] = useState<string>();

    useEffect(() => {
        searchFire && clearTimeout(searchFire);
        searchTerm && setSearchFire(setTimeout(() => props.accountActions.setSearchString(searchTerm), 500));
        // eslint-disable-next-line
    }, [searchTerm]);

    if(!props.visible) return null;
    const owner = props.accountState.accounts[props.ownerId];
    if(!owner) return null;

    const searching = props.accountState.searchStringResult !== props.accountState.searchString;

    return(
        <Dialog onClose={props.onClose} className='author-chooser-dialog' loading={props.loading}>
            <h2><Translate id='current_owner'/></h2>
            <div className='authors'>
                {isAccountType(owner) &&
                    <Author account={owner} />
                }
                <div className='clear-fix'/>
            </div>
            <h2><Translate id='owner_choose'/></h2>
            <InputField
                name='search'
                type='text'
                placeholder='search'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                noLabel
            />

            <div className='playlist-authors relative'>
                <Loading visible={searching} />
                {props.accountState.searchResult.accounts.map(acc => {
                    return <Author
                        key={acc.id}
                        account={acc}
                        onClick={() => {setConfirmAccountId(acc.id)}}
                    />
                })}
                <div className='clear-fix'/>
            </div>
            {confirmAccountId &&
            <ConfirmationForm
                text='transfer_ownership_confirm'
                confirmText='yes'
                cancelText='no'
                onConfirm={() => {
                    confirmAccountId && props.chooseFunction(confirmAccountId);
                    setConfirmAccountId(undefined)
                }}
                onCancel={() => setConfirmAccountId(undefined)}
            />
            }
        </Dialog>
    )
}

export default connect(
    (state: AppState) => ({ accountState: state.account }),
    dispatch => ({accountActions: bindActionCreators(accountActions, dispatch)})
)(TransferOwner);