import React from 'react';
import {courseList} from "../../model/Subject";
import {Translate} from "react-localize-redux";
import {languageNames} from "../../model/GoogleTranslateList";
import {BaseGame} from "../../model/Game/BaseGame";

interface Props{
    game: BaseGame;
}

export const GameTagList = (props: Props) => {
    const game = props.game;
    return(
        <div className='taglist'>
            { game.languageCode && <div className='tag language'>{game.languageCode.replace(/^\w/, c => c.toUpperCase())}</div>}
            {
                game.courseName &&
                <div className='tag subject'>
                    { courseList.findIndex(c => game.courseName === c) !== -1 ?
                        <Translate id={game.courseName}/> :
                        languageNames[game.courseName]
                    }
                </div>
            }
            {
                !!game.maxAge &&
                <div className='tag years'>
                    {game.minAge} - {game.maxAge}<Translate id={'years_old'}/>
                </div>
            }
            {
                game.tags &&
                game.tags.split(",").map(t => {
                    if(!t) return null;
                    return <div key={`${game.id}${t}`} className='tag'>{t}</div>
                })
            }
            <div className='clear-fix'/>
        </div>
    );
}