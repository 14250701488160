import { Reducer } from "redux";
import { Bundle } from "../model/Bundle";
import { BaseGame } from "../model/Game/BaseGame";
import { PlayListType } from "../model/PlayListType";
import { TA } from "./configureStore";

export const reducerName = 'globalComponentState';

export const GLOBAL_BUNDLE_ADD_ITEM = 'GLOBAL_BUNDLE_ADD_ITEM';
export const GLOBAL_WALL_ADD_ITEM = 'GLOBAL_WALL_ADD_ITEM';
export const GLOBAL_PLAYLIST_ADD_ITEM = 'GLOBAL_PLAYLIST_ADD_ITEM';

export interface GlobalComponentState{
    showPlaylistAddItem?: BaseGame;
    showBundleAddItem?: BaseGame | PlayListType;
    showWallAddItem?: BaseGame | PlayListType | Bundle;
}

const initialState: GlobalComponentState = {}

export const actionCreators = {
    bundleAddItemForm: (item: BaseGame|PlayListType|undefined): TA => async(dispatch) => {
        dispatch({ type: GLOBAL_BUNDLE_ADD_ITEM, item});
    },
    wallAddItemForm: (item: BaseGame | PlayListType | Bundle | undefined): TA => async (dispatch) => {
        dispatch({ type: GLOBAL_WALL_ADD_ITEM, item });
    },
    playlistAddItemForm: (item: BaseGame|undefined): TA => async(dispatch) => {
        dispatch({ type: GLOBAL_PLAYLIST_ADD_ITEM, item});
    }
}

// eslint-disable-next-line
const reducerMethods: { [type: string]: (state: GlobalComponentState, action: any) => GlobalComponentState } = {
    GLOBAL_BUNDLE_ADD_ITEM: (state, action) => ({
        ...state,
        showBundleAddItem: action.item
    }),
    GLOBAL_WALL_ADD_ITEM: (state, action) => ({
        ...state,
        showWallAddItem: action.item
    }),
    GLOBAL_PLAYLIST_ADD_ITEM: (state, action) => ({
        ...state,
        showPlaylistAddItem: action.item
    })
}

// eslint-disable-next-line
export const reducer: Reducer<GlobalComponentState, any> = (state, action) => {
    state = state || initialState;
    const type = action.type;
    const method = reducerMethods[type];
    if (method) return method(state, action);
    return state;
};