import React, {Component} from "react";
import * as LibraryStore from "../../store/LibraryStore";
import {BaseGame} from "../../model/Game/BaseGame";
import Game from "../ModelPreview/Game";
import {Loading} from "../Loading";

interface Props{
    libActions: typeof LibraryStore.actionCreators;
    accId: string;
    games?: BaseGame[];
    loading?: boolean;
}

export class AccountPopularGames extends Component<Props>{

    componentDidMount(): void {
        this.props.libActions.getAccountPopularGames(this.props.accId, false);
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.accId !== this.props.accId) {
            this.props.libActions.getAccountPopularGames(this.props.accId, false);
        }
    }

    render(){
        return(
            <div className='games' >
                <Loading visible={!!this.props.loading} noBackground />
                {this.props.games && this.props.games.map(g => <Game key={g.id} game={g} />)}
            </div>
        )
    }
}