import {BikeRaceGame} from "./BikeRace/BikeRaceGame";
import {DitjGame} from "./DangerInTheJungle/DitjGame";
import {PictureBookGame} from "./PictureBook/PictureBookGame";
import {MemoryGame} from "./Memory/MemoryGame";
import {SplatterSpaceGame} from "./SplatterSpace/SplatterSpaceGame";
import {QuizNinjaGame} from "./QuizNinja/QuizNinjaGame";
import {SmackTheFlyGame} from "./SmackTheFly/SmackTheFlyGame";
import { CrazyBalloonsGame } from "./CrazyBalloons/CrazyBalloonsGame";
import { SweetyWarGame } from "./SweetyWar/SweetyWarGame";
import { SlimeBattleGame } from "./SlimeBattle/SlimeBattleGame";
import { BlockBusterGame } from "./BlockBuster/BlockBusterGame";
import { CircusRaceGame } from "./CircusRace/CircusRaceGame";
import { JigsawPuzzleGame } from "./JigsawPuzzle/JigsawPuzzleGame";
import { BuzzItGame } from "./BuzzIt/BuzzItGame";
import { WarOfStrategyGame } from "./WarOfStrategy/WarOfStrategyGame";
import { GoalShowGame } from "./GoalShow/GoalShowGame";
import { SuperSorterGame } from "./SuperSorter/SuperSorterGame";
import { Presentation } from "./Presentation/Presentation";
import { WhackAMoleGame } from "./WhackAMole/WhackAMoleGame";
import { MeditationGame } from "./Meditation/MeditationGame";
import { RaceCarGame } from "./RaceCar/RaceCarGame";
import { FeedTheFoxGame } from "./FeedTheFox/FeedTheFoxGame";
import { PizzaPartyGame } from "./PizzaParty/PizzaPartyGame";
import { PopItGame } from "./PopIt/PopItGame"; 
import { DanceASlothGame } from "./DanceASloth/DanceASlothGame";
import { MadMonstersGame } from "./MadMonsters/MadMonstersGame";
import { WordFinderGame } from "./WordFinder/WordFinderGame";


export const AllGameTypes = [
    'BuzzIt',
    'BikeRace',
    'DangerInTheJungle',
    'PictureBook',
    'PresentationGame',
    //'Combination',
    'Memory',
    'SplatterSpace',
    'QuizNinja',
    'SmackTheFly',
    //'SpinTheBottle',
    //'TvLounge',
    'WarOfStrategy',
    'CrazyBalloons',
    'SweetyWar',
    'GoalShow',
    'SuperSorter',
    'Presentation',
    'SlimeBattle',
    'BlockBuster',
    'CircusRace',
    'JigsawPuzzle',
    'BrickOut',
    'WordWizard',
    'Painter',
    'Drums',
    'Piano',
    'Geekster',
    'Ordering',
    'DragonDice', 
    'WhackAMole', 
    'Meditation',
    'RaceCar',
    'FeedTheFox',
    'PizzaParty', 
    'PopIt', 
    'DanceASloth',
    'MadMonsters',
    'WordFinder'
] as const;

export const GameTypesMyWall = [
    'BuzzIt',
    'BikeRace',
    'DangerInTheJungle',
    'PictureBook',
    //'Combination',
    'Memory',
    'SplatterSpace',
    'QuizNinja',
    'SmackTheFly',
    //'SpinTheBottle',
    //'TvLounge',
    'WarOfStrategy',
    'CrazyBalloons',
    'SweetyWar',
    'GoalShow',
    'SlimeBattle',
    'BlockBuster',
    'CircusRace',
    'JigsawPuzzle',
    'SuperSorter',
    'Presentation',
    'WhackAMole',
    'Meditation',
    'RaceCar',
    'FeedTheFox',
    'PizzaParty',
    'PopIt', 
    'DanceASloth',
    'MadMonsters', 
    'WordFinder'
] as const;

export type GameType = typeof AllGameTypes[number];

export type GameClass =
    | BuzzItGame
    | BikeRaceGame
    | DitjGame
    | PictureBookGame
   // | CombinationGame
    | MemoryGame
    | SplatterSpaceGame
    | QuizNinjaGame
    | SmackTheFlyGame
    //| SpinTheBottleGame
    //| TvLoungeGame
    | WarOfStrategyGame
    | CrazyBalloonsGame
    | SweetyWarGame
    | SlimeBattleGame
    | BlockBusterGame
    | CircusRaceGame
    | JigsawPuzzleGame
    | GoalShowGame
    | SuperSorterGame
    | Presentation
    | WhackAMoleGame
    | MeditationGame
    | RaceCarGame
    | FeedTheFoxGame
    | PizzaPartyGame
    | PopItGame
    | DanceASlothGame
    | MadMonstersGame
    | WordFinderGame; 
    