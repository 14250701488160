import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as WhackAMoleStore from "../../../store/games/WhackAMoleStore";
import * as GamesStore from "../../../store/games/Games";
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {SelectList, SubmitButton} from "../../forms/FormGroup";
import {AppState} from "../../../store/configureStore";

interface Props {
    gamesActions: typeof GamesStore.actionCreators;
    whackAMoleState: WhackAMoleStore.WhackAMoleState;
    whackAMoleActions: typeof WhackAMoleStore.actionCreators;
}

const WhackAMoleSettings = (props: Props) => {
    if (!props.whackAMoleState.ui.showSettings) {
        return null;
    }

    const game = props.whackAMoleState.game;
    if (!game) {
        return null;
    }

    const settings = {
        skin: game.skin || 'Default',
    };

    const skinOptions = [
        {name: "Default",    value: "Default"   }
    ];

    return(
      <Dialog
          className='settings'
          onClose={() => props.gamesActions.showSettings(game.gameType, false)}
          loading={props.whackAMoleState.isLoading.updateSettings}>
          <h2><Translate id='settings'/></h2>
          <form onSubmit={e => e.preventDefault()}>
          <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.whackAMoleActions.setField}/>
              <h3><Translate id='preview_skin'/></h3>
              <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
              <div className='clear-fix' />
              <SubmitButton
                  text='submit'
                  onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                  split
              />
          </form>
      </Dialog>
    );
};

export default connect(
    (state: AppState) => ({whackAMoleState: state.whackAMoleState}),
    dispatch => ({
        whackAMoleActions: bindActionCreators(WhackAMoleStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(WhackAMoleSettings);