import axios from "axios";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {errorActions} from "./Error";
import {Reducer} from "redux";
import {AccountType} from "../model/AccountType";
import {BaseGame} from "../model/Game/BaseGame";
import {TA} from "./configureStore";
import {GameFilters} from "../model/GameFilters";
import {AccountSearchResponse} from "../model/Response/AccountSearchResponse";
import {PlaylistSearchResponse} from "../model/Response/PlaylistSearchResponse";
import {PlayListType} from "../model/PlayListType";

export const reducerName = 'libraryState';

const LIBRARY_PP_RESET = 'LIBRARY_PP_RESET';

const LIBRARY_PP_GET_GAMES_REQUEST = 'LIBRARY_PP_GET_GAMES_REQUEST';
const LIBRARY_PP_GET_GAMES_RESPONSE = 'LIBRARY_PP_GET_GAMES_RESPONSE';
const LIBRARY_PP_GET_PLAYLISTS_REQUEST = 'LIBRARY_PP_GET_PLAYLISTS_REQUEST';
const LIBRARY_PP_GET_PLAYLISTS_RESPONSE = 'LIBRARY_PP_GET_PLAYLISTS_RESPONSE';
const LIBRARY_GET_LIST_REQUEST = 'LIBRARY_GET_LIST_REQUEST';
const LIBRARY_GET_LIST_RESPONSE = 'LIBRARY_GET_LIST_RESPONSE';
const LIBRARY_GET_AUTHOR_LIST_REQUEST = 'LIBRARY_GET_AUTHOR_LIST_REQUEST';
const LIBRARY_GET_AUTHOR_LIST_RESPONSE = 'LIBRARY_GET_AUTHOR_LIST_RESPONSE';

const LIBRARY_PP_SHOW_ID = 'LIBRARY_PP_SHOW_ID';
const LIBRARY_PP_SHOW_MENU = 'LIBRARY_PP_SHOW_MENU';
const LIBRARY_SET_GAME_PREVIEW = 'LIBRARY_SET_GAME_PREVIEW';
const LIBRARY_SET_SEARCH_STRING = 'LIBRARY_SET_SEARCH_STRING';
const LIBRARY_SET_GAME_FILTERS = 'LIBRARY_SET_GAME_FILTERS';
const LIBRARY_SHOW_SEARCH_STRING = 'LIBRARY_SHOW_SEARCH_STRING';

const LIBRARY_SHOW_SEARCH_MENU = 'LIBRARY_SHOW_SEARCH_MENU';

const LIBRARY_GET_SEARCH_RESULTS_GAME_REQUEST = 'LIBRARY_GET_SEARCH_RESULTS_GAME_REQUEST';
const LIBRARY_GET_SEARCH_RESULTS_GAME_RESPONSE = 'LIBRARY_GET_SEARCH_RESULTS_GAME_RESPONSE';

const LIBRARY_GET_SEARCH_RESULTS_AUTHOR_REQUEST = 'LIBRARY_GET_SEARCH_RESULTS_AUTHOR_REQUEST';
const LIBRARY_GET_SEARCH_RESULTS_AUTHOR_RESPONSE = 'LIBRARY_GET_SEARCH_RESULTS_AUTHOR_RESPONSE';

const LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_REQUEST = 'LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_REQUEST';
const LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_RESPONSE = 'LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_RESPONSE';

const LIBRARY_ACCOUNT_POPULAR_GAMES_REQUEST = 'LIBRARY_ACCOUNT_POPULAR_GAMES_REQUEST';
const LIBRARY_ACCOUNT_POPULAR_GAMES_RESPONSE = 'LIBRARY_ACCOUNT_POPULAR_GAMES_RESPONSE';

const LIBRARY_GET_SAME_COUNTRY_GAMES_REQUEST = 'LIBRARY_GET_SAME_COUNTRY_GAMES_REQUEST';
const LIBRARY_GET_SAME_COUNTRY_GAMES_RESPONSE = 'LIBRARY_GET_SAME_COUNTRY_GAMES_RESPONSE';

export interface AuthorList{
    authors: {[key: string]: AccountType};
    name: string;
    loading: boolean;
}
export interface GameList{
    games: BaseGame[];
    name: string;
    resultCount: number;
    loading?: boolean;
    playlistCounts?: {id: string; count: number}[];
}

export interface LibraryState {
    publicProfile: {
        id: string;
        games?: GameList;
        playLists: PlayListType[];
        favorites: BaseGame[];
        menuShowing: string;
    };
    accountPopularGames: {[key: string]: BaseGame[]};
    gameLists: GameList[];
    gamesByCountry?: GameList;
    authorsMyOrganization: AuthorList | null;
    authorsPublic: AuthorList | null;
    authorsFollowing?: AuthorList;
    gamePreview: BaseGame | null;
    previewRow: number;
    searchString: string;
    searchResultGame: GameList | null;
    searchGameOffset: number;
    searchResultAuthor: AccountSearchResponse | null;
    searchAuthorOffset: number;
    searchResultPlaylist: PlaylistSearchResponse | null;
    searchPlaylistOffset: number;
    searchMenu: 'games' | 'playlists' | 'authors';
    gameFilters: GameFilters;
    loading: {
        getGamesByAccount: boolean;
        getPlayListsByAccount: boolean;
        getList1: boolean;
        searchGame: boolean;
        searchAuthor: boolean;
        searchPlaylist: boolean;
        getAccountPopularGames?: string;
        getSameCountryGames?: boolean;
    };
}

const gameFilterJson = localStorage.getItem("gamefilters");
const gameFilters: GameFilters = gameFilterJson
    ? JSON.parse(gameFilterJson)
    : {languages: [], subjects: [], minAge: 0, maxAge: 50, gameTypes: [], tags: []};

const initialState: LibraryState = {
    accountPopularGames: {},
    publicProfile:{
        id: "",
        games: undefined,
        playLists: [],
        favorites: [],
        menuShowing: "dashboard"
    },
    gameFilters: {
        languages: gameFilters.languages || [],
        subjects: gameFilters.subjects || [],
        gameTypes: gameFilters.gameTypes || [],
        tags: gameFilters.tags || [],
        minAge: gameFilters.minAge || 0,
        maxAge: gameFilters.maxAge || 50,
        sort:{
            key: gameFilters.sort?.key || "editedDateTime",
            ascending: gameFilters.sort?.ascending || false
        }
    },
    gameLists: [],
    authorsMyOrganization: null,
    authorsPublic: null,

    gamePreview: null,
    previewRow: -1,
    searchString: '',
    searchResultGame: null,
    searchGameOffset: 0,
    searchResultAuthor: null,
    searchAuthorOffset: 0,
    searchResultPlaylist: null,
    searchPlaylistOffset: 0,
    searchMenu: 'games',
    loading:{
        getGamesByAccount: false,
        getPlayListsByAccount: false,
        getList1: false,
        searchGame: false,
        searchAuthor: false,
        searchPlaylist: false
    }
};

export const actionCreators = {
    ppReset: (): TA => async(dispatch) => {
        dispatch({type: LIBRARY_PP_RESET});
    },
    ppSetId: (id: string): TA => async(dispatch) => {
        dispatch({type: LIBRARY_PP_SHOW_ID, id});
        dispatch(actionCreators.getGamesByAccount(id));
        dispatch(actionCreators.getPlayListsByAccount(id));
    },
    ppShowMenu: (menu: string): TA => async(dispatch, getState) => {
        if(menu === getState()[reducerName].publicProfile.menuShowing) return;
        dispatch({type: LIBRARY_PP_SHOW_MENU, menu});
        if("games" === menu) dispatch(actionCreators.getGamesByAccount(getState()[reducerName].publicProfile.id));
        if("playLists" === menu) dispatch(actionCreators.getPlayListsByAccount(getState()[reducerName].publicProfile.id));
    },
    getList: (row: number): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: LIBRARY_GET_LIST_REQUEST, row});

        axios.post(`api/game/random/50`, getState().libraryState.gameFilters, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_GET_LIST_RESPONSE, data: response.data, row});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getList', error));
            })
    },
    getSameCountryGames: (): TA => async(dispatch, getState) => {
        dispatch({type: LIBRARY_GET_SAME_COUNTRY_GAMES_REQUEST});
        axios.get(`api/game/myCountry`, CreateAuthHeader(getState))
            .then(response => {
                const data: { name: string; games: BaseGame[]} = response.data;
                dispatch({type: LIBRARY_GET_SAME_COUNTRY_GAMES_RESPONSE, data: data})
            })
            .catch(error => errorActions.reportAxiosError(reducerName, "getSameCountryGames", error));
    },
    setGameFilters: (gameFilters: GameFilters): TA => async(dispatch) => {
        localStorage.setItem("gamefilters", JSON.stringify(gameFilters));
        dispatch({type: LIBRARY_SET_GAME_FILTERS, data: gameFilters});
    },
    getStaticGames: (row: number): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: LIBRARY_GET_LIST_REQUEST, row});

        axios.post(`api/game/static`, {}, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_GET_LIST_RESPONSE, data: response.data, row});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getList', error));
            })
    },
    getOrganizationGames: (row: number): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: LIBRARY_GET_LIST_REQUEST, row});
        const meOrgId = getState().me.account?.organizationId;

        axios.post(`api/game/organization/${meOrgId}/20`, {}, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_GET_LIST_RESPONSE, data: response.data, row});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getList', error));
            })
    },
    getPublicGames: (row: number): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: LIBRARY_GET_LIST_REQUEST, row});

        axios.post(`api/game/public/20`, {}, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_GET_LIST_RESPONSE, data: response.data, row});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getList', error));
            })
    },
    getAuthorListOrganization: (): TA => async(dispatch, getState) => {
        const me = getState().me;
        if(me.account === null) return;
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({
            type: LIBRARY_GET_AUTHOR_LIST_REQUEST,
            data: {
                list: {authors: [], name: 'accounts_in_my_organization', loading: true},
                key: 'authorsMyOrganization'
            }
        });

        const url = `api/account/organization/${me.account.organizationId}`;

        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({
                    type: LIBRARY_GET_AUTHOR_LIST_RESPONSE,
                    data: {
                        list: {authors: response.data, name: 'accounts_in_my_organization'},
                        key: 'authorsMyOrganization'
                    }
                })
            })
            .catch(error => {
                dispatch(errorActions.reportError(reducerName, 'getAuthorListOrganization', error));
            })
    },
    getRandomPublicAuthors: (): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({
            type: LIBRARY_GET_AUTHOR_LIST_REQUEST,
            data: {
                list: {authors: [], name: 'random_public_authors', loading: true},
                key: 'authorsPublic'
            }
        });

        const url = `api/account/random/100`;

        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({
                    type: LIBRARY_GET_AUTHOR_LIST_RESPONSE,
                    data: {
                        list: {authors: response.data, name: 'random_public_authors'},
                        key: 'authorsPublic'
                    }
                })
            })
            .catch(error => {
                dispatch(errorActions.reportError(reducerName, 'getRandomPublicAuthors', error));
            })
    },
    getAccountFollowings: (): TA => async(dispatch, getState) => {
        dispatch({
            type: LIBRARY_GET_AUTHOR_LIST_REQUEST,
            data: {
                list: {authors: [], name: 'pp_following', loading: true},
                key: 'authorsFollowing'
            }
        });

        const meId = getState().me.account?.id;
        const url = `api/account/${meId}/followings`;
        axios.get(url, CreateAuthHeader(getState))
            .then(r => dispatch({type: LIBRARY_GET_AUTHOR_LIST_RESPONSE, data: {
                list: {authors: r.data, name: 'pp_following'},
                key: "authorsFollowing"
            }}))
            .catch(e => dispatch(errorActions.reportAxiosError(reducerName, 'getAccountFollowings', e)));
    },
    getAccountFollowingsGames: (n: number): TA => async(dispatch, getState) => {
        dispatch({type: LIBRARY_GET_LIST_REQUEST, row: n});
        const meId = getState().me.account?.id;
        axios.get(`api/game/account/${meId}/followings`, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_GET_LIST_RESPONSE, data: response.data, row: n});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getList', error));
            });
    },
    setGamePreview: (gameId: BaseGame | null, row: number): TA => async(dispatch) => {
        dispatch({type: LIBRARY_SET_GAME_PREVIEW, gameId, row});
    },
    getGamesByAccount: (accountId: string): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: LIBRARY_PP_GET_GAMES_REQUEST});
        const url = `api/game/account/${accountId}/all`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_PP_GET_GAMES_RESPONSE, data: response.data, accountId});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getGamesByAccount', error));
            })
    },
    getPlayListsByAccount: (accountId: string): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: LIBRARY_PP_GET_PLAYLISTS_REQUEST});
        const url = `api/workshop/playlist/account/${accountId}/all`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_PP_GET_PLAYLISTS_RESPONSE, data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportError(reducerName, 'getPlayListsByAccount', error));
            })
    },
    setSearchString: (searchString: string): TA => async(dispatch, getState) => {
        dispatch({type: LIBRARY_SET_SEARCH_STRING, searchString});
        const searchMenu = getState().libraryState.searchMenu;
        if(searchMenu === 'games'){
            dispatch(actionCreators.searchForGames(false, false));
        }
        if(searchMenu === 'playlists'){
            dispatch(actionCreators.searchForPlaylists(false, false));
        }
        if(searchMenu === 'authors'){
            dispatch(actionCreators.searchForAuthors(false, false));
        }
    },

    searchForGames: (force: boolean, appendResults: boolean): TA => async(dispatch, getState) => {
        const searchString = getState()[reducerName].searchString;
        const searchFilters = getState().libraryState.gameFilters;

        if(force || !getState()[reducerName].loading.searchGame){
            dispatch({type: LIBRARY_GET_SEARCH_RESULTS_GAME_REQUEST, reset: !appendResults});
            const data = {
                searchString: searchString,
                gameFilters: searchFilters,
                offset : appendResults ? getState().libraryState.searchGameOffset : 0,
                sort: searchFilters.sort.key,
                ascending: searchFilters.sort.ascending
            };

            axios.post('api/library/search/games', data, CreateAuthHeader(getState))
                .then(response => {
                    const currentSearchString = getState()[reducerName].searchString;
                    const currentSearchFilters = getState().libraryState.gameFilters;
                    //Fire a new searchRequest if the searched for info, doesn't match the current filters
                    if(currentSearchString !== searchString ||
                        JSON.stringify(searchFilters) !== JSON.stringify(currentSearchFilters)
                    ){
                        dispatch(actionCreators.searchForGames(true, false));
                    }
                    else{
                        dispatch({type: LIBRARY_GET_SEARCH_RESULTS_GAME_RESPONSE, data: response.data, append: appendResults});
                    }
                });
        }
    },
    searchForAuthors: (force: boolean, appendResults: boolean): TA => async(dispatch, getState) => {
        const searchString = getState()[reducerName].searchString;
        if(force || !getState()[reducerName].loading.searchAuthor){
            dispatch({type: LIBRARY_GET_SEARCH_RESULTS_AUTHOR_REQUEST});

            const data = {
                "searchString": searchString,
                "offset" : appendResults ? getState().libraryState.searchAuthorOffset : 0
            };

            axios.post('api/library/search/authors', data , CreateAuthHeader(getState))
                .then(response => {
                    const currentSearchString = getState()[reducerName].searchString;
                    if(currentSearchString !== searchString){
                        dispatch(actionCreators.searchForAuthors(true, false));
                    }
                    else{
                        dispatch({type: LIBRARY_GET_SEARCH_RESULTS_AUTHOR_RESPONSE, data: response.data, append: appendResults});
                    }

                });
        }
    },
    searchForPlaylists: (force: boolean, appendResults: boolean): TA => async(dispatch, getState) => {
        const searchString = getState()[reducerName].searchString;
        if(force || !getState()[reducerName].loading.searchPlaylist){
            dispatch({type: LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_REQUEST});

            const data = {
                "searchString": searchString,
                "offset" : appendResults ? getState().libraryState.searchPlaylistOffset : 0
            };

            axios.post('api/library/search/playlists', data , CreateAuthHeader(getState))
                .then(response => {
                    const currentSearchString = getState()[reducerName].searchString;
                    if(currentSearchString !== searchString){
                        dispatch(actionCreators.searchForPlaylists(true, false));
                    }
                    else{
                        dispatch({type: LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_RESPONSE, data: response.data, append: appendResults});
                    }
                });
        }
    },
    showSearchResult: (show: boolean): TA => async(dispatch) => {
        dispatch({type: LIBRARY_SHOW_SEARCH_STRING, show});
    },
    showSearchMenu: (menu: string): TA => async(dispatch, getState) => {
        if(menu !== getState().libraryState.searchMenu){
            dispatch({type: LIBRARY_SHOW_SEARCH_MENU, menu});
            if(menu === 'games'){
                dispatch(actionCreators.searchForGames(false, false));
            }
            if(menu === 'playlists'){
                dispatch(actionCreators.searchForPlaylists(false, false));
            }
            if(menu === 'authors'){
                dispatch(actionCreators.searchForAuthors(false, false));
            }
        }
    },
    getAccountPopularGames: (accountId: string, forceRefresh: boolean): TA => async(dispatch, getState) => {
        if(!forceRefresh){
            if(getState().libraryState.accountPopularGames.hasOwnProperty(accountId)) return;
        }

        dispatch({type: LIBRARY_ACCOUNT_POPULAR_GAMES_REQUEST, id: accountId});
        const url = `api/game/account/${accountId}/popular`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: LIBRARY_ACCOUNT_POPULAR_GAMES_RESPONSE, data: response.data, id: accountId});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getAccountPopularGames', error));
            })

    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: LibraryState, action: any) => LibraryState} = {
    LIBRARY_GET_SAME_COUNTRY_GAMES_REQUEST: (state, action) => ({
        ...state,
        loading:{
            ...state.loading,
            getSameCountryGames: true
        }
    }),
    LIBRARY_GET_SAME_COUNTRY_GAMES_RESPONSE: (state, action) => ({
        ...state,
        loading: {
            ...state.loading,
            getSameCountryGames: undefined
        },
        gamesByCountry: action.data
    }),
    LIBRARY_ACCOUNT_POPULAR_GAMES_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getAccountPopularGames: action.id
            }
        }
    },
    LIBRARY_ACCOUNT_POPULAR_GAMES_RESPONSE: (state, action) => {
        return{
            ...state,
            accountPopularGames:{
                ...state.accountPopularGames,
                [action.id]: action.data
            },
            loading:{
                ...state.loading,
                getAccountPopularGames: undefined
            }
        }
    },
    LIBRARY_SET_GAME_FILTERS: (state, action) => {
        return{
            ...state,
            gameFilters: action.data
        }
    },
    LIBRARY_SET_ACTIVE_LANGUAGE: (state, action) => {
        return{
            ...state,
            activeLanguage: action.language
        }
    },
    LIBRARY_SHOW_SEARCH_MENU: (state, action) => {
        return{
            ...state,
            searchMenu: action.menu,
        }
    },
    LIBRARY_GET_SEARCH_RESULTS_GAME_REQUEST: (state, action) => {
        return{
            ...state,
            searchResultGame: action.reset ? null : state.searchResultGame,
            loading:{
                ...state.loading,
                searchGame: true,
            }
        }
    },
    LIBRARY_GET_SEARCH_RESULTS_AUTHOR_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                searchAuthor: true,
            }
        }
    },
    LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                searchPlaylist: true,
            }
        }
    },
    LIBRARY_GET_SEARCH_RESULTS_GAME_RESPONSE: (state, action) => {
        if(!action.append){
            return{
                ...state,
                searchResultGame: action.data,
                searchGameOffset: action.data.games.length,
                loading:{
                    ...state.loading,
                    searchGame: false
                }
            }
        }else{
            return{
                ...state,
                searchResultGame: {
                    ...state.searchResultGame,
                    games: state.searchResultGame && state.searchResultGame.games.concat(action.data.games),
                    playlistCounts: state.searchResultGame && state.searchResultGame.playlistCounts?.concat(action.data.playlistCounts)
                },
                searchGameOffset: state.searchGameOffset + action.data.games.length,
                loading:{
                    ...state.loading,
                    searchGame: false
                }
            }
        }
    },
    LIBRARY_GET_SEARCH_RESULTS_AUTHOR_RESPONSE: (state, action) => {
        return{
            ...state,
            searchResultAuthor: {
                ...state.searchResultAuthor,
                accounts: action.append
                    ? state.searchResultAuthor && state.searchResultAuthor.accounts.concat(action.data.accounts)
                    : action.data.accounts,
                resultCount: action.data.resultCount
            },
            searchAuthorOffset: action.append
                ? state.searchAuthorOffset + action.data.accounts.length
                : action.data.accounts.length,
            loading:{
                ...state.loading,
                searchAuthor: false
            }
        }
    },
    LIBRARY_GET_SEARCH_RESULTS_PLAYLIST_RESPONSE: (state, action) => {
        return{
            ...state,
            searchResultPlaylist: {
                ...state.searchResultPlaylist,
                playlists: action.append ?
                    state.searchResultPlaylist && state.searchResultPlaylist.playlists.concat(action.data.playlists) :
                    action.data.playlists,
                resultCount: action.data.resultCount
            },
            searchPlaylistOffset: action.append ?
                state.searchPlaylistOffset + action.data.playlists.length :
                action.data.playlists.length,
            loading:{
                ...state.loading,
                searchPlaylist: false
            }
        }
    },
    LIBRARY_SHOW_SEARCH_STRING: (state, action) => {
        return{
            ...state,
            showSearchResult: action.show
        }
    },
    LIBRARY_SET_SEARCH_STRING: (state, action) => {
        return{
            ...state,
            searchString: action.searchString
        }
    },
    LIBRARY_GET_AUTHOR_LIST_REQUEST: (state, action) => {
        return{
            ...state,
            [action.data.key]: action.data.list
        }
    },
    LIBRARY_GET_AUTHOR_LIST_RESPONSE: (state, action) => {
        return{
            ...state,
            [action.data.key]: action.data.list
        }
    },
    LIBRARY_SET_GAME_PREVIEW: (state, action) => {
        return{
            ...state,
            previewRow: action.row,
            gamePreview: action.gameId
        }
    },
    LIBRARY_PP_RESET: (state) => {
        return{
            ...state,
            publicProfile: initialState.publicProfile
        }
    },
    LIBRARY_PP_SHOW_ID: (state, action) => {
        return {
            ...state,
            publicProfile:{
                ...state.publicProfile,
                id: action.id
            }
        }
    },
    LIBRARY_PP_SHOW_MENU: (state, action) => {
        return{
            ...state,
            publicProfile: {
                ...state.publicProfile,
                menuShowing: action.menu
            }
        }
    },
    LIBRARY_PP_GET_GAMES_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getGamesByAccount: true
            }
        }
    },
    LIBRARY_PP_GET_GAMES_RESPONSE: (state, action) => {
        return{
            ...state,
            publicProfile: {
                ...state.publicProfile,
                games: action.data
            },
            loading:{
                ...state.loading,
                getGamesByAccount: false
            }
        }
    },
    LIBRARY_PP_GET_PLAYLISTS_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getPlayListsByAccount: true
            }
        }
    },
    LIBRARY_PP_GET_PLAYLISTS_RESPONSE: (state, action) => {
        return{
            ...state,
            publicProfile: {
                ...state.publicProfile,
                playLists: action.data
            },
            loading:{
                ...state.loading,
                getPlayListsByAccount: false
            }
        }
    },
    LIBRARY_GET_LIST_REQUEST: (state, action) => {
        const newGameList = [...state.gameLists];
        newGameList[action.row] = {loading: true, games: [], name: '', resultCount: 0, playlistCounts: []};
        return{
            ...state,
            gameLists: newGameList,
        }
    },
    LIBRARY_GET_LIST_RESPONSE: (state, action) => {
        const newGameList = [...state.gameLists];
        newGameList[action.row] = action.data;
        return{
            ...state,
            gameLists: newGameList
        }
    },

    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            loading: {
                ...state.loading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<LibraryState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};