import * as React from 'react';
import Dialog from "../Dialog";

interface Props {
    visible: boolean;
    closeFunc: () => void;
}

export const Changelog = (props: Props) => {
    if(!props.visible) return null;
    return(
        <Dialog className='changelog-dialog' onClose={props.closeFunc}>
            <div className='changelog'>
                <h1>Changelog - MyWall</h1>
                <h2>Version 2.0.7 (02. Sep. 2024)</h2>
                <ul>
                    <li>WordFinder: Added new game</li>
                </ul>
                <h2>Version 2.0.6 (18. Jun. 2024)</h2>
                <ul>
                    <li>PopIt: Updated game name translations</li>
                </ul>
                <h2>Version 2.0.5 (17. Jun. 2024)</h2>
                <ul>
                    <li>PizzaParty: Added new game</li>
                    <li>Different games: Added missing translations</li>
                    <li>PopIt: Change game icon</li>
                </ul>
                <h2>Version 2.0.4 (09. Apr. 2024)</h2>
                <ul>
                    <li>DanceASloth: Added new game</li>
                    <li>MadMonsters: Added new game</li>
                    <li>PopIt: Added new game</li>
                </ul>
                <h2>Version 2.0.2 (11. Jan. 2024)</h2>
                <ul>
                    <li>Admin/licensing: Communication with myfloor regarding licenses</li>
                    <li>Reseller: Can no longer edit(license information only) or remove walls via MyWall (go to MyFloor instead)</li>
                </ul>
                <h2>Version 2.0.1 (15. Dec. 2023)</h2>
                <ul>
                    <li>Meditation: Added new game</li>
                    <li>RaceCar: Added new game</li>
                    <li>FeedTheFox: Added new game</li>
                    <li>WhackAMole: Added new game</li>
                </ul>
                <h2>Version 2.0.0 (20. Aug. 2023)</h2>
                <ul>
                    <li>Updated underlying framework to new version</li>
                </ul>
                <h2>Version 1.3.17 (16. Aug, 2023)</h2>
                <ul>
                    <li>General: Added Lithuanian</li>
                </ul>
                <h2>Version 1.3.16 (31. Jul, 2023)</h2>
                <ul>
                    <li>Accounts: Fixed a bug that would make it impossible to login if you have the playlistInPlaylist permission in myfloor</li>
                    <li>General: Possible fixes to server-crashes</li>
                </ul>
                <h2>Version 1.3.15 (13. Jul, 2023)</h2>
                <ul>
                    <li>Presentation game added</li>
                </ul>
                <h2>Version 1.3.14 (09. May, 2023)</h2>
                <ul>
                    <li>Wall list: Fixed an issue that would only allow you to see 10 walls on each organization</li>
                </ul>
                <h2>Version 1.3.13 (18. Apr, 2023)</h2>
                <ul>
                    <li>Accounts: Fix a bug that would make the system crash if you have the "Deal registration" permission</li>
                    <li>Localization: Added french</li>
                </ul>
                <ul>
                    <li>Goalshow game added</li>
                    <li>SuperSorter game added</li>
                </ul>
                <h2>Version 1.3.12 (14. Dec, 2022)</h2>
                <ul>
                    <li>Goalshow game added</li>
                    <li>SuperSorter game added</li>
                </ul>
                <h2>Version 1.3.10 (3. Feb, 2022)</h2>
                <ul>
                    <li>Fixed permissions issues that could cause crashes on the wall.</li>
                    <li>Added fitness skins to a range of games</li>
                    <li>Bugfixes...</li>
                </ul>
                <h2>Version 1.3.6 (20. aug, 2021)</h2>
                <ul>
                    <li>Disabled edit of organizations</li>
                    <li>Fixed an issue that would make playlists empty at seemingly random times</li>
                </ul>
                <h2>Version 1.3.4 (21. jul, 2021)</h2>
                <ul>
                    <li>Adjusted persmission service when using api login, to return more unauthorized</li>
                    <li>Fixed some leftover functionality from "floor" based systems</li>
                    <li>Cleaned up the soruce-code a bit</li>
                </ul>
                <h2>Version 1.3.3 (5. jul, 2021)</h2>
                <ul>
                    <li>Updated look of public profile</li>
                    <li>Updated interface of edit playlist</li>
                    <li>Fixed an issue with topmenu points persisting when switching away from the "my walls"-page</li>
                    <li>Fixed an issue with creating new walls</li>
                </ul>
                <h2>Version 1.3.2 (30. jun. 2021)</h2>
                <ul>
                    <li>Added Pirates' island gameType</li>
                </ul>
                <h2>Version 1.3.1 (09. jun. 2021)</h2>
                <ul>
                    <li>Fixed an issue that would not allow a user to create a playlist. If they set it to public or published.</li>
                    <li>Fixed a visual issue with checkboxes not being aligned correctly</li>
                    <li>Updated the checkboxes to adapt the new myfloor style as well</li>
                    <li>Walls, show Api-key when admin for creating new floors</li>
                    <li>Resellers can now add items to their customers walls, as long as they have the 'Customer information'-permission</li>
                    <li>Will now show the explaination for the "Customer information"-permission when certain conditions is met</li>
                    <li>Fixed a issue in how the related games window was rendered</li>
                </ul>
                <h2>Version 1.3.0 (7. jun. 2021)</h2>
                <ul>
                    <li>BuzzIt: Added game template.</li>
                    <li>Fixed various bugs, related to bundles and playlists.</li>
                </ul>
                <h2>Version 1.2.0 (10. may. 2021)</h2>
                <ul>
                    <li>General: Exchanged two fonts.</li>
                </ul>
                <h2>Version 1.1.1 (23. apr. 2021)</h2>
                <ul>
                    <li>Memory: New skin options from myfloor. (Doesn't work for the sportswall)</li>
                </ul>
                <h2>Version 1.1.0 (22. apr. 2021)</h2>
                <ul>
                    <li>Removed Private permission, introduced: "Publish","Public" and "Editor"</li>
                    <li>Public profile: Fixed a the game list not displaying properly</li>
                    <li>Singup: Updated the emails to contain SportsWall instead of ActiveFloor</li>
                </ul>
                <h2>Version 1.0.8 (16. apr. 2021)</h2>
                <ul>
                    <li>Can now create games again</li>
                </ul>
                <h2>Version 1.0.7 (13. apr. 2021)</h2>
                <ul>
                    <li>Renamed and relaxed requirements for translations, now called copies</li>
                    <li>Added a new coruseName "Recess"</li>
                    <li>Fixed game filters on organization profile page.</li>
                </ul>
                <h2>Version 1.0.6 (29. mar. 2021)</h2>
                <ul>
                    <li>SmackTheFly: Fixed background aspect-ratio from 1920x1080 to correct 1920x1200</li>
                </ul>
                <h2>Version 1.0.5 (25. mar. 2021)</h2>
                <ul>
                    <li>QuizNinja: Fixed an issue with modifying answers</li>
                </ul>
                <h2>Version 1.0.4 (23. mar. 2021)</h2>
                <ul>
                    <li>Wall: Fixed an issue in the permission service to allow users to add items to their walls</li>
                    <li>Users: Added reseller control of Users for clients</li>
                    <li>CircusRace: Updated skinpreview for Default</li>
                    <li>BlockBuster: Updated skinpreview for Default</li>
                    <li>BlockBuster: Updated game_icon</li>
                </ul>
                <h2>Version 1.0.3 (11. mar. 2021)</h2>
                <ul>
                    <li>General: Updated missing translations for arabic, danish, hebrew, swedish, norweigian, dutch and spanish</li>
                </ul>
                <h2>Version 1.0.2 (9. mar. 2021)</h2>
                <ul>
                    <li>General: Added the changelog</li>
                    <li>General: Adjusted color of topmenu bar, to remove transparency</li>
                    <li>Organization: Corrected colors to make info visible</li>
                    <li>Walls: Adjusted the available characters for apikeys</li>
                </ul>
            </div>
        </Dialog>
    );
};

