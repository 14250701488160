import {AccountType} from "../model/AccountType";
import {Organization} from "../model/Organization";
import {DayOfWeek} from "../model/Schedule/DeleteScheduledActionModel";
import { SortedItem } from "../model/SportsWall/SortedItem";

export const SortByFirstName = (a: AccountType, b: AccountType): number => {
    if(!a || !a.firstname) return 1;
    if(!b || !b.firstname) return -1;
    const x = a.firstname.toLowerCase();
    const y = b.firstname.toLowerCase();
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
};

export const SortByName = (a: Organization, b: Organization): number => {
    if(!a || !a.name) return 1;
    if(!b || !b.name) return -1;

    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
};

export const SortBySystemName = (a: Organization, b: Organization): number => {
    if(!a || !a.systemName) return 1;
    if(!b || !b.systemName) return -1;

    const x = a.systemName.toLowerCase();
    const y = b.systemName.toLowerCase();
    if(x < y) return -1;
    if(x > y) return 1;
    return 0;
};

export const SortByCustom = <T>(a: T, b: T, field: keyof T, ascending = false) => {
    if (!a || a[field] === undefined || a[field] === null) return 1;
    if (!b || b[field] === undefined || b[field] === null) return -1;

    const x = a[field];
    const y = b[field];
    if(typeof x === "string" && typeof y === "string"){
        const xS = x.toLowerCase();
        const yS = y.toLowerCase();
        if(xS < yS) return ascending ? -1 : 1;
        if(xS > yS) return ascending ? 1 : -1;
        return 0;
    }
    if(x < y) return ascending ? -1 : 1;
    if(x > y) return ascending ? 1 : -1;
    return 0;
};

export const SortSortedItems = <T>(a: SortedItem<T>, b: SortedItem<T>) => {
    return SortByCustom(a, b, "sortIndex", true);
}; 

export const SortByWeekday = (a: DayOfWeek, b: DayOfWeek): number => {
    const order = {
        "Monday": 1,
        "Tuesday": 2,
        "Wednesday": 3,
        "Thursday": 4,
        "Friday": 5,
        "Saturday": 6,
        "Sunday": 7
    };
    return order[a] - order[b];
};