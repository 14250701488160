import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {actionCreators as myGamesActions, MyGamesState} from "../../store/MyGames";
import {actionCreators as playListActions, PlayListState} from "../../store/PlayListStore";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ChooseGame from "../forms/games/game/ChooseGame";
import BaseGame from "../forms/games/BaseGame";
import {BaseGame as BaseGameModel} from '../../model/Game/BaseGame';
import Game from "../ModelPreview/Game";
import {LayoutComponentProps} from "../Layout";
import Dialog from "../Dialog";
import {FormButton, Slider} from "../forms/FormGroup";
import {AppState} from "../../store/configureStore";
import TopMenuButton from "../Menu/TopMenuButton";
import {SortByCustom} from "../../services/SortFunctions";
import {ErrorState} from "../../store/Error";
import SortGamesDialog from "../forms/SortGamesDialog";
import ItemContainer from '../ModelPreview/ItemContainer';

interface State{
    sort: {key: keyof BaseGameModel; ascending: boolean};
    showSort: boolean;
}

interface Props extends LayoutComponentProps{
    mygames: MyGamesState;
    errors: ErrorState['mygames'];
    playlist: PlayListState;

    myGamesActions: typeof myGamesActions;
    playListActions: typeof playListActions;
}

class MyGames extends Component<Props, State>{

    constructor(props: Props) {
        super(props);
        this.state = {
            showSort: false,
            sort: {key: "editedDateTime", ascending: false}
        };
        props.setLayoutButtons(this.renderButtons());
    }

    componentDidMount(){
        this.props.playListActions.getPlayLists();
        this.props.myGamesActions.getMyGames();
        this.props.myGamesActions.getMyAuthorGames();
        this.props.myGamesActions.toggleCreateGame(false);
        this.props.myGamesActions.showEditDialog(false);
        this.props.setLayoutButtons(this.renderButtons());
    }

    componentWillUnmount() {
        this.props.setLayoutButtons(undefined);
    }

    renderButtons = () => {
        return (
            <>
                <TopMenuButton action={() => this.props.myGamesActions.toggleCreateGame(true)} icon={'plus'} hoverMsg={'create_new_game'} />
                <TopMenuButton action={() => this.props.myGamesActions.showScaleForm(true)} icon={'search-plus'} hoverMsg={'zoom'} />
                <TopMenuButton action={() => this.setState({showSort: true})} icon='sort' hoverMsg={'sort'} />
            </>
        );
    }

    render() {
        const state = this.state;
        const props = this.props;
        const games = (props.mygames.myGames && props.mygames.myGames.games && [...props.mygames.myGames.games]) || [];
        const authoredGames = (props.mygames.authoredGames && props.mygames.authoredGames.games && [...props.mygames.authoredGames.games]) || [];

        games.sort((a,b) => SortByCustom(a, b, state.sort.key, state.sort.ascending));
        authoredGames.sort((a,b) => SortByCustom(a, b, state.sort.key, state.sort.ascending));

        return (
            <div className='my-games'>
                <div className='top'>

                </div>
                <ItemContainer
                    heading={<h1><Translate id='my_games_title' /></h1>}
                    loading={props.mygames.isLoading.getMyGames && !props.mygames.myGames}
                    items={games}
                    itemRender={(game) => {
                        const unitCount = props.mygames.myGames && props.mygames.myGames.playlistCounts?.find(x => x.id === game.id);
                        return <Game
                            key={game.id}
                            game={game}
                            scale={Number(props.mygames.ui.scale)}
                            count={unitCount ? unitCount.count : undefined}
                        />
                    }}
                />
                <ItemContainer
                    heading={<h2><Translate id='my_games_author' /></h2>}
                    loading={props.mygames.isLoading.getMyGames && !props.mygames.myGames}
                    items={authoredGames}
                    itemRender={(game) => {
                        const unitCount = props.mygames.myGames && props.mygames.myGames.playlistCounts?.find(x => x.id === game.id);
                        return <Game
                            key={game.id}
                            game={game}
                            scale={Number(props.mygames.ui.scale)}
                            count={unitCount ? unitCount.count : undefined}
                        />
                    }}
                />
                {props.mygames.isShowing.createDialog &&
                    <ChooseGame onClose={() => this.props.myGamesActions.toggleCreateGame(false)}/>
                }
                <BaseGame />

                {props.mygames.isShowing.scaleForm &&
                <Dialog onClose={() => props.myGamesActions.showScaleForm(false)}>
                    <h2><Translate id='zoom'/></h2>
                    <Slider min={2} max={20} name={'scale'} value={props.mygames.ui.scale * 10} onChange={e => props.myGamesActions.setScale(e.target.value/10)}/>
                    <FormButton name='reset_to_default' onClick={() => props.myGamesActions.setScale(1)}/>
                </Dialog>
                }

                {state.showSort &&
                <SortGamesDialog
                    sortKey={state.sort.key}
                    asc={state.sort.ascending}
                    onClose={() => this.setState({showSort: false})}
                    onChangeKey={(key) => this.setState({sort: {...this.state.sort, key: key}})}
                    onChangeAscending={(asc) => this.setState({sort: {...this.state.sort, ascending: asc}})}
                />
                }
            </div>
        );
    }
}

export default connect(
    (state: AppState) => ({
        mygames: state.mygames,
        errors: state.errors.mygames,
        playlist: state.playlist
    }),
    dispatch => ({
        myGamesActions: bindActionCreators(myGamesActions, dispatch),
        playListActions: bindActionCreators(playListActions, dispatch)
    })
)(MyGames);