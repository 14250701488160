import React, {MouseEvent} from 'react';
import {DbOrUploadImage, DbOrUploadSound} from '../../../services/ImageHelper';
import backgroundImage from '../../../images/games/smackthefly/background.png';
import backgroundOverlayimage from '../../../images/games/smackthefly/backgroundoverlay.png';
import announcerImage from '../../../images/games/smackthefly/announcer.png';
import bubbleImage from '../../../images/games/smackthefly/question-Bubble.png';
import {StfRound} from '../../../model/Game/SmackTheFly/StfRound';
import {StfAnswer} from "../../../model/Game/SmackTheFly/StfAnswer";
import {StfEditAnswer} from "../../../store/games/SmackTheFlyStore";

interface SmackTheFlyRoundPreviewProps {
    onClick?: () => void;
    round: StfRound;
    index: number;
}

export const SmackTheFlyRoundPreview = (props: SmackTheFlyRoundPreviewProps) => {
    const round = props.round;
    return(
        <div className='smackthefly-preview-wrapper' onClick={props.onClick || undefined}>
            <div className='smackthefly-round-preview' >
                <DbOrUploadImage image={round.backgroundImage} tempImage={backgroundImage} className='background' alt='' realSize/>
                <img src={backgroundOverlayimage} className='background-overlay' alt='' />
                <DbOrUploadImage image={round.announcerImage} tempImage={announcerImage} className='announcer' alt='' realSize/>
                <div className='bubble'>
                    <img src={bubbleImage} alt='' className='bubble-image' />
                    <div className='hint-text' dir='auto'>{round.hint}</div>
                    <DbOrUploadImage image={round.hintImage} className='hint-image'/>
                    <DbOrUploadSound audio={round.hintSound} className='hint-sound'/>
                </div>
                <div className='round-time'>{round.time}</div>
            </div>
        </div>
    );
};

interface AnswerPreviewType {
    answer: StfAnswer;
    onClick?: () => void;
}

export const SmackTheFlyAnswerPreview = (props: AnswerPreviewType) => {
    const answer = props.answer;
    const correctStyle = {borderColor: "rgb(35, 224, 110)"};
    return(
        <div className={`smackthefly-answer-preview-wrapper ${props.onClick ? 'clickable' : ''}`} onClick={props.onClick || undefined}>
            <div className='smackthefly-answer-preview' style={answer.correct ? correctStyle : {}}>
                <div className='text' style={{fontFamily: answer.font}} dir='auto'>{answer.text}</div>
                <DbOrUploadImage image={answer.image} alt='' className='image'/>
            </div>
        </div>
    );
};

interface PositionPreviewType {
    answer: StfEditAnswer;
    round: StfRound;
    onClick?: () => void;
    setX: (x: number) => void;
    setY: (y: number) => void;
}

export const SmackTheFlyPositionPrevew = (props: PositionPreviewType) => {
    const answer = props.answer;
    const styleNew = {top: `${answer.y * 0.5 - 5}px`, left: `${answer.x * 0.5 - 5}px`, width: `${answer.width * 0.5}px`, height: `${answer.width * 0.5}px`, border: "dashed 5px orange", lineHeight: `${answer.width * 0.5 - 5}px`};
    const round = props.round;
    const wrapperRef = React.createRef<HTMLDivElement>();


    const click = (e: MouseEvent<HTMLDivElement>) => {
        const target = wrapperRef.current;
        if(!target) return;

        const rect = target.getBoundingClientRect();
        const x = e.clientX - rect.left; //x position within the element.
        const y = e.clientY - rect.top;
        const pctX = x / rect.width;
        const pctY = y / rect.height;
        props.setX(Math.round(1280*pctX - (answer.width / 2)));
        props.setY(Math.round(800*pctY - (answer.width / 2)));
    };

    return(
        <div className='smackthefly-preview-wrapper' ref={wrapperRef} onClick={props.onClick || undefined}>
            <div className='smackthefly-round-preview' onClick={click} >
                <DbOrUploadImage image={round.backgroundImage} tempImage={backgroundImage} className='background' alt='' realSize/>
                <DbOrUploadImage image={round.announcerImage} tempImage={announcerImage} className='announcer' alt=''/>
                <img src={backgroundOverlayimage} className='background-overlay' alt='' />
                <div className='round-time'>{round.time}</div>
                {round.answers && round.answers.map((a, index) => {
                    const style= {top: `${a.y * 0.5}px`, left: `${a.x * 0.5}px`, width: `${a.width * 0.5}px`, height: `${a.width * 0.5}px`, lineHeight: `${a.width * 0.5 - 5}px`};
                    if (a.customPosition && index !== answer.index){
                        return (
                            <div className='position-preview' key={index} style={style}>
                                <SmackTheFlyAnswerPreview answer={a} />
                            </div>
                        )
                    }
                    return null;
                })}
                <div className='position-preview' style={styleNew}>
                    <SmackTheFlyAnswerPreview answer={answer} />
                </div>
            </div>
        </div>
    )
};