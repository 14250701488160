import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    actionCreators as wosActions,
    WarOfStrategyState,
    wosTransformQuizToStyle
} from "../../../store/games/WarOfStrategy";
import Dialog from "../../Dialog";
import {
    AudioFormButton,
    CheckBox,
    ColorAndFont,
    ImageFormButton,
    InputField,
    SelectList,
    Slider,
    SubmitButton,
    TextAlignmentFormGroup,
    TextArea
} from "../../forms/FormGroup";
import {Translate} from "react-localize-redux";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import {FormGeneralError} from '../../Error/FormGeneralError';
import {WarOfStrategyQuizPreview} from './WarOfStrategyQuizPreview';
import {WosAnswer} from "../../../model/Game/WarOfStrategy/WosAnswer";
import {AppState} from "../../../store/configureStore";
import {TextAlignment} from "../../../model/Game/TextType";
import {ErrorState} from "../../../store/Error";

const QUESTION = 'question';
const ANSWER1 = 'answer1';
const ANSWER2 = 'answer2';
const ANSWER3 = 'answer3';

interface Props {
    wosState: WarOfStrategyState;
    errors: ErrorState['wosState'];
    actions: typeof wosActions;
}

const WosQuizForm = (props: Props) => {
    const quiz = props.wosState.quiz;
    const isLoading = props.wosState.isLoading;
    const ui = props.wosState.ui;

    const q = quiz.question;
    const a1 = quiz.answer1;
    const a2 = quiz.answer2;
    const a3 = quiz.answer3;

    let validForm = true;
    //must have at least one
    if (!q.text.content && !q.image && !q.sound){
        validForm = false;
    }
    //at least one
    if (!a1.text.content && !a1.image){
        validForm = false;
    }
    if (!a2.text.content && !a2.image){
        validForm = false;
    }
    if (!a3.text.content && !a3.image){
        validForm = false;
    }
    //at least one correct
    if(!a1.correct && !a2.correct && !a3.correct){
        validForm = false;
    }

    const submitFunc = () => {
        if (quiz.newQuiz){
            props.actions.createQuiz(quiz);
        }
        else{
            props.actions.updateQuiz(quiz, quiz.index);
        }

    };

    const confirmDelete = <ConfirmationForm
        text={"wos_quiz_delete_confirm"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => props.actions.deleteQuiz(quiz.index)}
        onCancel={() => props.actions.showConfirmDelete(false)}
    />;

    if (!ui.showQuizForm) return null;

    return (
        <Dialog
            loading={isLoading.createQuiz || isLoading.updateQuiz || isLoading.deleteQuiz}
            className='wos-quiz-form-dialog'
            onClose={props.actions.hideQuizForm}
            icon={'trash'}
            onIconClick={() => props.actions.showConfirmDelete(true)}
            icons={[
                {icon: 'eye-dropper', onClick: () => props.actions.saveStyle(), hoverMsg: <div className='min-width'><Translate id='save_style' /></div>},
                {icon: 'fill-drip', onClick: () => props.actions.applyStyle(),
                    hoverMsg: (
                        <div>
                            <div><Translate id='apply_style'/></div>
                            <WarOfStrategyQuizPreview
                                quiz={wosTransformQuizToStyle(quiz, props.wosState.style)}
                            />
                        </div>
                    )
                }
            ]}
        >
            {ui.showConfirmDeleteQuiz && confirmDelete}
            <h1><Translate id='wos_quiz' /></h1>
            <form className='woz-quiz-form' onSubmit={(e) => e.preventDefault()}>
                <div className='wos-question'>
                    <SelectList
                        name='difficulty'
                        defaultValue={quiz.difficulty.toString()}
                        options={[{name:'wos_easy', value: "1"},{name:'wos_normal', value: "2"},{name:'wos_hard', value: "3"},{name: 'wos_veryhard', value: "4"}]}
                        onChange={(event) => {props.actions.updateDifficulty(parseInt(event.target.value))}}
                        noLabel
                        translate
                        original={quiz._o.difficulty.toString()}
                    />
                    <ImageFormButton
                        name={'image'}
                        imageSubmit={(image) => props.actions.updateImage(QUESTION, image)}
                        previewWidth={100}
                        previewHeight={100}
                        unsavedChange={quiz.question.image !== quiz._o.question.image}
                    />
                    <AudioFormButton
                        name={'sound'}
                        audioSubmit={props.actions.setSound}
                        unsavedChange={quiz.question.sound !== quiz._o.question.sound}
                    />
                    <TextArea
                        name='content'
                        value={q.text.content}
                        onChange={(e) => {props.actions.updateTextValue(QUESTION, e)}}
                        placeholder='text'
                        noLabel
                        tabIndex={1}
                        original={quiz._o.question.text.content}
                    />
                    <Slider
                        min={15}
                        max={120}
                        name='fontSize'
                        value={q.text.fontSize}
                        onChange={(e) => {props.actions.updateTextValue(QUESTION, e)}}
                        original={quiz._o.question.text.fontSize}
                    />
                    <ColorAndFont
                        colorValue={q.text.fontColorHex}
                        fontFamilyValue={q.text.fontFamily}
                        onColorChange={(e) => {props.actions.updateTextValue(QUESTION, e)}}
                        onFontFamilyChange={(e) => {props.actions.updateTextValue(QUESTION, e)}}
                        originalFontFamily={quiz._o.question.text.fontFamily}
                        originalColor={quiz._o.question.text.fontColorHex}
                    />
                    <TextAlignmentFormGroup
                        name={'textAlignment'}
                        value={q.text.textAlignment || TextAlignment.Center }
                        onChange={(e) => {props.actions.updateTextValue(QUESTION, e)}}
                        original={quiz._o.question.text.textAlignment || TextAlignment.Center}
                    />
                </div>
                <h2><Translate id='wos_answers'/></h2>
                <div className='wos-answers'>
                    <Answer index={ANSWER1} model={quiz.answer1} _o={quiz._o.answer1} actions={props.actions} tabIndex={2} />
                    <Answer index={ANSWER2} model={quiz.answer2} _o={quiz._o.answer2} actions={props.actions} tabIndex={3} />
                    <Answer index={ANSWER3} model={quiz.answer3} _o={quiz._o.answer3} actions={props.actions} tabIndex={4} />
                    <div className='clear-fix'/>
                </div>

                <div className='wos-quiz-form-preview'>
                    <WarOfStrategyQuizPreview quiz={quiz}/>
                </div>

                <FormGeneralError error={props.errors.createQuiz} />
                <FormGeneralError error={props.errors.updateQuiz} />
                <FormGeneralError error={props.errors.deleteQuiz} />

                <SubmitButton text={'submit'} onClick={submitFunc} split disabled={!validForm}/>
            </form>
        </Dialog>
    );
    
};

export default connect(
    (state: AppState) => ({wosState: state.wosState, errors: state.errors.wosState}),
    dispatch => ({ actions: bindActionCreators(wosActions, dispatch) })
)(WosQuizForm)


interface AnswerProps {
    model: WosAnswer;
    _o: WosAnswer;
    index: 'question' | 'answer1' | 'answer2' | 'answer3';
    actions: typeof wosActions;
    tabIndex: number;
}

const Answer = (props: AnswerProps) => {
    return(
        <div className='answer'>
            <ImageFormButton
                name={'image'}
                imageSubmit={(image) => {props.actions.updateImage(props.index, image)}}
                previewWidth={100}
                previewHeight={100}
                unsavedChange={props.model.image !== props._o.image}
            />
            <InputField
                name='content'
                type='text'
                value={props.model.text.content}
                onChange={(e) => {props.actions.updateTextValue(props.index, e)}}
                placeholder='text'
                tabIndex={props.tabIndex}
                noLabel
                original={props._o.text.content}
            />
            <Slider
                min={15}
                max={120}
                name='fontSize'
                value={props.model.text.fontSize}
                onChange={(e) => {props.actions.updateTextValue(props.index, e)}}
                original={props._o.text.fontSize}
            />
            <ColorAndFont
                colorValue={props.model.text.fontColorHex}
                fontFamilyValue={props.model.text.fontFamily}
                onColorChange={(e) => {props.actions.updateTextValue(props.index, e)}}
                onFontFamilyChange={(e) => {props.actions.updateTextValue(props.index, e)}}
                originalColor={props._o.text.fontColorHex}
                originalFontFamily={props._o.text.fontFamily}
            />
            <TextAlignmentFormGroup
                name={'textAlignment'}
                value={props.model.text.textAlignment || TextAlignment.Center }
                onChange={(e) => {props.actions.updateTextValue(props.index, e)}}
                original={props._o.text.textAlignment || TextAlignment.Center}
            />
            <CheckBox
                name='correct'
                active={props.model.correct}
                onChange={(e) => {props.actions.updateCorrect(props.index, e)}}
                original={props._o.correct}
            />
        </div>
    )
};