import React from 'react';
import {Translate} from 'react-localize-redux';
import {
    AgeRangeSelect,
    CheckBox,
    ColorAndFont,
    ColorPicker,
    ImageFormButton,
    InputField,
    SelectLanguage,
    SelectList,
    Slider,
    SubmitButton,
    TagInputField,
    TextArea
} from '../FormGroup';
import {connect} from "react-redux";
import {actionCreators as MyGamesActions, MyGamesState} from "../../../store/MyGames";
import {bindActionCreators} from "redux";
import {ConfirmationForm} from "./ConfirmationForm";
import {Dialog} from "../../Dialog";
import Game from "../../ModelPreview/Game";
import * as PlayListStore from '../../../store/PlayListStore';
import * as TagStore from '../../../store/TagStore';
import {TagState} from '../../../store/TagStore';
import FieldErrors from "../FieldErrors";
import {AppState} from '../../../store/configureStore';
import {GamesState} from "../../../store/games/Games";
import {courseList} from "../../../model/Subject";
import {ErrorState} from "../../../store/Error";
import {MeState} from "../../../store/Me";
import {accountHasAnyPermission, isAccountType} from "../../../model/AccountType";

interface Props {
    errors: ErrorState['mygames'];
    mygames: MyGamesState;
    myGamesActions: typeof MyGamesActions;
    gamesState: GamesState;
    tagState: TagState;
    tagActions: typeof TagStore.actionCreators;
    user: MeState['account'];
}

const BaseGame = (props: Props) => {
    if(!props.mygames.isShowing.editDialog) return null;
    
    const game = props.mygames.editGame ?? null;

    if(!game) return null;
    const isLoading = props.mygames.isLoading.submitEditGame || props.mygames.isLoading.deleteGame;

    const fontSize = game.fontSize || 20;
    const fontFamily = game.fontFamily || 'Lato';
    const fontColorHex = game.fontColorHex || '#ffffff';
    const showLabel = game.showLabel === undefined ? false : !!game.showLabel;
    const labelColorHex = game.labelColorHex || '#000000';
    const showTitle = game.showTitle === undefined ? false : !!game.showTitle;
    
    const confirmDelete = <ConfirmationForm
        text={"game_delete_confirm"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => props.myGamesActions.deleteGame(game.gameType, game.id)}
        onCancel={() => props.myGamesActions.showGameDeleteConfirm(false)}
    />;
    
    const mainContent = (
        <Dialog
            className='my-games-edit-dialog'
            onClose={() => props.myGamesActions.showEditDialog(false, game)}
            icon={'trash'}
            onIconClick={() => props.myGamesActions.showGameDeleteConfirm(true)}
            loading={isLoading}
        >
            <Game game={game} noMenu onlyImage />
            <div className='clear-fix'/>
            <form className='basegame-form'>
                <div className="form-left-part">
                    <h2><Translate id='base_info_icon_base'/></h2>
                    <div className='color-image-group'>
                        {game.image && <ColorPicker
                            name={'backgroundColorHex'}
                            colorValue={game.backgroundColorHex || '#FFFFFFFF'}
                            onColorChange={props.myGamesActions.changeEditDialog}
                            original={game._o.backgroundColorHex || '#FFFFFFFF'}
                        />}
                        <ImageFormButton
                            name={'image'}
                            imageSubmit={props.myGamesActions.setEditGameImage}
                            previewWidth={220}
                            previewHeight={150}
                            className='float-right'
                            noRemove
                            unsavedChange={game.image !== game._o.image}
                        />
                        <div className='clear-fix' />
                    </div>
                    <div className='clear-fix'/>

                    <div className='title-section'>
                        <InputField
                            name='title'
                            placeholder='title'
                            type='text'
                            value={game.title || ''}
                            errors={props.errors.submitEditGame}
                            onChange={props.myGamesActions.changeEditDialog}
                            noLabel
                            original={game._o.title || ''}
                        />
                        {
                            game.title &&
                            <CheckBox name='showTitle' active={showTitle} onChange={props.myGamesActions.changeEditDialog} original={game._o.showTitle}/>
                        }
                        {
                            showTitle &&
                            <div className='sub-section'>
                                <Slider min={16} max={32} name='fontSize' value={fontSize} onChange={props.myGamesActions.changeEditDialog} original={game._o.fontSize}/>
                                <ColorAndFont
                                    colorValue={fontColorHex}
                                    onColorChange={props.myGamesActions.changeEditDialog}
                                    fontFamilyValue={fontFamily}
                                    onFontFamilyChange={props.myGamesActions.changeEditDialog}
                                    originalColor={game._o.fontColorHex}
                                    originalFontFamily={game._o.fontFamily}
                                />
                                <div className='label-form-group'>
                                    <CheckBox
                                        className='checkbox-group'
                                        name='showLabel'
                                        active={showLabel}
                                        onChange={props.myGamesActions.changeEditDialog}
                                        original={game._o.showLabel}
                                    />
                                    {showLabel &&
                                    <ColorPicker
                                        name='labelColorHex'
                                        colorValue={labelColorHex}
                                        onColorChange={props.myGamesActions.changeEditDialog}
                                        original={game._o.labelColorHex || '#000000'}
                                    />
                                    }
                                    <div className='clear-fix'/>
                                </div>
                            </div>
                        }
                    </div>
                    <TextArea
                        name='description'
                        placeholder='game_description'
                        value={game.description || ''}
                        errors={props.errors.submitEditGame}
                        onChange={props.myGamesActions.changeEditDialog}
                        noLabel
                        original={game._o.description || ''}
                    />
                    <CheckBox 
                        name='isPublished' 
                        active={game.isPublished} 
                        onChange={props.myGamesActions.changeEditDialog} 
                        original={game._o.isPublished}
                        disabled={!accountHasAnyPermission(props.user, ["Public", "Admin"])}
                        disabledHint={"unauthorized_error"}
                    />
                    {(props.errors.submitEditGame && props.errors.submitEditGame.data && props.errors.submitEditGame.data.IsPublished) &&
                    <div className='form-error-container'>
                        <p><Translate id='game_publish_missing_title'/></p>
                        <FieldErrors fieldname='isPublished' errors={props.errors.submitEditGame}/>
                    </div>
                    }
                    {game?.isPublished && isAccountType(props.user) && accountHasAnyPermission(props.user, ["Public", "Admin"]) &&
                    <>
                        <CheckBox
                            name='isPublic'
                            active={game.isPublic}
                            onChange={props.myGamesActions.changeEditDialog}
                            original={game._o.isPublic}
                        />
                        {!game.parentId && 
                            <CheckBox 
                                hoverMsg={'prevent_copy_hover'} 
                                name='preventCopy' 
                                active={game.preventCopy} 
                                onChange={props.myGamesActions.changeEditDialog} 
                                original={game._o.preventCopy} 
                            />
                        }
                    </>
                    }
                    {(props.errors.submitEditGame && props.errors.submitEditGame.data && props.errors.submitEditGame.data.IsPublic) &&
                    <div className='form-error-container'>
                        <p><Translate id='game_public_missing_title'/></p>
                        <FieldErrors fieldname='isPublic' errors={props.errors.submitEditGame}/>
                    </div>
                    }
                </div>
                <div className={`form-right-part ${game.isPublished ? '' : 'hidden'}`}>
                    <h2><Translate id='base_info_category' /></h2>
                    <SelectLanguage
                        name={'languageCode'}
                        defaultValue={game.languageCode || ''}
                        onChange={props.myGamesActions.changeEditDialog}
                        labelName={'language_a'}
                        labelWidth100
                        errors={props.errors.submitEditGame}
                        original={game._o.languageCode || ''}
                    />

                    <div>
                        <SelectList
                            name={'courseName'}
                            defaultValue={game.courseName || ''}
                            options={
                                [
                                    {name: "course_choose", value: "", translate: true},
                                    ...courseList.map(c => {return{name: c, value: c, translate: true}}),
                                    ...props.gamesState.languageCodes.map(l => {
                                        return {name: l.name, value: l.value, translate: false}
                                    })
                                ]
                            }
                            onChange={props.myGamesActions.changeEditDialog}
                            labelName={'subject'}
                            labelWidth100
                            original={game._o.courseName || ''}
                        />
                        <AgeRangeSelect
                            minAge={game.minAge}
                            maxAge={game.maxAge}
                            onChange={props.myGamesActions.changeEditDialog}
                            labelWidth100
                            labelName={'agegroup'}
                            originalMin={game._o.minAge}
                            originalMax={game._o.maxAge}
                        />
                    </div>

                    <TagInputField
                        name={'tags'}
                        type={'text'}
                        value={game.tags || ''}
                        onChange={props.myGamesActions.changeEditDialog}
                        placeholder={'tags_placeholder'}
                        label={'add_tags'}
                        labelWidth100
                        original={game._o.tags || ''}
                    />
                </div>
                <div className='clear-fix'/>

                <SubmitButton text={'submit'} split onClick={(event) => {event.preventDefault(); props.myGamesActions.submitEditGame();}}/>
                {props.errors.submitEditGame && props.errors.submitEditGame.status === 404 &&
                    <div><Translate id='404'/></div>
                }
                {props.errors.deleteGame &&
                <div className='form-error'><Translate id='delete_game_error'/></div>
                }
                {props.mygames.isShowing.deleteGameConfirm && confirmDelete}
            </form>
        </Dialog>
    );

    return mainContent;
};

export default connect(
    (state: AppState) => ({
        errors: state.errors.mygames,
        mygames: state.mygames,
        gamesState: state.gamesState,
        tagState: state.tagState,
        user: state.me.account
    }),
    dispatch => ({
        playListActions: bindActionCreators(PlayListStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesActions, dispatch),
        tagActions: bindActionCreators(TagStore.actionCreators, dispatch)
    })
)(BaseGame)
