export const copyToClipboard = (msg: string) => {

    const eventHandler = (e: ClipboardEvent) => {
        e.clipboardData && e.clipboardData.setData('text/plain', msg);
        e.preventDefault();
    };

    document.addEventListener('copy', eventHandler);
    document.execCommand('copy');
    document.removeEventListener('copy', eventHandler);
};

export const updateItemInArray = <T extends {id: string}> (orignalArray: Array<T>, item: T) => {
    return orignalArray.map(x => x.id === item.id ? item : x);
}
export const removeItemFromArray = <T extends {id: string}> (orignalArray: Array<T>, id: string) => {
    return orignalArray.filter(x => x.id !== id);
}