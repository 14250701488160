import React from "react";
import Sortable from "../../DragnDrop";
import {PictureBookGame} from "../../../model/Game/PictureBook/PictureBookGame";
import {PictureBookSlide} from "../../../model/Game/PictureBook/PictureBookSlide";
import {Translate} from "react-localize-redux";
import {PictureBookSlidePreview} from "./PictureBookSlidePreview";

interface Props{
    game: PictureBookGame;
    onSlideClick?: (quiz: PictureBookSlide, index: number) => void;
    onSort?: (indexToMove: number, destinationIndex: number) => void;
    onSortCommit?: () => void;
}

const PictureBookGamePreview = (props: Props) => {

    const game = props.game;

    return(
        <div className='picturebook-slides'>
            <h2><Translate id='picturebook_slides'/></h2>
            {game && game.slides.length > 0 ?
                <div className='picturebook-slides-container'>
                    {
                        game.slides.map((slide, index) => {
                            return (
                                <Sortable
                                    type='sortable'
                                    key={slide.imageId}
                                    id={slide.imageId}
                                    onSort={(s,t) => {
                                        if(props.onSort){
                                            const n1 = game.slides.findIndex(x => x.imageId === s);
                                            const n2 = game.slides.findIndex(x => x.imageId === t);
                                            props.onSort(n1, n2);
                                        }
                                    }}
                                    onSortCommit={() => props.onSortCommit && props.onSortCommit()}
                                >
                                    <PictureBookSlidePreview
                                        slide={slide}
                                        onClick={() => props.onSlideClick && props.onSlideClick(slide, index)}
                                    />
                                </Sortable>
                            )
                        })
                    }
                    <div className='clear-fix'/>
                </div> :
                <Translate id='picturebook_no_slides' />
            }
        </div>

    )
};

export default PictureBookGamePreview;