import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Bundle } from '../../model/Bundle';
import { BaseGame } from '../../model/Game/BaseGame';
import { PlayListType } from '../../model/PlayListType';
import { SortedItem } from '../../model/SportsWall/SortedItem';
import { isBaseGame, isBundle, isPlaylistType, Wall } from '../../model/SportsWall/Wall';
import { useFetchDelete, useFetchGet } from '../../services/FetchHelper';
import { SortByCustom } from '../../services/SortFunctions';
import { useConfirmDelete } from '../forms/games/ConfirmationForm';
import { SecureLayoutComponentProps } from '../Layout';
import { Loading } from '../Loading';
import BundleIcon from '../ModelPreview/BundleIcon';
import { CustomGameMenu } from '../ModelPreview/CustomGameMenu';
import Game from '../ModelPreview/Game';
import ItemContainer from '../ModelPreview/ItemContainer';
import PlayListPreview from '../ModelPreview/PlayListPreview';

interface Props extends SecureLayoutComponentProps<{ id: string }>{
    
}

const WallMenu = (props: Props) => {
    const {match} = props;

    const [wall, setWall] = useState<Wall>();
    const [getWall, loadingGetWall, errorGetWall] = useFetchGet<Wall>(setWall);
    const [confirmDeleteId, _setConfirmDeleteId] = useState<string>();
    const [deleteItem, loadingDeleteItem, errorDeleteItem] = useFetchDelete<Wall>((w) => {
        setWall(w); 
        _setConfirmDeleteId(undefined);
    });
    const [confirmForm, showConfirmForm] = useConfirmDelete(() => deleteItem(`api/wall/${wall?.id}/item/${confirmDeleteId}`))
    const setConfirmDeleteId = (x: string|undefined) => { _setConfirmDeleteId(x); showConfirmForm() };

    const loading = loadingGetWall || loadingDeleteItem;
    const error = errorGetWall || errorDeleteItem;

    useEffect(() => {
        getWall(`api/wall/${match.params.id}`);
    },[getWall, match.params.id]);

    if (!wall) return <div className='wall-menu'><Loading visible={true} /></div>;

    const combinedItems: Array<SortedItem<BaseGame|PlayListType|Bundle>> = [];
    combinedItems.push(...wall.sortedPlaylists);
    combinedItems.push(...wall.sortedGames);
    combinedItems.push(...wall.sortedBundles);
    combinedItems.sort((a,b) => SortByCustom(a,b, 'sortIndex'));

    return(
        <div className='wall-menu'>
            <h1>{wall.name}</h1>
            <ItemContainer
                heading={<h1><Translate id='wall_menu_items' /></h1>}
                loading={loading}
                error={error}
                items={combinedItems}
                itemRender={(x) => {
                    if(isBaseGame(x.item)) return <Game 
                        key={x.itemId} 
                        game={x.item} 
                        customMenu={
                            <CustomGameMenu
                                items={[
                                    {key: 'edit', icon:'edit', text: "see_content", link: `/workshop/${x.item.gameType}/edit/${x.itemId}`},
                                    {key: 'delete', icon: 'trash', text: 'wall_remove_game', action: () => setConfirmDeleteId(x.itemId)}
                                ]}
                            />
                        } 
                    />
                    if (isBundle(x.item)) return <BundleIcon 
                        key={x.itemId} 
                        bundle={x.item}
                        customMenu={
                            <CustomGameMenu
                                items={[
                                    { key: 'edit', icon: 'edit', text: "see_content", link: `/bundles/details/${x.itemId}` },
                                    { key: 'delete', icon: 'trash', text: 'wall_remove_bundle', action: () => setConfirmDeleteId(x.itemId) }
                                ]}
                            />
                        } 
                    />
                    if (isPlaylistType(x.item)) return <PlayListPreview 
                        key={x.itemId} 
                        playlist={x.item}
                        customMenu={
                            <CustomGameMenu
                                items={[
                                    { key: 'edit', icon: 'edit', text: "see_content", link: `/workshop/playlist/edit/${x.itemId}` },
                                    { key: 'delete', icon: 'trash', text: 'wall_remove_playlist', action: () => setConfirmDeleteId(x.itemId) }
                                ]}
                            />
                        } 
                    />
                    return null;
                }}
            />
            {confirmForm}
        </div>
    )
};

export default WallMenu;