import React from 'react';
import {Translate} from "react-localize-redux";
import {QuizPreview} from "./QuizPreview";
import {DitjGame} from "../../../model/Game/DangerInTheJungle/DitjGame";
import {DitjQuiz} from "../../../model/Game/DangerInTheJungle/DitjQuiz";

interface Props{
    game: DitjGame;
    onQuizClick?: (quiz: DitjQuiz, index: number) => void;
}

export const DitjGamePreview = (props: Props) => {

    const game = props.game;
    const categories = game &&
        game.quizzes.map(x => x.category)
            .filter((value, index, self) => {
                return self.indexOf(value) === index
            });

    return(
        <div className='ditj-quizzes'>
            <h2><Translate id='ditj_categories'/></h2>
            {categories && categories.length > 0 ? categories.map((x,i) => {
                return(
                    <div key={i} className='ditj-category'>
                        <h3 dir='auto'>{x}</h3>
                        <div className='ditj-quizzes-container'>
                            {
                                game && game.quizzes.map((quiz, index) => {
                                    if (quiz.category === x){
                                        return (
                                            <QuizPreview
                                                key={index}
                                                quiz={quiz}
                                                onClick={() => props.onQuizClick && props.onQuizClick(quiz, index)}
                                            />
                                        )
                                    }
                                    else{
                                        return null;
                                    }
                                })
                            }
                            <div className='clear-fix'/>
                        </div>
                    </div>
                );
            }) : <Translate id='ditj_no_categories' />}
        </div>
    )
};