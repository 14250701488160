import React, { useEffect} from 'react';
import * as CircusRaceStore from "../../../store/games/CircusRaceStore";
import * as MyGamesStore from "../../../store/MyGames";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Translate} from "react-localize-redux";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";
import {SecureLayoutComponentProps} from "../../Layout";
import { accountHasAnyPermission } from '../../../model/AccountType';
import { useFetchPost } from '../../../services/FetchHelper';
import { history } from '../../..';
import { Loading } from '../../Loading';
import { FormGeneralError } from '../../Error/FormGeneralError';
import { CircusRaceGame } from '../../../model/Game/CircusRace/CircusRaceGame';
import CircusRaceSettings from "./CircusRaceSettings";

interface Props extends SecureLayoutComponentProps<{id?: string}>{
    gameActions: typeof CircusRaceStore.actionCreators;
    errorState: ErrorState['circusRaceState'];
    gameState: CircusRaceStore.CircusRaceState;
    myGamesActions: typeof MyGamesStore.actionCreators;
}

const CircusRace = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, me, setLayoutButtons} = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])

    const [create, loadingCreate, errorCreate] = useFetchPost<CircusRaceGame>(g => history.push(`/workshop/${g.gameType}/edit/${g.id}`));

    if(errorState.getGame) return <div><Translate id='unknown_error'/></div>;
    const game = gameState.game;

    return(
        <EditGameComponent
            gameState={gameState}
            routeMatch={match}
            gameType={'CircusRace'}
            setLayoutButtons={setLayoutButtons}
            extraButtons={[
                accountHasAnyPermission(me, ["Admin"]) && !routeId
                 ? 
                    {id: 'create', icon: 'plus', hoverMsg: 'circus_race_create', action: () => create(`/api/CircusRace/create`)}
                 : 
                    null
            ]}
        >
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='circus_race_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                    </div>
                }
                tempTitle={<Translate id='circus_race_title'/>}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
            <Loading visible={loadingCreate} />
            <FormGeneralError error={errorCreate} />
            <CircusRaceSettings />
        </EditGameComponent>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[CircusRaceStore.reducerName],
        errorState: state.errors[CircusRaceStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(CircusRaceStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(CircusRace)