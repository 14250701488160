import axios from "axios";
import {createError, errorActions} from "../Error";
import {
    createSmackTheFlyAnswerFormData,
    createSmackTheFlyRoundFormData,
    updateSmackTheFlyAnswerFormData,
    updateSmackTheFlyRoundFormData
} from "../../services/FormDataHelper";
import {abstractGameReducerMethods, AbstractGameState, AbstractGameIsLoading, AbstractGameUi} from "./Games";
import {actionCreators as gamesActions} from './Games';
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import { SmackTheFlyGame } from "../../model/Game/SmackTheFly/SmackTheFlyGame";
import { StfRound } from "../../model/Game/SmackTheFly/StfRound";
import { StfAnswer } from "../../model/Game/SmackTheFly/StfAnswer";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import {ImageUploadType} from "../../model/ImageUploadType";
import {SoundUploadType} from "../../model/SoundUploadType";

export const reducerName = 'smackthefly';

const STF_RESET = 'STF_RESET';

const STF_UPDATE_SORT_REQUEST = 'STF_UPDATE_SORT_REQUEST';
const STF_UPDATE_SORT_RESPONSE = 'STF_UPDATE_SORT_RESPONSE';
const STF_CREATE_ROUND_REQUEST = 'STF_CREATE_ROUND_REQUEST';
const STF_CREATE_ROUND_RESPONSE = 'STF_CREATE_ROUND_RESPONSE';
const STF_CREATE_ANSWER_REQUEST = 'STF_CREATE_ANSWER_REQUEST';
const STF_CREATE_ANSWER_RESPONSE = 'STF_CREATE_ANSWER_RESPONSE';
const STF_UPDATE_ROUND_REQUEST = 'STF_UPDATE_ROUND_REQUEST';
const STF_UPDATE_ROUND_RESPONSE = 'STF_UPDATE_ROUND_RESPONSE';
const STF_UPDATE_ANSWER_REQUEST = 'STF_UPDATE_ANSWER_REQUEST';
const STF_UPDATE_ANSWER_RESPONSE = 'STF_UPDATE_ANSWER_RESPONSE';
const STF_DELETE_ANSWER_REQUEST = 'STF_DELETE_ANSWER_REQUEST';
const STF_DELETE_ANSWER_RESPONSE = 'STF_DELETE_ANSWER_RESPONSE';
const STF_DELETE_ROUND_REQUEST = 'STF_DELETE_ROUND_REQUEST';
const STF_DELETE_ROUND_RESPONSE = 'STF_DELETE_ROUND_RESPONSE';

const STF_SHOW_EDIT_ANSWER = 'STF_SHOW_EDIT_ANSWER';
const STF_SHOW_ANSWER_DELETE = 'STF_SHOW_ANSWER_DELETE';
const STF_SHOW_EDIT_ROUND = 'STF_SHOW_EDIT_ROUND';
const STF_SHOW_ROUND_DELETE = 'STF_SHOW_ROUND_DELETE';
const STF_SHOW_ROUND_HINTSOUND = 'STF_SHOW_ROUND_HINTSOUND';

const STF_SET_ROUND = 'STF_SET_ROUND';
const STF_SET_ROUND_SORT = 'STF_SET_ROUND_SORT';
const STF_SET_ROUND_HINT = 'STF_SET_ROUND_HINT';
const STF_SET_ROUND_TIME = 'STF_SET_ROUND_TIME';
const STF_SET_ROUND_BACKGROUNDIMAGE = 'STF_SET_ROUND_BACKGROUNDIMAGE';
const STF_SET_ROUND_ANNOUNCERIMAGE = 'STF_SET_ROUND_ANNOUNCERIMAGE';
const STF_SET_ROUND_HINTIMAGE = 'STF_SET_ROUND_HINTIMAGE';
const STF_SET_ROUND_HINTSOUND = 'STF_SET_ROUND_HINTSOUND';

const STF_SET_ANSWER = 'STF_SET_ANSWER';
const STF_SET_ANSWER_TEXT = 'STF_SET_ANSWER_TEXT';
const STF_SET_ANSWER_IMAGE = 'STF_SET_ANSWER_IMAGE';
const STF_SET_ANSWER_FONT = 'STF_SET_ANSWER_FONT';
const STF_SET_ANSWER_CORRECT = 'STF_SET_ANSWER_CORRECT';
const STF_SET_ANSWER_CUSTOMPOSITION = 'STF_SET_ANSWER_CUSTOMPOSITION';
const STF_SET_ANSWER_X = 'STF_SET_ANSWER_X';
const STF_SET_ANSWER_Y = 'STF_SET_ANSWER_Y';
const STF_SET_ANSWER_WIDTH = 'STF_SET_ANSWER_WIDTH';
const STF_SET_FIELD = 'STF_SET_FIELD';


export interface StfEditAnswer extends StfAnswer{
    new: boolean;
    index: number;
    _o: StfAnswer;
}

interface EditRound extends StfRound{
    new: boolean;
    index: number;
    _o: StfRound;
}

export interface SmackTheFlyState extends AbstractGameState {
    game?: SmackTheFlyGame;
    round: EditRound;
    answer: StfEditAnswer;
    isLoading: AbstractGameIsLoading & {
        sortRounds: boolean; createRound: boolean; copyRound: boolean; updateRound: boolean; deleteRound: boolean;
        createAnswer: boolean; updateAnswer: boolean; deleteAnswer: boolean;
    };
    ui: AbstractGameUi & {
        editRound: boolean; deleteRound: boolean; editAnswer: boolean; deleteAnswer: boolean;
        roundHintSound: boolean;
    };
}

const initialState: SmackTheFlyState = {
    game: undefined,
    gameStats: undefined,
    gameType: "SmackTheFly",
    round: {
        answers: [],
        new: true,
        index : -1,
        hint: "",
        time: 20,
        backgroundImage: null,
        announcerImage: null,
        hintImage: null,
        hintSound: null,
        _o: {
            answers: [],
            hint: "",
            time: 20,
            backgroundImage: null,
            announcerImage: null,
            hintImage: null,
            hintSound: null
        }
    },
    answer: {
        new: true,
        index: -1,
        text: "",
        font: "Lato",
        customPosition: false,
        x: 0,
        y: 0,
        width: 200,
        image: null,
        correct: false,
        _o:{
            text: "",
            font: "Lato",
            customPosition: false,
            x: 0,
            y: 0,
            width: 200,
            image: null,
            correct: false,
        }
    },
    isLoading: {
        getGame: false, sortRounds: false, createRound: false, copyRound: false, updateRound: false,
        deleteRound: false, createAnswer: false, updateAnswer: false, deleteAnswer: false, updateSettings: false,
        transferOwner: false, removeAuthor: false, addAuthor: false
    },
    ui: {
        showSettings: false, editRound: false, deleteRound: false, editAnswer: false, deleteAnswer: false,
        roundHintSound: false,
    }
};

export const actionCreators = {
    setField: (e: ChangeEvent<string>|ChangeEvent<boolean>|ChangeEvent<number>): TA => async(dispatch) => {
        dispatch({type: STF_SET_FIELD, name: e.target.name, value: e.target.value});
    },
    reset: (): TA => async(dispatch) => {
        dispatch({type: STF_RESET});
    },
    getGame: (id: string): TA => async (dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'SmackTheFly'));
    },
    deleteRound: (roundIndex: number): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;

        dispatch({type: STF_DELETE_ROUND_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/smackthefly/${game.id}/round/${roundIndex}`;
        
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: STF_DELETE_ROUND_RESPONSE, data});
                dispatch({type: STF_SET_ROUND, round: initialState.round});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteRound', error));
            })
    },
    deleteAnswer: (roundIndex: number, answerIndex: number): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;

        dispatch({type: STF_DELETE_ANSWER_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/smackthefly/${game.id}/round/${roundIndex}/answer/${answerIndex}`;
        
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: STF_DELETE_ANSWER_RESPONSE, data: data});
                const round = data.rounds[roundIndex];
                round.index = roundIndex;
                dispatch({type: STF_SET_ROUND, round: round});
                dispatch({type: STF_SET_ANSWER, answer:initialState.answer});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteAnswer', error));
            })
    },
    createAnswer: (roundIndex: number, answer: StfEditAnswer): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;
        dispatch({type: STF_CREATE_ANSWER_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/smackthefly/${game.id}/round/${roundIndex}/answer`;
        const data = createSmackTheFlyAnswerFormData(answer);

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: STF_CREATE_ANSWER_RESPONSE, data: data});
                const round = {
                    ...data.rounds[roundIndex],
                    index: roundIndex,
                    _o: {
                        ...data.rounds[roundIndex]
                    }
                };
                dispatch({type: STF_SET_ROUND, round: round});
                dispatch({type: STF_SET_ANSWER, answer:initialState.answer});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createAnswer', error));
            })
    },
    updateAnswer: ( roundIndex: number, answer: StfEditAnswer): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;

        dispatch({type: STF_UPDATE_ANSWER_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/smackthefly/${game.id}/round/${roundIndex}/answer/${answer.index}`;
        const data = updateSmackTheFlyAnswerFormData(answer);
        
        axios.put(url,  data,  CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: STF_UPDATE_ANSWER_RESPONSE, data: data});
                const round = data.rounds[roundIndex];
                round.index = roundIndex;
                dispatch({type: STF_SET_ROUND, round: round});
                dispatch({type: STF_SET_ANSWER, answer:initialState.answer});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateAnswer', error));
            })
    },
    createRound: (round: EditRound, close: boolean): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        const gameId = game && game.id;
        dispatch({type: STF_CREATE_ROUND_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = 'api/workshop/smackthefly/round';
        const data = createSmackTheFlyRoundFormData(round, gameId);

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                if(!gameId) history.push(`/workshop/smackthefly/edit/${response.data.id}`);
                const round: EditRound = {
                    ...data.rounds[data.rounds.length-1],
                    index: data.rounds.length-1,
                    _o: {
                        ...data.rounds[data.rounds.length-1]
                    }
                };
                dispatch({type: STF_CREATE_ROUND_RESPONSE, data: data, round: round});
                if (close) dispatch({type: STF_SHOW_EDIT_ROUND, show: false});
                else dispatch(actionCreators.setEditAnswer(initialState.answer, -1));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createRound', error));
            });
    },
    updateRound: (round: EditRound, close: boolean): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;
        dispatch({type: STF_UPDATE_ROUND_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/smackthefly/round/${round.index}`;
        const data = updateSmackTheFlyRoundFormData(round, game.id);
        
        axios.put(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: STF_UPDATE_ROUND_RESPONSE, data: data});
                if (close) dispatch({type: STF_SHOW_EDIT_ROUND, show: false});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateRound', error));
            });
    },
    setRoundBackgroundImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: STF_SET_ROUND_BACKGROUNDIMAGE, value: image});
    },
    setRoundAnnouncerImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: STF_SET_ROUND_ANNOUNCERIMAGE, value: image});
    },
    setRoundHintImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: STF_SET_ROUND_HINTIMAGE, value: image});
    },
    setRoundHintSound: (sound: SoundUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: STF_SET_ROUND_HINTSOUND, value: sound});
        dispatch(actionCreators.showRoundHintSound(false));
    },
    setRoundHint: (text: string): TA => async(dispatch) => {
        dispatch({type: STF_SET_ROUND_HINT, value:text });
    },
    setRoundTime: (number: number): TA => async(dispatch) =>  {
        dispatch({type: STF_SET_ROUND_TIME, number:number});
    },
    setAnswerImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: STF_SET_ANSWER_IMAGE, value: image});
    },
    setAnswerText: (text: string): TA => async(dispatch) => {
        dispatch({type: STF_SET_ANSWER_TEXT, value:text});
    },
    setAnswerFont: (font: string): TA => async(dispatch) => {
        dispatch({type: STF_SET_ANSWER_FONT, value:font});
    },
    setCustomPosition: (value: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SET_ANSWER_CUSTOMPOSITION, value: value});
    },
    setAnswerX: (value: number): TA => async(dispatch) => {
        dispatch(errorActions.resetError(reducerName, 'setAnswerX'));
        if (value > 1180) dispatch(errorActions.reportError('smackthefly', 'setAnswerX', createError('X', 'smackthefly_x_error')));
        if (value < -100) dispatch(errorActions.reportError('smackthefly', "setAnswerX", createError('X', 'smackthefly_x_error')));
        dispatch({type: STF_SET_ANSWER_X, value:value});
    },
    setAnswerY: (value: number): TA => async(dispatch) => {
        dispatch(errorActions.resetError( 'smackthefly', "setAnswerY"));
        if (value > 700) dispatch(errorActions.reportError( 'smackthefly', "setAnswerY", createError('Y', 'smackthefly_y_error')));
        if (value < -100) dispatch(errorActions.reportError( 'smackthefly', "setAnswerY", createError('Y', 'smackthefly_y_error')));
        dispatch({type: STF_SET_ANSWER_Y, value: value});
    },
    setAnswerWidth: (value: number): TA => async(dispatch) => {
        dispatch(errorActions.resetError("smackthefly", "setAnswerWidth"));
        if (value > 500) dispatch(errorActions.reportError( 'smackthefly', "setAnswerWidth", createError('Width', 'smackthefly_width_error')));
        if (value < 25) dispatch(errorActions.reportError( 'smackthefly', "setAnswerWidth", createError('Width', 'smackthefly_width_error')));
        dispatch({type: STF_SET_ANSWER_WIDTH, value: value});
    },
    setCorrect: (correct: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SET_ANSWER_CORRECT, value:correct});
    },
    setEditRound: (round: StfRound | null, index: number): TA => async(dispatch,  getState) => {
        if (round == null) round = initialState.round;
        const currentEdit = getState().smackthefly.round;
        if (currentEdit.index === index){
            round = currentEdit;
        }
        if(round === null) return;

        const er: EditRound = {
            ...round,
            index: index,
            new: index < 0,
            _o:{
                ...round
            }
        };

        dispatch({type: STF_SET_ROUND, round: er});
        dispatch({type: STF_SET_ANSWER, answer: initialState.answer});
        dispatch({type: STF_SHOW_EDIT_ROUND, show: true});
    },
    newAnswer: (): TA => async(dispatch, getState) => {
        const round = getState().smackthefly.round;
        if (round.new){
            dispatch(actionCreators.createRound(round, false));
        } 
        else{
            dispatch(actionCreators.setEditAnswer(null, -1));
        }
    },
    setEditAnswer: (answer: StfAnswer | null, index: number): TA => async(dispatch, getState) => {
        if (answer == null) answer = initialState.answer;
        const currentEdit = getState().smackthefly.answer;
        if (currentEdit.index === index){
            answer = currentEdit;
        }
        if(answer === null) return;
        const ea: StfEditAnswer = {
            ...answer,
            index: index,
            new: index < 0,
            _o:{
                ...answer
            }
        };
        dispatch({type: STF_SET_ANSWER, answer: ea});
        dispatch({type: STF_SHOW_EDIT_ANSWER, show: true});
    },
    updateSortCommit: (): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;

        dispatch({type: STF_UPDATE_SORT_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/smackthefly/${game.id}/roundsort`;
        const data = {rounds: game.rounds};

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: STF_UPDATE_SORT_RESPONSE, data: data});
                dispatch({type: STF_SET_ROUND, round:initialState.round});
            })
            .catch(error => dispatch(errorActions.reportAxiosError(reducerName, 'updateSortCommit', error)))
    },
    showConfirmDeleteAnswer: (show: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SHOW_ANSWER_DELETE, show:show});
    },
    showConfirmDeleteRound: (show: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SHOW_ROUND_DELETE, show:show});
    },
    showRoundHintSound: (show: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SHOW_ROUND_HINTSOUND, show:show});
    },
    showEditRound: (show: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SHOW_EDIT_ROUND, show: show});
    },
    showEditAnswer: (show: boolean): TA => async(dispatch) => {
        dispatch({type: STF_SHOW_EDIT_ANSWER, show:show});
    },
    setSort: (sourceIndex: number, targetIndex: number): TA => async(dispatch, getState) => {
        const game = getState().smackthefly.game;
        if(!game) return;

        const currentArray = [...game.rounds];
        const itemSource = {...currentArray[sourceIndex]};
        const itemTarget = {...currentArray[targetIndex]};

        currentArray[sourceIndex] = itemTarget;
        currentArray[targetIndex] = itemSource;

        dispatch({type: STF_SET_ROUND_SORT, array: currentArray});
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: SmackTheFlyState, action: any) => SmackTheFlyState} = {
    ...abstractGameReducerMethods,
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            round: initialState.round,
            answer: initialState.answer
        }
    },
    STF_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    STF_RESET: () => {
        return initialState;
    },

    STF_DELETE_ANSWER_REQUEST: (state) => {
        return {
            ...state, 
            isLoading: {
                ...state.isLoading, 
                deleteAnswer: true
            }
        }
    },
    STF_DELETE_ANSWER_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editAnswer: false
            },
            isLoading: {
                ...state.isLoading,
                deleteAnswer: false
            }
        }
    },
    
    STF_DELETE_ROUND_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                deleteRound: true
            }
        }
    },
    STF_DELETE_ROUND_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editRound: false
            },
            isLoading: {
                ...state.isLoading, 
                deleteRound: false
            }
        }
    },
    
    STF_CREATE_ROUND_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                createRound: true,
            }
        };
    },
    STF_CREATE_ROUND_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            round: action.round,
            isLoading: {
                ...state.isLoading,
                createRound: false,
            }
        };
    },

    STF_UPDATE_ANSWER_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading,
                updateAnswer: true
            }
        }
    },
    STF_UPDATE_ANSWER_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editAnswer: false
            },
            isLoading:{
                ...state.isLoading,
                updateAnswer: false
            }
        }
    },
    
    STF_UPDATE_ROUND_REQUEST: (state) => {
        return{
            ...state, 
            isLoading: {
                ...state.isLoading, 
                updateRound: true
            }
        }
    },
    STF_UPDATE_ROUND_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                updateRound: false
            }
        }
    },
    STF_UPDATE_SORT_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                sortRounds: true,
            }
        };
    },
    STF_UPDATE_SORT_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            roundsTempSort: [],
            isLoading: {
                ...state.isLoading,
                sortRounds: false,
            }
        };
    },

    STF_SHOW_ROUND_BACKGROUNDIMAGE: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                roundBackgroundImage: action.show
            }
        };
    },
    STF_SHOW_ROUND_HINTIMAGE: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                roundHintImage: action.show
            }
        };
    },
    STF_SHOW_ROUND_ANNOUNCERIMAGE: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                roundAnnouncerImage: action.show
            }
        };
    },
    STF_SHOW_ROUND_HINTSOUND: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                roundHintSound: action.show
            }
        };
    },

    STF_SHOW_EDIT_ANSWER: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui,
                editAnswer: action.show
            }
        };
    },
    STF_SHOW_ANSWER_IMAGE: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui, 
                answerImage: action.show
            }
        };
    },
    
    STF_SHOW_EDIT_ROUND: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                editRound: action.show
            }
        };
    },
    STF_SHOW_ANSWER_DELETE: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                deleteAnswer: action.show
            }
        }
    },
    STF_SHOW_ROUND_DELETE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                deleteRound: action.show
            }
        }
    },

    STF_SET_ROUND: (state, action) => {
        return{
            ...state, 
            round: action.round,
        }
    },
    
    STF_SET_ROUND_HINT: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                hint: action.value
            }
        };
    },
    
    STF_SET_ROUND_TIME: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                time: action.number
            }
        };
    },
    STF_SET_ROUND_BACKGROUNDIMAGE: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                backgroundImage: action.value
            }
        };
    },
    STF_SET_ROUND_ANNOUNCERIMAGE: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                announcerImage: action.value
            }
        };
    },
    STF_SET_ROUND_HINTIMAGE: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                hintImage: action.value
            }
        };
    },
    STF_SET_ROUND_HINTSOUND: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                hintSound: action.value
            }
        };
    },

    STF_SET_ROUND_SORT: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game: {
                ...state.game,
                rounds: action.array
            }
        };
    },

    STF_SET_ANSWER_TEXT: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                text: action.value
            }
        };
    },
    STF_SET_ANSWER_FONT: (state, action) => {
        return{
            ...state, 
            answer: {
                ...state.answer, 
                font: action.value
            }
        };
    },
    STF_SET_ANSWER_IMAGE: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                image: action.value
            }
        };
    },
    STF_SET_ANSWER_CORRECT: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer, 
                correct: action.value
            }
        };
    },
    STF_SET_ANSWER_CUSTOMPOSITION: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                customPosition: action.value
            }
        };
    },
    STF_SET_ANSWER_X: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                x: action.value
            }
        };
    },
    STF_SET_ANSWER_Y: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                y: action.value
            }
        }
    },
    STF_SET_ANSWER_WIDTH: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                width: action.value
            }
        }
    },
    STF_SET_ANSWER: (state, action) => {
        return{
            ...state, 
            answer: action.answer
        };
    },

    STF_CREATE_ANSWER_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                createAnswer: true,
            }
        }
    },
    STF_CREATE_ANSWER_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editAnswer: false,
            },
            isLoading:{
                ...state.isLoading,
                createAnswer: false,
            }
        }
    },
   
    ERROR: (state, action) => {
        if (action.reducer !== 'smackthefly') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<SmackTheFlyState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};