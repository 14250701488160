import React, { useEffect } from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as OrganizationStore from "../../../store/OrganizationStore";
import {DictGetValues} from "../../../services/JsDict";
import {AppState} from '../../../store/configureStore';
import {isOrganizationType, Organization} from '../../../model/Organization';
import {accountHasAnyPermission, accountIsAdmin, AccountType} from "../../../model/AccountType";
import OrganizationList from "../../Organization/OrganizationList";

interface Props {
    orgActions: typeof OrganizationStore.actionCreators;
    myOrg?: Organization;
    allOrganizations: Array<Organization>;
    orgsLoading?: boolean;
    me: AccountType | null;
    onChoose: (org: Organization) => void;
    onClose: (visible: boolean) => void;
    visible: boolean;
}

const OrganizationChooser = (props: Props) => {
    const { onChoose: _onChoose, onClose, orgActions, myOrg, allOrganizations, me, visible, orgsLoading} = props;
    const onChoose = (org: Organization) => {_onChoose(org); onClose(false);};

    useEffect(() => {
        //Load organizations if there is none, they are not already loading, and the chooser is visble
        if(allOrganizations.length < 1 && !orgsLoading && visible){
            orgActions.getAll(false);
        }
    }, [orgActions, allOrganizations, orgsLoading, visible])

    if (!me) return null;
    if (!visible) return null;

    let editableOrganizations: Array<Organization> = [];
    if (accountIsAdmin(me)){
        editableOrganizations = allOrganizations;
    }
    else if (accountHasAnyPermission(me, ["ChildOrganizations"]) && isOrganizationType(myOrg)){
        editableOrganizations =
            allOrganizations.filter(x => x.id === myOrg.id || myOrg.childOrganizationIds.includes(x.id));
    }

    return(
        <Dialog className='organization-chooser-dialog' onClose={() => onClose(false)}>
            <div className='organization-container'>
                <OrganizationList
                    organizations={editableOrganizations}
                    orgEditClick={onChoose}
                />
                <div className='clear-fix'/>
            </div>
        </Dialog>
    );
};

export default connect(
    (state: AppState) => ({ 
        myOrg: state.me.account ? state.organization.organizations[state.me.account.organizationId] as Organization : undefined,
        allOrganizations: DictGetValues(state.organization.organizations).filter(x => isOrganizationType(x)) as Array<Organization>,
        orgsLoading: state.organization.loading.getAll,
        me: state.me.account 
    }),
    dispatch => ({
        orgActions: bindActionCreators(OrganizationStore.actionCreators, dispatch)
    })
)(OrganizationChooser);