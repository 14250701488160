import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import {AgeRangeSelect, SelectFromPool, SelectList, SelectMultipleLanguage, TagInputField} from "../forms/FormGroup";
import {courseList} from "../../model/Subject";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import * as LibraryStore from "../../store/LibraryStore";
import {bindActionCreators} from "redux";
import {GamesState} from "../../store/games/Games";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GameTypesMyWall} from "../../model/Game/GameType";
import {BaseGame} from "../../model/Game/BaseGame";

interface LibraryFiltersProps{
    libraryState: LibraryStore.LibraryState;
    gamesState: GamesState;
    libraryActions: typeof LibraryStore.actionCreators;
    action: () => void;
}

interface LibraryFiltersState{
    minimized: boolean;
    languages: string[];
    subjects: string[];
    gameTypes: string[];
    tags: string[];
    minAge: number;
    maxAge: number;
    sort: {
        key: keyof BaseGame;
        ascending: boolean;
    };
    showSort?: boolean;
}

class LibraryFilters extends Component<LibraryFiltersProps, LibraryFiltersState>
{
    constructor(props: LibraryFiltersProps){
        super(props);

        this.state = {
            minimized: true,
            languages: props.libraryState.gameFilters.languages,
            subjects: props.libraryState.gameFilters.subjects,
            gameTypes: props.libraryState.gameFilters.gameTypes,
            minAge: props.libraryState.gameFilters.minAge,
            maxAge: props.libraryState.gameFilters.maxAge,
            tags: props.libraryState.gameFilters.tags,
            sort: {key: props.libraryState.gameFilters.sort.key, ascending: props.libraryState.gameFilters.sort.ascending}
        }
    }

    render(){
        const state= this.state;
        return(
            <div className='filters-container'>
                {this.state.minimized &&
                <div className='minimized' onClick={() => this.setState({minimized: false})}>
                    <div className='buttons'>
                        <Translate id={'filters_adjust'}/><FontAwesomeIcon icon='sliders-h'/>
                    </div>
                    <span className='filters'>
                        <div className='filter taglist'>
                            <span className='float'><b><Translate id={'language_a'}/>:</b></span>
                            <span>&nbsp;
                                {
                                    this.state.languages.length > 0 ?
                                        this.state.languages.map(x => <span key={x} className='tag language'>{x.replace(/^\w/, c => c.toUpperCase())}</span>) :
                                        <span className='tag language'><Translate id='all'/></span>
                                }
                            </span>
                        </div>
                        <div className='filter taglist'>
                            <span className='float'><b><Translate id={'subject'}/>:</b></span>
                            <span>&nbsp;
                                {
                                    this.state.subjects.length > 0 ?
                                    this.state.subjects.map(x =>
                                        <span key={x} className='tag subject'>
                                            { courseList.findIndex(c => x === c) !== -1 ?
                                                <Translate id={x}/> :
                                                x
                                            }
                                        </span>) :
                                        <span className='tag subject'><Translate id='everything' /></span>
                                }
                            </span>
                        </div>
                        <div className='filter taglist'>
                            <span className='float'><b><Translate id={'gametypes'}/>:</b></span>
                            <span>&nbsp;
                                {
                                    this.state.gameTypes.length > 0 ?
                                        this.state.gameTypes.map(x =>
                                            <span key={x} className='tag gametype'>
                                                <Translate id={`gametype_${x}`}/>
                                        </span>) :
                                        <span className='tag gametype'><Translate id='everything' /></span>
                                }
                            </span>
                        </div>
                        <div className='filter taglist'>
                            <span className='float'><b><Translate id={'agegroup'}/>:</b></span>
                            <span className='float'>
                                <div className='tag years'>
                                   &nbsp;{this.state.minAge} - {this.state.maxAge}<Translate id={'years_old'}/>
                                </div>
                                <div className='clear-fix'/>
                              </span>
                            <div className='clear-fix'/>
                        </div>
                         <div className='filter taglist'>
                             <span className='float'><b><Translate id={'tags'}/>:</b></span>
                             <span className='float'>
                                 {this.state.tags.map(x => <div key={x} className='tag'>{x.replace(/^\w/, c => c.toUpperCase())}</div>)}
                                 <div className='clear-fix'/>
                             </span>
                        </div>
                    </span>
                    <div className='clear-fix'/>
                </div>

                }
                {!this.state.minimized &&
                        <div className='maximized'>
                            <div className='filter'>
                                <SelectMultipleLanguage
                                    labelName={"language_a"}
                                    labelWidth100
                                    name={'languageCode'}
                                    selected={this.state.languages}
                                    onChange={(e) => {this.setState({languages: e.target.value})}}
                                />
                            </div>
                            <div className='filter'>
                                <SelectList
                                    options={[
                                        {name: "lang_course_choose", value: "", translate: true},
                                        ...this.props.gamesState.languageCodes.map(l => {
                                            return {name: l.name, value: l.value, translate: false}
                                        })
                                    ]}
                                    name={'subject'}
                                    onChange={e => {
                                        if(!e.target.value) return;
                                        const newSub = [...this.state.subjects, e.target.value];
                                        this.setState({subjects: newSub});
                                    }}
                                    skipAutoSelect
                                    labelName='subject'
                                    labelWidth100
                                />
                                <SelectFromPool
                                    noLabel
                                    pool={[
                                        {name: 'course_choose', value: ''},
                                        ...courseList.map(x => {return {name: x, value: x}})
                                    ]}
                                    name={'subject'}
                                    selected={this.state.subjects}
                                    onChange={(e) => {this.setState({subjects: e.target.value})}}
                                    translateName
                                    translateValue
                                    subjectTranslateHack
                                    tagClass='subject'
                                />
                            </div>
                            <div className='filter'>
                                <SelectFromPool
                                    name='gameTypes'
                                    placeholder={'gametype_choose'}
                                    onChange={e => this.setState({gameTypes: e.target.value})}
                                    translatePre={"gametype_"}
                                    pool={GameTypesMyWall.map(gt => {return {name: gt, value: gt}})}
                                    selected={this.state.gameTypes || []}
                                    labelName='gametypes'
                                    tagClass='gametype'
                                    labelWidth100
                                />
                            </div>
                            <div className='filter'>
                                <TagInputField
                                    name={'tags'}
                                    type={'text'}
                                    value={`${this.state.tags.join(",")},`}
                                    onChange={(e) => this.setState({
                                        tags: e.target.value
                                            .split(",")
                                            .filter(x => x !== '')
                                    })}
                                    placeholder={'tags_placeholder'}
                                    labelWidth100
                                />
                            </div>
                            <div className='filter'>
                                <AgeRangeSelect
                                    labelName='agegroup_choose'
                                    labelWidth100
                                    minAge={this.state.minAge}
                                    maxAge={this.state.maxAge}
                                    onChange={event => {
                                        if(event.target.name === 'minAge'){
                                            this.setState({minAge: event.target.value});
                                        }
                                        else if(event.target.name === 'maxAge'){
                                            this.setState({maxAge: event.target.value});
                                        }
                                    }}
                                />
                            </div>
                            <div className='clear-fix'/>
                            <button className='btn' onClick={() => {
                                this.setState({minimized: true});
                                this.props.libraryActions.setGameFilters({
                                    languages: this.state.languages,
                                    subjects: this.state.subjects,
                                    minAge: this.state.minAge,
                                    maxAge: this.state.maxAge,
                                    gameTypes: this.state.gameTypes,
                                    tags: this.state.tags,
                                    sort: this.state.sort
                                })
                                this.props.action();
                            }}>
                                <Translate id={'search'}/>
                            </button>
                            <button className='btn' onClick={() => {
                                this.setState({
                                    minimized: true,
                                    languages: this.props.libraryState.gameFilters.languages,
                                    subjects: this.props.libraryState.gameFilters.subjects,
                                    minAge: this.props.libraryState.gameFilters.minAge,
                                    maxAge: this.props.libraryState.gameFilters.maxAge,
                                    gameTypes: this.props.libraryState.gameFilters.gameTypes
                                });
                            }}>
                                <Translate id={'cancel'}/>
                            </button>
                            <div className='sort-container'>
                                <Translate id='sort' />
                                <div className='flex'>
                                    <SelectList
                                        options={[
                                            {name: 'title', value: 'title'},
                                            {name: 'description', value: 'description'},
                                            {name: 'gametypes', value: 'gameType'},
                                            {name: 'createdDateTime', value: 'createdDateTime'},
                                            {name: 'editedDateTime', value: 'editedDateTime'},
                                            {name: 'language_a', value: "languageName"},
                                            {name: 'subject', value: "courseName"}
                                        ]}
                                        name={'sort'}
                                        noLabel
                                        defaultValue={state.sort.key}
                                        onChange={(e) => this.setState({sort: {...this.state.sort, key: e.target.value as keyof BaseGame}})}
                                        translate
                                    />
                                    <span className='asc' onClick={() => this.setState({sort: {...this.state.sort, ascending: !state.sort.ascending}})}>
                                        <FontAwesomeIcon icon={state.sort.ascending ? 'sort-amount-up' : 'sort-amount-down'} />
                                    </span>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default connect(
    (state: AppState) => ({
        libraryState: state[LibraryStore.reducerName],
        gamesState: state.gamesState
    }),
    dispatch => ({
        libraryActions: bindActionCreators(LibraryStore.actionCreators, dispatch),
    })
)(LibraryFilters)