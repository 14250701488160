import React from 'react';
import Dialog from "../../Dialog";
import { connect } from "react-redux";
import * as GamesStore from "../../../store/games/Games";
import * as JigsawStore from '../../../store/games/JigsawPuzzleStore';
import { bindActionCreators } from "redux";
import { SelectList, SubmitButton } from "../../forms/FormGroup";
import { Translate } from "react-localize-redux";
import { AppState } from "../../../store/configureStore";

interface Props {
    jigsawState: JigsawStore.JigsawPuzzleState;
    gamesActions: typeof GamesStore.actionCreators;
    jigsawActions: typeof JigsawStore.actionCreators;
}

const SmackTheFlySettings = (props: Props) => {
    if (!props.jigsawState.ui.showSettings) return null;
    const game = props.jigsawState.game;
    if (!game) return null;
    const settings = {
        skin: game.skin || 'Default'
    };

    const skinOptions = [
        { name: 'Default', value: 'Default' },
    ];

    return (
        <Dialog className='settings' onClose={() => { props.gamesActions.showSettings(game.gameType, false) }} loading={props.jigsawState.isLoading.updateSettings} >
            <h2><Translate id='settings' /></h2>
            <form onSubmit={e => e.preventDefault()}>
                <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.jigsawActions.setField} />
                <h3><Translate id='preview_skin' /></h3>
                <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
                <div className='clear-fix' />
                <SubmitButton
                    text='submit'
                    onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ jigsawState: state.jigsawPuzzleState }),
    dispatch => ({
        jigsawActions: bindActionCreators(JigsawStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(SmackTheFlySettings);