import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Translate} from "react-localize-redux";
import {actionCreators as ditjActions, DangerInTheJungleState} from '../../../store/games/DangerInTheJungle';
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {connect} from "react-redux";
import Question from "./Question";
import GameDetailed from "../../ModelPreview/GameDetailed";
import DITJSettings from "./DITJSettings";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import {DitjGamePreview} from "./DitjGamePreview";
import {LayoutComponentProps} from "../../Layout";
import {ErrorState} from "../../../store/Error";
import RenameCategory from "./RenameCategory";

interface State{
    showCategoriesRename?: boolean;
}

interface Props extends LayoutComponentProps<{id: string}>{
    ditjActions: typeof ditjActions;
    myGamesActions: typeof myGamesActions;
    ditjState: DangerInTheJungleState;
    errors: ErrorState['ditj'];
}

class DangerInTheJungle extends Component<Props, State> {
    
    constructor(props: Props){
        super(props);
        this.props.ditjActions.resetGame();
        this.state = {};
    }
    
    render(){
        const props = this.props;
        if(this.props.errors.getGame) return <div><Translate id='unknown_error'/></div>;
        const game = props.ditjState.game;
        const categories = game && game.quizzes.map(x => x.category).filter((value, index, self) => {return self.indexOf(value) === index});

        return(
            <EditGameComponent
                gameState={props.ditjState}
                newGameElementFunction={() => props.ditjActions.showNewQuizForm()}
                newGameElementDisabled={false}
                newGameElementText={'ditj_create_question'}
                routeMatch={props.match}
                gameType={'DangerInTheJungle'}
                setLayoutButtons={props.setLayoutButtons}
                extraButtons={[{action: () => this.setState({showCategoriesRename:  true}), icon: 'exchange-alt', id: 'rename-cat', hoverMsg: 'ditj_rename_category'}]}
            >
                <GameDetailed
                    game={game}
                    tempDescription={
                        <div>
                            <p><Translate id='ditj_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/en/portfolio-item/danger-in-the-jungle/"><Translate id='ditj_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='ditj_title'/>}
                    onClick={() => this.props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.ditjState.gameType}
                    gameStats={props.ditjState.gameStats}
                />
                <div className='clear-fix' />
                {game &&
                    <DitjGamePreview
                        game={game}
                        onQuizClick={(quiz, index) => this.props.ditjActions.openEditQuiz(quiz, index)}
                    />
                }
                <DITJSettings/>
                <Question categories={categories}/>
                {this.state.showCategoriesRename &&
                <RenameCategory
                    onClose={() => this.setState({showCategoriesRename: undefined})}
                    onSubmit={(dict) => props.ditjActions.renameCategories(dict)}
                    loading={props.ditjState.isLoading.renameCategories}
                    errors={props.errors.renameCategories}
                    initialCategories={categories}
                />
                }
            </EditGameComponent>
        );
    }
}

export default connect(
    (state: AppState) => ({
        ditjState: state.ditj,
        errors: state.errors.ditj,
    }),
    dispatch => ({
        ditjActions: bindActionCreators(ditjActions, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch)
    })
)(DangerInTheJungle)