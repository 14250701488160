import React, {Component} from 'react';
import Dialog from "../Dialog";
import {GameStats} from "../../model/GameStats";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import {Translate} from "react-localize-redux";
import Game from "../ModelPreview/Game";

interface Props{
    stats?: GameStats;
}

interface State{
    showPlaylistDialog: boolean;
    showSimilarDialog: boolean;
}

export class GameRelatedInfo extends Component<Props, State>{

    constructor(props: Props) {
        super(props);

        this.state = {
            showPlaylistDialog: false,
            showSimilarDialog: false
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if(this.props.stats === undefined && this.props.stats !== prevProps.stats){
            this.setState({showSimilarDialog: false, showPlaylistDialog: false});
        }
    }

    render(){
        if(!this.props.stats) return false;
        const stats = this.props.stats;
        return(
            <div className='game-related'>
                <div>
                    <h2><Translate id='info' /></h2>
                    <div className='group btn' onClick={() => this.setState({showPlaylistDialog: true})}>
                        <label><Translate id='playlists' /></label>
                        <span>{stats.playlistCount}</span>
                    </div>
                    <div className='group btn' onClick={() => this.setState({showSimilarDialog: true})}>
                        <label><Translate id='more_like_this' /></label>
                        <span>{stats.moreLikeThis.length}</span>
                    </div>
                </div>

                {this.state.showSimilarDialog &&
                <Dialog onClose={() => this.setState({showSimilarDialog: false})}>
                    <div className='flex-list'>
                        {stats.moreLikeThis.map(x => <Game key={x.id} game={x} />)}
                    </div>
                </Dialog>
                }
                {this.state.showPlaylistDialog &&
                <Dialog onClose={() => this.setState({showPlaylistDialog: false})}>
                    <h1><Translate id={'playlists'}/></h1>

                    <h2><Translate id='isPublic' /> <span className='count'>({stats.publicPlaylists.length})</span></h2>
                    {this.props.stats.publicPlaylists.length > 0 &&
                    <div className='playlist-list'>
                        {this.props.stats.publicPlaylists.map(x => <PlayListPreview key={x.id} playlist={x} />)}
                    </div>
                    }

                    { stats.playlistCount - stats.publicPlaylists.length !== 0 &&
                        <div>
                            <h2><Translate id='notPublic' /> <span className='count'>({stats.playlistCount - stats.publicPlaylists.length})</span></h2>
                        </div>
                    }
                </Dialog>
                }
            </div>
        )
    }
}