import React, {Component} from 'react';
import {actionCreators as pbActions, PictureBookState} from "../../../store/games/PictureBook";
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Translate} from "react-localize-redux";
import SlideEditForm from "./SlideEditForm";
import GameDetailed from "../../ModelPreview/GameDetailed";
import * as AccountStore from "../../../store/Account";
import * as GamesStore from "../../../store/games/Games";
import PictureBookSettings from "./PictureBookSettings";
import EditGameComponent from '../../EditGameComponent';
import {Loading} from '../../Loading';
import {AppState} from "../../../store/configureStore";
import PictureBookGamePreview from "./PictureBookGamePreview";
import {LayoutComponentProps} from "../../Layout";
import {ErrorState} from "../../../store/Error";

interface Props extends LayoutComponentProps<{id: string}>{
    pbActions: typeof pbActions;
    myGamesActions: typeof myGamesActions;
    errors: ErrorState['picturebook'];
    picturebook: PictureBookState;
}

class PictureBook extends Component<Props> {
    
    constructor(props: Props){
        super(props);
        props.pbActions.resetGame();
    }
    
    render(){
        const props = this.props;
        const game = this.props.picturebook.game;
        
        return(
            <EditGameComponent
                gameState={props.picturebook}
                newGameElementFunction={() => props.pbActions.openSlideForEdit(null, -1)}
                newGameElementText={'picturebook_create_slide'}
                routeMatch={props.match}
                gameType={'PictureBook'}
                setLayoutButtons={props.setLayoutButtons}
            >
                <Loading visible={props.picturebook.isLoading.updateSort} />
                <GameDetailed
                    game={game}
                    tempDescription={
                        <div>
                            <p><Translate id='picturebook_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/da/portfolio-item/billedbog/"><Translate id='picturebook_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='picturebook_title'/>}
                    onClick={() => this.props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.picturebook.gameType}
                    gameStats={props.picturebook.gameStats}
                />
                <div className='clear-fix' />
                {game &&
                    <PictureBookGamePreview
                        game={game}
                        onSort={this.props.pbActions.updateSort}
                        onSortCommit={this.props.pbActions.updateSortCommit}
                        onSlideClick={(slide, index) => {this.props.pbActions.openSlideForEdit(slide, index)}}
                    />
                }
                <SlideEditForm />
                <PictureBookSettings/>
            </EditGameComponent>
        );
    }
}
export default connect(
    (state: AppState) => ({
        picturebook: state.picturebook,
        errors: state.errors.picturebook,
        meState: state.me,
        account: state.account
    }),
    dispatch => ({
        pbActions: bindActionCreators(pbActions, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(PictureBook);