import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useFetchGet, useFetchPost } from '../../services/FetchHelper';
import Dialog, { DialogIcon } from '../Dialog';
import BundleIcon from '../ModelPreview/BundleIcon';
import GameDetailed from '../ModelPreview/GameDetailed';
import ItemContainer from '../ModelPreview/ItemContainer';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { FormGeneralError } from '../Error/FormGeneralError';
import { isBaseGame, isBundle, isPlaylistType, Wall } from '../../model/SportsWall/Wall';
import WallPreview from './WallPreview';
import { PlayListType } from '../../model/PlayListType';
import { Bundle } from '../../model/Bundle';
import { BaseGame } from '../../model/Game/BaseGame';
import { connect } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import * as GlobalComponentStore from '../../store/GlobalComponentStore';
import OrganizationChooser from '../forms/Organization/OrganizationChooser';
import * as OrgStore from '../../store/OrganizationStore';
import { accountHasAnyPermission, AccountType } from '../../model/AccountType';

interface Props{
    item?: BaseGame|PlayListType|Bundle;
    activeOrgId: string;
    onClose: () => void;
    setActiveOrg: typeof OrgStore.actionCreators.setActiveOrganization;
    me: AccountType|null;
}

interface GetAllWallsResponse {
    walls: Array<Wall>;
}

const WallAddItem = (props: Props) => {
    const {item, activeOrgId: activeOrg, onClose, setActiveOrg, me} = props;
    const [walls, setWalls] = useState<Wall[]>([]);
    const [postItem, loadingPostItem, errorPostItem] = useFetchPost(onClose);
    const [getWalls, loadingGetWalls, errorGetWalls] = useFetchGet<GetAllWallsResponse>(r => setWalls(r.walls));
    const [chooseOrg, showChooseOrg] = useState(false);
  
    useEffect(() => {
        if (item){
            setWalls([]);
            getWalls(`api/wall/organization/${activeOrg}`);
        }
    }, [item, getWalls, activeOrg]);

    //Shortciruit react element
    if (!item) return null;

    const onClick = (w: Wall) => {
        if (isBaseGame(item)) postItem(`api/wall/${w.id}/game/${item.id}`);
        else if (isBundle(item)) postItem(`api/wall/${w.id}/bundle/${item.id}`);
        else if (isPlaylistType(item)) postItem(`api/wall/${w.id}/playlist/${item.id}`);
    }

    const dialogIcons: DialogIcon[] = [];
    if(accountHasAnyPermission(me, ['Admin', 'ChildOrganizations'])){
        dialogIcons.push({
            icon: 'building', 
            hoverMsg: <Translate id='organization_admin_for' />, 
            onClick: () => showChooseOrg(true)
        });
    }

    return(
        <Dialog 
            className='wall-add-item-form' 
            onClose={onClose}
            loading={loadingPostItem} 
            icons={dialogIcons} 
        >
            <h1>
                {isBaseGame(item) && <Translate id='wall_add_game' />}
                {isBundle(item) && <Translate id='wall_add_bundle' />}
                {isPlaylistType(item) && <Translate id='wall_add_playlist' />}
            </h1>
            <FormGeneralError fetchError={errorPostItem} />
            <div className='item-preview'>
                {isBaseGame(item) && <GameDetailed game={item} gameType={item.gameType} hideTranslations />}
                {isBundle(item) && <BundleIcon bundle={item} />}
                {isPlaylistType(item) && <PlayListPreview playlist={item} noMenu />}
            </div>
           
            <ItemContainer
                heading={<h1><Translate id='wall_my_walls' /></h1>}
                items={walls}
                loading={loadingGetWalls}
                error={errorGetWalls}
                itemRender={w => <WallPreview key={w.id} wall={w} onClick={() => onClick(w)} noMenu />}
            />
            <OrganizationChooser 
                onChoose={(org) => setActiveOrg(org.id)} 
                onClose={() => showChooseOrg(false)}
                visible={chooseOrg} 
            />
        </Dialog>
    )

};

export default connect(
    (state: AppState) => ({
        item: state.globalComponentState.showWallAddItem,
        activeOrgId: state.organization.activeOrganization,
        me: state.me.account
    }),
    (dispatch) => ({
        onClose: bindActionCreators(() => GlobalComponentStore.actionCreators.wallAddItemForm(undefined), dispatch),
        setActiveOrg: bindActionCreators(OrgStore.actionCreators.setActiveOrganization, dispatch)
    })
)(WallAddItem);