import React, { FormEvent, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Wall } from '../../model/SportsWall/Wall';
import { useFetchPost } from '../../services/FetchHelper';
import Dialog from '../Dialog';
import { FormGeneralError } from '../Error/FormGeneralError';
import { CheckBox, InputField, SubmitButton, TextArea } from '../forms/FormGroup';

interface WallCreateModel {
    name: string;
    organizationId: string;
    isRightToLeftFlow: boolean;
    copyFromApiKey?: string;
}

interface Props {
    activeOrg: string;
    onClose: (visible: boolean) => void;
    onSuccess: (wall: Wall) => void;
    onDeleted?: () => void;
    visible: boolean;
}

const initialWall = {
    name: "Default",
    isRightToLeftFlow: false,
}

const WallCreate = (props: Props) => {
    const { onClose, onSuccess: _onSuccess, activeOrg, visible } = props;
    const onSuccess = (w: Wall) => { onClose(false); _onSuccess(w); };

    const [model, setModel] = useState<WallCreateModel>({...initialWall, organizationId: activeOrg });
    //prepare fetch post to create a wall
    const [postWall, loading, error] = useFetchPost<Wall>(onSuccess);

    //Update organizationId if activeOrg changes
    useEffect(() => setModel(m => ({ ...m, organizationId: activeOrg })), [activeOrg]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!loading) {
            postWall(`api/wall`, model);
        }
    }

    if (!visible) return null;

    return (
        <Dialog onClose={() => onClose(false)}>
            <h1><Translate id="wall_create_title" /></h1>
            <form className='wall-create-form' onSubmit={onSubmit}>
                <FormGeneralError fetchError={error} />
                <InputField
                    type='text'
                    name="name"
                    value={model.name}
                    onChange={e => setModel(m => ({ ...m, name: e.target.value }))}
                    errors={error}
                    labelWidth100
                />
                <CheckBox
                    label='wall_is_rtl'
                    name='isRightToLeftFlow'
                    active={model.isRightToLeftFlow}
                    onChange={e => setModel(m => ({ ...m, isRightToLeftFlow: e.target.value }))}
                    errors={error}
                />
                <TextArea
                    name='copyFromApiKey'
                    label={'wall_template_api_key'}
                    value={model.copyFromApiKey || ''}
                    onChange={e => setModel(m => ({ ...m, copyFromApiKey: e.target.value }))}
                    errors={error}
                />
                <SubmitButton split disabled={loading} />
            </form>
        </Dialog>
    )
}

export default WallCreate;