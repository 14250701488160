import axios from "axios";
import {BaseGame} from "../model/Game/BaseGame";
import {TA} from "./configureStore";
import {Reducer} from "redux";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {errorActions} from "./Error";

//should match index name in configureStore.ts
//and index name in errorState
const reducerName = "translationState";

export interface TranslationState{
    myTranslations: BaseGame[];
    isLoading: {
        getMyTranslations: boolean;
    };
}

const initialState: TranslationState = {
    myTranslations: [],
    isLoading: {
        getMyTranslations: false
    }
};


export const actionCreators = {
    getMyTranslations: (): TA => async(dispatch, getState) => {
        if(!getState().translationState.isLoading.getMyTranslations){
            dispatch({type: 'TRANSLATIONS_GET_MY_REQUEST'});

            const url = 'api/translation/getMyTranslations';
            axios.get(url, CreateAuthHeader(getState))
                .then(result => {
                    dispatch({type: "TRANSLATIONS_GET_MY_RESPONSE", data: result.data});
                })
                .catch(error => {
                    dispatch(errorActions.reportAxiosError(reducerName, "getMyTranslations", error));
                })
        }
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: TranslationState, action: any) => TranslationState} = {
    TRANSLATIONS_GET_MY_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                getMyTranslations: true
            }
        }
    },
    TRANSLATIONS_GET_MY_RESPONSE: (state, action) => {
        return{
            ...state,
            myTranslations: action.data,
            isLoading:{
                ...state.isLoading,
                getMyTranslations: false
            }
        }
    },
    MYGAMES_PUT_GAME_RESPONSE: (state, action) => {
        const gameIndex = state.myTranslations.findIndex(x =>
            action.data.id === x.id
        );
        if(gameIndex === -1) return state;
        const clonedTranslations = state.myTranslations.slice();
        clonedTranslations[gameIndex] = action.data;
        return{
            ...state,
            myTranslations: clonedTranslations
        }
    },
    ERROR: (state, action) => {
        if(action.reducer !== reducerName) return state;
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                [action.key]: false
            }
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<TranslationState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};