import React from 'react';
import {SmackTheFlyGame} from "../../../model/Game/SmackTheFly/SmackTheFlyGame";
import {StfRound} from "../../../model/Game/SmackTheFly/StfRound";
import {Translate} from "react-localize-redux";
import {SmackTheFlyAnswerPreview, SmackTheFlyRoundPreview} from "./SmackTheFlyPreview";

interface Props{
    game: SmackTheFlyGame;
    onRoundClick?: (quiz: StfRound, index: number) => void;
    onSort?: (indexToMove: number, destinationIndex: number) => void;
    onSortCommit?: () => void;
}


const SmackTheFlyGamePreview = (props: Props) => {
    const game = props.game;
    const rounds = game.rounds;

    return(
        <div className='smackthefly-rounds'>
            <h2><Translate id='smackthefly_rounds'/></h2>
            {rounds.length > 0 ?
                <div>
                    <div className='smackthefly-rounds-container'>
                        {
                            rounds.map((round, index) => {
                                return(
                                    <div
                                        className='smackthefly-round-sortable'
                                        key={index}
                                    >
                                        <SmackTheFlyRoundPreview
                                            round={round}
                                            index={index + 1}
                                            onClick={() => {props.onRoundClick && props.onRoundClick(round, index)}}
                                        />
                                        <div className='answers'>
                                            <div className='infinite'>
                                                {round.answers && round.answers.map((answer, index) => {
                                                    return (<SmackTheFlyAnswerPreview key={index} answer={answer} />)
                                                })}
                                            </div>

                                            <div className='answers-cover' />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className='clear-fix'/>
                </div>
                :
                <Translate id='smackthefly_no_rounds' />
            }
        </div>
    )
}



export default SmackTheFlyGamePreview;