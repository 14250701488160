import React, { useEffect } from 'react';
import * as SweetyWarStore from "../../../store/games/SweetyWarStore";
import * as MyGamesStore from "../../../store/MyGames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import { AppState } from "../../../store/configureStore";
import { ErrorState } from "../../../store/Error";
import { SecureLayoutComponentProps } from "../../Layout";
import { accountHasAnyPermission } from '../../../model/AccountType';
import { useFetchPost } from '../../../services/FetchHelper';
import { history } from '../../..';
import { Loading } from '../../Loading';
import { FormGeneralError } from '../../Error/FormGeneralError';
import { SweetyWarGame } from '../../../model/Game/SweetyWar/SweetyWarGame';
import SweetyWarSettings from './SweetyWarSettings';

interface Props extends SecureLayoutComponentProps<{ id?: string }> {
    gameActions: typeof SweetyWarStore.actionCreators;
    errorState: ErrorState['sweetyWarState'];
    gameState: SweetyWarStore.SweetyWarState;
    myGamesActions: typeof MyGamesStore.actionCreators;
}

const SweetyWar = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, me, setLayoutButtons } = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])

    const [create, loadingCreate, errorCreate] = useFetchPost<SweetyWarGame>(g => history.push(`/workshop/${g.gameType}/edit/${g.id}`));

    if (errorState.getGame) return <div><Translate id='unknown_error' /></div>;
    const game = gameState.game;

    return (
        <EditGameComponent
            gameState={gameState}
            routeMatch={match}
            gameType={'SweetyWar'}
            setLayoutButtons={setLayoutButtons}
            extraButtons={[
                accountHasAnyPermission(me, ["Admin"]) && !routeId
                    ?
                    { id: 'create', icon: 'plus', hoverMsg: 'sweety_war_create', action: () => create(`/api/SweetyWar/create`) }
                    :
                    null
            ]}
        >
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='sweety_war_text' options={{ renderInnerHtml: true, renderToStaticMarkup: false }} /></p>
                    </div>
                }
                tempTitle={<Translate id='sweety_war_title' />}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
            <Loading visible={loadingCreate} />
            <FormGeneralError error={errorCreate} />
            <SweetyWarSettings />
        </EditGameComponent>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[SweetyWarStore.reducerName],
        errorState: state.errors[SweetyWarStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(SweetyWarStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(SweetyWar)