const languages = [
    'af',
    'sq',
    'am',
    'ar',
    'hy',
    'az',
    'eu',
    'be',
    'bn',
    'bs',
    'bg',
    'ca',
    'ceb',
    'zh',
    'zh-CN',
    'zh-TW',
    'co',
    'hr',
    'cs',
    'da',
    'nl',
    'en',
    'eo',
    'et',
    'fi',
    'fr',
    'fy',
    'gl',
    'ka',
    'de',
    'el',
    'gu',
    'ht',
    'ha',
    'haw',
    'he',
    'iw',
    'hi',
    'hmn',
    'hu',
    'is',
    'ig',
    'id',
    'ga',
    'it',
    'ja',
    'jw',
    'kn',
    'kk',
    'km',
    'ko',
    'ku',
    'ky',
    'lo',
    'la',
    'lv',
    'lt',
    'lb',
    'mk',
    'mg',
    'ms',
    'ml',
    'mt',
    'mi',
    'mr',
    'mn',
    'my',
    'ne',
    'no',
    'ny',
    'ps',
    'fa',
    'pl',
    'pt',
    'pa',
    'ro',
    'ru',
    'sm',
    'gd',
    'sr',
    'st',
    'sn',
    'sd',
    'si',
    'sk',
    'sl',
    'so',
    'es',
    'su',
    'sw',
    'sv',
    'tl',
    'tg',
    'ta',
    'te',
    'th',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'cy',
    'xh',
    'yi',
    'yo',
    'zu'
];

export default languages;

export const languageNames: {[code: string]: string} = {
    af: 'Afrikaans (Afrikaans)',
    az: 'Azərbaycan (Azerbaijani)',
    bs: 'Bosanski (Bosnian)',
    ca: 'Català (Catalan)',
    cs: 'Čeština (Czech)',
    sn: 'Chishona (Shona)',
    cy: 'Cymraeg (Welsh)',
    da: 'Dansk (Danish)',
    de: 'Deutsch (German)',
    yo: 'Èdè Yorùbá (Yoruba)',
    et: 'Eesti (Estonian)',
    en: 'English (English)',
    es: 'Español (Spanish)',
    eo: 'Esperanto (Esperanto)',
    eu: 'Euskara (Basque)',
    fr: 'Français (French)',
    fy: 'Frysk (Western Frisian)',
    ga: 'Gaeilge (Irish)',
    gd: 'Gàidhlig (Scottish Gaelic)',
    gl: 'Galego (Galician)',
    ha: 'Hausa (Hausa)',
    hr: 'Hrvatski (Croatian)',
    haw: 'ʻōlelo Hawaiʻi (Hawaiian)',
    ig: 'Igbo (Igbo)',
    id: 'Indonesia (Indonesian)',
    zu: 'Isizulu (Zulu)',
    is: 'Íslenska (Icelandic)',
    it: 'Italiano (Italian)',
    sw: 'Kiswahili (Swahili)',
    lv: 'Latviešu (Latvian)',
    lb: 'Lëtzebuergesch (Luxembourgish)',
    lt: 'Lietuvių (Lithuanian)',
    hu: 'Magyar (Hungarian)',
    mg: 'Malagasy (Malagasy)',
    mt: 'Malti (Maltese)',
    ms: 'Melayu (Malay)',
    nl: 'Nederlands (Dutch)',
    no: 'Norsk (Norwegian)',
    uz: 'O‘Zbek (Uzbek)',
    pl: 'Polski (Polish)',
    pt: 'Português (Portuguese)',
    ro: 'Română (Romanian)',
    sq: 'Shqip (Albanian)',
    sk: 'Slovenčina (Slovak)',
    sl: 'Slovenščina (Slovenian)',
    so: 'Soomaali (Somali)',
    fi: 'Suomi (Finnish)',
    sv: 'Svenska (Swedish)',
    vi: 'Tiếng Việt (Vietnamese)',
    tr: 'Türkçe (Turkish)',
    el: 'Ελληνικά (Greek)',
    be: 'Беларуская (Belarusian)',
    bg: 'Български (Bulgarian)',
    ky: 'Кыргызча (Kyrgyz)',
    kk: 'Қазақ Тілі (Kazakh)',
    mk: 'Македонски (Macedonian)',
    mn: 'Монгол (Mongolian)',
    ru: 'Русский (Russian)',
    sr: 'Српски (Serbian)',
    tg: 'Тоҷикӣ (Tajik)',
    uk: 'Українська (Ukrainian)',
    ka: 'ქართული (Georgian)',
    hy: 'Հայերեն (Armenian)',
    yi: 'ייִדיש (Yiddish)',
    he: 'עברית (Hebrew)',
    ur: 'اردو (Urdu)',
    ar: 'العربية (Arabic)',
    ps: 'پښتو (Pashto)',
    fa: 'فارسی (Persian)',
    am: 'አማርኛ (Amharic)',
    ne: 'नेपाली (Nepali)',
    mr: 'मराठी (Marathi)',
    hi: 'हिन्दी (Hindi)',
    bn: 'বাংলা (Bangla)',
    pa: 'ਪੰਜਾਬੀ (Punjabi)',
    gu: 'ગુજરાતી (Gujarati)',
    ta: 'தமிழ் (Tamil)',
    te: 'తెలుగు (Telugu)',
    kn: 'ಕನ್ನಡ (Kannada)',
    ml: 'മലയാളം (Malayalam)',
    si: 'සිංහල (Sinhala)',
    th: 'ไทย (Thai)',
    lo: 'ລາວ (Lao)',
    my: 'မြန်မာ (Burmese)',
    km: 'ខ្មែរ (Khmer)',
    ko: '한국어 (Korean)',
    zh: '中文 (Chinese)',
    ja: '日本語 (Japanese)'
};