import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Dialog from "../../Dialog";
import * as BuzzItStore from '../../../store/games/BuzzItStore';
import {buzzItTransformQuizToStyle, BuzzItUi, Container, EditQuizType} from '../../../store/games/BuzzItStore';
import {Translate} from "react-localize-redux";
import {
    AudioFormButton,
    CheckBox,
    ColorAndFont,
    ImageFormButton,
    InputField,
    Slider,
    SubmitButton,
    TextAlignmentFormGroup
} from "../../forms/FormGroup";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import {BuzzItQuizPreview} from './BuzzItQuizPreview';
import {AppState} from "../../../store/configureStore";
import {BuzzItAnswer} from "../../../model/Game/BuzzIt/BuzzItAnswer";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {TextAlignment} from "../../../model/Game/TextType";
import {ErrorState} from "../../../store/Error";

interface Props {
    buzzItActions: typeof BuzzItStore.actionCreators;
    buzzItState: BuzzItStore.BuzzItState;
    errors: ErrorState['buzz_it_store'];
}

const BuzzItQuizForm = (props: Props) => {
    if(!props.buzzItState.ui.showQuizForm) return null;
    const ui = props.buzzItState.ui;
    const quiz = props.buzzItState.editQuiz;
    const question = quiz.question;

    let formValid = true;
    if(!quiz.question.image && !quiz.question.sound && !quiz.question.text.content) formValid = false; //must question
    if(!quiz.answer1.image && !quiz.answer1.text.content) formValid = false; //must answer1
    if(!quiz.answer2.image && !quiz.answer2.text.content) formValid = false; //must answer2
    if(!quiz.answer3.image && !quiz.answer3.text.content) formValid = false; //must answer3
    if(!quiz.answer1.correct && !quiz.answer2.correct && !quiz.answer3.correct) formValid = false; //must have at least one correct answer

    const confirmDelete = <ConfirmationForm
        text={"buzz_it_quiz_confirm_delete"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => props.buzzItActions.deleteQuiz(quiz.index)}
        onCancel={() => props.buzzItActions.showQuizDelete(false)}/>;

    const onSubmit = (quiz: EditQuizType) => {
        if(quiz.isNew){
            props.buzzItActions.createQuiz(quiz);
        }
        else{
            props.buzzItActions.updateQuiz(quiz, quiz.index);
        }
    };

    const loading = props.buzzItState.isLoading.createQuiz || props.buzzItState.isLoading.updateQuiz || props.buzzItState.isLoading.deleteQuiz;

    return(
        <Dialog
            onClose={() => {props.buzzItActions.showQuizForm(false)}}
            className='buzz-it-quiz-form-dialog'
            loading={loading}
            icon={quiz.isNew ? undefined : 'trash'}
            onIconClick={() => props.buzzItActions.showQuizDelete(true)}
            icons={[
                {icon: 'eye-dropper', onClick: () => props.buzzItActions.saveStyle(),
                    hoverMsg: <div className='min-width'><Translate id='save_style' /></div>
                },
                {icon: 'fill-drip', onClick: () => props.buzzItActions.applyStyle(),
                    hoverMsg: (
                        <div>
                            <div><Translate id='apply_style'/></div>
                            <BuzzItQuizPreview
                                quiz={buzzItTransformQuizToStyle(quiz, props.buzzItState.style)}
                            />
                        </div>
                    )
                }
            ]}
        >
            <h1><Translate id='buzz_it_quiz_title'/></h1>
            <form onSubmit={e => e.preventDefault()}>
                <div className='buzz-it-form-question'>
                    <ImageFormButton
                        name={'buzz_it_questionImage'}
                        imageSubmit={img => props.buzzItActions.setEditField('question', 'image', img)}
                        previewHeight={80}
                        previewWidth={0}
                        minRatio={1}
                        maxRatio={770/160}
                        //eslint-disable-next-line
                        unsavedChange={question.image != quiz._o.question.image}
                    />

                    <AudioFormButton
                        name={'buzz_it_questionSound'}
                        audioSubmit={sound => {props.buzzItActions.setEditField('question', 'sound', sound)}}
                        //eslint-disable-next-line
                        unsavedChange={question.sound != quiz._o.question.sound}
                    />

                    <InputField
                        placeholder='buzz_it_questionText'
                        name={'content'}
                        type='text'
                        value={question.text.content || ""}
                        onChange={e => {props.buzzItActions.setEditTextField('question', 'content', e.target.value)}}
                        noLabel
                        tabIndex={1}
                        original={quiz._o.question.text.content}
                    />
                    <Slider min={20} max={72}
                            className={'small'}
                            name='fontSize'
                            value={question.text.fontSize}
                            onChange={(e) => props.buzzItActions.setEditTextField('question', e.target.name, e.target.value)}
                            original={quiz._o.question.text.fontSize}
                    />
                    <ColorAndFont
                        className={'small'}
                        colorValue={question.text.fontColorHex}
                        onColorChange={(e) => {props.buzzItActions.setEditTextField('question', 'fontColorHex', e.target.value)}}
                        fontFamilyValue={question.text.fontFamily}
                        onFontFamilyChange={(e) => {props.buzzItActions.setEditTextField('question', 'fontFamily', e.target.value)}}
                        originalColor={quiz._o.question.text.fontColorHex}
                        originalFontFamily={quiz._o.question.text.fontFamily}
                    />
                    <TextAlignmentFormGroup
                        name={'textAlignment'}
                        value={question.text.textAlignment || TextAlignment.Center}
                        onChange={e => props.buzzItActions.setEditTextField('question', 'textAlignment', e.target.value)}
                        original={quiz._o.question.text.textAlignment}
                    />
                </div>
                <h2><Translate id='buzz_it_answers'/></h2>
                <div className='answer-form-container'>
                    {answerForm('answer1', quiz.answer1, quiz._o.answer1, ui, props.buzzItActions, 2)}
                    {answerForm('answer2', quiz.answer2, quiz._o.answer2, ui, props.buzzItActions, 3)}
                    {answerForm('answer3', quiz.answer3, quiz._o.answer3, ui, props.buzzItActions, 4)}
                </div>
                <div className='clear-fix' />
                <FormGeneralError error={props.errors.createQuiz}/>
                <FormGeneralError error={props.errors.updateQuiz}/>
                <SubmitButton text={'submit'} onClick={() => {onSubmit(quiz)}} disabled={!formValid} split/>
            </form>
            <h2><Translate id='buzz_it_preview_title'/></h2>
            <BuzzItQuizPreview quiz={quiz}/>
            {props.buzzItState.ui.showQuizDelete && confirmDelete}
        </Dialog>
    )
};

const answerForm = (container: Container, answer: BuzzItAnswer, _o: BuzzItAnswer, ui: BuzzItUi, actions: typeof BuzzItStore.actionCreators, tabIndex?: number) => {
    return(
        <div className='answer-form'>
            <ImageFormButton
                name={'buzz_it_questionImage'}
                imageSubmit={img => {actions.setEditField(container, 'image', img)}}
                previewHeight={80}
                previewWidth={80}
                //eslint-disable-next-line
                unsavedChange={answer.image != _o.image}
            />
            <InputField
                name={'content'}
                type={'text'}
                value={answer.text.content || ""}
                placeholder='buzz_it_answerText'
                onChange={e => actions.setEditTextField(container, 'content', e.target.value)}
                noLabel
                tabIndex={tabIndex}
                original={_o.text.content || ""}
            />
            <Slider
                min={20}
                max={72}
                name='fontSize'
                value={answer.text.fontSize}
                onChange={(e) => actions.setEditTextField(container, 'fontSize', e.target.value)}
                original={_o.text.fontSize}
            />
            <ColorAndFont
                colorValue={answer.text.fontColorHex}
                onColorChange={(e) => actions.setEditTextField(container, 'fontColorHex', e.target.value)}
                fontFamilyValue={answer.text.fontFamily}
                onFontFamilyChange={(e) => actions.setEditTextField(container, 'fontFamily', e.target.value)}
                originalFontFamily={_o.text.fontFamily}
                originalColor={_o.text.fontColorHex}
            />
            <TextAlignmentFormGroup
                name={'textAlignment'}
                value={answer.text.textAlignment || TextAlignment.Center}
                onChange={e => actions.setEditTextField(container, 'textAlignment', e.target.value)}
                original={_o.text.textAlignment || TextAlignment.Center}
            />
            <CheckBox
                name={'correct'}
                active={answer.correct}
                onChange={e => actions.setEditField(container, 'correct', e.target.value)}
                original={_o.correct}
            />
        </div>
    )
};

export default connect(
    (state: AppState) => ({buzzItState: state.buzz_it_store, errors: state.errors.buzz_it_store}),
    dispatch => ({buzzItActions: bindActionCreators(BuzzItStore.actionCreators, dispatch) })
)(BuzzItQuizForm)