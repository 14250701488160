import React, {MouseEvent, ReactNode, useState} from 'react';
import {Translate} from "react-localize-redux";

interface Props {
    text: string;
    confirmText: string;
    cancelText: string;
    onConfirm: (e: MouseEvent<HTMLElement>) => void;
    onCancel: (e: MouseEvent<HTMLElement>) => void;
}

export const ConfirmationForm = (props: Props) => {
    return(
        <div className='confirm-overlay' onClick={props.onCancel}>
            <div className='confirm'>
                <div className='confirm-text'><Translate id={props.text} /></div>
                <div className='confirm-buttons'>
                    <span className='btn' onClick={props.onConfirm}><Translate id={props.confirmText} /></span>
                    <span className='btn' onClick={props.onCancel}><Translate id={props.cancelText} /></span>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationForm;

export const useConfirmDelete = (_onConfirm: () => void): [ReactNode, () => void] => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const onConfirm = () => {
        setConfirmDelete(false);
        _onConfirm();
    }

    const node = confirmDelete ? <ConfirmationForm
        text='confirm_delete'
        cancelText='no'
        confirmText='yes'
        onCancel={() => setConfirmDelete(false)}
        onConfirm={onConfirm}
    /> : null;

    return [node, () => setConfirmDelete(true)];
}
