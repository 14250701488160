import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {actionCreators, AuthState} from '../store/Auth';
import {AppState} from "../store/configureStore";

interface Props{
    authActions: typeof actionCreators;
    authState: AuthState;
}

const Logout = (props: Props) => {
    useEffect(() => {
        props.authActions.logout();
    })

    return (
        <div>
            {!props.authState.activetoken && <Redirect to={'/login'} />}
        </div>
    );
}

export default connect(
    (state: AppState) => ({authState: state.auth}),
    dispatch => ({authActions: bindActionCreators(actionCreators, dispatch) })
)(Logout);