import React, {Component} from 'react';
import {LayoutComponentProps} from "../Layout";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as orgActions, OrganizationState} from "../../store/OrganizationStore";
import {Loading} from "../Loading";
import CreateOrganizationForm from "../forms/Organization/OrganizationForm";
import OrganizationPreview from "./OrganizationPreview";
import {DictGetValues} from "../../services/JsDict";
import {Translate} from "react-localize-redux";
import {MeState} from "../../store/Me";
import {AppState} from "../../store/configureStore";
import OrganizationList from "./OrganizationList";
import {accountIsAdmin} from "../../model/AccountType";
import {isOrganizationType} from "../../model/Organization";
import TopMenuButton from "../Menu/TopMenuButton";
import {withLayoutButtons} from "../Menu/LayoutButtons";

interface Props extends LayoutComponentProps{
    organization: OrganizationState;
    me: MeState;
    orgActions: typeof orgActions;
}

class Organization extends Component<Props> {

    componentDidMount(){
        const props = this.props;
        props.orgActions.getAll(false);
    }

    render(){
        const props = this.props;
        const me = props.me.account;
        if(!me) return false;
        const myOrg = props.organization.organizations[me.organizationId];
        const isAdmin =  (me.permissions && me.permissions.includes("Admin")) || undefined;
        const canSeeOtherThanOwn = me.permissions && (isAdmin || me.permissions.includes("ChildOrganizations"));
        const visibleOrganizations = canSeeOtherThanOwn ? DictGetValues(props.organization.organizations) : [];

        return(
            <>
                <Loading visible={props.organization.loading.getAll || props.organization.loading.getMy}/>
                <div className='organization'>
                    <h1><Translate id='organization_title'/></h1>

                    {isOrganizationType(myOrg) && <OrganizationPreview org={myOrg} onClick={() => props.orgActions.setEdit(myOrg)}/>}

                    { canSeeOtherThanOwn &&
                        <div>
                            <div className='clear-fix'/>
                            <h2><Translate id='organization_admin_for'/> ({visibleOrganizations.length})</h2>
                            <OrganizationList
                                organizations={visibleOrganizations}
                                orgEditClick={(org) => props.orgActions.setEdit(org)}
                                showChildCount={isAdmin}
                            />
                        </div>
                    }
                    <div className='clear-fix'/>
                </div>
                <CreateOrganizationForm/>
            </>
        )
    }
}

const renderButtons = (props: Props) => {
    const me = props.me.account;
    return accountIsAdmin(me)
        ? <TopMenuButton action={() => props.orgActions.setEdit(null)} icon={'plus'} />
        : null;
};

export default connect(
    (state: AppState) => ({ organization: state.organization, me: state.me }),
    dispatch => ({
        orgActions: bindActionCreators(orgActions, dispatch)
    })
)(withLayoutButtons(Organization, renderButtons));
