import React, {useEffect} from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import * as BuzzItStore from '../../../store/games/BuzzItStore';
import {bindActionCreators} from "redux";
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import GameDetailed from "../../ModelPreview/GameDetailed";
import BuzzItQuizForm from "./BuzzItQuizForm";
import BuzzItSettings from "./BuzzItSettings";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import {BuzzItGamePreview} from "./BuzzItGamePreview";
import {SecureLayoutComponentProps} from "../../Layout";

interface Props extends SecureLayoutComponentProps<{id?: string}>{
    buzzItActions: typeof BuzzItStore.actionCreators;
    buzzItState: BuzzItStore.BuzzItState;
    myGamesActions: typeof myGamesActions;
}

const BuzzIt = (props: Props) => {
    const { buzzItState, buzzItActions, myGamesActions, match, setLayoutButtons } = props;

    useEffect(() => {
        buzzItActions.resetGame();
    }, [buzzItActions]);

    const game = buzzItState.game;

    return(
        <EditGameComponent
            gameState={buzzItState}
            newGameElementFunction={buzzItActions.newQuiz}
            contentClass={'buzz-it-content'}
            newGameElementText={'buzz_it_add_quiz'}
            setLayoutButtons={setLayoutButtons}
            routeMatch={match}
            gameType={"BuzzIt"}
        >
            <GameDetailed
                game={game}
                gameType={'BuzzIt'}
                tempDescription={
                    <div>
                        <p><Translate id='buzz_it_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                    </div>
                }
                tempTitle={<Translate id='buzz_it_title'/>}
                onClick={() => myGamesActions.showEditDialog(true, game)}
                gameStats={buzzItState.gameStats}
            />
            { game &&
                <BuzzItGamePreview
                    game={game}
                    onQuizClick={(q,i) => buzzItActions.setEditQuiz({...q, index: i, isNew: false, _o: {...q}}, i)}
                />
            }
            {buzzItState.ui.showQuizForm && <BuzzItQuizForm />}
            <BuzzItSettings />
        </EditGameComponent>
    )
}

export default connect(
    (state: AppState) => ({
        buzzItState: state[BuzzItStore.reducerName],
    }),
    dispatch => ({
        myGamesActions: bindActionCreators(myGamesActions, dispatch),
        buzzItActions: bindActionCreators(BuzzItStore.actionCreators, dispatch)
    })
)(BuzzIt);