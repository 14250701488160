import React from 'react'
import {DbOrUploadImage, DbOrUploadSound} from '../../../services/ImageHelper';
import audioImage from '../../../images/games/picturebook/sound.png';
import backwardArrowImage from '../../../images/games/picturebook/backward.png';
import forwardArrowImage from '../../../images/games/picturebook/forward.png';
import {PictureBookSlide} from '../../../model/Game/PictureBook/PictureBookSlide';

interface Props {
    slide: PictureBookSlide;
    onClick?: () => void;
}

export const PictureBookSlidePreview = (props: Props) => {
    const slide = props.slide;
    if (!slide) return null;

    return(
        <div className='preview-wrapper' onClick={props.onClick || undefined}>
            <div className='picturebook-slide-preview' style={{backgroundColor: slide.backgroundColor}}>
                <DbOrUploadImage className='image' image={slide.image} realSize/>
                <DbOrUploadSound className='audio' audio={slide.sound} image={audioImage} />
                <img className='backward-arrow-image' src={backwardArrowImage} alt={''} />
                <img className='forward-arrow-image' src={forwardArrowImage} alt={''} />
            </div>
        </div>
    )
};