import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { accountHasAnyPermission, } from '../../model/AccountType';
import { Organization } from '../../model/Organization';
import { Wall } from '../../model/SportsWall/Wall';
import { removeItemFromArray, updateItemInArray } from '../../services/CustomFunctions';
import { useFetchGet } from '../../services/FetchHelper';
import OrganizationChooser from '../forms/Organization/OrganizationChooser';
import { SecureLayoutComponentProps } from '../Layout';
import TopMenuButton from '../Menu/TopMenuButton';
import ItemContainer from '../ModelPreview/ItemContainer';
import WallCreate from './WallCreate';
import WallPreview from './WallPreview';

interface Props extends SecureLayoutComponentProps{
    
}

interface GetAllWallsResponse{
    walls: Array<Wall>;
}

const WallIndex = (props: Props) => {
    const {me, setLayoutButtons} = props;

    const [showOrgChoose, setShowOrgChoose] = useState(false);
    const [showCreateWall, setShowCreateWall] = useState(false);
    
    const [activeOrg, _setActiveOrg] = useState<string|null>(me && me.organizationId);
    const setActiveOrg = (org: Organization) => { _setActiveOrg(org.id); setShowOrgChoose(false); };

    const [walls, setWalls] = useState<Array<Wall>>([]);

    const [invoke, loading, error] = useFetchGet<GetAllWallsResponse>(r => setWalls(r.walls));
    useEffect(() => {
        invoke(`api/wall/organization/${activeOrg}`);
    }, [activeOrg, invoke]);
    

    useEffect(() => {
        setLayoutButtons(
            <>
                <TopMenuButton icon={'building'} action={() => setShowOrgChoose(true)} hoverMsg='organization_admin_for' />
                {/* {accountHasAnyPermission(me, ["Admin"]) &&
                    <TopMenuButton icon={'plus'} action={() => setShowCreateWall(true)} hoverMsg='wall_create_hover_msg' />
                } */}
            </>
        )
        return () => setLayoutButtons(undefined);
    }, [setLayoutButtons, me])

    if(!activeOrg) return null;

    return (
        <div className='wall-index'>
            <ItemContainer
                heading={<h1><Translate id='wall_index_title' /></h1>}
                loading={loading}
                items={walls || []}
                itemRender={w => 
                    <WallPreview 
                        key={w.id} 
                        wall={w} 
                        onUpdated={(updatedWall) => setWalls(x => updateItemInArray(x, updatedWall))} 
                        onDeleted={() => setWalls(x => removeItemFromArray(x, w.id))}
                        account={me}
                    />
                }
                error={error}
            />
            <OrganizationChooser onChoose={setActiveOrg} onClose={setShowOrgChoose} visible={showOrgChoose} />
            <WallCreate onClose={setShowCreateWall} onSuccess={w => setWalls(x => [...x, w])} visible={showCreateWall} activeOrg={activeOrg} />
        </div>
    )
}

export default WallIndex;