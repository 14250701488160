import React from 'react';
import {actionCreators as stfActions, SmackTheFlyState} from "../../../store/games/SmackTheFlyStore";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import {Dialog} from "../../Dialog";
import {Translate} from "react-localize-redux";
import {AudioFormButton, ImageFormButton, InputField, Slider, SubmitButton} from "../../forms/FormGroup";
import SmackTheFlyAnswerForm from "./SmackTheFlyAnswerForm";
import SoundForm from "../../forms/games/SoundForm";
import newAnswerImage from '../../../images/plus.png';
import newAnswerImageDisabled from '../../../images/plus_disabled.png';
import {SmackTheFlyAnswerPreview, SmackTheFlyRoundPreview} from './SmackTheFlyPreview';
import {AppState} from "../../../store/configureStore";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {ErrorState} from "../../../store/Error";


interface Props {
    smackthefly: SmackTheFlyState;
    errors: ErrorState['smackthefly'];
    actions: typeof stfActions;
}

const SmackTheFlyRoundForm = (props: Props) => {
    const round = props.smackthefly.round;
    const loading = props.smackthefly.isLoading;
    const confirmDelete = <ConfirmationForm
        text={"smackthefly_round_confirm_delete"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => {props.actions.deleteRound(round.index)}}
        onCancel={() => props.actions.showConfirmDeleteRound(false)}/>;

    const submitFunc = () => {
        if (round.new){
            props.actions.createRound(round, true);
        }
        else{
            props.actions.updateRound(round, true);
        }

    };

    let validForm = true;
    if (!round.hint && !round.hintImage) validForm = false;

    const mainForm = (
        <Dialog
            loading={loading.createRound || loading.deleteRound || loading.updateRound}
            className='smackthefly-round-dialog'
            onClose={() => props.actions.showEditRound(false)}
            icon={round.new ? undefined : 'trash'}
            onIconClick={() => props.actions.showConfirmDeleteRound(true)} >
            {props.smackthefly.ui.deleteRound && confirmDelete}
            <h1><Translate id='smackthefly_round' /></h1>
            <form className='float smackthefly-round-form' onSubmit={(e) => e.preventDefault()}>
                <ImageFormButton
                    previewWidth={1280}
                    previewHeight={800}
                    imageSubmit={props.actions.setRoundBackgroundImage}
                    name='backgroundimage'
                    unsavedChange={round.backgroundImage !== round._o.backgroundImage}
                />

                <ImageFormButton
                    previewWidth={412}
                    previewHeight={395}
                    imageSubmit={props.actions.setRoundAnnouncerImage}
                    name='announcerimage'
                    unsavedChange={round.announcerImage !== round._o.announcerImage}
                />

                <ImageFormButton
                    previewWidth={150}
                    previewHeight={100}
                    imageSubmit={props.actions.setRoundHintImage}
                    name='hintimage'
                    unsavedChange={round.hintImage !== round._o.hintImage}
                />

                <AudioFormButton
                    name={'hintsound'}
                    audioSubmit={sound => props.actions.setRoundHintSound(sound)}
                    unsavedChange={round.hintSound !== round._o.hintSound}
                />

                <InputField
                    name='hint'
                    type='text'
                    value={round.hint}
                    placeholder='smackthefly_hint_placeholder'
                    onChange={(e) => props.actions.setRoundHint(e.target.value)}
                    noLabel
                    tabIndex={1}
                    original={round._o.hint}
                />
                <Slider
                    name='time'
                    value={round.time}
                    onChange={e => props.actions.setRoundTime(e.target.value)}
                    min={5}
                    max={120}
                    step={5}
                    original={round._o.time}
                />
                <SubmitButton text={'submit'} onClick={submitFunc} split disabled={!validForm}/>
            </form>
            <div className='float preview'>
                <SmackTheFlyRoundPreview round={round} index={1}/>
            </div>
            <div className='clear-fix' />
            <h2><Translate id='smackthefly_answers'/></h2>
            <div className='smackthefly-answer-container'>
                {round.answers && round.answers.map((answer, index) => {
                    return (
                        <SmackTheFlyAnswerPreview key={index} answer={answer} onClick={() => props.actions.setEditAnswer(answer, index)} />
                    );
                })}
                <button className='answer new' onClick={() => props.actions.newAnswer()} disabled={!validForm}>
                    <img src={validForm ? newAnswerImage : newAnswerImageDisabled} alt='new'/>
                </button>
            </div>
            <div className='clear-fix' />

            <FormGeneralError error={props.errors.createRound}/>
            <FormGeneralError error={props.errors.updateRound}/>
            <FormGeneralError error={props.errors.deleteRound}/>
        </Dialog>
    );
    
    if (props.smackthefly.ui.editAnswer){
        return <SmackTheFlyAnswerForm />;
    }
    if (props.smackthefly.ui.roundHintSound){
        return (
            <Dialog className='smackthefly-round-dialog' onClose={() => props.actions.showRoundHintSound(false)} >
                <SoundForm onSubmit={sound => props.actions.setRoundHintSound(sound)}/>
            </Dialog>
        );
    } 
    else if (props.smackthefly.ui.editRound) {
        return mainForm;
    }

    return null;
};

export default connect(
    (state: AppState) => ({smackthefly: state.smackthefly, errors: state.errors.smackthefly}),
    dispatch => ({ actions: bindActionCreators(stfActions, dispatch) })
)(SmackTheFlyRoundForm)