import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate} from "react-localize-redux";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface TopMenuButtonProps{
    action: () => void;
    icon: IconProp;
    hoverMsg?: string;
    disabled?: boolean;
}

const TopMenuButton = (p: TopMenuButtonProps) => {
    const [id, setId] = useState(1);
    useEffect(() => {
        setId(i => i + 1);
    }, [p.action]);

    return(
        <button data-key={id} disabled={p.disabled} onClick={p.action} className={`btn${p.hoverMsg ? ' hover-trigger' : ''}`}>
            <FontAwesomeIcon icon={p.icon} />
            {p.hoverMsg && <div className='hover-msg'><Translate id={p.hoverMsg}/></div>}
        </button>
    )
};

export default TopMenuButton;