import React, {useState} from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as AccountStore from "../../../store/Account";
import {actionCreators as accountActions} from "../../../store/Account";
import {DbOrUploadImage} from "../../../services/ImageHelper";
import {Translate} from "react-localize-redux";
import {SubmitButton} from "../FormGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import profile from '../../../images/missing-profile.png';
import ConfirmationForm from "../games/ConfirmationForm";
import * as MeStore from '../../../store/Me';
import {PermissionType} from "../../../model/Permission/PermissionType";
import {AppState} from "../../../store/configureStore";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {OrganizationState} from "../../../store/OrganizationStore";
import {isOrganizationType} from "../../../model/Organization";
import {accountHasAnyPermission, AccountType, getFullName} from "../../../model/AccountType";
import {history} from "../../../index";
import { fetchDelete, FetchError, fetchPost } from '../../../services/FetchHelper';
import { AuthState } from '../../../store/Auth';

interface Props{
    accountState: AccountStore.AccountState;
    accountActions: typeof accountActions;
    orgState: OrganizationState;
    meState: MeStore.MeState;
    account: AccountType;
    authState: AuthState;
    onClose: () => void;
}

interface State{
    confirmDelete?: boolean;
    showConfirmAdmin?: boolean;
}

const AdminEditAccount = (props: Props) => {
    const [state, setState] = useState<State>({});
    const [ac, setAc] = useState(props.account);
    const [error, setError] = useState<FetchError>();
    const [loading, setLoading] = useState(false);
    if(!ac) return null;
    const activeOrg = props.orgState.organizations[props.orgState.activeOrganization];
    const acPermissions = ac.permissions || [];
    const allPermissions: PermissionType[] = ["Organization", "Floor", "Users", "Editor", "Public", "Publish"];
    //only show the child org permission if org has a child org
    if (isOrganizationType(activeOrg) && activeOrg.childOrganizationIds && activeOrg.childOrganizationIds.length > 0) {
        allPermissions.push("ChildOrganizations");
    }
    //only show the admin if user has admin
    if (accountHasAnyPermission(props.meState.account, ["Admin"])) {
        allPermissions.push("Admin");
    }

    const onFetchError = (e: FetchError) => { setError(e); setLoading(false); };
    const onSubmit = () => {
        setLoading(true);
        fetchPost<AccountType>(`api/account/${ac.id}/adminEdit`, { permissions: ac.permissions })
            .then(a => {
                props.accountActions.updateRespose(props.account, a);
                props.onClose();
            })
            .catch(onFetchError)
    }
    const onDelete = () => {
        setLoading(true);
        fetchDelete(`api/account/${ac.id}`)
            .then(_ => {
                props.accountActions.deleteResponse(ac.id, ac.email);
                props.onClose();
            })
            .catch(onFetchError);
    }

    return(
        <Dialog
            className='admin-edit-account-dialog'
            onClose={() => props.onClose()}
            icon='trash'
            onIconClick={() => setState({...state, confirmDelete: true})}
            loading={loading}
        >
            <div>
                <h2><Translate id='admin_edit_account'/></h2>
                <div className='float'>
                    <DbOrUploadImage image={ac.profileImage} tempImage={profile}/>
                </div>
                <div className='float info'>
                    <div className='name' onClick={() => { history.push(`/library/profile/${ac.id}`)}}>
                        {getFullName(ac)}
                        <span className='to-public'><Translate id='account_public' /> &gt;</span>
                    </div>
                    <div>{ac.email}</div>
                </div>
                <div className='clear-fix' />

                <div className='permissions-title'><Translate id='added_permissions'/></div>
                <div className='permissions'>
                    {acPermissions && acPermissions.map(x => {
                        const canRemove = allPermissions.includes(x);
                        return (
                            <div
                                key={x}
                                className={`permission ${canRemove ? 'remove' : ''}`}
                                onClick={
                                    canRemove ?
                                        () => setAc({...ac, permissions: acPermissions.filter(y => y !== x)}):
                                        undefined
                                }
                            >
                                <Translate  id={`permission_${x}`}/>
                                {canRemove && <FontAwesomeIcon className='icon' icon='minus' /> }
                            </div>
                        );
                    })}
                    <div className='clear-fix' />
                </div>
                <div className='permissions-title'><Translate id='permissions'/></div>
                <div className='permissions'>
                    {allPermissions && allPermissions.map(x => {
                        if(ac.permissions && ac.permissions.includes(x)) return null;
                        return (
                            <div
                                key={x}
                                className='permission add'
                                onClick={() => {
                                    if(x === "Admin"){
                                        setState({...state, showConfirmAdmin: true});
                                    }
                                    else{
                                        setAc({...ac, permissions: [...acPermissions, x]});
                                    }
                                }}
                            >
                                <Translate  id={`permission_${x}`}/>
                                <FontAwesomeIcon className='icon' icon='plus' />
                            </div>
                        );
                    })}
                    <div className='clear-fix' />
                </div>
            </div>
            <FormGeneralError fetchError={error}/>
            <SubmitButton text='submit' onClick={onSubmit} split/>
            {state.confirmDelete && 
            <ConfirmationForm
                cancelText='no'
                confirmText='yes'
                text='account_delete_confirm'
                onCancel={() => setState({ ...state, confirmDelete: false })}
                onConfirm={onDelete}
            />
            }
            {state.showConfirmAdmin && 
            <ConfirmationForm
                text={'add_admin_warning'}
                confirmText={'yes'}
                cancelText={'no'}
                onConfirm={() => {
                    setAc({ ...ac, permissions: [...acPermissions, "Admin"] });
                    setState({ ...state, showConfirmAdmin: false });
                }}
                onCancel={() => setState({ ...state, showConfirmAdmin: false })}
            />
            }
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({
        accountState: state.account,
        orgState: state.organization,
        meState: state.me,
        authState: state.auth
    }),
    dispatch => ({
        accountActions: bindActionCreators(accountActions, dispatch)
    })
)(AdminEditAccount);
