import * as React from 'react';
import { Component, ReactNode } from 'react';
import AuthorChooser from './forms/AuthorChooser';
import TransferOwner from './forms/TransferOwner';
import BaseGame from './forms/games/BaseGame';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as GamesStore from '../store/games/Games';
import * as MyGamesStore from '../store/MyGames';
import * as AccountStore from '../store/Account';
import { Loading } from './Loading';
import { MeState } from '../store/Me';
import { AppState } from '../store/configureStore';
import {DictGetValues} from "../services/JsDict";
import {PlayListState, actionCreators as playlistActions} from "../store/PlayListStore";
import * as OrgStore from '../store/OrganizationStore';
import * as GlobalStore from '../store/GlobalComponentStore';
import CreateCopyForm from "./forms/games/CreateCopyForm";
import {GamesState} from "../store/games/Games";
import ConfirmationForm from "./forms/games/ConfirmationForm";
import {GameType} from "../model/Game/GameType";
import {accountHasAnyPermission, accountIsAuthor, accountIsOwner} from "../model/AccountType";
import TopMenuButton from "./Menu/TopMenuButton";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { match } from 'react-router-dom';

interface Props{
    setLayoutButtons: (n: ReactNode) => void;
    gameState: GamesStore.AbstractGameState;
    gamesState: GamesState;
    newGameElementFunction?: () => void;
    newGameElementDisabled?: boolean;
    meState: MeState;
    myGamesActions: typeof MyGamesStore.actionCreators;
    myGamesState: MyGamesStore.MyGamesState;
    gamesActions: typeof GamesStore.actionCreators;
    accountActions: typeof AccountStore.actionCreators;
    orgActions: typeof OrgStore.actionCreators;
    account: AccountStore.AccountState;
    children: ReactNode;
    contentClass?: string;
    playlist: PlayListState;
    playListActions: typeof playlistActions;
    newGameElementText?: string;
    gameType: GameType;
    extraButtons?: Array<{id: string; hoverMsg: string; icon: IconProp; action: () => void}|null>;
    routeMatch: match<{id?: string}>;
    globalActions: typeof GlobalStore.actionCreators;
}
interface State {
    showTransferOwner: boolean;
    playlistAddTranslationId?: string;
}

class EditGameComponent extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        this.state = {
            showTransferOwner: false
        };

        const myAccount = this.props.meState.account;
        const meId      = myAccount && myAccount.id;
        if(!this.props.playlist.loading.getPlayLists && meId && DictGetValues(this.props.playlist.playListsMap).length === 0){
            this.props.playListActions.getPlayLists();
        }

        const idFromRouting = props.routeMatch.params.id;
        if (idFromRouting){
            props.gamesActions.getGame(idFromRouting, true, props.gameType);
            props.gamesActions.getStats(props.gameType, idFromRouting);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        const newGame = this.props.gameState.game;
        const oldGame = prevProps.gameState.game;
        const oldRouteId = prevProps.routeMatch.params.id;
        const newRouteId = this.props.routeMatch.params.id;
        const oldAcc = prevProps.meState.account;
        const newAcc = this.props.meState.account;

        if(newGame){
            if(!oldGame || oldGame.ownerId !== newGame.ownerId){
                prevProps.accountActions.get(newGame.ownerId, false);
            }
        }

        if(oldRouteId !== newRouteId){
            if (newRouteId){
                this.props.gamesActions.getGame(
                    newRouteId,
                    true,
                    prevProps.gameType
                );
                this.props.gamesActions.getStats(this.props.gameType, newRouteId);
            }
        }

        if(JSON.stringify(newGame) !== JSON.stringify(oldGame) || JSON.stringify(newAcc) !== JSON.stringify(oldAcc)){
            this.props.setLayoutButtons(this.renderButtons());
        }
    }

    componentDidMount(){
        this.props.setLayoutButtons(this.renderButtons());
    }

    componentWillUnmount() {
        this.props.setLayoutButtons(undefined);
    }

    renderButtons = () => {
        const props = this.props;
        const game = props.gameState.game;

        const myAccount = props.meState.account;
        const isAdmin  = accountHasAnyPermission(myAccount, ["Admin"]);
        const isOwner  = accountIsOwner(myAccount, game);
        const isAuthor = accountIsAuthor(myAccount, game);

        return(
            <>
                {props.newGameElementFunction &&
                <TopMenuButton
                    action={props.newGameElementFunction}
                    icon={'plus'}
                    disabled={props.newGameElementDisabled}
                    hoverMsg={props.newGameElementText}
                />
                }
                {(isAdmin || isOwner) &&
                <TopMenuButton
                    action={() => props.myGamesActions.showEditDialog(true, game)}
                    icon={'pen'}
                    hoverMsg={'game_edit_base_info'}
                    disabled={!game}
                />
                }
                {game && (isAdmin || isOwner || isAuthor) &&
                <TopMenuButton
                    action={() => props.gamesActions.showSettings(game.gameType, true)}
                    icon={'cog'}
                    hoverMsg={'game_edit_settings'}
                    disabled={!game}
                />
                }
                {game && (isAdmin || isOwner) &&
                <TopMenuButton
                    action={() => props.accountActions.showSearch(true)}
                    icon={'users'}
                    hoverMsg={'game_edit_authors'}
                    disabled={!game}
                />
                }
                {game && (isAdmin || isOwner) &&
                <TopMenuButton
                    action={() => this.setState({showTransferOwner: true})}
                    icon={'user'}
                    hoverMsg={'transfer_owner'}
                    disabled={!game}
                />
                }
                <TopMenuButton
                    action={() => props.globalActions.playlistAddItemForm(game)}
                    icon={'plus-square'}
                    hoverMsg={'playlist_add_to'}
                    disabled={!game?.isPublished}
                />
                <TopMenuButton
                    action={() => props.globalActions.bundleAddItemForm(game)}
                    icon={'plus-circle'}
                    hoverMsg={'bundle_add_to'}
                    disabled={!game?.isPublished}
                />
                <TopMenuButton
                    action={() => props.globalActions.wallAddItemForm(game)}
                    icon={'folder-plus'}
                    hoverMsg={'wall_add_to'}
                    disabled={!game?.isPublished}
                />
                {game && game.id &&
                    <TopMenuButton
                        action={() => props.gamesActions.showCreateCopy(game.gameType, true)}
                        icon={'globe'}
                        hoverMsg={game.parentId ? 'copy_not_copy' : 'copy_create'}
                        disabled={!!game.parentId}
                    />
                }

                {game &&
                <TopMenuButton
                    action={() => props.gamesActions.showConfirmDelete(game.id)}
                    icon={'trash'}
                    hoverMsg={'delete'}
                />
                }
                {props.extraButtons && props.extraButtons.map(x => x === null ? null : <TopMenuButton key={x.id} {...x} />)}
            </>
        );
    };

    render(){
        const props = this.props;
        const game = props.gameState.game;
        const isLoading = props.gameState.isLoading;

        return(
            <div className={`layout-content ${props.contentClass || ''}`}>
                <Loading visible={props.gameState.isLoading.getGame || props.myGamesState.isLoading.deleteGame} />
                {props.children}
                <BaseGame />
                {game && props.account.showSearch &&
                    <AuthorChooser
                        chooseFunction={(authorId) => {props.gamesActions.addAuthor(game.gameType, game.id, authorId)}}
                        loading={isLoading.addAuthor || isLoading.removeAuthor}
                        visible={props.account.showSearch}
                        onClose={() => props.accountActions.showSearch(false)}
                        chooseDelete={(authorId) => {props.gamesActions.removeAuthor(game.gameType, game.id, authorId)}}
                        ownerId={game.ownerId}
                        authorIds={game.authorIds}
                    />
                }
                {game &&
                    <TransferOwner
                        loading={isLoading.transferOwner}
                        visible={this.state.showTransferOwner}
                        chooseFunction={(accId) => accId && props.gamesActions.transferOwner(game, accId)}
                        onClose={() => {this.setState({showTransferOwner: false})}}
                        ownerId={game.ownerId}
                    />
                }
                { game && props.gamesState.ui.showCreateCopy &&
                    <CreateCopyForm gameId={game.id} gameType={game.gameType}/>
                }
                {(game && props.gamesState.ui.confirmDelete === game.id) &&
                    <ConfirmationForm
                        text={'confirm_delete'}
                        confirmText={'yes'}
                        cancelText={'no'}
                        onConfirm={() => props.myGamesActions.deleteGame(game.gameType, game.id)}
                        onCancel={() => props.gamesActions.showConfirmDelete(undefined)}
                    />
                }
            </div>
        )
    }
}

export default connect(
    (state: AppState) => ({
        meState: state.me,
        account: state.account,
        playlist: state.playlist,
        gamesState: state.gamesState,
        myGamesState: state.mygames
    }),
    dispatch => ({
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch),
        playListActions: bindActionCreators(playlistActions, dispatch),
        orgActions: bindActionCreators(OrgStore.actionCreators, dispatch),
        globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
    })
)(EditGameComponent);
