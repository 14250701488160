import React, { FormEvent, useEffect, useState } from 'react';
import Dialog from "../../Dialog";
import { ColorPicker, ImageFormButton, SubmitButton } from "../../forms/FormGroup";
import { Translate } from "react-localize-redux";
import JigsawPreview from "./JigsawPreview";
import { EditPuzzleImage } from "../../../store/games/JigsawPuzzleStore";
import { ErrorType } from "../../../store/Error";
import { FormGeneralError } from "../../Error/FormGeneralError";
import ConfirmationForm from "../../forms/games/ConfirmationForm";

interface Props {
    onClose: () => void;
    item: EditPuzzleImage;
    onSubmit: (item: EditPuzzleImage) => void;
    loading: boolean;
    error: ErrorType | undefined;
    onDelete?: () => void;
}

const JigsawImageForm = (props: Props) => {
    const onClose = props.onClose;
    const [state, setState] = useState<EditPuzzleImage>(props.item);
    const [submitted, setSubmitted] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    useEffect(() => {
        if (submitted && !props.loading && !props.error) {
            onClose();
        }
    }, [submitted, props.loading, props.error, onClose]);

    const submitForm = (e: FormEvent) => {
        e.preventDefault();
        setSubmitted(true);
        props.onSubmit(state);
    }
    const submitDelete = () => {
        setSubmitted(true);
        if (props.onDelete) {
            props.onDelete();
        }
    }

    return (
        <Dialog className='jigsaw-image-dialog' onClose={onClose} loading={props.loading} icon={props.onDelete ? 'trash' : undefined} onIconClick={props.onDelete && (() => setConfirmDelete(true))} >
            <h1><Translate id='jigsaw_edit_image' /></h1>
            <form onSubmit={e => e.preventDefault()} className='jigsaw-image-form'>
                <ImageFormButton
                    imageSubmit={img => setState({ ...state, image: img })}
                    previewWidth={400}
                    previewHeight={266.66667}
                    name={'image'}
                    forceFixedAspect
                    noRemove
                />
                <ColorPicker colorValue={state.borderColor} onColorChange={e => setState({ ...state, borderColor: e.target.value })} label='jigsaw_border_color' />
                <FormGeneralError error={props.error} />
                <SubmitButton text={'submit'} onClick={submitForm} split />
            </form>
            <h2><Translate id='preview' /></h2>
            <JigsawPreview autoSlide={true} jigsawImage={{ image: state.image, borderColor: state.borderColor }} />
            {confirmDelete &&
                <ConfirmationForm
                    text={'delete'}
                    confirmText={'yes'}
                    cancelText={'no'}
                    onConfirm={submitDelete}
                    onCancel={() => setConfirmDelete(false)}
                />
            }
        </Dialog>
    )
}

export default JigsawImageForm;