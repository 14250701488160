import {AbstractGameIsLoading, abstractGameReducerMethods, AbstractGameState, AbstractGameUi} from "./Games";
import {BikeRaceGame} from "../../model/Game/BikeRace/BikeRaceGame";
import {BikeRaceQuiz} from "../../model/Game/BikeRace/BikeRaceQuiz";
import {Reducer} from "redux";
import {ChangeEvent, TA} from "../configureStore";
import axios from "axios";
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {ImageUploadType} from "../../model/ImageUploadType";
import {SoundUploadType} from "../../model/SoundUploadType";
import {errorActions} from "../Error";
import {actionCreators as gamesActions} from '../../store/games/Games';
import {createBikeRaceQuizFormData, updateBikeRaceQuizFormData,} from "../../services/FormDataHelper";
import {history} from "../../index";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import {getLocaleStorageItem, saveLocaleStorageItem} from "../../services/LocalStorageService";

export const reducerName = "bikeRaceState";

const BIKE_RACE_RESET = "BIKE_RACE_RESET";
const BIKE_RACE_SHOW_QUIZ_FORM = "BIKE_RACE_SHOW_QUIZ_FORM";
const BIKE_RACE_SET_TEXT_FIELD = "BIKE_RACE_SET_TEXT_FIELD";
const BIKE_RACE_SHOW_COLOR_PICKER = "BIKE_RACE_SHOW_COLOR_PICKER";
const BIKE_RACE_SHOW_AUDIO_FORM = "BIKE_RACE_SHOW_AUDIO_FORM";
const BIKE_RACE_SET_FIELD = "BIKE_RACE_SET_FIELD";
const BIKE_RACE_SET_ANSWER = "BIKE_RACE_SET_ANSWER";
const BIKE_RACE_CREATE_QUIZ_REQUEST = "BIKE_RACE_CREATE_QUIZ_REQUEST";
const BIKE_RACE_SET_EDIT_QUIZ = "BIKE_RACE_SET_EDIT_QUIZ";
const BIKE_RACE_CREATE_QUIZ_RESPONSE = "BIKE_RACE_CREATE_QUIZ_RESPONSE";
const BIKE_RACE_UPDATE_QUIZ_REQUEST = "BIKE_RACE_UPDATE_QUIZ_REQUEST";
const BIKE_RACE_UPDATE_QUIZ_RESPONSE = "BIKE_RACE_UPDATE_QUIZ_RESPONSE";
const BIKE_RACE_SET_SETTING = "BIKE_RACE_SET_SETTING";
const BIKE_RACE_SHOW_DELETE_FORM = "BIKE_RACE_SHOW_DELETE_FORM";
const BIKE_RACE_DELETE_QUIZ_REQUEST = "BIKE_RACE_DELETE_QUIZ_REQUEST";
const BIKE_RACE_DELETE_QUIZ_RESPONSE = "BIKE_RACE_DELETE_QUIZ_RESPONSE";
const BIKE_RACE_SAVE_STYLE = 'BIKE_RACE_SAVE_STYLE';
const BIKE_RACE_APPLY_STYLE = 'BIKE_RACE_APPLY_STYLE';

const _baseUrl = `api/workshop/bikerace`;
const bikeraceStyleKey = 'bikerace_style';

export type EditQuizType = BikeRaceQuiz & {
    isNew: boolean;
    index: number;
    _o: BikeRaceQuiz;
};

interface BikeRaceStyle{
    text: TextType;
}

export interface BikeRaceState extends AbstractGameState {
    game?: BikeRaceGame;
    editQuiz: EditQuizType;
    isLoading: AbstractGameIsLoading & {
        createQuiz: boolean;
        updateQuiz: boolean;
        deleteQuiz: boolean;
    };
    ui: BikeRaceUI;
    style: BikeRaceStyle;
}

export interface BikeRaceUI extends AbstractGameUi {
    showQuizForm: boolean;
    showAudioForm: boolean;
    showQuizDelete: boolean;
}

const initialStyle = {
    text: {
        content: '',
        fontFamily: 'Lato',
        fontColorHex: '#000000',
        fontSize: 20,
        textAlignment: TextAlignment.Left
    }
};

const initialState = (): BikeRaceState => {
    const style = {...getLocaleStorageItem(bikeraceStyleKey, initialStyle)};
    return {
        editQuiz: {
            isNew: true,
            index: -1,
            question: {
                image: null,
                text: {...style.text, content : ""}
            },
            correctAnswer: false,
            _o:{
                question: {
                    image: null,
                    text: {...style.text, content : ""}
                },
                correctAnswer: false,
            }
        },
        game: undefined,
        gameStats: undefined,
        gameType: "BikeRace",
        isLoading: {
            addAuthor: false,
            createQuiz: false,
            deleteQuiz: false,
            getGame: false,
            removeAuthor: false,
            transferOwner: false,
            updateQuiz: false,
            updateSettings: false
        },
        ui: {
            showSettings: false,
            showQuizForm: false,
            showAudioForm: false,
            showQuizDelete: false,
        },
        style: style
    }
};

export function bikeRaceTransformQuizToStyle(quiz: EditQuizType, style: BikeRaceStyle): EditQuizType{
    return({
        ...quiz,
        question: {
            ...quiz.question,
            text: {
                ...style.text,
                content: quiz.question.text.content
            }
        }
    });
}

export const actionCreators = {
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: BIKE_RACE_RESET});
    },
    saveStyle: (): TA => async(dispatch, getState) => {
        const newStyle: BikeRaceStyle = {
            text: {...getState().bikeRaceState.editQuiz.question.text}
        };
        saveLocaleStorageItem(bikeraceStyleKey, newStyle);
        dispatch({type: BIKE_RACE_SAVE_STYLE, newStyle});
    },
    applyStyle: (): TA => async(dispatch, getState) => {
        dispatch({type: BIKE_RACE_APPLY_STYLE});
    },
    showQuizForm: (show: boolean): TA => async (dispatch) => {
        dispatch({type: BIKE_RACE_SHOW_QUIZ_FORM, show});
    },
    newQuiz: (): TA => async (dispatch, getState) => {
        if (!getState().bikeRaceState.editQuiz.isNew) {
            dispatch({type: BIKE_RACE_SET_EDIT_QUIZ, quiz: initialState().editQuiz})
        }
        dispatch(actionCreators.showQuizForm(true));
    },
    showQuizDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: BIKE_RACE_SHOW_DELETE_FORM, show});
    },
    setEditQuiz: (quiz: EditQuizType, index: number): TA => async (dispatch, getState) => {
        if (getState().bikeRaceState.editQuiz.index !== index) {
            quiz.index = index;
            quiz.isNew = false;
            dispatch({type: BIKE_RACE_SET_EDIT_QUIZ, quiz});
        }
        dispatch(actionCreators.showQuizForm(true));
    },
    getGame: (id: string): TA => async (dispatch) => {
      dispatch(gamesActions.getGame(id, true, 'BikeRace'));
      dispatch(errorActions.resetAllErrors(reducerName));
    },
    createQuiz: (quiz: BikeRaceQuiz): TA => async(dispatch, getState) => {
        const url = `${_baseUrl}/quiz`;

        const game = getState().bikeRaceState.game;
        const gameId = game && game.id;

        dispatch({type: BIKE_RACE_CREATE_QUIZ_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const formData = createBikeRaceQuizFormData(quiz, gameId);

        axios.post(url, formData, CreateAuthHeader(getState))
            .then(response => {
                if(!gameId) {
                    dispatch(actionCreators.saveStyle());
                    history.push(`/workshop/BikeRace/edit/${response.data.id}`);
                }
               dispatch({type: BIKE_RACE_CREATE_QUIZ_RESPONSE, data: response.data});
                dispatch({type: BIKE_RACE_SET_EDIT_QUIZ, quiz: initialState().editQuiz});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createQuiz', error));

            });
    },
    updateQuiz: (quiz: BikeRaceQuiz, quizIndex: number): TA => async (dispatch, getState) => {
        const game = getState().bikeRaceState.game;
        const gameId = game && game.id;
        if (!gameId) {
            return;
        }
        dispatch({type: BIKE_RACE_UPDATE_QUIZ_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const formData = updateBikeRaceQuizFormData(quiz, quizIndex, gameId);
        const url = `${_baseUrl}/quiz`;

        axios.put(url, formData, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: BIKE_RACE_UPDATE_QUIZ_RESPONSE, data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateQuiz', error));
            })

    },
    setQuestionTextField: (field: string, value: string|number|null): TA => async (dispatch) => {
        dispatch({type: BIKE_RACE_SET_TEXT_FIELD, field, value});
    },
    showColorPicker: (show: boolean): TA => async (dispatch) => {
        dispatch({type: BIKE_RACE_SHOW_COLOR_PICKER, show});
    },
    showAudioForm: (show: boolean): TA => async (dispatch) => {
        dispatch({type: BIKE_RACE_SHOW_AUDIO_FORM, show});
    },
    setEditField: (field: string, value: string|number|boolean|ImageUploadType|SoundUploadType|null|undefined): TA => async (dispatch) => {
        dispatch({type: BIKE_RACE_SET_FIELD, field, value});
        if (field === 'sound') {
            dispatch({type: BIKE_RACE_SHOW_AUDIO_FORM, show: false});
        }
    },
    setSetting: (e: ChangeEvent<string>|ChangeEvent<number>): TA => async(dispatch) => {
      dispatch({type: BIKE_RACE_SET_SETTING, field: e.target.name, value: e.target.value});
    },
    setAnswer: (correct: boolean): TA => async (dispatch) => {
        dispatch({type: BIKE_RACE_SET_ANSWER, correct});
    },
    deleteQuiz: (quizIndex: number): TA => async (dispatch, getState) => {
        const game = getState().bikeRaceState.game;
        const gameId = game && game.id;
        if (!gameId) {
            return;
        }
        dispatch({type: BIKE_RACE_DELETE_QUIZ_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `${_baseUrl}/${gameId}/quiz/${quizIndex}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: BIKE_RACE_DELETE_QUIZ_RESPONSE, data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteQuiz', error));
            })
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: BikeRaceState, action: any) => BikeRaceState} = {
    ...abstractGameReducerMethods,
    BIKE_RACE_RESET: () => {
        return initialState();
    },
    BIKE_RACE_APPLY_STYLE: (state, action) => {
        return{
            ...state,
            editQuiz: bikeRaceTransformQuizToStyle(state.editQuiz, state.style)
        }
    },
    BIKE_RACE_SAVE_STYLE: (state, action) => {
        return{
            ...state,
            style: action.newStyle
        }
    },
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            editQuiz: initialState().editQuiz
        }
    },
    BIKE_RACE_SET_SETTING: (state, action) => {
      if (!state.game) {
          return state;
      }
      return {
          ...state,
          game: {
              ...state.game,
              [action.field]: action.value
          }
      }
    },
    BIKE_RACE_SHOW_QUIZ_FORM: (state, action: {show: boolean}) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                showQuizForm: action.show
            }
        }
    },
    BIKE_RACE_SET_TEXT_FIELD: (state, action: {field: string; value: number|string}) => {
        return {
            ...state,
            editQuiz: {
                ...state.editQuiz,
                question: {
                    ...state.editQuiz.question,
                    text: {
                        ...state.editQuiz.question.text,
                        [action.field]: action.value
                    }
                }
            }
        }
    },
    BIKE_RACE_SHOW_DELETE_FORM: (state, action) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                showQuizDelete: action.show
            }
        }
    },
    BIKE_RACE_SHOW_COLOR_PICKER: (state, action: {show: boolean}) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                showColorPicker: action.show
            }
        }
    },
    BIKE_RACE_SHOW_AUDIO_FORM: (state, action: {show: boolean}) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                showAudioForm: action.show
            }
        }
    },
    BIKE_RACE_SET_EDIT_QUIZ: (state, action: {quiz: EditQuizType}) => {
      return {
          ...state,
          editQuiz: action.quiz
      }
    },
    BIKE_RACE_SET_FIELD: (state, action: {field: string; value: number|string}) => {
        return {
            ...state,
            editQuiz: {
                ...state.editQuiz,
                question: {
                    ...state.editQuiz.question,
                    [action.field] : action.value
                }
            }
        }
    },
    BIKE_RACE_CREATE_QUIZ_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                createQuiz: true
            }
        }
    },
    BIKE_RACE_CREATE_QUIZ_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            editQuiz: initialState().editQuiz,
            ui: {
                ...state.ui,
                showQuizForm: false
            },
            isLoading: {
                ...state.isLoading,
                createQuiz: false
            }
        }
    },
    BIKE_RACE_UPDATE_QUIZ_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                updateQuiz: true
            }
        }
    },
    BIKE_RACE_UPDATE_QUIZ_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            editQuiz: initialState().editQuiz,
            ui: {
                ...state.ui,
                showQuizForm: false
            },
            isLoading: {
                ...state.isLoading,
                updateQuiz: false
            }
        }
    },
    BIKE_RACE_SET_ANSWER: (state, action: {correct: boolean}) => {
        return {
            ...state,
            editQuiz: {
                ...state.editQuiz,
                correctAnswer: action.correct
            }
        }
    },
    BIKE_RACE_DELETE_QUIZ_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                deleteQuiz: true
            }
        }
    },
    BIKE_RACE_DELETE_QUIZ_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                showQuizDelete: false,
                showQuizForm: false
            },
            isLoading: {
                ...state.isLoading,
                deleteQuiz: false
            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<BikeRaceState, any> = (state, action) => {
    state = state || initialState();
    const method = reducerMethods[action.type];
    if (method) {
        return method(state, action);
    }
    return state;
};