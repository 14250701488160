import {Action, applyMiddleware, combineReducers, compose, createStore, Reducer} from 'redux';
import thunk, {ThunkAction} from 'redux-thunk';
import * as Auth from './Auth';
import * as Feed from './Feed';
import * as Account from './Account';
import * as Me from './Me';
import * as Error from './Error';
import * as Navigation from './Navigation';
import * as MyGames from './MyGames';
import * as Memory from './games/Memory';
import * as DangerInTheJungle from './games/DangerInTheJungle';
import * as PictureBook from './games/PictureBook';
import * as QuizNinja from './games/QuizNinjaStore';
import * as SmackTheFly from './games/SmackTheFlyStore';
import * as PlayList from './PlayListStore';
import * as Games from './games/Games';
import * as Organization from './OrganizationStore';
import * as Library from './LibraryStore';
import * as SplatterSpaceStore from './games/SplatterSpaceStore';
import * as BikeRaceStore from './games/BikeRaceStore';
import * as TranslationStore from './TranslationStore';
import * as TagStore from './TagStore';
import * as ApiTestStore from './ApiTestStore';
import * as MessageStore from './MessageStore';
import * as UnitStore from './ActiveFloorUnit';
import * as FeaturedGameStore from './FeaturedGameStore';
import * as BundleStore from './GlobalComponentStore';
import * as SlimeBattleStore from './games/SlimeBattleStore';
import * as CrazyBalloonsStore from './games/CrazyBalloonsStore';
import * as SweetyWarStore from './games/SweetyWarStore';
import * as GoalShowStore from './games/GoalShowStore';
import * as BlockBusterStore from './games/BlockBusterStore';
import * as CircusRaceStore from './games/CircusRaceStore';
import * as JigsawPuzzleStore from './games/JigsawPuzzleStore';
import * as BuzzItStore from './games/BuzzItStore';
import * as WosStore from './games/WarOfStrategy';
import * as SuperSorterStore from './games/SuperSorterStore';
import * as PresentationStore from './games/PresentationStore';
import * as WhackAMoleStore from './games/WhackAMoleStore';
import * as MeditationStore from './games/MeditationStore';
import * as RaceCarStore from './games/RaceCarStore';
import * as FeedTheFoxStore from './games/FeedTheFoxStore';
import * as PizzaPartyStore from './games/PizzaPartyStore';
import * as PopItStore from './games/PopItStore';
import * as DanceASlothStore from './games/DanceASlothStore';
import * as MadMonstersStore from './games/MadMonstersStore';   
import * as WordFinderStore from './games/WordFinderStore';  

import {History} from 'history';

import {  localizeReducer } from "react-localize-redux";

export type TA = ThunkAction<void, AppState, null, Action<string>>;

export interface ChangeEvent<T> {target: {name: string; value: T}}

const reducers = {
    localize: localizeReducer,
    auth: Auth.reducer,
    feed: Feed.reducer,
    account: Account.reducer,
    me: Me.reducer,
    errors: Error.reducer,
    navigation: Navigation.reducer,
    mygames: MyGames.reducer,
    playlist: PlayList.reducer,
    gamesState: Games.reducer,
    organization: Organization.reducer,
    libraryState: Library.reducer,
    memory: Memory.reducer,
    ditj: DangerInTheJungle.reducer,
    picturebook: PictureBook.reducer,
    quizninja: QuizNinja.reducer,
    smackthefly: SmackTheFly.reducer,
    splatterspace: SplatterSpaceStore.reducer,
    bikeRaceState: BikeRaceStore.reducer,
    // eslint-disable-next-line
    translationState: TranslationStore.reducer,
    tagState: TagStore.reducer,
    apiTestState: ApiTestStore.reducer,
    message: MessageStore.reducer,
    activefloorunits: UnitStore.reducer,
    // eslint-disable-next-line
    featured_game_store: FeaturedGameStore.reducer,
    globalComponentState: BundleStore.reducer,
    sweetyWarState: SweetyWarStore.reducer,
    goalShowState: GoalShowStore.reducer,
    crazyBalloonsState: CrazyBalloonsStore.reducer,
    slimeBattleState: SlimeBattleStore.reducer,
    circusRaceState: CircusRaceStore.reducer,
    blockBusterState: BlockBusterStore.reducer,
    jigsawPuzzleState: JigsawPuzzleStore.reducer,
    buzz_it_store: BuzzItStore.reducer,
    wosState: WosStore.reducer,
    supersorter: SuperSorterStore.reducer,
    presentation: PresentationStore.reducer,
    whackAMoleState: WhackAMoleStore.reducer,
    meditationState: MeditationStore.reducer,
    raceCarState: RaceCarStore.reducer,
    feedTheFoxState: FeedTheFoxStore.reducer,
    pizzaPartyState: PizzaPartyStore.reducer, 
    popItState: PopItStore.reducer,
    danceASlothState: DanceASlothStore.reducer, 
    madMonstersState: MadMonstersStore.reducer, 
    wordFinderState: WordFinderStore.reducer
};

const appReducer = combineReducers({
    ...reducers
});


export type AppState = ReturnType<typeof appReducer>

export default function configureStore(history: History<unknown>, initialState: AppState) {

    const middleware = [
        thunk
    ];

    const LOGOUT = 'LOGOUT';
    const rootReducer: Reducer<AppState, Action> = (state, action) => {
        if (action.type === LOGOUT){
            localStorage.removeItem("myfloorjwt");
            localStorage.removeItem("myfloorjwt_expire");
            if(state === undefined) return initialState;
            const { localize } = state;
            state = { ...initialState, localize };
        }

        return appReducer(state, action);
    };

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    //eslint-disable-next-line
    if (isDevelopment && typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        //eslint-disable-next-line
        enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
