import './index.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {createBrowserHistory} from 'history';
import configureStore from './store/configureStore';
import App from './App';
import {LocalizeProvider, initialize, addTranslationForLanguage, setActiveLanguage} from 'react-localize-redux';
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import daTranslations from './localization/da.json';
import enTranslations from './localization/en.json';
import iwTranslations from './localization/iw.json';
import arTranslations from './localization/ar.json';
import esTranslations from './localization/es.json';
import nlTranslations from './localization/nl.json';
import noTranslations from './localization/no.json';
import svTranslations from './localization/sv.json';
import frTranslations from './localization/fr.json';
import ltTranslations from './localization/lt.json';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";


import { renderToStaticMarkup } from 'react-dom/server';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';
export const history = createBrowserHistory({basename: baseUrl});

// Get the application-wide store instance,
// prepopulating with state from the server where available.
// eslint-disable-next-line
const initialState = (window as any).initialReduxState;
const store = configureStore(history, initialState);

const activeLang = localStorage.getItem("myfloor_lang") || 'en';
store.dispatch(initialize({
  languages: ['da', 'en', 'iw', 'ar','es','nl','no','sv','fr','lt'],
  options: { renderToStaticMarkup }
}));
store.dispatch(addTranslationForLanguage(daTranslations, "da"));
store.dispatch(addTranslationForLanguage(enTranslations, "en"));
store.dispatch(addTranslationForLanguage(iwTranslations, "iw"));
store.dispatch(addTranslationForLanguage(arTranslations, "ar"));
store.dispatch(addTranslationForLanguage(esTranslations, "es"));
store.dispatch(addTranslationForLanguage(nlTranslations, "nl"));
store.dispatch(addTranslationForLanguage(noTranslations, "no"));
store.dispatch(addTranslationForLanguage(svTranslations, "sv"));
store.dispatch(addTranslationForLanguage(frTranslations, "fr"));
store.dispatch(addTranslationForLanguage(ltTranslations, "lt"));
store.dispatch(setActiveLanguage(activeLang));

const rootElement = document.getElementById('root');

ReactDOM.render(
  <LocalizeProvider store={store}>
    <Provider store={store}>
      <Router history={history}>
          <DndProvider backend={HTML5Backend}>
              <App />
          </DndProvider>
      </Router>
    </Provider>
  </LocalizeProvider>,
  rootElement);

//registerServiceWorker();
