import * as React from 'react'
import {Translate} from 'react-localize-redux';
import { FetchError } from '../../services/FetchHelper';
import {ErrorType} from '../../store/Error';

interface Props {
    error?: false | ErrorType;
    fetchError?: FetchError;
}

export const FormGeneralError = (props: Props) => {
    //Added fetch error after the fact, convert to known format is set..
    const {error: _error, fetchError} = props;
    const error: ErrorType | undefined =  _error ? _error : fetchError  ? { status: fetchError.status } : undefined; 

    if(!error) return null;
    if(!error.status) return null;

    if(error.status === 400){
        return null;
    }

    if(error.status === 401){
        return(
            <div className='form-error general-error'><Translate id='unauthorized_error'/></div>
        )
    }

    if(error.status === 404){
        return(
            <div className='form-error general-error'><Translate id='notfound_error'/></div>
        )
    }

    if(error.status === 500){
        return(
            <div className='form-error general-error'><Translate id='server_error'/></div>
        )
    }

    return(
        <div className='form-error general-error'><Translate id='unknown_error'/></div>
    )
};