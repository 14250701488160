import React from 'react';

const EULA = () => {
    return(
        <>
            <h2>ActiveFloor<br />
                SOFTWARE LICENSE AGREEMENT</h2>
            <br />
            This Software License Agreement is made as of&nbsp; between <strong>ActiveFloor ApS </strong>(<strong>ActiveFloor</strong>), a corporation organized under the laws of&nbsp; Denmark, with its principal place of business at Helgesh&oslash;j alle 16, 2630 Taastrup and customer and user signed into the ActiveFloor MyWall system.<br />
            &nbsp;<br />
            This Software License Agreement, contain the terms and conditions under which Customer agrees to license the Software from ActiveFloor.
            <h3><br />
                1. DEFINITIONS.</h3>
            The following terms shall have the meanings set forth below: &ldquo;Agreement&rdquo; means the combination of the terms and conditions set forth in this Software License Agreement and its exhibits together with the terms of each Supplemental Schedule made a part hereof.<br />
            <strong>&ldquo;Documentation&rdquo; </strong>means the published documentation, including electronic and written aids and speci-fications developed by ActiveFloor or ActiveFloor&rsquo; licensors in relation to the Software, that Ac-tiveFloor makes generally available for the Software.<br />
            <strong>&ldquo;Licenskey(s)&rdquo; </strong>means a special security program owned and controlled by ActiveFloor required to render the Software operable.<br />
            <strong>&ldquo;Product&rdquo;</strong> means the Software; and any hardware components described in Supplemental Schedule A delivered by ActiveFloor ApS (&ldquo;ActiveFloor&rdquo;) or a reseller thereof as part of the full delivery of a product package (<strong>&ldquo;Hardware&rdquo;</strong>).<br />
            <strong>&ldquo;Reseller&rdquo;</strong> means a reseller of ActiveFloor, which by direct agreement or as sublicensee to a licensee of ActiveFloor has been granted the right to sell the Software to Customers.<br />
            <strong>&ldquo;Software&rdquo; </strong>means (i) all software products identified in a Supplemental Schedule; (ii) the related doc-umentation; (iii) the related Updates and Upgrades to such software products and documentation; (iv) modifications and improvements of such software products, documentation, Updates and Upgrades; and (v) all copies of the foregoing.<br />
            &ldquo;Supplemental Schedule&rdquo; or &ldquo;Supplemental Schedule(s)&rdquo; shall have the meaning as set forth in Sec-tion 2, below.<br />
            <strong>&ldquo;Updates&rdquo;</strong> means error corrections and maintenance releases to the Software which have been devel-oped by ActiveFloor, ActiveFloor&rsquo;s Licensor or ActiveFloor&rsquo;s Licensee as applicable.<br />
            <strong>&ldquo;Upgrades&rdquo;</strong> means Software enhancements that accomplish incidental, structural, functional, and per-formance improvements.

            <h3><br />
                2. SOFTWARE.</h3>
            ActiveFloor ApS (or, as applicable, ActiveFloor or Reseller) will furnish to Customer and Customer will accept and pay for the Software as identified in Supplemental Schedule A to this Agreement.

            <h3><br />
                3. OWNERSHIP.</h3>
            Customer acknowledges that the Software and all copyright, trade secrets and other right, title and interest therein, are the sole property of ActiveFloor ApS (or ActiveFloor&rsquo; li-censors) and that Customer shall gain no right, title or interest in the Software by virtue of this Agree-ment other than the nonexclusive time-limited right of use granted herein. Without limiting the forego-ing, Customer specifically acknowledges ActiveFloor&rsquo;s exclusive rights to ownership of any modifica-tion, translation or adaptation of the Software and any other improvement or development based there-on, which is developed, supplied, installed or paid for by or on behalf of Customer, and further under-takes to disclose the same to Customer.

            <h3><br />
                4. LICENSE OF SOFTWARE.</h3>
            In consideration of Customer&rsquo;s payment of the Software li-cense fees specified in a Supplemental Schedule, ActiveFloor Aps hereby grants to Customer a non-transferable, time-limited and nonexclusive right and license during the Term to use the Software. Cus-tomer represents, warrants and agrees that the Software will be used only in accordance with the terms, conditions and limitations set forth in this Software License Agreement, and only for the benefit of Cus-tomer.

            <h3><br />
                6. SERVICES EXCLUDED.</h3>
            This Agreement covers only the right to use the Software. To the extent Customer requires any other related services not specifically addressed in the Agreement (e.g., Maintenance, software installation and configuration for Customer&rsquo;s use, custom programming or training), Customer may purchase such services (from ActiveFloor ApS, or a Reseller, as applicable) by separate contract.

            <h3><br />
                7. WARRANTY.</h3>
            ActiveFloor issues no warranty in relation to the Software other than what shall follow from applicable mandatory, directory law. As Customer&rsquo;s sole remedy and ActiveFloor&rsquo; sole obligation and liability in respect to a breach of this warranty, ActiveFloor will, at its option: (i) make commercially reasonable efforts to modify or replace any non-conformities in the Software; or (ii) refund Customer those license fees received by ActiveFloor from Customer or ActiveFloor&rsquo;s Reseller for the claiming Customer&rsquo;s use of the Software.<br />
            The foregoing warranties shall not apply to the extent that any alleged defect derives from a combina-tion of the Software with any program, equipment or device not supplied or recommended by Ac-tiveFloor.
            <h3><br />
                8. DISCLAIMER OF OTHER WARRANTIES.</h3>
            <strong>Except as provided in Section 7, all war-ranties, conditions, duties, representations and guarantees, whether express or implied, arising by law, custom, oral or written statements of ActiveFloor, ActiveFloor&rsquo; Reseller(s) or otherwise (in-cluding, but not limited to, any warranty or condition as to merchantability, satisfactory quality or fitness for particular purpose, or of error-free and uninterrupted use of any Software (includ-ing Updates and Upgrades) and non-infringement are hereby superseded, excluded and dis-claimed.</strong> Without limiting the generality of the foregoing, ActiveFloor makes no warranty that the Software will contain computer programs with characteristics or specifications desired by Customer or that such Software will be error free or uninterrupted, and Customer acknowledges that use of the Soft-ware is subject to provision of the Licenskey.

            <h3><br />
                9. REMEDY LIMITATIONS &ndash; LIMITATIONS OF LIABILITY.</h3>
            In no event shall Ac-tiveFloor be liable to the Customer for any: (a) loss of profits or loss of revenue; (b) loss of production or loss of contracts; (c) loss of goodwill; (d) third party claims; (e) loss of data or interruption in its use or availability; or (f) any other consequential, incidental or indirect loss. Furthermore, ActiveFloor shall not be liable for any decision made by Customer based on the results of the use of the Software made by Customer.<br />
            In any event (including without limitation if any exclusion or other provision contained in this clause shall be held ineffective for any reason), the maximum aggregate liability of ActiveFloor whether aris-ing from negligence, breach of contract, misrepresentation or otherwise shall not in any circumstances exceed the Software license fees payable to ActiveFloor by Customer or ActiveFloor&rsquo; Reseller for Cus-tomer&rsquo;s use of the Software under this Agreement.
            <h3><br />
                10. TERMINATION.</h3>
            ActiveFloor shall have the right to terminate this Agreement for cause upon thirty (30) calendar days&rsquo; written notice if Customer breaches any of its obligations under this Agreement and thereafter fails to cure such breach to the satisfaction of ActiveFloor within the first ten<br />
            <br />
            (10) days following Customer&rsquo;s receipt of such notice of termination. Customer shall, no later than the effective date of such termination: (i) purge all Software from all computer systems, terminals, person-al computers, storage media, and any and all other devices and files with which the Software is used; and (ii) if requested by ActiveFloor, certify to ActiveFloor in writing that it has complied with the fore-going obligations and has not provided total or partial copies of the Software to any third party.
            <h3><br />
                11. ASSIGNMENT.</h3>
            This Agreement is personal to Customer and neither this Agreement nor any of Customer&rsquo;s rights or duties hereunder may be assigned, sublicensed, sold or otherwise trans-ferred by Customer, including by operation of law or otherwise to any successor-in-interest to Custom-er&rsquo;s rights without ActiveFloor&rsquo; prior written consent (which consent shall not be unreasonably with-held).

            <h3><br />
                12. LICENSKEY(s).</h3>
            The Licenskey(s) are necessary to allow the Software to operate. Ac-tiveFloor shall (possibly through ActiveFloor&rsquo; Reseller) deliver the Licenskey(s) to Customer promptly after Customer&rsquo;s payment in full of the applicable fees as specified in each Supplemental Schedule. Any attempt by any person other than an authorized ActiveFloor representative to alter, remove or de-activate a Licenskey will be deemed a fraud by such person on ActiveFloor and a material breach by Customer of this Agreement. ActiveFloor may deliver temporary Licenskeys for interim access for a limited period.

            <h3><br />
                13. GOVERNING LAW AND JURISDICTION.</h3>
            This Agreement shall be governed by and interpreted in accordance with Danish law. Any controversies or disputes arising out of or relating to this Agreement that cannot be settled amicably, shall be resolved by binding arbitration in accordance with the Danish Institute of Arbitration Rules and the venue shall be the Danish Institute of Arbitration (Det Danske Voldgiftsinstitut), Copenhagen, Denmark.

            <h3><br />
                14. PAYMENT.</h3>
            Customer shall pay ActiveFloor or ActiveFloor&rsquo; Reseller (as the case may be) the applicable fees upon the signing of this Agreement by Customer. Payment shall be made in full, without deduction for any sales, use or other taxes or similar charges, which shall be borne exclusively by Customer and, to the extent required by applicable law, collected by ActiveFloor from Customer.

            <h3><br />
                15. SEVERANCE.</h3>
            Each of the provisions of this Agreement is distinct and severable from the others and if at any time one or more of such provisions is or becomes invalid, unlawful or unenforcea-ble (whether wholly or to any extent), the validity, lawfulness and enforceability of the remaining pro-visions (or the same provision to any other extent) of this Agreement shall not in any way be affected or impaired.

            <h3><br />
                16. MISCELLANEOUS.</h3>
            Customer shall not disclose the terms and conditions of this Agree-ment to third parties (except Customer&rsquo;s auditors, legal counsel or third parties whose review is man-dated by law) without the prior written authorization of ActiveFloor. No amendment of this Agreement or waiver of any rights hereunder shall be effective unless in writing and signed by an authorized repre-sentative of the party against whom enforcement is sought.

            <h3><br />
                17. RESELLER&rsquo;S AUTHORITY.</h3>
            ActiveFloor&rsquo; Reseller is authorized by ActiveFloor to exe-cute this Agreement on behalf of ActiveFloor, but:<br />
            (i) ActiveFloor&rsquo; Reseller has no other power or authority on behalf of ActiveFloor; and<br />
            (ii) this Agreement is contingent on its express confirmation by ActiveFloor itself through the signature below of a director of ActiveFloor.
            <h3><br />
                18. FORCE MAJEURE.</h3>
            Neither party shall be liable for any delays or failures in performance due to circumstances beyond its control.

            <h3><br />
                19. ENTIRE AGREEMENT.</h3>
            This Agreement contains the entire agreement and understanding of the parties as to the subject matter hereof and merges and supersedes all prior discussions and agree-ments with respect thereto. Any modification to this Agreement shall be in writing and signed by au-thorized representatives of the parties.<br />
            <br />
            Signature Acceptance<br />
            The acceptance of this Software license agreement is done electronic by customers at first user login on each individual ActiveFloor profile.<br />
            <br />
            &nbsp;
        </>
    )
};

export default EULA;