import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import Dialog from '../../../Dialog';
import {Translate} from 'react-localize-redux';
import {Link} from 'react-router-dom';
import * as GamesStore from '../../../../store/games/Games';
import dangerInTheJungleImage from '../../../../images/games/icons/icon_dangerinthejungle.png';
import memoryImage from '../../../../images/games/icons/icon_memory.png';
import pictureBookImage from '../../../../images/games/icons/icon_picturebook.png';
import quizNinjaImage from '../../../../images/games/icons/icon_quizninja.png';
import smackTheFlyImage from '../../../../images/games/icons/icon_smackthefly.png';
import bikeRaceImage from '../../../../images/games/icons/icon_bikerace.png';
import jigsawPuzzleImage from '../../../../images/games/icons/icon_jigsawpuzzle.png';
import buzzItImage from '../../../../images/games/icons/icon_buzzit.png'
import wosImage from '../../../../images/games/icons/icon_pirateislands.png'
import ssImage from '../../../../images/games/icons/icon_supersorter.png'
import presentationImage from '../../../../images/games/icons/icon_presentation.png'
import {AppState} from "../../../../store/configureStore";
import {GameType} from "../../../../model/Game/GameType";
import popitImage from '../../../../images/games/icons/icon_popit.png'
import wordFinderImage from '../../../../images/games/icons/icon_wordFinder.png'

interface Props{
    onClose: () => void;
    gamesState: GamesStore.GamesState;
}

const ChooseGame = (props: Props) => {

    const gameTypes = props.gamesState.activeGameTypes;
    if(!gameTypes) return null;

    const games: {key: GameType; node: ReactNode}[] = [
        {
            key: "Memory",
            node:
                <Link key='Memory' to={'/workshop/memory/create'} className='game-choice'>
                    <img src={memoryImage} alt='Memory'/>
                    <div className='game-name'><Translate id='memory_title' /></div>
                </Link>
        },
        {
            key: "DangerInTheJungle",
            node:
                <Link key='DangerInTheJungle' to={'/workshop/dangerinthejungle/create'} className='game-choice'>
                    <img src={dangerInTheJungleImage} alt='danger in the jungle'/>
                    <div className='game-name'><Translate id='ditj_title' /></div>
                </Link>
        },
        {
            key: "PictureBook",
            node:
                <Link key='PictureBook' to={'/workshop/picturebook/create'} className='game-choice'>
                    <img src={pictureBookImage} alt='PictureBook'/>
                    <div className='game-name'><Translate id='picturebook_title' /></div>
                </Link>
        },
        {
            key: "QuizNinja",
            node:
                <Link key='QuizNinja' to={'/workshop/quizninja/create'} className='game-choice'>
                    <img src={quizNinjaImage} alt='QuizNinja'/>
                    <div className='game-name'><Translate id='quizninja_title' /></div>
                </Link>
        },
        {
            key: "SmackTheFly",
            node:
                <Link key='SmackTheFly' to={'/workshop/smackthefly/create'} className='game-choice'>
                    <img src={smackTheFlyImage} alt='Smack the Fly'/>
                    <div className='game-name'><Translate id='smackthefly_title' /></div>
                </Link>
        },
        {
            key: "BikeRace",
            node:
                <Link key='BikeRace' to={'/workshop/bikerace/create'} className='game-choice'>
                    <img src={bikeRaceImage} alt='Bike Race' />
                    <div className='game-name'><Translate id='bike_race_title' /></div>
                </Link>
        },
        {
            key: "JigsawPuzzle",
            node:
                <Link key='JigsawPuzzle' to={'/workshop/jigsawpuzzle/Create'} className='game-choice'>
                    <img src={jigsawPuzzleImage} alt='JigsawPuzzle '/>
                    <div className='game-name'><Translate id='jigsaw_title' /></div>
                </Link>
        },
        {
            key: "BuzzIt",
            node:
                <Link key='BuzzIt' to={'/workshop/buzzit/Create'} className='game-choice'>
                    <img src={buzzItImage} alt='BuzzIt '/>
                    <div className='game-name'><Translate id='buzz_it_title' /></div>
                </Link>
        },
        {
            key: "WarOfStrategy",
            node:
                <Link key='WarOfStrategy' to={'/workshop/warofstrategy/Create'} className='game-choice'>
                    <img src={wosImage} alt='WarOfStrategy '/>
                    <div className='game-name'><Translate id='wos_title' /></div>
                </Link>
        },
        {
            key: "SuperSorter",
            node:
                <Link key='SuperSorter' to={'/workshop/supersorter/Create'} className='game-choice'>
                    <img src={ssImage} alt='SuperSorter '/>
                    <div className='game-name'><Translate id='super_sorter_title' /></div>
                </Link>
        },
        {
            key: "Presentation",
            node:
                <Link key='Presentation' to={'/workshop/presentation/Create'} className='game-choice'>
                    <img src={presentationImage} alt='Presentation '/>
                    <div className='game-name'><Translate id='presentation_title' /></div>
                </Link>
        },
        {
            key: "PopIt",
            node:
                <Link key='PopIt' to={'/workshop/popit/Create'} className='game-choice'>
                    <img src={popitImage} alt='Presentation '/>
                    <div className='game-name'><Translate id='pop_it_title' /></div>
                </Link>
        }, 
        {
            key: "WordFinder",
            node:
                <Link key='WordFinder' to={'/workshop/wordfinder/Create'} className='game-choice'>
                    <img src={wordFinderImage} alt='Presentation '/>
                    <div className='game-name'><Translate id='word_finder_title' /></div>
                </Link>
        }
    ];

    return(
        <Dialog onClose={props.onClose}>
            <h2><Translate id='choose_game'/></h2>
            <div className='game-chooser'>
                {games.map(g => gameTypes.includes(g.key) ? g.node : null)}
            </div>
        </Dialog>
    );
};

export default connect(
    (state: AppState) => ({ gamesState: state.gamesState})
)(ChooseGame);