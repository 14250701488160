import React, {ReactNode} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {actionCreators as meActions, MeState} from "../../store/Me";
import {actionCreators as navActions, NavigationState} from "../../store/Navigation";
import * as LibraryStore from '../../store/LibraryStore';
import {bindActionCreators} from "redux";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import profile from '../../images/missing-profile.png';
import {DbOrUploadImage} from "../../services/ImageHelper";
import {AuthState} from "../../store/Auth";
import {AppState} from "../../store/configureStore";
import {OrganizationState} from "../../store/OrganizationStore";
import {isOrganizationType} from "../../model/Organization";

interface Props{
    buttons: ReactNode;
    navigation: NavigationState;
    auth: AuthState;
    me: MeState;
    orgState: OrganizationState;
    meActions: typeof meActions;
    navActions: typeof navActions;
    libraryActions: typeof LibraryStore.actionCreators;
}

const TopMenu = (props: Props) => {

    const toggleEditProfile = () => {
        props.meActions.setShowEditForm(!props.me.ui.editForm);
    };

    const toggleProfileNav = () => {
        props.navActions.toggleProfileNav(!props.navigation.ui.showProfileNav);
    };

    const navigationAccount = (props: Props) => {
        const showingProfileNav = props.navigation.ui.showProfileNav;
        const meAccount = props.me.account;
        if(!meAccount) return false;
        const meOrg = props.orgState.organizations[meAccount.organizationId];

        return(
            <div className={`profile ${showingProfileNav ? 'active' : ''}`} onClick={toggleProfileNav}>
                <DbOrUploadImage image={meAccount.profileImage} tempImage={profile}/>
                <p className='name'>{meAccount.firstname} {meAccount.lastname}</p>
                <div className='profile-menu'>
                    <FontAwesomeIcon icon={showingProfileNav ? 'caret-up' : 'caret-down'} />
                </div>
                {showingProfileNav &&
                    <div className='profile-nav'>
                        <div className='menu-point-container' onClick={toggleEditProfile}>
                            <div className='menu-point'>
                                <FontAwesomeIcon icon='edit'/> <Translate id='menu_edit_profile'/>
                            </div> 
                        </div>
                        <Link to={`/library/profile/${meAccount.id}`} onClick={() => {props.libraryActions.ppSetId(meAccount.id);}}>
                            <div className='menu-point-container'>
                                <div className='menu-point'>
                                    <FontAwesomeIcon icon='user'/> <Translate id='menu_show_public_profile'/>
                                </div>
                            </div>
                        </Link>
                        {isOrganizationType(meOrg) &&
                        <Link to={`/discover/org/${meAccount.organizationId}`}>
                            <div className='menu-point-container'>
                                <div className='menu-point'>
                                    <FontAwesomeIcon icon='building'/> {meOrg.name}
                                </div>
                            </div>
                        </Link>
                        }

                        <Link to={'/logout'}>
                            <div className='menu-point-container'>
                                <div className='menu-point'>
                                    <FontAwesomeIcon icon='sign-out-alt'/><Translate id='menu_logout'/>
                                </div>
                            </div>
                        </Link>
                    </div>
                }
            </div>
        );
    };

    const account = props.auth.activetoken ? navigationAccount(props) : null;

    return (
        <div className='header-container'>
            <div className='header'>
                <div className='buttons'>
                    {props.buttons}
                </div>
                {account}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({auth: state.auth, me: state.me, navigation: state.navigation, orgState: state.organization}),
    dispatch => ({
        meActions: bindActionCreators(meActions, dispatch),
        navActions : bindActionCreators(navActions, dispatch),
        libraryActions: bindActionCreators(LibraryStore.actionCreators, dispatch),
    })
)(TopMenu);