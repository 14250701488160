import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import {Route, Redirect,  RouteProps} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {actionCreators as authActions, AuthState} from '../store/Auth';
import {Loading} from "./Loading";
import moment from "moment";
import {AppState} from "../store/configureStore";
import {MeState} from "../store/Me";

interface Props extends RouteProps{
    path: string;
    authState: AuthState;
    meState: MeState;
    setLayoutButtons: (n: ReactNode) => void;
}

const SecureRoute =(props: Props) => {
    let expired = false;
    const expiretime = moment(props.authState.expiretime || '');
    if(expiretime){
        if(expiretime.isBefore(moment.now()))
            expired = true;
    }
    if(!props.authState.activetoken || expired){
        let loginUrl = `/login?returnUrl=${props.location && props.location.pathname}`;
        if (props.path === "/") {
            loginUrl= "/login";
        }
        return(
            <Redirect to={loginUrl} />
        );
    }
    else{
        if(!props.meState.account) return <Loading visible={true}/>;//don't show anything till me has been loaded
        if((!props.meState.account.firstname || props.meState.eulaVersion !== props.meState.account.wallAcceptedEulaVersion) &&
            props.location && !props.location.pathname.startsWith("/firstvisit")
        ){ //If me doesn't have a firstname, redirect to firstvisit
            return <Redirect to={'/firstvisit'} />
        }
        else{
            return (
                <Route 
                    path={props.path} 
                    render={p => props.component && 
                        React.createElement(props.component,{
                            ...p,
                            setLayoutButtons: props.setLayoutButtons, 
                            me: props.meState.account
                        }
                    )} 
                />
            );
        }

    }
        
};


export default connect(
    (state: AppState) => ({authState: state.auth, meState: state.me}),
    (dispatch) => ({authActions: bindActionCreators(authActions, dispatch)})
)(SecureRoute)
