import React, {useEffect} from "react";
import * as OrganizationStore from "../../store/OrganizationStore";
import {MinimalOrganization} from "../../model/Response/MinimalOrganization";
import { history } from "../..";

interface Props{
    orgActions: typeof OrganizationStore.actionCreators;
    orgId: string;
    miniOrg?: MinimalOrganization;
}

export const OrganizationName = (props: Props) => {

    useEffect(() => {
        if(props.orgId){
            props.orgActions.getMinimalOrg(props.orgId, false);
        }
    }, [props.orgActions, props.orgId]);

    return(
        
        <div className='org-name' dir='auto' >
            <a 
                onClick={e => { e.preventDefault(); history.push(`/discover/org/${props.orgId}`)}} 
                href={`/discover/org/${props.orgId}`}
            >
                {props.miniOrg && props.miniOrg.name}
            </a>
        </div>
    )
};