import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Bundle } from '../../model/Bundle';
import { useFetchGet } from '../../services/FetchHelper';
import { DictGetValues } from '../../services/JsDict';
import { SecureLayoutComponentProps } from '../Layout';
import TopMenuButton from '../Menu/TopMenuButton';
import BundleIcon from '../ModelPreview/BundleIcon';
import ItemContainer from '../ModelPreview/ItemContainer';
import BundleEdit from './BundleEdit';

interface Props extends SecureLayoutComponentProps{
}

const BundleIndex = (props: Props) => {
    const {setLayoutButtons, me} = props;
    const [bundles, setBundles] = useState<Bundle[]>([]);
    const [showCreate, setShowCreate] = useState(false);

    const [getBundles, loading, error] = useFetchGet<{ [key: string]: Bundle }>(r => r && setBundles(DictGetValues(r)));

    useEffect(() => {
        setLayoutButtons(
            <>
                <TopMenuButton action={() => setShowCreate(true)} icon={'plus'} hoverMsg={'bundles_new'} />
            </>
        );
        
        //on component unmount reset topmenu
        return () => {
            setLayoutButtons(undefined);
        };
    }, [setLayoutButtons]);

    useEffect(() => {
        getBundles('/api/bundle');
    }, [getBundles]);
    
    if(!me) return null;

    return(
        <div className='bundle-index'>
            <ItemContainer 
                heading={<h1><Translate id='bundles_my_bundles' /></h1>} 
                items={bundles.filter(b => b.ownerId === me.id)}
                loading={loading}
                itemRender={b => <BundleIcon key={b.id} bundle={b} />}
                error={error}
            />
            <ItemContainer
                heading={<h1><Translate id='bundles_author_bundles' /></h1>}
                items={bundles.filter(b => b.authorIds && b.authorIds.find(x => x === me.id))}
                loading={loading}
                itemRender={b => <BundleIcon key={b.id} bundle={b} />}
                error={error}
            />
            {showCreate && 
                <BundleEdit 
                    onClose={() => setShowCreate(false)}
                    onSuccess={addedBundle => setBundles(b => [...b, addedBundle])}
                />
            }
        </div>
    )
}

export default BundleIndex;