import { 
    AbstractGameIsLoading, 
    abstractGameReducerMethods, AbstractGameState, 
    AbstractGameUi, 
    actionCreators as gamesActions 
} from './Games'; 
import {errorActions} from "../Error"; 
import {ChangeEvent, TA} from "../configureStore"; 
import {Reducer} from "redux"; 
import { MadMonstersGame } from '../../model/Game/MadMonsters/MadMonstersGame'; 
 
 
export const reducerName = 'madMonstersState'; 
 
export interface MadMonstersState extends AbstractGameState{ 
    game?: MadMonstersGame; 
    isLoading: AbstractGameIsLoading; 
    ui: AbstractGameUi; 
} 
 
const initialState: MadMonstersState = { 
    game: undefined, 
    gameStats: undefined, 
    gameType: "MadMonsters", 
    isLoading: { 
        getGame: false, addAuthor: false, removeAuthor: false, transferOwner: false, updateSettings: false 
    }, 
    ui: { showSettings: false} 
}; 
 
export const actionCreators = { 
    resetGame: (): TA => async(dispatch) => { 
        dispatch({type: 'MAD_MONSTERS_RESET'}); 
    }, 
    setField: (e: ChangeEvent<string>|ChangeEvent<number>|ChangeEvent<boolean>): TA => async(dispatch) => { 
        dispatch({ type: 'MAD_MONSTERS_SET_FIELD', name: e.target.name, value: e.target.value}); 
    }, 
    getGame: (id: string): TA => async(dispatch,) => { 
        dispatch(gamesActions.getGame(id, true, 'MadMonsters')); 
        dispatch(errorActions.resetAllErrors(reducerName)); 
    } 
}; 
 
// eslint-disable-next-line 
const reducerMethods: { [key: string]: (state: MadMonstersState, action: any) => MadMonstersState} = { 
    ...abstractGameReducerMethods, 
    MAD_MONSTERS_RESET: () => { 
        return initialState; 
    }, 
    MAD_MONSTERS_SET_FIELD: (state, action) => { 
        if(!state.game) return state; 
        return{ 
            ...state, 
            game:{ 
                ...state.game, 
                [action.name]: action.value 
            } 
        } 
    }, 
    ERROR: (state, action) => { 
        if (action.reducer !== reducerName) return state; 
        return { 
            ...state, 
            isLoading: { 
                ...state.isLoading, 
                [action.key]: false 
            }, 
        }; 
    } 
}; 
 
// eslint-disable-next-line 
export const reducer: Reducer<MadMonstersState, any> = (state, action) => { 
    state = state || initialState; 
    const method = reducerMethods[action.type]; 
    if (method) return method(state, action); 
    return state; 
};