import axios from 'axios';
import {CreateAuthHeader} from "../services/AxiosHelper";
import {Reducer} from "redux";
import {IPost} from "../model/Feed/IPost";
import {TA} from "./configureStore";

export const reducerName = "feed";

const REQUEST_FEED = "REQUEST_FEED";
const RECEIVED_FEED = "RECEIVED_FEED";
const ERROR_FEED = "ERROR_FEED";
const FEED_RESET = 'FEED_RESET';

export interface FeedState {
    posts: IPost[];
    offset: number;
    fetchError: boolean;
    isLoading: {
        requestFeed: boolean;
    };
}

const initialState: FeedState = {
    posts: [],
    offset: 0,
    fetchError: false,
    isLoading: {requestFeed: false}
};

export const actionCreators = {
    requestFeed: (reset: boolean): TA => async (dispatch, getState) => {
        if(reset) dispatch({type: FEED_RESET});
        if(getState().feed.isLoading.requestFeed) return;
        dispatch({ type: REQUEST_FEED });
        const offset = getState().feed.offset;
        const url = `api/feed/${offset}`;
        axios.get(url,CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: RECEIVED_FEED, data: response.data });
            })
            .catch(error => {
                dispatch({type: ERROR_FEED});
            });
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: FeedState, action: any) => FeedState} = {
    FEED_RESET: () => {
        return initialState;
    },
    REQUEST_FEED: (state) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading, 
                requestFeed: true,
            }
        }
    },
    RECEIVED_FEED: (state, action) => {
        return {
            ...state,
            posts: state.posts.concat(action.data),
            offset: state.offset + action.data.length,
            fetchError: false,
            isLoading: {
                ...state.isLoading,
                requestFeed: false,
            }
        };
    },
    ERROR_FEED: (state) => {
        return {
            ...state,
            fetchError: true,
            isLoading: {
                ...state.isLoading,
                requestFeed: false,
            }
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<FeedState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};