import { Bundle } from "../Bundle";
import { BaseGame } from "../Game/BaseGame";
import { PlayListType } from "../PlayListType";
import { ScheduledAction } from "../Schedule/ScheduledAction";
import { SortedItem } from "./SortedItem";

export interface Wall{
    id: string;
    apiKey: string;
    organizationId: string;
    name: string;
    isRightToLeftFlow: boolean;

    createdDateTime: string;
   
    sortedPlaylists: Array<SortedItem<PlayListType>>;
    sortedBundles: Array<SortedItem<Bundle>>;
    sortedGames: Array<SortedItem<BaseGame>>;

    scheduledActions: Array<ScheduledAction>;
}

export const isBaseGame = (item?: BaseGame|PlayListType|Bundle): item is BaseGame => {
    return !!item && 'gameType' in item;
}

export const isPlaylistType = (item?: BaseGame | PlayListType | Bundle): item is PlayListType => {
    return !!item && 'games' in item;
}

export const isBundle = (item?: BaseGame|PlayListType|Bundle): item is Bundle => {
    return !!item && 'sortedPlaylists' in item;
}