import React from 'react';
import {BuzzItGame} from "../../../model/Game/BuzzIt/BuzzItGame";
import {BuzzItQuiz} from "../../../model/Game/BuzzIt/BuzzItQuiz";
import {BuzzItQuizPreview} from "./BuzzItQuizPreview";

interface Props{
    game: BuzzItGame;
    onQuizClick?: (quiz: BuzzItQuiz, index: number) => void;
}

export const BuzzItGamePreview = (props: Props) => {

    const game = props.game;

    return(
        <div className='quizzes'>
            {game && game.quizzes && game.quizzes.map((q, i) =>
                <BuzzItQuizPreview
                    key={i}
                    quiz={q}
                    onClick={() => props.onQuizClick && props.onQuizClick(q, i)}/>
            )}
            <div className={'clear-fix'} />
        </div>
    )
};