import React from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import * as GamesStore from "../../../store/games/Games";
import * as WarOfStrategyStore from '../../../store/games/WarOfStrategy';
import {bindActionCreators} from "redux";
import {SelectList, SubmitButton} from "../../forms/FormGroup";
import {Translate} from "react-localize-redux";
import {AppState} from "../../../store/configureStore";

interface Props {
    wosState: WarOfStrategyStore.WarOfStrategyState;
    gamesActions: typeof GamesStore.actionCreators;
    wosActions: typeof WarOfStrategyStore.actionCreators;
}

const WarOfStrategySettings = (props: Props) => {
    if(!props.wosState.ui.showSettings) return null;
    const game = props.wosState.game;
    if(!game) return null;
    const settings = {
        skin: game.skin || 'Default'
    };

    const skinOptions = [
        {name: 'Default',    value: 'Default'   },
        {name: 'Sea',        value: 'Sea'       },
        {name: 'Bloom Boom', value: 'Bloom Boom'}
    ];

    return(
        <Dialog className='settings' onClose={() => {props.gamesActions.showSettings(game.gameType, false)}} loading={props.wosState.isLoading.updateSettings} >
            <h2><Translate id='settings'/></h2>
            <form onSubmit={e => e.preventDefault()}>
                <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.wosActions.setField}/>
                <h3><Translate id='preview_skin'/></h3>
                <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
                <div className='clear-fix' />
                <SubmitButton
                    text='submit'
                    onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ wosState: state.wosState }),
    dispatch => ({
        wosActions: bindActionCreators(WarOfStrategyStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(WarOfStrategySettings);