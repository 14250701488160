import axios from "axios";
import {errorActions} from "./Error";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {actionCreators as orgActions} from "./OrganizationStore";
import {AccountType, isTempAccount} from "../model/AccountType";
import { Organization } from "../model/Organization";
import { PermissionType } from "../model/Permission/PermissionType";
import { Reducer} from "redux";
import {TA} from "./configureStore";
import {actionCreators as msgActions} from "./MessageStore";

export const reducerName = 'account';

export interface TempAccount {
    id: string; 
    loading: boolean;
}

export interface AccountState {
    readonly accounts: {[id: string]: TempAccount|AccountType};
    readonly accountsByOrg?: {[id: string]: TempAccount|AccountType};
    readonly showSearch: boolean;
    readonly searchString: string;
    readonly searchStringResult: string;
    readonly searchResult: {resultCount: number; accounts: AccountType[]};
    readonly chooseOrganizationFilter: string;
    readonly followers: {[key: string]: {resultCount: number; accounts: AccountType[]}};
    readonly newAccount: {
        readonly email: string;
    };
    readonly ui: {
        readonly showCreateAccount: boolean;
        readonly showEditAccount: boolean;
        readonly showConfirmRemoveAuthor: false | string;
    };
    readonly isLoading: {
        readonly getByOrganization: boolean;
        readonly create: boolean;
    };
}

const initialState: AccountState = {
    accounts: {},
    showSearch: false,
    searchString: "",
    searchStringResult: "",
    searchResult: {resultCount: 0, accounts: []},
    chooseOrganizationFilter: "",
    newAccount: {
        email: ""
    },
    followers: {},
    ui:{
        showCreateAccount: false,
        showEditAccount: false,
        showConfirmRemoveAuthor: false,
    },
    isLoading:{
        getByOrganization: false,
        create: false
    }
};

export const actionCreators = {
    deleteResponse: (accountId: string, accountEmail: string): TA => async(dispatch, getState) => {
        dispatch({ type: 'ACCOUNT_DELETE_RESPONSE', accountId: accountId });
        dispatch(msgActions.addMessage("pop_deleted", "pop_msg_user_deleted", { email: accountEmail }));
    },
    updateRespose: (accountBefore: AccountType, accountResponse: AccountType): TA => async(dispatch, getState) => {
        dispatch({ type: 'ACCOUNT_UPDATE_RESPONSE', data: accountResponse });
        if (
            accountBefore && !isTempAccount(accountBefore) &&
            ((accountBefore.permissions || []).some(p => (accountResponse.permissions || []).indexOf(p) === -1) ||
                (accountResponse.permissions || []).some(p => (accountBefore.permissions || []).indexOf(p) === -1))
        ) {
            dispatch(
                msgActions.addMessage(
                    "pop_permissions",
                    "pop_msg_user_permission_change",
                    { email: accountResponse.email }
                )
            );
        }
    },
    create: (email: string, orgId: string): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: 'ACCOUNT_CREATE_REQUEST'});

        const url = 'api/account';
        const data = { email: email, organizationId: orgId };
        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'ACCOUNT_CREATE_RESPONSE', data: response.data});
                dispatch(msgActions.addMessage("pop_added", "pop_msg_user_created", {email: email}));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'create', error));
            })
    },
    get: (id: string, forceRefresh: boolean): TA => async (dispatch, getState) => {
        const hasAlready = getState().account.accounts[id];
        if(hasAlready && !forceRefresh) return;

        const tempAccount = hasAlready || {id: id, loading: true};
        dispatch({ type: 'ACCOUNT_REQUEST', tempAccount });

        const url = `api/account/${id}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: 'ACCOUNT_RECEIVED', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'get', error));
            });
    },
    getFollowers: (id: string): TA => async(dispatch, getState) => {
        const url = `api/account/${id}/followers`;
        axios.get(url, CreateAuthHeader(getState))
            .then(r => dispatch({type: 'ACCOUNT_GET_FOLLOWERS_RESPONSE', data: r.data, account: id}));
    },
    getByOrganization: (organizationId: string): TA => async(dispatch, getState) => {
        dispatch({type: 'ACCOUNT_GET_BY_ORGANIZATION_REQUEST', organizationId});
        const url = `api/account/organization/${organizationId}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'ACCOUNT_GET_BY_ORGANIZATION_RESPONSE', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getByOrganization', error));
            })
    },
    setVisibleOrganization: (organization: Organization): TA => async(dispatch) => {
        dispatch(orgActions.setActiveOrganization(organization.id));
        dispatch(actionCreators.getByOrganization(organization.id));
    },
    showSearch: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'ACCOUNT_SHOW_SEARCH', show});
    },
    showCreateAccount: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'ACCOUNT_SHOW_CREATE_ACCOUNT', show});
    },
    setCreateEmail: (value: string): TA => async(dispatch) => {
        dispatch({type: 'ACCOUNT_SET_CREATE_EMAIL', value});
    },
    setEditAccountPermissions: (permissions: PermissionType[]): TA => async(dispatch) => {
        dispatch({type: 'ACCOUNT_SET_EDIT_PERMISSIONS', value: permissions});
    },
    showConfirmRemoveAuthor: (show: false | string): TA => async(dispatch) => {
        dispatch({type: 'ACCOUNT_SHOW_CONFIRM_REMOVE_AUTHOR', show});
    },
    setSearchString: (searchString: string): TA => async(dispatch, getState) => {
        dispatch({type: 'ACCOUNT_SET_SEARCH_STRING', searchString});

        const url = 'api/account/search';
        const body = {searchString: searchString};
        axios.post(url, body, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'ACCOUNT_SEARCH_RESPONSE', data:response.data, searchStringResult: searchString});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'setSearchString', error));
            })
    }
   
};

const updateSingleAccount = (state: AccountState, account: AccountType): AccountState => {
    return {
        ...state,
        accounts: {
            ...state.accounts,
            [account.id]: account
        }
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: AccountState, action: any) => AccountState} = {
    ACCOUNT_GET_FOLLOWERS_RESPONSE: (state, action: {data: {resultCount: number; accounts: AccountType[]}; account: string}) => ({
        ...state,
        followers: {
            ...state.followers,
            [action.account] : action.data
        }
    }),
    ACCOUNT_DELETE_REQUEST: (state) => ({
        ...state,
        isLoading:{
            ...state.isLoading,
            delete: true
        }
    }),
    ACCOUNT_DELETE_RESPONSE: (state, action) => {
        const currentMap = state.accounts;
        const {...rest} = currentMap;
        delete rest[action.accountId];
        return{
            ...state,
            accounts: rest,
            isLoading:{
                ...state.isLoading,
                delete: false
            },
            ui:{
                ...state.ui,
                showEditAccount: false
            }
        }
    },
    ACCOUNT_UPDATE_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                update: true
            }
        }
    },
    ACCOUNT_UPDATE_RESPONSE: (state, action) => {
        state = updateSingleAccount(state, action.data);
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                update: false
            },
            ui:{
                ...state.ui,
                showEditAccount: false
            }
        }
    },
    ACCOUNT_CREATE_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                create: true
            }
        }
    },
    ACCOUNT_CREATE_RESPONSE: (state, action) => {
        state = updateSingleAccount(state, action.data);
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                create: false
            },
            ui:{
                ...state.ui,
                showCreateAccount: false
            }
        }
    },
    ACCOUNT_REQUEST: (state, action) => {
        return updateSingleAccount(state, action.tempAccount);
    },
    ME_RECEIVED: (state, action) => {
        return updateSingleAccount(state, action.data);
    },
    ME_RECEIVED_UPDATE_IMAGE: (state, action) => {
        return updateSingleAccount(state, action.data);
    },
    ACCOUNT_RECEIVED: (state, action) => {
        return updateSingleAccount(state, action.data);
    },
    ACCOUNT_SET_SEARCH_STRING: (state, action) => {
        return{
            ...state,
            searchString: action.searchString
        }
    },
    ACCOUNT_SET_CREATE_EMAIL: (state, action) => {
        return{
            ...state,
            newAccount: {
                ...state.newAccount,
                email: action.value
            }
        }
    },
    ACCOUNT_SHOW_EDIT_ACCOUNT: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showEditAccount: action.show
            }
        }
    },
    ACCOUNT_SHOW_SEARCH: (state, action) => {
        return{
            ...state,
            showSearch: action.show
        }
    },
    ACCOUNT_SHOW_CREATE_ACCOUNT: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showCreateAccount: action.show
            }
        }
    },
    ACCOUNT_SHOW_CONFIRM_REMOVE_AUTHOR: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showConfirmRemoveAuthor: action.show
            }
        }
    },
    ACCOUNT_SEARCH_RESPONSE: (state, action) => {
        return{
            ...state,
            searchResult: action.data,
            searchStringResult: action.searchStringResult
        }
    },
    ACCOUNT_GET_BY_ORGANIZATION_REQUEST: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                organizationVisible: action.organizationId
            },
            isLoading:{
                ...state.isLoading,
                getByOrganization: true,
            }
        }
    },
    ACCOUNT_GET_BY_ORGANIZATION_RESPONSE: (state, action) => {
        return{
            ...state,
            accountsByOrg: action.data,
            isLoading:{
                ...state.isLoading,
                getByOrganization: false,
            },
            accounts:Object.assign(state.accounts, action.data)
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<AccountState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};