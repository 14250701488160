import React, { useEffect} from 'react'; 
import { SecureLayoutComponentProps } from '../../Layout'; 
import * as MadMonstersStore from "../../../store/games/MadMonstersStore"; 
import { ErrorState } from '../../../store/Error'; 
import * as MyGamesStore from "../../../store/MyGames"; 
import { useFetchPost } from '../../../services/FetchHelper'; 
import { history } from '../../..'; 
import { Translate } from 'react-localize-redux'; 
import EditGameComponent from '../../EditGameComponent'; 
import { accountHasAnyPermission } from '../../../model/AccountType'; 
import GameDetailed from '../../ModelPreview/GameDetailed'; 
import { Loading } from '../../Loading'; 
import { FormGeneralError } from '../../Error/FormGeneralError'; 
import { connect } from 'react-redux'; 
import { AppState } from '../../../store/configureStore'; 
import { bindActionCreators } from 'redux'; 
import MadMonstersSettings from './MadMonstersSettings'; 
import { MadMonstersGame } from '../../../model/Game/MadMonsters/MadMonstersGame';
 
 
 
interface Props extends SecureLayoutComponentProps<{id?: string}>{ 
    gameActions: typeof MadMonstersStore.actionCreators; 
    errorState: ErrorState['madMonstersState']; 
    gameState: MadMonstersStore.MadMonstersState; 
    myGamesActions: typeof MyGamesStore.actionCreators; 
} 
 
const MadMonsters = (props: Props) => { 
    const { gameActions, gameState, errorState, myGamesActions, match, me, setLayoutButtons} = props; 
    const routeId = match.params.id; 
 
    useEffect(() => { 
        gameActions.resetGame(); 
    }, [gameActions]) 
 
    const [create, loadingCreate, errorCreate] = useFetchPost<MadMonstersGame>(g => history.push(`/workshop/${g.gameType}/edit/${g.id}`)); 
 
    if(errorState.getGame) return <div><Translate id='unknown_error'/></div>; 
    const game = gameState.game; 
 
    return( 
        <EditGameComponent 
            gameState={gameState} 
            routeMatch={match} 
            gameType={'MadMonsters'} 
            setLayoutButtons={setLayoutButtons} 
            extraButtons={[ 
                accountHasAnyPermission(me, ["Admin"]) && !routeId 
                 ?  
                    {id: 'create', icon: 'plus', hoverMsg: 'mad_monsters_create', action: () => create(`/api/madmonsters/create`)} 
                 :  
                    null 
            ]} 
        > 
            <GameDetailed 
                game={game} 
                tempDescription={ 
                    <div> 
                        <p><Translate id='mad_monsters_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p> 
                    </div> 
                } 
                tempTitle={<Translate id='mad_monsters_title'/>} 
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined} 
                gameType={gameState.gameType} 
                gameStats={gameState.gameStats} 
            /> 
            <Loading visible={loadingCreate} /> 
            <FormGeneralError error={errorCreate} /> 
            <MadMonstersSettings /> 
        </EditGameComponent> 
    ); 
} 
 
export default connect( 
    (state: AppState) => ({ 
        gameState: state[MadMonstersStore.reducerName], 
        errorState: state.errors[MadMonstersStore.reducerName] 
    }), 
    dispatch => ({ 
        gameActions: bindActionCreators(MadMonstersStore.actionCreators, dispatch), 
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch) 
    }) 
)(MadMonsters)