import axios from "axios";
import {errorActions} from "../Error";
import {AbstractGameIsLoading, abstractGameReducerMethods, AbstractGameState} from "./Games";
import {actionCreators as gamesActions} from './Games';
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import {ImageUploadType} from "../../model/ImageUploadType";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import {JigsawPuzzleImage} from "../../model/Game/JigsawPuzzle/JigsawPuzzleImage";
import {JigsawPuzzleGame} from "../../model/Game/JigsawPuzzle/JigsawPuzzleGame";
import {createJigsawPuzzleImageFormData, updateJigsawPuzzleImageFormData} from "../../services/FormDataHelper";

export const reducerName = 'jigsawPuzzleState';
export const apiPath = 'api/jigsawpuzzle'

export interface EditPuzzleImage extends JigsawPuzzleImage{
    index: number;
}

export interface JigsawPuzzleState extends AbstractGameState{
    game?: JigsawPuzzleGame;
    isLoading: AbstractGameIsLoading & {
        createItem?: boolean;
        updateItem?: boolean;
        deleteItem?: boolean;
    };
}

const initialState: JigsawPuzzleState = {
    game: undefined,
    gameStats: undefined,
    gameType: 'JigsawPuzzle',
    isLoading: {},
    ui: { showSettings: false }
};

export const actionCreators = {
    setField: (e: ChangeEvent<string>|ChangeEvent<boolean>): TA => async(dispatch) => {
        dispatch({type: 'JIGSAW_SET_FIELD', name: e.target.name, value: e.target.value})
    },
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: 'JIGSAW_RESET'});
    },
    getGame: (id: string): TA => async (dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'JigsawPuzzle'));
    },
    createItem: (item: EditPuzzleImage): TA => async(dispatch, getState) => {
        const game = getState().jigsawPuzzleState.game;
        const gameId = game && game.id;

        dispatch({type: 'JIGSAW_FORM_ITEM_REQUEST', key: 'createItem'});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `${apiPath}/image`;
        const data = createJigsawPuzzleImageFormData(item, gameId);
        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                if(!gameId) history.push(`/workshop/jigsawpuzzle/edit/${response.data.id}`);
                dispatch({type: 'JIGSAW_FORM_ITEM_RESPONSE', data: data, key: 'createItem'});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createItem', error));
            })
    },
    updateItem: (item: EditPuzzleImage): TA => async(dispatch, getState) => {
        const game = getState().jigsawPuzzleState.game;
        if(!game) return;

        dispatch({type: 'JIGSAW_FORM_ITEM_REQUEST', key: 'updateItem'});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `${apiPath}/image`;
        const data = updateJigsawPuzzleImageFormData(item, game.id);
        
        axios.put(url, data, CreateAuthHeader(getState))
            .then(r => dispatch({type: 'JIGSAW_FORM_ITEM_RESPONSE', data: r.data, key: 'updateItem'}))
            .catch(e => dispatch(errorActions.reportAxiosError(reducerName, 'updateItem', e)));
    },
    deleteItem: (item: EditPuzzleImage): TA => async(dispatch, getState) => {
        const game = getState().jigsawPuzzleState.game;
        if(!game) return;
        dispatch({type: 'JIGSAW_FORM_ITEM_REQUEST', key: 'deleteItem'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `${apiPath}/${game.id}/image/${item.index}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'JIGSAW_FORM_ITEM_RESPONSE', data, key: 'deleteItem'});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteItem', error));
            })
    },
    itemSetImage: (image: ImageUploadType|undefined): TA => async(dispatch) => {
        dispatch({type: 'JIGSAW_ITEM_SET_IMAGE', image:image});
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: JigsawPuzzleState, action: any) => JigsawPuzzleState}  = {
    ...abstractGameReducerMethods,
    JIGSAW_RESET: () => initialState,
    JIGSAW_FORM_ITEM_REQUEST: (state, action) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: true
            }
        }
    },
    JIGSAW_FORM_ITEM_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            }
        }
    },
    JIGSAW_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<JigsawPuzzleState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};