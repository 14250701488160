import { useRef, ReactNode, MouseEvent } from 'react';
import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Loading} from "./Loading";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    onClose: () => void;
    onClick?: () => void;
    onIconClick?: () => void;
    loading?: boolean;
    className?: string;
    icon?: IconProp;
    icons?: DialogIcon[];
    children: ReactNode;
}

export interface DialogIcon{
    onClick: () => void;
    icon: IconProp;
    hoverMsg?: ReactNode;
}

export const Dialog = (props: Props) => {

    const mouseDownInsideDialog = useRef(false);

    const onOverlayClick = (event: MouseEvent<HTMLDivElement> ) => {
        if(mouseDownInsideDialog.current && event.target === event.currentTarget && event.type === "click") {
            props.onClose();
        }
        event.preventDefault();
        event.stopPropagation();
    };
    const dialogClasses = `dialog${props.className ? ` ${props.className}` : ''}`;
    return (
        <div className='dialog-overlay' onMouseDown={e => {e.stopPropagation(); mouseDownInsideDialog.current = true;}} onClick={onOverlayClick}>
            <div className={dialogClasses} onMouseDown={(e) => {e.stopPropagation(); mouseDownInsideDialog.current = false;}} onClick={e => {e.stopPropagation(); if(props.onClick){props.onClick()} }}>
                <Loading visible={!!props.loading}/>
                { props.icons &&
                    <div className='dialog-icon-container'>
                        {props.icons.map(i => {
                            return(
                                <button key={i.icon.toString()} className='icon hover-trigger' onClick={i.onClick}>
                                    <FontAwesomeIcon icon={i.icon} />
                                    <div className='hover-msg'>
                                        {i.hoverMsg}
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                }
                <button className='close-button' onClick={() => props.onClose()}>
                    <FontAwesomeIcon icon='times' />
                </button>
                <div className='dialog-top'>
                    {props.children}
                </div>
                <div className='dialog-bottom'>
                    { props.icon && <div className='dialog-icon' onClick={props.onIconClick}><FontAwesomeIcon icon={props.icon}/></div>}
                </div>
            </div>
        </div>
    );
};

export default Dialog;