import {TA} from "./configureStore";
import axios from "axios";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {errorActions} from "./Error";
import {Reducer} from "redux";

const TAG_SUGGEST_REQUEST = 'TAG_SUGGEST_REQUEST';
const TAG_SUGGEST_RESPONSE = 'TAG_SUGGEST_RESPONSE';
const TAG_UPDATE_TYPEDNAME = 'TAG_UPDATE_TYPEDNAME';

//should match index name in configureStore.ts
//and index name in errorState
const reducerName = "tagState";

export interface TagState{
    currentSuggestions: string[];
    typedName: string;
    isLoading: {
        getSuggestions: boolean;
    };
}

const initialState: TagState = {
    currentSuggestions: [],
    typedName: "",
    isLoading: {
        getSuggestions: false
    }
};

export const actionCreators = {
    getSuggestions: (name: string): TA => async(dispatch, getState) => {
        if(!getState().tagState.isLoading.getSuggestions){
            if(!name){
                //if name is empty return empty suggestions
                dispatch({type: TAG_SUGGEST_RESPONSE, data: []});
                return;
            }

            dispatch({type: TAG_SUGGEST_REQUEST});

            const url = `api/tags/suggesttags/${name}`;
            axios.get(url, CreateAuthHeader(getState))
                .then(result => {
                    dispatch({type: TAG_SUGGEST_RESPONSE, data: result.data});
                    //if the user typed more while we were getting tags, get new tags.
                    if(getState().tagState.typedName !== name){
                        dispatch(actionCreators.getSuggestions(getState().tagState.typedName));
                    }
                })
                .catch(error => {
                    dispatch(errorActions.reportAxiosError(reducerName, "getSuggestions", error));
                })
        }
    },
    updateTypedName: (name: string): TA => async(dispatch, getState) => {
        dispatch({type: TAG_UPDATE_TYPEDNAME, name});
        dispatch(actionCreators.getSuggestions(name));
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: TagState, action: any) => TagState} = {
    TAG_SUGGEST_REQUEST: (state, action) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                getSuggestions: true
            }
        }
    },
    TAG_SUGGEST_RESPONSE: (state, action) => {
        return{
            ...state,
            currentSuggestions: action.data,
            isLoading:{
                ...state.isLoading,
                getSuggestions: false,
            }
        }
    },
    TAG_UPDATE_TYPEDNAME: (state, action) => {
        return{
            ...state,
            typedName: action.name
        }
    },
    ERROR: (state, action) => {
        if(action.reducer !== reducerName) return state;
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                [action.key]: false
            }
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<TagState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};