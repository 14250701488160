import React from 'react';
import {actionCreators as stfActions, SmackTheFlyState} from "../../../store/games/SmackTheFlyStore";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import {Dialog} from "../../Dialog";
import {Translate} from "react-localize-redux";
import {
    CheckBox,
    FontOptions,
    ImageFormButton,
    InputField,
    SelectList,
    Slider,
    SubmitButton
} from '../../forms/FormGroup';
import {SmackTheFlyAnswerPreview, SmackTheFlyPositionPrevew} from './SmackTheFlyPreview';
import {AppState} from "../../../store/configureStore";
import {FormGeneralError} from "../../Error/FormGeneralError";
import FieldErrors from "../../forms/FieldErrors";
import {ErrorState} from "../../../store/Error";

interface Props {
    smackthefly: SmackTheFlyState;
    actions: typeof stfActions;
    errors: ErrorState['smackthefly'];
}

const SmackTheFlyAnswerForm = (props: Props) => {
    const round = props.smackthefly.round;
    const answer = props.smackthefly.answer;
    const loading = props.smackthefly.isLoading;

    const confirmDelete = <ConfirmationForm
        text={"smackthefly_answer_confirm_delete"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => {props.actions.deleteAnswer(round.index, answer.index)}}
        onCancel={() => props.actions.showConfirmDeleteAnswer(false)}/>;

    const submitFunc = () => {
        if (answer.new){
            props.actions.createAnswer(round.index, answer);
        }
        else{
            props.actions.updateAnswer(round.index,  answer);
        }

    };

    let validForm = true;
    if (props.errors.setAnswerWidth || props.errors.setAnswerX || props.errors.setAnswerY) validForm = false;

    const mainForm = (
        <Dialog
            loading={loading.createAnswer || loading.deleteAnswer || loading.updateAnswer}
            className='smackthefly-answer-dialog'
            onClose={() => props.actions.showEditAnswer(false)}
            icon={answer.new ? undefined : 'trash'}
            onIconClick={() => props.actions.showConfirmDeleteAnswer(true)} >
            {props.smackthefly.ui.deleteAnswer && confirmDelete}
            <h1><Translate id='smackthefly_answer' /></h1>
            <form className='smackthefly-answer-form' onSubmit={(e) => e.preventDefault()}>

                <ImageFormButton
                    previewWidth={200}
                    previewHeight={200}
                    imageSubmit={props.actions.setAnswerImage}
                    name='image'
                    unsavedChange={answer.image !== answer._o.image}
                />

                <InputField
                    name='text'
                    type='text'
                    value={answer.text}
                    placeholder='smackthefly_text_placeholder'
                    onChange={(e) => props.actions.setAnswerText(e.target.value)}
                    noLabel
                    tabIndex={1}
                    original={answer._o.text}
                />
                <SelectList
                    noLabel
                    name='font'
                    defaultValue={answer.font || "Chewy"}
                    options={FontOptions}
                    onChange={(e) => {props.actions.setAnswerFont(e.target.value)}}
                    original={answer._o.font}
                />
                <CheckBox
                    name='correct'
                    active={answer.correct}
                    onChange={(e) => props.actions.setCorrect(e.target.value)}
                    original={answer._o.correct}
                />
                <CheckBox
                    name='customPosition'
                    active={answer.customPosition}
                    onChange={(e) => props.actions.setCustomPosition(e.target.value)}
                    original={answer._o.customPosition}
                />
                {answer.customPosition &&
                    <>
                        <p><Translate id={'smackthefly_placement_help'} /></p>
                        <Slider name='x' step={1} min={0} max={1280-answer.width} value={answer.x} onChange={(e) => props.actions.setAnswerX(e.target.value)} original={answer._o.x}  />
                        <FieldErrors errors={props.errors.setAnswerX} fieldname='x'/>

                        <Slider name='y' step={1} min={0} max={800-answer.width} value={answer.y} onChange={(e) => props.actions.setAnswerY(e.target.value)} original={answer._o.y} />
                        <FieldErrors errors={props.errors.setAnswerY} fieldname='y'/>

                        <Slider min={50} max={200} name={'width'} value={answer.width} onChange={(e) => props.actions.setAnswerWidth(e.target.value)} original={answer._o.width} />
                        <FieldErrors errors={props.errors.setAnswerWidth} fieldname='width'/>
                    </>
                }
                <SubmitButton text={'submit'} onClick={submitFunc} split disabled={!validForm}/>
            </form>
            <div className='preview'>
                <SmackTheFlyAnswerPreview answer={answer}/>
            </div>
            {answer.customPosition &&
                <div className='preview'>
                    <SmackTheFlyPositionPrevew answer={answer} round={round} setX={props.actions.setAnswerX} setY={props.actions.setAnswerY}/>
                </div>
            }
            <div className='clear-fix' />

            <FormGeneralError error={props.errors.createAnswer}/>
            <FormGeneralError error={props.errors.updateAnswer}/>
            <FormGeneralError error={props.errors.deleteAnswer}/>

        </Dialog>
    );

    if (props.smackthefly.ui.editAnswer) {
        return mainForm;
    }

    return null;
};

export default connect(
    (state: AppState) => ({smackthefly: state.smackthefly, errors: state.errors.smackthefly}),
    dispatch => ({ actions: bindActionCreators(stfActions, dispatch) })
)(SmackTheFlyAnswerForm)