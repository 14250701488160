import React from 'react';
import PreviewBackground from '../../../images/games/warofstrategy/Flag.png';
import {Translate} from 'react-localize-redux';
import {DbOrUploadImage, DbOrUploadSound} from '../../../services/ImageHelper';
import {WosQuiz} from '../../../model/Game/WarOfStrategy/WosQuiz';
import TextView from '../../TextView';

interface Props {
    quiz: WosQuiz;
    onClick?: () => void;
}

export const WarOfStrategyQuizPreview = (props: Props) => {
    const quiz = props.quiz;
    if (!quiz) return null;
    const q = quiz.question;
    const a1 = quiz.answer1;
    const a2 = quiz.answer2;
    const a3 = quiz.answer3;

    return(
        <div className='preview-wrapper' onClick={props.onClick || undefined}>
            <div className='wos-quiz-preview'>
                <img className='quiz-background' src={PreviewBackground} alt='' />
                <div className='quiz-difficulty'><Translate id='wos_difficulty'/>{quiz.difficulty}</div>
                <DbOrUploadSound className='audio' audio={q.sound} />
                <div className={`question${q.image && q.text.content ? ' image-and-text' : ''}`}>
                    <TextView element='div' scale={0.5} text={q.text} />
                    <DbOrUploadImage image={q.image}/>
                </div>
                <div className={`answer a${a1.correct ? ' correct' : ''}${a1.image && a1.text.content ? ' image-and-text' : ''}`}>
                    <DbOrUploadImage image={a1.image}/>
                    <TextView element='div' scale={0.5} text={a1.text} />
                </div>
                <div className={`answer b${a2.correct ? ' correct' : ''}${a2.image && a2.text.content ? ' image-and-text' : ''}`}>
                    <DbOrUploadImage image={a2.image}/>
                    <TextView element='div' scale={0.5} text={a2.text} />
                </div>
                <div className={`answer c${a3.correct ? ' correct' : ''}${a3.image && a3.text.content ? ' image-and-text' : ''}`}>
                    <DbOrUploadImage image={a3.image}/>
                    <TextView element='div' scale={0.5} text={a3.text} />
                </div>
            </div>
        </div>
    )
};