import React, { useEffect} from 'react';
import { SecureLayoutComponentProps } from '../../Layout';
import * as FeedTheFoxStore from "../../../store/games/FeedTheFoxStore";
import { ErrorState } from '../../../store/Error';
import * as MyGamesStore from "../../../store/MyGames";
import { useFetchPost } from '../../../services/FetchHelper';
import { FeedTheFoxGame } from '../../../model/Game/FeedTheFox/FeedTheFoxGame';
import { history } from '../../..';
import { Translate } from 'react-localize-redux';
import EditGameComponent from '../../EditGameComponent';
import { accountHasAnyPermission } from '../../../model/AccountType';
import GameDetailed from '../../ModelPreview/GameDetailed';
import { Loading } from '../../Loading';
import { FormGeneralError } from '../../Error/FormGeneralError';
import { connect } from 'react-redux';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import FeedTheFoxSettings from './FeedTheFoxSettings';



interface Props extends SecureLayoutComponentProps<{id?: string}>{
    gameActions: typeof FeedTheFoxStore.actionCreators;
    errorState: ErrorState['feedTheFoxState'];
    gameState: FeedTheFoxStore.FeedTheFoxState;
    myGamesActions: typeof MyGamesStore.actionCreators;
}

const FeedTheFox = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, me, setLayoutButtons} = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])

    const [create, loadingCreate, errorCreate] = useFetchPost<FeedTheFoxGame>(g => history.push(`/workshop/${g.gameType}/edit/${g.id}`));

    if(errorState.getGame) return <div><Translate id='unknown_error'/></div>;
    const game = gameState.game;

    return(
        <EditGameComponent
            gameState={gameState}
            routeMatch={match}
            gameType={'FeedTheFox'}
            setLayoutButtons={setLayoutButtons}
            extraButtons={[
                accountHasAnyPermission(me, ["Admin"]) && !routeId
                 ? 
                    {id: 'create', icon: 'plus', hoverMsg: 'feed_the_fox_create', action: () => create(`/api/feedthefox/create`)}
                 : 
                    null
            ]}
        >
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='feed_the_fox_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                    </div>
                }
                tempTitle={<Translate id='feed_the_fox_title'/>}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
            <Loading visible={loadingCreate} />
            <FormGeneralError error={errorCreate} />
            <FeedTheFoxSettings />
        </EditGameComponent>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[FeedTheFoxStore.reducerName],
        errorState: state.errors[FeedTheFoxStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(FeedTheFoxStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(FeedTheFox)