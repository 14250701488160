import {TA} from "./configureStore";
import {Reducer} from "redux";
import axios from "axios";
import {CreateAuthHeader} from "../services/AxiosHelper";

export const API_TEST_SET_URL = 'API_TEST_SET_URL';
export const API_TEST_SET_METHOD = 'API_TEST_SET_METHOD';

export const reducerName = 'apiTestState';

export interface ApiTestState{
    url: string;
    method: string;
}

const initialState: ApiTestState = {
    url: "",
    method: "get"
};

export const actionCreators = {
    setUrl: (url: string): TA => async(dispatch, getState) => {
        dispatch({type: API_TEST_SET_URL, url});
    },
    setMethod: (method: string): TA => async(dispatch) => {
        dispatch({type: API_TEST_SET_METHOD, method});
    },
    fireRequest: (): TA => async(dispatch, getState) => {
        axios.get(getState().apiTestState.url, CreateAuthHeader(getState)).then(response => console.log(response));
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: ApiTestState, action: any) => ApiTestState} = {
    API_TEST_SET_URL: (state, action) => {
        return{
            ...state,
            url: action.url
        }
    },
    API_TEST_SET_METHOD: (state, action) => {
        return{
            ...state,
            method: action.method
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<ApiTestState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};