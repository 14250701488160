export const courseList = [
    'art',
    'biology',
    'business_studies',
    'chemistry',
    'citizenship',
    'culture',
    'geography',
    'geometry',
    'mathematics',
    'physics',
    'history',
    'physical_education',
    'home_economics',
    'music',
    'woodworking',
    'reading',
    'road_safety',
    'life_skills',
    'social_skills',
    'health_nutrition',
    'recess',
    'religion',
    'science_technology',
    'sensory'
];