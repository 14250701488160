import React from 'react';
import {FormButton, InputField} from "./forms/FormGroup";
import {connect} from "react-redux";
import {AppState} from "../store/configureStore";
import {bindActionCreators} from "redux";
import {actionCreators} from "../store/ApiTestStore";
import {ApiTestState} from "../store/ApiTestStore";
import Dialog from "./Dialog";

interface Props{
    apiState: ApiTestState;
    apiActions: typeof actionCreators;
}

const ApiTest = (props: Props) => {

    return(
        <div className='layout-content'>
            <Dialog onClose={() => {}} >
                <InputField
                    name={'url'}
                    type={'text'}
                    value={props.apiState.url}
                    onChange={e => props.apiActions.setUrl(e.target.value)}
                    noLabel
                />
                <FormButton
                    onClick={props.apiActions.fireRequest}
                    name='submit'
                />
            </Dialog>

        </div>
    )
};

export default connect(
    (state: AppState) => ({apiState: state.apiTestState}),
    dispatch => ({apiActions: bindActionCreators(actionCreators, dispatch)})
)(ApiTest);