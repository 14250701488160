import axios from "axios";
import {errorActions} from "../Error";
import {createDitjQuizEditFormData, createDitjQuizFormData} from "../../services/FormDataHelper";
import {AbstractGameIsLoading, abstractGameReducerMethods, AbstractGameState, AbstractGameUi} from "./Games";
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import {actionCreators as gamesActions} from '../../store/games/Games';
import {SoundUploadType} from "../../model/SoundUploadType";
import {DitjGame} from "../../model/Game/DangerInTheJungle/DitjGame";
import {ImageUploadType} from "../../model/ImageUploadType";
import {DitjQuiz} from "../../model/Game/DangerInTheJungle/DitjQuiz";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import {getLocaleStorageItem, saveLocaleStorageItem} from "../../services/LocalStorageService";

export const reducerName = 'ditj';
const ditjStyleKey = 'ditj_style';

export type DitjAnswerContainer = 'answer1' | 'answer2' | 'answer3';
export type DitjQuestionContainer = 'question';
export type DitjContainer = DitjAnswerContainer | DitjQuestionContainer;

const DITJ_SAVE_STYLE = 'DITJ_SAVE_STYLE';
const DITJ_APPLY_STYLE = 'DITJ_APPLY_STYLE';

interface DitjStyle{
    question: TextType;
    answer: TextType;
}

interface EditQuiz extends DitjQuiz{
    newQuiz?: boolean;
    newCategory?: boolean;
    index: number;
    _o: DitjQuiz;
}

export interface DangerInTheJungleState extends AbstractGameState {
    game?: DitjGame;
    quiz: EditQuiz;
    isLoading: AbstractGameIsLoading & {
        createQuiz?: boolean;
        updateQuiz?: boolean;
        deleteQuiz?: boolean;
        renameCategories?: boolean;
    };
    ui: AbstractGameUi & {
        showConfirmDeleteQuiz?: boolean;
        showQuizForm?: boolean;
    };
    style: DitjStyle;
}

const initialStyle: DitjStyle = {
    question: {
        content: "",
        fontSize: 14,
        fontFamily: "Lato",
        fontColorHex: "#6F0505",
        textAlignment: TextAlignment.Center
    },
    answer: {
        content: "",
        fontSize: 14,
        fontFamily: "Lato",
        fontColorHex: "#6F0505",
        textAlignment: TextAlignment.Center
    }
};



const initialState = (): DangerInTheJungleState => {
    const defaultStyle = getLocaleStorageItem(ditjStyleKey, initialStyle);

    const answer = {
        correct: false,
        text: {...defaultStyle.answer, content: ""}
    };

    return {
        gameType: 'DangerInTheJungle',
        quiz: {
            newQuiz: true,
            newCategory: true,
            index: -1,
            category: "",
            question: { text: {...defaultStyle.question, content: ""} },
            answer1: {...answer},
            answer2: {...answer},
            answer3: {...answer},
            _o: {
                category: "",
                question: { text: {...defaultStyle.question, content: ""} },
                answer1: {...answer},
                answer2: {...answer},
                answer3: {...answer},
            }
        },
        isLoading: {},
        ui: {},
        style: defaultStyle
    }
};

export function ditjTransformQuizToStyle(quiz: EditQuiz, style: DitjStyle): EditQuiz{
    return({
        ...quiz,
        question: {
            ...quiz.question,
            text: {
                ...style.question,
                content: quiz.question.text.content
            }
        },
        answer1: {
            ...quiz.answer1,
            text: {
                ...style.answer,
                content: quiz.answer1.text.content
            }
        },
        answer2: {
            ...quiz.answer2,
            text: {
                ...style.answer,
                content: quiz.answer2.text.content
            }
        },
        answer3: {
            ...quiz.answer3,
            text: {
                ...style.answer,
                content: quiz.answer3.text.content
            }
        }
    });
}

export const actionCreators = {
    saveStyle: (): TA => async(dispatch, getState) => {
        const newStyle: DitjStyle = {
            question: {...getState().ditj.quiz.question.text},
            answer: {...getState().ditj.quiz.answer1.text}
        };
        saveLocaleStorageItem(ditjStyleKey, newStyle);
        dispatch({type: DITJ_SAVE_STYLE, newStyle});
    },
    applyStyle: (): TA => async(dispatch) => {
        dispatch({type: DITJ_APPLY_STYLE});
    },
    setField: (e: {target: {name: string; value: string|number|boolean}}): TA => async(dispatch) => {
        dispatch({type: 'DITJ_SET_FIELD', name: e.target.name, value: e.target.value});
    },
    showConfirmDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'DITJ_SHOW_DELETE_QUIZ_CONFIRM' ,show})
    },
    setSound: (sound: SoundUploadType|undefined): TA => async(dispatch) => {
        dispatch({type: 'DITJ_SET_SOUND', sound});
    },
    questionTextChange: (event: ChangeEvent<string>|ChangeEvent<number>, key: DitjContainer): TA => async(dispatch) => {
        const field = event.target.name;
        const value = event.target.value;
        dispatch({type: 'DITJ_QUESTION_TEXT_CHANGE', key, field, value });
    },
    changeCorrect: (event: {target: {name: string; value: boolean}}, key: DitjAnswerContainer): TA => async(dispatch) => {
        const field = event.target.name;
        const value = event.target.value;
        dispatch({type: 'DITJ_CORRECT_CHANGE', field, value: value, key})
    },
    questionToggleColor: (key: DitjContainer, show: boolean): TA => async(dispatch) => {
        dispatch({type: 'DITJ_UPDATE_QUESTION_UI', field: "showColor", value: show, key});
    },
    questionSetImage: (key: DitjContainer, image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: 'DITJ_QUESTION_IMAGE_CHANGE', key, image});
        dispatch({type: 'DITJ_UPDATE_QUESTION_UI', field: "showImageForm", value:false, key});
    },
    questionSetCategory: (event: ChangeEvent<string>): TA => async(dispatch) => {
        const category = event.target.value;
        dispatch({type: 'DITJ_QUESTION_CATEGORY_CHANGE', category});
    },
    questionShowNewCategory: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'DITJ_SHOW_NEW_CATEGORY' , show});
    },
    toggleQuestionForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'DITJ_SHOW_QUESTION_FORM', show});
    },
    showNewQuizForm: (): TA => async(dispatch, getState) => {
        const curQ = getState().ditj.quiz;
        dispatch({type: 'DITJ_SET_EDIT_QUESTION', quiz: curQ.newQuiz ? curQ : initialState().quiz});
    },
    openEditQuiz: (quiz: DitjQuiz, index: number): TA => async(dispatch, getState) => {
        const curQ = getState().ditj.quiz;
        const editQuiz: EditQuiz = { ...quiz, index, newQuiz: false, newCategory: false, _o: quiz};
        dispatch({type: 'DITJ_SET_EDIT_QUESTION', quiz: curQ.index !== index ? editQuiz : curQ})
    },
    createQuiz: (quiz: DitjQuiz): TA => async(dispatch, getState) => {
        const game = getState().ditj.game;
        const gameId = game && game.id;
        dispatch({type: 'DITJ_CREATE_QUIZ_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const formData = createDitjQuizFormData(quiz, gameId);
        const url = `api/workshop/dangerinthejungle/quiz`;

        axios.post(url, formData, CreateAuthHeader(getState))
            .then(response => {
                if(!gameId){
                    dispatch(actionCreators.saveStyle());
                    history.push(`/workshop/dangerinthejungle/edit/${response.data.id}`);
                }
                dispatch({type: 'DITJ_CREATE_QUIZ_RESPONSE', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createQuiz', error));
            });
    },
    updateQuiz: (quiz: DitjQuiz, quizIndex: number): TA => async(dispatch, getState) => {
        const game = getState().ditj.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: 'DITJ_UPDATE_QUIZ_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const formData = createDitjQuizEditFormData(quiz, quizIndex, gameId);
        const url = 'api/workshop/dangerinthejungle/quiz';
        axios.put(url, formData, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'DITJ_UPDATE_QUIZ_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateQuiz', error));
            })
    },
    deleteQuiz: (quizIndex: number): TA => async(dispatch, getState) => {
        const game = getState().ditj.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: 'DITJ_DELETE_QUIZ_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/dangerinthejungle/${gameId}/quiz/${quizIndex}`;

        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'DITJ_DELETE_QUIZ_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteQuiz', error));
            })
    },
    renameCategories: (dict: {[key: string]: string}): TA => async(dispatch, getState) => {
        const gameId = getState().ditj.game?.id;
        if(!gameId) return;
        dispatch({type: 'DITJ_RENAME_CATEGORIES_REQUEST'});
        dispatch(errorActions.resetError(reducerName, 'renameCategories'));
        const url = `api/workshop/dangerinthejungle/${gameId}/rename`;
        axios.put(url, dict, CreateAuthHeader(getState))
            .then(r => dispatch({type: 'DITJ_RENAME_CATEGORIES_RESPONSE', data: r.data}))
            .catch(e => dispatch(errorActions.reportAxiosError(reducerName, 'renameCategories', e)));
    },
    resetGame: (): TA => async(dispatch) => {
        dispatch({type:'DITJ_RESET_GAME'});
    },
    getGame: (id: string): TA => async(dispatch) =>{
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'DangerInTheJungle'));
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: DangerInTheJungleState, action: any) => DangerInTheJungleState} = {
    ...abstractGameReducerMethods,
    DITJ_APPLY_STYLE: (state) => {
        return{
            ...state,
            quiz: ditjTransformQuizToStyle(state.quiz, state.style)
        }
    },
    DITJ_SAVE_STYLE: (state, action) => {
        return{
            ...state,
            style: action.newStyle
        }
    },
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            quiz: initialState().quiz
        }
    },
    DITJ_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    DITJ_SHOW_DELETE_QUIZ_CONFIRM: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui,
                showConfirmDeleteQuiz: action.show
            }
        };
    },
    DITJ_RESET_GAME: () => {
        return initialState();
    },
    DITJ_SET_SOUND: (state, action) => {
        return{
            ...state,
            quiz:{
                ...state.quiz,
                question:{
                    ...state.quiz.question,
                    sound: action.sound
                }
            }
        }
    },
    DITJ_RENAME_CATEGORIES_REQUEST: (state) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                renameCategories: true
            }
        }
    },
    DITJ_RENAME_CATEGORIES_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                renameCategories: undefined
            }
        }
    },
    DITJ_CREATE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading, 
                createQuiz: true,
            }
        }
    },
    DITJ_CREATE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            quiz:{
                ...state.quiz,
                newQuiz: false
            },
            isLoading:{
                ...state.isLoading,
                createQuiz: false,
            },
            ui: {
                ...state.ui,
                showQuizForm: false
            }
        };
    },
    DITJ_UPDATE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                updateQuiz: true,
            }
        };
    },
    DITJ_UPDATE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                updateQuiz: false,
            },
            ui: {
                ...state.ui,
                showQuizForm: false
            }
        };
    },
    DITJ_DELETE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                deleteQuiz: true,
            },
        }
    },
    DITJ_DELETE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                deleteQuiz: false,
            },
            ui: {
                ...state.ui,
                showQuizForm: false
            }
        };
    },
    DITJ_SET_EDIT_QUESTION: (state, action) => {
        return{
            ...state,
            quiz: action.quiz,
            ui:{
                ...state.ui,
                showQuizForm: true,
            }
        };
    },
    DITJ_SHOW_NEW_CATEGORY: (state, action) => {
        return{
            ...state,
            quiz:{
                ...state.quiz,
                newCategory: action.show
            }
        };
    },
    DITJ_QUESTION_CATEGORY_CHANGE: (state, action) => {
        return{
            ...state,
            quiz: {
                ...state.quiz,
                category: action.category
            }
        };
    },
    DITJ_SHOW_QUESTION_FORM: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                showQuizForm: action.show
            }
        };
    },
    DITJ_QUESTION_IMAGE_CHANGE: (state, action: {key: DitjContainer; image: ImageUploadType | null}) => {
        return{
            ...state,
            quiz: {
                ...state.quiz,
                [action.key]:{
                    ...state.quiz[action.key],
                    image: action.image
                }
            }
        };
    },
    DITJ_CORRECT_CHANGE: (state, action: {key: DitjContainer; value: boolean}) => {
        return{
            ...state,
            quiz:{
                ...state.quiz,
                [action.key]:{
                    ...state.quiz[action.key],
                    correct: action.value
                }
            }
        };
    },
    DITJ_QUESTION_TEXT_CHANGE: (state, action: {key: DitjContainer; field: string; value: string|number}) => {
        return{
            ...state,
            quiz:{
                ...state.quiz,
                [action.key]:{
                    ...state.quiz[action.key],
                    text: {
                        ...state.quiz[action.key].text,
                        [action.field]: action.value
                    }
                }
            }
        };
    },
    ERROR: (state, action) => {
        if (action.reducer !== 'ditj') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<DangerInTheJungleState, any> = (state, action) => {
    state = state || initialState();
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};