import axios from "axios";
import {errorActions} from "../Error";
import {
    createQuizNinjaAnswerFormData,
    createQuizNinjaRoundFormData,
    updateQuizNinjaAnswerFormData,
    updateQuizNinjaRoundFormData
} from "../../services/FormDataHelper";
import {abstractGameReducerMethods, AbstractGameState, AbstractGameIsLoading, AbstractGameUi} from "./Games";
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import {actionCreators as gamesActions} from './Games';
import { QuizNinjaGame } from "../../model/Game/QuizNinja/QuizNinjaGame";
import { QnRound } from "../../model/Game/QuizNinja/QnRound";
import { QnAnswer } from "../../model/Game/QuizNinja/QnAnswer";
import { ChangeEvent, TA} from "../configureStore";
import { Reducer} from "redux";
import {ImageUploadType} from "../../model/ImageUploadType";
import {isDbImage} from "../../services/ImageHelper";
import {getLocaleStorageItem, saveLocaleStorageItem} from "../../services/LocalStorageService";

export const reducerName = 'quizninja';
const quizninjaStyleKey = 'quizninja_style';

const QUIZNINJA_RESET = 'QUIZNINJA_RESET';

const QUIZNINJA_UPDATE_SORT_REQUEST = 'QUIZNINJA_UPDATE_SORT_REQUEST';
const QUIZNINJA_UPDATE_SORT_RESPONSE = 'QUIZNINJA_UPDATE_SORT_RESPONSE';
const QUIZNINJA_CREATE_ROUND_REQUEST = 'QUIZNINJA_CREATE_ROUND_REQUEST';
const QUIZNINJA_CREATE_ROUND_RESPONSE = 'QUIZNINJA_CREATE_ROUND_RESPONSE';
const QUIZNINJA_CREATE_ANSWER_REQUEST = 'QUIZNINJA_CREATE_ANSWER_REQUEST';
const QUIZNINJA_CREATE_ANSWER_RESPONSE = 'QUIZNINJA_CREATE_ANSWER_RESPONSE';
const QUIZNINJA_UPDATE_ROUND_REQUEST = 'QUIZNINJA_UPDATE_ROUND_REQUEST';
const QUIZNINJA_UPDATE_ROUND_RESPONSE = 'QUIZNINJA_UPDATE_ROUND_RESPONSE';
const QUIZNINJA_UPDATE_ANSWER_REQUEST = 'QUIZNINJA_UPDATE_ANSWER_REQUEST';
const QUIZNINJA_UPDATE_ANSWER_RESPONSE = 'QUIZNINJA_UPDATE_ANSWER_RESPONSE';
const QUIZNINJA_DELETE_ANSWER_REQUEST = 'QUIZNINJA_DELETE_ANSWER_REQUEST';
const QUIZNINJA_DELETE_ANSWER_RESPONSE = 'QUIZNINJA_DELETE_ANSWER_RESPONSE';
const QUIZNINJA_DELETE_ROUND_REQUEST = 'QUIZNINJA_DELETE_ROUND_REQUEST';
const QUIZNINJA_DELETE_ROUND_RESPONSE = 'QUIZNINJA_DELETE_ROUND_RESPONSE';

const QUIZNINJA_SHOW_EDIT_ROUND = 'QUIZNINJA_SHOW_EDIT_ROUND';
const QUIZNINJA_SHOW_EDIT_ANSWER = 'QUIZNINJA_SHOW_EDIT_ANSWER';
const QUIZNINJA_SHOW_ANSWER_DELETE = 'QUIZNINJA_SHOW_ANSWER_DELETE';
const QUIZNINJA_SHOW_ROUND_DELETE = 'QUIZNINJA_SHOW_ROUND_DELETE';
const QUIZNINJA_SHOW_ANSWER_FONT_COLOR = 'QUIZNINJA_SHOW_ANSWER_FONT_COLOR';

const QUIZNINJA_SET_ROUND = 'QUIZNINJA_SET_ROUND';
const QUIZNINJA_SET_ROUND_SORT = 'QUIZNINJA_SET_ROUND_SORT';
const QUIZNINJA_SET_ROUND_TITLE = 'QUIZNINJA_SET_ROUND_TITLE';
const QUIZNINJA_SET_ROUND_DESCRIPTION = 'QUIZNINJA_SET_ROUND_DESCRIPTION';
const QUIZNINJA_SET_ROUND_QUESTION = 'QUIZNINJA_SET_ROUND_QUESTION';
const QUIZNINJA_SET_ROUND_TIME = 'QUIZNINJA_SET_ROUND_TIME';
const QUIZNINJA_SET_ROUND_IMAGE = 'QUIZNINJA_SET_ROUND_IMAGE';

const QUIZNINJA_SET_ANSWER = 'QUIZNINJA_SET_ANSWER';
const QUIZNINJA_SET_ANSWER_TEXT = 'QUIZNINJA_SET_ANSWER_TEXT';
const QUIZNINJA_SET_ANSWER_IMAGE = 'QUIZNINJA_SET_ANSWER_IMAGE';
const QUIZNINJA_SET_ANSWER_FONT = 'QUIZNINJA_SET_ANSWER_FONT';
const QUIZNINJA_SET_ANSWER_FONT_COLOR_HEX = 'QUIZNINJA_SET_ANSWER_FONT_COLOR_HEX';
const QUIZNINJA_SET_ANSWER_CORRECT = 'QUIZNINJA_SET_ANSWER_CORRECT';

const QUIZNINJA_SET_FIELD = 'QUIZNINJA_SET_FIELD';
const QUIZNINJA_APPLY_STYLE = 'QUIZNINJA_APPLY_STYLE';
const QUIZNINJA_SAVE_STYLE = 'QUIZNINJA_SAVE_STYLE';

interface QuizNinjaStyle {
    font: string;
    fontColorHex: string;
}

interface EditAnswer extends QnAnswer{
    new: boolean;
    index: number;
    _o: QnAnswer;
}

interface EditRound extends QnRound{
    new: boolean;
    index: number;
    _o: QnRound;
}

export interface QuizNinjaState extends AbstractGameState {
    game?: QuizNinjaGame;
    round: EditRound;
    answer: EditAnswer;
    roundTempSort: QnRound[];
    isLoading: AbstractGameIsLoading & {
        sortRounds?: boolean; createRound?: boolean; copyRound?: boolean; updateRound?: boolean; deleteRound?: boolean;
        createAnswer?: boolean; updateAnswer?: boolean; deleteAnswer?: boolean;
    };
    ui: AbstractGameUi & {editRound?: boolean; deleteRound?: boolean; editAnswer?: boolean;
        deleteAnswer?: boolean;
    };
    style: QuizNinjaStyle;
}

const initialStyle: QuizNinjaStyle = {
    font: "Chewy",
    fontColorHex: "#FFFFFF"
};

const initialState = (): QuizNinjaState => {
    const defaultStyle = getLocaleStorageItem(quizninjaStyleKey, initialStyle);
    return {
        gameType: "QuizNinja",
        round: {
            new: true,
            index : -1,
            title: "",
            description: "",
            question: "",
            time: 20,
            image: null,
            answers: [],
            _o:{
                title: "",
                description: "",
                question: "",
                time: 20,
                image: null,
                answers: [],
            }
        },
        answer: {
            new: true,
            index: -1,
            text: "",
            font: defaultStyle.font,
            fontColorHex: defaultStyle.fontColorHex,
            image: null,
            correct: false,
            _o: {
                text: "",
                font: defaultStyle.font,
                fontColorHex: defaultStyle.fontColorHex,
                image: null,
                correct: false,
            }
        },
        roundTempSort: [],
        isLoading: {},
        ui: {},
        style: defaultStyle
    }
};

export const actionCreators = {
    saveStyle: (): TA => async(dispatch, getState) => {
        const newStyle: QuizNinjaStyle = {
            font: getState().quizninja.answer.font,
            fontColorHex: getState().quizninja.answer.fontColorHex
        };
        saveLocaleStorageItem(quizninjaStyleKey, newStyle);
        dispatch({type: QUIZNINJA_SAVE_STYLE, newStyle});
    },
    applyStyle: (): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_APPLY_STYLE});
    },
    reset: (): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_RESET});
    },
    setField: (e: ChangeEvent<string>|ChangeEvent<number>|ChangeEvent<boolean>): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_FIELD, name:e.target.name, value: e.target.value});
    },
    getGame: (id: string): TA => async (dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'QuizNinja'));
    },
    deleteRound: (roundIndex: number): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: QUIZNINJA_DELETE_ROUND_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/quizninja/${gameId}/round/${roundIndex}`;
        
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: QUIZNINJA_DELETE_ROUND_RESPONSE, data: response.data});
                dispatch({type: QUIZNINJA_SET_ROUND, round: initialState().round});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteRound', error));
            })
    },
    deleteAnswer: (roundIndex: number, answerIndex: number): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: QUIZNINJA_DELETE_ANSWER_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/quizninja/${gameId}/round/${roundIndex}/answer/${answerIndex}`;
        
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: QUIZNINJA_DELETE_ANSWER_RESPONSE, data: data});
                const round = data.rounds[roundIndex];
                round.index = roundIndex;
                dispatch({type: QUIZNINJA_SET_ROUND, round: round});
                dispatch({type: QUIZNINJA_SET_ANSWER, answer:initialState().answer});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteAnswer', error));
            })
    },
    createAnswer: (roundIndex: number, answer: EditAnswer): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: QUIZNINJA_CREATE_ANSWER_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/quizninja/${gameId}/round/${roundIndex}/answer`;
        const data = createQuizNinjaAnswerFormData(answer);

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data: QuizNinjaGame = response.data;
                dispatch({type: QUIZNINJA_CREATE_ANSWER_RESPONSE, data: data});
                const editRound = {...data.rounds[roundIndex], index: roundIndex, _o: {...data.rounds[roundIndex]}};
                if(editRound.answers.length === 1){
                    dispatch(actionCreators.saveStyle());
                }
                dispatch({type: QUIZNINJA_SET_ROUND, round: editRound});
                dispatch({type: QUIZNINJA_SET_ANSWER, answer : initialState().answer});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createAnswer', error));
            })
    },
    updateAnswer: (roundIndex: number, answer: EditAnswer): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        const gameId = game && game.id;
        if(!gameId) return;

        dispatch({type: QUIZNINJA_UPDATE_ANSWER_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/quizninja/${gameId}/round/${roundIndex}/answer/${answer.index}`;
        const data = updateQuizNinjaAnswerFormData(answer);
        
        axios.put(url,  data,  CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: QUIZNINJA_UPDATE_ANSWER_RESPONSE, data: data});
                const round = data.rounds[roundIndex];
                round.index = roundIndex;
                dispatch({type: QUIZNINJA_SET_ROUND, round: round});
                dispatch({type: QUIZNINJA_SET_ANSWER, answer : initialState().answer});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateAnswer', error));
            })
    },
    createRound: (round: EditRound, close: boolean): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        const gameId = game && game.id;

        dispatch({type: QUIZNINJA_CREATE_ROUND_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = 'api/workshop/quizninja/round';
        const data = createQuizNinjaRoundFormData(round, gameId);

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                if(!gameId) history.push(`/workshop/quizninja/edit/${response.data.id}`);
                const round: EditRound = {
                    ...data.rounds[data.rounds.length-1],
                    index:  data.rounds.length-1,
                    _o: {...data.rounds[data.rounds.length-1]}
                };
                dispatch({type: QUIZNINJA_CREATE_ROUND_RESPONSE, data: data, round: round});
                if (close) dispatch({type: QUIZNINJA_SHOW_EDIT_ROUND, show: false});
                else dispatch(actionCreators.setEditAnswer( initialState().answer, -1));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createRound', error));
            });
    },
    updateRound: (round: EditRound, close: boolean): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        const gameId = game && game.id;
        if(!gameId)return;

        dispatch({type: QUIZNINJA_UPDATE_ROUND_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/quizninja/round/${round.index}`;
        const data = updateQuizNinjaRoundFormData(round, gameId);
        
        axios.put(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: QUIZNINJA_UPDATE_ROUND_RESPONSE, data: data});
                if (close) dispatch({type: QUIZNINJA_SHOW_EDIT_ROUND, show: false});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateRound', error));
            });
    },
    setRoundTitleText: (text: string): TA => async(dispatch) =>  {
        dispatch({type: QUIZNINJA_SET_ROUND_TITLE, text:text});
    },
    setRoundDescription: (text: string): TA => async(dispatch) =>  {
        dispatch({type: QUIZNINJA_SET_ROUND_DESCRIPTION, text:text});
    },
    setRoundQuestion: (text: string): TA => async(dispatch) =>  {
        dispatch({type: QUIZNINJA_SET_ROUND_QUESTION, text:text});
    },
    setRoundTime: (number: number): TA => async(dispatch) =>  {
        dispatch({type: QUIZNINJA_SET_ROUND_TIME, number:number});
    },
    setRoundImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_ROUND_IMAGE, image:image});
    },
    setAnswerImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_ANSWER_IMAGE, value: image});
    },
    setAnswerText: (text: string): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_ANSWER_TEXT, value:text});
    },
    setAnswerFont: (font: string): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_ANSWER_FONT, value:font});
    },
    setAnswerFontColor: (fontColorHex: string): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_ANSWER_FONT_COLOR_HEX, value: fontColorHex});
    },
    setCorrect: (correct: boolean): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SET_ANSWER_CORRECT, value:correct});
    },
    setEditRound: (round: QnRound | null, index: number): TA => async(dispatch,  getState) => {
        if (round == null) round = initialState().round;
        const currentEdit = getState().quizninja.round;
        if (currentEdit.index === index){
            round = currentEdit;
        }
        const er: EditRound = {
            ...round,
            index: index,
            new: index < 0,
            _o: {...round}
        };
        dispatch({type: QUIZNINJA_SET_ROUND, round: er});
        dispatch({type: QUIZNINJA_SET_ANSWER, answer: initialState().answer});
        dispatch({type: QUIZNINJA_SHOW_EDIT_ROUND, show: true});
    },
    newAnswer: (): TA => async(dispatch, getState) => {
        const round = getState().quizninja.round;
        const game  = getState().quizninja.game;

        if (round.new){
            dispatch(actionCreators.createRound(round, false));
        } 
        else{
            const roundIndex = round.index;
            const gameRounds = game && game.rounds;
            const originalRound = gameRounds && gameRounds[roundIndex];
            const shouldUpdate = originalRound && (originalRound.title !== round.title ||
                originalRound.description !== round.description ||
                originalRound.question !== round.question ||
                originalRound.time !== round.time ||
                (!originalRound.image && round.image) ||
                (originalRound.image && !round.image) ||
                (originalRound.image && (!isDbImage(round.image))));
            if(shouldUpdate){
                dispatch(actionCreators.updateRound(round, true))
            }
            dispatch(actionCreators.setEditAnswer(null, -1));
        }
    },
    setEditAnswer: (answer: QnAnswer | null, index: number): TA => async(dispatch, getState) => {
        if (answer == null) answer = initialState().answer;
        const currentEdit = getState().quizninja.answer;
        if (currentEdit.index === index){
            answer = currentEdit;
        }
        if(answer == null) return;
        const ea: EditAnswer = {
            ...answer,
            index: index,
            new: index < 0,
            _o: {...answer}
        };
        dispatch({type: QUIZNINJA_SET_ANSWER, answer:ea});
        dispatch({type: QUIZNINJA_SHOW_EDIT_ANSWER, show: true});
    },
    updateSortCommit: (): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        if(!game)return;
        dispatch({type: QUIZNINJA_UPDATE_SORT_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/quizninja/${game.id}/roundsort`;
        const data = {rounds: game.rounds};

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: QUIZNINJA_UPDATE_SORT_RESPONSE, data: data});
                dispatch({type: QUIZNINJA_SET_ROUND, round : initialState().round});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateSortCommit', error));
            })
    },
    showConfirmDeleteAnswer: (show: boolean): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SHOW_ANSWER_DELETE, show:show});
    },
    showConfirmDeleteRound: (show: boolean): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SHOW_ROUND_DELETE, show:show});
    },
    showEditRound: (show: boolean): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SHOW_EDIT_ROUND, show: show});
    },
    showEditAnswer: (show: boolean): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SHOW_EDIT_ANSWER, show:show});
    },
    showAnswerFontColor: (show: boolean): TA => async(dispatch) => {
        dispatch({type: QUIZNINJA_SHOW_ANSWER_FONT_COLOR, show: show});
    },
    setSort: (sourceIndex: number, targetIndex: number): TA => async(dispatch, getState) => {
        const game = getState().quizninja.game;
        if(!game)return;

        const currentArray = [...game.rounds];
        const itemSource = {...currentArray[sourceIndex]};
        const itemTarget = {...currentArray[targetIndex]};

        currentArray[sourceIndex] = itemTarget;
        currentArray[targetIndex] = itemSource;

        dispatch({type: QUIZNINJA_SET_ROUND_SORT, array: currentArray});
    }
};

export const quizNinjaTransformAnswerToStyle = (answer: EditAnswer, style: QuizNinjaStyle): EditAnswer => {
    return {
        ...answer,
        fontColorHex: style.fontColorHex,
        font: style.font
    };
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: QuizNinjaState, action: any) => QuizNinjaState} = {
    ...abstractGameReducerMethods,
    QUIZNINJA_APPLY_STYLE: (state) => {
        return{
            ...state,
            answer: quizNinjaTransformAnswerToStyle(state.answer, state.style)
        }
    },
    QUIZNINJA_SAVE_STYLE: (state, action) => {
        return{
            ...state,
            style: action.newStyle
        }
    },
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            round: initialState().round,
            answer: initialState().answer
        }
    },
    QUIZNINJA_RESET: () => {
        return initialState();
    },
    QUIZNINJA_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    QUIZNINJA_DELETE_ANSWER_REQUEST: (state) => {
        return {
            ...state, 
            isLoading: {
                ...state.isLoading, 
                deleteAnswer: true
            }
        }
    },
    QUIZNINJA_DELETE_ANSWER_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editAnswer: false
            },
            isLoading: {
                ...state.isLoading,
                deleteAnswer: false
            }
        }
    },
    
    QUIZNINJA_DELETE_ROUND_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                deleteRound: true
            }
        }
    },
    QUIZNINJA_DELETE_ROUND_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editRound: false
            },
            isLoading: {
                ...state.isLoading, 
                deleteRound: false
            }
        }
    },
    
    QUIZNINJA_CREATE_ROUND_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                createRound: true,
            }
        };
    },
    QUIZNINJA_CREATE_ROUND_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            round: action.round,
            isLoading: {
                ...state.isLoading,
                createRound: false,
            }
        };
    },

    QUIZNINJA_UPDATE_ANSWER_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading,
                updateAnswer: true
            }
        }
    },
    QUIZNINJA_UPDATE_ANSWER_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editAnswer: false
            },
            isLoading:{
                ...state.isLoading,
                updateAnswer: false
            }
        }
    },
    
    QUIZNINJA_UPDATE_ROUND_REQUEST: (state) => {
        return{
            ...state, 
            isLoading: {
                ...state.isLoading, 
                updateRound: true
            }
        }
    },
    QUIZNINJA_UPDATE_ROUND_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading,
                updateRound: false
            }
        }
    },
    QUIZNINJA_UPDATE_SORT_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                sortRounds: true,
            }
        };
    },
    QUIZNINJA_UPDATE_SORT_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            roundsTempSort: null, 
            isLoading: {
                ...state.isLoading,
                sortRounds: false,
            }
        };
    },

    QUIZNINJA_SHOW_ROUND_IMAGE: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                roundImage: action.show
            }
        };
    },

    QUIZNINJA_SHOW_EDIT_ANSWER: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui,
                editAnswer: action.show
            }
        };
    },
    QUIZNINJA_SHOW_ANSWER_IMAGE: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui, 
                answerImage: action.show
            }
        };
    },
    QUIZNINJA_SHOW_ANSWER_FONT_COLOR: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showAnswerFontColor: action.show
            }
        }
    },
    
    QUIZNINJA_SHOW_EDIT_ROUND: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                editRound: action.show
            }
        };
    },
    QUIZNINJA_SHOW_ANSWER_DELETE: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                deleteAnswer: action.show
            }
        }
    },
    QUIZNINJA_SHOW_ROUND_DELETE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                deleteRound: action.show
            }
        }
    },

    QUIZNINJA_SET_ROUND: (state, action) => {
        return{
            ...state, 
            round: {
                ...action.round,
                _o: action.round
            },
        }
    },
    
    QUIZNINJA_SET_ROUND_TITLE: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                title: action.text
            }
        };
    },
    QUIZNINJA_SET_ROUND_DESCRIPTION: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                description: action.text
            }
        };
    },
    QUIZNINJA_SET_ROUND_QUESTION: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                question: action.text
            }
        };
    },
    QUIZNINJA_SET_ROUND_TIME: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                time: action.number
            }
        };
    },
    QUIZNINJA_SET_ROUND_IMAGE: (state, action) => {
        return{
            ...state,
            round: {
                ...state.round,
                image: action.image
            }
        };
    },

    QUIZNINJA_SET_ROUND_SORT: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                rounds: action.array
            }
        };
    },

    QUIZNINJA_SET_ANSWER_TEXT: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                text: action.value
            }
        };
    },
    QUIZNINJA_SET_ANSWER_FONT: (state, action) => {
        return{
            ...state, 
            answer: {
                ...state.answer, 
                font: action.value
            }
        };
    },
    QUIZNINJA_SET_ANSWER_FONT_COLOR_HEX: (state, action) => {
        return{
            ...state,
            answer:{
                ...state.answer,
                fontColorHex: action.value
            }
        }
    },
    QUIZNINJA_SET_ANSWER_IMAGE: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer,
                image: action.value
            }
        };
    },
    QUIZNINJA_SET_ANSWER_CORRECT: (state, action) => {
        return{
            ...state, 
            answer:{
                ...state.answer, 
                correct: action.value
            }
        };
    },
    QUIZNINJA_SET_ANSWER: (state, action) => {
        return{
            ...state, 
            answer: action.answer
        };
    },

    QUIZNINJA_CREATE_ANSWER_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                createAnswer: true,
            }
        }
    },
    QUIZNINJA_CREATE_ANSWER_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                editAnswer: false,
            },
            isLoading:{
                ...state.isLoading,
                createAnswer: false,
            }
        }
    },
   
    ERROR: (state, action) => {
        if (action.reducer !== 'quizninja') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<QuizNinjaState, any> = (state, action) => {
    state = state || initialState();
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};