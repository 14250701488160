import {Reducer} from "redux";
import {TA} from "./configureStore";

export const reducerName = 'navigation';

const NAVIGATION_PROFILE_TOGGLE = 'NAVIGATION_PROFILE_TOGGLE';
const NAVIGATION_SHOW_MAIN_NAV = 'NAVIGATION_SHOW_MAIN_NAV';

export interface NavigationState {
    ui: {
        showProfileNav: boolean;
        showMainNav: boolean;
    };
}

const initialState: NavigationState = {
    ui: { showProfileNav: false, showMainNav: false }
};

export const actionCreators = {
    toggleProfileNav: (show: boolean): TA => async (dispatch, getState) => {
        if (undefined === show){
            show = !getState().navigation.ui.showProfileNav;
        } 
        dispatch({type: NAVIGATION_PROFILE_TOGGLE, show});
    },
    showMainNav: (show: boolean): TA => async(dispatch) => {
        dispatch({type: NAVIGATION_SHOW_MAIN_NAV, show});
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: NavigationState, action: any) => NavigationState} = {
    NAVIGATION_PROFILE_TOGGLE: (state, action) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                showProfileNav: action.show
            }
        }
    },
    NAVIGATION_SHOW_MAIN_NAV: (state, action) =>{
        return{
            ...state,
            ui: {
                ...state.ui,
                showMainNav: action.show
            }
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<NavigationState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};