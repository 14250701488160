import axios from "axios";
import {errorActions} from "./Error";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {actionCreators as accountActions} from "./Account";
import {actionCreators as msgActions} from './MessageStore';
import {PlayListType} from "../model/PlayListType";
import {Reducer} from "redux";
import {ChangeEvent, TA} from "./configureStore";
import {ImageUploadType} from "../model/ImageUploadType";
import {findGameInState} from "../model/Game/BaseGame";
import {isDbImage} from "../services/ImageHelper";
import {PlaylistStats} from "../model/PlaylistStats";
import {AddGameRequest} from "../model/Request/playlist/AddGameRequest";
import {history} from "../index";


const PLAYLIST_RESET = 'PLAYLIST_RESET';
const PLAYLIST_GET_REQUEST = 'PLAYLIST_GET_REQUEST';
const PLAYLIST_GET_RESPONSE = 'PLAYLIST_GET_RESPONSE';
const PLAYLIST_GET_ALL_REQUEST = 'PLAYLIST_GET_ALL_REQUEST';
const PLAYLIST_GET_ALL_RESPONSE = 'PLAYLIST_GET_ALL_RESPONSE';
const PLAYLIST_CREATE_REQUEST = 'PLAYLIST_CREATE_REQUEST';
const PLAYLIST_CREATE_RESPONSE = 'PLAYLIST_CREATE_RESPONSE';
const PLAYLIST_ADD_AUTHOR_REQUEST = 'PLAYLIST_ADD_AUTHOR_REQUEST';
const PLAYLIST_ADD_AUTHOR_RESPONSE = 'PLAYLIST_ADD_AUTHOR_RESPONSE';
const PLAYLIST_TRANSFER_OWNER_REQUEST = 'PLAYLIST_TRANSFER_OWNER_REQUEST';
const PLAYLIST_TRANSFER_OWNER_RESPONSE = 'PLAYLIST_TRANSFER_OWNER_RESPONSE';
const PLAYLIST_REMOVE_AUTHOR_REQUEST = 'PLAYLIST_REMOVE_AUTHOR_REQUEST';
const PLAYLIST_REMOVE_AUTHOR_RESPONSE = 'PLAYLIST_REMOVE_AUTHOR_RESPONSE';
const PLAYLIST_UPDATE_REQUEST = 'PLAYLIST_UPDATE_REQUEST';
const PLAYLIST_UPDATE_RESPONSE = 'PLAYLIST_UPDATE_RESPONSE';
const PLAYLIST_DELETE_REQUEST = 'PLAYLIST_DELETE_REQUEST';
const PLAYLIST_DELETE_RESPONSE = 'PLAYLIST_DELETE_RESPONSE';
const PLAYLIST_ADD_GAME_REQUEST = 'PLAYLIST_ADD_GAME_REQUEST';
const PLAYLIST_ADD_GAME_RESPONSE = 'PLAYLIST_ADD_GAME_RESPONSE';
const PLAYLIST_REMOVE_GAME_REQUEST = 'PLAYLIST_REMOVE_GAME_REQUEST';
const PLAYLIST_REMOVE_GAME_RESPONSE = 'PLAYLIST_REMOVE_GAME_RESPONSE';
const PLAYLIST_SET_SORT_REQUEST = 'PLAYLIST_SET_SORT_REQUEST';
const PLAYLIST_SET_SORT_RESPONSE = 'PLAYLIST_SET_SORT_RESPONSE';
const PLAYLIST_SET_SORT = 'PLAYLIST_SET_SORT';
const PLAYLIST_GET_BYFLOOR_REQUEST = 'PLAYLIST_GET_BYFLOOR_REQUEST';
const PLAYLIST_GET_BYFLOOR_RESPONSE = 'PLAYLIST_GET_BYFLOOR_RESPONSE';

const PLAYLIST_SET_EDIT = 'PLAYLIST_SET_EDIT';
const PLAYLIST_SET_NAME = 'PLAYLIST_SET_NAME';
const PLAYLIST_SET_DESCRIPTION = 'PLAYLIST_SET_DESCRIPTION';
const PLAYLIST_SET_IMAGE = 'PLAYLIST_SET_IMAGE';
const PLAYLIST_SET_ISPUBLIC = 'PLAYLIST_SET_ISPUBLIC';
const PLAYLIST_SET_ISPUBLISHED = 'PLAYLIST_SET_ISPUBLISHED';
const PLAYLIST_SET_FIELD = 'PLAYLIST_SET_FIELD';

const PLAYLIST_SHOW_MENU = 'PLAYLIST_SHOW_MENU';
const PLAYLIST_SHOW_EDIT = 'PLAYLIST_SHOW_EDIT';
const PLAYLIST_SHOW_COLORPICKER = 'PLAYLIST_SHOW_COLORPICKER';
const PLAYLIST_SHOW_CONFIRM_DELETE = 'PLAYLIST_SHOW_CONFIRM_DELETE';
const PLAYLIST_SHOW_CONFIRM_REMOVE_GAME = 'PLAYLIST_SHOW_CONFIRM_REMOVE_GAME';
const PLAYLIST_SHOW_TRANSFER_OWNER = 'PLAYLIST_SHOW_TRANSFER_OWNER';
const PLAYLIST_SHOW_AUTHOR_FORM = 'PLAYLIST_SHOW_AUTHOR_FORM';


export const reducerName = 'playlist';

interface EditPlaylist extends PlayListType{
    new: boolean;
    _o: PlayListType;
}

export interface PlayListState {
    playListsMap: {[key: string]: PlayListType};
    playlistIdsOnOrganizationsFloors: string[];
    playlistStats: PlaylistStats | undefined;
    loading: {
        getPlayLists: boolean; createPlayList: boolean; updatePlayList: boolean; deletePlayList: boolean;
        addAuthor: boolean; removeAuthor: boolean; addGame: boolean; removeGame: boolean; getGames: boolean;
        showSelectListForGame: boolean; transferOwner: boolean; updateSort?: string; getPlaylistsByFloor?: true;
        getPlaylistsOnOrganizationsFloors?: boolean;
    };
    playListEdit: EditPlaylist;
    ui: {
        showEdit: boolean; showConfirmDelete: boolean; showMenu: false|string;
        showRemoveGame: false|string; backgroundColorPicker: boolean;
        showTransferOwner: boolean; showAuthorForm: boolean;
    };
}

const initialState: PlayListState = {
    playListsMap: {},
    playlistIdsOnOrganizationsFloors: [],
    loading: {
        getPlayLists: false, createPlayList: false, updatePlayList: false, deletePlayList: false, addAuthor: false,
        removeAuthor: false, addGame: false, removeGame: false, getGames: false, showSelectListForGame: false,
        transferOwner: false
    },
    playlistStats: undefined,
    playListEdit: {
        new: true,
        backgroundColorHex : '#000000',
        description : '',
        fontColorHex: '#FFFFFF',
        fontFamily: 'Lato',
        fontSize: 24,
        image: null,
        isPublic: false,
        isPublished: false,
        labelColorHex: '#000000',
        name: '',
        showLabel: true,
        showTitle: true,
        id: '',
        tags : '',
        ownerId: '',
        countries: '',
        owner: null,
        organizationId: '',
        imageId: '',
        editedDateTime: '',
        createdDateTime: '',
        authorIds: [],
        games: [],
        _o:{
            backgroundColorHex : '#000000',
            description : '',
            fontColorHex: '#FFFFFF',
            fontFamily: 'Lato',
            fontSize: 24,
            image: null,
            isPublic: false,
            isPublished: false,
            labelColorHex: '#000000',
            name: '',
            showLabel: true,
            showTitle: true,
            id: '',
            tags : '',
            ownerId: '',
            countries: '',
            owner: null,
            organizationId: '',
            imageId: '',
            editedDateTime: '',
            createdDateTime: '',
            authorIds: [],
            games: [],
        }
    },
    ui: {
        showEdit: false, showConfirmDelete: false, showMenu: false,
        showRemoveGame: false, backgroundColorPicker: false,
        showTransferOwner: false, showAuthorForm: false
    }
};

export const actionCreators = {
    reset: (): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_RESET});
    },
    showMenu: (playlistId: false|string): TA => async(dispatch, getState) => {
        if(playlistId === getState().playlist.ui.showMenu) playlistId = false;
        dispatch({type: PLAYLIST_SHOW_MENU, id: playlistId});
    },
    showEdit: (show: boolean): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SHOW_EDIT, show: show});
    },
    showConfirmDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SHOW_CONFIRM_DELETE, show});
    },
    showColorPicker: (colorPicker: string, show: boolean): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SHOW_COLORPICKER, colorPicker, show});
    },
    showRemoveGameConfirm: (show: false|string): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SHOW_CONFIRM_REMOVE_GAME, show});
    },
    showTransferOwner: (show: boolean): TA => async(dispatch) => {
        dispatch({type:  PLAYLIST_SHOW_TRANSFER_OWNER, show});
    },
    showAuthorForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type:  PLAYLIST_SHOW_AUTHOR_FORM, show});
    },
    setEditField: (e: ChangeEvent<string>|ChangeEvent<boolean>|ChangeEvent<number>): TA => async(dispatch) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatch({type: PLAYLIST_SET_FIELD, name: name, value: value});
    },
    setEdit: (playlist: EditPlaylist | null): TA => async(dispatch) => {
        if(playlist === null) playlist = initialState.playListEdit;
        dispatch(accountActions.get(playlist.ownerId ,false));
        playlist.authorIds.forEach(x => {
            dispatch(accountActions.get(x, false));
        });
        dispatch({type: PLAYLIST_SET_EDIT, data: playlist});
    },
    setName: (value: string): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SET_NAME, value:value});
    },
    setDescription: (value: string): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SET_DESCRIPTION, value: value});
    },
    setImage: (image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SET_IMAGE, image});
    },
    setIsPublic: (isPublic: boolean): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SET_ISPUBLIC, value:isPublic});
    },
    setIsPublished: (isPublished: boolean): TA => async(dispatch) => {
        dispatch({type: PLAYLIST_SET_ISPUBLISHED, value: isPublished});
    },
    updateSort: (playlistId: string, source: string, target: string): TA => async(dispatch, getState) => {
        const playlist = getState().playlist.playListsMap[playlistId];
        if(!playlist) return;
        const currentArray = playlist.games ? [...playlist.games] : [];
        
        const sourceIndex = currentArray.findIndex(x => x.itemId === source);
        const targetIndex = currentArray.findIndex(x => x.itemId === target);
        const itemToMove = currentArray[sourceIndex];

        currentArray.splice(sourceIndex, 1);
        currentArray.splice(targetIndex, 0, itemToMove);
        dispatch({ type: PLAYLIST_SET_SORT, array: currentArray, playlistId });

    },
    updateSortCommit: (playlistId: string): TA => async(dispatch, getState) => {
        const playlist = getState().playlist.playListsMap[playlistId];
        if(!playlist) return;

        let alreadyInOrder = true;
        //Check if already sorted
        if (!playlist.games || playlist.games.length < 2) return;
        //If next element is smaller than current array not sorted.
        for (let i = 0; alreadyInOrder && i < playlist.games.length - 1; i++){
            if (playlist.games[i+1].sortIndex < playlist.games[i].sortIndex){
                alreadyInOrder = false;
            }
        }
        if(alreadyInOrder) return;

        dispatch({type: PLAYLIST_SET_SORT_REQUEST, playlistId});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/playlist/${playlistId}/updateSort`;
        const data = {
            sort: playlist.games && playlist.games.map((x,i) => {
            return {
                id: x.itemId,
                sort: i
            }
        })};

        axios.put(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data: PlayListType = response.data;
                dispatch({type: PLAYLIST_SET_SORT_RESPONSE, data: data});
                dispatch(msgActions.addMessage("pop_change", "pop_msg_playlist_sort_update", {name: data.name}));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateSort', error));
            })
    },
    transferOwner: (playlistId: string, newOwnerId: string): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_TRANSFER_OWNER_REQUEST, playlistId: playlistId});
        const url = `api/workshop/playlist/${playlistId}/transferOwner/${newOwnerId}`;

        axios.post(url, null, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: PLAYLIST_TRANSFER_OWNER_RESPONSE, data: response.data});
                dispatch(accountActions.get(newOwnerId, false));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'transferOwner', error));
            });

    },
    addAuthor: (playListId: string, authorId: string): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_ADD_AUTHOR_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = `api/workshop/playlist/${playListId}/author/${authorId}`;
        axios.post(url, null, CreateAuthHeader(getState))
            .then(response => {
                const playlist = response.data;
                dispatch(accountActions.get(authorId, false));
                dispatch({type: PLAYLIST_ADD_AUTHOR_RESPONSE, data: playlist});
                dispatch(accountActions.showSearch(false));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'addAuthor', error));
            })
    },
    removeAuthor: (playListId: string, authorId: string): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_REMOVE_AUTHOR_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/playlist/${playListId}/author/${authorId}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const playlist = response.data;
                dispatch({type: PLAYLIST_REMOVE_AUTHOR_RESPONSE, data: playlist});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'removeAuthor', error));
            })
    },
    addGame: (playListId: string, gameId: string): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_ADD_GAME_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const data: AddGameRequest = {
            gameId: gameId,
        };

        const url = `api/workshop/playlist/${playListId}/game`;
        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const playlist: PlayListType = response.data;
                dispatch({type: PLAYLIST_ADD_GAME_RESPONSE, data: playlist});

                //Try to find the game that was added
                const game = findGameInState(getState(), gameId);

                dispatch(msgActions.addMessage("pop_added", "pop_msg_playlist_game_add", {name: game ? game.title : ''}));
            })
            .catch(error => {
                console.log(error);
                dispatch(errorActions.reportAxiosError('playlist', 'addGame', error));
            })
    },
    removeGame: (playListId: string, gameId: string|false): TA => async(dispatch, getState) => {
        if(!gameId) return;
        dispatch({type: PLAYLIST_REMOVE_GAME_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        const url = `api/workshop/playlist/${playListId}/game/${gameId}`;

        //Try to find the game that was added
        const game = findGameInState(getState(), gameId);

        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const playlist: PlayListType = response.data;
                dispatch({type: PLAYLIST_REMOVE_GAME_RESPONSE, data: playlist});
                dispatch(msgActions.addMessage("pop_deleted", "pop_msg_playlist_game_removed", {name: game ? game.title : ''}));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'removeGame', error));
            })
    },
    createPlayList: (playlist: EditPlaylist, openSearchAuthor: boolean): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_CREATE_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = 'api/workshop/playlist';

        const body = {
            Name: playlist.name,
            Description: playlist.description,
            isPublic: playlist.isPublic,
            isPublished: playlist.isPublished,
            image: playlist.image,
            showTitle: playlist.showTitle,
            showLabel: playlist.showLabel,
            fontColorHex: playlist.fontColorHex,
            fontSize: playlist.fontSize,
            fontFamily: playlist.fontFamily,
            labelColorHex: playlist.labelColorHex,
            backgroundColorHex: playlist.backgroundColorHex
        };

        axios.post(url, body, CreateAuthHeader(getState))
            .then(response => {
                const playlist = response.data;
                dispatch({type: PLAYLIST_CREATE_RESPONSE, data: playlist});
                if (openSearchAuthor){
                    dispatch(actionCreators.setEdit(playlist));
                    dispatch(accountActions.showSearch(true));
                }
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'createPlayList', error));
            });
    },
    updatePlayList: (playlist: EditPlaylist): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_UPDATE_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/playlist/${playlist.id}`;
        const reuseImage = !!(playlist.image && isDbImage(playlist.image));

        const body = {
            Name: playlist.name,
            Description: playlist.description,
            IsPublic: playlist.isPublic,
            isPublished: playlist.isPublished,
            image: reuseImage ? null : playlist.image,
            showTitle: playlist.showTitle,
            showLabel: playlist.showLabel,
            fontColorHex: playlist.fontColorHex,
            fontSize: playlist.fontSize,
            fontFamily: playlist.fontFamily,
            labelColorHex: playlist.labelColorHex,
            backgroundColorHex: playlist.backgroundColorHex,
            ReuseImage: reuseImage
        };

        const playlistBeforeUpdate = {...getState().playlist.playListsMap[playlist.id]};

        axios.put(url, body, CreateAuthHeader(getState))
            .then(response => {
                const playlistResponse: PlayListType = response.data;
                dispatch({type: PLAYLIST_UPDATE_RESPONSE, data: playlistResponse});
                if(
                    playlistResponse.isPublic !== playlistBeforeUpdate.isPublic ||
                    playlistResponse.isPublished !== playlistBeforeUpdate.isPublished
                ){
                    if(!playlistResponse.isPublished){
                        dispatch(msgActions.addMessage("pop_change", "pop_msg_playlist_draft", {name: playlistResponse.name}));
                    }
                    else if(!playlistResponse.isPublic){
                        dispatch(msgActions.addMessage("pop_change", "pop_msg_playlist_published", {name: playlistResponse.name}));
                    }
                    else{
                        dispatch(msgActions.addMessage("pop_change", "pop_msg_playlist_public", {name: playlistResponse.name}));
                    }
                }
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'updatePlayList', error));
            });
    },
    _updatePlaylist: (p: PlayListType): TA => async(dispatch) => {
        dispatch({ type: PLAYLIST_UPDATE_RESPONSE, data: p });
    },
    deletePlayList: (id: string): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_DELETE_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const playlistBeforeUpdate = {...getState().playlist.playListsMap[id]};

        const url = `api/workshop/playlist/${id}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(() => {
                dispatch({type: PLAYLIST_DELETE_RESPONSE, id:id});
                dispatch(msgActions.addMessage("pop_deleted", "pop_msg_playlist_removed", {name: playlistBeforeUpdate.name}));
                if (history.location.pathname.includes('/workshop/playlist/edit/')) {
                    history.push(`/playlist`);
                }
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'deletePlayList', error));
            })
    },
    getPlayLists: (): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_GET_ALL_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/playlist`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                const playlistMap = data.reduce(function (acc: { [key: string]: PlayListType }, cur: PlayListType) {
                    acc[cur.id] = cur;
                    return acc;
                }, {});
                dispatch({type: PLAYLIST_GET_ALL_RESPONSE, data: playlistMap});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'getPlayLists', error));
            })

    },
    getPlayList: (id: string): TA => async(dispatch, getState) => {
        dispatch({type: PLAYLIST_GET_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/playlist/${id}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: PLAYLIST_GET_RESPONSE, data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError('playlist', 'getPlayList', error));
            })
    },
    getPlaylistsByFloor: (apiKey: string): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetError(reducerName, 'getPlaylistsByFloor'));
        dispatch({type: PLAYLIST_GET_BYFLOOR_REQUEST});

        const url = `api/workshop/playlist/byfloor`;
        axios.post(url, {id: apiKey}, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: PLAYLIST_GET_BYFLOOR_RESPONSE, data: response.data});
            })
            .catch((error) => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getPlaylistsByFloor', error));
            })
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: PlayListState, action: any) => PlayListState} = {
    PLAYLIST_RESET: (state, action) => {
        return{
            ...state,
            playlistStats: undefined
        }
    },
    PLAYLIST_GET_ORGANIZATIONS_FLOORS_REQUEST: (state) => {
        return{
            ...state,
            loading: {
                ...state.loading,
                getPlaylistsOnOrganizationsFloors: true
            }
        }
    },
    PLAYLIST_GET_ORGANIZATIONS_FLOORS_RESPONSE: (state, action) => ({
        ...state,
        playListsMap: { ...state.playListsMap, ...action.data },
        playlistIdsOnOrganizationsFloors: action.ids,
        loading:{
            ...state.loading,
            getPlaylistsOnOrganizationsFloors: undefined
        }
    }),
    PLAYLIST_GET_BYFLOOR_REQUEST : (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getPlaylistsByFloor: true
            }
        }
    },
    PLAYLIST_GET_BYFLOOR_RESPONSE: (state, action) => {
        const playlistDict: {[key: string]: PlayListType} = action.data;
        return{
            ...state,
            playListsMap:{
                ...state.playListsMap,
                ...playlistDict
            },
            loading:{
                ...state.loading,
                getPlaylistsByFloor: undefined,
            }
        }
    },
    PLAYLIST_SET_SORT: (state, action ) => {
        return{
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.playlistId]: {
                    ...state.playListsMap[action.playlistId],
                    games: action.array
                }
            }
        }
    },
    PLAYLIST_SET_SORT_REQUEST: (state, action) => {
        return{
            ...state,
            loading: {
                ...state.loading,
                updateSort: action.playlistId
            }
        }
    },
    PLAYLIST_SET_SORT_RESPONSE: (state, action) => {
        return{
            ...state,
            loading: {
                ...state.loading,
                updateSort: undefined
            },
            playListsMap: {
                [action.data.id]: action.data
            }
        }
    },
    PLAYLIST_SET_FIELD: (state, action) => {
        return{
            ...state,
            playListEdit: {
                ...state.playListEdit,
                [action.name]: action.value
            }
        }
    },
    PLAYLIST_GET_REQUEST:(state) => {
        return{
            ...state,
            playListsMap: {
                ...state.playListsMap,
                //TODO loading thingy?
            }
        }
    },
    PLAYLIST_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id]: action.data
            }
        }
    },
    PLAYLIST_DELETE_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                deletePlayList: true
            }
        }
    },
    PLAYLIST_DELETE_RESPONSE: (state, action) => {
        const currentMap = state.playListsMap;
        const {...rest} = currentMap;
        delete rest[action.id];
        return{
            ...state,
            playListsMap: rest,
            loading:{
                ...state.loading,
                deletePlayList: false
            },
            ui: {
                ...state.ui,
                showEdit: false,
            }
        }
    },
    PLAYLIST_SHOW_TRANSFER_OWNER: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showTransferOwner: action.show,
                showAuthorForm: false
            }
        }
    },
    PLAYLIST_SHOW_AUTHOR_FORM: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showAuthorForm: action.show
            }
        }
    },
    PLAYLIST_SHOW_COLORPICKER: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                [action.colorPicker]: action.show
            }
        }
    },
    PLAYLIST_SHOW_CONFIRM_REMOVE_GAME: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showRemoveGame: action.show
            }
        }
    },
    PLAYLIST_TRANSFER_OWNER_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                transferOwner: true
            }
        }
    },
    PLAYLIST_TRANSFER_OWNER_RESPONSE: (state, action) => {
        const newState = {
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id] : action.data,
            },
            loading:{
                ...state.loading,
                transferOwner: false
            },
            ui:{
                ...state.ui,
                showTransferOwner: false,
            }
        };
        if(state.playListEdit.id === action.data.id){
            newState.playListEdit = action.data;
        }
        return newState;
    },
    PLAYLIST_ADD_AUTHOR_REQUEST: (state) => {
        return{
            ...state, 
            loading:{
                ...state.loading, 
                addAuthor: true
            }
        }
    },
    PLAYLIST_ADD_AUTHOR_RESPONSE: (state, action) => {
        const newState = {
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id] : action.data,
            },
            loading:{
                ...state.loading, 
                addAuthor: false
            }
        };
        if(state.playListEdit.id === action.data.id){
            newState.playListEdit = action.data;
        }
        return newState;
    },
    PLAYLIST_REMOVE_AUTHOR_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                removeAuthor: true
            }
        }
    },
    PLAYLIST_REMOVE_AUTHOR_RESPONSE: (state, action) => {
        const newState = {
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id] : action.data,
            },
            loading:{
                ...state.loading,
                removeAuthor: false
            }
        };
        if(state.playListEdit.id === action.data.id){
            newState.playListEdit = action.data;
        }
        return newState;
    },
    
    PLAYLIST_CREATE_REQUEST: (state) => {
        return{
            ...state,
            loading: {
                ...state.loading,
                createPlayList: true
            }
        }
    },
    PLAYLIST_CREATE_RESPONSE: (state, action) => {
        return{
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id]: action.data
            },
            loading: {
                ...state.loading,
                createPlayList: false
            },
            ui:{
                ...state.ui,
                showEdit: false
            }
        }
    },

    PLAYLIST_UPDATE_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                updatePlayList: true
            }
        }
    },
    PLAYLIST_UPDATE_RESPONSE: (state, action) => {
        return{
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id] : action.data
            },
            loading:{
                ...state.loading,
                updatePlayList: false
            },
            ui: {
                ...state.ui,
                showEdit: false
            }
        }
    },
    
    PLAYLIST_GET_ALL_REQUEST: (state) => {
        return{
            ...state,
            loading: {
                ...state.loading, 
                getPlayLists: true
            }
        }
    },
    PLAYLIST_GET_ALL_RESPONSE: (state, action) => {
        return{
            ...state,
            playListsMap: {...state.playListsMap, ...action.data},
            loading:{
                ...state.loading, 
                getPlayLists: false
            }
        }
    },

    PLAYLIST_ADD_GAME_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                addGame: true,
            }
        }
    },
    PLAYLIST_ADD_GAME_RESPONSE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showSelectListForGame: false
            },
            playListsMap:{
                ...state.playListsMap,
                [action.data.id]: action.data
            },
            loading:{
                ...state.loading,
                addGame: false,
            }
        }
    },
    PLAYLIST_REMOVE_GAME_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                removeGame: true,
            }
        }
    },
    PLAYLIST_REMOVE_GAME_RESPONSE: (state, action) => {
        return{
            ...state,
            playListsMap: {
                ...state.playListsMap,
                [action.data.id]: action.data
            },
            ui:{
                ...state.ui,
                showRemoveGame: false,
            },
            loading:{
                ...state.loading,
                removeGame: false
            }
        }
    },

    PLAYLIST_SET_EDIT: (state, action) => {
        return{
            ...state, 
            playListEdit: action.data,
            ui:{
                ...state.ui,
                showEdit: true,
            }
        }
    },
    PLAYLIST_SET_DESCRIPTION: (state, action) => {
        return{
            ...state, 
            playListEdit:{
                ...state.playListEdit,
                description: action.value,
            }
        }
    },
    PLAYLIST_SET_NAME: (state, action) => {
        return{
            ...state, 
            playListEdit:{
                ...state.playListEdit,
                name: action.value
            }
        }
    },
    PLAYLIST_SET_IMAGE: (state, action) => {
        return{
            ...state,
            playListEdit:{
                ...state.playListEdit,
                image: action.image
            },
            ui:{
                ...state.ui,
                showImageForm: false
            }
        }
    },
    PLAYLIST_SET_ISPUBLIC: (state, action) => {
        return{
            ...state,
            playListEdit:{
                ...state.playListEdit,
                isPublic: action.value
            }
        }
    },
    PLAYLIST_SET_ISPUBLISHED: (state, action) => {
        return{
            ...state,
            playListEdit:{
                ...state.playListEdit,
                isPublished: action.value
            }
        }
    },
    PLAYLIST_SHOW_MENU: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showMenu: action.id
            }
        }
    },
    PLAYLIST_SHOW_CONFIRM_DELETE: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                showConfirmDelete: action.show
            }
        }
    },
    PLAYLIST_SHOW_EDIT: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui, 
                showEdit: action.show
            }
        }
    },
    
    ERROR: (state, action) => {
        if (action.reducer !== 'playlist') return state;
        return {
            ...state,
            loading: {
                ...state.loading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<PlayListState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};