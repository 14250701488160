import React from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as GamesStore from "../../../store/games/Games";
import {GamesState} from "../../../store/games/Games";
import {CheckBox, SelectList, SubmitButton} from "../FormGroup";
import {GameType} from "../../../model/Game/GameType";
import {AppState} from "../../../store/configureStore";
import FieldErrors from '../FieldErrors';
import { ErrorType } from '../../../store/Error';

interface Props {
    gameId: string;
    gameType: GameType;
    gamesState: GamesState;
    gamesActions: typeof GamesStore.actionCreators;
    errors: ErrorType | undefined;
}
const CreateCopyForm = (props: Props) => {
    const langCodes = props.gamesState.languageCodes;

    return(
        <Dialog
            onClose={() => props.gamesActions.showCreateCopy(props.gameType, false)}
            loading={props.gamesState.loading.createCopy}
        >
            <form onSubmit={e => e.preventDefault()}>
                <SelectList
                    name={'Language'}
                    options={langCodes}
                    defaultValue={props.gamesState.createCopy.langCode}
                    onChange={e => {props.gamesActions.setCopyLanguageCode(e.target.value)}}
                    noLabel
                />
                <CheckBox
                    name={'googleTranslate'}
                    label={'use_google_translate'}
                    active={props.gamesState.createCopy.googleTranslate}
                    onChange={(e) => props.gamesActions.setGoogleTranslate(e.target.value)}
                    disabled={!props.gamesState.createCopy.canTranslate}
                    disabledHint={'google_cant_translate'}
                />
                {props.errors &&
                    <div className="form-error"><FieldErrors errors={props.errors} fieldname={'preventCopy'} /></div>
                }
                <SubmitButton
                    text='submit'
                    onClick={() => {
                        props.gamesActions.createCopy(props.gameId, props.gameType, props.gamesState.createCopy.langCode, props.gamesState.createCopy.googleTranslate)}
                    }
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ gamesState: state[GamesStore.reducerName], errors: state.errors.gamesState.createCopy }),
    dispatch => ({
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(CreateCopyForm);