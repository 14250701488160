import { 
    AbstractGameIsLoading, 
    abstractGameReducerMethods, AbstractGameState, 
    AbstractGameUi, 
    actionCreators as gamesActions 
} from './Games'; 
import {errorActions} from "../Error"; 
import {ChangeEvent, TA} from "../configureStore"; 
import {Reducer} from "redux"; 
import { DanceASlothGame } from '../../model/Game/DanceASloth/DanceASlothGame'; 
 
 
export const reducerName = 'danceASlothState'; 
 
export interface DanceASlothState extends AbstractGameState{ 
    game?: DanceASlothGame; 
    isLoading: AbstractGameIsLoading; 
    ui: AbstractGameUi; 
} 
 
const initialState: DanceASlothState = { 
    game: undefined, 
    gameStats: undefined, 
    gameType: "DanceASloth", 
    isLoading: { 
        getGame: false, addAuthor: false, removeAuthor: false, transferOwner: false, updateSettings: false 
    }, 
    ui: { showSettings: false} 
}; 
 
export const actionCreators = { 
    resetGame: (): TA => async(dispatch) => { 
        dispatch({type: 'DANCE_A_SLOTH_RESET'}); 
    }, 
    setField: (e: ChangeEvent<string>|ChangeEvent<number>|ChangeEvent<boolean>): TA => async(dispatch) => { 
        dispatch({ type: 'DANCE_A_SLOTH_SET_FIELD', name: e.target.name, value: e.target.value}); 
    }, 
    getGame: (id: string): TA => async(dispatch,) => { 
        dispatch(gamesActions.getGame(id, true, 'DanceASloth')); 
        dispatch(errorActions.resetAllErrors(reducerName)); 
    } 
}; 
 
// eslint-disable-next-line 
const reducerMethods: { [key: string]: (state: DanceASlothState, action: any) => DanceASlothState} = { 
    ...abstractGameReducerMethods, 
    DANCE_A_SLOTH_RESET: () => { 
        return initialState; 
    }, 
    DANCE_A_SLOTH_SET_FIELD: (state, action) => { 
        if(!state.game) return state; 
        return{ 
            ...state, 
            game:{ 
                ...state.game, 
                [action.name]: action.value 
            } 
        } 
    }, 
    ERROR: (state, action) => { 
        if (action.reducer !== reducerName) return state; 
        return { 
            ...state, 
            isLoading: { 
                ...state.isLoading, 
                [action.key]: false 
            }, 
        }; 
    } 
}; 
 
// eslint-disable-next-line 
export const reducer: Reducer<DanceASlothState, any> = (state, action) => { 
    state = state || initialState; 
    const method = reducerMethods[action.type]; 
    if (method) return method(state, action); 
    return state; 
};