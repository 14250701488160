const languageNames: {[key: string]: {[key: string]: string}} ={
    'ar':{
        DK: "الدنمارك",
        NL: "هولندا",
        IL: "إسرائيل",
        "IL(Arabic)":"إسرائيل",
        NO: "النرويج",
        SE: "السويد",
        US: "الولايات المتحدة الامريكيه",
        UK: "الخامس",
        SA: "المملكة العربية السعودية",
        SG: "سنغافورة",
        SI: "السلوفينية"
    },
    'da':{
        DK: "Danmark",
        NL: "Holland",
        IL: "Israel",
        "IL(Arabic)":"Israel",
        NO: "Norge",
        SE: "Sverige",
        US: "Amerikas Forenede Stater",
        UK: "Storbritianen",
        SA: "Saudi arabaien",
        SG: "Singapore",
        SI: "Slovenien"
    },
    'en':{
        DK: "Denmark",
        NL: "Netherlands",
        IL: "Israel",
        "IL(Arabic)":"Israel",
        NO: "Norway",
        SE: "Sweden",
        US: "United States of America",
        UK: "United Kingdom",
        SA: "Saudi Arabia",
        SG: "Singapore",
        SI: "Slovenia"
    },
    'es':{
        DK: "Dinamarca",
        NL: "Países Bajos",
        IL: "Israel",
        "IL(Arabic)":"Israel",
        NO: "Noruega",
        SE: "Suecia",
        US: "Estados Unidos de America",
        UK: "Reino Unido",
        SA: "Arabia Saudita",
        SG: "Singapur",
        SI: "Esloveno"
    },
    'iw':{
        DK: "דנמרק",
        NL: "הולנד",
        IL: "ישראל",
        "IL(Arabic)": "ישראל",
        NO: "נורווגיה",
        SE: "שבדיה",
        US: "ארצות הברית",
        UK: "הממלכה המאוחדת",
        SA: "ערב הסעודית",
        SG: "סינגפור",
        SI: "סלובניה"
    },
    'nl':{
        DK: "Denemarken",
        NL: "Nederland",
        IL: "Israël",
        "IL(Arabic)": "Israël",
        NO: "Noorwegen",
        SE: "Zweden",
        US: "de Verenigde Staten van Amerika",
        UK: "Verenigd Koningkrijk",
        SA: "Saoedi-Arabië",
        SG: "Singapore",
        SI: "Slovenien"
    },
    'no':{
        DK: "Danmark",
        NL: "Nederland",
        IL: "Israel",
        "IL(Arabic)": "Israel",
        NO: "Norge",
        SE: "Sverige",
        US: "Amerikas forente stater",
        UK: "Storbritannia",
        SA: "Saudi-Arabia",
        SG: "Singapore",
        SI: "Slovenien"
    },
    'sv':{
        DK: "Danmark",
        NL: "Nederländerna",
        IL: "Israel",
        "IL(Arabic)": "Israel",
        NO: "Norge",
        SE: "Sverige",
        US: "Amerikas förenta stater",
        UK: "Storbritannien",
        SA: "Saudiarabien",
        SG: "Singapore",
        SI: "Slovenien"
    },
    "fr":{
        DK: "Danemark",
        NL: "Pays-Bas",
        IL: "Israël",
        "IL(Arabic)":"Israël",
        NO: "Norvège",
        SE: "Suède",
        US: "les états-unis d'Amérique",
        UK: "Royaume-Uni",
        SA: "Arabie Saoudite",
        SG: "Singapour",
        SI: "Slovénie"
    }
}

export const getLanguageNames = (locale: string|undefined, countryCode: string) => {
    if (locale && languageNames.hasOwnProperty(locale)){
        const localeNames = languageNames[locale];
        if(localeNames.hasOwnProperty(countryCode)){
            return localeNames[countryCode];
        }
    }

    return countryCode;
}