import React, {Component} from 'react';
import {Translate} from "react-localize-redux";
import {Loading} from "../../Loading";
import {FormButton} from "../FormGroup";
import OrganizationList from "../../Organization/OrganizationList";
import {Organization, TempOrganization} from "../../../model/Organization";
import * as OrgStore from '../../../store/OrganizationStore';
import ConfirmationForm from "../games/ConfirmationForm";
import FieldErrors from "../FieldErrors";
import {ErrorState} from "../../../store/Error";
import OrganizationChooser from './OrganizationChooser';

interface Props{
    organization: Organization;
    visibleOrganizations: Array<Organization|TempOrganization>;
    orgChildren: Organization[];
    loading?: boolean;
    orgActions: typeof OrgStore.actionCreators;
    orgErrors: ErrorState['organization'];
}

interface State{
    confirmRemove?: string;
    showOrgChoose?: boolean;
}


class OganizationChilds extends Component<Props, State>{

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render(){
        const props = this.props;
        const {showOrgChoose} = this.state;
        if(!props.organization.id) return null;
        const confirmRemove = this.state.confirmRemove;

        if (showOrgChoose) return (
            <OrganizationChooser
                onChoose={(o) => {
                    props.orgActions.showChooseOrganization(false);
                    props.orgActions.addChildOrg(props.organization.id, o.id);
                }}
                onClose={() => this.setState({showOrgChoose: false})}
                visible={showOrgChoose}
            />
        );


        return(
            <div>
                <h2><Translate id='organization_admin_for' /></h2>
                <Loading visible={props.loading} />
                <FormButton disabled={true} name={'organization_add'} onClick={() => this.setState({ showOrgChoose: true })} />
                <FieldErrors errors={props.orgErrors.addChildOrg} fieldname={'organizationId'}/>
                <OrganizationList
                    organizations={props.orgChildren}
                    orgEditClick={() => {}}
                />
                {confirmRemove &&
                    <ConfirmationForm
                        text={'organization_remove_confirm'}
                        confirmText={'yes'}
                        cancelText={'no'}
                        onConfirm={() => props.orgActions.removeChildOrg(props.organization.id, confirmRemove)}
                        onCancel={() => this.setState({confirmRemove: undefined})}
                    />
                }
            </div>
        )
    }
}

export default OganizationChilds;