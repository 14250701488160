import React, {Component, CSSProperties, ReactNode, MouseEvent} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as playlistStore from '../../store/PlayListStore';
import * as orgStore from '../../store/OrganizationStore';
import * as meStore from '../../store/Me';
import * as bundleStore from '../../store/GlobalComponentStore';
import {DbOrUploadImage} from "../../services/ImageHelper";
import missingImage from "../../images/games/missingimage220x150.png";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {AppState} from "../../store/configureStore";
import {PlayListType} from "../../model/PlayListType";
import moment from "moment";

interface Props{
    playListActions: typeof playlistStore.actionCreators;
    orgActions: typeof orgStore.actionCreators;
    playlistState: playlistStore.PlayListState;
    globalActions: typeof bundleStore.actionCreators;
    meState: meStore.MeState;
    orgState: orgStore.OrganizationState;
    playlistId?: string;
    forceNoWebRequest?: boolean;
    playlist?: PlayListType;
    onClick?: (playlistId: string) => void;
    onlyImage?: boolean;
    style?: CSSProperties;
    className?: string;
    noMenu?: boolean;
    customMenu?: ReactNode;
    width?: number;
}

interface State{
    menuVisible: boolean;
}

class PlayListPreview extends Component<Props, State>{

    constructor(props: Props) {
        super(props);
        this.state = {menuVisible: false};
    }

    componentDidMount(){
        const id = this.props.playlistId;
        if(id && !this.props.forceNoWebRequest){
            this.props.playListActions.getPlayList(id);
        }
    }

    render(){
        const props = this.props;
        if(!props.playlist && !props.playlistId) return false;
        const scale = props.width ? props.width / 220 : 1;
        const width = 220 * scale;
        const height = 150 * scale;

        const pl = props.playlist || (props.playlistId && props.playlistState.playListsMap[props.playlistId]);
        if (!pl) return false;
        const titleStyle = { fontSize: `${pl.fontSize}px`, fontFamily: pl.fontFamily, color: pl.fontColorHex};
        const labelStyle = { background: `linear-gradient(to bottom, ${pl.labelColorHex}DD, ${pl.labelColorHex}00` };

        const isNews = pl.isPublic && moment(pl.createdDateTime).isAfter(moment().add(-30, 'days')) && (pl.games?.length || 0) > 4;
        const isUpdated = !isNews && pl.isPublic && moment(pl.editedDateTime).isAfter(moment().add(-14, 'days')) && (pl.games?.length || 0) > 4;

        const onClick = (e: MouseEvent<HTMLElement>) => {
            if(props.onClick){
                e.stopPropagation();
                props.onClick(pl.id);
            }
            else if(!props.noMenu){
                this.setState({menuVisible: !this.state.menuVisible});
            }
        };

        const renderMenu = () => {
            const link = `/workshop/playlist/edit/${pl.id}`;
            return(
                <div className={`icon-menu${this.state.menuVisible ? '' : ' hidden'}`}>
                    {props.customMenu ? props.customMenu :
                        <div className='menu-container'>
                            <Link to={link}>
                                <div className='menu-point red'>
                                    <span>
                                        <Translate id='edit_content'/>
                                    </span>
                                    <FontAwesomeIcon icon='edit' className='fa-icon'/>
                                </div>
                            </Link>
                            {pl.isPublished &&
                                <div
                                    className='menu-point'
                                    onClick={() => props.globalActions.bundleAddItemForm(pl)}
                                >
                                    <Translate id='bundle_add_to' />
                                    <FontAwesomeIcon icon='plus' className='fa-icon' />
                                </div>
                            }
                            {pl.isPublished && 
                                <div className='menu-point' onClick={() => props.globalActions.wallAddItemForm(pl)}>
                                    <Translate id='wall_add_to' />
                                    <FontAwesomeIcon icon='plus' className='fa-icon' />
                                </div>
                            }
                        </div>
                    }
                </div>
            );
        };

        const renderIcon = () => {
            const style = {transform: `scale(${scale})`};
            return(
                <div className='icon-wrapper' style={scale !== 1 ? { width: `${width}px`, height: `${height}px` } : undefined}>
                    <div className={`icon`} style={style}>
                        {!!pl &&
                            <div className={`${pl.isPublished ? '' : 'draft'}`} style={{ backgroundColor: pl.backgroundColorHex }}>
                                <DbOrUploadImage className='pl-image' image={pl.image} tempImage={missingImage} realSize />
                                {pl.showLabel && <div className='playlist-icon-label' style={labelStyle} />}
                                {pl.showTitle && <div className='playlist-icon-title' style={titleStyle} dir='auto'>{pl.name}</div>}
                            </div>
                        }
                        {!props.noMenu && renderMenu()}
                        {!pl.isPublished &&
                            <div className='draft-label'>
                                <div className='draft-title'><Translate id='draft' /></div>
                                <div className='draft-text'><Translate id='draft_info' /></div>
                            </div>
                        }
                        {isNews && <div className='new-badge'><Translate id='new_badge' /></div>}
                        {isUpdated && <div className='update-badge'><Translate id='update_badge' /></div>}
                    </div>
                </div>
               
            );
        };

        const renderText = () => {
            return(
                <div className='pl-text-wrapper' style={{height: `${90 * scale}px`}}>
                    <div style={{transform: `scale(${scale})`}} className='pl-text' onClick={e => {e.stopPropagation(); this.setState({menuVisible: !this.state.menuVisible})}}>
                        <div className='pl-title' dir='auto'>{pl.name}</div>
                        <div className='pl-description' dir='auto'>{pl.description}</div>
                    </div>
                </div>
            )
        };

        return(
            <div
                className={`playlist-preview ${props.className || ''}${props.onClick || !props.noMenu ? ' clickable' : ''}`}
                onClick={onClick}
                style={scale !== 1 ? { width: `${width}px`} : undefined}
            >
                {renderIcon()}
                {!props.onlyImage && renderText()}
            </div>
        );
    }
}


export default connect(
    (state: AppState) => ({playlistState: state.playlist, meState: state[meStore.reducerName], orgState: state[orgStore.reducerName]}),
    dispatch => ({
        playListActions: bindActionCreators(playlistStore.actionCreators, dispatch),
        orgActions: bindActionCreators(orgStore.actionCreators, dispatch),
        globalActions: bindActionCreators(bundleStore.actionCreators, dispatch)
    })
)(PlayListPreview);