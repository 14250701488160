import React, {MouseEvent} from 'react';
import ninjaBackgroundImage from '../../../images/games/quizninja/background.png';
import ninjaClockImage from '../../../images/games/quizninja/ninja-clock.png';
import ninjaLessonImage from '../../../images/games/quizninja/ninjaLesson.png';
import ninjaPlayerImage from '../../../images/games/quizninja/ninjaPlayer.png';
import {DbOrUploadImage} from '../../../services/ImageHelper';
import {QnRound} from '../../../model/Game/QuizNinja/QnRound';
import {QnAnswer} from "../../../model/Game/QuizNinja/QnAnswer";

interface QuizNinjaRoundPreviewProps {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    round: QnRound;
    index: number;
}

export const QuizNinjaRoundPreview = (props: QuizNinjaRoundPreviewProps) => {
    const round = props.round;
    return(
        <div className='quizninja-preview-wrapper' onClick={props.onClick || undefined}>
            <div className='quizninja-round-preview' >
                <div className='quizninja-round-outer'>
                    <img className='background' src={ninjaBackgroundImage} alt=''/>
                    <div className='clock'>
                        <img src={ninjaClockImage} alt=''/>
                        <div className='time-text'>{round.time}</div>
                    </div>
                    <div className='quizninja-round-inner'>
                        <img className='ninja' src={ninjaLessonImage} alt=''/>
                        <div className='round-text'>
                            <div className='round-title' dir='auto'>{props.index}. {round.title}</div>
                            <div className='round-description' dir='auto'>{round.description}</div>
                        </div>
                    </div>
                    <div className='quizninja-round-question-preview' >
                        <img className='ninja' src={ninjaPlayerImage} alt=''/>
                        <div className='round-question'>
                            <div className='question-text' dir='auto'>{round.question}</div>
                        </div>
                        <DbOrUploadImage image={round.image} className='question-image'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface AnswerPreviewType {
    onClick?: () => void;
    answer: QnAnswer;
}

export const QuizNinjaAnswerPreview = (props: AnswerPreviewType) => {
    const answer = props.answer;
    const correctStyle = {borderColor: "rgb(35, 224, 110)"};
    return(
        <div className={`quizninja-answer-preview-wrapper ${props.onClick ? 'clickable' : ''}`} onClick={props.onClick || undefined}>
            <div className='quizninja-answer-preview' style={answer.correct ? correctStyle : {}}>
                <div className='text' style={{fontFamily: answer.font, color: answer.fontColorHex}} dir='auto'>{answer.text}</div>
                <DbOrUploadImage image={answer.image} alt='' className='image'/>
            </div>
        </div>
    );
};