import React, { useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { AccountType } from '../../model/AccountType';
import { Bundle } from '../../model/Bundle';
import { BaseGame } from '../../model/Game/BaseGame';
import { useFetchGet, useFetchPost } from '../../services/FetchHelper';
import { DictGetValues } from '../../services/JsDict';
import { AppState } from '../../store/configureStore';
import Dialog from '../Dialog';
import BundleIcon from '../ModelPreview/BundleIcon';
import GameDetailed from '../ModelPreview/GameDetailed';
import ItemContainer from '../ModelPreview/ItemContainer';
import * as BundleStore from '../../store/GlobalComponentStore';
import { bindActionCreators } from 'redux';
import { PlayListType } from '../../model/PlayListType';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import { FormGeneralError } from '../Error/FormGeneralError';
import { isBaseGame, isPlaylistType } from '../../model/SportsWall/Wall';

interface Props{
    item?: BaseGame | PlayListType;
    me: AccountType | null;
    bundleActions: typeof BundleStore.actionCreators;
}

const BundleAddItem = (props: Props) => {
    const {item, me, bundleActions} = props;
    const [bundles, setBundles] = useState<Bundle[]>([]);
    const [postItem, isLoading, error] = useFetchPost<Bundle>(() => bundleActions.bundleAddItemForm(undefined));
    const [getBundles, loadingBundles, errorBundles] = useFetchGet<{[key: string]: Bundle}>(r => setBundles(DictGetValues(r)))
  
    useEffect(() => {
        if (item){
            getBundles('api/bundle');
        }
    }, [item, getBundles]);

    //Shortciruit react element
    if (!item || !me ) return null;


    const onClick = (b: Bundle) => {
        if (isBaseGame(item)) postItem(`api/bundle/${b.id}/game/${item.id}`);
        else if (isPlaylistType(item)) postItem(`api/bundle/${b.id}/playlist/${item.id}`);
    }
     
    const onClose = () => {
        bundleActions.bundleAddItemForm(undefined);
    }

    return(
        <Dialog onClose={onClose} loading={isLoading} >
            <h1>
                {isBaseGame(item) && <Translate id='bundle_add_game_dialog' />}
                {isPlaylistType(item) && <Translate id='bundle_add_playlist_dialog' />}
            </h1>
            <FormGeneralError fetchError={error} />
            <div>
                {isBaseGame(item) && <GameDetailed game={item} gameType={item.gameType} hideTranslations />}
                {isPlaylistType(item) && <PlayListPreview playlist={item} noMenu />}
            </div>
           
            <ItemContainer
                heading={<h1><Translate id='bundles_my_bundles' /></h1>}
                items={bundles.filter(b => b.ownerId === me.id)}
                loading={loadingBundles}
                error={errorBundles}
                itemRender={b => <BundleIcon key={b.id} bundle={b} onClick={() => onClick(b)}/>}
            />
            <ItemContainer
                heading={<h1><Translate id='bundles_author_bundles' /></h1>}
                items={bundles.filter(b => b.authorIds && b.authorIds.find(x => x === me.id))}
                loading={loadingBundles}
                error={errorBundles}
                itemRender={b => <BundleIcon key={b.id} bundle={b} onClick={() => onClick(b)} />}
            />
        </Dialog>
    )

};

export default connect(
    (state: AppState) => ({activeToken: state.auth.activetoken, me: state.me.account, item: state.globalComponentState.showBundleAddItem}),
    (dispatch) => ({bundleActions: bindActionCreators(BundleStore.actionCreators, dispatch)})
)(BundleAddItem)