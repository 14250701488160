import React, { useEffect } from 'react';
import {LayoutComponentProps} from "../Layout";
import {AuthState} from "../../store/Auth";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import FeaturedGameAdmin from "./FeaturedGameAdmin";
import SmartAddAdmin from './SmartAddAdmin';
import TopMenuButton from '../Menu/TopMenuButton';

interface Props extends LayoutComponentProps{
    authToken: AuthState['activetoken'];
}

const AdminPage = (props: Props) => {

    const setButtons = props.setLayoutButtons;
    const authToken = props.authToken;

    useEffect(() => {

        const getCsv = () => {
            fetch('/api/account/csv/export', {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Accept': 'application/json, text/plain, */*'
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "user_export.csv";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                });
        }

        const getCsvGame = () => {
            fetch('/api/game/csv/export', {
                method: 'get',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Accept': 'application/json, text/plain, */*'
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const a = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "game_export.csv";
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                });
        }

        setButtons((
            <>
                <TopMenuButton action={getCsv} icon={'file-csv'} hoverMsg={'csv_users_download'} />
                <TopMenuButton action={getCsvGame} icon={'file-csv'} hoverMsg={'csv_games_download'} />
            </>
        ));

        return () => setButtons(undefined);
    }, [setButtons, authToken]);

    return(
        <div className='admin'>
            <FeaturedGameAdmin />
            <SmartAddAdmin />
        </div>
    )
}

export default connect(
    (state: AppState) => ({authToken: state.auth.activetoken}),
)(AdminPage);