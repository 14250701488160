import React from 'react';
import {
    AudioFormButton,
    CheckBox,
    ColorAndFont,
    ImageFormButton,
    InputField,
    SelectList,
    Slider,
    SubmitButton,
    TextAlignmentFormGroup
} from "../../forms/FormGroup";
import {connect} from "react-redux";
import {
    actionCreators as ditjActions,
    DangerInTheJungleState,
    ditjTransformQuizToStyle
} from "../../../store/games/DangerInTheJungle";
import {bindActionCreators} from "redux";
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {QuizPreview} from "./QuizPreview";
import ConfirmationForm from "../../forms/games/ConfirmationForm";
import {AppState} from "../../../store/configureStore";
import {DitjAnswer} from "../../../model/Game/DangerInTheJungle/DitjAnswer";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {TextAlignment} from "../../../model/Game/TextType";
import {ErrorState} from "../../../store/Error";

const QUESTION = 'question';
const ANSWER1 = 'answer1';
const ANSWER2 = 'answer2';
const ANSWER3 = 'answer3';

interface QuestionProps {
    ditj: DangerInTheJungleState;
    errors: ErrorState['ditj'];
    actions: typeof ditjActions;
    categories?: string[];
}

const Question = (props: QuestionProps) => {
    const isLoading = props.ditj.isLoading;
    const ui = props.ditj.ui;

    const quiz = props.ditj.quiz;
    const q = quiz.question;
    const a1 = quiz.answer1;
    const a2 = quiz.answer2;
    const a3 = quiz.answer3;
    
    let validForm = true;
    //must have category
    if (!quiz.category){
        validForm = false;
    } 
    //must have at least one
    if (!q.text.content && !q.image && !q.sound){
        validForm = false;
    }
    //at least one
    if (!a1.text.content && !a1.image){
        validForm = false;
    }
    if (!a2.text.content && !a2.image){
        validForm = false;
    }
    if (!a3.text.content && !a3.image){
        validForm = false;
    }
    //at least one correct
    if(!a1.correct && !a2.correct && !a3.correct){
        validForm = false;
    }
    
    const submitFunc = () => {
        if (quiz.newQuiz){
            props.actions.createQuiz(quiz)
        } 
        else{
            props.actions.updateQuiz(quiz, quiz.index);
        }
        
    };

    const confirmDelete = <ConfirmationForm 
        text={"ditj_quiz_delete_confirm"} 
        confirmText={"yes"} 
        cancelText={"no"} 
        onConfirm={() => props.actions.deleteQuiz(quiz.index)}
        onCancel={() => props.actions.showConfirmDelete(false)}/>;


    const mainForm = (
        <Dialog 
            className='ditj-dialog' 
            onClose={() => props.actions.toggleQuestionForm(false)} 
            loading={isLoading.createQuiz || isLoading.updateQuiz || isLoading.deleteQuiz}
            icon={quiz.newQuiz ? undefined : 'trash'}
            onIconClick={() => props.actions.showConfirmDelete(true)}
            icons={[
                {icon: 'eye-dropper', onClick: () => props.actions.saveStyle(), hoverMsg: <div className='min-width'><Translate id='save_style' /></div>},
                {icon: 'fill-drip', onClick: () => props.actions.applyStyle(),
                    hoverMsg: (
                        <div>
                            <div><Translate id='apply_style'/></div>
                            <QuizPreview
                                quiz={ditjTransformQuizToStyle(quiz, props.ditj.style)}
                            />
                        </div>
                    )
                }
            ]}
        >
            {ui.showConfirmDeleteQuiz && confirmDelete}
            <h1><Translate id='ditj_question' /></h1>
            <form className='ditj-form' onSubmit={(e) => e.preventDefault()}>
                <div className='ditj-question'>
                    <SelectList
                        name='category'
                        newText='ditj_new_category'
                        maxOptions={5}
                        defaultValue={quiz.category}
                        options={props.categories ? props.categories.map(x => {return {name: x, value: x}}) : []}
                        newFunc={() => props.actions.questionShowNewCategory(true)}
                        onChange={(event) => {props.actions.questionShowNewCategory(false);props.actions.questionSetCategory(event);}}
                        canAddNew={true}
                        noLabel
                        original={quiz._o.category}
                    />
                    {quiz.newCategory &&
                        <InputField
                            name='category'
                            type='text'
                            value={quiz.category}
                            onChange={props.actions.questionSetCategory}
                            placeholder='ditj_category'
                            noLabel
                        />
                    }
                    <ImageFormButton
                        name={'image'}
                        imageSubmit={(image) => {props.actions.questionSetImage(QUESTION, image)}}
                        previewWidth={100}
                        previewHeight={100}
                        unsavedChange={quiz.question.image !== quiz._o.question.image}
                    />
                    <AudioFormButton
                        name={'sound'}
                        audioSubmit={props.actions.setSound}
                        unsavedChange={quiz.question.sound !== quiz._o.question.sound}
                    />
                    <InputField
                        name='content'
                        type='text'
                        value={q.text.content || ''}
                        onChange={(e) => props.actions.questionTextChange(e, QUESTION)}
                        placeholder='text'
                        noLabel
                        tabIndex={1}
                        original={quiz._o.question.text.content}
                    />
                    <Slider
                        min={10}
                        max={50}
                        name='fontSize'
                        value={q.text.fontSize}
                        onChange={e => props.actions.questionTextChange(e, QUESTION)}
                        original={quiz._o.question.text.fontSize}
                    />
                    <ColorAndFont
                        colorValue={q.text.fontColorHex}
                        onColorChange={e => props.actions.questionTextChange(e, QUESTION)}
                        fontFamilyValue={q.text.fontFamily}
                        onFontFamilyChange={e => props.actions.questionTextChange(e, QUESTION)}
                        originalFontFamily={quiz._o.question.text.fontFamily}
                        originalColor={quiz._o.question.text.fontColorHex}
                    />
                    <TextAlignmentFormGroup
                        name='textAlignment'
                        value={q.text.textAlignment || TextAlignment.Center}
                        onChange={e => props.actions.questionTextChange(e, QUESTION)}
                        original={quiz._o.question.text.textAlignment || TextAlignment.Center}
                    />
                </div>
                <h2><Translate id='ditj_answers'/></h2>
                <div className='ditj-answers'>
                    <Answer index={ANSWER1} model={quiz.answer1} _o={quiz._o.answer1} actions={props.actions} tabIndex={2} />
                    <Answer index={ANSWER2} model={quiz.answer2} _o={quiz._o.answer2} actions={props.actions} tabIndex={3} />
                    <Answer index={ANSWER3} model={quiz.answer3} _o={quiz._o.answer3} actions={props.actions} tabIndex={4} />
                    <div className='clear-fix' />
                </div>

                <FormGeneralError error={props.errors.createQuiz}/>
                <FormGeneralError error={props.errors.updateQuiz}/>
                <FormGeneralError error={props.errors.deleteQuiz}/>

                <div className='ditj-question-form-preview'>
                    <QuizPreview quiz={quiz}/>
                </div>

                <SubmitButton
                    text={'submit'}
                    onClick={submitFunc}
                    split
                    disabled={!validForm}
                />
            </form>
        </Dialog>
    );
    
    let content = null;

    if (ui.showQuizForm) {
        content = mainForm;
    }
    else{
        return null;
    }
    
    return(
        content
    );
};

export default connect(
    (state: AppState) => ({ditj: state.ditj, errors: state.errors.ditj}),
    dispatch => ({ actions: bindActionCreators(ditjActions, dispatch) })
)(Question)

interface AnswerProps {
    model: DitjAnswer;
    _o: DitjAnswer;
    index: 'answer1' | 'answer2' | 'answer3';
    actions: typeof ditjActions;
    tabIndex: number;
}

const Answer = (props: AnswerProps) => {
    return(
        <div className='answer'>
            <ImageFormButton
                name={'image'}
                imageSubmit={(image) => props.actions.questionSetImage(props.index, image)}
                previewWidth={100}
                previewHeight={100}
                unsavedChange={props.model.image !== props._o.image}
            />
            <InputField
                name='content'
                type='text'
                value={props.model.text.content || ''}
                onChange={(e) => props.actions.questionTextChange(e, props.index)}
                placeholder='text'
                noLabel
                tabIndex={props.tabIndex}
                original={props._o.text.content}
            />
            <Slider
                min={10}
                max={50}
                name='fontSize'
                value={props.model.text.fontSize}
                onChange={(e) => props.actions.questionTextChange(e, props.index)}
                original={props._o.text.fontSize}
            />
            <ColorAndFont
                colorValue={props.model.text.fontColorHex}
                onColorChange={(e) => props.actions.questionTextChange(e, props.index)}
                fontFamilyValue={props.model.text.fontFamily}
                onFontFamilyChange={(e) => props.actions.questionTextChange(e, props.index)}
                originalColor={props._o.text.fontColorHex}
                originalFontFamily={props._o.text.fontFamily}
            />
            <TextAlignmentFormGroup
                name='textAlignment'
                value={props.model.text.textAlignment || TextAlignment.Center}
                onChange={e => props.actions.questionTextChange(e, props.index)}
                original={props._o.text.textAlignment  || TextAlignment.Center}
            />
            <CheckBox
                name='correct'
                active={props.model.correct}
                onChange={e => props.actions.changeCorrect(e,  props.index)}
                original={props._o.correct}
            />
        </div>
    )
};