import React, { useEffect } from 'react';
import * as MyGamesStore from "../../../store/MyGames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import { AppState } from "../../../store/configureStore";
import { ErrorState } from "../../../store/Error";
import { SecureLayoutComponentProps } from "../../Layout";
import { accountHasAnyPermission } from '../../../model/AccountType';
import { useFetchPost } from '../../../services/FetchHelper';
import { history } from '../../..';
import { Loading } from '../../Loading';
import { FormGeneralError } from '../../Error/FormGeneralError';
import GoalShowSettings from './GoalShowSettings';
import { GoalShowGame } from '../../../model/Game/GoalShow/GoalShowGame';
import * as GoalShowStore from "../../../store/games/GoalShowStore";

interface Props extends SecureLayoutComponentProps<{ id?: string }> {
    gameActions: typeof GoalShowStore.actionCreators;
    errorState: ErrorState['goalShowState'];
    gameState: GoalShowStore.GoalShowState;
    myGamesActions: typeof MyGamesStore.actionCreators;
}

const GoalShow = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, me, setLayoutButtons } = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])

    const [create, loadingCreate, errorCreate] = useFetchPost<GoalShowGame>(g => history.push(`/workshop/${g.gameType}/edit/${g.id}`));

    if (errorState.getGame) return <div><Translate id='unknown_error' /></div>;
    const game = gameState.game;

    return (
        <EditGameComponent
            gameState={gameState}
            routeMatch={match}
            gameType={'GoalShow'}
            setLayoutButtons={setLayoutButtons}
            extraButtons={[
                accountHasAnyPermission(me, ["Admin"]) && !routeId
                    ?
                    { id: 'create', icon: 'plus', hoverMsg: 'goal_show_create', action: () => create(`/api/GoalShow/create`) }
                    :
                    null
            ]}
        >
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='goal_show_text' options={{ renderInnerHtml: true, renderToStaticMarkup: false }} /></p>
                    </div>
                }
                tempTitle={<Translate id='goal_show_title' />}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
            <Loading visible={loadingCreate} />
            <FormGeneralError error={errorCreate} />
            <GoalShowSettings />
        </EditGameComponent>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[GoalShowStore.reducerName],
        errorState: state.errors[GoalShowStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(GoalShowStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(GoalShow)