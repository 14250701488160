import React, { ReactNode, useRef } from 'react';

import {DragObjectWithType, useDrag, useDrop} from "react-dnd";

interface Props extends DragObjectWithType {
    id: string;
    onSort: (keySource: string, keyTarget: string) => void;
    onSortCommit: () => void;
    children: ReactNode | ReactNode[];
    className?: string;
}

const Sortable = (props: Props) => {
    const {id} = props;
    const ref = useRef<HTMLDivElement>(null);
    
    const [, drag] = useDrag({
        item: props,
        end: props.onSortCommit
    });

    const [,drop] = useDrop({
        accept: props.type,
        hover: (item: Props) => {
            if (item.id === id) return;
            props.onSort(item.id, id);
        }
    });

    drag(drop(ref));

    return(
        <div ref={ref} className={`sortable ${props.className || ''}`}>
            {props.children}
        </div>
    )
}

export default Sortable;