import React from 'react';
import PropTypes from 'prop-types';
import {Organization} from "../../model/Organization";

interface Props{
    org: Organization;
    onClick?: () => void;
}

const OrganizationPreview = (props: Props) => {
    const org = props.org;
    return(
        <div className='org-preview' onClick={props.onClick ? props.onClick : undefined}>
            <div className='systemname'>{org.systemName}</div>
            <div className='country'>{org.country}</div>
            <div className='type'>{org.type}</div>
            <div className='title'>{org.name}</div>
            <div className='contact'>
                <div className='name'>{org.contactName}</div>
                <div className='email'>{org.contactEmail}</div>
                <div className='phone'>{org.contactPhone}</div>
            </div>
        </div>
    );
};

OrganizationPreview.propTypes = {
    org: PropTypes.object.isRequired,
    onClick: PropTypes.func
};
export default OrganizationPreview;