import React from 'react';
import Dialog from "../../Dialog";
import {connect} from "react-redux";
import * as GamesStore from "../../../store/games/Games";
import * as DitjStore from '../../../store/games/DangerInTheJungle';
import {bindActionCreators} from "redux";
import {CheckBox, SelectList, SubmitButton} from "../../forms/FormGroup";
import {Translate} from "react-localize-redux";
import {AppState} from "../../../store/configureStore";

interface Props {
    ditjState: DitjStore.DangerInTheJungleState;
    gamesActions: typeof GamesStore.actionCreators;
    ditjActions: typeof DitjStore.actionCreators;
}

const DITJSettings = (props: Props) => {
    if(!props.ditjState.ui.showSettings) return null;
    const game = props.ditjState.game;
    if(!game) return null;
    const settings = {
        skin: game.skin || 'Default',
        turnBased: game.turnBased

    };

    const skinOptions = [
        {name:"Default", value:"Default"},
        {name:"TheSwamp", value:"TheSwamp"},
        {name: "StepWize", value: "StepWize"},
        {name: "Desert", value: "Desert"}
    ];

    return(
        <Dialog className='settings' onClose={() => {props.gamesActions.showSettings(game.gameType, false)}} loading={props.ditjState.isLoading.updateSettings} >
            <h2><Translate id='settings'/></h2>
            <form onSubmit={e => e.preventDefault()}>
                <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.ditjActions.setField}/>
                <CheckBox name='turnBased' active={settings.turnBased} onChange={props.ditjActions.setField} label='ditj_turn_based' notLabel='ditj_not_turn_based'/>
                <h3><Translate id='preview_skin'/></h3>
                <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
                <div className='clear-fix' />
                <SubmitButton
                    text='submit'
                    onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ ditjState: state.ditj }),
    dispatch => ({
        ditjActions: bindActionCreators(DitjStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(DITJSettings);