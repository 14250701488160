import React, {Component, CSSProperties} from 'react';
import {DbOrUploadImage} from "../../services/ImageHelper";
import {Loading} from "../Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import missingProfileImage from "../../images/missing-profile.png";
import {connect} from "react-redux";
import * as AccountStore from "../../store/Account";
import {bindActionCreators} from "redux";
import {AccountState} from "../../store/Account";
import {AccountType, getFullName, isTempAccount} from "../../model/AccountType";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {AppState} from "../../store/configureStore";

interface Props{
    accountState: AccountState;
    accountActions: typeof AccountStore.actionCreators;
    account?: AccountType;
    accountId?: string;
    onClick?: () => void;
    icon?: IconProp;
    hideName?: boolean;
    className?: string;
    style?: CSSProperties;
    fullImage?: boolean;
    onlyName?: boolean;
    small?: boolean;
    hoverName?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

class Author extends Component<Props>{

    componentDidMount(){
        if(!this.props.account && this.props.accountId){
            this.props.accountActions.get(this.props.accountId, false);
        }
    }

    render(){
        const props = this.props;
        const author = props.account || (props.accountId && props.accountState.accounts[props.accountId]);
        if(!author) return null;
        if(props.onlyName) return <p className='author-name'>{getFullName(author)}</p>;
        return(
            <div
                className={`author-preview hover-trigger${props.small ? ' small' : ''}${props.onClick ? ' clickable' : ''} ${props.className || ''}`}
                style={props.style}
                onClick={props.onClick ? props.onClick : undefined}
                onMouseLeave={props.onMouseLeave ? props.onMouseLeave : undefined}
                onMouseEnter={props.onMouseEnter ? props.onMouseEnter : undefined}
            >
                <Loading visible={isTempAccount(author)} noBackground/>
                {
                    !isTempAccount(author) && !author.profileImage && author.firstname ?
                        <div className='initials-container'>
                            <div className='initials-image'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                    <text x="250" y="350" textAnchor='middle' >{author.firstname[0].toUpperCase()}{author.lastname && author.lastname[0].toUpperCase()}</text>
                                </svg>
                            </div>
                        </div>
                         :
                        <DbOrUploadImage
                            image={!isTempAccount(author) ? author.profileImage : null}
                            tempImage={missingProfileImage}
                            realSize={props.fullImage}
                        />
                }

                {props.icon && <FontAwesomeIcon icon='trash' className='icon' />}
                {!props.hideName && <div className='author-name' dir='auto'>{getFullName(author)}</div> }
                {props.hoverName && <div className='hover-msg' dir='auto'>{getFullName(author)}</div>}
            </div>
        );
    }
}

export default connect(
    (state: AppState) => ( {
        accountState: state.account,
    } ),
    dispatch => ({
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch)
    })
)(Author)