import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PlaylistStore from '../../store/PlayListStore';
import * as GameStore from '../../store/games/Games';
import * as GlobalStore from '../../store/GlobalComponentStore';
import {isTempGame} from '../../store/games/Games';
import * as AccountStore from '../../store/Account';
import {SecureLayoutComponentProps} from "../Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate} from "react-localize-redux";
import {Loading} from "../Loading";
import Game from "../ModelPreview/Game";
import PlayListForm from "../forms/PlayListForm";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import Author from "../ModelPreview/Author";
import {ConfirmationForm} from '../forms/games/ConfirmationForm';
import AuthorChooser from "../forms/AuthorChooser";
import {MeState} from "../../store/Me";
import {AppState} from "../../store/configureStore";
import GameMenuPlaylist from "../ModelPreview/GameMenuPlaylist";
import TransferOwner from "../forms/TransferOwner";
import Sortable from "../DragnDrop";
import {accountIsAdmin, accountIsAuthor, accountIsOwner} from "../../model/AccountType";
import {ErrorState} from "../../store/Error";
import ItemContainer from '../ModelPreview/ItemContainer';
import TopMenuButton from '../Menu/TopMenuButton';
import moment from 'moment';

interface Props extends SecureLayoutComponentProps<{ id: string }>{
    playlistActions: typeof PlaylistStore.actionCreators;
    accountActions: typeof AccountStore.actionCreators;
    globalActions: typeof GlobalStore.actionCreators;
    playlistState: PlaylistStore.PlayListState;
    gamesState: GameStore.GamesState;
    accountState: AccountStore.AccountState;
    meState: MeState;
    errors: ErrorState['playlist'];
}


const PlayListEdit = (props: Props) => {
    const { match, me, setLayoutButtons, playlistActions, playlistState, globalActions} = props;
    const playlistId = match.params.id;
    const playlist = playlistState.playListsMap[playlistId];
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        playlistActions.reset();
        playlistActions.showEdit(false);
        if (playlistId) {
            playlistActions.getPlayList(playlistId);
        }
    },[playlistId, playlistActions]);

    useEffect(() => {
        const isOwner = accountIsOwner(me, playlist);
        const isAuthor = accountIsAuthor(me, playlist);
        const isAdmin = accountIsAdmin(me);

        setLayoutButtons(
            <div>
                {(isAdmin || isOwner || isAuthor) &&
                    <button className='btn hover-trigger' onClick={() => setShowEdit(true)} disabled={!playlist}>
                        <div className='hover-msg'><Translate id='playlist_edit' /></div>
                        <FontAwesomeIcon icon='edit' />
                    </button>
                }
                {(isAdmin || isOwner || isAuthor) &&
                    <button className='btn hover-trigger' disabled={!playlist} onClick={() => playlistActions.showAuthorForm(true)}>
                        <div className='hover-msg'><Translate id='playlist_authors' /></div>
                        <FontAwesomeIcon icon='users' />
                    </button>
                }
                {(isAdmin || isOwner || isAuthor) &&
                    <button className='btn hover-trigger' disabled={!playlist} onClick={() => playlistActions.showTransferOwner(true)}>
                        <div className='hover-msg'><Translate id='transfer_owner' /></div>
                        <FontAwesomeIcon icon='user' />
                    </button>
                }
                <TopMenuButton icon='plus-circle' action={() => globalActions.bundleAddItemForm(playlist)} hoverMsg='bundle_add_to' disabled={!playlist} />
                <TopMenuButton icon='folder-plus' action={() => globalActions.wallAddItemForm(playlist)} hoverMsg='wall_add_to' disabled={!playlist} />
            </div>
        );

        return () => setLayoutButtons(undefined);
    }, [setLayoutButtons, me, playlist, globalActions, playlistActions]);

    const isOwner = accountIsOwner(me, playlist);
    const isAuthor = accountIsAuthor(me, playlist);
    const isAdmin = accountIsAdmin(me);

    if(!me) return null;

    const confirmRemove = <ConfirmationForm
        text={"playlist_remove_game_confirm"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => props.playlistActions.removeGame(playlist.id, props.playlistState.ui.showRemoveGame)}
        onCancel={() => props.playlistActions.showRemoveGameConfirm(false)}
    />;

    return(
        <>
            <div className='playlist-content'>
                <Loading visible={!playlist || props.playlistState.loading.removeGame} />
                {playlist &&
                <div>
                    <div className='playlist-info'>
                        <div>
                            <PlayListPreview playlist={playlist} onlyImage noMenu onClick={() => setShowEdit(true)}/>
                            <div className='edit-time' dir='auto'><label><Translate id='editedDateTime' /></label> {moment(playlist.editedDateTime).format('YYYY-MM-DD')}</div>
                            <h1 className='playlist-title'>{playlist.name}</h1>
                            <p>{playlist.description}</p>
                        </div>
                        <div>
                            <div className='authors'>
                                <h2><Translate id='playlist_authors'/></h2>
                                <Author accountId={playlist.ownerId} className='owner-container' />
                                {playlist.authorIds && playlist.authorIds.map((x,i) =>
                                    <Author key={i} accountId={x} className='author-img-container' hideName/>
                                )}
                            </div>
                        </div>
                        <div className='clear-fix'/>
                    </div>
                    <ItemContainer
                        heading={
                            <>
                                <h1><Translate id='playlist_games' /></h1>
                                <p><Translate id='drag_and_drop' /></p>
                            </>
                        }
                        loading={props.playlistState.loading.getGames || props.playlistState.loading.updateSort === playlist.id}
                        items={playlist.games || []}
                        itemRender={(sortedItem) => {
                            const game = props.gamesState.games[sortedItem.itemId];
                            return (
                                <Sortable
                                    type='sortable'
                                    key={sortedItem.itemId}
                                    id={sortedItem.itemId}
                                    onSort={(s, t) => props.playlistActions.updateSort(playlist.id, s, t)}
                                    onSortCommit={() => props.playlistActions.updateSortCommit(playlist.id)}
                                >
                                    <Game
                                        gameId={sortedItem.itemId}
                                        customMenu={
                                            game && !isTempGame(game)
                                                ? <GameMenuPlaylist isAuthor={isAuthor || isAdmin || isOwner} game={game} />
                                                : undefined
                                        }
                                    />
                                </Sortable>
                            )
                        }}
                    />
                </div>
                }
            </div>
            {showEdit && 
                <PlayListForm 
                    me={me} 
                    onClose={() => setShowEdit(false)} 
                    playlist={playlist} 
                    onSuccess={props.playlistActions._updatePlaylist}
                /> 
            }
            {props.playlistState.ui.showRemoveGame && confirmRemove}

            {playlist &&
                <AuthorChooser
                    chooseFunction={(authorId) => { props.playlistActions.addAuthor(playlist.id, authorId)}}
                    loading={props.playlistState.loading.addAuthor || props.playlistState.loading.removeAuthor}
                    visible={props.playlistState.ui.showAuthorForm}
                    onClose={() => props.playlistActions.showAuthorForm(false)}
                    chooseDelete={(authorId) => { props.playlistActions.removeAuthor(playlist.id, authorId)}}
                    ownerId={playlist.ownerId}
                    authorIds={playlist.authorIds}
                />
            }

            {playlist &&
                <TransferOwner
                    chooseFunction={(authorId) => {
                        props.playlistActions.transferOwner(playlist.id, authorId);
                    }}
                    loading={props.playlistState.loading.transferOwner}
                    visible={props.playlistState.ui.showTransferOwner}
                    onClose={() => props.playlistActions.showTransferOwner(false)}
                    ownerId={playlist.ownerId}
                />
            }
        </>
    )
}

export default connect(
    (state: AppState) => ( {
        playlistState: state[PlaylistStore.reducerName],
        meState: state.me,
        accountState: state.account,
        errors: state.errors[PlaylistStore.reducerName],
        gamesState: state[GameStore.reducerName]
    } ),
    dispatch => ({
        playlistActions: bindActionCreators(PlaylistStore.actionCreators, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch),
        globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
    })
)(PlayListEdit);

