import React from 'react';
import {Translate} from 'react-localize-redux';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    actionCreators as memoryActions,
    CardUi,
    MemoryState,
    memoryTransformQuizToStyle
} from "../../../store/games/Memory";
import Dialog from "../../Dialog";
import {FormGeneralError} from '../../Error/FormGeneralError';
import {
    AudioFormButton,
    CheckBox,
    ColorAndFont,
    ImageFormButton,
    InputField,
    Slider,
    SubmitButton,
    TextAlignmentFormGroup
} from '../../forms/FormGroup';
import SoundForm from '../../forms/games/SoundForm';
import {AppState} from "../../../store/configureStore";
import {TextAlignment} from "../../../model/Game/TextType";
import {CardPreview} from "./CardPreview";
import {ErrorState} from "../../../store/Error";

interface Props {
    memory: MemoryState;
    memoryActions: typeof memoryActions;
    errors: ErrorState['memory'];
}

const PairForm = (props: Props) => {
    if(!props.memory.ui.showNewPairForm) return null;


    const renderSoundForm = (cardLetter: 'a'|'b', p: Props) => {
        return (
            <Dialog onClose={() => p.memoryActions.showSoundForm(cardLetter, false)}>
                <SoundForm onSubmit={(sound) =>  p.memoryActions.setSound(cardLetter, sound)}/>
            </Dialog>
        );
    };

    if ( props.memory.ui.a.showSoundForm) return renderSoundForm('a', props);
    if ( props.memory.ui.b.showSoundForm) return renderSoundForm('b', props);
    
    const renderCardForm = (ui: CardUi, cardLetter: 'a'|'b', p: Props, tabIndex: number) => {
        const card = pair[cardLetter];
        const _oCard = pair._o[cardLetter];

        const colorAndFont = (
            <ColorAndFont
                colorValue={card.text.fontColorHex}
                onColorChange={(e) => p.memoryActions.setFontColor(cardLetter, e.target.value)}
                fontFamilyValue={card.text.fontFamily}
                onFontFamilyChange={(e) => p.memoryActions.setFont(cardLetter, e.target.value)}
                colorPickerClass={cardLetter}
                colorPickerToggleClass={cardLetter}
                fontFamilyPickerClass={cardLetter}
                originalColor={_oCard.text.fontColorHex}
                originalFontFamily={_oCard.text.fontFamily}
            />
        );

        return(
            <div className={`pair`}>
                <h2><Translate id='memory_card'/></h2>
                <ImageFormButton
                    name={'image'}
                    imageSubmit={(image) => p.memoryActions.setImage(cardLetter, image)}
                    previewWidth={400}
                    previewHeight={400}
                    unsavedChange={_oCard.image !== card.image}
                />
                <AudioFormButton
                    audioSubmit={(sound) =>  p.memoryActions.setSound(cardLetter, sound)}
                    name={'sound'}
                    unsavedChange={_oCard.sound !== card.sound}
                />
                <InputField
                    name='text'
                    type='text'
                    value={card.text.content}
                    onChange={(event) => p.memoryActions.setText(cardLetter, event.target.value)}
                    placeholder='text'
                    tabIndex={tabIndex}
                    original={_oCard.text.content}
                    noLabel
                />
                <Slider min={5}
                    max={60}
                    value={card.text.fontSize}
                    name='fontsize'
                    onChange={(event) => p.memoryActions.setFontSize(cardLetter, event.target.value)}
                    original={_oCard.text.fontSize}
                />
                {colorAndFont}
                <TextAlignmentFormGroup
                    name={'textAlignment'}
                    value={card.text.textAlignment || TextAlignment.Center}
                    onChange={(event) => p.memoryActions.setTextAlignment(cardLetter, event.target.value)}
                    original={_oCard.text.textAlignment}
                />
                {cardLetter === 'a' &&
                    <CheckBox
                        active={pair.identicalCards}
                        name='identicalCards'
                        onChange={e => p.memoryActions.setIdentical(e.target.value)}
                        original={pair._o.identicalCards}
                    />
                }
            </div>
        );
    };

    const pair = props.memory.pair;
    const a = props.memory.pair.a;
    const b = props.memory.pair.b;
    const aUi = props.memory.ui.a;
    const bUi = props.memory.ui.b;
    const aFilled = (a.image || a.sound || a.text.content);
    const bFilled = (b.image || b.sound || b.text.content);
    const filledCorrectly = aFilled && (pair.identicalCards || bFilled);

    return(
        <Dialog
            loading={props.memory.isLoading.editPair || props.memory.isLoading.createPair || props.memory.isLoading.deletePair}
            onClose={ () => props.memoryActions.showPairForm(false) }
            className='pair-dialog'
            icon={'trash'}
            onIconClick={pair.index !== -1 ? () => props.memoryActions.deletePair(pair.index) : undefined}
            icons={[
                {icon: 'eye-dropper', onClick: () => props.memoryActions.saveStyle(), hoverMsg: <div className='min-width'><Translate id='save_style' /></div>},
                {icon: 'fill-drip', onClick: () => props.memoryActions.applyStyle(),
                    hoverMsg: (
                        <div>
                            <div><Translate id='apply_style'/></div>
                            <div className='style-preview'>
                                <CardPreview
                                    card={memoryTransformQuizToStyle(pair, props.memory.style).a}
                                />
                                <CardPreview
                                    card={memoryTransformQuizToStyle(pair, props.memory.style).b}
                                />
                            </div>

                        </div>
                    )
                }
            ]}
        >
            <div>
                <h2><Translate id='memory_create_pair_title'/></h2>
            </div>
            <div className='memory-pair-form'>
                <div className='form'>
                    {renderCardForm(aUi, 'a', props, 1)}
                    {pair.identicalCards ? (
                        <div className='pair' />
                    ) : (
                        renderCardForm(bUi, 'b', props, 2)
                    )}
                    <div className='clear-fix'/>
                </div>
                <div><h2><Translate id='preview'/></h2></div>
                <div className='card-previews-container'>
                    <CardPreview card={a} />

                    { !pair.identicalCards ? (
                        <CardPreview card={b} />
                    ) : (
                        <CardPreview card={a} />
                    )}
                </div>

                <FormGeneralError error={props.errors.createPair}/>
                <FormGeneralError error={props.errors.editPair}/>

                <SubmitButton
                    text={'submit'}
                    onClick={pair.new ? () => props.memoryActions.createPair(pair) : () => props.memoryActions.editPair(pair)}
                    disabled={!filledCorrectly}
                    split
                />
            </div>
        </Dialog>
    );
};

export default connect(
    (state: AppState) => ({memory: state.memory, errors: state.errors.memory}),
    dispatch => ({ memoryActions: bindActionCreators(memoryActions, dispatch) })
)(PairForm)