import React from 'react';
import {actionCreators as quizNinjaActions, QuizNinjaState} from "../../../store/games/QuizNinjaStore";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import {Dialog} from "../../Dialog";
import {Translate} from "react-localize-redux";
import {ImageFormButton, InputField, Slider, SubmitButton, TextArea} from "../../forms/FormGroup";
import QuizninjaAnswerForm from "./QuizninjaAnswerForm";
import newAnswerImage from "../../../images/plus.png";
import {QuizNinjaAnswerPreview, QuizNinjaRoundPreview} from './QuizNinjaRoundPreview';
import {AppState} from "../../../store/configureStore";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {ErrorState} from "../../../store/Error";

interface Props {
    actions: typeof quizNinjaActions;
    quizninja: QuizNinjaState;
    errors: ErrorState['quizninja'];
}

const QuizninjaRoundForm = (props: Props) => {
    const round = props.quizninja.round;
    const loading = props.quizninja.isLoading;
    const confirmDelete = <ConfirmationForm
        text={"quizninja_round_confirm_delete"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => {props.actions.deleteRound(round.index)}}
        onCancel={() => props.actions.showConfirmDeleteRound(false)}/>;

    const submitFunc = () => {
        if (round.new){
            props.actions.createRound(round, true);
        }
        else{
            props.actions.updateRound(round, true);
        }

    };

    let validForm = true;
    if (!round.title) validForm = false;  
    if (!round.question && !round.image) validForm = false;

    const mainForm = (
        <Dialog
            loading={loading.createRound || loading.deleteRound || loading.updateRound}
            className='quizninja-round-dialog'
            onClose={() => props.actions.showEditRound(false)}
            icon={round.new ? undefined : 'trash'}
            onIconClick={() => props.actions.showConfirmDeleteRound(true)} >
            {props.quizninja.ui.deleteRound && confirmDelete}
            <h1>{!round.new ? `${round.index + 1}. ` : ''}<Translate id='quizninja_round' /></h1>
            <form className='float quizninja-round-form' onSubmit={(e) => e.preventDefault()}>
                <ImageFormButton
                    name={'image'}
                    imageSubmit={props.actions.setRoundImage}
                    previewWidth={100}
                    previewHeight={100}
                    unsavedChange={round.image !== round._o.image}
                />
                <InputField
                    name='title'
                    type='text'
                    value={round.title}
                    placeholder='quizninja_title_placeholder'
                    onChange={(e) => props.actions.setRoundTitleText(e.target.value)}
                    noLabel
                    tabIndex={1}
                    original={round._o.title}
                />
                <TextArea
                    name='description'
                    value={round.description}
                    placeholder='quizninja_description_placeholder'
                    onChange={(e) => props.actions.setRoundDescription(e.target.value)}
                    noLabel
                    tabIndex={2}
                    original={round._o.description}
                />
                <InputField
                    name='question'
                    type='text'
                    value={round.question}
                    placeholder='quizninja_question_placeholder'
                    onChange={(e) => props.actions.setRoundQuestion(e.target.value)}
                    noLabel
                    tabIndex={3}
                    original={round._o.question}
                />
                <Slider
                    name={'time'}
                    value={round.time}
                    min={5}
                    max={120}
                    step={5}
                    onChange={e => props.actions.setRoundTime(e.target.value)}
                    original={round._o.time}
                />
                <SubmitButton
                    text={'submit'}
                    onClick={submitFunc}
                    split
                    disabled={!validForm}

                />
            </form>
            <div className='float preview'>
                <QuizNinjaRoundPreview round={round} index={1}/>
            </div>
            <div className='clear-fix' />
            <h2><Translate id='quizninja_answers'/></h2>
            <div className='quizninja-answer-container'>
                {round.answers && round.answers.map((answer, index) => {
                    return (
                        <QuizNinjaAnswerPreview key={index} answer={answer} onClick={() => props.actions.setEditAnswer(answer, index)} />
                    );
                })}

                <button className='answer new' onClick={() => props.actions.newAnswer()} disabled={!validForm}>
                    <img src={newAnswerImage} alt="" />
                </button>
            </div>
            <div className='clear-fix' />

            <FormGeneralError error={props.errors.createRound}/>
            <FormGeneralError error={props.errors.updateRound}/>
            <FormGeneralError error={props.errors.deleteRound}/>

        </Dialog>
    );
    
    if (props.quizninja.ui.editAnswer && !loading.updateRound){
        return <QuizninjaAnswerForm />;
    }
    if (props.quizninja.ui.editRound) {
        return mainForm;
    }

    return null;
};

export default connect(
    (state: AppState) => ({quizninja: state.quizninja, errors: state.errors.quizninja}),
    dispatch => ({ actions: bindActionCreators(quizNinjaActions, dispatch) })
)(QuizninjaRoundForm)