import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as PlaylistStore from "../../store/PlayListStore";
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BaseGame} from "../../model/Game/BaseGame";
import {isTempGame} from "../../store/games/Games";

interface Props{
    game: BaseGame;
    isAuthor: boolean;
    playListActions: typeof PlaylistStore.actionCreators;
}
interface State{}

class GameMenuPlaylist extends Component<Props, State>{

    render(){
        const props = this.props;
        const game = props.game;

        return(
            <div className={'menu-container'}>
                {game && !isTempGame(game) &&
                    <Link to={`/workshop/${game.gameType}/edit/${game.id}`} onClick={e => e.stopPropagation()}>
                        <div className='menu-point'>
                            <span>
                               <Translate id={props.isAuthor ? 'edit_content' : 'see_content'}/>
                            </span>
                            <FontAwesomeIcon icon='edit' className='fa-icon'/>
                        </div>
                    </Link>
                }
                <div
                    className='menu-point'
                    onClick={() => props.playListActions.showRemoveGameConfirm(game.id)}
                >
                    <Translate id='playlist_remove_from'/>
                    <FontAwesomeIcon icon='minus' className='fa-icon'/>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    dispatch => ({
        playListActions: bindActionCreators(PlaylistStore.actionCreators, dispatch)
    })
)(GameMenuPlaylist);