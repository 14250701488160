import React, { useEffect, useState } from 'react';
import { JigsawPuzzleImage } from "../../../model/Game/JigsawPuzzle/JigsawPuzzleImage";
import { DbOrUploadImage } from "../../../services/ImageHelper";
import { PuzzleGrid3x2, PuzzleGrid4x3, PuzzleGrid6x4 } from "./JigsawPuzzleGrids";

interface Props {
    jigsawImage: JigsawPuzzleImage;
    onClick?: () => void;
    autoSlide?: boolean;
}

const JigsawPreview = (props: Props) => {
    const [active, setActive] = useState<number>(0);
    const [timeId, setTimeId] = useState<ReturnType<typeof setTimeout>>();
    useEffect(() => {
        setTimeId(t => {
            t && clearTimeout(t);
            if (props.autoSlide) {
                return setTimeout(() => setActive(a => a + 1 > 2 ? 0 : a + 1), 3000);
            }
            else {
                return undefined;
            }
        });
    }, [props.autoSlide, active]);
    useEffect(() => () => timeId && clearTimeout(timeId), [timeId])


    if (!props.jigsawImage.image) return null;

    return (
        <div className={`preview${props.onClick ? ' cliackable' : ''}`}>
            <div className='selector'>
                <div
                    className={`jigsaw-image threebytwo${active === 0 ? ' active' : ''}`}
                    onClick={() => setActive(0)}
                >
                    <DbOrUploadImage realSize image={props.jigsawImage.image} className='uploaded' />
                    <PuzzleGrid3x2 className='puzzle-overlay-image' pathColor={"#FFFFFF"} />
                    <div className='size-text'>6</div>
                </div>

                <div
                    className={`jigsaw-image fourbythree${active === 1 ? ' active' : ''}`}
                    onClick={() => setActive(1)}
                >
                    <DbOrUploadImage realSize image={props.jigsawImage.image} className='uploaded' />
                    <PuzzleGrid4x3 className='puzzle-overlay-image' pathColor={"#FFF"} />
                    <div className='size-text'>12</div>
                </div>

                <div
                    className={`jigsaw-image sixbyfour${active === 2 ? ' active' : ''}`}
                    onClick={() => setActive(2)}
                >
                    <DbOrUploadImage realSize image={props.jigsawImage.image} className='uploaded' />
                    <PuzzleGrid6x4 className='puzzle-overlay-image' pathColor={"#FFF"} />
                    <div className='size-text'>24</div>
                </div>
            </div>
            <div className='container' onClick={props.onClick}>
                {active === 0 &&
                    <div className='jigsaw-image threebytwo'>
                        <DbOrUploadImage realSize image={props.jigsawImage.image} className='uploaded' />
                        <PuzzleGrid3x2 className='puzzle-overlay-image' pathColor={props.jigsawImage.borderColor} />
                    </div>
                }
                {active === 1 &&
                    <div className='jigsaw-image fourbythree'>
                        <DbOrUploadImage realSize image={props.jigsawImage.image} className='uploaded' />
                        <PuzzleGrid4x3 className='puzzle-overlay-image' pathColor={props.jigsawImage.borderColor} />
                    </div>
                }
                {active === 2 &&
                    <div className='jigsaw-image sixbyfour'>
                        <DbOrUploadImage realSize image={props.jigsawImage.image} className='uploaded' />
                        <PuzzleGrid6x4 className='puzzle-overlay-image' pathColor={props.jigsawImage.borderColor} />
                    </div>
                }

            </div>

        </div>

    )
}

export default JigsawPreview;