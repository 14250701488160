import React, {useEffect, useState} from 'react';
import {Translate} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as wosActions, WarOfStrategyState} from '../../../store/games/WarOfStrategy';
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import IslandsImage from '../../../images/games/warofstrategy/wos_background_no_sea.png';
import WosQuizForm from "./WosQuizForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GameDetailed from "../../ModelPreview/GameDetailed";
import WarOfStrategySettings from "./WarOfStrategySettings";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import {WosGamePreview} from "./WosGamePreview";
import {ErrorState} from "../../../store/Error";
import { SecureLayoutComponentProps } from '../../Layout';

interface Props extends SecureLayoutComponentProps<{ id?: string }> {
    wosActions: typeof wosActions;
    myGamesActions: typeof myGamesActions;
    errors: ErrorState['wosState'];
    wosState: WarOfStrategyState;
}

const WarOfStrategy = (props: Props) => {
    const {wosActions, wosState, errors, myGamesActions, match, setLayoutButtons } = props;
    
    const [showOverview, setShowOverview] = useState(true);

    useEffect(() => {
        return(() => {wosActions.resetGame()});
    }, [wosActions]);
   
    if(errors.getGame) return <div><Translate id='unknown_error'/></div>;
    const game = wosState.game;

    let easyCount = 0, normalCount = 0, hardCount = 0, veryHardCount = 0;
    game && game.quizzes.forEach(x => {
        switch (x.difficulty){
            case 1: easyCount++;break;
            case 2: normalCount++;break;
            case 3: hardCount++;break;
            case 4: veryHardCount++;break;
            default:
        } 
    });

    return (
        <EditGameComponent
            gameState={wosState}
            newGameElementFunction={() => wosActions.showNewQuiz()}
            newGameElementText={'wos_create_quiz'}
            //extraButtons={[{id: 'convert', hoverMsg: 'wod_convert_game',icon: "exchange-alt", action: wosActions.convertToDitj}]}
            routeMatch={match}
            gameType={'WarOfStrategy'}
            setLayoutButtons={setLayoutButtons}
        >
            <GameDetailed 
                game={game}
                gameType={'WarOfStrategy'}
                tempDescription={
                    <div>
                        <p><Translate id='wos_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                    </div>
                }
                tempTitle={<Translate id='wos_title'/>}
                onClick={() => myGamesActions.showEditDialog(true, game)}
                gameStats={wosState.gameStats}
            />
            {game &&
                <WosGamePreview
                    game={game}
                    onQuizClick={(quiz, index) => wosActions.openEditQuiz(quiz, index)}
                />
            }
            <div className={`island-overview ${!showOverview ? 'hidden' : ''}`} onClick={() => setShowOverview(s => !s)}>
                <div className='island-overview-title'><Translate id='wos_quiz_count_title'/></div>
                <div className='toggle'>
                    <FontAwesomeIcon icon={showOverview ? 'chevron-circle-right' : 'chevron-circle-left'} />
                </div>
                <div className='island-numbers'>
                    <img src={IslandsImage} alt='island'/>
                    <div className='island-quiz-count absolom'>{veryHardCount}</div>
                    <div className='island-quiz-count booty'>{normalCount}</div>
                    <div className='island-quiz-count candyapple'>{normalCount}</div>
                    <div className='island-quiz-count cannibal'>{normalCount}</div>
                    <div className='island-quiz-count crab'>{easyCount}</div>
                    <div className='island-quiz-count dragonroost'>{normalCount}</div>
                    <div className='island-quiz-count hydra'>{normalCount}</div>
                    <div className='island-quiz-count kookoo'>{normalCount}</div>
                    <div className='island-quiz-count poodle'>{easyCount}</div>
                    <div className='island-quiz-count shutter'>{easyCount}</div>
                    <div className='island-quiz-count tortuga'>{hardCount}</div>
                    <div className='island-quiz-count vulcan'>{normalCount}</div>
                </div>
            </div>
            <WosQuizForm />
            <WarOfStrategySettings/>
        </EditGameComponent>
    );
}

export default connect(
    (state: AppState) => ({
        wosState: state.wosState,
        errors: state.errors.wosState,
    }),
    dispatch => ({
        wosActions: bindActionCreators(wosActions, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch)
    })
)(WarOfStrategy)