import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as OrgStore from "../../store/OrganizationStore";
import * as GlobalStore from '../../store/GlobalComponentStore';
import {Link} from "react-router-dom";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BaseGame} from "../../model/Game/BaseGame";

interface Props{
    game: BaseGame;
    isAuthor: boolean;
    globalActions: typeof GlobalStore.actionCreators;
}

const GameMenuDefault = (props: Props) => {
    const {game, isAuthor, globalActions} = props;

    return(
        <div className='menu-container'>
            <Link to={`/workshop/${game.gameType}/edit/${game.id}`} >
                <div className='menu-point'>
                    <span>
                        <Translate id={isAuthor ? 'edit_content' : 'see_content'}/>
                    </span>
                    <FontAwesomeIcon icon='file-import' className='fa-icon'/>
                </div>
            </Link>
            { game.isPublished &&
            <div
                className='menu-point'
                onClick={() => globalActions.playlistAddItemForm(game)}
            >
                <Translate id='playlist_add_to'/>
                <FontAwesomeIcon icon='plus' className='fa-icon'/>
            </div>
            }
            { game.isPublished &&
                <div
                    className='menu-point'
                    onClick={() => globalActions.bundleAddItemForm(game)}
                >
                    <Translate id='bundle_add_to' />
                    <FontAwesomeIcon icon='plus' className='fa-icon' />
                </div>
            }
            { game.isPublished &&
                <div
                    className='menu-point'
                    onClick={() => globalActions.wallAddItemForm(game)}
                >
                    <Translate id='wall_add_to' />
                    <FontAwesomeIcon icon='plus' className='fa-icon' />
                </div>
            }
        </div>
    )
}

export default connect(
    () => ({}),
    dispatch => ({
        orgActions: bindActionCreators(OrgStore.actionCreators, dispatch),
        globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
    })
)(GameMenuDefault);