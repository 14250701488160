import React from 'react';
import {QuizNinjaGame} from "../../../model/Game/QuizNinja/QuizNinjaGame";
import {QnRound} from "../../../model/Game/QuizNinja/QnRound";
import {Translate} from "react-localize-redux";
import {QuizNinjaAnswerPreview, QuizNinjaRoundPreview} from "./QuizNinjaRoundPreview";

interface Props{
    game: QuizNinjaGame;
    onRoundClick?: (quiz: QnRound, index: number) => void;
    onSort?: (indexToMove: number, destinationIndex: number) => void;
    onSortCommit?: () => void;
}

const QuizNinjaGamePreview = (props: Props) => {

    const game = props.game;
    const rounds = game.rounds;

    return(
        <div className='quizninja-rounds'>
            <h2><Translate id='quizninja_rounds'/></h2>
            {rounds && rounds.length > 0 ?
                <div>
                    <div className='quizninja-rounds-container'>
                        {
                            rounds.map((round, index) => {
                                return(
                                    <div
                                        className='quizninja-round-sortable'
                                        key={index}
                                    >
                                        <QuizNinjaRoundPreview
                                            round={round}
                                            key={index}
                                            index={index + 1}
                                            onClick={() => {props.onRoundClick && props.onRoundClick(round, index)}}
                                        />
                                        <div className='answers'>
                                            <div className='infinite'>
                                                {round.answers && round.answers.map((answer, index) => {
                                                    return (<QuizNinjaAnswerPreview key={index} answer={answer} />)
                                                })}
                                            </div>

                                            <div className='answers-cover' />
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className='clear-fix'/>
                </div>
                :
                <Translate id='quizninja_no_rounds' />
            }
        </div>
    )
};

export default QuizNinjaGamePreview;