import {TA} from './configureStore';
import {Reducer} from "redux";
import {AxiosError} from 'axios';
import * as PlaylistStore from './PlayListStore';
import * as GamesStore from './games/Games'
import * as OrgStore from './OrganizationStore';
import * as AccountStore from './Account';
import * as ActiveFloorUnitStore from './ActiveFloorUnit';
import * as MemoryStore from './games/Memory';
import * as SuperSorterStore from './games/SuperSorterStore';
import * as PresentationStore from './games/PresentationStore';
import * as AuthStore from './Auth';
import * as MyGamesStore from './MyGames';
import * as DangerInTheJungleStore from './games/DangerInTheJungle';
import * as QuizNinjaStore from './games/QuizNinjaStore';
import * as PictureBookStore from './games/PictureBook';
import * as SmackTheFlyStore from './games/SmackTheFlyStore';
import * as SplatterSpaceStore from './games/SplatterSpaceStore';
import * as BikeRaceStore from './games/BikeRaceStore';
import * as MeStore from './Me';
import * as TranslationStore from './TranslationStore';
import * as LibraryStore from './LibraryStore';
import * as TagStore from './TagStore';
import * as FeaturedGameStore from './FeaturedGameStore';
import * as SlimeBattleStore from './games/SlimeBattleStore';
import * as CrazyBalloonsStore from './games/CrazyBalloonsStore';
import * as SweetyWarStore from './games/SweetyWarStore';
import * as BlockBusterStore from './games/BlockBusterStore';
import * as CircusRaceStore from './games/CircusRaceStore';
import * as JigsawPuzzleStore from './games/JigsawPuzzleStore';
import * as GoalShowStore from './games/GoalShowStore';
import * as BuzzItStore from './games/BuzzItStore';
import * as WosStore from './games/WarOfStrategy';
import * as WhackAMoleStore from './games/WhackAMoleStore';
import * as MeditationStore from './games/MeditationStore';
import * as RaceCarStore from './games/RaceCarStore';
import * as FeedTheFoxStore from './games/FeedTheFoxStore';
import * as PizzaPartyStore from './games/PizzaPartyStore';
import * as PopItStore from './games/PopItStore';
import * as DanceASlothStore from './games/DanceASlothStore';
import * as MadMonstersStore from './games/MadMonstersStore';   
import * as WordFinderStore from './games/WordFinderStore'; 

export const reducerName = 'errors';

export interface ErrorType {
    status: number;
    data?: {
        [error: string]: string[];
    };
}
/* eslint-disable @typescript-eslint/no-unused-vars */
export interface ErrorState{
    auth: {[key in keyof typeof AuthStore.actionCreators]?: ErrorType };
    mygames: {[key in keyof typeof MyGamesStore.actionCreators]?: ErrorType };
    organization: {[key in keyof typeof OrgStore.actionCreators]?: ErrorType };
    me: {[key in keyof typeof MeStore.actionCreators]?: ErrorType };
    playlist: {[key in keyof typeof PlaylistStore.actionCreators]?: ErrorType };
    gamesState: {[key in keyof typeof GamesStore.actionCreators]?: ErrorType };
    libraryState: {[key in keyof typeof LibraryStore.actionCreators]?: ErrorType };
    account: {[key in keyof typeof AccountStore.actionCreators]?: ErrorType };
    translationState: {[key in keyof typeof TranslationStore.actionCreators]?: ErrorType };
    activefloorunits: {[key in keyof typeof ActiveFloorUnitStore.actionCreators]?: ErrorType };
    tagState: {[key in keyof typeof TagStore.actionCreators]?: ErrorType };

    memory: {[key in keyof typeof MemoryStore.actionCreators]?: ErrorType };
    supersorter: {[key in keyof typeof SuperSorterStore.actionCreators]?: ErrorType };
    presentation: {[key in keyof typeof PresentationStore.actionCreators]?: ErrorType };
    ditj: {[key in keyof typeof DangerInTheJungleStore.actionCreators]?: ErrorType };
    quizninja: {[key in keyof typeof QuizNinjaStore.actionCreators]?: ErrorType };
    picturebook: {[key in keyof typeof PictureBookStore.actionCreators]?: ErrorType };
    smackthefly: {[key in keyof typeof SmackTheFlyStore.actionCreators]?: ErrorType };
    splatterspace: {[key in keyof typeof SplatterSpaceStore.actionCreators]?: ErrorType };
    bikeRaceState: {[key in keyof typeof BikeRaceStore.actionCreators]?: ErrorType };
    featured_game_store: {[key in keyof typeof FeaturedGameStore.actionCreators]? : ErrorType};
    sweetyWarState: {[key in keyof typeof SweetyWarStore.actionCreators]? : ErrorType};
    goalShowState: {[key in keyof typeof GoalShowStore.actionCreators]? : ErrorType};
    crazyBalloonsState: { [key in keyof typeof CrazyBalloonsStore.actionCreators]?: ErrorType };
    slimeBattleState: { [key in keyof typeof SlimeBattleStore.actionCreators]?: ErrorType };
    blockBusterState: { [key in keyof typeof BlockBusterStore.actionCreators]?: ErrorType };
    circusRaceState: { [key in keyof typeof CircusRaceStore.actionCreators]?: ErrorType };
    jigsawPuzzleState: { [key in keyof typeof JigsawPuzzleStore.actionCreators]?: ErrorType};
    buzz_it_store: {[key in keyof typeof BuzzItStore.actionCreators]?: ErrorType};
    wosState: {[key in keyof typeof WosStore.actionCreators]?: ErrorType};
    whackAMoleState: { [key in keyof typeof WhackAMoleStore.actionCreators]?: ErrorType };
    meditationState: { [key in keyof typeof MeditationStore.actionCreators]?: ErrorType };
    raceCarState: { [key in keyof typeof RaceCarStore.actionCreators]?: ErrorType };
    feedTheFoxState: { [key in keyof typeof FeedTheFoxStore.actionCreators]?: ErrorType };
    pizzaPartyState: { [key in keyof typeof PizzaPartyStore.actionCreators]?: ErrorType };
    popItState: { [key in keyof typeof PopItStore.actionCreators]?: ErrorType };
    danceASlothState: { [key in keyof typeof DanceASlothStore.actionCreators]?: ErrorType };
    madMonstersState: { [key in keyof typeof MadMonstersStore.actionCreators]?: ErrorType };
    wordFinderState: { [key in keyof typeof WordFinderStore.actionCreators]?: ErrorType }; 
}
/* eslint-enable @typescript-eslint/no-unused-vars */

const initialState: ErrorState = {
    auth: {},
    me: {},
    playlist : {},
    mygames: {},
    gamesState: {},
    organization: {},
    libraryState: {},
    account: {},
    memory: {},
    ditj: {},
    splatterspace: {},
    bikeRaceState: {},
    picturebook: {},
    quizninja: {},
    smackthefly: {  },
    translationState: {},
    activefloorunits: {},
    tagState: {},
    featured_game_store: {},
    sweetyWarState: {},
    goalShowState: {},
    crazyBalloonsState: {},
    slimeBattleState: {},
    blockBusterState: {},
    circusRaceState: {},
    jigsawPuzzleState: {},
    buzz_it_store: {},
    wosState: {},
    supersorter: {},
    presentation: {},
    whackAMoleState: {}, 
    meditationState: {}, 
    raceCarState: {},
    feedTheFoxState: {}, 
    pizzaPartyState: {}, 
    popItState: {},
    danceASlothState: {},
    madMonstersState: {}, 
    wordFinderState: {}
};


export const createError = (key: string, ...error: string[]): ErrorType => {
    return {
        status: 400,
        data: {
            [key]: error
        }
    }
};

export const errorActions = {
    reportAxiosError: <T extends keyof ErrorState>(reducer: T, key: keyof ErrorState[T], error: AxiosError): TA => async (dispatch) => {
        const errorData: ErrorType = {
            data: error.response && error.response.data,
            status: (error.response && error.response.status) || 999
        };
        dispatch({type: 'ERROR', key, reducer, data: errorData});
    },
    reportError: <T extends keyof ErrorState>(reducer: T, key: keyof ErrorState[T], data: ErrorType): TA => async(dispatch) => {
        dispatch({type:'ERROR', key, reducer, data});
    },
    resetError: <T extends keyof ErrorState>(reducer: T, key: keyof ErrorState[T]): TA => async(dispatch) => {
        dispatch({type:'RESET_ERROR', key, reducer } );
    },
    resetAllErrors:  <T extends keyof ErrorState>(reducer: T): TA => async(dispatch) => {
        dispatch({type:'RESET_ALL_ERROR', reducer});
    }
};

// eslint-disable-next-line
const reducerMethods: { [key: string]: (state: ErrorState, action: any) => ErrorState } = {
    ERROR: (state: ErrorState, action: {reducer: keyof ErrorState; key: string; data: ErrorType}) => {
        return {
            ...state,
            [action.reducer]:{
                ...state[action.reducer],
                [action.key]: action.data
            }
        };
    },
    RESET_ERROR: (state: ErrorState, action: {reducer: keyof ErrorState; key: string}) => {
        return {
            ...state,
            [action.reducer]:{
                ...state[action.reducer],
                [action.key]: false
            }
        };
    },
    RESET_ALL_ERROR: (state: ErrorState, action: {reducer: keyof ErrorState}) => {
        return {
            ...state, 
            [action.reducer]: initialState[action.reducer]
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<ErrorState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};