import React from 'react';
import Dialog from "../Dialog";
import {SelectList} from "./FormGroup";
import {BaseGame} from "../../model/Game/BaseGame";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface Props{
    sortKey: keyof BaseGame;
    asc?: boolean;
    onClose: () => void;
    onChangeKey: (key: keyof BaseGame) => void;
    onChangeAscending: (asc: boolean) => void;
}

const SortGamesDialog = (props: Props) => {

    return(
        <Dialog onClose={props.onClose}>
            <div className='sort-container'>
                <SelectList
                    options={[
                        {name: 'title', value: 'title'},
                        {name: 'description', value: 'description'},
                        {name: 'gametypes', value: 'gameType'},
                        {name: 'createdDateTime', value: 'createdDateTime'},
                        {name: 'editedDateTime', value: 'editedDateTime'},
                        {name: 'language_a', value: "languageName"},
                        {name: 'subject', value: "courseName"}
                    ]}
                    name={'sort'}
                    noLabel
                    defaultValue={props.sortKey}
                    onChange={(e) => props.onChangeKey(e.target.value as keyof BaseGame)}
                    translate
                />
                <span className='asc' onClick={() => props.onChangeAscending(!props.asc)}>
                    <FontAwesomeIcon icon={props.asc ? 'sort-amount-up' : 'sort-amount-down'} />
                </span>
            </div>
        </Dialog>
    )
}

export default SortGamesDialog;