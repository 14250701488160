import React from 'react';
import {
    actionCreators as quizNinjaActions,
    QuizNinjaState,
    quizNinjaTransformAnswerToStyle
} from "../../../store/games/QuizNinjaStore";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ConfirmationForm} from "../../forms/games/ConfirmationForm";
import {Dialog} from "../../Dialog";
import {Translate} from "react-localize-redux";
import {CheckBox, ColorAndFont, ImageFormButton, InputField, SubmitButton} from '../../forms/FormGroup';
import {QuizNinjaAnswerPreview} from './QuizNinjaRoundPreview';
import {AppState} from "../../../store/configureStore";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {ErrorState} from "../../../store/Error";

interface Props {
    actions: typeof quizNinjaActions;
    quizninja: QuizNinjaState;
    errors:  ErrorState['quizninja'];
}

const QuizninjaAnswerForm = (props: Props) => {
    const round = props.quizninja.round;
    const answer = props.quizninja.answer;
    const loading = props.quizninja.isLoading;

    const confirmDelete = <ConfirmationForm
        text={"quizninja_answer_confirm_delete"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => {props.actions.deleteAnswer(round.index, answer.index)}}
        onCancel={() => props.actions.showConfirmDeleteAnswer(false)}/>;

    const submitFunc = () => {
        if (answer.new){
            props.actions.createAnswer(round.index, answer);
        }
        else{
            props.actions.updateAnswer(round.index,  answer);
        }

    };

    let validForm = false;
    if (answer.text !== ""){
        validForm = true;
    }
    if (answer.image !== null){
        validForm = true;
    }

    const mainForm = (
        <Dialog
            loading={loading.createAnswer || loading.deleteAnswer || loading.updateAnswer}
            className='quizninja-answer-dialog'
            onClose={() => props.actions.showEditAnswer(false)}
            icon={answer.new ? undefined : 'trash'}
            onIconClick={() => props.actions.showConfirmDeleteAnswer(true)}
            icons={[
                {icon: 'eye-dropper', onClick: () => props.actions.saveStyle(), hoverMsg: <div className='min-width'><Translate id='save_style' /></div>},
                {icon: 'fill-drip', onClick: () => props.actions.applyStyle(),
                    hoverMsg: (
                        <div>
                            <div><Translate id='apply_style'/></div>
                            <QuizNinjaAnswerPreview
                                answer={quizNinjaTransformAnswerToStyle(answer, props.quizninja.style)}
                            />
                        </div>
                    )
                }
            ]}
        >
            {props.quizninja.ui.deleteAnswer && confirmDelete}
            <h1><Translate id='quizninja_answer' /></h1>
            <form className='quizninja-answer-form' onSubmit={(e) => e.preventDefault()}>
                <ImageFormButton
                    name={'image'}
                    imageSubmit={props.actions.setAnswerImage}
                    previewWidth={100}
                    previewHeight={100}
                    unsavedChange={answer.image !== answer._o.image}
                />
                <InputField
                    name='text'
                    type='text'
                    value={answer.text}
                    placeholder='quizninja_text_placeholder'
                    onChange={(e) => props.actions.setAnswerText(e.target.value)}
                    noLabel
                    tabIndex={1}
                    original={answer._o.text}
                />
                <ColorAndFont
                    colorValue={answer.fontColorHex || '#FFFFFF'}
                    onColorChange={e => props.actions.setAnswerFontColor(e.target.value)}
                    fontFamilyValue={answer.font || "Chewy"}
                    onFontFamilyChange={(e) => {props.actions.setAnswerFont(e.target.value)}}
                    originalFontFamily={answer._o.font}
                    originalColor={answer._o.fontColorHex}
                />
                <CheckBox
                    name='correct'
                    active={answer.correct}
                    onChange={(e) => props.actions.setCorrect(e.target.value)}
                    original={answer._o.correct}
                />
                <SubmitButton
                    text={'submit'}
                    onClick={submitFunc}
                    split
                    disabled={!validForm}
                />
            </form>
            <div className='text-note'>
                <Translate id='quizninja_textoverflow_warning'/>
            </div>
            <div className='preview'>
                <QuizNinjaAnswerPreview answer={answer}/>
            </div>
            <div className='clear-fix' />
            <FormGeneralError error={props.errors.createAnswer}/>
            <FormGeneralError error={props.errors.updateAnswer}/>
            <FormGeneralError error={props.errors.deleteAnswer}/>
        </Dialog>
    );

    if (props.quizninja.ui.editAnswer) {
        return mainForm;
    }

    return null;
};

export default connect(
    (state: AppState) => ({quizninja: state.quizninja, errors: state.errors.quizninja}),
    dispatch => ({ actions: bindActionCreators(quizNinjaActions, dispatch) })
)(QuizninjaAnswerForm)