import React from 'react'; 
import {connect} from "react-redux"; 
import {bindActionCreators} from "redux"; 
import * as MadMonstersStore from "../../../store/games/MadMonstersStore"; 
import * as GamesStore from "../../../store/games/Games"; 
import Dialog from "../../Dialog"; 
import {Translate} from "react-localize-redux"; 
import {SelectList, SubmitButton} from "../../forms/FormGroup"; 
import {AppState} from "../../../store/configureStore"; 
 
interface Props { 
    gamesActions: typeof GamesStore.actionCreators; 
    madMonstersState: MadMonstersStore.MadMonstersState; 
    madMonstersActions: typeof MadMonstersStore.actionCreators; 
} 
 
const MadMonstersSettings = (props: Props) => { 
    if (!props.madMonstersState.ui.showSettings) { 
        return null; 
    } 
 
    const game = props.madMonstersState.game; 
    if (!game) { 
        return null; 
    } 
 
    const settings = { 
        skin: game.skin || 'Default', 
    }; 
 
    const skinOptions = [ 
        {name: "Default",    value: "Default"   } 
    ]; 
 
    return( 
      <Dialog 
          className='settings' 
          onClose={() => props.gamesActions.showSettings(game.gameType, false)} 
          loading={props.madMonstersState.isLoading.updateSettings}> 
          <h2><Translate id='settings'/></h2> 
          <form onSubmit={e => e.preventDefault()}> 
          <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.madMonstersActions.setField}/> 
              <h3><Translate id='preview_skin'/></h3> 
              <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' /> 
              <div className='clear-fix' /> 
              <SubmitButton 
                  text='submit' 
                  onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)} 
                  split 
              /> 
          </form> 
      </Dialog> 
    ); 
}; 
 
export default connect( 
    (state: AppState) => ({madMonstersState: state.madMonstersState}), 
    dispatch => ({ 
        madMonstersActions: bindActionCreators(MadMonstersStore.actionCreators, dispatch), 
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch) 
    }) 
)(MadMonstersSettings);