import React, {Component, CSSProperties, KeyboardEvent, MouseEvent, ReactNode, useEffect, useState} from 'react';
import {getTranslate, LocalizeState, Translate} from 'react-localize-redux';
import FieldErrors from "./FieldErrors";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ChromePicker} from "react-color";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import QuickImage from "./games/QuickImage";
import {QuickAudio} from "./QuickAudio";
import {ErrorType} from '../../store/Error';
import {AppState, ChangeEvent} from '../../store/configureStore';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ImageUploadType} from '../../model/ImageUploadType';
import {SoundUploadType} from '../../model/SoundUploadType';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as TagStore from "../../store/TagStore";
import {TagState} from "../../store/TagStore";
import {GamesState} from '../../store/games/Games';
import {createSliderWithTooltip, Range as RangeType} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {courseList} from "../../model/Subject";
import {TextAlignment} from "../../model/Game/TextType";
import {getMathText, getSubScriptText, getSuperScriptText} from "../../services/TextHelper";
import ImageForm from "./games/ImageForm";
import Dialog from "../Dialog";
import { FetchError } from '../../services/FetchHelper';
import SoundForm from './games/SoundForm';

export const FontOptions = [
    { name: 'Boogaloo', value: 'Boogaloo' },
    { name: 'Cabin Sketch', value: 'Cabin Sketch' },
    { name: 'Chewy', value: 'Chewy' },
    { name: 'Eater', value: 'Eater' },
    { name: 'Inconsolata', value: 'Inconsolata' },
    { name: 'Lato', value: 'Lato' },
    { name: 'Luckiest Guy', value: 'Luckiest Guy' },
    { name: 'Ropa Sans', value: 'Ropa Sans' },
    { name: 'Julee', value: 'Julee' },
    { name: 'Covered By Your Grace', value: 'Covered By Your Grace' },
    { name: 'Spirax', value: 'Spirax' },

    { name: 'Alef (עברית)', value: "Alef" },
    { name: 'Amatic SC (עברית)', value: "Amatic SC" },
    { name: 'Bellefair (עברית)', value: "Bellefair" },
    { name: 'David Libre (עברית)', value: "David Libre" },
    { name: 'Noot (עברית)', value: "Noot" },
    { name: 'Rubik (עברית)', value: "Rubik" },
    { name: 'Secular One (עברית)', value: "Secular One" },
    { name: 'Varela Round (עברית)', value: "Varela Round" }
];



interface TextAlignmentProps {
    name: string;
    value: TextAlignment;
    onChange: (event: ChangeEvent<TextAlignment>) => void;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    original?: TextAlignment;
}
export const TextAlignmentFormGroup = (props: TextAlignmentProps) => {
    const showUnsaved = props.original !== undefined && props.original !== props.value;

    const label = props.label ? <label htmlFor={props.name}><Translate id={props.label} /></label> : null;

    const onClick = (e: MouseEvent<HTMLButtonElement>, align: TextAlignment) => {
        e.preventDefault();
        props.onChange({target: {name: props.name, value: align}});
    };

    const input = (
        <div className={`text-alignment${showUnsaved ? ' show-changes' : ''}`}>
            <button
                className={`float btn ${props.value === TextAlignment.Left ? 'active' : ''}`}
                onClick={(e) => (onClick(e, TextAlignment.Left))}
            >
                <FontAwesomeIcon icon={'align-left'} />
            </button>
            <button
                className={`float btn ${props.value === TextAlignment.Center ? 'active' : ''}`}
                onClick={(e) => (onClick(e, TextAlignment.Center))}
            >
                <FontAwesomeIcon icon={'align-center'} />
            </button>
            <button
                className={`float btn ${props.value === TextAlignment.Right ? 'active' : ''}`}
                onClick={(e) => (onClick(e, TextAlignment.Right))}
            >
                <FontAwesomeIcon icon={'align-right'} />
            </button>
            <div className='clear-fix' />
            <UnsavedChangesIcon show={showUnsaved}/>
        </div>
    );
    return(
        <div className={`form-group`}>
            { label }
            { input }
            { props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div> }
            <div className='clear-fix'/>
        </div>
    )
};

interface InputFieldProps<T extends string|number> {
    name: string;
    type: 'text'|'number'|'password'|'email';
    value: T;
    onChange: (event: ChangeEvent<T>) => void;
    errors?: false | ErrorType | FetchError;
    errorField?: string;
    noLabel?: boolean;
    placeholder?: string;
    inputIcon?: IconProp;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    tabIndex?: number;
    labelWidth100?: boolean;
    original?: string;
}
export const InputField = <T extends string|number>(props: InputFieldProps<T>) => {

    const label = props.noLabel || <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}><Translate id={props.label || props.name} /></label>;
    const inputIcon = props.inputIcon && <FontAwesomeIcon icon={props.inputIcon} className='input-icon'/>;

    const showUnsaved = props.original !== undefined && props.original !== props.value;

    const input =
        <Translate>
            { ({translate}) =>
                <>
                    <input
                        dir='auto'
                        name={props.name}
                        placeholder={props.placeholder ? translate(props.placeholder).toString() : ''}
                        className={`form-control${showUnsaved ? ' unsaved-changes' : ''}`}
                        type={props.type}
                        value={props.value}
                        onChange={(event) =>
                            props.onChange(
                                {target:
                                        {
                                            name: props.name,
                                            value: typeof(props.value) === 'number' ? parseFloat(event.target.value) as T : (event.target.value) as T
                                        }
                                }
                            )}
                        onKeyDown={(e) => {
                            if(e.ctrlKey && (e.key === "+" || e.key === "-" || e.key === "*")){
                                e.stopPropagation();
                                e.preventDefault();
                                const start = e.currentTarget.selectionStart;
                                const end = e.currentTarget.selectionEnd;
                                if(start !== null && end !== null) {
                                    let selectedText = e.currentTarget.value.slice(start, end);
                                    const t = e.currentTarget.value;
                                    selectedText = e.key === "+"
                                        ? getSuperScriptText(selectedText)
                                        : e.key === "-"
                                            ? getSubScriptText(selectedText)
                                            : getMathText(selectedText);

                                    const newText = `${t.slice(0, start)}${selectedText}${t.slice(end)}`;
                                    props.onChange({
                                        target: {
                                            name: props.name,
                                            value: typeof(props.value) === 'number' ? parseFloat(e.currentTarget.value) as T : newText as T
                                        }
                                    })
                                }
                            }
                        }}
                        disabled={props.disabled}
                        tabIndex={props.tabIndex}
                        autoFocus={props.tabIndex === 1}
                    />
                    <UnsavedChangesIcon show={showUnsaved}/>
                </>
            }
        </Translate>;
    const errors = props.errors && <div><FieldErrors errors={props.errors} fieldname={props.errorField || props.name} /></div>;

    return(
        <div className={`form-group ${props.inputIcon ? 'icon-group' : ''}`}>
            { label }
            { inputIcon }
            { input }
            { errors }
            { props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div> }
            <div className='clear-fix'/>
        </div>
    );
};

interface TagInputFieldProps {
    name: string;
    type: string;
    value: string;
    onChange: (event: ChangeEvent<string>) => void;
    errors?: false | ErrorType;
    errorField?: string;
    noLabel?: boolean;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    disabledHint?: string;
    tagState: TagState;
    tagActions: typeof TagStore.actionCreators;
    labelWidth100: boolean;
    original?: string;
}

interface TagInputFieldState {
    showSuggestions: boolean;
    activeSuggestion: string;
}

class TagInputFieldClass extends Component<TagInputFieldProps, TagInputFieldState>{

    constructor(props: TagInputFieldProps){
        super(props);

        this.state = {
            showSuggestions: false,
            activeSuggestion: ''
        }
    }

    render(){
        const props = this.props;

        const showUnsaved = props.original !== undefined && props.original !== props.value;

        const label = props.noLabel || <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}><Translate id={props.label || props.name} /></label>;
        const tags = props.value.split(",").filter(t => t);

        const suggestions = props.tagState.currentSuggestions.filter(sug => !tags.find(t => t === sug));
        if(
            props.tagState.typedName &&
            !suggestions.find(s => s === props.tagState.typedName) &&
            !tags.find(t => t === props.tagState.typedName))
        {
            suggestions.push(props.tagState.typedName);
        }

        const onChange = (event: ChangeEvent<string>) => {
            props.tagActions.updateTypedName(event.target.value.toLowerCase());
            this.setState({showSuggestions: true});
        };

        const keyDown = (event: KeyboardEvent<HTMLInputElement>) => {
            switch (event.key) {
                case 'ArrowDown':{
                    if(!this.state.activeSuggestion){
                        this.setState({activeSuggestion: suggestions[0]});
                    }
                    else{
                        let i = suggestions.indexOf(this.state.activeSuggestion) + 1;
                        if(i === suggestions.length){
                            i = 0;
                        }
                        this.setState({activeSuggestion: suggestions[i]})
                    }
                    return;
                }
                case 'ArrowUp': {
                    if(!this.state.activeSuggestion){
                        this.setState({activeSuggestion: suggestions[suggestions.length - 1]});
                    }
                    else{
                        let i = suggestions.indexOf(this.state.activeSuggestion) - 1;
                        if(i === -1){
                            i = suggestions.length - 1;
                        }
                        this.setState({activeSuggestion: suggestions[i]})
                    }
                    return;
                }
                case 'Enter':
                case '.':
                case ',':
                case ' ':{
                    event.preventDefault();
                    if(this.state.activeSuggestion && suggestions.find(s => s === this.state.activeSuggestion)){
                        addTag(this.state.activeSuggestion);
                        this.setState({activeSuggestion: ''});
                    }
                    else{
                        addTag(this.props.tagState.typedName);
                    }
                    return;
                }
                default:
            }
        };

        const onBlur = () => {
            //Wait a sec before hiding the list.
            setTimeout(
                () => this.setState({showSuggestions: false}),
                1000
            );

        };

        const onFocus = () => {
            if(props.tagState.typedName){
                this.setState({showSuggestions: true});
            }
        };

        const input =
            <Translate>
                { ({translate}) =>
                    <div>
                        <input
                            dir='auto'
                            name={props.name}
                            placeholder={props.placeholder ? translate(props.placeholder).toString() : ''}
                            className='form-control'
                            type={props.type}
                            value={props.tagState.typedName}
                            onChange={(event) => onChange({target: {name: props.name, value: event.target.value}})}
                            onKeyDown={keyDown}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            disabled={props.disabled}
                        />
                    </div>
                }
            </Translate>;
        const errors = props.errors && <div><FieldErrors errors={props.errors} fieldname={props.errorField || props.name} /></div>;

        const addTag = (name: string) => {
            //only add tags thats not already there
            if(tags.find(t => t === name)) return;

            const event: ChangeEvent<string> = {
                target: {
                    name: props.name,
                    value: `${props.value}${name},`
                }
            };
            props.tagActions.updateTypedName('');
            props.onChange(event);
        };

        const removeTag = (name: string) => {
            const value = props.value.replace(`${name},`, '');
            const event: ChangeEvent<string> = {
                target: {
                    name: props.name,
                    value: value
                }
            };

            props.onChange(event);
        };

        //filter out all suggestions this has already been tagged with

        const suggestionsDivs =
            suggestions &&
            suggestions.map(s =>
                <div
                    className={`${s === this.state.activeSuggestion ? 'active' : ''}`}
                    onClick={() => addTag(s)} key={`suggest-${s}`}
                    dir='auto'
                >
                    {s}
                </div>
            );
        const tagsDiv = tags.map(t => {
            if(t){
                return (
                    <div className="tag" key={`tagged-${t}`} onClick={() => removeTag(t)} dir='auto'>
                        {t}<FontAwesomeIcon className='tag-trash' icon='trash'/>
                    </div>
                )
            }
            return false
        });

        const suggestionsContainer = this.state.showSuggestions && (
            <div className={'autocomplete-list'} onMouseDown={(e) => {e.stopPropagation();}}>
                {suggestionsDivs}
            </div>
        );

        return(
            <div className={`form-group`}>
                { label }
                { input }
                { suggestionsContainer || false }
                <div className='taglist'>
                    { tagsDiv.length > 0 ? tagsDiv : <div className='no-tag-test'><Translate id='no_tags' /></div> }
                    <div className='clear-fix'/>
                </div>
                { errors }
                { props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div> }
                <UnsavedChangesIcon show={showUnsaved}/>
                <div className='clear-fix'/>
            </div>
        );
    }
}

export const TagInputField = connect(
    (state: AppState) => ({
        tagState: state.tagState
    }),
    dispatch => ({
        tagActions: bindActionCreators(TagStore.actionCreators, dispatch)
    })
)(TagInputFieldClass);

interface TextAreaType {
    name: string;
    value: string;
    onChange: (e: ChangeEvent<string>) => void;
    placeholder?: string;
    errors?: false | ErrorType;
    noLabel?: boolean;
    label?: string;
    tabIndex?: number;
    original?: string;
    labelWidth100?: boolean;
}
export const TextArea = (props: TextAreaType) => {

    const showUnsaved = props.original !== undefined && props.original !== props.value;

    return(
        <Translate>
            { ({translate}) =>
                <div className='form-group'>
                    { !props.noLabel && <label className={props.labelWidth100 ? 'full-width' : undefined} htmlFor={props.name}><Translate id={props.label || props.name} /></label> }
                    <textarea dir='auto' tabIndex={props.tabIndex} name={props.name} placeholder={props.placeholder ? translate(props.placeholder).toString() : ''}  className='form-control' value={props.value} onChange={(event) => props.onChange({target: {name: props.name, value: event.target.value}})} />
                    <UnsavedChangesIcon show={showUnsaved}/>
                    { props.errors &&
                    <div className="form-error"><FieldErrors errors={props.errors} fieldname={props.name} /></div>
                    }
                    <div className='clear-fix'/>
                </div>
            }
        </Translate>
    );
};

interface SubmitButtonType {
    split?: boolean;
    text?: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    className?: string;
}
export const SubmitButton = (props: SubmitButtonType) => {
    return(
        <div className={`${props.split ? 'btn-split-container': ''}`}>
            <button type='submit' className={`btn${props.split ? ' btn-split' : ''} ${props.className || ''}`} onClick={props.onClick} disabled={props.disabled}>
                <Translate id={props.text || 'submit'} />
            </button>
        </div>
    );
};

interface CheckBoxType {
    name: string;
    active: boolean;
    onChange: (e: {target: {name: string; value: boolean}}) => void;
    notLabel?: string;
    label?: string;
    hoverMsg?: string;
    className?: string;
    disabled?: boolean;
    disabledHint?: string;
    original?: boolean;
    icon?: IconProp;
    errors?: ErrorType;
}
export const CheckBox = (props: CheckBoxType) => {
    const showUnsaved = props.original !== undefined && props.original !== props.active;

    const event = {
        target:{
            name: props.name,
            value: Boolean(!props.active)
        }
    };

    const clicky = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        !props.disabled && props.onChange(event)
    };

    const label = props.label || props.name;
    return (
        <div className={`form-group ${props.className || ''}${showUnsaved ? ' unsaved-changes' : ''}`}  >
            <div className={`checkbox-container${props.disabled ? ' disabled' : ''}`} onClick={clicky}>
                <span className='checkbox-label'>
                    { props.icon && <FontAwesomeIcon icon={props.icon} />}
                    <Translate id={label} />
                </span>
                <span className={`checkbox-slide${props.active ? ' active' : ''}`}/>
            </div>
            { props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
            { props.hoverMsg && <p className='hover-msg'><Translate id={props.hoverMsg}/></p>}
            <UnsavedChangesIcon show={showUnsaved}/>
            { props.errors &&
            <div className="form-error"><FieldErrors errors={props.errors} fieldname={props.name} /></div>
            }
        </div>
    );
};

interface SelectBasic{
    labelName?: string;
    name: string;
    canAddNew?: boolean;
    newFunc?: () => void;
    onChange: (e: ChangeEvent<string>) => void;
    onBlur?: () => void;
    newText?: string;
    defaultValue?: string;
    maxOptions?: number;
    noLabel?: boolean;
    labelWidth100?: boolean;
    translate?: boolean;
    disabled?: boolean;
    disabledHint?: string;
    icon?: IconProp;
    className?: string;
    skipAutoSelect?: boolean;
    errors?: false | ErrorType;
    original?: string;
}

interface SelectListType extends SelectBasic{
    options: {name: string; value: string; translate?: boolean}[];
}
export const SelectList = (props: SelectListType) => {
    const max = props.maxOptions || 99999999;
    const labelName = !props.noLabel ? (props.labelName || props.name) : "";
    const showUnsaved = props.original !== undefined && props.original !== props.defaultValue;


    useEffect(() => {
        if(!props.skipAutoSelect){
            if((!props.canAddNew || props.options.length >= max) && !props.options.find(x => x.value === props.defaultValue)){
                if(props.options.length > 0){
                    props.onChange({target:{name: props.name, value: props.options[0].value}});
                }
            }
        }
        //eslint-disable-next-line
    }, [])

    return(
        <Translate>
            {({translate}) =>
                <div className='form-group'>
                    {!props.noLabel &&
                        <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}>{translate(labelName)}</label>
                    }
                    {props.icon && <FontAwesomeIcon icon={props.icon} className='icon' />}
                    <select
                        name={props.name}
                        className={`form-control ${props.className}${showUnsaved ? ' unsaved-changes' : ''}`}
                        onChange={(event) => {
                            if(event.target.value === "--new-opt--" && props.newFunc){
                                props.newFunc();
                            }
                            else{
                                props.onChange({target: {name: props.name, value: event.target.value}})
                            }
                        }}
                        defaultValue={props.defaultValue || (props.canAddNew ? '' : ((props.options[0] && props.options[0].value) || ''))}
                        disabled={props.disabled}
                        onBlur={props.onBlur}
                        dir='auto'
                    >
                        {props.canAddNew &&
                            (props.options.length < max ? <option value={"--new-opt--"}>{translate(props.newText || '')}</option> : undefined)
                        }
                        {
                            props.options
                                .map(x => { return {name: x.translate || props.translate ? translate(x.name) : x.name, value: x.value}})
                                .map(x => {
                                    return (
                                        <option key={x.value} value={x.value} dir='auto'>
                                            {x.name}
                                        </option>
                                    )
                                })
                        }
                    </select>
                    <UnsavedChangesIcon show={showUnsaved}/>
                    {props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
                    { props.errors &&
                        <div className="form-error"><FieldErrors errors={props.errors} fieldname={props.name} /></div>
                    }
                </div>
            }
        </Translate>
    );
};

interface SelectLanguageType extends SelectBasic {
    gamesState: GamesState;
}
const SelectLanguageElement = (props: SelectLanguageType) => {
    const topChoice = {name: "lang_choose", value: "", translate: true};
    const languages = [topChoice, ...props.gamesState.languageCodes];

    const newProps = {...props, options: languages};
    return <SelectList {...newProps} />
};

export const SelectLanguage = connect(
    (state: AppState) => ({
        gamesState: state.gamesState
    })
)(SelectLanguageElement);


interface SelectMultipleLanguageType extends BaseSelectFromPoolType {
    gamesState: GamesState;
    localizeState: LocalizeState;
}
const SelectMultipleLanguageElement = (props: SelectMultipleLanguageType) => {
    const translate = getTranslate(props.localizeState);
    const topChoice = {name: translate("lang_choose").toString(), value: ""};
    const languages = [topChoice, ...props.gamesState.languageCodes];

    const newProps = {...props, pool: languages, gamesStates: undefined, localizeState: undefined, tagClass: 'language'};

    return <SelectFromPool {...newProps}/>
};

export const SelectMultipleLanguage = connect(
    (state: AppState) => ({
        gamesState: state.gamesState,
        localizeState: state.localize
    })
)(SelectMultipleLanguageElement);



interface ButtonType {
    className?: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    name?: string;
    link?: string;
    icon?: IconProp;
    children?: ReactNode;
    disabled?: boolean;
    blank?: boolean;
}
export const Button = (props: ButtonType) => {
    if(props.link && !props.disabled){
        return(
            <Link target={props.blank? '_blank' : undefined} to={{pathname: props.link}} onClick={props.onClick}>
                <button type='button' className={`btn${props.className ? ` ${props.className}` : ''}`} disabled={props.disabled}>
                    {props.icon && <FontAwesomeIcon icon={props.icon} />}
                    {props.name && <Translate id={props.name} /> }
                    {props.children && props.children}
                </button>
            </Link>
        )
    }

    return(
        <button type='button' className={`btn${props.className ? ` ${props.className}` : ''}`} onClick={props.onClick} disabled={props.disabled}>
            {props.icon && <FontAwesomeIcon icon={props.icon} />}
            {props.name && <Translate id={props.name} /> }
            {props.children && props.children}
        </button>
    )
}

interface FormButtonType extends ButtonType{
    removeButtonOnClick?: (e: MouseEvent<HTMLElement>) => void;
}

export const FormButton = (props: FormButtonType) => {
    return(
        <div className={`form-group ${props.className || ''}`}>
            { props.removeButtonOnClick
                ?
                <div className='btn-icon-group'>
                    <Button {...props} />
                    <span className='icon' onClick={props.removeButtonOnClick}>
                        <FontAwesomeIcon icon='trash' />
                    </span>
                </div>
                :
                <Button {...props} />
            }
        </div>
    );
};

interface ImageFormButtonType {
    className?: string;
    name?: string;
    imageSubmit: (e: ImageUploadType|undefined) => void;
    previewWidth: number;
    previewHeight: number;
    minRatio?: number;
    maxRatio?: number;
    unsavedChange?: boolean;
    noRemove?:  boolean;
    forceFixedAspect?: boolean;
}
interface ImageFormButtonState{
    showImageForm?: boolean;
}
export const ImageFormButton = (props: ImageFormButtonType) => {

    const [state, setState] = useState<ImageFormButtonState>({});

    return(
        <>
            <div className={`form-group ${props.className || ''}${props.unsavedChange ? ' unsaved-canges' : ''}`}>
                <div className='image-button-quick'>
                    <QuickImage
                        onSubmit={props.imageSubmit}
                        previewWidth={props.previewWidth}
                        previewHeight={props.previewHeight}
                        aspectRatio={props.previewWidth/props.previewHeight}
                        maxRatio={props.maxRatio}
                        minRatio={props.minRatio}
                        forceFixedAspect={props.forceFixedAspect}
                    />
                    <button className='btn' type='button' onClick={() => setState({...state, showImageForm: true})}>
                        { props.name && <Translate id={props.name} /> }
                    </button>
                    {!props.noRemove &&
                    <span onClick={() => props.imageSubmit(undefined)}>
                    <FontAwesomeIcon icon='trash' className='remove-icon'/>
                </span>
                    }
                    <div className='clear-fix' />
                </div>
                <UnsavedChangesIcon show={!!props.unsavedChange}/>
            </div>
            {state.showImageForm &&
                <Dialog onClose={() => setState({...state, showImageForm: false})}>
                    <ImageForm
                        onSubmit={img => {
                            props.imageSubmit(img);
                            setState({...state, showImageForm:false});
                        }}
                        aspectRatio={props.previewWidth/props.previewHeight}
                        previewHeight={props.previewHeight}
                        previewWidth={props.previewWidth}
                        minRatio={props.minRatio}
                        maxRatio={props.maxRatio}
                        forceFixedAspect={props.forceFixedAspect}
                    />
                </Dialog>
            }
        </>
    )
};

interface AudioFormButtonType {
    className?: string;
    name?: string;
    audioSubmit: (audio: SoundUploadType|undefined) => void;
    unsavedChange?: boolean;
    accept?: string;
}
export const AudioFormButton = (props: AudioFormButtonType) => {
    const {className, name, audioSubmit, unsavedChange, accept} = props;
    const [showForm, setShowForm] = useState(false);

    return(
        <>
            <div className={`form-group ${className || ''}${unsavedChange ? ' unsaved-canges' : ''}`}>
                <div className='image-button-quick'>
                    <QuickAudio onSubmit={audioSubmit} accept={accept} />
                    <button type='button' className='btn' onClick={() => setShowForm(true)}>
                        { name && <Translate id={props.name} /> }
                    </button>
                    <span onClick={() => audioSubmit(undefined)}>
                        <FontAwesomeIcon icon='trash' className='remove-icon' />
                    </span>
                    <div className='clear-fix' />
                </div>
                <UnsavedChangesIcon show={!!unsavedChange}/>
            </div>
            { showForm &&
                <Dialog onClose={() => setShowForm(false)}>
                    <SoundForm
                        accept={accept}
                        onSubmit={a => {
                            audioSubmit(a);
                            setShowForm(false);
                        }}
                    />
                </Dialog>
            }
        </>
    )
};

interface SliderType {
    min: number;
    max: number;
    name: string;
    value: number;
    step?: number;
    onChange: (e: ChangeEvent<number>) => void;
    className?: string;
    hoverMsg?: string;
    original?: number;
    showLabel?: boolean;
}
export const Slider = (props: SliderType) => {
    const showUnsaved = props.original !== undefined && props.original !== props.value;

    const [value, setValue] = useState(props.value);
    const [t, setT] = useState<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if(props.value !== value) setValue(props.value);
        // eslint-disable-next-line
    }, [props.value]);

    const fireDelayedChange = (newValue: number) => {
        if(t) clearTimeout(t);
        const ti = setTimeout(() => triggerChangeEvent(newValue), 500);
        setT(ti);
    };

    const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(e.target.value, 10) || 0;
        setValue(newValue);
        fireDelayedChange(newValue);
    };

    const triggerChangeEvent = (newValue: number) => {
        const cappedValue = Math.max(props.min, Math.min(props.max, newValue));
        props.onChange({
            target: {
                name: props.name,
                value: cappedValue
            }
        });
        setValue(cappedValue)
    };


    return(
        <div className={`form-group ${props.className || ''}`}>
            {props.showLabel &&
            <label><Translate id={props.name} /></label>
            }
            <div className={`form-control slider${showUnsaved ? ' unsaved-changes' : ''}`}>
                <input
                    className='number'
                    value={value}
                    onChange={inputChange}

                />
                <input type='range'
                    step={props.step || 1}
                    min={props.min}
                    max={props.max}
                    value={props.value}
                    name={props.name}
                    onChange={(e) => triggerChangeEvent(parseInt(e.target.value, 10))}
                />
                <UnsavedChangesIcon show={showUnsaved}/>
            </div>
            { props.hoverMsg && <p className='hover-msg'><Translate id={props.hoverMsg}/></p>}
            <div className='clear-fix'/>
        </div>
    );
};

interface ColorPickerType {
    colorPickerClass?: string;
    colorValue: string;
    colorPickerToggleClass?: string;
    onColorChange: (event: ChangeEvent<string>) => void;
    name?: string;
    className? : string;
    original?: string;
    label?: string;
}
export const ColorPicker = (props: ColorPickerType) => {
    const [showPicker, setShowPicker] = useState(false);
    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    };
    let colorPicker = undefined;

    const showUnsaved = props.original !== undefined && props.original !== props.colorValue;


    if (showPicker){
        colorPicker = (
            <div>
                <div style={cover} onClick={(e) => { e.stopPropagation(); setShowPicker(false); }} />
                <div className={props.colorPickerClass || ''}>
                    <ChromePicker
                        color={props.colorValue}
                        onChangeComplete={(color) => props.onColorChange({ target: { name: props.name || 'fontColorHex', value: color.hex } })}
                        disableAlpha
                    />
                </div>
            </div>
        );
    }

    return(
        <div className={`form-group ${props.className ? ` ${props.className}` : ''}${props.label ? ' color-group' : ''}`}>
            {props.label && <label><Translate id={props.label} /></label>}
            <div className='font-color-container'>
                <div className={`font-color ${props.colorPickerToggleClass || ''}`} onClick={() => setShowPicker(true)} >
                    <FontAwesomeIcon className='font-color-icon' icon='palette' style={{color: props.colorValue}}/>
                    {colorPicker}
                    <UnsavedChangesIcon show={showUnsaved}/>
                </div>
            </div>
        </div>
    );
};

interface ColorAndFontType {
    colorPickerClass?: string;
    colorPickerToggleClass?: string;
    colorValue: string;
    onColorChange: (e: ChangeEvent<string>) => void;
    fontFamilyPickerClass?: string;
    fontFamilyValue: string;
    onFontFamilyChange: (e: ChangeEvent<string>) => void;
    className?: string;
    originalColor?: string;
    originalFontFamily?: string;
}
export const ColorAndFont = (props: ColorAndFontType) => {

    const [showPicker, setShowPicker] = useState(false);
    const showUnsaved =
        (props.originalColor !== undefined && props.originalColor !== props.colorValue) ||
        (props.originalFontFamily !== undefined && props.originalFontFamily !== props.fontFamilyValue);

    const cover: CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    };
    let colorPicker = undefined;
    if (showPicker){
        colorPicker = (
            <div>
                <div className='cover-overlay' style={cover} onClick={(e) => { e.stopPropagation(); setShowPicker(false) }} />
                <div className={props.colorPickerClass || ''}>
                    <ChromePicker
                        color={props.colorValue}
                        onChangeComplete={(color) => props.onColorChange({ target: { name: 'fontColorHex', value: color.hex } })}
                    />
                </div>
            </div>
        );
    }
    
    return(
        <div className={`form-group color-group ${props.className || ''}`}>
            <div className='font-color-container'>
                <div className={`font-color ${props.colorPickerToggleClass || ''}`} onClick={() => setShowPicker(true)} >
                    <FontAwesomeIcon className='font-color-icon' icon='palette' style={{color: props.colorValue}}/>
                    {colorPicker}
                </div>
            </div>
            <select
                className={`form-control font-family ${props.fontFamilyPickerClass || ''} ${showUnsaved ? ' unsaved-changes' : ''}`}
                value={props.fontFamilyValue}
                onChange={(event) => props.onFontFamilyChange({target: {name: 'fontFamily', value: event.target.value}})}
            >
                {FontOptions.map((f,i) => <option key={i} value={f.value} style={{fontFamily: f.value}}>{f.name}</option>)}
            </select>
            <UnsavedChangesIcon show={showUnsaved}/>
        </div>
    );
};



interface ImageRadioGroupType {
    radioButtonWidth: number;
    radioButtonHeight: number;
}
export const ImageRadioGroup = (props: ImageRadioGroupType) => {
    return(
      <div>

      </div>
    );
}

interface DatePickerWrappedType {
    name: string;
    label?: string;
    value: Moment;
    noLabel?: boolean;
    onChange: (e: ChangeEvent<Moment>) => void;
    labelWidth100?: boolean;
    errors?: false | ErrorType | FetchError;
}
export const DatePickerWrapped = (props: DatePickerWrappedType) => {
    const event = {
        target: {
            name: props.name,
            value: props.value,
        }
    };

    const errors = props.errors && <div><FieldErrors errors={props.errors} fieldname={props.name} /></div>;

    return(
        <div className='form-group date-picker'>
            {!props.noLabel &&
                <label className={props.labelWidth100 ? 'full-width' : undefined} htmlFor={props.name}>
                    <Translate id={props.label || props.name}/>
                </label>
            }
            {errors}
            <DatePicker
                className='form-control'
                name={props.name}
                selected={props.value.toDate()}
                dateFormat={'yyyy/MM/dd'}
                onChange={e => props.onChange({...event, target: {...event.target, value: moment(Array.isArray(e) ? undefined : e || undefined).startOf('day')}})}
                dropdownMode='scroll'
            />
        </div>
    );
};

interface DurationPickerType {
    name: string;
    placeholder?: string;
    value: number;
    noLabel?: boolean;
    onChange: (e: ChangeEvent<string>) => void;
    timeUnit: string;
    errors?: false | ErrorType;
    labelWidth100?: boolean;
}
export const DurationPicker = (props: DurationPickerType) => {
    return(
        <Translate>
            {({translate}) =>
                <div className='form-group'>
                    {!props.noLabel &&
                        <label className={props.labelWidth100 ? 'full-width' : ''} htmlFor={props.name}><Translate id={props.name}/></label>
                    }
                    <input
                        name={props.name}
                        placeholder={props.placeholder ? translate(props.placeholder).toString() : ''}
                        className='form-control'
                        type='number'
                        value={props.value}
                        onChange={event =>
                        {
                            props.onChange(
                                {target: {name: props.name, value: event.target.value}}
                            );
                        }}
                    />
                    { props.errors &&
                    <div className="form-error"><FieldErrors errors={props.errors} fieldname={props.name} /></div>
                    }
                </div>
            }
        </Translate>
    )
};

interface InputToListProps {
    name: string;
    value: string[];
    onChange: (event: ChangeEvent<string[]>) => void;
    placeholder: string;
    type: 'text' | 'number';
    labelName?: string;
    labelWidth100?: boolean;
    translatePre?: string;
    translateAppendValue?: string;
}
interface InputToListState {
    inputValue: string | undefined;
}
export class InputToList extends Component<InputToListProps, InputToListState> {

    constructor(props: InputToListProps){
        super(props);

        this.state = {inputValue: ''};
    }

    render(){
        const props = this.props;

        const inputChange = (value: string | undefined) => {
            this.setState({inputValue: value});
        };

        const keyDown = (event: KeyboardEvent<HTMLInputElement>) => {
            switch (event.key) {
                case 'Enter':
                case '.':
                case ',':
                case ' ':{
                    event.preventDefault();
                    if(this.state.inputValue && -1 === props.value.findIndex(v => v === this.state.inputValue)){
                        const newValues = [...props.value, this.state.inputValue];
                        props.onChange({target: {name: props.name, value: newValues}});
                    }
                    this.setState({inputValue: ''});
                    return;
                }
                default:
            }
        };

        const onRemove = (value: string) => {
            const newSelected = props.value.filter(y => y !== value);

            props.onChange({
                target:{
                    name: props.name,
                    value: newSelected
                }
            });
        };

        const label = props.labelName ?
            <label className={props.labelWidth100 ? 'full-width' : ''}>
                <Translate id={props.labelName}/>
            </label> :
            null;

        const input =  (
            <Translate>
                {({translate}) =>
                    <input
                        placeholder={props.placeholder ? translate(props.placeholder).toString() : ''}
                        className='form-control'
                        type={props.type}
                        onChange={(e) => inputChange(e.target.value.toString())}
                        onKeyDown={keyDown}
                        value={this.state.inputValue}
                    />
                }
            </Translate>
        );

        const selectedDivs = (
            <div className='select-pool'>
                {props.value && props.value.map(x => {
                    return (
                        <div
                            key={x}
                            className='option remove'
                            onClick={() => onRemove(x)}
                        >
                            {x}{props.translateAppendValue ? <Translate  id={`${props.translateAppendValue}`}/> : ''}
                            <FontAwesomeIcon className='icon' icon='trash' />
                        </div>
                    );
                })}
                <div className='clear-fix' />
            </div>
        );

        return (
            <div className='form-group select-pool'>
                { label }
                { input }
                { selectedDivs }
            </div>
        )
    }
}


interface BaseSelectFromPoolType{
    name: string;
    selected: string[];
    onChange: (event: ChangeEvent<string[]>) => void;
    placeholder?: string;
    translatePre?: string;
    translateAppendValue?: string;
    labelWidth100?: boolean;
    noLabel?: boolean;
    labelName?: string;
    disabled?: boolean;
    disabledHint?: string;
    translateName?: boolean;
    translateValue?: boolean;
    subjectTranslateHack?: boolean;
}

interface SelectFromPoolType extends BaseSelectFromPoolType{
    pool: {name: string; value: string}[];
    tagClass?: string;
}

export const SelectFromPool = (props: SelectFromPoolType) => {
    const selected = props.selected;
    const pool = props.pool.filter(x => !selected.includes(x.value));

    const onRemove = (value: string) => {
        if(props.disabled) return;
        const newSelected = selected.filter(y => y !== value);

        props.onChange({
            target:{
                name: props.name,
                value: newSelected
            }
        })
    };

    const onAdd = (value: string) => {
        if(props.disabled) return;
        if(!value)return;
        const newSelected = [...selected, value];

        props.onChange({
            target:{
                name: props.name,
                value: newSelected
            }
        })
    };

    const label =  props.noLabel ? null : (
        <label className={`${props.labelWidth100 ? 'full-width' : ''}`}>
            <Translate id={props.labelName}/>
        </label>
    );

    /**
     * SubjectTranslateHack only translates the value if its found in the subject array,
     * this is to avoid language subject translation
     */
    const selectedDivs = (
        <div className='select-pool'>
            {selected && selected.map(x => {
                return (
                    <div
                        key={x}
                        className={`option remove${props.disabled ? ' disabled' : ''}${props.tagClass ? ` tag ${props.tagClass}` : ''}`}
                        onClick={() => onRemove(x)}
                        dir='auto'
                    >
                        {props.translatePre || props.translateValue ?
                            props.subjectTranslateHack && courseList.findIndex(c => c === x) === -1 ?
                                x : <Translate  id={`${props.translatePre || ''}${x}`}/> :
                            x
                        }
                        {props.translateAppendValue ? <Translate  id={`${props.translateAppendValue}`}/> : ''}
                        <FontAwesomeIcon className='icon' icon='trash' />
                    </div>
                );
            })}
            <div className='clear-fix' />
        </div>
    );

    const selectList = (
            <Translate>
                {({translate}) =>
                    <select className='form-control' disabled={props.disabled} onChange={e => onAdd(e.target.value)}>
                        {props.placeholder && <option>{translate(props.placeholder)}</option> }
                        {pool && pool.map(x => {
                            return (
                                <option
                                    key={x.value}
                                    value={x.value}
                                    dir='auto'
                                >
                                    {props.translatePre || props.translateName ? translate(`${props.translatePre || ''}${x.name}`) : x.name}
                                </option>
                            );
                        })}
                    </select>
                }
            </Translate>
    );

    return(
        <div className='form-group select-pool'>
            { label }
            { selectList }
            { selectedDivs }
            {props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
        </div>
    );
};

interface AgeRangeSelectType {
    minAge: number;
    maxAge: number;
    onChange: (event: ChangeEvent<number>) => void;
    labelWidth100?: boolean;
    noLabel?: boolean;
    labelName?: string;
    disabled?: boolean;
    disabledHint?: string;
    originalMin?: number;
    originalMax?: number;
}

const RCRange = createSliderWithTooltip(RangeType);

const MIN_AGE = 0;
const MAX_AGE = 50;

export const AgeRangeSelect = (props: AgeRangeSelectType) => {

    const label =  props.noLabel ? null : (
        <label className={`${props.labelWidth100 ? 'full-width' : ''}`}>
            <Translate id={props.labelName}/>
        </label>
    );

    const showUnsaved =
        (props.originalMin !== undefined && props.originalMin !== props.minAge) ||
        (props.originalMax !== undefined && props.originalMax !== props.maxAge);

    const value = [
        props.minAge === undefined || props.minAge === null ? MIN_AGE : props.minAge,
        props.maxAge === undefined || props.maxAge === null ? MAX_AGE : props.maxAge
    ];

    const input = (
        <Translate>
            {({translate}) =>
                <div>
                    <RCRange
                        min={MIN_AGE}
                        max={MAX_AGE}
                        value={value}
                        disabled={props.disabled}
                        tipFormatter={( value: number ) => `${value}${translate('years_old')}`}
                        onChange={(values) => {
                            props.onChange({target:{name:'minAge', value: values[0]}});
                            props.onChange({target:{name:'maxAge', value: values[1]}});
                        }}
                    />
                    <input
                        className='form-control'
                        type='number'
                        min={MIN_AGE}
                        max={value[1]}
                        value={value[0]}
                        disabled={props.disabled}
                        onChange={(e) => {
                            props.onChange({target:{name:'minAge', value: Number(e.target.value)}});
                        }}
                        style={{width: '25%', float: 'left'}}
                    />
                    <input
                        className='form-control'
                        type='number'
                        min={value[0]}
                        max={MAX_AGE}
                        value={value[1]}
                        disabled={props.disabled}
                        onChange={(e) => {
                            props.onChange({target:{name:'maxAge', value: Number(e.target.value)}});
                        }}
                        style={{width: '25%', float: 'right'}}
                    />
                    <UnsavedChangesIcon show={showUnsaved}/>
                    <div className='clear-fix'/>
                </div>
            }
        </Translate>
    );


    return(
        <div className='form-group select-pool'>
            { label }
            {props.disabled && props.disabledHint && <div className='hover-msg'><Translate id={props.disabledHint} /></div>}
            { input }
        </div>
    );
};

const UnsavedChangesIcon = (props: {show: boolean}) => {
    if(!props.show) return null;
    return (
    <>
        <div className='changes-icon'>
            <FontAwesomeIcon icon='info-circle'/>
        </div>
        <div className='hover-msg unsaved-changes'><Translate id='unsaved_changes'/></div>
    </>
    )
};