import React, {Component} from 'react';
import {actionCreators as stfActions, SmackTheFlyState} from "../../../store/games/SmackTheFlyStore";
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";
import {Loading} from "../../Loading";
import SmackTheFlyRoundForm from "./SmackTheFlyRoundForm";
import GameDetailed from "../../ModelPreview/GameDetailed";
import SmackTheFlySettings from "./SmackTheFlySettings";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import SmackTheFlyGamePreview from "./SmackTheFlyGamePreview";
import {ErrorState} from "../../../store/Error";
import {LayoutComponentProps} from "../../Layout";

interface Props extends LayoutComponentProps<{id: string}>{
    actions: typeof stfActions;
    smackthefly: SmackTheFlyState;
    myGamesActions: typeof myGamesActions;
    errors: ErrorState['smackthefly'];
}

class SmackTheFly extends Component<Props> {

    constructor(props: Props){
        super(props);
        props.actions.reset();
    }
    
    render(){
        const props = this.props;
        const game = props.smackthefly.game;

        return(
            <EditGameComponent
                gameState={props.smackthefly}
                newGameElementFunction={() => {props.actions.setEditRound(null, -1)}}
                newGameElementText={'smackthefly_create_round'}
                routeMatch={props.match}
                gameType={'SmackTheFly'}
                setLayoutButtons={props.setLayoutButtons}
            >
                <Loading visible={props.smackthefly.isLoading.sortRounds}/>
                <GameDetailed
                    game={game}
                    tempDescription={
                        <div>
                            <p><Translate id='smackthefly_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/en/portfolio-item/smack-the-fly/"><Translate id='smackthefly_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='smackthefly_title'/>}
                    onClick={() => this.props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.smackthefly.gameType}
                    gameStats={props.smackthefly.gameStats}
                />
                <div className='clear-fix' />

                {game &&
                    <SmackTheFlyGamePreview
                        game={game}
                        onSort={props.actions.setSort}
                        onSortCommit={props.actions.updateSortCommit}
                        onRoundClick={(r,i) => props.actions.setEditRound(r,i)}
                    />
                }
                <SmackTheFlyRoundForm />
                <SmackTheFlySettings/>

            </EditGameComponent>
        )
    }
}

export default connect(
    (state: AppState) => ( {
        smackthefly: state.smackthefly,
        errors: state.errors.smackthefly
    } ),
    dispatch => ({
        actions: bindActionCreators(stfActions, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch)
    })
)(SmackTheFly);