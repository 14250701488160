import React, {Component} from 'react';
import {connect} from "react-redux";
import * as LibraryStore from "../../store/LibraryStore";
import * as FeaturedStore from '../../store/FeaturedGameStore';
import {AuthorList, GameList, LibraryState} from "../../store/LibraryStore";
import * as GameStore from "../../store/games/Games";
import * as PlaylistStore from '../../store/PlayListStore';
import {PlayListState} from '../../store/PlayListStore';
import * as OrganizationStore from '../../store/OrganizationStore';
import {bindActionCreators} from "redux";
import Game from "../ModelPreview/Game";
import ComponentSlider from "./ComponentSlider";
import {Loading} from "../Loading";
import Author from "../ModelPreview/Author";
import {DictGetValues} from "../../services/JsDict";
import {history} from '../../index';
import {Translate} from "react-localize-redux";
import {AppState} from "../../store/configureStore";
import GameSliderPreview from "./GameSliderPreview";
import {getFullName, isAccountType} from "../../model/AccountType";
import {OrganizationName} from "./OrganizationName";
import {AccountPopularGames} from "./AccountPopularGames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, RouteComponentProps} from 'react-router-dom';
import FeaturedGameView from "./FeaturedGameView";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from "pure-react-carousel";
import {MeState} from "../../store/Me";
import { getLanguageNames } from '../../localization/countryName';

interface Props extends RouteComponentProps{
    libraryActions: typeof LibraryStore.actionCreators;
    playlistActions: typeof PlaylistStore.actionCreators;
    orgActions: typeof OrganizationStore.actionCreators;
    featuredActions: typeof FeaturedStore.actionCreators;
    meState: MeState;
    library: LibraryState;
    playlist: PlayListState;
    gamesState: GameStore.GamesState;
    organizationState: OrganizationStore.OrganizationState;
    featuredState: FeaturedStore.FeaturedGameState;
}

type AuthorIndexNames = "ColleaguesIndex" | "PublicIndex" | "FollowingsIndex";

interface State{
    ColleaguesIndex?: number;
    PublicIndex?: number;
    FollowingsIndex?: number;
}

class Discover extends Component<Props,State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        this.props.featuredActions.getRelevant();
        this.props.libraryActions.setGamePreview(null, -1);
        this.props.playlistActions.getPlayLists();
        this.props.libraryActions.getStaticGames(0);
        this.props.libraryActions.getOrganizationGames(1);
        this.props.libraryActions.getPublicGames(2);
        this.props.libraryActions.getAccountFollowingsGames(4);
        this.props.libraryActions.getAuthorListOrganization();
        this.props.libraryActions.getRandomPublicAuthors();
        this.props.libraryActions.getAccountFollowings();
        this.props.libraryActions.getSameCountryGames();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if(
            JSON.stringify(this.props.meState.account?.followings)
            !== JSON.stringify(prevProps.meState.account?.followings)
        ){
            this.props.libraryActions.getAccountFollowings();
            this.props.libraryActions.getAccountFollowingsGames(4);
        }
    }

    render(){
        const props = this.props;

        const renderAuthorRow = (authorList: AuthorList | null | undefined, indexName: AuthorIndexNames, activeIndex?: number) => {
            if(!authorList || !authorList.authors) return;
            const authors = DictGetValues(authorList.authors) || [];
            const name = authorList.name || '';
            const hoverAuthor = authors && activeIndex !== undefined && authors[activeIndex];
            return(
                <div className='row row-author'>
                    <Loading visible={authorList.loading}/>
                    <h2 dir='auto'><Translate id={name}/></h2>
                    <ComponentSlider width={100} height={116}>
                        {authors && authors.map((author, i) => {
                            if(!author.firstname) return null;
                            return <Author
                                key={i}
                                account={author}
                                onClick={() => this.setState({[indexName]: i})}
                            />;
                        })}
                    </ComponentSlider>
                    {hoverAuthor &&
                        <div className='author-card'>
                            <div className='close-x' onClick={() => this.setState({[indexName]: undefined})} ><FontAwesomeIcon icon='times' /></div>
                            <div className='left'>
                                <div className='account-title'>
                                    <div className='author-name' onClick={() => { history.push(`/library/profile/${hoverAuthor.id}`)}}>
                                        {getFullName(hoverAuthor)}
                                        <span className='to-public'><Translate id='account_public' /> &gt;</span>
                                    </div>
                                    <Link to={`/discover/org/${hoverAuthor.organizationId}`}>
                                        <OrganizationName
                                            orgActions={props.orgActions}
                                            orgId={hoverAuthor.organizationId}
                                            miniOrg={props.organizationState.minimalOrgs[hoverAuthor.organizationId]}
                                        />
                                    </Link>

                                </div>

                                <div className='author-description'>
                                    {isAccountType(hoverAuthor) ? hoverAuthor.description : "-"}
                                </div>
                                <div className='popular-games'>
                                    <AccountPopularGames
                                        libActions={props.libraryActions}
                                        accId={hoverAuthor.id}
                                        games={props.library.accountPopularGames[hoverAuthor.id]}
                                        loading={props.library.loading.getAccountPopularGames === hoverAuthor.id}
                                    />
                                </div>
                            </div>
                            <div className='right'>
                                <Author account={hoverAuthor} fullImage={true} hideName />
                                <div className='clear-fix' />
                            </div>
                            <div className='clear-fix'/>
                        </div>
                    }
                </div>
            )
        };

        const renderGameRow = (gameList: GameList | null, hasPreview: boolean, row: number, translationData?: {[key: string]: string}) => {
            if(!gameList) return;
            const games = gameList.games || [];
            const name = gameList.name || '';
            if(!gameList.loading && games.length === 0) return;
            return(
                <div className='row' id={`row-${row}`} key={`row-${row}`}>
                    <Loading visible={gameList.loading}/>
                    <h2>{name && <Translate id={name} data={translationData}/>}</h2>
                    {games && hasPreview &&
                    <div className={`game-slider-preview-wrapper`}>
                        <GameSliderPreview
                            closeFunction={() => props.libraryActions.setGamePreview(null, -1)}
                            scrollToOnMount={`row-${row}`}
                        />
                    </div>
                    }
                    <ComponentSlider width={220} height={320} className='game-row'>
                        {games && games.map(game => {
                            return <Game
                                autoScale
                                className={
                                    props.library.gamePreview &&
                                    props.library.gamePreview.id === game.id
                                        ? 'selected'
                                        : ''
                                }
                                menuHideEdit
                                key={game.id} 
                                game={game}
                            />
                        })}
                    </ComponentSlider >
                </div>
            )
        };

        return(
            <div className='discover'>
                {props.featuredState.relevant && props.featuredState.relevant.length > 0 &&
                <CarouselProvider
                    naturalSlideHeight={400}
                    naturalSlideWidth={1920}
                    isIntrinsicHeight
                    totalSlides={props.featuredState.relevant.length}
                    interval={5000}
                    dragEnabled={true}
                    isPlaying
                    infinite
                >
                    <Slider>
                        {props.featuredState.relevant.map((x,i) =>
                            <Slide key={x.id} index={i}><FeaturedGameView fg={x}/></Slide>
                        )}
                    </Slider>
                    {props.featuredState.relevant.length > 1 &&
                        <>
                            <ButtonNext className='carousel-btn next'>{'\u232A'}</ButtonNext>
                            <ButtonBack className='carousel-btn back'>{'\u2329'}</ButtonBack>
                        </>
                    }
                </CarouselProvider>
                }
                <div className='rows'>
                    {props.library.gamesByCountry && 
                        renderGameRow(
                            {...props.library.gamesByCountry, name: 'country_games'}, 
                            props.library.previewRow === 5,
                            5, 
                            { country: getLanguageNames(props.meState.account?.language, props.library.gamesByCountry.name) }
                        )
                    }
                    {renderGameRow(props.library.gameLists[1], props.library.previewRow === 1, 1)}
                    {renderGameRow(props.library.gameLists[4], props.library.previewRow === 4,4)}
                    {renderGameRow(props.library.gameLists[2], props.library.previewRow === 2, 2)}
                    {renderGameRow(props.library.gameLists[0], props.library.previewRow === 0,0)}
                    {renderAuthorRow(props.library.authorsFollowing, "FollowingsIndex", this.state.FollowingsIndex)}
                    {renderAuthorRow(props.library.authorsMyOrganization, "ColleaguesIndex", this.state.ColleaguesIndex)}
                    {renderAuthorRow(props.library.authorsPublic, "PublicIndex", this.state.PublicIndex)}
                </div>

            </div>
        );
    }
}

export default connect(
    (state: AppState) => ({
        library: state[LibraryStore.reducerName],
        gamesState: state[GameStore.reducerName],
        playlist: state[PlaylistStore.reducerName],
        organizationState: state[OrganizationStore.reducerName],
        featuredState: state.featured_game_store,
        meState: state.me
    }),
    dispatch => ({
        libraryActions: bindActionCreators(LibraryStore.actionCreators, dispatch),
        memoryActions: bindActionCreators(GameStore.actionCreators, dispatch),
        playlistActions: bindActionCreators(PlaylistStore.actionCreators, dispatch),
        orgActions: bindActionCreators(OrganizationStore.actionCreators, dispatch),
        featuredActions: bindActionCreators(FeaturedStore.actionCreators, dispatch)
    })
)(Discover)