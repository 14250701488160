import React, {Component, MouseEvent} from 'react';
import {Translate} from 'react-localize-redux';
import {bindActionCreators} from 'redux';
import {actionCreators} from '../../../store/Auth';
import {connect} from 'react-redux';
import {InputField, SubmitButton} from "../FormGroup";
import {AppState, ChangeEvent} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";

interface Props{
    errors: ErrorState['auth'];
    initialValues: {token?: string; email: string};
    authActions: typeof actionCreators;
}

interface State{
    password: string;
    passwordRepeat: string;
    token: string;
}

class ResetPasswordForm extends Component<Props, State>{

    constructor(props: Props){
        super(props);

        this.state = {
            password: '',
            passwordRepeat: '',
            token: this.props.initialValues.token ? this.props.initialValues.token : '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e: MouseEvent<HTMLElement>){
        e.preventDefault();
        this.props.authActions.resetPassword(this.state.token, this.state.password, this.state.passwordRepeat);
    }

    handleChange(event: ChangeEvent<string>) {
        const name = event.target.name;
        if(name === "password"){
            this.setState({ password : event.target.value});
        }
        else if(name === "passwordRepeat"){
            this.setState({ passwordRepeat : event.target.value});
        }

    }

    render(){

        let loginError = null;
        if(this.props.errors.resetPassword){

            if(this.props.errors.resetPassword.status === 401){
                loginError = <div className='form-error'><Translate id='token_expired'/></div>
            }
        }

        return (
            <div>
                <h1><Translate id='choose_new_password'/></h1>
                <form>
                    <InputField
                        name={'password'}
                        type={'password'}
                        value={this.state.password}
                        onChange={this.handleChange}
                        placeholder={'password'}
                        inputIcon={'lock'}
                        errors={this.props.errors.resetPassword}
                        noLabel
                    />
                    <InputField
                        name={'passwordRepeat'}
                        type={'password'}
                        value={this.state.passwordRepeat}
                        onChange={this.handleChange}
                        placeholder={'password_repeat'}
                        inputIcon={'lock'}
                        errors={this.props.errors.resetPassword}
                        noLabel
                    />

                    {loginError}
                    <SubmitButton text='reset_password' onClick={this.handleSubmit} split/>
                </form>
            </div>
        );
    }
}


export default connect(
    (state: AppState) => ({errors: state.errors.auth}),
    dispatch => ({
        authActions: bindActionCreators(actionCreators, dispatch)
    })
)(ResetPasswordForm);