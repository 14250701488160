import React, {Component} from 'react';
import {actionCreators as quizNinjaActions, QuizNinjaState} from "../../../store/games/QuizNinjaStore";
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Translate} from "react-localize-redux";
import {Loading} from "../../Loading";
import QuizninjaRoundForm from "./QuizninjaRoundForm";
import * as AccountStore from "../../../store/Account";
import * as GamesStore from "../../../store/games/Games";
import GameDetailed from "../../ModelPreview/GameDetailed";
import QuizNinjaSettings from "./QuizNinjaSettings";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import QuizNinjaGamePreview from "./QuizNinjaGamePreview";
import {ErrorState} from "../../../store/Error";
import {LayoutComponentProps} from "../../Layout";

interface Props extends LayoutComponentProps<{id: string}> {
    actions: typeof quizNinjaActions;
    quizninja: QuizNinjaState;
    errors: ErrorState['quizninja'];
    myGamesActions: typeof myGamesActions;

}

class QuizNinja extends Component<Props> {

    constructor(props: Props){
        super(props);
        props.actions.reset();
    }
    
    render(){
        const props = this.props;
        const game = props.quizninja.game;

        return(
            <EditGameComponent
                gameState={props.quizninja}
                newGameElementFunction={() => {props.actions.setEditRound(null, -1)}}
                newGameElementText={'quizninja_create_round'}
                routeMatch={props.match}
                gameType={'QuizNinja'}
                setLayoutButtons={props.setLayoutButtons}
            >
                <Loading visible={props.quizninja.isLoading.sortRounds} />
                <GameDetailed
                    game={game}
                    tempDescription={
                        <div>
                            <p><Translate id='quizninja_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/da/portfolio-item/quiz-ninja/"><Translate id='quizninja_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='quizninja_title'/>}
                    onClick={() => this.props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.quizninja.gameType}
                    gameStats={props.quizninja.gameStats}
                />
                <div className='clear-fix' />
                <div>
                    {game &&
                    <QuizNinjaGamePreview
                        game={game}
                        onRoundClick={props.actions.setEditRound}
                        onSort={props.actions.setSort}
                        onSortCommit={props.actions.updateSortCommit}
                    />
                    }
                </div>
                <QuizninjaRoundForm />
                <QuizNinjaSettings/>
            </EditGameComponent>
        )
    }
}

export default
connect(
    (state: AppState) => ( {
        quizninja: state.quizninja,
        errors: state.errors.quizninja,
        meState: state.me,
        account: state.account
    } ),
    dispatch => ({
        actions: bindActionCreators(quizNinjaActions, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(QuizNinja);