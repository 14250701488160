import {errorActions} from "./Error";
import axios from "axios/index";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {ChangeEvent, TA} from './configureStore';
import {BaseGame, findGameInState} from "../model/Game/BaseGame";
import { ImageUploadRequest } from "../model/Request/ImageUploadRequest";
import {Reducer} from "redux";
import {ImageUploadType} from "../model/ImageUploadType";
import {UpdateGameRequest} from "../model/Request/UpdateGameRequest";
import {isDbImage} from "../services/ImageHelper";
import {history} from "../index";
import {DbImage} from "../model/DbImage";
import {GameList} from "./LibraryStore";
import {actionCreators as msgActions} from "./MessageStore";
import { GameType } from "../model/Game/GameType";

export const reducerName = 'mygames';

type ColorPicker = |'backgroundColorPicker'|'baseGameFontColorPicker'|'baseGameLabelColorPicker';

const MYGAMES_ORIGINAL_IMAGE_REQUEST = 'MYGAMES_ORIGINAL_IMAGE_REQUEST';
const MYGAMES_ORIGINAL_IMAGE_RESPONSE = 'MYGAMES_ORIGINAL_IMAGE_RESPONSE';
const MYGAMES_GET_GAMES_REQUEST = 'MYGAMES_GET_GAMES_REQUEST';
const MYGAMES_GET_GAMES_RESPONSE = 'MYGAMES_GET_GAMES_RESPONSE';
const MYGAMES_GET_AUTHOR_GAMES_REQUEST = 'MYGAMES_GET_AUTHOR_GAMES_REQUEST';
const MYGAMES_GET_AUTHOR_GAMES_RESPONSE = 'MYGAMES_GET_AUTHOR_GAMES_RESPONSE';

interface EditGame extends BaseGame{
    _o: BaseGame;
}

export interface MyGamesState {
    myGames?: GameList;
    readonly deletedGameIds: string[];
    authoredGames?: GameList;
    editGame?: EditGame;
    editGameImage: ImageUploadType | null;
    originalImage: DbImage | null;
    isLoading: {getMyGames: boolean; submitEditGame: boolean; deleteGame: boolean; originalImage: boolean; getMyAuthorGames?: boolean};
    ui: {scale: number};
    isShowing: {
        createDialog: boolean; editDialog: boolean; sideBar: boolean;
        deleteGameConfirm: boolean; scaleForm: boolean;
    };
}

const initialState: MyGamesState = {
    myGames: undefined,
    deletedGameIds: [],
    editGame: undefined,
    editGameImage: null,
    originalImage: null,
    isLoading: {
        getMyGames: false,
        submitEditGame: false,
        deleteGame: false,
        originalImage: false,
        getMyAuthorGames: false
    },
    ui: { scale: parseFloat(localStorage.getItem("my_games_scale") || "1") || 1},
    isShowing: {
        createDialog: false,
        editDialog: false,
        sideBar: false,
        deleteGameConfirm: false,
        scaleForm: false,
    }
};

export const actionCreators = {
    toggleCreateGame: (show: boolean): TA => async (dispatch, getState) => {
        if (undefined === show){
            show = !getState().mygames.isShowing.createDialog;
        }
        dispatch({type: 'MYGAMES_CREATE_TOGGLE', show});
    },
    showEditDialog: (show: boolean, g?: BaseGame): TA => async (dispatch, getState) => {
        //if hide go back to initial state
        if(!show){
            dispatch({type:'MYGAMES_SHOW_EDIT', show: false, game: initialState.editGame, editImage: initialState.editGameImage});
        }

        //if show but game is not there return
        if (!g || !g.id) return;
        //reset errors
        dispatch(errorActions.resetAllErrors(reducerName));

        const game: EditGame = {
            ...g,
            title: g.title,
            description: g.description,
            _o: g
        };

        if(game){
            dispatch({type:'MYGAMES_SHOW_EDIT', show, game, editImage: game.image});
        }
    },
    showEditGameColorPicker: (colorPicker: ColorPicker, show: boolean): TA => async(dispatch) => {
        dispatch({type: 'MYGAMES_BASEGAME_SHOW_COLOR_PICKER', colorPicker, show});
    },
    showGameDeleteConfirm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'MYGAMES_SHOW_DELETE_GAME_CONFIRM', show});
    },
    showScaleForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'MYGAMES_SHOW_SCALE_FORM', show});
    },
    setEditGameImage: (image: ImageUploadRequest | undefined): TA => async(dispatch) => {
        dispatch({type: 'MYGAMES_SET_EDIT_GAME_IMAGE', image})
    },
    getOriginalImage: (imageId: string): TA => async(dispatch, getState) => {
        dispatch({type: MYGAMES_ORIGINAL_IMAGE_REQUEST});

        const url = `api/dbimages/${imageId}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: MYGAMES_ORIGINAL_IMAGE_RESPONSE, data });
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getOriginalImage', error));
            });
    },
    submitEditGame: (): TA => async(dispatch, getState) => {
        const editGame = getState().mygames.editGame;
        if(editGame == null) return;

        dispatch({type: 'MYGAMES_PUT_GAME_REQUEST'});
        dispatch(errorActions.resetError(reducerName, 'submitEditGame'));

        editGame.fontSize = editGame.fontSize || 20;
        editGame.fontFamily = editGame.fontFamily || 'Lato';
        editGame.fontColorHex = editGame.fontColorHex || '#ffffff';
        editGame.showLabel = editGame.showLabel === undefined ? false : editGame.showLabel;
        editGame.labelColorHex = editGame.labelColorHex || '#000000';
        editGame.showTitle = editGame.showTitle === undefined ? false : editGame.showTitle;

        const data: UpdateGameRequest = {
            id: editGame.id,
            title: editGame.title,
            description: editGame.description,
            isPublic: editGame.isPublic,
            isPublished: editGame.isPublished,
            fontSize: editGame.fontSize,
            fontFamily: editGame.fontFamily,
            fontColorHex: editGame.fontColorHex,
            labelColorHex: editGame.labelColorHex,
            backgroundColorHex: editGame.backgroundColorHex,
            showTitle: editGame.showTitle,
            showLabel: editGame.showLabel,
            imageUpload: isDbImage(editGame.image) ? null : editGame.image,
            languageCode: editGame.languageCode,
            tags: editGame.tags || '',
            minAge: editGame.minAge || 0,
            maxAge: editGame.maxAge || 50,
            courseName: editGame.courseName || '',
            preventCopy: editGame.preventCopy
        };

        //Try to find the game that was edited
        const game = findGameInState(getState(), editGame.id);

        const url = `api/${editGame.gameType}/${editGame.id}`;
        axios.put(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data: BaseGame = response.data;
                dispatch({type: 'MYGAMES_PUT_GAME_RESPONSE', data });
                if(
                    game &&
                    (data.isPublic !== game.isPublic ||
                    data.isPublished !== game.isPublished)
                ){
                    if(!data.isPublished){
                        dispatch(msgActions.addMessage("pop_change", "pop_msg_game_draft", {name: data.title}));
                    }
                    else if(!data.isPublic){
                        dispatch(msgActions.addMessage("pop_change", "pop_msg_game_published", {name: data.title}));
                    }
                    else{
                        dispatch(msgActions.addMessage("pop_change", "pop_msg_game_public", {name: data.title}));
                    }
                }
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'submitEditGame', error));
            });
    },
    deleteGame: (gameType: GameType, gameId: string,): TA => async(dispatch, getState) => {
        dispatch({type: 'MYGAMES_DELETE_GAME_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/${gameType}/${gameId}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'MYGAMES_DELETE_GAME_RESPONSE', data});
                // Redirect to /mygames if deleting from a /workshop/ url
                if (history.location.pathname.includes('/workshop/')) {
                    history.push('/mygames');
                }
                dispatch(msgActions.addMessage("pop_deleted","pop_msg_game_delete"));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteGame', error));
            })
    },
    setScale: (scale: number): TA => async(dispatch) => {
        localStorage.setItem("my_games_scale", scale.toString());
        dispatch({type: 'MYGAMES_SET_SCALE', scale});
    },
    changeEditDialog: (event: ChangeEvent<string|boolean|number>): TA => async(dispatch, getState) => {
        dispatch({type: 'MYGAMES_EDIT_CHANGE', event});
        if(event.target.name === "showTitle"){
            dispatch({
                type: 'MYGAMES_EDIT_CHANGE',
                event: {target:{name: 'showLabel', value: event.target.value}}
            });
        }
    },
    getMyGames: (): TA => async(dispatch, getState) => {
        dispatch({type: MYGAMES_GET_GAMES_REQUEST});

        const url = 'api/game/mygames';
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: MYGAMES_GET_GAMES_RESPONSE, data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getMyGames', error));
            });
    },
    getMyAuthorGames: (): TA => async(dispatch, getState) => {
        dispatch({type: MYGAMES_GET_AUTHOR_GAMES_REQUEST});

        const url = 'api/game/mygames/author';
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: MYGAMES_GET_AUTHOR_GAMES_RESPONSE, data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getMyAuthorGames', error));
            });
    },
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: MyGamesState, action: any) => MyGamesState} = {
    MYGAMES_ORIGINAL_IMAGE_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                originalImage: true
            }
        }
    },
    MYGAMES_ORIGINAL_IMAGE_RESPONSE: (state, action) => {
        return{
            ...state,
            originalImage: action.data,
            isLoading:{
                ...state.isLoading,
                originalImage: false,
            }
        }
    },
    MYGAMES_BASEGAME_SHOW_COLOR_PICKER: (state, action) => {
        return{
            ...state,
            isShowing: {
                ...state.isShowing,
                [action.colorPicker] : action.show
            }
        }
    },
    MYGAMES_SHOW_DELETE_GAME_CONFIRM: (state, action) => {
        return{
            ...state,
            isShowing:{
                ...state.isShowing,
                deleteGameConfirm: action.show
            }
        }
    },
    MYGAMES_SHOW_SCALE_FORM: (state, action) => {
        return{
            ...state,
            isShowing:{
                ...state.isShowing,
                scaleForm: action.show
            }
        }
    },
    MYGAMES_SET_EDIT_GAME_IMAGE: (state, action) => {
        if(!state.editGame) return state;
        return{
            ...state,
            editGame:{
                ...state.editGame,
                image: action.image
            }
        };
    },
    MYGAMES_SHOW_EDIT_IMAGE: (state, action) => {
        return{
            ...state,
            isShowing: {
                ...state.isShowing,
                editImageForm: action.show
            }
        };
    },
    MYGAMES_PUT_GAME_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                submitEditGame: true
            }
        };
    },
    MYGAMES_PUT_GAME_RESPONSE: (state, action) => {
        const game = action.data;
        const newGames = state.myGames ? state.myGames.games.map(x => x.id === game.id ? game : x) : [];
        const newMyGames = state.myGames ? {
            ...state.myGames,
            games: newGames
        } : undefined;

        return{
            ...state,
            myGames: newMyGames,
            isLoading:{
                ...state.isLoading,
                submitEditGame: false
            },
            editGame: initialState.editGame,
            editGameImage: initialState.editGameImage,
            isShowing:{
                ...state.isShowing,
                editDialog: initialState.isShowing.editDialog
            }
        }
    },
    MYGAMES_DELETE_GAME_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                deleteGame: true
            }
        }
    },
    MYGAMES_DELETE_GAME_RESPONSE: (state, action) => {
        const idResponse = action.data;
        const newGames = state.myGames ? state.myGames.games.filter(x => x.id !== idResponse.id) : [];
        const newMyGames = state.myGames ? {
            ...state.myGames,
            games: newGames
        } : undefined;

        return{
            ...state,
            myGames: newMyGames,
            isLoading:{
                ...state.isLoading,
                deleteGame: false
            },
            editGame: initialState.editGame,
            editGameImage: initialState.editGameImage,
            isShowing:{
                ...state.isShowing,
                editDialog: initialState.isShowing.editDialog
            }
        }
    },
    MYGAMES_SHOW_EDIT: (state, action) => {
        return{
            ...state,
            editGame: action.game,
            editGameImage: action.editImage,
            isShowing:{
                ...state.isShowing,
                editDialog: action.show
            }
        };
    },
    MYGAMES_EDIT_CHANGE: (state, action) => {
        if(!state.editGame) return state;
        return{
            ...state,
            editGame: {
                ...state.editGame,
                [action.event.target.name] : action.event.target.value
            }
        };
    },
    MYGAMES_CREATE_TOGGLE: (state, action) => {
        return {
            ...state,
            isShowing: {
                ...state.isShowing,
                createDialog: action.show
            }
        };
    },
    MYGAMES_GET_GAMES_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                getMyGames: true
            }
        };
    },
    MYGAMES_GET_GAMES_RESPONSE: (state, action) => {
        return {
            ...state,
            myGames: action.data,
            isLoading: {
                ...state.isLoading,
                getMyGames: false
            }
        };
    },
    MYGAMES_GET_AUTHOR_GAMES_REQUEST: (state) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                getMyAuthorGames: true
            }
        }
    },
    MYGAMES_GET_AUTHOR_GAMES_RESPONSE: (state, action) => {
        return{
            ...state,
            authoredGames: action.data,
            isLoading: {
                ...state.isLoading,
                getMyAuthorGames: undefined
            }
        }

    },
    MYGAMES_SET_SCALE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                scale: action.scale
            }
        }
    },
    ERROR: (state, action) => {
        if(action.reducer !== 'mygames') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<MyGamesState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};