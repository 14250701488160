import axios from 'axios';
import {errorActions} from './Error';
import profile from '../images/missing-profile.png'
import {actionCreators as orgActions} from "./OrganizationStore";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {accountIsChildAllowed, AccountType, getFullName} from '../model/AccountType';
import { Reducer} from 'redux';
import { ChangeEvent, TA} from './configureStore';
import { ImageUploadRequest } from '../model/Request/ImageUploadRequest';
import {UpdateAccountRequest} from "../model/Request/UpdateAccountRequest";
import moment from "moment";
import {actionCreators as msgActions} from "./MessageStore";
import { setDefaultLocale } from 'react-datepicker';
export const reducerName = 'me';

export interface MeState {
    readonly eulaVersion: string;
    readonly account: AccountType | null;
    readonly editAccount: AccountType | null;
    readonly lastUsedImage: string;
    readonly isLoading: {
        readonly get: boolean; readonly update: boolean; readonly updateImage: boolean; readonly changePassword: boolean; readonly followAuthor: boolean;
        readonly unfollowAuthor: boolean; readonly acceptEula: boolean;
    };
    readonly ui: {
        readonly editForm: boolean; readonly imageForm: boolean; readonly changePasswordForm: boolean;
    };
}

let defaultImage = localStorage.getItem("profileImage");
if (!defaultImage) defaultImage = profile; 

const initialState: MeState = {
    eulaVersion: "1",
    account: null,
    editAccount : null,
    lastUsedImage: defaultImage,
    isLoading: { get: false, update: false, updateImage: false, changePassword: false, followAuthor: false, unfollowAuthor: false, acceptEula: false},
    ui: { editForm: false, imageForm: false, changePasswordForm: false }
};

export const actionCreators = {
    setShowEditForm: (show: boolean): TA => async(dispatch, getState) => {
        if(show) dispatch({type:'ME_SET_EDIT', account: getState().me.account});
        dispatch({type: 'ME_SHOW_EDIT', show});
    },
    setShowImageForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'ME_SHOW_UPDATE_IMAGE', show});
    },
    setShowChangePasswordForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'ME_SHOW_CHANGE_PASSWORD', show});
    },
    setEditField: (event: ChangeEvent<string>|ChangeEvent<boolean>|ChangeEvent<number>): TA => async(dispatch) => {
        const field = event.target.name;
        const value = event.target.value;

        dispatch({type: 'ME_SET_EDIT_FIELD', field, value});
    },
    changePassword: (oldPassword: string, newPassword: string, newPasswordRepeat: string): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_REQUEST_CHANGE_PASSWORD'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const data = {
            oldPassword: oldPassword,
            password: newPassword,
            passwordRepeat: newPasswordRepeat
        };
        const url = `api/account/changePassword`;
        axios.post(url, data, CreateAuthHeader(getState))
            .then(() => {
                dispatch({type: 'ME_RECEIVED_CHANGE_PASSWORD'});
                dispatch(msgActions.addMessage(
                    "pop_password",
                    "pop_msg_user_password_change"
                ));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'changePassword', error));
            });
    },
    acceptEula: (): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_ACCEPT_EULA_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/account/AcceptEula/${getState().me.eulaVersion}`;

        axios.post(url, {}, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'ME_RECEIVED', data});
                dispatch({type: 'ME_ACCEPT_EULA_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'acceptEula', error));
            })
    },
    get: (): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/account`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                const data: AccountType = response.data;
                dispatch({ type: 'ME_RECEIVED', data });
                dispatch( orgActions.getMy() );
                if(accountIsChildAllowed(data)){
                    dispatch(orgActions.getAll(false));
                }
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'get', error));
            });

    },
    update: (account: UpdateAccountRequest): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_UPDATE_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/account/${account.id}`;
        axios.post(url, account, CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: 'ME_RECEIVED', data: response.data });
                dispatch({ type: 'ME_UPDATE_RECEIVED', data: response.data });
                localStorage.setItem("myfloor_lang", response.data.language);
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'update', error));
            });
    },
    updateImage: (data: ImageUploadRequest): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_REQUEST_UPDATE_IMAGE'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/account/image`;

        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({ type: 'ME_RECEIVED_UPDATE_IMAGE', data });
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateImage', error));
            });
    },
    followAuthor: (authorId: string): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_FOLLOW_AUTHOR_REQUEST'});

        const account = getState().account.accounts[authorId];

        const url = `api/account/follow/${authorId}`;
        axios.post(url, null, CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: 'ME_RECEIVED', data: response.data });
                dispatch({ type: 'ME_FOLLOW_AUTHOR_RESPONSE' });
                dispatch(msgActions.addMessage(
                    "pop_friends",
                    "pop_msg_user_follow",
                    {name: account ? getFullName(account) : ''}
                ));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'followAuthor', error));
            })
    },
    unfollowAuthor: (authorId: string): TA => async(dispatch, getState) => {
        dispatch({type: 'ME_UNFOLLOW_AUTHOR_REQUEST'});

        const account = getState().account.accounts[authorId];

        const url = `api/account/follow/${authorId}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: 'ME_RECEIVED', data: response.data });
                dispatch({ type: 'ME_UNFOLLOW_AUTHOR_RESPONSE' });
                dispatch(msgActions.addMessage(
                    "pop_friends",
                    "pop_msg_user_unfollow",
                    {name: account ? getFullName(account) : ''}
                ));
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'unfollowAuthor', error));
            })
    }
};

// eslint-disable-next-line
const reducerMethods: {[type: string]: (state: MeState, action: any) => MeState} = {
    ME_ACCEPT_EULA_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                acceptEula: true
            }
        }
    },
    ME_ACCEPT_EULA_RESPONSE: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                acceptEula:false
            }
        }
    },
    ME_FOLLOW_AUTHOR_RESPONSE: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                followAuthor: false
            }
        }
    },
    ME_FOLLOW_AUTHOR_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                followAuthor: true
            }
        }
    },
    ME_UNFOLLOW_AUTHOR_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                unfollowAuthor: true
            }
        }
    },
    ME_UNFOLLOW_AUTHOR_RESPONSE: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                unfollowAuthor: false
            }
        }
    },
    ME_SET_EDIT: (state, action) => {
        return{
            ...state,
            editAccount: action.account
        }
    },
    ME_SET_EDIT_FIELD: (state, action) => {
        if(!state.editAccount) return state;
        return{
            ...state,
            editAccount:{
                ...state.editAccount,
                [action.field]: action.value
            }
        }
    },
    ME_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                me: true
            },
        };
    },
    ME_RECEIVED: (state, action) => {
        if(action.data.profileImage) localStorage.setItem("profileImage", action.data.profileImage.imagePath);
        if(action.data.language){
            setDefaultLocale(action.data.language);
            moment.locale(action.data.language);
        } 
        return {
            ...state,
            account: action.data,
            isLoading: {
                ...state.isLoading,
                me: false
            },
            ui: {
                ...state.ui,
                editForm: false
            }
        };
    },
    ME_UPDATE_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                update: true
            },
        };
    },
    ME_UPDATE_RECEIVED: (state) => {
        return {
            ...state,
            editAccount: initialState.editAccount,
            isLoading: {
                ...state.isLoading,
                update: false
            },
        };
    },
    ME_SHOW_EDIT: (state, action) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                editForm: action.show
            }
        };
    },
    ME_SHOW_UPDATE_IMAGE: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                imageForm: action.show
            }
        };
    },
    ME_REQUEST_UPDATE_IMAGE: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                updateImage: true
            },
        };
    },
    ME_RECEIVED_UPDATE_IMAGE: (state, action) => {
        if(action.data.profileImage) localStorage.setItem("profileImage", action.data.profileImage.imagePath);
        if(!state.account || !state.editAccount) return state;
        return {
            ...state,
            account:{
                ...state.account,
                profileImage: action.data.profileImage
            },
            editAccount:{
                ...state.editAccount,
                profileImage: action.data.profileImage
            },
            isLoading: {
                ...state.isLoading,
                updateImage: false
            },
            ui: {
                ...state.ui,
                imageForm: false
            }
        };
    },
    ME_SHOW_CHANGE_PASSWORD:  (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                changePasswordForm: action.show
            }
        };
    },
    ME_REQUEST_CHANGE_PASSWORD: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                changePassword: true
            },
        };
    },
    ME_RECEIVED_CHANGE_PASSWORD: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                changePassword: false
            },
            ui: {
                ...state.ui,
                changePasswordForm: false
            }
        };
    },
    ERROR: (state, action) => {
        if(action.reducer !== 'me') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<MeState, any> = (state, action) => {
    state = state || initialState;
    const type = action.type;
    const method = reducerMethods[type];
    if(method) return method(state, action);
    return state;
};