import React, {useEffect, useRef, useState} from 'react';
import {LayoutComponentProps} from "../Layout";
import {isMinOrgType, TempOrganization} from "../../model/Organization";
import * as OrgStore from "../../store/OrganizationStore";
import {OrganizationState} from "../../store/OrganizationStore";
import * as AccountStore from '../../store/Account';
import {AccountState} from '../../store/Account';
import * as GamesStore from '../../store/games/Games';
import {connect} from 'react-redux';
import {AppState} from "../../store/configureStore";
import {Link} from 'react-router-dom';
import {Loading} from "../Loading";
import {bindActionCreators} from "redux";
import {MinimalOrganization} from "../../model/Response/MinimalOrganization";
import {DictGetValues} from "../../services/JsDict";
import Author from "../ModelPreview/Author";
import Game from "../ModelPreview/Game";
import {Translate} from "react-localize-redux";
import LibraryFilters from "../Library/LibraryFilters";
import {SortByFirstName} from "../../services/SortFunctions";
import {isAccountType, isTempAccount} from "../../model/AccountType";
import Pagination from "../Pagination";

interface Props extends LayoutComponentProps<{id: string}>{
    orgState: OrganizationState;
    accountState: AccountState;
    gamesState: GamesStore.GamesState;
    orgActions: typeof OrgStore.actionCreators;
    accountActions: typeof AccountStore.actionCreators;
    gamesActions: typeof GamesStore.actionCreators;
}

interface State{
    id: string;
    organization: MinimalOrganization | TempOrganization;
    gamePaginate: {
        currentPage: number;
        pageCount: number;
    };
}

const pageSize = 49;

const OrganizationProfile = (props: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const idFromRoute = props.match.params.id;
    const minimalOrg = props.orgState.minimalOrgs[idFromRoute];
    const [state, setState] = useState<State>( {
        id: "not set",
        organization: props.orgState.minimalOrgs[idFromRoute],
        gamePaginate: {
            currentPage: 0,
            pageCount: (props.gamesState.gamesByOrg?.length || 0) / pageSize
        }
    });

    useEffect(() => {
        if(state.id !== idFromRoute){
            props.orgActions.getMinimalOrg(idFromRoute, false);
            props.accountActions.getByOrganization(idFromRoute);
            props.gamesActions.getGamesByOrg(idFromRoute);
        }
        if(minimalOrg){
            setState(s => ({
                ...s,
                id: idFromRoute,
                organization: minimalOrg,
                gamePaginate: {
                    ...s.gamePaginate,
                    pageCount: (props.gamesState.gamesByOrg?.length || 0) / pageSize
                }
            }));
        }
    }, [state.id, minimalOrg, idFromRoute, props.gamesState.gamesByOrg, props.orgActions, props.accountActions, props.gamesActions])

    if(!isMinOrgType(state.organization) ) {
        return <div className='org-profile'><Loading visible /></div>;
    }

    const setPage =(n: number) => {
        setState({
            ...state,
            gamePaginate:{
                ...state.gamePaginate,
                currentPage: n
            }
        });

        setTimeout(() => {
            if(containerRef.current){
                window.scrollTo({top: containerRef.current.offsetTop - 20, behavior: "smooth"});
            }
        }, 500);
    }

    return(
        <div className='org-profile'>
            <h1>{state.organization.name}</h1>
            <div className='container'>
                <h2><Translate id='authors' /></h2>
                <div className='author-list'>
                    <Loading visible={props.accountState.isLoading.getByOrganization}/>
                    {props.accountState.accountsByOrg &&
                    DictGetValues(props.accountState.accountsByOrg)
                        .filter(isAccountType)
                        .sort(SortByFirstName)
                        .map(x => {
                            if(!x) return null;
                            if(isTempAccount(x)) return null;
                            return <Link key={x.id} to={`/library/profile/${x.id}`}><Author account={x} onClick={() => {}} /></Link>
                    })}
                </div>
            </div>

            <div className='container'>
                <h2><Translate id='games' /> {props.gamesState.gamesByOrg && `(${props.gamesState.gamesByOrg.length})`}</h2>
                <LibraryFilters action={() => props.gamesActions.getGamesByOrg(idFromRoute)} />
                <div ref={containerRef} />
                
                <Pagination currentPage={state.gamePaginate.currentPage} pageCount={state.gamePaginate.pageCount} setPage={setPage} />
                <div className='game-list'>
                    <Loading visible={props.gamesState.loading.getGamesByOrg} />
                    {props.gamesState.gamesByOrg &&
                    props.gamesState.gamesByOrg
                        .slice(state.gamePaginate.currentPage * pageSize, state.gamePaginate.currentPage * pageSize + pageSize)
                        .map(x =>
                            <Game key={x.id} game={x} scale={0.95} />
                        )
                    }
                </div>
                <Pagination currentPage={state.gamePaginate.currentPage} pageCount={state.gamePaginate.pageCount} setPage={setPage} />
            </div>

        </div>
    )
}

export default connect(
    (state: AppState) => ({
        orgState: state.organization,
        accountState: state.account,
        gamesState: state.gamesState
    }),
    (dispatch) => ({
        orgActions: bindActionCreators(OrgStore.actionCreators, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(OrganizationProfile);