import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../../store/configureStore';
import { ErrorState } from '../../../store/Error';
import * as GlobalStore from '../../../store/GlobalComponentStore';
import { Button } from '../../forms/FormGroup';
import { SecureLayoutComponentProps } from '../../Layout';
import GameDetailed from '../../ModelPreview/GameDetailed';
import * as MyGamesStore from "../../../store/MyGames";
import EditGameComponentSimple from '../../EditGameComponentSimple';
import * as PresentationStore from "../../../store/games/PresentationStore";

interface Props extends SecureLayoutComponentProps<{ id?: string }> {
    gameActions: typeof PresentationStore.actionCreators;
    errorState: ErrorState['presentation'];
    gameState: PresentationStore.PresentationState;
    myGamesActions: typeof MyGamesStore.actionCreators;
    globalActions: typeof GlobalStore.actionCreators;
}

const Presentation = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, setLayoutButtons  } = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])
    
    if (errorState.getGame) return <div><Translate id='unknown_error' /></div>;
    const game = gameState.game;

    return (
        <EditGameComponentSimple
            gameState={gameState}
            routeMatch={match}
            gameType={'Presentation'}
            setLayoutButtons={setLayoutButtons}
        >
            <Translate id='presentation_show_in_myfloor'></Translate>
            <div>
                <Button 
                    name='presentation_myfloor_click' 
                    blank={true} 
                    link={game?.id ? `https://myfloor.activefloor.com/workshop/Presentation/edit/${game?.id}` : `https://myfloor.activefloor.com/workshop/Presentation/create`} 
                    className='presentation_placement'
                />
            </div>
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='presentation_text' options={{ renderInnerHtml: true, renderToStaticMarkup: false }} /></p>
                    </div>
                }
                tempTitle={<Translate id='presentation_title' />}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
        </EditGameComponentSimple>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[PresentationStore.reducerName],
        errorState: state.errors[PresentationStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(PresentationStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(Presentation)