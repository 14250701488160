import React, {Component} from 'react';
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {bindActionCreators} from "redux";
import * as MessageStore from "../../store/MessageStore";
import {Translate} from "react-localize-redux";

interface Props {
    messageState: MessageStore.MessageState;
    messageActions: typeof MessageStore.actionCreators;
}

class PopContainer extends Component<Props>{


    render(){
        return (
            <div className='pop-container'>
                {this.props.messageState.messages.map(x => {
                    return(
                        <div key={x.id} className={`msg ${x.state || ''}`}>
                            <div className='msg-content'>
                                <div className='title'>
                                    <Translate id={x.title} />
                                </div>
                                <p><Translate id={x.message} data={x.placeholderData || undefined} /></p>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default connect(
    (state: AppState) => ({
        messageState: state.message
    }),
    dispatch => ({
        messageActions : bindActionCreators(MessageStore.actionCreators, dispatch)
    })
)(PopContainer);