import React, {memo, ReactNode} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { AccountType } from '../model/AccountType';
import TopMenu from './Menu/TopMenu';

export interface SecureLayoutComponentProps<T extends { [K in keyof T]?: string | undefined; } = void> extends LayoutComponentProps<T>{
    me: AccountType;
}

export interface LayoutComponentProps<T extends { [K in keyof T]?: string | undefined; } = void> extends RouteComponentProps<T>{
    setLayoutButtons: (n: ReactNode) => void;
}

interface Props {
    children: ReactNode;
    navMenu: ReactNode;
    buttons?: ReactNode;
    globalModals: ReactNode;
}


const Layout = (props: Props) => {

    return (
        <>
            <TopMenu buttons={props.buttons}/>
            {props.navMenu}
            <div className={'content'}>
                {props.children}
                {props.globalModals}
            </div>
        </>
    );
};

export default memo(Layout);