import {
    AbstractGameIsLoading,
    abstractGameReducerMethods, AbstractGameState,
    AbstractGameUi,
    actionCreators as gamesActions
} from './Games';
import {errorActions} from "../Error";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import { MeditationGame } from '../../model/Game/Meditation/MeditationGame';


export const reducerName = 'meditationState';

export interface MeditationState extends AbstractGameState{
    game?: MeditationGame;
    isLoading: AbstractGameIsLoading;
    ui: AbstractGameUi;
}

const initialState: MeditationState = {
    game: undefined,
    gameStats: undefined,
    gameType: "Meditation",
    isLoading: {
        getGame: false, addAuthor: false, removeAuthor: false, transferOwner: false, updateSettings: false
    },
    ui: { showSettings: false}
};

export const actionCreators = {
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: 'MEDITATION_RESET'});
    },
    setField: (e: ChangeEvent<string>|ChangeEvent<number>|ChangeEvent<boolean>): TA => async(dispatch) => {
        dispatch({ type: 'MEDITATION_SET_FIELD', name: e.target.name, value: e.target.value});
    },
    getGame: (id: string): TA => async(dispatch,) => {
        dispatch(gamesActions.getGame(id, true, 'Meditation'));
        dispatch(errorActions.resetAllErrors(reducerName));
    }
};

// eslint-disable-next-line
const reducerMethods: { [key: string]: (state: MeditationState, action: any) => MeditationState} = {
    ...abstractGameReducerMethods,
    MEDITATION_RESET: () => {
        return initialState;
    },
    MEDITATION_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<MeditationState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};