import React, { FormEvent, useState } from 'react';
import Dialog from "../Dialog";
import {
    CheckBox,
    ColorAndFont,
    ColorPicker,
    ImageFormButton,
    InputField,
    Slider,
    SubmitButton,
    TextArea
} from "./FormGroup";
import { useConfirmDelete } from "./games/ConfirmationForm";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import {ChangeEvent} from "../../store/configureStore";
import {accountHasAnyPermission, AccountType, isAccountType} from "../../model/AccountType";
import { PlayListType } from '../../model/PlayListType';
import { isDbImage } from '../../services/ImageHelper';
import { useFetchPost, useFetchPut } from '../../services/FetchHelper';


const initialPlaylist: PlayListType = {
    id: '',
    fontColorHex: "#FFFFFF",
    fontFamily: "Lato",
    fontSize: 24,
    labelColorHex: "#000000", 
    authorIds: [],
    backgroundColorHex: '#FFFFFFFF',
    countries: '',
    createdDateTime: '',
    description: '',
    editedDateTime: '',
    image: null,
    imageId : '',
    isPublic: false,
    isPublished: false,
    name: '',
    organizationId: '',
    owner: null,
    ownerId: '',
    showLabel: true,
    showTitle: true,
    tags: '',
    games: []
}

interface Props{
    playlist: PlayListType|undefined;
    me: AccountType; 
    onClose: () => void;
    onSuccess: (p: PlayListType) => void;
}

const PlayListForm = (props: Props) => {
    const {me, playlist: _original, onClose, onSuccess: _onSuccess} = props;
    const original = _original || {...initialPlaylist};
    const onSuccess = (p: PlayListType) => {onClose(); _onSuccess(p);}

    const [model, setModel] = useState<PlayListType>(_original ? { ..._original} : {...initialPlaylist});
    const [confirmDelete, showConfirmDelete] = useConfirmDelete(() => {});
    const [post, postLoading, postError] = useFetchPost<PlayListType>(onSuccess);
    const [put, putLoading, putError] = useFetchPut<PlayListType>(onSuccess);
    const loading = postLoading || putLoading;
    const error = postError || putError;

    const isValid = !!(model.name && model.description);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        const reuseImage = !!(model.image && isDbImage(model.image));
        const body = {
            name: model.name,
            description: model.description,
            isPublic: model.isPublic,
            isPublished: model.isPublished,
            image: reuseImage ? null : model.image,
            showTitle: model.showTitle,
            showLabel: model.showLabel,
            fontColorHex: model.fontColorHex,
            fontSize: model.fontSize,
            fontFamily: model.fontFamily,
            labelColorHex: model.labelColorHex,
            backgroundColorHex: model.backgroundColorHex,
            reuseImage: reuseImage
        };

        if(model.id){ //updating
            put(`api/playlist/${model.id}`, body);
        }
        else{ //creating
            post('api/playlist', body);
        }
    }

    const changeField = (e: ChangeEvent<string|boolean|number>) => {
        setModel(m => ({...m, [e.target.name]: e.target.value}));
    };

    return(
        <Dialog
            onClose={onClose}
            className='playlist-edit-dialog'
            icon={model.id ? 'trash' : undefined}
            onIconClick={showConfirmDelete}
            loading={loading}
        >
            {confirmDelete}
            <div className='playlist-edit'>
                <PlayListPreview playlist={model} onlyImage noMenu/>
                <form onSubmit={onSubmit}>
                    <div className='form-group color-image-group'>
                        {model.image &&
                        <ColorPicker
                            name={'backgroundColorHex'}
                            colorValue={model.backgroundColorHex || '#FFFFFFFF'}
                            onColorChange={changeField}
                            original={original.backgroundColorHex || '#FFFFFFFF'}
                        />
                        }
                        <ImageFormButton
                            name={'image'}
                            imageSubmit={img => setModel(m => ({...m, image: img}))}
                            previewWidth={220}
                            previewHeight={150}
                            noRemove
                            unsavedChange={model.image !== original.image}
                        />
                        <div className='clear-fix'/>
                    </div>
                    <div className='title-section'>
                        <InputField
                            name='name'
                            placeholder='name'
                            type='text'
                            value={model.name}
                            onChange={changeField}
                            errors={error}
                            noLabel
                            original={original.name}
                        />
                        {model.name &&
                        <CheckBox
                            name='showTitle'
                            label='showTitle'
                            active={model.showTitle}
                            onChange={changeField}
                            original={original.showTitle}
                        />
                        }

                        { model.name && model.showTitle &&
                        <div className='sub-section'>
                            <Slider
                                min={12}
                                max={30}
                                name={'fontSize'}
                                value={model.fontSize}
                                onChange={changeField}
                                original={original.fontSize}
                            />
                            <ColorAndFont
                                colorValue={model.fontColorHex}
                                onColorChange={changeField}
                                fontFamilyValue={model.fontFamily}
                                onFontFamilyChange={changeField}
                                originalColor={original.fontColorHex}
                                originalFontFamily={original.fontFamily}
                            />
                            <div className='label-form-group'>
                                <CheckBox
                                    name='showLabel'
                                    className='checkbox-group'
                                    label='showLabel'
                                    active={model.showLabel}
                                    onChange={changeField}
                                    original={original.showLabel}
                                />
                                {model.showLabel &&
                                <ColorPicker
                                    name='labelColorHex'
                                    colorValue={model.labelColorHex}
                                    onColorChange={changeField}
                                    original={original.labelColorHex}
                                />
                                }
                                <div className='clear-fix'/>
                            </div>
                        </div>
                        }
                    </div>

                    <TextArea
                        name='description'
                        placeholder='description'
                        value={model.description}
                        onChange={changeField}
                        errors={error}
                        noLabel
                        original={original.description}
                    />

                    <CheckBox
                        name='isPublished'
                        active={model.isPublished}
                        onChange={changeField}
                        original={original.isPublished}
                        disabled={!accountHasAnyPermission(me, ["Publish", "Admin"])}
                        disabledHint={"unauthorized_error"}
                    />
                    {model.isPublished && isAccountType(me) && accountHasAnyPermission(me, ["Public", "Admin"]) &&
                    <CheckBox
                        name='isPublic'
                        active={model.isPublic}
                        onChange={changeField}
                        original={original.isPublic}
                        errors={error}
                    />
                    }

                    <SubmitButton
                        text='submit'
                        disabled={!isValid}
                        split
                    />
                </form>
            </div>
        </Dialog>
           
    )
};


export default PlayListForm;