import React, { useState } from 'react';
import { Translate } from 'react-localize-redux';
import { accountHasAnyPermission, AccountType } from '../../model/AccountType';
import { Wall } from '../../model/SportsWall/Wall';
import { copyToClipboard } from '../../services/CustomFunctions';
import { Button, CheckBox } from '../forms/FormGroup';
import WallEdit from './WallEdit';

interface Props{
    wall: Wall;
    onClick?: () => void;
    noMenu?: boolean;
    onUpdated?: (w: Wall) => void;
    onDeleted?: () => void;
    account?: AccountType;
}

const WallPreview = (props: Props) => {
    const {wall, onUpdated, onDeleted, onClick, noMenu, account} = props;
    const [showEditWall, setShowEditWall] = useState(false);

    return(
        <div className={`wall-preview${onClick ? ' clickable' : ''}`} onClick={onClick}>
            <h2>{wall.name}</h2>
            {accountHasAnyPermission(account, ['Admin']) && 
                <div className='group'>
                    <div><Translate id='api_key'/></div>
                    <div className='xs-text clickable' onClick={() => copyToClipboard(wall.apiKey)}>{wall.apiKey}</div>
                </div>
            }
            <div dir='auto'>
                <Translate id='wall_games' />: {wall.sortedGames.length}
            </div>
            <div dir='auto'>
                <Translate id='wall_playlists' />: {wall.sortedPlaylists.length}
            </div>
            <div dir='auto'>
                <Translate id='wall_bundles' />: {wall.sortedBundles.length}
            </div>
            <CheckBox
                label='wall_is_rtl' 
                name='isRightToLeftFlow' 
                active={wall.isRightToLeftFlow} 
                onChange={_ => {}} 
                disabled
            />
            {!noMenu && 
                <div className='wall-preview-menu'>
                    <Button link={`/wall/${wall.id}`} ><Translate id='wall_menu_link' /></Button>
                    {/* <Button onClick={() => setShowEditWall(true)} icon='edit' ><Translate id='wall_menu_edit' /></Button> */}
                </div>
            }
            {!noMenu && showEditWall &&
                <WallEdit
                    onClose={() => setShowEditWall(false)}
                    onSuccess={onUpdated}
                    onDeleted={onDeleted}
                    wall={wall}
                />
            }
        </div>
    )
}

export default WallPreview;