import React from 'react';

interface Props{
    setPage: (n: number) => void;
    currentPage: number;
    pageCount: number;
}

const Pagination = (props: Props) => {
    const setPage = props.setPage;
    const currentPage = props.currentPage;
    const pageCount = props.pageCount;

    const pages: number[] = [];
    for(let i = 0; i < pageCount; i++){
        pages.push(i);
    }

    const leftDisable = currentPage === 0;
    const rightDisable = currentPage >= (pageCount-1);

    return(
        <div className='pages'>
            <span className={leftDisable ? 'disabled' : ''} onClick={() => !leftDisable && setPage(currentPage-1)}>&lt;</span>
            {pages.map(x => <span onClick={() => setPage(x)} key={x} className={x === currentPage ? 'active' : ''}>{x+1}</span>)}
            <span className={rightDisable ? 'disabled' : ''} onClick={() => !rightDisable && setPage(currentPage+1)}>&gt;</span>
        </div>
    )
}

export default Pagination;