import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../../store/configureStore';
import { ErrorState } from '../../../store/Error';
import * as GlobalStore from '../../../store/GlobalComponentStore';
import { Button } from '../../forms/FormGroup';
import { SecureLayoutComponentProps } from '../../Layout';
import GameDetailed from '../../ModelPreview/GameDetailed';
import * as MyGamesStore from "../../../store/MyGames";
import EditGameComponentSimple from '../../EditGameComponentSimple';
import * as PopItStore from "../../../store/games/PopItStore";

interface Props extends SecureLayoutComponentProps<{ id?: string }> {
    gameActions: typeof PopItStore.actionCreators;
    errorState: ErrorState['popItState'];
    gameState: PopItStore.PopItState;
    myGamesActions: typeof MyGamesStore.actionCreators;
    globalActions: typeof GlobalStore.actionCreators;
}

const PopIt = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, setLayoutButtons  } = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])
    
    if (errorState.getGame) return <div><Translate id='unknown_error' /></div>;
    const game = gameState.game;

    return (
        <EditGameComponentSimple
            gameState={gameState}
            routeMatch={match}
            gameType={'PopIt'}
            setLayoutButtons={setLayoutButtons}
        >
            <Translate id={`pop_it_${game?.id ? 'edit' : 'create'}_in_myfloor`}></Translate>
            <div>
                <Button 
                    name='pop_it_myfloor_click' 
                    blank={true} 
                    link={game?.id ? `https://myfloor.activefloor.com/workshop/popit/edit/${game?.id}` : `https://myfloor.activefloor.com/workshop/popit/create`} 
                    className='presentation_placement'
                />
            </div>
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='pop_it_text' options={{ renderInnerHtml: true, renderToStaticMarkup: false }} /></p>
                    </div>
                }
                tempTitle={<Translate id='pop_it_title' />}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
        </EditGameComponentSimple>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[PopItStore.reducerName],
        errorState: state.errors[PopItStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(PopItStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(PopIt)