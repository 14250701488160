import React, {useEffect, useState} from 'react';
import Dialog from "../Dialog";
import {Translate} from "react-localize-redux";
import GameDetailed from "../ModelPreview/GameDetailed";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {bindActionCreators} from "redux";
import {actionCreators as playlistActions} from "../../store/PlayListStore";
import * as GlobalStore from '../../store/GlobalComponentStore';
import {DictGetValues} from "../../services/JsDict";
import { accountHasAnyPermission, AccountType } from '../../model/AccountType';
import ItemContainer from '../ModelPreview/ItemContainer';
import { BaseGame } from '../../model/Game/BaseGame';
import { PlayListType } from '../../model/PlayListType';
import { useFetchGet, useFetchPost } from '../../services/FetchHelper';

interface Props{
    item?: BaseGame;
    me: AccountType|null;
    onClose: () => void;
}

const AddGameToPlaylist = (props: Props) => {
    const {item, me, onClose} = {...props};

    const [playlists, setPlaylists] = useState<PlayListType[]>([]);
    const [orgPlaylists, setOrgPlaylists] = useState<PlayListType[]>([]);
    
    const [getPlaylists, loadingGet, errorGet] = useFetchGet<{[key: string]: PlayListType}>(r => setPlaylists(DictGetValues(r)));
    const [getPlaylistsOnOrg, loadingGetOnOrg, errorGetOnOrg] = useFetchGet<{ [key: string]: PlayListType }>(r => setOrgPlaylists(DictGetValues(r)));
    const [postGame, loadingPost, errorPost] = useFetchPost<PlayListType>(onClose);
    const error = errorGet || errorGetOnOrg || errorPost;

    const meHasFloorPermission = me && accountHasAnyPermission(me, ['Floor', 'Admin']);

    useEffect(() => {
        if (item){
            getPlaylists('api/playlist');
            if (meHasFloorPermission) {
                getPlaylistsOnOrg(`api/organization/${me?.organizationId}/playlists`);
            }
        }
       
    }, [item, getPlaylists, me, getPlaylistsOnOrg, meHasFloorPermission]); 

    if (!item) return null;
    if(me == null) return null;

    const onSubmit = (playlistId: string) => {
        postGame(`api/playlist/${playlistId}/game`, {gameId: item.id});
    }

    return(
        <Dialog onClose={onClose} className='playlist-chooser' loading={loadingPost}>
            
            <h2><Translate id='playlist_put_game' data={{ game: item.title}}/></h2>
            <GameDetailed game={item} gameType={item.gameType} hideTranslations/>
            <div className='clear-fix'/>
            <ItemContainer 
                heading={<h1><Translate id='playlist_owner' /></h1>}
                loading={loadingGet}
                items={loadingGet ? [] : playlists.filter(x => x.ownerId === me.id)}
                itemRender={value => <PlayListPreview
                    width={150} 
                    key={value.id} 
                    noMenu 
                    playlist={value} 
                    onClick={onSubmit} 
                    className='no-white-gradient' 
                />}
                error={error}
            />
            <ItemContainer 
                heading={<h2><Translate id='playlist_is_author' /></h2>}
                loading={loadingGet}
                items={loadingGet ? [] : playlists.filter(x => x.authorIds && x.authorIds.includes(me.id))}
                itemRender={value => <PlayListPreview 
                    width={150} key={value.id} 
                    noMenu 
                    playlist={value} 
                    onClick={onSubmit} 
                    className='no-white-gradient' 
                />}
                error={error}
            />
            { meHasFloorPermission &&
                <ItemContainer 
                    heading={<h2><Translate id='playlist_is_floor_org' /></h2>}
                    loading={loadingGetOnOrg}
                    items={loadingGetOnOrg ? [] : orgPlaylists}
                    itemRender={value => <PlayListPreview 
                        width={150} 
                        key={value.id} 
                        noMenu 
                        playlist={value} 
                        onClick={onSubmit} 
                        className='no-white-gradient' 
                    />}
                    error={error}
                />
            }
        </Dialog>
    );

};

export default connect(
    (state: AppState) => ({
        item: state.globalComponentState.showPlaylistAddItem,
        allPlaylists: DictGetValues(state.playlist.playListsMap),
        me: state.me.account,
        error: state.errors.playlist.addGame
    }),
    dispatch => ({
        playlistActions: bindActionCreators(playlistActions, dispatch),
        onClose: bindActionCreators(() => GlobalStore.actionCreators.playlistAddItemForm(undefined), dispatch)
    })
)(AddGameToPlaylist);