import {TA} from "./configureStore";
import {Reducer} from "redux";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

export const reducerName = 'message';

const MESSAGE_STORE_ADD = 'MESSAGE_STORE_ADD';
const MESSAGE_STORE_REMOVE = 'MESSAGE_STORE_REMOVE';
const MESSAGE_STORE_CHANGE_ANIM = 'MESSAGE_STORE_CHANGE_ANIM';

type MessageAnim = 'spawn' | 'in' | 'out';

export interface Message{
    message: string;
    placeholderData: {[key: string]: string};
    title: string;
    id: string;
    state: MessageAnim;
}

export interface MessageState {
    messages: Message[];
}

const initialState: MessageState = {
    messages: []
};

export const useMsg = () => {
    const dispatch = useDispatch();
    return useCallback((title, msg) => dispatch(actionCreators.addMessage(title, msg)), [dispatch]);
}

export const actionCreators = {
    addMessage: (title: string, msg: string, data?: {[key: string]: string}): TA => async(dispatch) => {
        const id = Math.random();
        dispatch({type: MESSAGE_STORE_ADD, msg: {title: title, message: msg, placeholderData: data, id: id, state: 'spawn'}});
        setTimeout(() => {
            dispatch({type: MESSAGE_STORE_CHANGE_ANIM, id: id, state: 'in'});
            setTimeout(() => {
                dispatch({type: MESSAGE_STORE_CHANGE_ANIM, id: id, state: 'out'});
                setTimeout(() => {
                    dispatch({type: MESSAGE_STORE_REMOVE, msg: {message: msg, id: id}});
                }, 1000); //despawn time
            }, 5000)//in-time

        }, 100)//spawn
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: MessageState, action: any) => MessageState} = {
    MESSAGE_STORE_ADD: (state, action) => {
        return {
            ...state,
            messages: [
                ...state.messages,
                action.msg
            ]

        }
    },
    MESSAGE_STORE_CHANGE_ANIM: (state, action) => {
        return{
            ...state,
            messages: state.messages.map(x => {
                if(x.id !== action.id) return x;
                return {...x, state: action.state}
            })
        }
    },
    MESSAGE_STORE_REMOVE: (state, action) => {
        return{
            ...state,
            messages: state.messages.filter(x => x.id !== action.msg.id)
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<MessageState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};