import React, {Component} from 'react';
import * as BikeRaceStore from '../../../store/games/BikeRaceStore';
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {connect} from "react-redux";
import {AppState} from "../../../store/configureStore";
import {bindActionCreators} from "redux";
import EditGameComponent from "../../EditGameComponent";
import GameDetailed from "../../ModelPreview/GameDetailed";
import {Translate} from "react-localize-redux";
import BikeRaceQuizForm from "./BikeRaceQuizForm";
import BikeRaceSettings from "./BikeRaceSettings";
import {BikeRaceGamePreview} from "./BikeRaceGamePreview";
import {LayoutComponentProps} from "../../Layout";


interface Props extends LayoutComponentProps<{id: string}> {
    bikeRaceActions: typeof BikeRaceStore.actionCreators;
    bikeRaceState: BikeRaceStore.BikeRaceState;
    myGamesActions: typeof myGamesActions;
}

class BikeRace extends Component<Props> {
    constructor(props: Props) {
        super(props);
        props.bikeRaceActions.resetGame();
    }

    render() {
        const props = this.props;
        const game = props.bikeRaceState.game;

        return (
            <EditGameComponent
                gameState={props.bikeRaceState}
                newGameElementFunction={ props.bikeRaceActions.newQuiz }
                contentClass={'bike-race-content'}
                newGameElementText={'bike_race_add_quiz'}
                routeMatch={props.match}
                gameType={'BikeRace'}
                setLayoutButtons={props.setLayoutButtons}
            >
                <GameDetailed
                    game={game}
                    tempDescription={
                        <div>
                            <p><Translate id='bike_race_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/da/portfolio-item/bike-race/"><Translate id='bike_race_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='bike_race_title'/>}
                    onClick={() => props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.bikeRaceState.gameType}
                    gameStats={props.bikeRaceState.gameStats}
                />
                {game &&
                    <BikeRaceGamePreview
                        onQuizClick={(q,i) => props.bikeRaceActions.setEditQuiz({...q, index: i, isNew: false, _o: {...q}}, i)}
                        game={game}
                    />
                }
                <BikeRaceQuizForm />
                <BikeRaceSettings />

            </EditGameComponent>

        );
    }
}

export default connect(
    (state: AppState) => ({
        bikeRaceState: state.bikeRaceState
    }),
    dispatch => ({
        myGamesActions: bindActionCreators(myGamesActions, dispatch),
        bikeRaceActions: bindActionCreators(BikeRaceStore.actionCreators, dispatch)
    })
)(BikeRace);