import React from 'react';
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import { Link } from 'react-router-dom';

interface MenuItem{
    key: string;
    icon: IconProp;
    text: string;
    action?: () => void;
    link?: string;
}

interface Props{
    items: Array<MenuItem|null>;
}

export const CustomGameMenu = (props: Props) => {
    return(
        <div className='menu-container'>
            {props.items.map(x => {
                if(x === null) return null;
                const content = (
                    <div key={x.link ? undefined : x.key} className={'menu-point'} onClick={x.action}>
                        <span>
                            <Translate id={x.text} />
                        </span>
                        <FontAwesomeIcon icon={x.icon} className='fa-icon' />
                    </div>
                );
                return x.link ? <Link key={x.key} to={x.link}>{content}</Link> : content
            })}
        </div>
    )
};