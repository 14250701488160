import React, {Component} from 'react';
import {Translate} from 'react-localize-redux';
import {bindActionCreators} from "redux";
import {actionCreators as memoryActions, EditPair, MemoryState} from "../../../store/games/Memory";
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {actionCreators as gamesActions} from '../../../store/games/Games';
import {connect} from "react-redux";
import PairForm from "./PairForm";
import {Loading} from "../../Loading";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import MemorySettings from './MemorySettings';
import {MeState} from '../../../store/Me';
import {AccountState} from '../../../store/Account';
import {MemoryPair} from '../../../model/Game/Memory/MemoryPair';
import {AppState} from '../../../store/configureStore';
import {MemoryGamePreview} from "./MemoryGamePreview";
import {LayoutComponentProps} from "../../Layout";
import {ErrorState} from "../../../store/Error";

interface Props extends LayoutComponentProps<{id: string}>{
    memoryActions: typeof memoryActions;
    myGamesActions: typeof myGamesActions;
    gamesActions: typeof gamesActions;
    memoryState: MemoryState;
    errorState: ErrorState['memory'];
    meState: MeState;
    accountState: AccountState;
}

class Memory extends Component<Props> {

    constructor(props: Props){
        super(props);
        props.myGamesActions.showEditDialog(false);
        props.memoryActions.resetGame();
    }

    openEditPair = (pair: MemoryPair, index: number) => {
        const editPair: EditPair = {
            ...pair,
            index: index,
            new: false,
            _o: {...pair}
        };
        
        this.props.memoryActions.setPair(editPair);
        this.props.memoryActions.showPairForm(true);
    };


    openNewPair = () => {
        this.props.memoryActions.resetPair();
        this.props.memoryActions.showPairForm(true);
    };
    
    render(){
        const props = this.props;
        if(props.errorState.getGame) return <div><Translate id='unknown_error'/></div>;

        const game = props.memoryState.game;

        return(
            <EditGameComponent
                gameState={props.memoryState}
                newGameElementFunction={this.openNewPair}
                newGameElementDisabled={false}
                newGameElementText={'memory_create_pair_button'}
                routeMatch={props.match}
                gameType={'Memory'}
                setLayoutButtons={props.setLayoutButtons}
            >
                <Loading visible={!!props.memoryState.isLoading.getGame}/>
                <GameDetailed
                    game={props.memoryState.game}
                    tempDescription={
                        <div>
                            <p><Translate id='memory_text' options={{renderToStaticMarkup: false, renderInnerHtml: true}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/da/portfolio-item/memory/"><Translate id='memory_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='memory_create_title'/>}
                    onClick={() => props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.memoryState.gameType}
                    gameStats={props.memoryState.gameStats}
                />
                <div className='clear-fix' />
                {game &&
                    <MemoryGamePreview
                        game={game}
                        onPairClick={(pair, index) => this.openEditPair(pair, index)}
                    />
                }
                <PairForm />
                <MemorySettings />

            </EditGameComponent>
        )
    }
}

export default connect(
    (state: AppState) => ({
        memoryState: state.memory,
        errorState: state.errors.memory,
        meState: state.me,
        accountState: state.account
    }),
    dispatch => ({ 
        memoryActions: bindActionCreators(memoryActions, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch),
        gamesActions: bindActionCreators(gamesActions, dispatch)
    })
)(Memory);