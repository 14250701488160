import React, {Component} from 'react';
import * as splatterSpaceStore from "../../../store/games/SplatterSpaceStore";
import {actionCreators as myGamesActions} from "../../../store/MyGames";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Translate} from "react-localize-redux";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";
import {LayoutComponentProps} from "../../Layout";

interface Props extends LayoutComponentProps<{id: string}>{
    splatterSpaceActions: typeof splatterSpaceStore.actionCreators;
    myGamesActions: typeof myGamesActions;
    errorState: ErrorState['splatterspace'];
    splatterSpaceState: splatterSpaceStore.SplatterSpaceState;
}

class SplatterSpace extends Component<Props> {

    constructor(props: Props){
        super(props);
        props.splatterSpaceActions.resetGame();
    }

    render(){
        const props = this.props;
        if(props.errorState.getGame) return <div><Translate id='unknown_error'/></div>;
        const game = props.splatterSpaceState.game;

        return(
            <EditGameComponent
                gameState={props.splatterSpaceState}
                routeMatch={props.match}
                gameType={'SplatterSpace'}
                setLayoutButtons={props.setLayoutButtons}
            >
                <GameDetailed
                    game={game}
                    tempDescription={
                        <div>
                            <p><Translate id='splatterspace_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                            <a target='_blank' rel="noopener noreferrer" href="https://activefloor.com/da/portfolio-item/splatter-space/"><Translate id='splatterspace_readmore' /></a>
                        </div>
                    }
                    tempTitle={<Translate id='splatterspace_title'/>}
                    onClick={() => this.props.myGamesActions.showEditDialog(true, game)}
                    gameType={props.splatterSpaceState.gameType}
                    gameStats={props.splatterSpaceState.gameStats}
                />
            </EditGameComponent>
        );
    }
}

export default connect(
    (state: AppState) => ({
        splatterSpaceState: state[splatterSpaceStore.reducerName],
        errorState: state.errors[splatterSpaceStore.reducerName]
    }),
    dispatch => ({
        splatterSpaceActions: bindActionCreators(splatterSpaceStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch)
    })
)(SplatterSpace)