import React, {useEffect, useState} from 'react';
import Dialog from "../Dialog";
import {Translate} from "react-localize-redux";
import {InputField} from "./FormGroup";
import {AccountState, actionCreators as accountActions} from "../../store/Account";
import {bindActionCreators} from "redux";
import Author from "../ModelPreview/Author";
import {connect} from "react-redux";
import {Loading} from "../Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfirmationForm from "./games/ConfirmationForm";
import {MeState} from '../../store/Me';
import {isAccountType} from '../../model/AccountType';
import {AppState} from '../../store/configureStore';

interface Props {
    accountState: AccountState;
    meState: MeState;
    accountActions: typeof accountActions;

    chooseFunction: (authorId: string) => void;
    chooseDelete?: (authorId: string) => void;
    loading?: boolean;
    visible: boolean;
    onClose: () => void;
    ownerId: string;
    authorIds?: string[];
}

const AuthorChooser = (props: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchFire, setSearchFire] = useState<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        searchFire && clearTimeout(searchFire);
        searchTerm && setSearchFire(setTimeout(() => props.accountActions.setSearchString(searchTerm), 500));
        // eslint-disable-next-line
    }, [searchTerm]);

    if(!props.visible) return null;

    const owner = props.accountState.accounts[props.ownerId];

    const confirmDialog =
        <ConfirmationForm
            text='remove_author_confirm'
            confirmText='yes'
            cancelText='no'
            onConfirm={() => props.chooseDelete && props.chooseDelete(props.accountState.ui.showConfirmRemoveAuthor || '')}
            onCancel={() => props.accountActions.showConfirmRemoveAuthor(false)}
        />;

    const searching = props.accountState.searchStringResult !== props.accountState.searchString;

    return(
        <Dialog onClose={props.onClose} className='author-chooser-dialog' loading={props.loading}>
            <h2><Translate id='current_authors'/></h2>
            <div className='authors'>
                {owner && isAccountType(owner) &&
                    <Author account={owner} hideName hoverName />
                }
                {props.authorIds && props.authorIds.map(x => {
                    return (
                        <div key={x} className='relative clickable float' onClick={() => props.accountActions.showConfirmRemoveAuthor(x)}>
                            <Author accountId={x} small hideName hoverName />
                            <FontAwesomeIcon icon='trash' className='delete-icon'/>
                        </div>
                    )
                })}
                <div className='clear-fix'/>
            </div>
            <h2><Translate id='author_choose'/></h2>
            <InputField
                name='search'
                type='text'
                placeholder='search'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                noLabel
            />

            <div className='authors-container'>
                <Loading visible={searching} noBackground/>
                {props.accountState.searchResult.accounts.map(acc => {
                    return <Author
                        key={acc.id}
                        account={acc}
                        hoverName
                        onClick={() => props.chooseFunction(acc.id)}
                    />
                })}
                <div className='clear-fix'/>
            </div>
            {props.accountState.ui.showConfirmRemoveAuthor && confirmDialog}
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ accountState: state.account, meState: state.me }),
    dispatch => ({accountActions: bindActionCreators(accountActions, dispatch)})
)(AuthorChooser);