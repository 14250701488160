import {AbstractGameIsLoading, abstractGameReducerMethods, AbstractGameState, AbstractGameUi} from "./Games";
import {errorActions} from "../Error";
import {createBuzzItQuizFormData, updateBuzzItQuizFormData} from "../../services/FormDataHelper";
import axios from "axios";
import {actionCreators as gamesActions} from '../../store/games/Games';
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import {BuzzItQuiz} from "../../model/Game/BuzzIt/BuzzItQuiz";
import {BuzzItGame} from "../../model/Game/BuzzIt/BuzzItGame";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import {ImageUploadType} from "../../model/ImageUploadType";
import {SoundUploadType} from "../../model/SoundUploadType";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import {getLocaleStorageItem, saveLocaleStorageItem} from "../../services/LocalStorageService";

export type Container = | 'question' | 'answer1' | 'answer2' | 'answer3';
export type EditQuizType = BuzzItQuiz & {
    isNew: boolean;
    index: number;
    _o: BuzzItQuiz;
};

const BUZZ_IT_SAVE_STYLE = 'BUZZ_IT_SAVE_STYLE';
const BUZZ_IT_APPLY_STYLE = 'BUZZ_IT_APPLY_STYLE';

export const reducerName = "buzz_it_store";
const buzzitStyleKey = 'buzzit_style';

interface BuzzItStyle{
    question: TextType;
    answer: TextType;
}

export interface BuzzItUi extends AbstractGameUi{
    showQuizForm?: boolean;
    showSoundForm?: Container;
    showQuizDelete?: boolean;
    showSettings?: boolean;
}

export interface BuzzItState extends AbstractGameState {
    game?: BuzzItGame;
    editQuiz: EditQuizType;
    isLoading: AbstractGameIsLoading & {
        createQuiz?: boolean;
        updateQuiz?: boolean;
        deleteQuiz?: boolean;
    };
    ui: BuzzItUi;
    style: BuzzItStyle;
}

const initialStyle: BuzzItStyle = {
    question: {
        content: '',
        fontFamily: 'Lato',
        fontColorHex: '#000000',
        fontSize: 32,
        textAlignment: TextAlignment.Center
    },
    answer: {
        content: "",
        fontSize: 32,
        fontFamily: "Lato",
        fontColorHex: "#000000",
        textAlignment: TextAlignment.Center
    }
};

const initialState = (): BuzzItState => {
    const defaultStyle = getLocaleStorageItem(buzzitStyleKey, initialStyle);

    const answer = {
        correct: false,
        text: {...defaultStyle.answer, content: ''}
    };

    return{
        game: undefined,
        gameStats: undefined,
        gameType: "BuzzIt",
        editQuiz: {
            isNew: true,
            index: -1,
            question:{
                text: {...defaultStyle.question, content: ''},
            },
            answer1: {...answer},
            answer2: {...answer},
            answer3: {...answer},
            _o:{
                question:{
                    text: {...defaultStyle.question, content: ''},
                },
                answer1: {...answer},
                answer2: {...answer},
                answer3: {...answer}
            }
        },
        isLoading: {},
        ui: {},
        style: defaultStyle
    }
};

export const actionCreators = {
    saveStyle: (): TA => async(dispatch, getState) => {
        const newStyle: BuzzItStyle = {
            question: {...getState().buzz_it_store.editQuiz.question.text},
            answer: {...getState().buzz_it_store.editQuiz.answer1.text}
        };
        saveLocaleStorageItem(buzzitStyleKey, newStyle);
        dispatch({type: BUZZ_IT_SAVE_STYLE, newStyle});
    },
    applyStyle: (): TA => async(dispatch) => {
        dispatch({type: BUZZ_IT_APPLY_STYLE});
    },
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_RESET'});
    },
    showQuizForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_SHOW_QUIZ_FORM', show});
    },
    newQuiz: (): TA => async(dispatch, getState) => {
        if(!getState()[reducerName].editQuiz.isNew){
            dispatch({type: 'BUZZ_IT_SET_EDIT_QUIZ', quiz: initialState().editQuiz });
        }
        dispatch(actionCreators.showQuizForm(true));
    },
    showQuizDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_SHOW_DELETE_FORM', show});
    },
    setSetting: (e: ChangeEvent<string>|ChangeEvent<number>): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_SET_SETTING', field: e.target.name, value: e.target.value});
    },
    setEditQuiz: (quiz: EditQuizType, index: number): TA => async(dispatch, getState) => {
        if(getState()[reducerName].editQuiz.index !== index){
            quiz.index = index;
            quiz.isNew = false;
            dispatch({type: 'BUZZ_IT_SET_EDIT_QUIZ', quiz});
        }
        dispatch(actionCreators.showQuizForm(true));
    },
    showColorPicker: (show: false | Container): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_SHOW_COLOR_PICKER', show});
    },
    showSoundForm: (show: false | Container): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_SHOW_SOUND_FORM', show});
    },
    setEditField: (container: undefined | Container, field: string, value: string|number|boolean|ImageUploadType|SoundUploadType|null|undefined): TA => async(dispatch) => {
        if(container){
            dispatch({type: 'BUZZ_IT_SET_FIELD', container, field, value});
            if(field === 'sound') dispatch({type: 'BUZZ_IT_SHOW_SOUND_FORM', show:false});
        }
    },
    setEditTextField: (container: Container, field: string, value: string|number|null): TA => async(dispatch) => {
        dispatch({type: 'BUZZ_IT_SET_TEXT_FIELD', container, field, value});
    },
    getGame: (id: string): TA => async(dispatch) =>{
        dispatch(gamesActions.getGame(id, true, 'BuzzIt'));
        dispatch(errorActions.resetAllErrors(reducerName));
    },
    createQuiz: (quiz: BuzzItQuiz): TA => async(dispatch, getState) => {
        const game = getState().buzz_it_store.game;
        const gameId = game && game.id;
        dispatch({type: 'BUZZ_IT_CREATE_QUIZ_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const formData = createBuzzItQuizFormData(quiz, gameId);
        const url = `api/buzzit/quiz`;

        axios.post(url, formData, CreateAuthHeader(getState))
            .then(response => {
                if(!gameId){
                    dispatch(actionCreators.saveStyle());
                    history.push(`/workshop/buzzit/edit/${response.data.id}`);
                }
                dispatch({type: 'BUZZ_IT_CREATE_QUIZ_RESPONSE', data: response.data});
                dispatch({type: 'BUZZ_IT_SET_EDIT_QUIZ', quiz: initialState().editQuiz});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createQuiz', error));
            });
    },
    updateQuiz: (quiz: BuzzItQuiz, quizIndex: number): TA => async(dispatch, getState) => {
        const game = getState().buzz_it_store.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: 'BUZZ_IT_UPDATE_QUIZ_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const formData = updateBuzzItQuizFormData(quiz, quizIndex, gameId);
        const url = 'api/buzzit/quiz';
        axios.put(url, formData, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'BUZZ_IT_UPDATE_QUIZ_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateQuiz', error));
            })
    },
    deleteQuiz: (quizIndex: number): TA => async(dispatch, getState) => {
        const game = getState().buzz_it_store.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: 'BUZZ_IT_DELETE_QUIZ_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/buzzit/${gameId}/quiz/${quizIndex}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'BUZZ_IT_DELETE_QUIZ_RESPONSE', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteQuiz', error));
            })
    }
};

export function buzzItTransformQuizToStyle(quiz: EditQuizType, style: BuzzItStyle): EditQuizType{
    return({
        ...quiz,
        question: {
            ...quiz.question,
            text: {
                ...style.question,
                content: quiz.question.text.content
            }
        },
        answer1: {
            ...quiz.answer1,
            text: {
                ...style.answer,
                content: quiz.answer1.text.content
            }
        },
        answer2: {
            ...quiz.answer2,
            text: {
                ...style.answer,
                content: quiz.answer2.text.content
            }
        },
        answer3: {
            ...quiz.answer3,
            text: {
                ...style.answer,
                content: quiz.answer3.text.content
            }
        }
    });
}

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: BuzzItState, action: any) => BuzzItState} = {
    ...abstractGameReducerMethods,
    BUZZ_IT_APPLY_STYLE: (state) => {
        return{
            ...state,
            editQuiz: buzzItTransformQuizToStyle(state.editQuiz, state.style)
        }
    },
    BUZZ_IT_SAVE_STYLE: (state, action) => {
        return{
            ...state,
            style: action.newStyle
        }
    },
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            editQuiz: initialState().editQuiz,
        }
    },
    BUZZ_IT_RESET: () => {
        return initialState();
    },
    BUZZ_IT_SET_SETTING: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game: {
                ...state.game,
                [action.field]: action.value
            }
        }
    },
    BUZZ_IT_SHOW_DELETE_FORM: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showQuizDelete: action.show
            }
        }
    },
    BUZZ_IT_DELETE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                deleteQuiz: true
            }
        }
    },
    BUZZ_IT_DELETE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui:{
                ...state.ui,
                showQuizDelete: false,
                showQuizForm: false
            },
            isLoading:{
                ...state.isLoading,
                deleteQuiz: false
            }
        }
    },
    BUZZ_IT_CREATE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                createQuiz: true
            }
        }
    },
    BUZZ_IT_CREATE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            editQuiz: initialState().editQuiz,
            ui:{
                ...state.ui,
                showQuizForm: false,
            },
            isLoading:{
                ...state.isLoading,
                createQuiz: false
            }
        }
    },
    BUZZ_IT_UPDATE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                updateQuiz: true
            }
        }
    },
    BUZZ_IT_UPDATE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            editQuiz: initialState().editQuiz,
            ui:{
                ...state.ui,
                showQuizForm: false,
            },
            isLoading:{
                ...state.isLoading,
                updateQuiz: false
            }
        }
    },

    BUZZ_IT_SHOW_QUIZ_FORM: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showQuizForm: action.show
            }
        }
    },
    BUZZ_IT_SHOW_COLOR_PICKER: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showColorPicker: action.show
            }
        }
    },
    BUZZ_IT_SHOW_SOUND_FORM: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showSoundForm: action.show
            }
        }
    },
    BUZZ_IT_SET_EDIT_QUIZ: (state, action) => {
        return{
            ...state,
            editQuiz: action.quiz
        }
    },
    BUZZ_IT_SET_FIELD: (state, action: {container: Container; field: string; value: number|string}) => {
        return{
            ...state,
            editQuiz:{
                ...state.editQuiz,
                [action.container]:{
                    ...state.editQuiz[action.container],
                    [action.field] : action.value
                }
            }

        }
    },
    BUZZ_IT_SET_TEXT_FIELD: (state, action: {container: Container; field: string; value: number|string}) => {
        return{
            ...state,
            editQuiz:{
                ...state.editQuiz,
                [action.container]:{
                    ...state.editQuiz[action.container],
                    text:{
                        ...state.editQuiz[action.container].text,
                        [action.field]: action.value
                    }
                }
            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== reducerName) return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<BuzzItState, any> = (state, action) => {
    state = state || initialState();
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};