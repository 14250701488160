import {ActiveFloorUnit} from "../model/ActiveFloorUnit";
import {Reducer} from "redux";
import {TA} from "./configureStore";
import axios from "axios";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {errorActions} from "./Error";

export const reducerName = 'activefloorunits';

const UNITS_GET_BY_ORGID_REQUEST = 'UNITS_GET_BY_ORGID_REQUEST';
const UNITS_GET_BY_ORGID_RESPONSE = "UNITS_GET_BY_ORGID_RESPONSE";
const UNITS_GET_ALL_REQUEST = 'UNITS_GET_ALL_REQUEST';
const UNITS_GET_ALL_RESPONSE = 'UNITS_GET_ALL_RESPONSE';
const UNITS_DELETE_REQUEST = 'UNITS_DELETE_REQUEST';
const UNITS_DELETE_RESPONSE  = 'UNITS_DELETE_RESPONSE';


export interface ActiveFloorUnitState {
    unitsMap: {[key: string]: ActiveFloorUnit};
    isLoading: {
        delete?: boolean;
        getByOrgId?: boolean;
    };
}

const initialState: ActiveFloorUnitState = {
    unitsMap: {},
    isLoading: {}
};

export const actionCreators = {
    getByOrgId: (orgId: string): TA => async(dispatch, getState) => {
        dispatch({type: UNITS_GET_BY_ORGID_REQUEST});

        const url = `api/unit/org/${orgId}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => dispatch({type: UNITS_GET_BY_ORGID_RESPONSE, data: response.data}))
            .catch(error => dispatch(errorActions.reportAxiosError(reducerName, 'getByOrgId', error)))
    },
    getAll: (): TA => async(dispatch, getState) => {
        dispatch({type: UNITS_GET_ALL_REQUEST});
        const url = 'api/unit';
        axios.get(url, CreateAuthHeader(getState))
            .then(r => dispatch({type: UNITS_GET_ALL_RESPONSE, data: r.data}))
            .catch(e => dispatch(errorActions.reportAxiosError(reducerName, 'getAll', e)));
    },
    delete: (id: string): TA => async(dispatch, getState) => {
        dispatch({type: UNITS_DELETE_REQUEST});
        const url = `api/unit/status/delete`;

        axios.post(url, {id: id}, CreateAuthHeader(getState))
            .then(_ => dispatch({type: UNITS_DELETE_RESPONSE, id}))
            .catch(error => dispatch(errorActions.reportError(reducerName, 'delete', error)));

    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: ActiveFloorUnitState, action: any) => ActiveFloorUnitState} = {
    UNITS_GET_BY_ORGID_REQUEST: (state) =>
        ({...state, isLoading: {...state.isLoading, getByOrgId: true}}),
    UNITS_DELETE_REQUEST: (state) =>
        ({...state, isLoading: {...state.isLoading, delete: true}}),
    UNITS_GET_ALL_REQUEST: (state) => ({...state, isLoading: {...state.isLoading, getAll: true}}),
    UNITS_GET_ALL_RESPONSE: (state, action: {data: Array<ActiveFloorUnit>}) => ({
        ...state,
        unitsMap: action.data.reduce((dict,x) => ({...dict, [x.id]: x}), {}),
        isLoading: {...state.isLoading, getAll: false}
    }),
    UNITS_GET_BY_ORGID_RESPONSE: (state, action: {type: string; data: ActiveFloorUnit[]}) => {
        let updatedMap = {...state.unitsMap};
        for (const x of action.data) {
            updatedMap = {
                ...updatedMap,
                [x.id]: x
            }
        }

        return {
            ...state,
            unitsMap: updatedMap,
            isLoading: {...state.isLoading, getByOrgId: false}
        };
    },
    UNITS_DELETE_RESPONSE: (state, action) => ({
        ...state,
        isLoading: {...state.isLoading, delete: false},
        unitsMap: {...state.unitsMap, [action.id]: undefined}
    }),
    ERROR: (state, action) => (action.reducer !== reducerName ? state : {
        ...state,
        isLoading: {...state.isLoading, [action.key]: false}
    }),
};

// eslint-disable-next-line
export const reducer: Reducer<ActiveFloorUnitState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};