import React from 'react';
import {connect} from "react-redux";
import * as GamesStore from "../../../store/games/Games";
import * as MemoryStore from '../../../store/games/Memory';
import {bindActionCreators} from "redux";
import {CheckBox, InputField, SelectList, SubmitButton} from "../../forms/FormGroup";
import {Translate} from "react-localize-redux";
import Dialog from '../../Dialog';
import {AppState} from "../../../store/configureStore";


interface Props {
    memoryState: MemoryStore.MemoryState;
    memoryActions: typeof MemoryStore.actionCreators;
    gamesActions: typeof GamesStore.actionCreators;
}

const MemorySettings = (props: Props) => {
    if(!props.memoryState.ui.showSettings) return null;
    const game = props.memoryState.game;
    if(!game) return null;
    const settings = {
        skin: game.skin || 'Default',
        pairTurning: game.pairTurning,
        openCards: game.openCards,
        turnTimeSeconds: game.turnTimeSeconds || 3
    };

    const skinOptions = [
        {name: "Default",    value: "Default"   },
        {name: "Unicorn",    value: "Unicorn"   },
        {name: "Deathstone", value: "Deathstone"},
        {name: "Hygge",      value: "Hygge"     },
        { name: "Egypt", value: "Egypt" },
        { name: "Illuminated", value: "Illuminated" },
        { name: "Flowers", value: "Flowers" },
        { name: "Tropical", value: "Tropical" }
    ];

    return(
        <Dialog className='settings' onClose={() => {props.gamesActions.showSettings(game.gameType, false)}} loading={props.memoryState.isLoading.updateSettings} >
            <h2><Translate id='settings'/></h2>
            <form onSubmit={e => e.preventDefault()}>
                <SelectList name='skin' defaultValue={settings.skin} options={skinOptions} onChange={props.memoryActions.setField}/>
                <InputField<string> name='turnTimeSeconds' type='number' label='memory_turn_time' value={settings.turnTimeSeconds.toString()} onChange={props.memoryActions.setField} />
                <CheckBox name='openCards' active={settings.openCards} onChange={props.memoryActions.setField} label='memory_open_cards' notLabel='memory_not_open_cards'/>
                <CheckBox name='pairTurning' active={settings.pairTurning} onChange={props.memoryActions.setField} label='memory_pair_turning' notLabel='memory_not_pair_turning'/>
                <h3><Translate id='preview_skin'/></h3>
                <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
                <div className='clear-fix' />
                <SubmitButton
                    text='submit'
                    onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                    split
                />
            </form>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({ memoryState: state.memory }),
    dispatch => ({
        memoryActions: bindActionCreators(MemoryStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(MemorySettings);