import axios from "axios";
import {errorActions} from "../Error";
import {createMemoryPairFormData, editMemoryPairFormData} from "../../services/FormDataHelper";
import {
    AbstractGameIsLoading,
    abstractGameReducerMethods,
    AbstractGameState,
    AbstractGameUi,
    actionCreators as gamesActions
} from './Games';
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import {MemoryGame} from "../../model/Game/Memory/MemoryGame";
import {MemoryPair} from "../../model/Game/Memory/MemoryPair";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import {ImageUploadType} from "../../model/ImageUploadType";
import {SoundUploadType} from "../../model/SoundUploadType";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import {getLocaleStorageItem, saveLocaleStorageItem} from "../../services/LocalStorageService";

const memoryStyleKey = 'memory_style';
export const reducerName = 'memory';

const MEMORY_SAVE_STYLE = 'MEMORY_SAVE_STYLE';
const MEMORY_APPLY_STYLE = 'MEMORY_APPLY_STYLE';

type Card = |'a'|'b';

interface MemoryStyle extends TextType{}

export interface EditPair extends MemoryPair {
    readonly new: boolean;
    readonly index: number;
    readonly _o: MemoryPair;
}

export interface CardUi {
    readonly showSoundForm?: boolean;
}
export interface MemoryUi extends AbstractGameUi{
    readonly showNewPairForm?: boolean;
    readonly showSettings?: boolean;
    readonly a: CardUi;
    readonly b: CardUi;
}

export interface MemoryState extends AbstractGameState {
    readonly game?: MemoryGame;
    readonly pair: EditPair;
    readonly ui: MemoryUi;
    readonly isLoading: AbstractGameIsLoading & {
        readonly getGame?: boolean;
        readonly editPair?: boolean;
        readonly createPair?: boolean;
        readonly deletePair?: boolean;
        readonly addAuthor?: boolean;
        readonly removeAuthor?: boolean;
    };
    readonly style: MemoryStyle;
}

const initialStyle: MemoryStyle = {
        content: '',
        fontFamily: 'Lato',
        fontColorHex: '#000000',
        fontSize: 16,
        textAlignment: TextAlignment.Center
};

const initialState = (): MemoryState => {
    const style = {...getLocaleStorageItem(memoryStyleKey, initialStyle)};
    return {
        gameType: 'Memory',
        pair: {
            new: true,
            index: -1,
            identicalCards: false,
            a: { text: {...style, content: ''} },
            b: { text: {...style, content: ''} },
            _o: {
                a: { text: {...style, content: ''} },
                b: { text: {...style, content: ''} },
                identicalCards: false,
            }
        },
        ui: { a: {}, b: {} },
        isLoading: { },
        style: style
    }
};

export const actionCreators = {
    setField: (e: ChangeEvent<string>|ChangeEvent<number>|ChangeEvent<boolean>): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_SET_FIELD', name: e.target.name, value: e.target.value});
    },
    saveStyle: (): TA => async(dispatch, getState) => {
        const newStyle: MemoryStyle = {...getState().memory.pair.a.text};
        saveLocaleStorageItem(memoryStyleKey, newStyle);
        dispatch({type: MEMORY_SAVE_STYLE, newStyle});
    },
    applyStyle: (): TA => async(dispatch, getState) => {
        dispatch({type: MEMORY_APPLY_STYLE});
    },
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_RESET_GAME'});
    },
    getGame: (id: string): TA => async(dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'Memory'));
    },
    editPair: (pair: EditPair): TA => async(dispatch, getState) => {
        const game = getState().memory.game;
        const gameId = game && game.id;
        if(!game || !gameId) return;
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type:'MEMORY_EDIT_PAIR_REQUEST'});

        const form = editMemoryPairFormData(pair, gameId);
        const url = `api/workshop/memory/pair/${pair.index}`;
        axios.put(url, form, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type:'MEMORY_EDIT_PAIR_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'editPair', error));
            });
    },
    createPair: (pair: EditPair): TA => async(dispatch, getState) => {
        const game = getState().memory.game;
        const gameId = game && game.id;

        dispatch({type:'MEMORY_CREATE_PAIR_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const form = createMemoryPairFormData(pair, gameId);

        const url = 'api/workshop/memory/pair';

        axios.post(url,form ,CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                if(!gameId){
                    dispatch(actionCreators.saveStyle());
                    history.push(`/workshop/memory/edit/${data.id}`);
                }
                dispatch({type:'MEMORY_CREATE_PAIR_RESPONSE', data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createPair', error));
            });
    },
    deletePair: (index: number): TA => async(dispatch, getState) => {
        const game = getState().memory.game;
        const gameId = game && game.id;
        if(!gameId) return;

        dispatch({type: 'MEMORY_DELETE_PAIR_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/memory/${gameId}/pair/${index}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'MEMORY_DELETE_PAIR_RESPONSE', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deletePair', error));
            });
    },
    showPairForm: (show: boolean): TA => async(dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: 'MEMORY_SHOW_PAIRFORM', show});
    },
    showColorPicker: (card: Card, show: boolean): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SHOW_FONTCOLORFORM', card, show});
    },
    showSoundForm: (card: Card, show: boolean): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SHOW_SOUNDFORM', card, show});
    },
    setPair: (pair: EditPair): TA => async(dispatch) => {
        const iState = initialState();
        if(pair.a.text === null) pair.a.text = iState.pair.a.text;
        if(pair.a.text.content === null) pair.a.text.content = iState.pair.a.text.content;
        if(pair.b.text === null) pair.b.text = iState.pair.b.text;
        if(pair.b.text.content === null) pair.b.text.content = iState.pair.b.text.content;
        dispatch({type: 'MEMORY_PAIR_SET', pair: pair});
    },
    resetPair: (): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_RESET'});
    },
    setIdentical: (value: boolean): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_IDENTICAL', value});
    },
    setFontColor: (card: Card, value: string): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_FONT_COLOR', card: card, value: value});
    },
    setFontSize: (card: Card, value: number): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_FONT_SIZE', card: card, value: value});
    },
    setTextAlignment: (card: Card, value: TextAlignment): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_TEXT_ALIGNMENT', card: card, value: value});
    },
    setFont: (card: Card, value: string): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_FONT', card:card, value:value});
    },
    setText: (card: Card, value: string): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_TEXT', card: card, value: value});
    },
    setImage: (card: Card, value: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_IMAGE', card: card, value: value});
    },
    setSound: (card: Card, value: SoundUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: 'MEMORY_PAIR_SET_SOUND', card: card, value: value});
    }
};

export function memoryTransformQuizToStyle(quiz: EditPair, style: MemoryStyle): EditPair{
    return({
        ...quiz,
        a: {
            ...quiz.a,
            text: {
                ...style,
                content: quiz.a.text.content
            }
        },
        b: {
            ...quiz.b,
            text: {
                ...style,
                content: quiz.b.text.content
            }
        }
    });
}

// eslint-disable-next-line
const reducerMethods: {[type: string]: (state: MemoryState, action: any) => MemoryState} = {
    ...abstractGameReducerMethods,
    MEMORY_APPLY_STYLE: (state, action) => {
        return{
            ...state,
            pair: memoryTransformQuizToStyle(state.pair, state.style)
        }
    },
    MEMORY_SAVE_STYLE: (state, action) => {
        return{
            ...state,
            style: action.newStyle
        }
    },
    GAMES_SET_ACTIVE_TRANSLATION: (state, action) => {
        return {
            ...state,
            pair: initialState().pair
        }
    },
    MEMORY_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name] : action.value
            }
        }
    },
    MEMORY_PAIR_RESET: (state) => {
        return{
            ...state,
            pair: initialState().pair
        }
    },
    MEMORY_PAIR_SET: (state, action) => {
        return{
            ...state,
            pair: action.pair
        }
    },
    MEMORY_PAIR_SET_IDENTICAL: (state, action) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                identicalCards: action.value
            }
        }
    },
    MEMORY_PAIR_SET_SOUND: (state, action: { card: Card; value: SoundUploadType|null }) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    sound: action.value
                }
            },
            ui: {
                ...state.ui,
                [action.card]:{
                    ...state.ui[action.card],
                    showSoundForm: false
                }
            }
        }
    },
    MEMORY_PAIR_SET_IMAGE: (state, action: {card: Card; value: ImageUploadType|null}) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    image: action.value
                }
            },
            ui: {
                ...state.ui,
                [action.card]:{
                    ...state.ui[action.card],
                    showImageForm: false
                }
            }
        }
    },
    MEMORY_PAIR_SET_TEXT: (state, action: { card: Card; value: string }) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    text:{
                        ...state.pair[action.card].text,
                        content: action.value
                    }
                }
            }
        }
    },
    MEMORY_PAIR_SET_FONT: (state, action: { card: Card; value: string }) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    text:{
                        ...state.pair[action.card].text,
                        fontFamily: action.value
                    }
                }
            }
        }
    },
    MEMORY_PAIR_SET_TEXT_ALIGNMENT: (state, action: { card: Card; value: TextAlignment }) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    text:{
                        ...state.pair[action.card].text,
                        textAlignment: action.value
                    }
                }
            }
        }
    },
    MEMORY_PAIR_SET_FONT_COLOR: (state, action: { card: Card; value: string }) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    text:{
                        ...state.pair[action.card].text,
                        fontColorHex: action.value
                    }
                }
            }
        }
    },
    MEMORY_PAIR_SET_FONT_SIZE: (state, action: { card: Card; value: number }) => {
        return{
            ...state,
            pair: {
                ...state.pair,
                [action.card]: {
                    ...state.pair[action.card],
                    text:{
                        ...state.pair[action.card].text,
                        fontSize: action.value
                    }
                }
            }
        }
    },
    MEMORY_RESET_GAME: () => {
        return initialState();
    },
    MEMORY_PAIR_SHOW_SOUNDFORM: (state, action: { card: Card; show: boolean }) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                [action.card]:{
                    ...state.ui[action.card],
                    showSoundForm: action.show
                }
            }
        };
    },
    MEMORY_PAIR_SHOW_FONTCOLORFORM: (state, action: { card: Card; show: boolean }) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                [action.card]:{
                    ...state.ui[action.card],
                    showColorPicker: action.show
                }
            }
        };
    },
    MEMORY_SHOW_PAIRFORM: (state, action) => {
        return {
            ...state,
            ui: {
                ...state.ui,
                showNewPairForm: action.show
            }
        };
    },
    MEMORY_CREATE_PAIR_REQUEST: (state) => {
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                createPair: true,
            }
        };
    },
    MEMORY_CREATE_PAIR_RESPONSE: (state, action) => {
        return {
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                showNewPairForm: false
            },
            isLoading: {
                ...state.isLoading,
                createPair: false,
            }
        };
    },
    MEMORY_EDIT_PAIR_REQUEST: (state) => {
        return{
            ...state,
            isLoading: {
                ...state.isLoading,
                editPair: true
            }
        };
    },
    MEMORY_EDIT_PAIR_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui: {
                ...state.ui,
                showNewPairForm: false,
            },
            isLoading: {
                ...state.isLoading,
                editPair: false
            }
        };
    },
    MEMORY_DELETE_PAIR_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                deletePair: true,
            }
        }
    },
    MEMORY_DELETE_PAIR_RESPONSE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showNewPairForm: false
            },
            isLoading:{
                ...state.isLoading,
                deletePair: false
            },
            game: action.data
        }
    },
    MYGAMES_DELETE_GAME_RESPONSE: (state) => {
        return{
            ...state,
            game: initialState().game,
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== 'memory') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<MemoryState, any> = (state, action) => {
    state = state || initialState();
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};