import React from 'react';
import {Translate} from 'react-localize-redux';
import { FetchError } from '../../services/FetchHelper';
import {ErrorType} from "../../store/Error";


const capitalize = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const FieldErrors = (props: {errors: undefined | false | ErrorType | FetchError; fieldname: string}) => {
    const {errors: _errors, fieldname} = props;

    const errors: ErrorType|undefined = 
        _errors && _errors instanceof FetchError && _errors.status === 400 && _errors.isJson 
            ? { status: _errors.status, data: _errors.body } 
            : _errors ? _errors : undefined; 

    if(!errors || !errors.data) return null;

    let fieldErrors = errors.data[fieldname];
    if(!fieldErrors){
        fieldErrors = errors.data[capitalize(fieldname)];
    }
    if(!fieldErrors) return null;
    
    return(
        <div>
            {fieldErrors.map(x => <div key={x} className='form-error'><Translate id={x}/></div>)}
        </div>
    );
        
};

export default FieldErrors;