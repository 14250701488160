import React from 'react';
import {BikeRaceQuiz} from "../../../model/Game/BikeRace/BikeRaceQuiz";
import ClipboardImage from '../../../images/games/bikerace/clipboard.png';
import CorrectImage from '../../../images/games/bikerace/correct.png';
import WrongImage from '../../../images/games/bikerace/wrong.png';
import {DbOrUploadImage} from "../../../services/ImageHelper";
import TextView from '../../TextView';

interface Props {
    quiz: BikeRaceQuiz;
    onClick?: () => void;
    scaleFactor?: number;
}

export const BikeRaceQuizPreview = (props: Props) => {

    const scaleFactor = props.scaleFactor || (2/3.0);


    const previewStyle = {
        width: 370 * scaleFactor + 'px',
        height: 557 * scaleFactor + 'px',
        marginRight: 40 * scaleFactor + 'px',
        marginTop: 40 * scaleFactor + 'px'
    };

    const baseAnswerStyle= {
        height: 107 * scaleFactor + 'px',
        marginBottom: 40 * scaleFactor + 'px'
    };

    const correctAnswerStyle = {
        ...baseAnswerStyle,
        marginLeft: 40 * scaleFactor + 'px',
        opacity: props.quiz.correctAnswer ? 1 : 0.2
    };

    const wrongAnswerStyle= {
        ...baseAnswerStyle,
        marginRight: 40 * scaleFactor + 'px',
        opacity: props.quiz.correctAnswer ? 0.1 : 1
    };

    const questionBlockStyle = {
        left: 40 * scaleFactor + 'px',
        right: 40 * scaleFactor + 'px',
        top: 90 * scaleFactor + 'px',
        height: 300 * scaleFactor + 'px'
    };

    const imageStyle = {
        height: 150 * scaleFactor + 'px'
    };

    return(
        <div style={previewStyle} className={`bike-race-preview-quiz${props.onClick ? ' clickable' : ''}`} onClick={props.onClick || undefined}>
            <img className='clipboard-image' src={ClipboardImage} alt='' />

            <div className='question-block' style={questionBlockStyle}>
                { props.quiz.question.image &&
                    <div className='question-image' style={imageStyle}>
                        <DbOrUploadImage image={props.quiz.question.image} style={imageStyle}/>
                    </div>
                }
                { props.quiz.question.text &&
                    <TextView className='question-text' element='p' text={props.quiz.question.text} scale={scaleFactor}  />
                }
            </div>

            <div className='answers'>
                <div className={`answer ${props.quiz.correctAnswer ? ' correct' : ''}`}>
                    <img style={correctAnswerStyle} src={CorrectImage} alt='correct'/>
                </div>
                <div className={`answer ${props.quiz.correctAnswer ? ' correct' : ''}`}>
                    <img style={wrongAnswerStyle} src={WrongImage} alt='wrong'/>
                </div>
            </div>
        </div>
    )
};