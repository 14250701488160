import React from 'react';
import Game from "../ModelPreview/Game";
import {FeaturedGame} from "../../model/FeaturedGame";
import {Button} from "../forms/FormGroup";
import * as PlaylistStore from '../../store/PlayListStore';
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {bindActionCreators} from "redux";
import {isDbImage} from "../../services/ImageHelper";
import TextView from "../TextView";
import Author from "../ModelPreview/Author";
import {Loading} from "../Loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate} from "react-localize-redux";
import * as MeStore from "../../store/Me";
import * as OrganizationStore from '../../store/OrganizationStore';
import * as GlobalStore from '../../store/GlobalComponentStore';
import PlayListPreview from "../ModelPreview/PlayListPreview";
import { TextAlignment } from '../../model/Game/TextType';

interface Props{
    fg: FeaturedGame;
    playlistActions: typeof PlaylistStore.actionCreators;
    orgActions: typeof OrganizationStore.actionCreators;
    globalActions: typeof GlobalStore.actionCreators;
    meState: MeStore.MeState;
    meActions: typeof MeStore.actionCreators;
}

const FeaturedGameView = (props: Props) => {
    const model = props.fg;
    const imgUrl =  isDbImage(model.image) ? model.image.imagePath : model.image && model.image.previewBlob;

    const account = model.account;
    const following = account && props.meState.account && props.meState.account.followings.includes(account.id);
    const followFunction = () => {
        if(following && account) props.meActions.unfollowAuthor(account.id);
        else if(account) props.meActions.followAuthor(account.id);
    };
    const followLoad = props.meState.isLoading.followAuthor || props.meState.isLoading.unfollowAuthor;

    return(
        <div className='featured-game'>
            <img className={'hero-image'} src={imgUrl} alt={'hero-img'}/>
            <div className={'featured-content'}>
                <div className='centered'>
                    <div className='game-info'>
                        <div className='text'>
                            <div style={{
                                textAlign: model.labelText?.textAlignment === TextAlignment.Left
                                    ? 'left'
                                    : model.labelText?.textAlignment === TextAlignment.Center
                                        ? 'center'
                                        : "right"}}
                            >
                                <TextView className={'featured-type'} element={'h1'} text={model.labelText} style={{ background: model.labelColorHex }} />
                            </div>
                            <TextView element='h1' text={model.text} overrideFontSize={`calc(${model.text?.fontSize || 0}px + 2vw)`} />
                            <TextView element='p' text={model.descriptionText} overrideFontSize={`calc(${model.descriptionText?.fontSize || 0}px + 0.5vw)`} />
                        </div>
                        {model.game &&
                            <div className='game-wrapper'>
                                <Game game={model.game} noMenu />
                            </div>
                        }
                        {model.playlist &&
                            <div className='playlist-wrapper'>
                                <PlayListPreview playlist={model.playlist} noMenu />
                            </div>
                        }
                        {account &&
                            <div className='account-wrapper'>
                                <Author account={account} fullImage hideName />
                            </div>
                        }
                    </div>
                    <div className='game-actions'>
                        {model.game &&
                            <>
                                <Button icon={'file-import'} name={'see_content'} link={`workshop/${model.game?.gameType}/edit/${model.game?.id}`} />
                                <Button icon={'plus'} name={'playlist_add_to'} onClick={() => props.globalActions.playlistAddItemForm(model.game)} />
                            </>
                        }
                        { model.playlist &&
                            <>
                                <Button icon={'file-import'} name={'see_content'} link={`workshop/playlist/edit/${model.playlistId}`} />
                                <Button icon={'plus'} name={'floor_add'} onClick={() => props.orgActions.showChooseFloor(model.playlistId || false)} />
                            </>
                        }
                        {account &&
                            <>
                                <Button icon={'file-import'} name={'account_public'} link={`library/profile/${model.accountId}`} />
                                {account.id !== props.meState.account?.id &&
                                <div className='follow-btn-container'>
                                    <button className='follow btn' onClick={following ? undefined : followFunction} disabled={!!(followLoad || following)}>
                                        <Loading visible={followLoad} noBackground/>
                                        <FontAwesomeIcon icon={following ? 'check' : 'user-plus'} className='icon'/>
                                        <Translate
                                            id={following ? 'pp_following' : 'account_follow' }
                                        />
                                    </button>
                                </div>
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
};

export default connect(
    (state: AppState) => ({ meState: state.me }),
    dispatch => ({
        playlistActions: bindActionCreators(PlaylistStore.actionCreators, dispatch),
        meActions: bindActionCreators(MeStore.actionCreators, dispatch),
        orgActions: bindActionCreators(OrganizationStore.actionCreators, dispatch),
        globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)
    })
)
(FeaturedGameView);