import React from 'react';

interface Props {
    className?: string;
    pathColor?: string;
}

export const PuzzleGrid3x2 = (props: Props) => {
    return (
        <svg version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 560.49 374"
            className={props.className}
        >
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M559.48,186.55h-48.44c-13.76,0-21.62,0.12-23.47,3.33c-0.86,1.57-0.26,3.66,1.7,6.87
				c3.18,5.02,4.96,11.63,4.96,18.18c0,15.22-12.32,27.55-27.48,27.55c-15.14,0-27.47-12.33-27.47-27.55
				c0-6.55,1.75-13.15,4.97-18.18c1.95-3.21,2.44-5.31,1.64-6.87c-1.79-3.2-9.64-3.33-23.4-3.33h-48.5v-48.49
				c0-13.03,0.1-21.11,2.88-22.62c1.18-0.66,3.05-0.17,5.86,1.65c5.16,3.27,12.03,5.08,18.78,5.08c15.64,0,28.36-12.72,28.36-28.39
				c0-15.69-12.72-28.46-28.36-28.46c-6.75,0-13.62,1.88-18.78,5.15c-2.82,1.76-4.68,2.24-5.86,1.59
				c-2.78-1.53-2.88-9.54-2.88-22.63V1h185.49L559.48,186.55L559.48,186.55z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M1,1h185.55v48.44c0,13.08-0.16,21.1-2.87,22.63c-1.23,0.65-3.11,0.17-5.92-1.59
				c-5.17-3.27-11.97-5.15-18.69-5.15c-15.69,0-28.45,12.77-28.45,28.46c0,15.67,12.76,28.39,28.45,28.39
				c6.71,0,13.51-1.81,18.69-5.08c2.81-1.82,4.68-2.31,5.92-1.65c2.72,1.52,2.87,9.59,2.87,22.62v48.49h-48.49
				c-13.77,0-21.63,0.12-23.4,3.33c-0.87,1.57-0.34,3.66,1.64,6.87c3.15,5.02,4.97,11.63,4.97,18.18
				c0,15.22-12.34,27.55-27.48,27.55S66.3,230.16,66.3,214.94c0-6.55,1.82-13.15,4.97-18.18c1.98-3.21,2.52-5.31,1.64-6.87
				c-1.77-3.2-9.63-3.33-23.39-3.33H1V1z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M559.48,373H373.99v-48.49c0-16.1-0.26-23.79-5.02-23.79c-1.34,0-2.98,0.65-5.21,2.04
                c-5.01,3.22-11.66,4.97-18.22,4.97c-15.16,0-27.53-12.34-27.53-27.49s12.37-27.48,27.53-27.48c6.57,0,13.21,1.82,18.22,4.96
                c2.23,1.39,3.87,2.04,5.21,2.04c4.76,0,5.02-7.71,5.02-23.8v-48.5h48.5c13.03,0,21.11,0.16,22.62,2.89
                c0.65,1.22,0.05,3.1-1.66,5.91c-3.31,5.17-5.13,11.99-5.13,18.68c0,15.69,12.69,28.46,28.44,28.46c15.64,0,28.46-12.76,28.46-28.46
                c0-6.69-1.93-13.51-5.13-18.68c-1.85-2.81-2.34-4.68-1.66-5.91c1.51-2.73,9.58-2.89,22.62-2.89h48.44V373H559.48z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M187.46,373v-48.49c0-1.71,0.01-3.26,0.01-4.8c0.01-0.68,0.02-1.28,0.03-2.01
                c0.01-0.72,0.03-1.54,0.05-2.29c0.01-0.78,0.05-1.45,0.08-2.17c0.03-0.63,0.06-1.14,0.09-1.6c0.05-0.68,0.07-1.33,0.1-1.94
                c0.05-0.5,0.08-0.86,0.15-1.32c0.07-0.55,0.15-1.05,0.2-1.6c0.07-0.36,0.15-0.72,0.23-1.04c0.11-0.41,0.15-0.82,0.27-1.14
                c0.14-0.38,0.23-0.72,0.39-0.96c0.11-0.26,0.18-0.49,0.29-0.63c0.25-0.38,0.46-0.6,0.7-0.79c0.09-0.08,0.17-0.18,0.27-0.23
                c0.36-0.27,0.75-0.29,1.15-0.29c0.34,0,0.71,0,1.13,0.05c0.14,0.03,0.29,0.1,0.39,0.14c0.33,0.14,0.67,0.29,1.05,0.5
                c0.19,0.03,0.31,0.05,0.52,0.15c0.51,0.32,1.09,0.62,1.7,1.01c3.9,2.5,8.67,4.05,13.72,4.73c1.64,0.28,3.31,0.4,5.03,0.4
                c15.69,0,28.44-12.8,28.44-28.44c0-15.67-12.76-28.45-28.44-28.45c-1.73,0-3.39,0.14-5.03,0.34c-5.05,0.66-9.82,2.38-13.72,4.79
                c-0.61,0.41-1.18,0.71-1.71,0.97c-0.19,0.09-0.3,0.17-0.49,0.25c-0.38,0.14-0.75,0.31-1.07,0.45c-0.1,0.01-0.24,0.03-0.37,0.07
                c-0.43,0.11-0.8,0.18-1.14,0.17c-0.41-0.01-0.83-0.15-1.19-0.36c-0.03-0.02-0.05-0.05-0.08-0.07c-0.33-0.23-0.62-0.57-0.93-1.05
                c-0.03-0.05-0.03-0.12-0.03-0.19c-0.28-0.41-0.46-0.98-0.67-1.6c-0.03-0.12-0.05-0.25-0.05-0.35c-0.17-0.62-0.32-1.34-0.4-2.08
                c-0.03-0.21-0.05-0.38-0.09-0.63c-0.11-0.75-0.21-1.55-0.24-2.44c0-0.26-0.02-0.57-0.04-0.87c-0.06-0.85-0.11-1.86-0.15-2.85
                c-0.01-0.35-0.03-0.66-0.04-1.01c-0.03-1.09-0.05-2.27-0.07-3.47c0-0.35-0.01-0.65-0.01-0.98c-0.01-1.57-0.01-3.18-0.01-4.91v-48.5
                h48.51c13.76,0,21.67-0.17,23.46-3.39c0.81-1.51,0.33-3.66-1.71-6.83c-3.14-5.01-4.96-11.67-4.96-18.24
                c0-15.15,12.33-27.47,27.48-27.47c15.22,0,27.56,12.33,27.56,27.47c0,6.57-1.83,13.23-5.04,18.24c-1.97,3.17-2.52,5.32-1.64,6.83
                c1.77,3.22,9.63,3.39,23.44,3.39h48.45v48.5c0,14.63-0.15,22.88-4.04,22.88c-1.1,0-2.67-0.63-4.74-1.92
                c-5.11-3.26-12-5.13-18.69-5.13c-15.69,0-28.45,12.78-28.45,28.45c0,15.64,12.75,28.44,28.45,28.44c6.69,0,13.58-1.89,18.69-5.13
                c2.07-1.25,3.64-1.85,4.74-1.85c3.89,0,4.04,8.19,4.04,22.8V373L187.46,373L187.46,373z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M187.46,1h185.55v48.44c0,13.81,0.18,21.69,3.38,23.47c1.58,0.88,3.67,0.33,6.91-1.65
                c5-3.2,11.66-5.02,18.21-5.02c15.16,0,27.5,12.34,27.5,27.55c0,15.14-12.34,27.48-27.5,27.48c-6.55,0-13.21-1.82-18.21-4.96
                c-3.24-2.04-5.33-2.51-6.91-1.7c-3.2,1.75-3.38,9.68-3.38,23.46v48.49h-48.45c-13.08,0-21.09-0.16-22.61-2.89
                c-0.66-1.21-0.16-3.09,1.6-5.9c3.26-5.17,5.13-11.99,5.13-18.75c0-15.68-12.76-28.44-28.45-28.44s-28.42,12.76-28.42,28.44
                c0,6.77,1.85,13.58,5.11,18.75c1.81,2.81,2.31,4.69,1.66,5.9c-1.53,2.74-9.61,2.89-22.62,2.89h-48.51v-48.49
                c0-13.77-0.13-21.7-3.32-23.46c-1.57-0.81-3.68-0.35-6.9,1.7c-5.01,3.14-11.63,4.96-18.18,4.96c-15.21,0-27.54-12.34-27.54-27.48
                c0-15.21,12.33-27.55,27.54-27.55c6.54,0,13.16,1.82,18.18,5.02c3.22,1.98,5.32,2.53,6.9,1.65c3.19-1.77,3.32-9.66,3.32-23.47
                L187.46,1L187.46,1z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M49.5,187.46c13.03,0,21.09,0.16,22.61,2.89c0.14,0.28,0.25,0.63,0.27,1.01
                c0.06,1.18-0.61,2.79-1.92,4.89c-2.43,3.85-4.12,8.67-4.8,13.66c-0.2,1.7-0.33,3.36-0.33,5.02c0,15.69,12.76,28.46,28.45,28.46
                s28.45-12.76,28.45-28.46c0-1.66-0.14-3.32-0.34-5.02c-0.67-4.99-2.37-9.81-4.79-13.66c-1.32-2.1-1.99-3.72-1.93-4.89
                c0.02-0.39,0.12-0.73,0.26-1.01c1.52-2.73,9.6-2.89,22.62-2.89h48.49v48.5c0,1.83,0.01,3.52,0.03,5.18
                c0.01,0.6,0.02,1.14,0.03,1.78c0.01,0.91,0.03,1.86,0.06,2.71c0.02,0.73,0.04,1.38,0.04,2.06c0.01,0.64,0.05,1.24,0.09,1.8
                c0.06,0.73,0.12,1.38,0.19,1.98c0.05,0.46,0.07,0.89,0.11,1.29c0.09,0.6,0.19,1.16,0.32,1.71c0.02,0.33,0.09,0.59,0.17,0.86
                c0.15,0.52,0.23,1.04,0.41,1.43c0.1,0.26,0.17,0.4,0.3,0.65c0.19,0.35,0.32,0.73,0.56,0.98c0.2,0.29,0.45,0.52,0.75,0.69
                c0.19,0.18,0.38,0.27,0.65,0.39c0.35,0.17,0.74,0.23,1.15,0.25c0,0,0.03,0.03,0.1,0.03c0.35,0,0.73-0.06,1.15-0.11
                c0.1-0.03,0.21-0.08,0.29-0.11c0.42-0.15,0.89-0.27,1.38-0.53c0.15-0.06,0.33-0.1,0.47-0.2c0.63-0.29,1.26-0.63,1.97-1.09
                c5-3.14,11.66-4.96,18.23-4.96c15.14,0,27.49,12.34,27.49,27.48c0,15.15-12.35,27.49-27.49,27.49c-6.57,0-13.22-1.76-18.23-4.97
                c-0.71-0.4-1.34-0.79-1.98-1.05c-0.13-0.08-0.31-0.15-0.45-0.24c-0.49-0.25-0.96-0.46-1.4-0.49c-0.05,0-0.17-0.05-0.27-0.07
                c-0.42-0.11-0.8-0.18-1.15-0.18c-0.07,0-0.1,0.04-0.1,0.04c-0.42,0.02-0.8,0.15-1.15,0.22c-0.27,0.07-0.46,0.24-0.65,0.41
                c-0.3,0.24-0.56,0.34-0.75,0.7c-0.24,0.31-0.37,0.63-0.56,1.04c-0.13,0.12-0.2,0.34-0.3,0.63c-0.18,0.34-0.27,0.85-0.41,1.38
                c-0.08,0.22-0.15,0.55-0.17,0.92c-0.14,0.5-0.23,1.06-0.32,1.67c-0.03,0.46-0.06,0.83-0.11,1.25c-0.07,0.69-0.13,1.33-0.19,2.02
                c-0.04,0.55-0.07,1.15-0.09,1.76c0,0.66-0.02,1.31-0.04,2.04c-0.03,0.87-0.05,1.85-0.06,2.83c-0.01,0.56-0.02,1.09-0.03,1.71
                c-0.02,1.65-0.03,3.41-0.03,5.17V373H1V187.46H49.5z"/>
        </svg>
    )
}

export const PuzzleGrid6x4 = (props: Props) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            viewBox="0 0 753.46 502"
            className={props.className}
        >
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M126,1v32.82c0,13.84-0.3,18.18-6.37,14.36c-3.57-2.27-8.14-3.42-12.4-3.42
			c-10.36,0-18.74,8.41-18.74,18.77c0,10.36,8.38,18.77,18.74,18.77c4.26,0,8.83-1.14,12.4-3.42c6.07-3.81,6.37,0.53,6.37,14.29V126
			H95.58h-2.4c-13.8,0-18.14,0.34-14.33,6.41c2.27,3.49,3.38,8.1,3.38,12.36c0,10.36-8.37,18.77-18.73,18.77
			c-10.36,0-18.77-8.41-18.77-18.77c0-4.26,1.14-8.86,3.38-12.36c3.85-6.07-0.49-6.41-14.29-6.41H1V1H126z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M375.92,1v30.42v2.4c0,13.84-0.26,18.18-6.33,14.36c-3.57-2.27-8.1-3.42-12.36-3.42
			c-10.36,0-18.77,8.41-18.77,18.77c0,10.36,8.41,18.77,18.77,18.77c4.26,0,8.79-1.14,12.36-3.42c6.07-3.81,6.33,0.53,6.33,14.29
			V126h32.82c13.84,0,18.18-0.26,14.36-6.33c-2.27-3.57-3.42-8.18-3.42-12.43c0-10.28,8.41-18.7,18.77-18.7
			c10.35,0,18.77,8.41,18.77,18.7c0,4.26-1.15,8.86-3.42,12.43c-3.81,6.07,0.53,6.33,14.29,6.33h32.83V93.17
			c0-13.76,0.34-18.1,6.41-14.29c3.49,2.27,8.1,3.42,12.36,3.42c10.36,0,18.77-8.41,18.77-18.77c0-10.36-8.41-18.77-18.77-18.77
			c-4.26,0-8.86,1.14-12.36,3.42c-6.07,3.81-6.41-0.53-6.41-14.36v-2.4V1H375.92z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M126,1v30.42v2.4c0,13.84-0.3,18.18-6.37,14.36c-3.57-2.27-8.14-3.42-12.4-3.42
			c-10.36,0-18.74,8.41-18.74,18.77c0,10.36,8.38,18.77,18.74,18.77c4.26,0,8.83-1.14,12.4-3.42c6.07-3.81,6.37,0.53,6.37,14.29V126
			h32.79c13.8,0,18.18-0.26,14.33-6.33c-2.23-3.57-3.38-8.18-3.38-12.43c0-10.28,8.37-18.7,18.73-18.7
			c10.36,0,18.77,8.41,18.77,18.7c0,4.26-1.14,8.86-3.38,12.43c-3.85,6.07,0.49,6.33,14.33,6.33h32.79V93.17
			c0-13.76,0.3-18.1,6.37-14.29c3.57,2.27,8.14,3.42,12.4,3.42c10.36,0,18.74-8.41,18.74-18.77c0-10.36-8.38-18.77-18.74-18.77
			c-4.26,0-8.83,1.14-12.4,3.42c-6.07,3.81-6.37-0.53-6.37-14.36v-2.4V1H126z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M457.23,269.77c0-4.26-1.15-8.86-3.42-12.43c-3.81-6.07,0.53-6.33,14.29-6.33h32.83v-32.82
			c0-13.76-0.26-18.18-6.33-14.29c-3.57,2.2-8.18,3.34-12.43,3.34c-10.28,0-18.7-8.33-18.7-18.69c0-10.36,8.41-18.77,18.7-18.77
			c4.26,0,8.86,1.14,12.43,3.34c6.07,3.89,6.33-0.45,6.33-14.29V126H468.1c-13.76,0-18.1-0.26-14.29-6.33
			c2.27-3.57,3.42-8.18,3.42-12.43c0-10.28-8.41-18.7-18.77-18.7c-10.35,0-18.77,8.41-18.77,18.7c0,4.26,1.15,8.86,3.42,12.43
			c3.81,6.07-0.53,6.33-14.36,6.33h-2.4h-30.42v32.83c0,13.84,0.34,18.18,6.41,14.29c3.57-2.2,8.1-3.34,12.36-3.34
			c10.36,0,18.77,8.41,18.77,18.77c0,10.36-8.41,18.69-18.77,18.69c-4.26,0-8.79-1.14-12.36-3.34c-6.07-3.89-6.41,0.53-6.41,14.29
			V251h30.42h2.4c13.84,0,18.18,0.26,14.36,6.33c-2.27,3.57-3.42,8.18-3.42,12.43c0,10.36,8.41,18.7,18.77,18.7
			C448.81,288.46,457.23,280.13,457.23,269.77z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M500.92,501v-32.82c0-13.84-0.26-18.18-6.33-14.36c-3.57,2.27-8.18,3.42-12.43,3.42
			c-10.28,0-18.7-8.41-18.7-18.77c0-10.35,8.41-18.77,18.7-18.77c4.26,0,8.86,1.15,12.43,3.42c6.07,3.81,6.33-0.53,6.33-14.29V376
			H468.1c-13.76,0-18.1-0.34-14.29-6.41c2.27-3.49,3.42-8.1,3.42-12.36c0-10.36-8.41-18.77-18.77-18.77
			c-10.35,0-18.77,8.41-18.77,18.77c0,4.26,1.15,8.86,3.42,12.36c3.81,6.07-0.53,6.41-14.36,6.41h-2.4h-30.42v32.82
			c0,13.76,0.34,18.1,6.41,14.29c3.57-2.27,8.1-3.42,12.36-3.42c10.36,0,18.77,8.41,18.77,18.77c0,10.35-8.41,18.77-18.77,18.77
			c-4.26,0-8.79-1.15-12.36-3.42c-6.07-3.81-6.41,0.53-6.41,14.36V501H500.92z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M332.23,394.77c0-4.26-1.15-8.86-3.42-12.43c-3.81-6.07,0.53-6.33,14.36-6.33h32.75v-32.83
			c0-13.84-0.26-18.18-6.33-14.29c-3.57,2.19-8.1,3.34-12.36,3.34c-10.36,0-18.77-8.41-18.77-18.77s8.41-18.69,18.77-18.69
			c4.26,0,8.79,1.15,12.36,3.34c6.07,3.89,6.33-0.53,6.33-14.29V251h-32.75c-13.84,0-18.18-0.26-14.36-6.33
			c2.27-3.57,3.42-8.18,3.42-12.43c0-10.36-8.41-18.7-18.77-18.7c-10.36,0-18.73,8.34-18.73,18.7c0,4.26,1.11,8.86,3.38,12.43
			c3.81,6.07-0.53,6.33-14.33,6.33h-2.4h-30.42v32.82c0,13.76,0.3,18.18,6.37,14.29c3.57-2.19,8.14-3.34,12.4-3.34
			c10.36,0,18.74,8.34,18.74,18.69s-8.38,18.77-18.74,18.77c-4.26,0-8.83-1.15-12.4-3.34c-6.07-3.89-6.37,0.45-6.37,14.29V376h30.42
			h2.4c13.8,0,18.14,0.26,14.33,6.33c-2.27,3.57-3.38,8.18-3.38,12.43c0,10.28,8.37,18.7,18.73,18.7
			C323.81,413.46,332.23,405.05,332.23,394.77z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M250.96,1v32.82c0,13.84,0.3,18.18,6.37,14.36c3.57-2.27,8.14-3.42,12.4-3.42
			c10.36,0,18.74,8.41,18.74,18.77c0,10.36-8.38,18.77-18.74,18.77c-4.26,0-8.83-1.14-12.4-3.42c-6.07-3.81-6.37,0.53-6.37,14.29
			V126h30.42h2.4c13.8,0,18.14,0.34,14.33,6.41c-2.27,3.49-3.38,8.1-3.38,12.36c0,10.36,8.37,18.77,18.73,18.77
			c10.35,0,18.77-8.41,18.77-18.77c0-4.26-1.15-8.86-3.42-12.36c-3.81-6.07,0.53-6.41,14.36-6.41h32.75V93.17
			c0-13.76-0.26-18.1-6.33-14.29c-3.57,2.27-8.1,3.42-12.36,3.42c-10.36,0-18.77-8.41-18.77-18.77c0-10.36,8.41-18.77,18.77-18.77
			c4.26,0,8.79,1.14,12.36,3.42c6.07,3.81,6.33-0.53,6.33-14.36V1H250.96z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M357.23,332.23c4.26,0,8.79-1.15,12.36-3.34c6.07-3.89,6.33,0.45,6.33,14.29V376h32.82
			c13.84,0,18.18-0.34,14.36-6.41c-2.27-3.49-3.42-8.1-3.42-12.36c0-10.36,8.41-18.77,18.77-18.77c10.35,0,18.77,8.41,18.77,18.77
			c0,4.26-1.15,8.86-3.42,12.36c-3.81,6.07,0.53,6.41,14.29,6.41h32.83v-32.83c0-13.84,0.34-18.18,6.41-14.29
			c3.49,2.19,8.1,3.34,12.36,3.34c10.36,0,18.77-8.41,18.77-18.77s-8.41-18.69-18.77-18.69c-4.26,0-8.86,1.15-12.36,3.34
			c-6.07,3.89-6.41-0.53-6.41-14.29v-2.4V251H468.1c-13.76,0-18.1,0.26-14.29,6.33c2.27,3.57,3.42,8.18,3.42,12.43
			c0,10.36-8.41,18.7-18.77,18.7c-10.35,0-18.77-8.34-18.77-18.7c0-4.26,1.15-8.86,3.42-12.43c3.81-6.07-0.53-6.33-14.36-6.33
			h-32.82v30.42v2.4c0,13.76-0.26,18.18-6.33,14.29c-3.57-2.19-8.1-3.34-12.36-3.34c-10.36,0-18.77,8.34-18.77,18.69
			S346.87,332.23,357.23,332.23z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M232.23,207.23c4.26,0,8.83-1.14,12.36-3.34c6.07-3.89,6.37,0.53,6.37,14.29V251h32.83
			c13.8,0,18.14-0.26,14.33-6.33c-2.27-3.57-3.38-8.18-3.38-12.43c0-10.36,8.37-18.7,18.73-18.7c10.35,0,18.77,8.34,18.77,18.7
			c0,4.26-1.15,8.86-3.42,12.43c-3.81,6.07,0.53,6.33,14.36,6.33h32.75v-32.82c0-13.76,0.34-18.18,6.41-14.29
			c3.57,2.2,8.1,3.34,12.36,3.34c10.36,0,18.77-8.33,18.77-18.69c0-10.36-8.41-18.77-18.77-18.77c-4.26,0-8.79,1.14-12.36,3.34
			c-6.07,3.89-6.41-0.45-6.41-14.29v-2.4V126h-32.75c-13.84,0-18.18,0.34-14.36,6.41c2.27,3.49,3.42,8.1,3.42,12.36
			c0,10.36-8.41,18.77-18.77,18.77c-10.36,0-18.73-8.41-18.73-18.77c0-4.26,1.11-8.86,3.38-12.36c3.81-6.07-0.53-6.41-14.33-6.41
			h-32.83v30.42v2.4c0,13.84-0.3,18.18-6.37,14.29c-3.53-2.2-8.1-3.34-12.36-3.34c-10.36,0-18.77,8.41-18.77,18.77
			C213.46,198.9,221.87,207.23,232.23,207.23z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M232.23,457.23c4.26,0,8.83-1.15,12.36-3.42c6.07-3.81,6.37,0.53,6.37,14.36V501h124.96
			v-32.82c0-13.84,0.34-18.18,6.41-14.36c3.57,2.27,8.1,3.42,12.36,3.42c10.36,0,18.77-8.41,18.77-18.77
			c0-10.35-8.41-18.77-18.77-18.77c-4.26,0-8.79,1.15-12.36,3.42c-6.07,3.81-6.41-0.53-6.41-14.29v-2.4V376h-32.75
			c-13.84,0-18.18,0.26-14.36,6.33c2.27,3.57,3.42,8.18,3.42,12.43c0,10.28-8.41,18.7-18.77,18.7c-10.36,0-18.73-8.41-18.73-18.7
			c0-4.26,1.11-8.86,3.38-12.43c3.81-6.07-0.53-6.33-14.33-6.33h-32.83v30.42v2.4c0,13.76-0.3,18.1-6.37,14.29
			c-3.53-2.27-8.1-3.42-12.36-3.42c-10.36,0-18.77,8.41-18.77,18.77C213.46,448.81,221.87,457.23,232.23,457.23z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M582.23,394.77c0-4.26-1.15-8.86-3.42-12.43c-3.81-6.07,0.53-6.33,14.36-6.33h32.75v-32.83
			c0-13.84-0.26-18.18-6.33-14.29c-3.57,2.19-8.1,3.34-12.36,3.34c-10.36,0-18.77-8.41-18.77-18.77s8.41-18.69,18.77-18.69
			c4.26,0,8.79,1.15,12.36,3.34c6.07,3.89,6.33-0.53,6.33-14.29V251h-32.75c-13.84,0-18.18-0.26-14.36-6.33
			c2.27-3.57,3.42-8.18,3.42-12.43c0-10.36-8.41-18.7-18.77-18.7c-10.36,0-18.73,8.34-18.73,18.7c0,4.26,1.11,8.86,3.38,12.43
			c3.81,6.07-0.53,6.33-14.33,6.33h-2.4h-30.42v32.82c0,13.76,0.3,18.18,6.37,14.29c3.57-2.19,8.14-3.34,12.4-3.34
			c10.36,0,18.74,8.34,18.74,18.69s-8.38,18.77-18.74,18.77c-4.26,0-8.83-1.15-12.4-3.34c-6.07-3.89-6.37,0.45-6.37,14.29V376h30.42
			h2.4c13.8,0,18.14,0.26,14.33,6.33c-2.27,3.57-3.38,8.18-3.38,12.43c0,10.28,8.37,18.7,18.73,18.7
			C573.81,413.46,582.23,405.05,582.23,394.77z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M500.96,1v32.82c0,13.84,0.3,18.18,6.37,14.36c3.57-2.27,8.14-3.42,12.4-3.42
			c10.36,0,18.74,8.41,18.74,18.77c0,10.36-8.38,18.77-18.74,18.77c-4.26,0-8.83-1.14-12.4-3.42c-6.07-3.81-6.37,0.53-6.37,14.29
			V126h30.42h2.4c13.8,0,18.14,0.34,14.33,6.41c-2.27,3.49-3.38,8.1-3.38,12.36c0,10.36,8.37,18.77,18.73,18.77
			c10.35,0,18.77-8.41,18.77-18.77c0-4.26-1.15-8.86-3.42-12.36c-3.81-6.07,0.53-6.41,14.36-6.41h32.75V93.17
			c0-13.76-0.26-18.1-6.33-14.29c-3.57,2.27-8.1,3.42-12.36,3.42c-10.36,0-18.77-8.41-18.77-18.77c0-10.36,8.41-18.77,18.77-18.77
			c4.26,0,8.79,1.14,12.36,3.42c6.07,3.81,6.33-0.53,6.33-14.36V1H500.96z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M482.23,207.23c4.26,0,8.83-1.14,12.36-3.34c6.07-3.89,6.37,0.53,6.37,14.29V251h32.83
			c13.8,0,18.14-0.26,14.33-6.33c-2.27-3.57-3.38-8.18-3.38-12.43c0-10.36,8.37-18.7,18.73-18.7c10.35,0,18.77,8.34,18.77,18.7
			c0,4.26-1.15,8.86-3.42,12.43c-3.81,6.07,0.53,6.33,14.36,6.33h32.75v-32.82c0-13.76,0.34-18.18,6.41-14.29
			c3.57,2.2,8.1,3.34,12.36,3.34c10.36,0,18.77-8.33,18.77-18.69c0-10.36-8.41-18.77-18.77-18.77c-4.26,0-8.79,1.14-12.36,3.34
			c-6.07,3.89-6.41-0.45-6.41-14.29v-2.4V126h-32.75c-13.84,0-18.18,0.34-14.36,6.41c2.27,3.49,3.42,8.1,3.42,12.36
			c0,10.36-8.41,18.77-18.77,18.77c-10.36,0-18.73-8.41-18.73-18.77c0-4.26,1.11-8.86,3.38-12.36c3.81-6.07-0.53-6.41-14.33-6.41
			h-32.83v30.42v2.4c0,13.84-0.3,18.18-6.37,14.29c-3.53-2.2-8.1-3.34-12.36-3.34c-10.36,0-18.77,8.41-18.77,18.77
			C463.46,198.9,471.87,207.23,482.23,207.23z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M482.23,457.23c4.26,0,8.83-1.15,12.36-3.42c6.07-3.81,6.37,0.53,6.37,14.36V501h124.96v-32.82
			c0-13.84,0.34-18.18,6.41-14.36c3.57,2.27,8.1,3.42,12.36,3.42c10.36,0,18.77-8.41,18.77-18.77c0-10.35-8.41-18.77-18.77-18.77
			c-4.26,0-8.79,1.15-12.36,3.42c-6.07,3.81-6.41-0.53-6.41-14.29v-2.4V376h-32.75c-13.84,0-18.18,0.26-14.36,6.33
			c2.27,3.57,3.42,8.18,3.42,12.43c0,10.28-8.41,18.7-18.77,18.7c-10.36,0-18.73-8.41-18.73-18.7c0-4.26,1.11-8.86,3.38-12.43
			c3.81-6.07-0.53-6.33-14.33-6.33h-32.83v30.42v2.4c0,13.76-0.3,18.1-6.37,14.29c-3.53-2.27-8.1-3.42-12.36-3.42
			c-10.36,0-18.77,8.41-18.77,18.77C463.46,448.81,471.87,457.23,482.23,457.23z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M207.23,269.77c0-4.26-1.14-8.86-3.38-12.43c-3.85-6.07,0.49-6.33,14.33-6.33h32.79v-32.82
			c0-13.76-0.3-18.18-6.37-14.29c-3.53,2.2-8.1,3.34-12.36,3.34c-10.36,0-18.77-8.33-18.77-18.69c0-10.36,8.41-18.77,18.77-18.77
			c4.26,0,8.83,1.14,12.36,3.34c6.07,3.89,6.37-0.45,6.37-14.29V126h-32.79c-13.84,0-18.18-0.26-14.33-6.33
			c2.23-3.57,3.38-8.18,3.38-12.43c0-10.28-8.41-18.7-18.77-18.7c-10.36,0-18.73,8.41-18.73,18.7c0,4.26,1.14,8.86,3.38,12.43
			c3.85,6.07-0.53,6.33-14.33,6.33h-2.4H126v32.83c0,13.84,0.3,18.18,6.33,14.29c3.57-2.2,8.14-3.34,12.4-3.34
			c10.36,0,18.74,8.41,18.74,18.77c0,10.36-8.38,18.69-18.74,18.69c-4.26,0-8.83-1.14-12.4-3.34c-6.03-3.89-6.33,0.53-6.33,14.29
			V251h30.38h2.4c13.8,0,18.18,0.26,14.33,6.33c-2.23,3.57-3.38,8.18-3.38,12.43c0,10.36,8.37,18.7,18.73,18.7
			C198.82,288.46,207.23,280.13,207.23,269.77z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M250.96,501v-32.82c0-13.84-0.3-18.18-6.37-14.36c-3.53,2.27-8.1,3.42-12.36,3.42
			c-10.36,0-18.77-8.41-18.77-18.77c0-10.35,8.41-18.77,18.77-18.77c4.26,0,8.83,1.15,12.36,3.42c6.07,3.81,6.37-0.53,6.37-14.29
			V376h-32.79c-13.84,0-18.18-0.34-14.33-6.41c2.23-3.49,3.38-8.1,3.38-12.36c0-10.36-8.41-18.77-18.77-18.77
			c-10.36,0-18.73,8.41-18.73,18.77c0,4.26,1.14,8.86,3.38,12.36c3.85,6.07-0.53,6.41-14.33,6.41h-2.4H126v32.82
			c0,13.76,0.3,18.1,6.33,14.29c3.57-2.27,8.14-3.42,12.4-3.42c10.36,0,18.74,8.41,18.74,18.77c0,10.35-8.38,18.77-18.74,18.77
			c-4.26,0-8.83-1.15-12.4-3.42c-6.03-3.81-6.33,0.53-6.33,14.36V501H250.96z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M1,376h30.42h2.4c13.8,0,18.14,0.26,14.29,6.33c-2.24,3.57-3.38,8.18-3.38,12.43
			c0,10.28,8.41,18.7,18.77,18.7c10.36,0,18.73-8.41,18.73-18.7c0-4.26-1.11-8.86-3.38-12.43c-3.81-6.07,0.53-6.33,14.33-6.33H126
			v-32.83c0-13.84-0.3-18.18-6.37-14.29c-3.57,2.19-8.14,3.34-12.4,3.34c-10.36,0-18.74-8.41-18.74-18.77s8.38-18.69,18.74-18.69
			c4.26,0,8.83,1.15,12.4,3.34c6.07,3.89,6.37-0.53,6.37-14.29V251H93.17c-13.8,0-18.14-0.26-14.33-6.33
			c2.27-3.57,3.38-8.18,3.38-12.43c0-10.36-8.37-18.7-18.73-18.7c-10.36,0-18.77,8.34-18.77,18.7c0,4.26,1.14,8.86,3.38,12.43
			c3.85,6.07-0.49,6.33-14.29,6.33h-2.4H1V376z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M107.23,332.23c4.26,0,8.83-1.15,12.4-3.34c6.07-3.89,6.37,0.45,6.37,14.29V376h32.79
			c13.8,0,18.18-0.34,14.33-6.41c-2.23-3.49-3.38-8.1-3.38-12.36c0-10.36,8.37-18.77,18.73-18.77c10.36,0,18.77,8.41,18.77,18.77
			c0,4.26-1.14,8.86-3.38,12.36c-3.85,6.07,0.49,6.41,14.33,6.41h32.79v-32.83c0-13.84,0.3-18.18,6.37-14.29
			c3.57,2.19,8.14,3.34,12.4,3.34c10.36,0,18.74-8.41,18.74-18.77s-8.38-18.69-18.74-18.69c-4.26,0-8.83,1.15-12.4,3.34
			c-6.07,3.89-6.37-0.53-6.37-14.29v-2.4V251h-32.79c-13.84,0-18.18,0.26-14.33,6.33c2.23,3.57,3.38,8.18,3.38,12.43
			c0,10.36-8.41,18.7-18.77,18.7c-10.36,0-18.73-8.34-18.73-18.7c0-4.26,1.14-8.86,3.38-12.43c3.85-6.07-0.53-6.33-14.33-6.33H126
			v30.42v2.4c0,13.76-0.3,18.18-6.37,14.29c-3.57-2.19-8.14-3.34-12.4-3.34c-10.36,0-18.74,8.34-18.74,18.69
			S96.87,332.23,107.23,332.23z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M1,251h32.82c13.8,0,18.14-0.26,14.29-6.33c-2.24-3.57-3.38-8.18-3.38-12.43
			c0-10.36,8.41-18.7,18.77-18.7c10.36,0,18.73,8.34,18.73,18.7c0,4.26-1.11,8.86-3.38,12.43c-3.81,6.07,0.53,6.33,14.33,6.33H126
			v-32.82c0-13.76,0.3-18.18,6.33-14.29c3.57,2.2,8.14,3.34,12.4,3.34c10.36,0,18.74-8.33,18.74-18.69
			c0-10.36-8.38-18.77-18.74-18.77c-4.26,0-8.83,1.14-12.4,3.34c-6.03,3.89-6.33-0.45-6.33-14.29v-2.4V126H93.17
			c-13.8,0-18.14,0.34-14.33,6.41c2.27,3.49,3.38,8.1,3.38,12.36c0,10.36-8.37,18.77-18.73,18.77c-10.36,0-18.77-8.41-18.77-18.77
			c0-4.26,1.14-8.86,3.38-12.36c3.85-6.07-0.49-6.41-14.29-6.41H1V251z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M626.46,501v-32.82c0-13.84,0.3-18.18,6.37-14.36c3.57,2.27,8.14,3.42,12.4,3.42
            c10.36,0,18.74-8.41,18.74-18.77c0-10.36-8.38-18.77-18.74-18.77c-4.26,0-8.83,1.14-12.4,3.42c-6.07,3.81-6.37-0.53-6.37-14.29
            V376h30.42h2.4c13.8,0,18.14-0.34,14.33-6.41c-2.27-3.49-3.38-8.1-3.38-12.36c0-10.36,8.37-18.77,18.73-18.77
            c10.36,0,18.77,8.41,18.77,18.77c0,4.26-1.14,8.86-3.38,12.36c-3.85,6.07,0.49,6.41,14.29,6.41h32.82v125H626.46z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M751.46,126h-32.82c-13.84,0-18.18-0.3-14.36-6.37c2.27-3.57,3.42-8.14,3.42-12.4
            c0-10.36-8.41-18.74-18.77-18.74c-10.36,0-18.77,8.38-18.77,18.74c0,4.26,1.14,8.83,3.42,12.4c3.81,6.07-0.53,6.37-14.29,6.37
            h-32.83V95.58v-2.4c0-13.8-0.34-18.14-6.41-14.33c-3.49,2.27-8.1,3.38-12.36,3.38c-10.36,0-18.77-8.37-18.77-18.73
            c0-10.36,8.41-18.77,18.77-18.77c4.26,0,8.86,1.14,12.36,3.38c6.07,3.85,6.41-0.49,6.41-14.29V1h125V126z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M751.46,126h-30.42h-2.4c-13.8,0-18.14-0.26-14.29-6.33c2.24-3.57,3.38-8.18,3.38-12.43
            c0-10.28-8.41-18.7-18.77-18.7c-10.36,0-18.73,8.41-18.73,18.7c0,4.26,1.11,8.86,3.38,12.43c3.81,6.07-0.53,6.33-14.33,6.33
            h-32.83v32.83c0,13.84,0.3,18.18,6.37,14.29c3.57-2.19,8.14-3.34,12.4-3.34c10.36,0,18.74,8.41,18.74,18.77
            c0,10.35-8.38,18.69-18.74,18.69c-4.26,0-8.83-1.15-12.4-3.34c-6.07-3.89-6.37,0.53-6.37,14.29V251h32.83
            c13.8,0,18.14,0.26,14.33,6.33c-2.27,3.57-3.38,8.18-3.38,12.43c0,10.36,8.37,18.7,18.73,18.7c10.36,0,18.77-8.34,18.77-18.7
            c0-4.26-1.14-8.86-3.38-12.43c-3.85-6.07,0.49-6.33,14.29-6.33h2.4h30.42V126z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M751.46,251h-32.82c-13.8,0-18.14,0.26-14.29,6.33c2.24,3.57,3.38,8.18,3.38,12.43
            c0,10.36-8.41,18.7-18.77,18.7c-10.36,0-18.73-8.34-18.73-18.7c0-4.26,1.11-8.86,3.38-12.43c3.81-6.07-0.53-6.33-14.33-6.33
            h-32.83v32.82c0,13.76-0.3,18.18-6.33,14.29c-3.57-2.2-8.14-3.34-12.4-3.34c-10.36,0-18.74,8.33-18.74,18.69
            c0,10.36,8.38,18.77,18.74,18.77c4.26,0,8.83-1.14,12.4-3.34c6.03-3.89,6.33,0.45,6.33,14.29v2.4V376h32.83
            c13.8,0,18.14-0.34,14.33-6.41c-2.27-3.49-3.38-8.1-3.38-12.36c0-10.36,8.37-18.77,18.73-18.77c10.36,0,18.77,8.41,18.77,18.77
            c0,4.26-1.14,8.86-3.38,12.36c-3.85,6.07,0.49,6.41,14.29,6.41h32.82V251z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M1,501h125v-32.82c0-13.84,0.3-18.18,6.33-14.36c3.57,2.27,8.14,3.42,12.4,3.42
            c10.36,0,18.74-8.41,18.74-18.77c0-10.35-8.38-18.77-18.74-18.77c-4.26,0-8.83,1.15-12.4,3.42c-6.03,3.81-6.33-0.53-6.33-14.29
            v-2.4V376H93.17c-13.8,0-18.14,0.26-14.33,6.33c2.27,3.57,3.38,8.18,3.38,12.43c0,10.28-8.37,18.7-18.73,18.7
            c-10.36,0-18.77-8.41-18.77-18.7c0-4.26,1.14-8.86,3.38-12.43c3.85-6.07-0.49-6.33-14.29-6.33H1V501z"/>
        </svg>
    )
}

export const PuzzleGrid4x3 = (props: Props) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            viewBox="0 0 591.63 444"
            className={props.className}
        >
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M39.39,148.57c10.31,0,16.7,0.13,17.9,2.29c0.11,0.22,0.19,0.49,0.21,0.8
			c0.05,0.94-0.48,2.21-1.52,3.87c-1.92,3.05-3.26,6.86-3.79,10.81c-0.16,1.35-0.27,2.66-0.27,3.98c0,12.42,10.1,22.51,22.52,22.51
			c12.42,0,22.51-10.1,22.51-22.51c0-1.32-0.11-2.63-0.27-3.98c-0.53-3.95-1.87-7.76-3.79-10.81c-1.05-1.66-1.57-2.94-1.53-3.87
			c0.01-0.31,0.1-0.58,0.21-0.8c1.21-2.16,7.6-2.29,17.9-2.29h38.39v38.39c0,1.45,0,2.79,0.01,4.1c0.01,0.48,0.02,0.91,0.03,1.41
			c0.01,0.73,0.03,1.47,0.05,2.14c0.02,0.58,0.04,1.09,0.04,1.63c0.01,0.51,0.04,0.99,0.07,1.43c0.04,0.58,0.09,1.09,0.15,1.57
			c0.04,0.36,0.06,0.7,0.09,1.02c0.07,0.47,0.15,0.92,0.25,1.36c0.02,0.25,0.08,0.46,0.14,0.68c0.11,0.41,0.18,0.83,0.32,1.13
			c0.08,0.2,0.14,0.32,0.24,0.52c0.15,0.27,0.26,0.58,0.45,0.78c0.15,0.23,0.36,0.41,0.6,0.55c0.15,0.14,0.3,0.22,0.52,0.3
			c0.28,0.14,0.58,0.18,0.91,0.19c0,0,0.03,0.03,0.07,0.03c0.28,0,0.58-0.05,0.91-0.09c0.09-0.02,0.17-0.06,0.23-0.09
			c0.33-0.12,0.71-0.22,1.09-0.42c0.11-0.05,0.27-0.09,0.37-0.16c0.5-0.23,1-0.5,1.56-0.86c3.96-2.49,9.23-3.93,14.43-3.93
			c11.99,0,21.75,9.77,21.75,21.75c0,11.99-9.76,21.75-21.75,21.75c-5.2,0-10.47-1.38-14.43-3.93c-0.56-0.32-1.06-0.63-1.57-0.84
			c-0.1-0.06-0.24-0.12-0.36-0.19c-0.38-0.2-0.76-0.36-1.1-0.39c-0.05,0-0.13-0.04-0.22-0.06c-0.32-0.09-0.63-0.14-0.91-0.14
			c-0.04,0-0.07,0.03-0.07,0.03c-0.33,0.01-0.63,0.12-0.91,0.17c-0.22,0.06-0.36,0.19-0.52,0.33c-0.24,0.19-0.44,0.26-0.6,0.55
			c-0.19,0.24-0.29,0.5-0.45,0.82c-0.09,0.1-0.15,0.27-0.24,0.5c-0.14,0.27-0.21,0.68-0.32,1.09c-0.07,0.17-0.13,0.43-0.14,0.72
			c-0.1,0.4-0.18,0.85-0.25,1.32c-0.03,0.37-0.05,0.66-0.09,0.99c-0.06,0.55-0.11,1.05-0.15,1.6c-0.03,0.43-0.06,0.91-0.07,1.4
			c0,0.52-0.02,1.04-0.04,1.61c-0.02,0.69-0.04,1.47-0.05,2.25c-0.01,0.45-0.02,0.86-0.03,1.35c-0.01,1.31-0.01,2.69-0.01,4.09
			v38.38h-38.39c-10.31,0-16.7-0.07-17.9-2.3c-0.1-0.19-0.19-0.5-0.21-0.78c-0.05-0.95,0.48-2.14,1.53-3.83
			c1.92-3.08,3.26-6.91,3.79-10.9c0.16-1.24,0.27-2.62,0.27-3.97c0-12.37-10.1-22.53-22.51-22.53c-12.42,0-22.52,10.16-22.52,22.53
			c0,1.35,0.11,2.74,0.27,3.97c0.53,3.99,1.87,7.82,3.79,10.9c1.04,1.68,1.57,2.88,1.52,3.83c-0.01,0.27-0.1,0.59-0.21,0.78
			c-1.2,2.23-7.59,2.3-17.9,2.3H1V148.57H39.39z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M148.57,1h146.86v38.34c0,10.93,0.14,17.17,2.68,18.57c1.24,0.7,2.9,0.27,5.46-1.31
			c3.96-2.53,9.23-3.97,14.42-3.97c12,0,21.76,9.77,21.76,21.8c0,11.98-9.76,21.75-21.76,21.75c-5.19,0-10.46-1.44-14.42-3.93
			c-2.56-1.61-4.22-1.99-5.46-1.35c-2.54,1.4-2.68,7.67-2.68,18.57v38.39h-38.34c-10.35,0-16.7-0.13-17.9-2.3
			c-0.53-0.95-0.13-2.44,1.27-4.66c2.58-4.1,4.06-9.49,4.06-14.84c0-12.42-10.1-22.51-22.51-22.51c-12.41,0-22.5,10.1-22.5,22.51
			c0,5.36,1.46,10.75,4.04,14.84c1.44,2.22,1.84,3.71,1.32,4.66c-1.21,2.17-7.6,2.3-17.91,2.3h-38.39v-38.39
			c0-10.9-0.09-17.17-2.63-18.57c-1.24-0.64-2.9-0.27-5.45,1.35c-3.97,2.49-9.21,3.93-14.4,3.93c-12.03,0-21.79-9.77-21.79-21.75
			c0-12.03,9.76-21.8,21.79-21.8c5.18,0,10.43,1.44,14.4,3.97c2.54,1.57,4.2,2,5.45,1.31c2.54-1.4,2.63-7.64,2.63-18.57V1z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M499.37,140.49c-2.48-3.97-3.96-9.23-3.96-14.43c0-11.99,9.76-21.75,21.81-21.75
			c12.03,0,21.79,9.76,21.79,21.75c0,5.2-1.49,10.47-3.94,14.43c-1.66,2.51-2.03,4.21-1.34,5.41c1.35,2.55,7.67,2.68,18.56,2.68
			h38.34v146.84h-38.34c-10.89,0-17.22,0.12-18.56,2.69c-0.69,1.25-0.32,2.91,1.34,5.37c2.45,4.03,3.94,9.23,3.94,14.42
			c0,12.06-9.76,21.85-21.79,21.85c-12.05,0-21.81-9.79-21.81-21.85c0-5.2,1.48-10.39,3.96-14.42c1.63-2.46,2.01-4.12,1.31-5.37
			c-1.35-2.58-7.64-2.69-18.56-2.69h-38.34v-38.38c0-1.35,0.01-2.63,0.02-3.87c0-0.32,0.01-0.49,0.01-0.79
			c0.01-0.94,0.02-1.87,0.05-2.76c0.01-0.26,0.02-0.48,0.04-0.76c0.03-0.78,0.06-1.6,0.12-2.36c0.01-0.14,0.04-0.37,0.05-0.59
			c0.03-0.66,0.03-1.35,0.12-1.97c0.02-0.17,0.05-0.33,0.07-0.42c0.1-0.63,0.22-1.18,0.37-1.67c0.03-0.1,0.04-0.2,0.04-0.3
			c0.11-0.5,0.3-0.91,0.53-1.32c0.02-0.06,0.04-0.07,0.05-0.07c0.16-0.4,0.44-0.62,0.66-0.85c0.02-0.01,0.04-0.04,0.07-0.06
			c0.33-0.22,0.6-0.24,1.01-0.24c0.24,0,0.58,0,0.87,0.04c0.12,0.03,0.23,0.07,0.28,0.12c0.28,0.1,0.58,0.23,0.88,0.4
			c0.14,0.01,0.27,0.03,0.34,0.1c0.49,0.26,0.92,0.49,1.4,0.81c4.1,2.56,9.47,4.06,14.78,4.06c12.47,0,22.52-10.13,22.52-22.51
			c0-12.4-10.06-22.52-22.52-22.52c-5.31,0-10.69,1.49-14.78,4.07c-0.48,0.31-0.91,0.55-1.4,0.75c-0.07,0.08-0.2,0.14-0.35,0.21
			c-0.29,0.12-0.59,0.24-0.87,0.35c-0.04,0.01-0.17,0.03-0.28,0.06c-0.3,0.11-0.66,0.16-0.89,0.15c-0.02,0-0.04-0.01-0.06-0.01
			c-0.34-0.02-0.54-0.1-0.81-0.19c-0.01-0.01-0.03-0.01-0.05-0.02c-0.22-0.17-0.45-0.38-0.68-0.65c-0.02-0.04-0.06-0.09-0.09-0.14
			c-0.12-0.28-0.31-0.63-0.48-1.02c-0.03-0.08-0.04-0.19-0.07-0.22c-0.04-0.35-0.14-0.76-0.24-1.17c-0.04-0.2-0.08-0.35-0.12-0.53
			c-0.05-0.28-0.09-0.57-0.13-0.83c-0.09-0.55-0.13-1.12-0.13-1.76c-0.02-0.29-0.04-0.58-0.07-0.87c-0.05-0.68-0.09-1.43-0.12-2.17
			c-0.01-0.34-0.02-0.66-0.04-0.99c-0.02-0.86-0.04-1.73-0.06-2.65c0-0.32-0.01-0.63-0.01-0.97c-0.01-1.27-0.02-2.56-0.02-3.93
			v-38.39h38.34c10.92,0,17.22-0.13,18.56-2.68C501.37,144.7,500.99,143,499.37,140.49z"/>

            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M443.01,186.96c0,1.43,0.01,2.76,0.01,4.06c0,0.32,0.01,0.63,0.01,0.97
			c0.01,0.91,0.03,1.81,0.06,2.61c0.01,0.46,0.04,0.82,0.06,1.26c0.03,0.63,0.06,1.23,0.1,1.81c0.04,0.49,0.08,0.89,0.13,1.35
			c0.05,0.39,0.09,0.83,0.13,1.22c0,0.22,0,0.5,0.01,0.71c0.24,1.32,0.56,2.76,1.3,3.74c0.01,0.02,0.03,0.04,0.04,0.07
			c0.25,0.27,0.45,0.49,0.8,0.68c0.03,0,0.05,0.01,0.08,0.03c0.05,0.03,0.07,0.03,0.08,0.05c0.23,0.12,0.5,0.19,0.68,0.2
			c0.12,0.01,0.24,0.03,0.36,0.04c0.07,0,0.1,0.02,0.1,0.02c0.3,0,0.58-0.05,0.94-0.09c0.06-0.01,0.14-0.06,0.22-0.07
			c0.32-0.11,0.66-0.22,1.14-0.44c0.04-0.04,0.17-0.07,0.31-0.14c0.47-0.24,0.96-0.5,1.53-0.88c3.96-2.49,9.23-3.93,14.42-3.93
			c11.99,0,21.76,9.77,21.76,21.75c0,11.99-9.77,21.75-21.76,21.75c-5.19,0-10.47-1.38-14.42-3.93c-0.57-0.32-1.07-0.65-1.53-0.84
			c-0.14-0.06-0.27-0.12-0.32-0.17c-0.47-0.22-0.81-0.39-1.13-0.4c-0.07-0.01-0.15-0.04-0.22-0.06c-0.36-0.09-0.64-0.14-0.94-0.14
			c0,0-0.01,0.03-0.05,0.03c-0.39,0.01-0.63,0.12-0.96,0.17c-0.17,0.07-0.4,0.24-0.63,0.43c-0.1,0.14-0.32,0.2-0.5,0.45
			c-0.17,0.26-0.25,0.5-0.41,0.85c-0.09,0.09-0.19,0.24-0.27,0.46c-0.07,0.29-0.17,0.69-0.28,1.12c-0.06,0.16-0.13,0.42-0.19,0.71
			c-0.1,0.4-0.17,0.85-0.18,1.32c-0.02,0.37-0.07,0.66-0.11,0.99c-0.06,0.55-0.11,1.07-0.15,1.61c-0.04,0.42-0.07,0.89-0.09,1.37
			c-0.03,0.52-0.05,1.05-0.07,1.63c-0.02,0.69-0.04,1.47-0.05,2.25c-0.01,0.45-0.01,0.86-0.02,1.35c-0.01,1.31-0.01,2.69-0.01,4.09
			v38.38h-38.34c-10.31,0-16.71-0.07-17.91-2.3c-0.52-0.94-0.14-2.37,1.32-4.61c2.53-4.07,4.07-9.52,4.07-14.87
			c0-12.37-10.16-22.53-22.52-22.53c-12.47,0-22.51,10.16-22.51,22.53c0,5.35,1.44,10.8,4.07,14.87c1.36,2.23,1.83,3.67,1.32,4.61
			c-1.21,2.23-7.6,2.3-17.91,2.3H296.2v-38.38c0-12.74-0.19-18.83-3.98-18.83c-1.05,0-2.34,0.52-4.11,1.61
			c-3.97,2.55-9.23,3.93-14.43,3.93c-11.99,0-21.77-9.76-21.77-21.75c0-11.98,9.78-21.75,21.77-21.75c5.2,0,10.47,1.44,14.43,3.93
			c1.77,1.1,3.06,1.61,4.11,1.61c3.78,0,3.98-6.1,3.98-18.84v-38.39h38.39c10.31,0,16.7,0.13,17.91,2.29
			c0.51,0.96,0.04,2.45-1.32,4.67c-2.63,4.1-4.07,9.49-4.07,14.79c0,12.42,10.05,22.51,22.51,22.51c12.37,0,22.52-10.1,22.52-22.51
			c0-5.31-1.54-10.7-4.07-14.79c-1.45-2.22-1.84-3.71-1.32-4.67c1.2-2.16,7.59-2.29,17.91-2.29h38.34V186.96z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M443.77,404.66c0-1.5,0-2.78-0.01-4.09c0-0.49-0.01-0.94-0.02-1.45
			c-0.01-0.68-0.02-1.45-0.04-2.15c-0.02-0.59-0.05-1.11-0.08-1.64c-0.02-0.46-0.05-0.94-0.09-1.45c-0.01-0.55-0.01-1.07-0.04-1.55
			c-0.04-0.3-0.09-0.68-0.14-0.94c-0.07-0.49-0.16-0.96-0.25-1.44c-0.06-0.19-0.12-0.45-0.16-0.68c-0.04-0.39-0.16-0.76-0.31-1.15
			c-0.08-0.12-0.18-0.27-0.26-0.45c-0.07-0.26-0.22-0.6-0.42-0.76c-0.16-0.23-0.28-0.37-0.48-0.52c-0.23-0.1-0.37-0.29-0.65-0.39
			c-0.22-0.13-0.56-0.19-0.84-0.19c-0.05,0-0.08,0-0.12,0c-0.35,0-0.6,0-0.95,0.06c0,0.01-0.04,0.04-0.1,0.06
			c-0.43,0.12-0.8,0.29-1.19,0.46c-0.12,0-0.24,0.06-0.37,0.12c-0.47,0.29-0.97,0.52-1.55,0.94c-3.97,2.45-9.23,3.93-14.43,3.93
			c-11.98,0-21.75-9.76-21.75-21.75c0-12.02,9.77-21.81,21.75-21.81c5.2,0,10.47,1.45,14.43,3.99c0.56,0.32,1.06,0.62,1.52,0.82
			c0.14,0.07,0.29,0.14,0.44,0.2c0.35,0.17,0.68,0.26,1.09,0.37c0.09,0.03,0.17,0.06,0.17,0.09c0.36,0.09,0.61,0.13,0.95,0.13
			c0.04,0,0.07-0.03,0.12-0.03c0.28-0.01,0.63-0.1,0.86-0.24c0.27-0.1,0.4-0.17,0.63-0.35c0.2-0.17,0.33-0.32,0.49-0.45
			c0.2-0.26,0.35-0.52,0.42-0.85c0.08-0.2,0.18-0.26,0.26-0.47c0.15-0.33,0.27-0.75,0.31-1.12c0.04-0.26,0.1-0.42,0.16-0.71
			c0.09-0.39,0.18-0.85,0.25-1.38c0.05-0.32,0.09-0.59,0.14-0.99c0.03-0.47,0.03-0.99,0.04-1.55c0.04-0.42,0.07-0.91,0.09-1.45
			c0.03-0.53,0.06-1.05,0.08-1.63c0.02-0.71,0.03-1.38,0.04-2.14c0.01-0.45,0.02-0.88,0.02-1.44c0.01-1.22,0.01-2.62,0.01-4.03
			v-38.42h38.34c10.34,0,16.7,0.13,17.91,2.27c0.51,1.02,0.14,2.46-1.23,4.68c-2.62,4.1-4.05,9.54-4.05,14.8
			c0,12.47,10.05,22.51,22.48,22.51c12.4,0,22.46-10.05,22.46-22.51c0-5.25-1.44-10.7-4.03-14.8c-1.39-2.22-1.77-3.66-1.25-4.68
			c1.2-2.14,7.59-2.27,17.9-2.27h38.34V443H443.77V404.66z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M590.63,147.86h-38.34c-10.32,0-16.7-0.13-17.9-2.3c-0.52-0.95-0.14-2.44,1.25-4.66
			c2.59-4.1,4.03-9.49,4.03-14.84c0-12.42-10.05-22.51-22.46-22.51c-12.43,0-22.48,10.1-22.48,22.51c0,5.36,1.43,10.75,4.05,14.84
			c1.37,2.22,1.74,3.71,1.23,4.66c-1.21,2.17-7.56,2.3-17.91,2.3h-38.34v-38.39c0-10.9-0.14-17.17-2.63-18.57
			c-1.24-0.64-2.9-0.27-5.46,1.35c-3.97,2.49-9.23,3.93-14.43,3.93c-11.98,0-21.75-9.77-21.75-21.75c0-12.03,9.77-21.8,21.75-21.8
			c5.2,0,10.47,1.44,14.43,3.97c2.56,1.58,4.22,2,5.46,1.31c2.48-1.4,2.63-7.64,2.63-18.57V1h146.86V147.86z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M443.01,39.34c0,10.35-0.07,16.7-2.28,17.91c-0.18,0.16-0.5,0.22-0.76,0.22
			c-0.99,0.03-2.21-0.45-3.92-1.49c-3-1.96-6.87-3.27-10.8-3.79c-1.31-0.19-2.63-0.27-3.99-0.27c-12.37,0-22.52,10.11-22.52,22.52
			c0,12.4,10.16,22.47,22.52,22.47c1.36,0,2.68-0.07,3.99-0.28c3.94-0.52,7.8-1.83,10.8-3.75c1.71-1.08,2.93-1.56,3.92-1.52
			c0.26,0.01,0.58,0.08,0.76,0.21c2.21,1.21,2.28,7.59,2.28,17.91v38.39h-38.34c-10.9,0-17.12,0.09-18.57,2.63
			c-0.69,1.24-0.22,2.9,1.35,5.44c2.51,3.98,3.93,9.21,3.93,14.4c0,12.04-9.76,21.8-21.75,21.8c-11.98,0-21.75-9.76-21.75-21.8
			c0-5.18,1.39-10.42,3.94-14.4c1.55-2.54,1.94-4.2,1.29-5.44c-1.4-2.53-7.62-2.63-18.52-2.63H296.2v-38.39
			c0-10.31,0.08-16.7,2.29-17.91c0.94-0.52,2.4-0.13,4.63,1.31c4.09,2.59,9.53,4.03,14.87,4.03c12.37,0,22.45-10.07,22.45-22.47
			c0-12.42-10.08-22.52-22.45-22.52c-5.35,0-10.78,1.49-14.87,4.07c-2.23,1.4-3.7,1.78-4.63,1.26c-2.2-1.21-2.29-7.55-2.29-17.91V1
			h146.81V39.34z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M1,1h146.86v38.34c0,10.35-0.13,16.7-2.28,17.91c-0.97,0.52-2.46,0.14-4.68-1.26
			c-4.1-2.58-9.48-4.07-14.8-4.07c-12.41,0-22.51,10.11-22.51,22.52c0,12.4,10.1,22.47,22.51,22.47c5.32,0,10.7-1.44,14.8-4.03
			c2.22-1.44,3.71-1.82,4.68-1.31c2.15,1.21,2.28,7.59,2.28,17.91v38.39h-38.39c-10.9,0-17.12,0.09-18.52,2.63
			c-0.69,1.24-0.27,2.9,1.3,5.44c2.49,3.98,3.93,9.21,3.93,14.4c0,12.04-9.76,21.8-21.75,21.8c-11.99,0-21.76-9.76-21.76-21.8
			c0-5.18,1.44-10.42,3.93-14.4c1.57-2.54,1.99-4.2,1.3-5.44c-1.4-2.53-7.62-2.63-18.52-2.63H1V1z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M239.83,303.48c2.54,4.03,3.98,9.23,3.98,14.42c0,12.06-9.76,21.85-21.8,21.85
            c-11.99,0-21.75-9.79-21.75-21.85c0-5.2,1.44-10.39,3.93-14.42c1.62-2.46,1.99-4.12,1.35-5.37c-1.41-2.58-7.67-2.69-18.57-2.69
            h-38.39v-38.38c0-1.35,0-2.58,0.01-3.8c0-0.53,0.01-1.01,0.02-1.58c0.01-0.58,0.02-1.22,0.04-1.81c0.01-0.62,0.04-1.15,0.07-1.71
            c0.02-0.5,0.04-0.91,0.06-1.27c0.04-0.55,0.06-1.05,0.08-1.54c0.04-0.39,0.07-0.68,0.11-1.04c0.06-0.43,0.13-0.83,0.16-1.27
            c0.05-0.29,0.12-0.58,0.18-0.82c0.08-0.33,0.12-0.65,0.22-0.91c0.1-0.3,0.18-0.58,0.3-0.76c0.09-0.2,0.15-0.39,0.23-0.49
            c0.19-0.3,0.37-0.48,0.56-0.63c0.07-0.06,0.13-0.14,0.21-0.19c0.28-0.22,0.59-0.23,0.91-0.23c0.27,0,0.56,0,0.9,0.04
            c0.11,0.03,0.23,0.09,0.31,0.12c0.26,0.1,0.53,0.23,0.83,0.39c0.15,0.03,0.25,0.04,0.41,0.12c0.4,0.26,0.86,0.49,1.34,0.81
            c3.09,1.97,6.86,3.21,10.86,3.74c1.3,0.22,2.62,0.32,3.98,0.32c12.42,0,22.51-10.13,22.51-22.51c0-12.4-10.1-22.52-22.51-22.52
            c-1.36,0-2.69,0.11-3.98,0.27c-3.99,0.53-7.77,1.88-10.86,3.79c-0.48,0.32-0.94,0.55-1.36,0.76c-0.15,0.07-0.24,0.14-0.38,0.2
            c-0.31,0.12-0.59,0.24-0.85,0.35c-0.08,0.01-0.19,0.03-0.3,0.06c-0.33,0.09-0.63,0.15-0.9,0.14c-0.32-0.01-0.65-0.12-0.94-0.29
            c-0.02-0.01-0.04-0.04-0.06-0.06c-0.27-0.18-0.5-0.45-0.74-0.83c-0.02-0.04-0.02-0.1-0.02-0.15c-0.22-0.32-0.37-0.78-0.53-1.27
            c-0.03-0.1-0.04-0.2-0.04-0.28c-0.13-0.5-0.26-1.06-0.31-1.65c-0.03-0.17-0.05-0.3-0.08-0.49c-0.09-0.6-0.16-1.24-0.19-1.94
            c0-0.21-0.02-0.45-0.04-0.69c-0.05-0.68-0.09-1.48-0.12-2.26c-0.01-0.27-0.03-0.52-0.03-0.8c-0.03-0.86-0.04-1.8-0.05-2.74
            c0-0.28-0.01-0.52-0.01-0.78c-0.01-1.24-0.01-2.51-0.01-3.89v-38.39h38.39c10.9,0,17.15-0.13,18.57-2.68
            c0.64-1.2,0.27-2.9-1.35-5.41c-2.49-3.97-3.93-9.23-3.93-14.43c0-11.99,9.76-21.75,21.75-21.75c12.04,0,21.8,9.76,21.8,21.75
            c0,5.2-1.44,10.47-3.98,14.43c-1.57,2.51-1.99,4.21-1.3,5.41c1.39,2.55,7.62,2.68,18.56,2.68h38.34v38.39
            c0,11.58-0.12,18.12-3.21,18.12c-0.86,0-2.1-0.5-3.74-1.52c-4.05-2.58-9.49-4.07-14.8-4.07c-12.42,0-22.51,10.12-22.51,22.52
            c0,12.38,10.1,22.51,22.51,22.51c5.31,0,10.75-1.5,14.8-4.06c1.64-0.99,2.88-1.47,3.74-1.47c3.09,0,3.21,6.48,3.21,18.05v38.38
            h-38.34c-10.94,0-17.17,0.12-18.56,2.69C237.83,299.36,238.26,301.02,239.83,303.48z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M1,443V296.15h38.39c10.9,0,17.12-0.14,18.52-2.63c0.69-1.24,0.27-2.89-1.3-5.46
            c-2.49-3.96-3.93-9.23-3.93-14.42c0-11.99,9.77-21.75,21.76-21.75c11.99,0,21.75,9.76,21.75,21.75c0,5.2-1.44,10.47-3.93,14.42
            c-1.57,2.56-1.99,4.22-1.3,5.46c1.4,2.49,7.62,2.63,18.52,2.63h38.39v38.42c0,1.32,0,2.65-0.01,3.9c0,0.22-0.01,0.49-0.01,0.71
            c-0.01,1.02-0.03,1.9-0.05,2.82c-0.01,0.23-0.02,0.53-0.03,0.75c-0.03,0.84-0.06,1.61-0.07,2.26c-0.02,0.27-0.04,0.5-0.06,0.68
            c-0.06,0.73-0.13,1.4-0.22,2c0,0.13,0,0.27-0.03,0.45c-0.1,0.62-0.23,1.17-0.32,1.64c-0.03,0.12-0.05,0.23-0.08,0.33
            c-0.15,0.49-0.31,0.89-0.48,1.21c-0.03,0.06-0.05,0.12-0.07,0.16c-0.19,0.37-0.46,0.63-0.74,0.88c-0.01,0-0.01,0.03-0.01,0.04
            c-0.3,0.13-0.61,0.23-0.99,0.24c-0.27,0-0.57-0.04-0.9-0.14c-0.11-0.03-0.18-0.07-0.29-0.07c-0.26-0.04-0.54-0.17-0.85-0.33
            c-0.14-0.07-0.25-0.13-0.41-0.22c-0.4-0.16-0.86-0.43-1.34-0.71c-4.1-2.62-9.48-4.06-14.8-4.06c-12.41,0-22.51,10.05-22.51,22.51
            c0,12.37,10.1,22.51,22.51,22.51c5.32,0,10.7-1.53,14.8-4.06c0.49-0.37,0.97-0.56,1.37-0.81c0.14-0.07,0.24-0.13,0.35-0.2
            c0.33-0.1,0.64-0.2,0.92-0.32c0.09-0.03,0.15-0.07,0.24-0.09c0.34-0.1,0.65-0.16,0.92-0.16c0.36,0.01,0.68,0.11,0.95,0.33
            c0.03,0.04,0.08,0.13,0.16,0.13c0.19,0.16,0.41,0.39,0.55,0.59c0.1,0.17,0.17,0.39,0.23,0.56c0.13,0.22,0.23,0.49,0.31,0.73
            c0.1,0.33,0.18,0.58,0.21,0.96c0.07,0.22,0.14,0.53,0.19,0.78c0.08,0.4,0.09,0.88,0.15,1.31c0.04,0.26,0.08,0.65,0.11,0.94
            c0.05,0.49,0.09,1.04,0.11,1.61c0,0.39,0.01,0.79,0.03,1.19c0.03,0.56,0.05,1.11,0.07,1.71c0.02,0.59,0.03,1.24,0.04,1.9
            c0.01,0.5,0.02,0.96,0.02,1.53c0.01,1.19,0.01,2.48,0.01,3.87V443H1z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M148.57,404.66c0-1.5,0-2.78-0.01-4.09c0-0.49,0-0.94,0-1.45c0-0.68,0-1.45-0.02-2.15
            c-0.01-0.58-0.04-1.09-0.07-1.61c-0.03-0.49-0.05-0.96-0.09-1.48c-0.04-0.56-0.09-1.07-0.12-1.54c-0.02-0.3-0.07-0.69-0.12-0.96
            c-0.07-0.48-0.15-0.95-0.2-1.43c-0.05-0.19-0.13-0.45-0.19-0.68c-0.07-0.4-0.18-0.76-0.32-1.15c-0.03-0.12-0.14-0.29-0.23-0.48
            c-0.11-0.23-0.26-0.59-0.4-0.73c-0.21-0.27-0.41-0.46-0.6-0.55c-0.2-0.14-0.35-0.27-0.53-0.36c-0.29-0.13-0.57-0.19-0.89-0.19
            c-0.05,0-0.08,0-0.13,0c-0.29,0-0.6,0-0.93,0.06c-0.05,0.01-0.06,0.04-0.12,0.06c-0.37,0.12-0.79,0.29-1.18,0.46
            c-0.13,0-0.2,0.06-0.33,0.13c-0.47,0.27-1.01,0.5-1.58,0.92c-3.97,2.45-9.21,3.93-14.4,3.93c-12.03,0-21.79-9.76-21.79-21.75
            c0-12.02,9.76-21.81,21.79-21.81c5.18,0,10.43,1.45,14.4,3.99c0.58,0.32,1.09,0.62,1.55,0.82c0.15,0.07,0.25,0.14,0.4,0.2
            c0.35,0.17,0.72,0.26,1.06,0.37c0.09,0.01,0.14,0.06,0.22,0.09c0.32,0.09,0.63,0.13,0.91,0.13c0.04,0,0.07-0.03,0.12-0.03
            c0.34-0.01,0.62-0.1,0.91-0.24c0.17-0.09,0.32-0.14,0.51-0.24c0.2-0.19,0.41-0.37,0.62-0.55c0.14-0.26,0.29-0.5,0.4-0.83
            c0.09-0.19,0.2-0.26,0.23-0.49c0.14-0.33,0.26-0.75,0.32-1.11c0.07-0.26,0.14-0.43,0.2-0.72c0.04-0.39,0.13-0.85,0.2-1.38
            c0.05-0.3,0.09-0.59,0.12-1.01c0.03-0.48,0.08-0.98,0.12-1.53c0.03-0.43,0.06-0.92,0.09-1.48c0.03-0.52,0.06-1.04,0.07-1.61
            c0.02-0.71,0.02-1.38,0.02-2.14c0-0.45,0-0.88,0-1.44c0.01-1.22,0.01-2.62,0.01-4.03v-38.42h38.39c10.31,0,16.7,0.13,17.91,2.27
            c0.52,1.02,0.12,2.46-1.32,4.68c-2.58,4.1-4.04,9.54-4.04,14.8c0,12.47,10.08,22.51,22.5,22.51c12.42,0,22.51-10.05,22.51-22.51
            c0-5.25-1.49-10.7-4.06-14.8c-1.4-2.22-1.79-3.66-1.27-4.68c1.2-2.14,7.55-2.27,17.9-2.27h38.34v38.42c0,1.48,0,2.88,0.02,4.26
            c0.02,2.61,0.09,4.76,0.22,6.68c0,0.06,0,0.24,0,0.4c0.06,0.72,0.15,1.31,0.24,1.9c0.03,0.2,0.05,0.55,0.06,0.71
            c0,0.04,0,0.07,0.01,0.12c0.01,0.07,0.03,0.16,0.04,0.24c0.12,0.62,0.23,1.24,0.45,1.81c0.03,0,0.05,0.07,0.07,0.14
            c0.14,0.43,0.31,0.91,0.58,1.22c0.02,0.1,0.09,0.2,0.16,0.29c0.21,0.25,0.46,0.52,0.75,0.65c0.1,0.03,0.2,0.07,0.26,0.13
            c0.32,0.17,0.69,0.29,1.13,0.29c1.05,0,2.35-0.5,4.16-1.61c3.96-2.53,9.23-3.99,14.42-3.99c12,0,21.76,9.79,21.76,21.81
            c0,11.99-9.76,21.75-21.76,21.75c-5.19,0-10.46-1.48-14.42-3.93c-1.82-1.09-3.11-1.63-4.16-1.63c-3.78,0-3.98,6.1-3.98,18.84V443
            H148.57V404.66z"/>
            <path style={{ stroke: props.pathColor }} className={'puzzle-outline'} d="M443.01,334.57c0,1.31-0.01,2.61-0.01,3.83c0,0.52-0.01,0.98-0.02,1.48
            c-0.01,0.65-0.02,1.31-0.04,1.91c0,0.6,0,1.14,0,1.68c0,0.42-0.01,0.88-0.03,1.3c-0.04,0.55-0.08,1.05-0.13,1.54
            c-0.04,0.35-0.07,0.66-0.12,0.92c-0.06,0.55-0.13,0.95-0.2,1.32c-0.06,0.35-0.1,0.56-0.1,0.86c-0.07,0.27-0.16,0.65-0.26,0.88
            c-0.1,0.33-0.22,0.5-0.32,0.79c-0.03,0.17-0.11,0.32-0.22,0.49c-0.19,0.26-0.36,0.43-0.55,0.62c-0.07,0.07-0.14,0.16-0.22,0.22
            c-0.22,0.1-0.6,0.2-0.9,0.22c-0.32,0-0.58-0.04-0.96-0.14c-0.03-0.03-0.12-0.07-0.22-0.07c-0.32-0.03-0.56-0.17-0.92-0.33
            c-0.04-0.07-0.19-0.13-0.34-0.22c-0.4-0.14-0.85-0.43-1.4-0.71c-4.05-2.62-9.44-4.06-14.79-4.06c-12.37,0-22.52,10.05-22.52,22.51
            c0,12.37,10.16,22.51,22.52,22.51c5.36,0,10.74-1.53,14.79-4.06c0.55-0.37,1.01-0.56,1.42-0.81c0.14-0.07,0.27-0.13,0.3-0.2
            c0.38-0.1,0.63-0.22,0.96-0.32c0.1-0.03,0.17-0.07,0.19-0.09c0.39-0.1,0.65-0.16,0.97-0.16c0.32,0.01,0.7,0.13,0.93,0.35
            c0.02,0.01,0.04,0.04,0.06,0.06c0.32,0.13,0.5,0.45,0.74,0.78c0.03,0.04,0.04,0.09,0.06,0.14c0.13,0.32,0.32,0.82,0.49,1.32
            c0.03,0.1,0.05,0.12,0.08,0.2c0.1,0.49,0.17,1.07,0.27,1.67c0.02,0.17,0.05,0.35,0.06,0.52c0.09,0.6,0.17,1.21,0.23,1.96
            c0.01,0.23,0.04,0.37,0.05,0.61c0.05,0.76,0.06,1.5,0.06,2.36c0,0.29,0,0.46,0,0.76c0.02,0.84,0.04,1.83,0.05,2.76
            c0,0.22,0.01,0.47,0.01,0.79c0.01,1.15,0.01,2.46,0.01,3.87V443H296.2v-38.34c0-11.57,0.13-18.15,3.21-18.15
            c0.87,0,2.1,0.48,3.71,1.57c4.09,2.53,9.53,4.06,14.87,4.06c12.37,0,22.45-10.15,22.45-22.51c0-12.47-10.08-22.51-22.45-22.51
            c-5.35,0-10.78,1.44-14.87,4.06c-1.6,0.99-2.84,1.48-3.71,1.48c-1.52,0-2.3-1.58-2.75-4.61c-0.05-0.24-0.08-0.65-0.12-0.92
            c-0.02-0.5-0.09-1.04-0.13-1.61c-0.04-0.58-0.06-1.15-0.09-1.73c-0.03-0.46-0.06-0.89-0.07-1.41c0-0.82,0-1.63-0.01-2.52
            c-0.01-0.39-0.02-0.71-0.02-1.19c-0.01-1.24-0.01-2.66-0.01-4.09v-38.42h38.39c10.9,0,17.12-0.14,18.52-2.63
            c0.64-1.24,0.26-2.89-1.29-5.46c-2.55-3.96-3.94-9.23-3.94-14.42c0-11.99,9.77-21.75,21.75-21.75c11.99,0,21.75,9.76,21.75,21.75
            c0,5.2-1.42,10.47-3.93,14.42c-1.56,2.56-2.04,4.22-1.35,5.46c1.45,2.49,7.67,2.63,18.57,2.63h38.34V334.57z"/>
        </svg>
    )
}