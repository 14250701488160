import React from 'react';
import PreviewBackground from '../../../images/games/dangerinthejungle/paper_small.png';
import {DbOrUploadImage, DbOrUploadSound} from '../../../services/ImageHelper';
import {DitjQuiz} from '../../../model/Game/DangerInTheJungle/DitjQuiz';
import TextView from '../../TextView';

interface Props {
    quiz: DitjQuiz;
    onClick?: () => void;
}

export const QuizPreview = (props: Props) => {
    const quiz = props.quiz;
    if (!quiz) return null;
    const q = quiz.question;
    const a1 = quiz.answer1;
    const a2 = quiz.answer2;
    const a3 = quiz.answer3;

    return(
        <div className='ditj-preview-wrapper' onClick={props.onClick || undefined}>
            <div className='ditj-question-preview'>
                <img src={PreviewBackground} alt='' />
                <div className={`question${q.image && q.text.content ? ' image-and-text' :''}`}>
                    <TextView element='div' text={q.text} />
                    <DbOrUploadImage image={q.image}/>
                    <DbOrUploadSound className='audio' audio={q.sound}/>
                </div>
                <div className={`answer a ${a1.correct ? 'correct' : ''}${a1.image && a1.text.content ? ' image-and-text' : ''}`}>
                    <DbOrUploadImage image={a1.image}/>
                    <TextView element='div' text={a1.text} />
                </div>
                <div className={`answer b ${a2.correct ? 'correct' : ''}${a2.image && a2.text.content ? ' image-and-text' : ''}`}>
                    <DbOrUploadImage image={a2.image}/>
                    <TextView element='div' text={a2.text} />
                </div>
                <div className={`answer c ${a3.correct ? 'correct' : ''}${a3.image && a3.text.content ? ' image-and-text' : ''}`}>
                    <DbOrUploadImage image={a3.image}/>
                    <TextView element='div' text={a3.text} />
                </div>
            </div>
        </div>
    )
};