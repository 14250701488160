import React, {Component} from 'react';
import {isOrganizationType, Organization, TempOrganization} from "../../model/Organization";
import {SortByCustom} from "../../services/SortFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Translate} from "react-localize-redux";
import {InputField} from "../forms/FormGroup";
import {Link} from "react-router-dom";

interface Props{
    organizations: Array<Organization|TempOrganization>;
    orgEditClick: (org: Organization) => void;
    showChildCount?: boolean;
}
interface State{
    sort: {sortKey: keyof Organization; ascending: boolean};
    searchTerm: string;
}

export default class OrganizationList extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = { sort: {sortKey: "systemName", ascending: true}, searchTerm: "" };
    }

    render(){
        const props = this.props;
        const state = this.state;
        const regex = new RegExp(this.state.searchTerm, "ig");

        const orgs: Organization[] = props.organizations
            .filter(isOrganizationType)
            .filter(o =>
                `${o.systemName}${o.country}${o.type}${o.contactName}${o.contactEmail}${o.contactPhone}`.search(regex) !== -1
            );

        const renderHeader = (key: keyof Organization) => (
            <th className='sortable' onClick={() => this.setState({sort: {...state.sort, sortKey: key, ascending: state.sort.sortKey === key ? !state.sort.ascending : false}})}>
                <Translate id={key}/> {this.state.sort.sortKey === key ? <FontAwesomeIcon icon='sort' /> : false}
            </th>
        );

        return(
            <div className='org-list'>
                <InputField
                    labelWidth100
                    name='search'
                    type='text'
                    value={this.state.searchTerm}
                    onChange={(e) => this.setState({searchTerm: e.target.value})}
                />
                <table className='org-table'>
                    <thead>
                    <tr>
                        {props.showChildCount &&
                            <th />
                        }
                        {renderHeader("country")}
                        {renderHeader("systemName")}
                        {renderHeader("name")}
                        {renderHeader("type")}
                        {renderHeader("contactName")}
                        <th />
                        <th />
                    </tr>
                    </thead>
                    <tbody>
                    {
                        orgs
                            .sort((a,b) => SortByCustom(a, b, this.state.sort.sortKey, state.sort.ascending))
                            .map((org) => {
                            return(
                                <tr onClick={() => props.orgEditClick(org)} key={org.id} className={org.childOrganizationIds.length > 0 ? 'reseller' : ''}>
                                    {props.showChildCount &&
                                    <td>
                                        {org.childOrganizationIds.length}
                                    </td>
                                    }
                                    <td>{org.country}</td>
                                    <td>{org.systemName}</td>
                                    <td className='truncate'>{org.name}</td>
                                    <td>{org.type}</td>
                                    <td className='truncate'>{org.contactName}</td>
                                    <td className='truncate'>{org.contactEmail}</td>
                                    <td>{org.contactPhone}</td>
                                    <td><Link to={`/discover/org/${org.id}`} onClick={e => e.stopPropagation()}>&gt;</Link></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}
