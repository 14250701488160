import axios from 'axios';
import moment from 'moment';
import {errorActions} from './Error';
import { actionCreators as meActions } from './Me';
import {CreateAuthHeader} from "../services/AxiosHelper";
import {history} from "../index";
import {Reducer} from "redux";
import {TA} from "./configureStore";

export const reducerName = 'auth';

export interface AuthState {
    activetoken: string | null;
    expiretime: string | null;
    waitingForToken: boolean;
    showRequestPassword: boolean;
    waitingForRequestReset: boolean;
    resetRequested: boolean;
    waitingForReset: boolean;
}

const initialState: AuthState = {
    activetoken: null,
    expiretime: null,
    waitingForToken: false,
    showRequestPassword: false,
    waitingForRequestReset: false,
    resetRequested: false,
    waitingForReset: false
};

export const actionCreators = {
    setTokenFromStorage: (token: string|null, expiretime: string): TA => async(dispatch) => {
        dispatch({type:'SET_TOKEN', token: token, expiretime});
        dispatch(meActions.get());
    },
    setRequestPassword: (show: boolean): TA => async(dispatch) => {
        dispatch({type:'SHOW_REQUEST_PASSWORD', show});
    },
    requestToken: (username: string, password: string): TA => async (dispatch, getState) => {
        //if request already being handled just return.
        if(getState().auth.waitingForToken) return;
        dispatch(errorActions.resetError(reducerName, 'requestToken'));
        dispatch({ type: 'REQUEST_TOKEN' });

        const url = 'api/account/authenticate';
        axios.post(url, {'username' : username, 'password':password})
            .then(response => {
                const token = response.data.token;
                const expiretime = moment(response.data.expiretime).toISOString();
                localStorage.setItem("myfloorjwt", token);
                localStorage.setItem("myfloorjwt_expire", expiretime);
                dispatch({type: 'RECEIVED_TOKEN', token, expiretime});
                dispatch(errorActions.resetError(reducerName, 'refreshToken'));
                dispatch(meActions.get());
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'requestToken', error));
            });
    },
    refreshToken: (): TA => async(dispatch, getState) => {
        //if request already being handled just return.
        if(getState().auth.waitingForToken) return;
        dispatch(errorActions.resetError( reducerName,'refreshToken'));
        dispatch({ type: 'REFRESH_TOKEN' });
        const url = 'api/account/ReAuthenticate';
        axios.post(url, {}, CreateAuthHeader(getState))
            .then(response => {
                const token = response.data.token;
                const expiretime = moment(response.data.expiretime).toISOString();
                localStorage.setItem("myfloorjwt", token);
                localStorage.setItem("myfloorjwt_expire", expiretime);
                dispatch({ type: 'RECEIVED_TOKEN', token, expiretime });
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'refreshToken', error));
            });
    },
    resetPassword: (token: string, password: string, passwordRepeat: string): TA => async(dispatch) => {
        const params = {
            password: password,
            passwordRepeat: passwordRepeat
        };
        dispatch(errorActions.resetError(reducerName, 'resetPassword'));
        dispatch({type: 'RESET_PASSWORD_SEND' });
        const url = 'api/account/resetpassword';
        axios.post(url, params, {headers : {Authorization: `Bearer ${token}`}})
            .then(() => {
                dispatch( {type: 'RESET_PASSWORD_RECEIVED'} );
                history.push("/");
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'resetPassword', error));
            });
    },
    requestResetPassword: (email: string): TA => async(dispatch) => {
        dispatch(errorActions.resetError(reducerName,'requestResetPassword'));
        dispatch({type: 'REQUEST_RESET_PASSWORD_SEND' });
        const url = 'api/account/requestPasswordReset';
        axios.post(url, {Email: email})
            .then(() => {
                dispatch( {type: 'REQUEST_RESET_PASSWORD_RECEIVED'} );
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'requestResetPassword', error));
            });
    },
    logout: (): TA => async(dispatch) => {
        dispatch({ type: 'LOGOUT' });
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: AuthState, action: any) => AuthState} = {
    REQUEST_TOKEN: (state) => {
        return {
            ...state,
            waitingForToken: true
        };
    },
    REFRESH_TOKEN: (state) => {
        return {
            ...state,
            waitingForToken: true
        };
    },
    RECEIVED_TOKEN: (state, action) => {
        return {
            ...state,
            activetoken: action.token,
            expiretime: action.expiretime,
            waitingForToken: false,
        };
    },
    ERROR:  (state, action) => {
        if(action.reducer !== 'auth') return state;
        switch(action.key){
            case 'requestToken':
            case 'refreshToken':
                return {
                    ...state,
                    activetoken: null,
                    expiretime: null,
                    waitingForToken: false
                };
            case 'requestResetPassword':
                return {
                    ...state,
                    waitingForRequestReset: false
                };
            case 'resetPassword':
                return{
                    ...state,
                    waitingForReset: false,
                };
            default:
                return state;
        }
    },
    SHOW_REQUEST_PASSWORD:  (state, action) => {
        return{
            ...state,
            showRequestPassword: action.show,
            resetRequested: !action.show
        };
    },
    REQUEST_RESET_PASSWORD_SEND:(state) => {
        return{
            ...state,
            waitingForRequestReset: true
        }
    },
    REQUEST_RESET_PASSWORD_RECEIVED:(state) => {
        return{
            ...state,
            waitingForRequestReset: false,
            resetRequested: true
        };
    },
    SET_TOKEN: (state, action) => {
        return {
            ...state,
            activetoken: action.token,
            expiretime: action.expiretime,
        };
    },
    RESET_PASSWORD_SEND :(state) => {
        return{
            ...state,
            waitingForReset: true,
        }
    },
    RESET_PASSWORD_RECEIVED: (state) => {
        return{
            ...state,
            waitingForReset: false,
        }
    }
};

// eslint-disable-next-line
export const reducer: Reducer<AuthState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};