import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
    AccountState,
} from "../../store/Account";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { BaseGame } from "../../model/Game/BaseGame";
import { AppState } from "../../store/configureStore";
import Author from "./Author";
import { Link } from "react-router-dom";
import Game from "./Game";
import { GameRelatedInfo } from "../WorkShop/GameRelatedInfo";
import { GameStats } from "../../model/GameStats";
import { isTempAccount } from "../../model/AccountType";
import { Loading } from '../Loading';
import { useFetchGet } from '../../services/FetchHelper';
import { Button } from '../forms/FormGroup';
import { GameType } from '../../model/Game/GameType';
import moment from 'moment';
import { GameTagList } from '../WorkShop/GameTagList';
import { isRtlActive } from '../../services/TextHelper';

interface Props {
    accountState: AccountState;
    gameType: GameType;
    game?: BaseGame;
    gameStats?: GameStats;
    tempDescription?: ReactNode;
    tempTitle?: ReactNode;
    hideTranslations?: boolean;
    onClick?: () => void;
    activeLanguage?: string;
}

const GameDetailed = (props: Props) => {
    const { accountState, game: _game, gameStats, gameType, tempDescription, tempTitle, hideTranslations, onClick, activeLanguage } = props;

    const game: BaseGame = useMemo(() => _game || {
        parentId: null, fontSize: 16, fontFamily: 'Lato', fontColorHex: '#FFF', labelColorHex: '#000', ownerId: '', title: '',
        description: '', backgroundColorHex: '#000', image: null, gameType: gameType, showLabel: true,
        showTitle: true, authorIds: [], isPublic: false, isPublished: false, imageId: '', id: '', owner: null,
        skin: 'Default', organizationId: '', editedDateTime: '', tags: '', createdDateTime: '',
        translations: [], languageCode: '', languageName: '', minAge: 0, maxAge: 50,
        courseName: '', preventCopy: true
    }, [_game, gameType]);

    const [copies, setCopies] = useState<BaseGame[]>();
    const [invokeGetCopies, loadingGetCopies] = useFetchGet<BaseGame[]>(setCopies);

    const rtl = isRtlActive(activeLanguage)

    const originalId = game?.parentId || game?.id;

    useEffect(() => {
        if (originalId && !hideTranslations) {
            invokeGetCopies(`api/game/${originalId}/copies`);
        }
        else {
            setCopies(undefined);
        }
    }, [originalId, invokeGetCopies, hideTranslations]);

    useEffect(() => {

        setCopies(cp => {
            if (!game.isPublished) {
                return cp?.filter(g => g.id !== game.id);
            }
            if (cp?.find(g => g.id === game.id)) {
                return cp?.map(g => g.id === game.id ? game : g);
            }
            else {
                return [...cp || [], game];
            }
        });
    }, [game]);

    const accounts = accountState.accounts;
    const owner = accounts[game.ownerId];

    return (
        <div className='game-detailed-preview'>
            <div className='author-and-stats'>
                <div className='left'>
                    <div className='game-and-authors'>
                        <Game game={game} onClick={onClick} noMenu onlyImage />
                        {owner && !isTempAccount(owner) &&
                            <div className='authors'>
                                <h2><Translate id='game_authors' /></h2>
                                <Link to={`/library/profile/${owner.id}`}>
                                    <Author
                                        account={owner}
                                        hoverName
                                    />
                                </Link>
                                {game.authorIds && game.authorIds.map((x: string) => {
                                    return (
                                        <Link key={x} to={`/library/profile/${x}`}>
                                            <Author
                                                accountId={x}
                                                small
                                                hideName
                                                hoverName
                                            />
                                        </Link>
                                    );

                                })}
                            </div>
                        }
                    </div>
                    <div className='text-content'>
                        <h2 dir='auto'>{game.title || tempTitle}</h2>
                        <div dir='auto'>{game.description || tempDescription}</div>
                        <div className='details'>
                            <GameTagList game={game} />
                            {game.editedDateTime &&
                                <div className='edit-date' dir='auto'>
                                    <label><Translate id='editedDateTime' /></label> {moment(game.editedDateTime).format(rtl ? 'DD-MM-YYYY' : 'YYYY-MM-DD')}
                                </div>
                            }
                        </div>
                    </div>
                    {!hideTranslations &&
                        <div className='copies'>
                            <Loading visible={loadingGetCopies} />
                            <h3><Translate id={'game_copies'} /></h3>
                            {copies && copies.map(gc =>
                                <CopiesButton
                                    original={!gc.parentId}
                                    key={gc.id}
                                    title={gc.title}
                                    active={gc.id === game.id}
                                    authorId={gc.ownerId}
                                    url={`/workshop/${game.gameType}/edit/${gc.id}`}
                                    languageName={gc.languageName}
                                />
                            )}
                        </div>
                    }
                </div>
                <div className='middle'>
                    <h2><Translate id='preview' /></h2>
                    <img src={`/images/skins/${game.gameType}/${game.skin || 'Default'}.png`} alt='' className='skin-preview' />
                </div>
                <div className='right'>
                    <GameRelatedInfo stats={gameStats} />
                </div>
                <div className='clear-fix' />
            </div>
            <div className='clear-fix' />
        </div>
    );
}

interface CopiesButtonProps {
    original: boolean;
    title: string;
    active: boolean;
    url: string;
    authorId: string;
    languageName: string;
}
const CopiesButton = (props: CopiesButtonProps) => {
    const { title, active, url, authorId, original, languageName } = props;
    return (
        <Button
            className={`${active ? 'active' : ''}${original ? ' original' : ''}`}
            link={url}
        >
            <span className='float'>
                <Author accountId={authorId} className='owner-img' hideName />
            </span>
            <span className='float'>

                <div className='title'>{title}</div>
                <div className='owner'>{languageName}</div>
            </span>
            <div className='clear-fix' />
        </Button>
    )
};

export default connect(
    (state: AppState) => ({ accountState: state.account, activeLanguage: state.localize.languages.find(x => x.active)?.code })
)(GameDetailed);

