import React, {Component, FormEvent} from 'react';
import {Translate} from 'react-localize-redux';
import FieldErrors from '../FieldErrors';
import {bindActionCreators} from 'redux';
import {actionCreators, AuthState} from '../../../store/Auth';
import {connect} from 'react-redux';
import {AppState, ChangeEvent} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";
import {SubmitButton} from "../FormGroup";

interface Props{
    authState: AuthState;
    errors: ErrorState['auth'];
    authActions: typeof actionCreators;
}

interface State{
    email: string;
}

class RequestResetPassword extends Component<Props, State>{

    constructor(props: Props){
        super(props);
        
        this.state = {
            email: ''
        };
    }

    handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.props.authActions.requestResetPassword(this.state.email);
    };

    handleChange = (event: ChangeEvent<string>) => {
        this.setState({ email : event.target.value});
    };

    render(){
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='form-group'>
                    <label htmlFor='email'><Translate id="email" /></label>
                    <input name='email' className='form-control' type='email' value={this.state.email} onChange={this.handleChange} />
                    <div className="form-error"><FieldErrors errors={this.props.errors.requestResetPassword} fieldname='Email' /></div>
                </div>
                <SubmitButton split text={'reset_password'} disabled={this.props.authState.waitingForRequestReset} />
            </form>
        );
    }
}


export default connect(
    (state: AppState) => ({
        authState: state.auth,
        errors: state.errors.auth}),
    dispatch => ({
        authActions: bindActionCreators(actionCreators, dispatch)
    })
)(RequestResetPassword);