import axios from "axios";
import {errorActions} from "./Error";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {actionCreators as accountActions} from "./Account";
import {actionCreators as msgActions} from "./MessageStore";
import {DictGetValues} from "../services/JsDict";
import {Organization, TempOrganization} from "../model/Organization";
import { ChangeEvent, TA } from "./configureStore";
import {Reducer} from "redux";
import {MinimalOrganization} from "../model/Response/MinimalOrganization";

export const reducerName = 'organization';

const ORGANIZATION_GET_MINIMAL_REQUEST = 'ORGANIZATION_GET_MINIMAL_REQUEST';
const ORGANIZATION_GET_MINIMAL_RESPONSE = 'ORGANIZATION_GET_MINIMAL_RESPONSE';
const ORGANIZATION_DELETE_REQUEST = 'ORGANIZATION_DELETE_REQUEST';
const ORGANIZATION_DELETE_RESPONSE = 'ORGANIZATION_DELETE_RESPONSE';
const ORGANIZATION_SHOW_CONFIRM_DELETE = 'ORGANIZATION_SHOW_CONFIRM_DELETE';

const ORGANIZATION_CHILD_ADD_REQUEST = 'ORGANIZATION_CHILD_ADD_REQUEST';
const ORGANIZATION_CHILD_ADD_RESPONSE = 'ORGANIZATION_CHILD_ADD_RESPONSE';
const ORGANIZATION_CHILD_REMOVE_REQUEST = 'ORGANIZATION_CHILD_REMOVE_REQUEST';
const ORGANIZATION_CHILD_REMOVE_RESPONSE = 'ORGANIZATION_CHILD_REMOVE_RESPONSE';

interface EditOrganization extends Organization{
    new: boolean;
}

export interface OrganizationState {
    organizations: {[key: string]: Organization|TempOrganization};
    minimalOrgs: {[key: string]: MinimalOrganization};
    activeOrganization: string;
    chooseOrganizationFilter: string;
    editOrg: EditOrganization;
    addPlaylistBulk?: string;
    loading: {
        get?: boolean; getMy?: boolean; getAll?: boolean; createNewOrganization?: boolean; updateOrganization?: boolean;
        createFloor?: boolean; updateFloor?: boolean; deleteFloor?: boolean; updateFloorAppearance?: boolean; removePlayListFromFloor?: boolean;
        addPlayListToFloor?: boolean; addGameToFloor?: boolean; removeGameFromFloor?: boolean; getMinimalOrg?: string; delete?: boolean;
        addChildOrg?: boolean; removeChildOrg?: boolean; addScheduledAction?: boolean; removeScheduledAction?: boolean; editBundle?: boolean;
        sortMenu?: boolean; changeRow?: boolean;
    };
    ui: {
        showChooseOrganization?: boolean;
        showChooseFloor?: string;
        showConfirmDelete?: boolean;
        showEditOrganization?: boolean;
        showEditFloor?: boolean;
        showEditFloorAppearance?: boolean;
        showBackgroundColorPicker?: boolean;
        showConfirmDeleteFloor?: number;
        showAddGameToFloorForm?: string;
        showRemoveGameConfirm?: {floorIndex: number; gameId: string; rowIndex: number; sortIndex: number};
    };
}

const initialState: OrganizationState = {
    organizations: {},
    minimalOrgs: {},
    activeOrganization: '',
    chooseOrganizationFilter: "",
    editOrg: {
        new: true,
        id: '',
        systemName: "",
        type: "",
        country: "",
        name: "",
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        accountIds: [],
        childOrganizationIds: [],
    },
    loading: {},
    ui: {}
};

export const actionCreators = {
    showConfirmDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: ORGANIZATION_SHOW_CONFIRM_DELETE, show});
    },
    showChooseOrganization: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'ORGANIZATION_SHOW_CHOOSE', show});
    },
    showChooseFloor: (show: false|string): TA => async(dispatch) => {
        dispatch({type: 'ORGANIZATION_SHOW_CHOOSE_FLOOR', show});
    },
    setChooseFilter: (filter: string): TA => async(dispatch) => {
        dispatch({type: 'ORGANIZATION_SET_CHOOSE_FILTER', filter});
    },
    showEditOrganization: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'ORGANIZATION_EDIT_FORM_SHOW', show});
    },
    setEdit: (org: Organization|null): TA => async(dispatch, getState) => {
        if(org == null){
            const current = getState()[reducerName].editOrg;
            if(current.new) org = current;
            else org = initialState.editOrg;
        }
        dispatch({type: 'ORGANIZATION_EDIT_SET', org: org});
    },
    setActiveOrganization: (orgId: string): TA => async(dispatch) => {
        dispatch({type: 'ORGANIZATION_SET_ACTIVE', orgId});
        dispatch(actionCreators.get(orgId, false));
    },
    setNewValue: (event: ChangeEvent<string>): TA => async(dispatch) => {
        const field = event.target.name;
        const value = event.target.value;

        dispatch({type: 'ORGANIZATION_EDIT_SET_VALUE', field, value});
    },
    updateOrganization: (org: Organization): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: 'ORGANIZATION_UPDATE_REQUEST'});

        const url = `api/organization/${org.id}`;
        axios.put(url, org, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'ORGANIZATION_UPDATE_RESPONSE', data: response.data});
                dispatch(
                    msgActions.addMessage(
                        "pop_change",
                        "pop_msg_organization_changed"
                    )
                );
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateOrganization', error));
            });
    },
    createNewOrganization: (createOrg: Organization): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: 'ORGANIZATION_CREATE_NEW_REQUEST'});

        const url = 'api/organization';
        axios.post(url, createOrg, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'ORGANIZATION_CREATE_NEW_RESPONSE', data: response.data});
                dispatch(
                    msgActions.addMessage(
                        "pop_added",
                        "pop_msg_organization_created"
                    )
                );
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createNewOrganization', error));
            })
    },
    deleteOrganization: (org: Organization): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: ORGANIZATION_DELETE_REQUEST});

        const url = `api/organization/${org.id}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: ORGANIZATION_DELETE_RESPONSE, id: org.id});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteOrganization', error));
            });
    },
    getAll: (retrieveUsersForActive: boolean): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: 'ORGANIZATION_GET_ALL_REQUEST'});

        const url = 'api/organization/all';
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                const responseDict = response.data;
                dispatch({type: 'ORGANIZATION_GET_ALL_RESPONSE', data: responseDict});
                const values = DictGetValues(responseDict);
                if(values.length > 0) {
                    if(retrieveUsersForActive){
                        dispatch(accountActions.getByOrganization(getState()[reducerName].activeOrganization));
                    }
                }
            })
            .catch(error => {
                dispatch(errorActions.reportError(reducerName, 'getAll', error));
            })
    },
    getMy: (): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch({type: 'ORGANIZATION_GET_MY_REQUEST'});
        const me = getState().me;
        const id = me.account && me.account.organizationId;
        if(!id) return;
        const url = `api/organization/${id}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'ORGANIZATION_GET_MY_RESPONSE', data: response.data});
                if(!getState()[reducerName].activeOrganization){
                    dispatch(actionCreators.setActiveOrganization(response.data.id));
                }
            })
            .catch(error => {
                dispatch(errorActions.reportError(reducerName, 'getMy', error));
            })
    },
    get: (id: string, forceRefresh: boolean): TA => async (dispatch, getState) => {
        const hasAlready = getState().organization.organizations[id];
        if(hasAlready && !forceRefresh) return;
        dispatch(errorActions.resetAllErrors(reducerName));

        const tempOrg = hasAlready || {id: id, loading: true};
        dispatch({ type: 'ORGANIZATION_GET_REQUEST', data: tempOrg });

        const url = `api/organization/${id}`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({ type: 'ORGANIZATION_GET_RESPONSE', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'get', error));
            });
    },
    getMinimalOrg:(orgId: string, forceRefresh: boolean): TA => async(dispatch, getState) => {
        if(!forceRefresh){
            if(getState().organization.minimalOrgs.hasOwnProperty(orgId)) return;
        }

        dispatch({type: ORGANIZATION_GET_MINIMAL_REQUEST, id: orgId});
        const url = `api/organization/${orgId}/minimal`;
        axios.get(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: ORGANIZATION_GET_MINIMAL_RESPONSE, data: response.data, id: orgId});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'getMinimalOrg', error));
            })
    },
    addChildOrg: (orgId: string, addOrgId: string): TA => async(dispatch, getState) => {
        if(addOrgId === '') return;
        dispatch({type: ORGANIZATION_CHILD_ADD_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        const url = `api/organization/${orgId}/child`;
        const body = { organizationId: addOrgId};
        axios.post(url, body, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: ORGANIZATION_CHILD_ADD_RESPONSE, data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'addChildOrg', error));
            })

    },
    removeChildOrg: (orgId: string, removeOrgId: string): TA => async(dispatch, getState) => {
        dispatch({type: ORGANIZATION_CHILD_REMOVE_REQUEST});
        const url = `api/organization/${orgId}/child/${removeOrgId}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: ORGANIZATION_CHILD_REMOVE_RESPONSE, data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'removeChildOrg', error))
            })
    }
};

const updateSingleOrg = (state: OrganizationState, org: Organization): OrganizationState => {
    return {
        ...state,
        organizations: {
            ...state.organizations,
            [org.id]: org
        }
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: OrganizationState, action: any) => OrganizationState} = {
    ORGANIZATION_CHILD_REMOVE_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                removeChildOrg: true
            }
        }
    },
    ORGANIZATION_CHILD_REMOVE_RESPONSE: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            editOrg: {
                ...state.editOrg,
                childOrganizationIds: action.data.childOrganizationIds
            },
            loading: {
                ...state.loading,
                removeChildOrg: false
            }
        }
    },
    ORGANIZATION_CHILD_ADD_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                addChildOrg: true
            }
        }
    },
    ORGANIZATION_CHILD_ADD_RESPONSE: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            editOrg: {
                ...state.editOrg,
                childOrganizationIds: action.data.childOrganizationIds
            },
            loading:{
                ...state.loading,
                addChildOrg: false
            }
        }
    },
    ORGANIZATION_SHOW_CONFIRM_DELETE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showConfirmDelete: action.show
            }
        }
    },
    ORGANIZATION_DELETE_REQUEST: (state) => {
        return{
            ...state,
            loading: {
                ...state.loading,
                delete: true
            }
        }
    },
    ORGANIZATION_DELETE_RESPONSE: (state, action) => {
        return{
            ...state,
            organizations: {
                ...state.organizations,
                [action.id]: undefined
            },
            ui: {
                ...state.ui,
                showEditOrganization: undefined
            },
            loading: {
                ...state.loading,
                delete: undefined
            }
        }
    },
    ORGANIZATION_GET_MINIMAL_REQUEST: (state, action) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getMinimalOrg: action.id
            }
        }
    },
    ORGANIZATION_GET_MINIMAL_RESPONSE: (state, action) => {
        return{
            ...state,
            minimalOrgs:{
                ...state.minimalOrgs,
                [action.data.id]: action.data
            },
            loading:{
                ...state.loading,
                getMinimalOrg: undefined
            }
        }
    },

    /** ORGANIZATION **/
    ORGANIZATION_SET_CHOOSE_FILTER: (state, action) => {
        return{
            ...state,
            chooseOrganizationFilter: action.filter
        }
    },
    ORGANIZATION_SHOW_CHOOSE: (state, action) => {
        return{
            ...state,
            ui:{
                ...state.ui,
                showChooseOrganization: action.show
            }
        }
    },
    ORGANIZATION_SET_ACTIVE: (state, action) => {
        return{
            ...state,
            activeOrganization: action.orgId,
            ui:{
                ...state.ui,
                showChooseOrganization: false
            }
        }
    },
    ORGANIZATION_EDIT_FORM_SHOW: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                showEditOrganization: action.show
            }
        }
    },
    ORGANIZATION_UPDATE_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                updateOrganization: true,
            }
        }
    },
    ORGANIZATION_UPDATE_RESPONSE: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            loading:{
                ...state.loading,
                updateOrganization: false
            },
            ui:{
                ...state.ui,
                showEditOrganization: false
            }
        }
    },

    ORGANIZATION_GET_ALL_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getAll: true
            }
        }
    },
    ORGANIZATION_GET_ALL_RESPONSE: (state, action) => {
        return{
            ...state,
            organizations: action.data,
            loading:{
                ...state.loading,
                getAll: false
            }
        }
    },
    ORGANIZATION_CREATE_NEW_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                createNewOrganization: true
            }
        }
    },
    ORGANIZATION_CREATE_NEW_RESPONSE: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            editOrg: initialState.editOrg,
            loading:{
                ...state.loading,
                createNewOrganization: false
            },
            ui: {
                ...state.ui,
                showEditOrganization: false
            }
        }
    },
    ORGANIZATION_EDIT_SET : (state, action) => {
        return{
            ...state,
            editOrg: action.org,
            ui: {
                ...state.ui,
                showEditOrganization: true
            }
        }
    },
    ORGANIZATION_EDIT_SET_VALUE: (state, action) => {
        return{
            ...state,
            editOrg:{
                ...state.editOrg,
                [action.field]: action.value
            }
        }
    },
    ORGANIZATION_GET_MY_REQUEST: (state) => {
        return{
            ...state,
            loading:{
                ...state.loading,
                getMy: true
            }
        }
    },
    ORGANIZATION_GET_MY_RESPONSE: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            loading:{
                ...state.loading,
                getMy: false
            }
        }
    },

    ORGANIZATION_GET_REQUEST: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            loading:{
                ...state.loading,
                get: true
            }
        };
    },
    ORGANIZATION_GET_RESPONSE: (state, action) => {
        state = updateSingleOrg(state, action.data);
        return{
            ...state,
            loading:{
                ...state.loading,
                get: false
            }
        };
    },

    ERROR: (state, action) => {
        if(action.reducer !== reducerName) return state;
        return {
            ...state,
            loading: {
                ...state.loading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<OrganizationState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};