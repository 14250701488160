import React, { FormEvent, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Bundle } from '../../model/Bundle';
import { TextAlignment, TextType } from '../../model/Game/TextType';
import { useFetchDelete, useFetchPost, useFetchPut } from '../../services/FetchHelper';
import { ChangeEvent } from '../../store/configureStore';
import Dialog from '../Dialog';
import { FormGeneralError } from '../Error/FormGeneralError';
import { CheckBox, ColorAndFont, ColorPicker, InputField, Slider, SubmitButton, TextAlignmentFormGroup } from '../forms/FormGroup';
import { useConfirmDelete } from '../forms/games/ConfirmationForm';
import BundleIcon from '../ModelPreview/BundleIcon';
import { defaultEmptyText } from '../TextView';

interface Props{
    onClose: () => void;
    onSuccess: (bundle: Bundle) => void;
    onDeleted?: () => void;
    bundle?: Bundle;
}

interface BundleCreate{
    id?: string;
    text: TextType;
    showText: boolean;
    showTextBackground: boolean;
    textBackgroundColorHex: string;
    backgroundColorHex: string;
    isPublic: boolean;
    isPublished: boolean;
}

const initialBundle: BundleCreate = { 
    text:  {...defaultEmptyText, fontSize: 22}, 
    backgroundColorHex: "#DDDDDD",
    showText: true, 
    showTextBackground: true, 
    textBackgroundColorHex: "#000000",
    isPublic: false,
    isPublished: false
}

const BundleEdit = (props: Props) => {
    const {onClose, onSuccess: _onSuccess, bundle, onDeleted} = props;
    const [onSuccess] = useState(() => (m: Bundle) => { onClose(); _onSuccess(m); });

    const [model, setModel] = useState<BundleCreate>(bundle || initialBundle);
    const [postBundle, loadingPost, errorPost] = useFetchPost<Bundle>(onSuccess);
    const [putBundle, loadingPut, errorPut] = useFetchPut<Bundle>(onSuccess);
    const [deleteBundle, loadingDelete, errorDelete] = useFetchDelete(onDeleted)
    const [confirmDelete, showConfirmDelete] = useConfirmDelete(() => deleteBundle(`api/bundle/${model.id}`));
    const loading = loadingPost || loadingPut || loadingDelete;
    const error = errorPost || errorPut || errorDelete;

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(!loading){
            if(model.id){
                putBundle(`api/bundle/${model.id}`, model);
            }
            else{
                postBundle('api/bundle', model);
            }
        }
    }

    const changeText = (e: ChangeEvent<string | number>) => {
        setModel(m => ({ ...m, text: { ...m.text, [e.target.name]: e.target.value } }));
    }

    return(
        <Dialog className={'bundle-edit-dialog'} loading={loading} onClose={onClose} icon={model.id ? 'trash' : undefined} onIconClick={showConfirmDelete}>
            <h1><Translate id={model.id ? 'bundle_edit_title' : 'bundle_create_title'} /></h1>
            <form onSubmit={onSubmit} className={'bundle-create-form'} >
                <FormGeneralError fetchError={error} />
                <InputField noLabel name={'content'} type={'text'} value={model.text.content} onChange={changeText} />
                <ColorPicker label={'bundle_background_color'} colorValue={model.backgroundColorHex} onColorChange={e => setModel(m => ({...m, backgroundColorHex: e.target.value}))} />
                <CheckBox active={model.showText} name={'bundle_show_text'} onChange={e => setModel(m => ({...m, showText: e.target.value}))}  />
                {model.showText &&
                    <>
                        <ColorAndFont
                            colorValue={model.text.fontColorHex}
                            onColorChange={changeText}
                            fontFamilyValue={model.text.fontFamily}
                            onFontFamilyChange={changeText}
                        />
                        <Slider
                            min={10}
                            max={40}
                            name={'fontSize'}
                            value={model.text.fontSize}
                            onChange={changeText}
                        />
                        <TextAlignmentFormGroup name={'textAlignment'} value={model.text.textAlignment || TextAlignment.Left} onChange={changeText} />
                        <CheckBox
                            active={model.showTextBackground}
                            name={'bundle_show_text_background'}
                            onChange={e => setModel(m => ({ ...m, showTextBackground: e.target.value }))}
                        />
                        {model.showTextBackground &&
                        <ColorPicker label={'bundle_text_background_color'} colorValue={model.textBackgroundColorHex} onColorChange={e => setModel(m => ({ ...m, textBackgroundColorHex: e.target.value }))} />
                        }
                    </>
                }
                {model.id && 
                    <>
                        <CheckBox name='isPublished' active={model.isPublished} onChange={e => setModel(m => ({...m, isPublished: e.target.value}))} />
                        {model.isPublished &&
                            <CheckBox name='isPublic' active={model.isPublic} onChange={e => setModel(m => ({ ...m, isPublic: e.target.value }))} />
                        }
                    </>
                }
                <SubmitButton split text='submit' />
            </form>
            <div>
                <h2><Translate id='preview' /></h2>
                <BundleIcon bundle={{ ...model, sortedGames: [], sortedPlaylists: [], ownerId: '', authorIds: [], isPublic: false, isPublished: true }} />
            </div>
            {confirmDelete}
        </Dialog>
    )

}

export default BundleEdit;