import React, { FormEvent, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Wall } from '../../model/SportsWall/Wall';
import { useFetchDelete, useFetchPut } from '../../services/FetchHelper';
import Dialog from '../Dialog';
import { FormGeneralError } from '../Error/FormGeneralError';
import { CheckBox, InputField, SubmitButton } from '../forms/FormGroup';
import { useConfirmDelete } from '../forms/games/ConfirmationForm';

interface Props{
    wall: Wall;
    onClose: () => void;
    onSuccess?: (wall: Wall) => void;
    onDeleted?: () => void;
}

interface EditWallModel {
    name: string;
    isRightToLeftFlow: boolean;
}

const WallEdit = (props: Props) => {
    const { onClose, onSuccess: _onSuccess, onDeleted: _onDeleted, wall} = props;
    const onSuccess = (w: Wall) => {onClose(); _onSuccess && _onSuccess(w);};
    const onDeleted = () => { onClose(); _onDeleted && _onDeleted();};

    const [model, setModel] = useState<EditWallModel>({name: wall.name, isRightToLeftFlow: wall.isRightToLeftFlow});
    //prepare fetch post to create a wall
    const [putWall, loadingPut, errorPut ] = useFetchPut<Wall>(onSuccess);
    const [deleteWall, loadngDelete, errorDelete ] = useFetchDelete(onDeleted);
    const [deleteConfirm, showDeleteConfirm] = useConfirmDelete(() => deleteWall(`api/wall/${wall.id}`));
    const loading = loadingPut || loadngDelete;
    const error = errorPut || errorDelete;

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(!loading){
            console.log(model);
            putWall(`api/wall/${wall.id}`, model);
        }
    }

    return(
        <Dialog className={'wall-edit-dialog'} onClose={onClose} loading={loading} icon={'trash'} onIconClick={showDeleteConfirm}>
            <h1><Translate id="wall_edit_title" /></h1>
            <form className='wall-create-form' onSubmit={onSubmit}>
                <FormGeneralError fetchError={error} />
                <InputField 
                    type='text'
                    name="name" 
                    value={model.name} 
                    onChange={e => setModel(m => ({...m, name: e.target.value}))} 
                    errors={error} 
                    labelWidth100
                />
                <CheckBox
                    label='wall_is_rtl' 
                    name='isRightToLeftFlow' 
                    active={model.isRightToLeftFlow} 
                    onChange={e => setModel(m => ({...m, isRightToLeftFlow: e.target.value}))} 
                    errors={error} 
                />
                <SubmitButton split disabled={loading} />
            </form>
            {deleteConfirm}
        </Dialog>
    )
}

export default WallEdit;