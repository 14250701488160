import React, { useEffect} from 'react';
import * as BlockBusterStore from "../../../store/games/BlockBusterStore";
import * as MyGamesStore from "../../../store/MyGames";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Translate} from "react-localize-redux";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import {AppState} from "../../../store/configureStore";
import {ErrorState} from "../../../store/Error";
import {SecureLayoutComponentProps} from "../../Layout";
import { accountHasAnyPermission } from '../../../model/AccountType';
import { useFetchPost } from '../../../services/FetchHelper';
import { history } from '../../..';
import { Loading } from '../../Loading';
import { FormGeneralError } from '../../Error/FormGeneralError';
import { BlockBusterGame } from '../../../model/Game/BlockBuster/BlockBusterGame';
import BlockBusterSettings from './BlockBusterSettings';

interface Props extends SecureLayoutComponentProps<{id?: string}>{
    gameActions: typeof BlockBusterStore.actionCreators;
    errorState: ErrorState['blockBusterState'];
    gameState: BlockBusterStore.BlockBusterState;
    myGamesActions: typeof MyGamesStore.actionCreators;
}

const BlockBuster = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, me, setLayoutButtons} = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])

    const [create, loadingCreate, errorCreate] = useFetchPost<BlockBusterGame>(g => history.push(`/workshop/${g.gameType}/edit/${g.id}`));

    if(errorState.getGame) return <div><Translate id='unknown_error'/></div>;
    const game = gameState.game;

    return(
        <EditGameComponent
            gameState={gameState}
            routeMatch={match}
            gameType={'BlockBuster'}
            setLayoutButtons={setLayoutButtons}
            extraButtons={[
                accountHasAnyPermission(me, ["Admin"]) && !routeId
                 ? 
                    {id: 'create', icon: 'plus', hoverMsg: 'block_buster_create', action: () => create(`/api/BlockBuster/create`)}
                 : 
                    null
            ]}
        >
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='block_buster_text' options={{renderInnerHtml: true, renderToStaticMarkup: false}}/></p>
                    </div>
                }
                tempTitle={<Translate id='block_buster_title'/>}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
            <Loading visible={loadingCreate} />
            <FormGeneralError error={errorCreate} />
            <BlockBusterSettings />
        </EditGameComponent>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[BlockBusterStore.reducerName],
        errorState: state.errors[BlockBusterStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(BlockBusterStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(BlockBuster)