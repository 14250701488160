import { DbModel } from "../DbModel";
import { GameType } from "./GameType";
import { DbImage } from "../DbImage";
import { AccountType } from "../AccountType";
import {ImageUploadType} from "../ImageUploadType";
import {AppState} from "../../store/configureStore";
import {isTempGame, TempGame} from "../../store/games/Games";

export interface BaseGame extends DbModel {
    parentId?: string | null;
    ownerId: string;
    authorIds: string[];
    imageId: string;
    gameType: GameType;
    isPublic: boolean;
    isPublished: boolean;
    title: string;
    description: string;
    tags: string | null;
    skin: string;
    createdDateTime: string;
    editedDateTime: string;
    organizationId: string;
    fontColorHex: string;
    fontSize: number;
    fontFamily: string;
    showTitle: boolean;
    labelColorHex: string;
    showLabel: boolean;
    backgroundColorHex: string;
    image: DbImage | ImageUploadType | null;
    owner: AccountType | null;
    languageCode: string;
    languageName: string;
    minAge: number;
    maxAge: number;
    courseName: string | null;
    preventCopy: boolean;
}


export const findGameInState = (state: AppState, id: string): BaseGame | undefined => {
    //Try to find the game that was added
    let game: BaseGame | TempGame | undefined | null = state.gamesState.games[id];
    if(!game){
        const mygames = state.mygames.myGames;
        game = mygames && mygames.games.find(x => x.id === id);
    }
    if(!game){
        const searchGames = state.libraryState.searchResultGame;
        game = searchGames && searchGames.games.find(x => x.id === id);
    }

    return game && !isTempGame(game) ? game : undefined;
};