import React from 'react';
import {CardPreview} from "./CardPreview";
import {MemoryPair} from "../../../model/Game/Memory/MemoryPair";

interface Props {
    pair: MemoryPair;
    onClick?: () => void;
}

export const PairPreview = (props: Props) => {
    const pair = props.pair;
    return(
        <div className='pair' onClick={props.onClick}>
            <CardPreview card={pair.a} className='a'/>
            { pair.identicalCards ? <CardPreview card={pair.a} className='b'/> : <CardPreview card={pair.b} className='b'/>}
        </div>
    )
};