import axios from "axios";
import {errorActions} from "../Error";
import {createPicturebookSlideFormData, updatePicturebookSlideFormData} from "../../services/FormDataHelper";
import {AbstractGameIsLoading, abstractGameReducerMethods, AbstractGameState, AbstractGameUi} from "./Games";
import {actionCreators as gamesActions} from './Games';
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {history} from "../../index";
import {PictureBookSlide} from "../../model/Game/PictureBook/PictureBookSlide";
import {SoundUploadType} from "../../model/SoundUploadType";
import {PictureBookGame} from "../../model/Game/PictureBook/PictureBookGame";
import {ImageUploadType} from "../../model/ImageUploadType";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";

export const reducerName = 'picturebook';

interface EditSlide extends PictureBookSlide{
    new: boolean;
    index: number;
    _o: PictureBookSlide;
}

export interface PictureBookState extends AbstractGameState{
    game?: PictureBookGame;
    slide: EditSlide;
    isLoading: AbstractGameIsLoading & {
        createSlide: boolean;
        updateSlide: boolean;
        deleteSlide: boolean;
        updateSort: boolean;
    };
    ui: AbstractGameUi & {
        showSlideEdit: false;
        showConfirmDeleteSlide: false;
        showSoundForm: false;
    };
}

const initialState: PictureBookState = {
    game: undefined,
    gameStats: undefined,
    gameType: 'PictureBook',
    slide: {
        new: true,
        index : -1,
        backgroundColor: '#FFF',
        image : null,
        imageId: '',
        sound : null,
        loopSound: false,
        autoPlay: false,
        _o:{
            backgroundColor: '#FFF',
            image : null,
            imageId: '',
            sound : null,
            loopSound: false,
            autoPlay: false
        }
    },
    isLoading: {
        getGame: false, createSlide: false, updateSlide: false, deleteSlide: false, addAuthor: false,
        removeAuthor: false, transferOwner: false, updateSettings: false, updateSort: false
    },
    ui: {
        showSlideEdit: false,
        showConfirmDeleteSlide: false,
        showSoundForm: false,
        showSettings: false
    }
};

export const actionCreators = {
    setField: (e: ChangeEvent<string>|ChangeEvent<boolean>): TA => async(dispatch) => {
        dispatch({type: 'PB_SET_FIELD', name: e.target.name, value: e.target.value})
    },
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: 'PB_RESET'});
    },
    getGame: (id: string): TA => async (dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'PictureBook'));
    },
    createSlide: (slide: PictureBookSlide): TA => async(dispatch, getState) => {
        const game = getState().picturebook.game;
        const gameId = game && game.id;

        dispatch({type: 'PB_CREATE_SLIDE_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = 'api/workshop/picturebook/slide';
        const data = createPicturebookSlideFormData(slide, gameId);
        
        axios.post(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                if(!gameId) history.push(`/workshop/picturebook/edit/${response.data.id}`);
                dispatch({type: 'PB_CREATE_SLIDE_RESPONSE', data: data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createSlide', error));
            })
    },
    updateSlide: (slide: EditSlide): TA => async(dispatch, getState) => {
        const game = getState().picturebook.game;
        if(!game) return;

        dispatch({type: 'PB_UPDATE_SLIDE_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));
        
        const url = 'api/workshop/picturebook/slide';
        const data = updatePicturebookSlideFormData(slide, game.id);
        
        axios.put(url, data, CreateAuthHeader(getState))
            .then(response => {
                dispatch({type: 'PB_UPDATE_SLIDE_RESPONSE', data: response.data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateSlide', error));
            })
    },
    updateSort: (indexToMove: number, destinationIndex: number): TA => async(dispatch, getState) => {
        const game = getState().picturebook.game;
        if(!game) return;

        const currentArray = [...game.slides];
        const itemToMove = currentArray[indexToMove];
        currentArray.splice(indexToMove, 1);
        currentArray.splice(destinationIndex, 0, itemToMove);
        
        dispatch({type: 'PB_SET_SORT', array:currentArray});
    },
    deleteSlide: (slide: EditSlide): TA => async(dispatch, getState) => {
        const game = getState().picturebook.game;
        if(!game) return;
        dispatch({type: 'PB_DELETE_SLIDE_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/picturebook/${game.id}/slide/${slide.index}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'PB_DELETE_SLIDE_RESPONSE', data});
                dispatch({type: 'PB_SHOW_EDIT_FORM', show:false});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteSlide', error));
            })
    },
    updateSortCommit: (): TA => async(dispatch, getState) => {
        const game = getState().picturebook.game;
        if(!game) return;

        dispatch({type: 'PB_UPDATE_SORT_REQUEST'});
        dispatch(errorActions.resetAllErrors(reducerName));

        const url = `api/workshop/picturebook/${game.id}/sort`;
        const data = {slides: game.slides};
        
        axios.put(url, data, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: 'PB_UPDATE_SORT_RESPONSE', data: data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateSort', error));
            })
    },
    openSlideForEdit: (slide: PictureBookSlide | null, index: number): TA => async(dispatch, getState) => {
        const currentSlide = getState().picturebook.slide;
        slide = currentSlide.index === index ? currentSlide : (slide || initialState.slide);

        const es: EditSlide = {
            ...slide,
            index: index,
            new: index < 0,
            _o:{...slide}
        };
        dispatch({type: 'PB_SET_SLIDE', slide: es});
        dispatch({type: 'PB_SHOW_EDIT_FORM', show: true});
    },
    showConfirmDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'PB_SHOW_CONFIRM_SLIDE_DELETE', show: show});
    },
    showSlideEdit: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'PB_SHOW_EDIT_FORM', show: show});
    },
    onRequestSound: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'PB_SHOW_SOUND_FORM', show: show});
    },
    onRequestColorPicker: (show: boolean): TA => async(dispatch) => {
        dispatch({type: 'PB_SHOW_COLOR_PICKER', show: show});
    },
    setImage: (image: ImageUploadType|undefined): TA => async(dispatch) => {
        dispatch({type: 'PB_SET_IMAGE', image:image});
    },
    setSound: (sound: SoundUploadType|undefined): TA => async(dispatch) => {
        dispatch({type: 'PB_SET_SOUND', sound: sound});
    },
    setColor: (event: ChangeEvent<string>): TA => async(dispatch) => {
        const val = event.target.value;
        dispatch({type: 'PB_SET_COLOR', color: val});
    },
    setAutoPlay:(event: ChangeEvent<boolean>): TA => async(dispatch) => {
        const value = event.target.value;
        dispatch({type: 'PB_SET_AUTOPLAY', autoPlay: value});
    },
    setLoopSound: (event: ChangeEvent<boolean>): TA => async(dispatch) => {
        const val = event.target.value;
        dispatch({type: 'PB_SET_LOOPSOUND', loopSound: val});
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: PictureBookState, action: any) => PictureBookState} = {
    ...abstractGameReducerMethods,
    PB_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    PB_RESET: () => {
        return initialState;
    },
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            slide: initialState.slide
        }
    },
    PB_SET_SORT: (state, action) => {
        if(!state.game) return state;
        return{
            ...state, 
            game:{
                ...state.game,
                slides: action.array
            }
        }
    },

    PB_DELETE_SLIDE_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                deleteSlide: true
            }
        }
    },
    PB_DELETE_SLIDE_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui:{
                ...state.ui,
                showSlideEdit: false
            },
            isLoading:{
                ...state.isLoading,
                deleteSlide: false
            }
        }
    },

    PB_UPDATE_SORT_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading,
                updateSort: true
            }
        }
    },
    PB_UPDATE_SORT_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading:{
                ...state.isLoading,
                updateSort: false
            }
        }
    },
    
    PB_UPDATE_SLIDE_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                updateSlide: true
            }
        }
    },
    PB_UPDATE_SLIDE_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            ui:{
                ...state.ui, 
                showSlideEdit: false
            },
            isLoading:{
                ...state.isLoading,
                updateSlide: false
            }
        }
    },
    
    PB_GET_GAME_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                getGame: true
            }
        }
    },
    PB_GET_GAME_RESPONSE: (state, action) => {
        return{
            ...state, 
            game: action.data,
            isLoading:{
                ...state.isLoading, 
                getGame: false
            }
        } 
    },
    
    PB_CREATE_SLIDE_REQUEST: (state) => {
        return{
            ...state, 
            isLoading:{
                ...state.isLoading, 
                createSlide: true
            }
        }
    },
    PB_CREATE_SLIDE_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            slide: initialState.slide, 
            ui: {
                ...state.ui, 
                showSlideEdit: false
            },
            isLoading:{
                ...state.isLoading,
                createSlide: false
            }
        }
    },
    PB_SET_SLIDE: (state, action) => {
        return{
            ...state, 
            slide: action.slide
        }
    },
    PB_SET_AUTOPLAY: (state, action) => {
        return{
            ...state, 
            slide:{
                ...state.slide,
                autoPlay: action.autoPlay
            }
        }
    },
    PB_SET_COLOR: (state, action) => {
        return{
            ...state, 
            slide:{
                ...state.slide,
                backgroundColor: action.color
            }
        }
    },
    PB_SET_LOOPSOUND: (state, action) => {
        return{
            ...state, 
            slide:{
                ...state.slide,
                loopSound: action.loopSound
            }
        }
    },
    PB_SET_SOUND: (state, action) => {
        return{
            ...state, 
            slide:{
                ...state.slide, 
                sound: action.sound
            }
        }
    },
    PB_SET_IMAGE: (state, action) => {
        return{
            ...state, 
            slide: {
                ...state.slide,
                image: action.image,
            }
        }
    },
    PB_SHOW_CONFIRM_SLIDE_DELETE: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui,
                showConfirmDeleteSlide: action.show
            }
        }
    },
    PB_SHOW_EDIT_FORM: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui,
                showSlideEdit: action.show
            }
        }
    },
    PB_SHOW_COLOR_PICKER: (state, action) => {
        return{
            ...state, 
            ui: {
                ...state.ui,
                showColorPicker: action.show
            }
        }
    },
    PB_SHOW_SOUND_FORM: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                showSoundForm: action.show
            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== 'picturebook') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<PictureBookState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};