import React, {Component} from 'react';
import { RouteComponentProps } from 'react-router';

export default class NotFound extends Component<RouteComponentProps> {
  
    render() {
        const { staticContext } = this.props;
        // Tell server we should send a 404 not found
        if (staticContext) {
            staticContext.statusCode = 404;
        }
        return <h1 style={{padding: '4vh'}}>Not Found</h1>
    }
}