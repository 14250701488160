import React, {Component} from 'react';
import {LayoutComponentProps} from "../Layout";
import {connect} from "react-redux";
import * as LibraryStore from "../../store/LibraryStore";
import {LibraryState} from "../../store/LibraryStore";
import * as GameStore from "../../store/games/Games";
import * as PlaylistStore from '../../store/PlayListStore';
import {PlayListState} from '../../store/PlayListStore';
import * as OrganizationStore from '../../store/OrganizationStore';
import {bindActionCreators} from "redux";
import Game from "../ModelPreview/Game";
import {Loading} from "../Loading";
import Author from "../ModelPreview/Author";
import {history} from '../../index';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import {Translate} from "react-localize-redux";
import {AppState} from "../../store/configureStore";
import LibraryFilters from "./LibraryFilters";
import {withLayoutButtons} from "../Menu/LayoutButtons";
import ItemContainer from '../ModelPreview/ItemContainer';

interface Props extends LayoutComponentProps{
    libraryActions: typeof LibraryStore.actionCreators;
    playlistActions: typeof PlaylistStore.actionCreators;
    library: LibraryState;
    playlist: PlayListState;
    gamesState: GameStore.GamesState;
    organizationState: OrganizationStore.OrganizationState;
}

class Library extends Component<Props> {
    searchContent: HTMLElement | null = null;

    componentDidMount(){
        const props = this.props;
        if(props.library.searchMenu === 'games'){
            props.libraryActions.searchForGames(true, false);
        }
        if(props.library.searchMenu === 'playlists'){
            props.libraryActions.searchForPlaylists(false, false);
        }
        if(props.library.searchMenu === 'authors'){
            props.libraryActions.searchForAuthors(false, false);
        }

        document.addEventListener('scroll', this.trackScrolling);
        this.searchContent = document.getElementById('library-content');
    }

    componentWillUnmount(){
        document.removeEventListener('scroll', this.trackScrolling);
    }

    //Should be "IsHalfAScreenHeightFromBottom", "isBottom" is just more readable.
    isBottom = (el: HTMLElement) => {
        return el.getBoundingClientRect().bottom / 2 <= window.innerHeight;
    };

    trackScrolling = () => {
        if(this.searchContent && this.isBottom(this.searchContent)){
            if(this.props.library.searchMenu === 'games'){
                const gameResults = this.props.library.searchResultGame;
                if(gameResults && gameResults.games.length < gameResults.resultCount){
                    this.props.libraryActions.searchForGames(false, true);
                }
            }
            else if(this.props.library.searchMenu === 'authors'){
                const authorResults = this.props.library.searchResultAuthor;
                if(authorResults && authorResults.accounts.length < authorResults.resultCount){
                    this.props.libraryActions.searchForAuthors(false, true);
                }
            }
            else if(this.props.library.searchMenu === 'playlists'){
                const playlistResult = this.props.library.searchResultPlaylist;
                if(playlistResult && playlistResult.playlists.length < playlistResult.resultCount){
                    this.props.libraryActions.searchForPlaylists(false, true);
                }
            }
        }
    };


    render(){
        const props = this.props;

        const gameResults = props.library.searchResultGame;
        const authorResults = props.library.searchResultAuthor;
        const playlistResults = props.library.searchResultPlaylist;

        const games = (gameResults && gameResults.games) || [];
        const menuShowing = props.library.searchMenu;

        return(
            <div className='library'>
                {(menuShowing === 'games') && <LibraryFilters action={() => props.libraryActions.searchForGames(false,false)} /> }
                <div className='library-content' id='library-content'>
                    <div className='navigation-menu'>
                        <div
                            className={`btn btn-black ${menuShowing === 'games' ? 'active' : ''}`}
                            onClick={() => props.libraryActions.showSearchMenu('games')}
                        >
                            <Translate id='lib_search_games' />
                        </div>
                        <div
                            className={`btn btn-black ${menuShowing === 'playlists' ? 'active' : ''}`}
                            onClick={() => props.libraryActions.showSearchMenu('playlists')}
                        >
                            <Translate id='lib_search_playlist' />
                        </div>
                        <div
                            className={`btn btn-black ${menuShowing === 'authors' ? 'active' : ''}`}
                            onClick={() => props.libraryActions.showSearchMenu('authors')}
                        >
                            <Translate id='lib_search_author' />
                        </div>
                    </div>
                    {(menuShowing === 'playlists') && <h2 className=''><Translate id='search_results_list' /> {playlistResults && playlistResults.resultCount}</h2>}
                    {(menuShowing === 'authors') && <h2 className=''><Translate id='search_results_list' /> {authorResults && authorResults.resultCount}</h2>}
                    {(menuShowing === 'games') &&
                        <ItemContainer 
                            heading={<h2 className=''><Translate id='search_results_list' /> {gameResults && gameResults.resultCount}</h2>}
                            loading={false}
                            items={games}
                            itemRender={game => {
                                const unitCount = gameResults && gameResults.playlistCounts?.find(x => x.id === game.id);
                                return <Game
                                    className={
                                        props.library.gamePreview &&
                                        props.library.gamePreview.id === game.id
                                            ? 'selected'
                                            : ''
                                    }
                                    key={game.id}
                                    game={game}
                                    count={unitCount ? unitCount.count : undefined}
                                />
                            }}
                        />
                    }

                    {(menuShowing === 'playlists') &&
                    <div>
                        <div className='search-result-container list'>
                            <Loading visible={props.library.loading.searchPlaylist}/>
                            {playlistResults && playlistResults.playlists.map(pl => {
                                return <PlayListPreview key={pl.id} playlist={pl} />
                            })}
                            <div className='clear-fix' />
                        </div>
                    </div>
                    }

                    {(menuShowing === 'authors') &&
                    <div>
                        <div className='search-result-container'>
                            <Loading visible={props.library.loading.searchAuthor}/>
                            {authorResults && authorResults.accounts.map(author => {
                                return <Author key={author.id} account={author} onClick={() => {history.push(`/library/profile/${author.id}`)}} fullImage/>
                            })}
                            <div className='clear-fix' />
                        </div>
                    </div>
                    }
                </div>
                <div className='load-more'>
                    <Loading visible={props.library.loading.searchGame}/>
                </div>
            </div>
        )
    }
}


const renderButtons = (props: Props) => {
    return(
        <div className='top-menu'>
            <div className={'search-form'} >
                <FontAwesomeIcon icon='search' className='search-icon'/>
                <input
                    name='search-field'
                    type='text'
                    value={props.library.searchString}
                    onChange={(event) => {
                        props.libraryActions.setSearchString(event.target.value);
                    }}
                />
            </div>
            <div className='clear-fix'/>
        </div>
    );
};


export default connect(
    (state: AppState) => ({
        library: state[LibraryStore.reducerName],
        gamesState: state[GameStore.reducerName],
        playlist: state[PlaylistStore.reducerName],
        organizationState: state[OrganizationStore.reducerName],
    }),
    dispatch => ({
        libraryActions: bindActionCreators(LibraryStore.actionCreators, dispatch),
        memoryActions: bindActionCreators(GameStore.actionCreators, dispatch),
        playlistActions: bindActionCreators(PlaylistStore.actionCreators, dispatch)
    })
)(withLayoutButtons(Library, renderButtons))