import React, { useEffect } from 'react';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AppState } from '../../../store/configureStore';
import { ErrorState } from '../../../store/Error';
import * as GlobalStore from '../../../store/GlobalComponentStore';
import { Button } from '../../forms/FormGroup';
import { SecureLayoutComponentProps } from '../../Layout';
import GameDetailed from '../../ModelPreview/GameDetailed';
import * as SuperSorterStore from "../../../store/games/SuperSorterStore";
import * as MyGamesStore from "../../../store/MyGames";
import EditGameComponentSimple from '../../EditGameComponentSimple';

interface Props extends SecureLayoutComponentProps<{ id?: string }> {
    gameActions: typeof SuperSorterStore.actionCreators;
    errorState: ErrorState['supersorter'];
    gameState: SuperSorterStore.SuperSorterState;
    myGamesActions: typeof MyGamesStore.actionCreators;
    globalActions: typeof GlobalStore.actionCreators;
}

const SuperSorter = (props: Props) => {
    const { gameActions, gameState, errorState, myGamesActions, match, setLayoutButtons  } = props;
    const routeId = match.params.id;

    useEffect(() => {
        gameActions.resetGame();
    }, [gameActions])
    
    if (errorState.getGame) return <div><Translate id='unknown_error' /></div>;
    const game = gameState.game;

    console.log(game);

    return (
        <EditGameComponentSimple
            gameState={gameState}
            routeMatch={match}
            gameType={'SuperSorter'}
            setLayoutButtons={setLayoutButtons}
        >
            <Translate id='super_sorter_show_in_myfloor'></Translate>
            <div>
                <Button name='super_sorter_myfloor_click' blank={true} link={`https://myfloor2.activefloor.com/workshop/SuperSorter/edit/${game?.id}`} className='supersorter_placement'></Button>
            </div>
            <GameDetailed
                game={game}
                tempDescription={
                    <div>
                        <p><Translate id='super_sorter_text' options={{ renderInnerHtml: true, renderToStaticMarkup: false }} /></p>
                    </div>
                }
                tempTitle={<Translate id='super_sorter_title' />}
                onClick={routeId ? () => myGamesActions.showEditDialog(true, game) : undefined}
                gameType={gameState.gameType}
                gameStats={gameState.gameStats}
            />
        </EditGameComponentSimple>
    );
}

export default connect(
    (state: AppState) => ({
        gameState: state[SuperSorterStore.reducerName],
        errorState: state.errors[SuperSorterStore.reducerName]
    }),
    dispatch => ({
        gameActions: bindActionCreators(SuperSorterStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(MyGamesStore.actionCreators, dispatch)
    })
)(SuperSorter)