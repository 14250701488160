import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as navActions, NavigationState} from "../../store/Navigation";
import {AuthState} from "../../store/Auth";
import {MeState} from "../../store/Me";
import {AppState} from "../../store/configureStore";
import {OrganizationState} from "../../store/OrganizationStore";
import {isAdmin} from "../../services/PermissionHelper";
import NavMenuItem from "../Menu/NavMenuItem";

interface Props{
    auth: AuthState;
    me: MeState;
    navigation: NavigationState;
    orgState: OrganizationState;
    navActions: typeof navActions;
}

const NavMenu = (props: Props) => {

    const linksForLoggedInUser = (props: Props) => {
        return (
            <div>
                <NavMenuItem path={'mygames'} icon={'gamepad'}  />
                <NavMenuItem path={'playlist'} icon={'list-ul'}  />
                <NavMenuItem path={'bundles'} icon={'folder'} />
                <NavMenuItem path={'wall'} icon={'tv'} />
                <NavMenuItem path={'library'} icon={'archive'}  />
                <NavMenuItem path={'users'} icon={'users'}  />
                <NavMenuItem path={'organization'} icon={'building'}  />
                {isAdmin(props.me.account) &&
                    <>
                        <NavMenuItem path={'admin'} icon='user-shield' />
                    </>
                }
                
            </div>
        );
    };


    const links = props.auth.activetoken ? linksForLoggedInUser(props) : null;
    const sideBar = props.navigation.ui.showMainNav;

    return(
        <div className={`left-bar ${!sideBar && 'hidden'}`}>
            <div className='link-container'>
                <NavMenuItem path={''} icon={'home'} />
                {links}
            </div>
            <div className='toggle-menu' onClick={() => {props.navActions.showMainNav(!sideBar)}}>
                <FontAwesomeIcon icon={sideBar ? 'chevron-left' : 'chevron-right'} className='icon'/>
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        auth: state.auth,
        me: state.me,
        navigation: state.navigation,
        orgState: state.organization
    }),
    dispatch => ({ navActions : bindActionCreators(navActions, dispatch)})
)(NavMenu);