import React, {Component, ReactNode} from 'react';
import {Route, Switch} from "react-router-dom";
import Login from "../Login";
import Logout from "../Logout";
import ResetPassword from "../ResetPassword";
import SecureRoute from "../SecureRoute";
import FirstVisit from "../FirstVisit";
import Organization from "../Organization/Organization";
import PlayList from "../PlayList/PlayList";
import PublicProfile from "../Discover/PublicProfile";
import Library from "../Library/Library";
import Users from "../Users/Users";
import PlayListEdit from "../PlayList/PlayListEdit";
import SmackTheFly from "../WorkShop/SmackTheFly/SmackTheFly";
import QuizNinja from "../WorkShop/QuizNinja/QuizNinja";
import PictureBook from "../WorkShop/PictureBook/PictureBook";
import DangerInTheJungle from "../WorkShop/DangerInTheJungle/DangerInTheJungle";
import Memory from "../WorkShop/Memory/Memory";
import SplatterSpace from "../WorkShop/SplatterSpace/SplatterSpace";
import BikeRace from "../WorkShop/BikeRace/BikeRace";
import MyGames from "../WorkShop/MyGames";
import Discover from "../Discover/Discover";
import ApiTest from "../ApiTest";
import NotFound from "../NotFound";
import Layout from "../Layout";
import NavMenu from "./NavMenu";
import AddGameToPlaylist from "../forms/AddGameToPlaylist";
import PopContainer from "../PopSplash/PopContainer";
import AccountEditForm from "../forms/profile/AccountEditForm";
import AdminPage from '../Admin/AdminPage';
import OrganizationProfile from '../Discover/OrganizationProfile';
import BundleIndex from '../Bundles/BundleIndex';
import BundleDetails from '../Bundles/BundleDetails';
import BundleAddItem from '../Bundles/BundleAddItem';
import WallIndex from '../Wall/WallIndex';
import WallMenu from '../Wall/WallMenu';
import WallAddItem from '../Wall/WallAddItem';
import SlimeBattle from '../WorkShop/SlimeBattle/SlimeBattle';
import SweetyWar from '../WorkShop/SweetyWar/SweetyWar';
import CrazyBalloons from '../WorkShop/CrazyBalloons/CrazyBalloons';
import AddPlaylistSuccess from '../landingPages/AddPlaylistSuccess';
import CircusRace from '../WorkShop/CircusRace/CircusRace';
import BlockBuster from '../WorkShop/BlockBuster/BlockBuster';
import { Changelog } from './Changelog';
import JigsawPuzzleView from '../WorkShop/JigsawPuzzle/JigsawPuzzleView';
import BuzzIt from '../WorkShop/BuzzIt/BuzzIt';
import WarOfStrategy from '../WorkShop/WarOfStrategy/WarOfStrategy';
import GoalShow from '../WorkShop/GoalShow/GoalShow';
import SuperSorterView from '../WorkShop/SuperSorter/SuperSorterView';
import PresentationView from '../WorkShop/Presentation/PresentationView';
import WhackAMole from '../WorkShop/WhackAMole/WhackAMole';
import Meditation from '../WorkShop/Meditation/Meditation';
import RaceCar from '../WorkShop/RaceCar/RaceCar';
import FeedTheFox from '../WorkShop/FeedTheFox/FeedTheFox';
import PopIt from '../WorkShop/PopIt/PopIt';
import DanceASloth from '../WorkShop/DanceASloth/DanceASloth';
import MadMonsters from '../WorkShop/MadMonsters/MadMonsters';
import PizzaParty from '../WorkShop/PizzaParty/PizzaParty';
import WordFinder from '../WorkShop/WordFinder/WordFinder';


interface Props {
    isShowingLog: boolean;
    showLog: () => void;
    hideLog: () => void;
}

interface State {
    layoutButtons?: ReactNode;
}

class MyWallApp extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
        this.setBtns = this.setBtns.bind(this);
    }

    setBtns(b: ReactNode){this.setState({layoutButtons: b})}

    render(){
        const props = this.props;
        

        return(
            <>
                <Switch>
                    <Route path='/login' component={Login} />
                    <Route path='/logout' component={Logout} />
                    <Route path='/passwordreset' component={ResetPassword} />
                    <Layout
                        buttons={this.state.layoutButtons}
                        navMenu={<NavMenu />}
                        globalModals={
                            <>
                                <AddGameToPlaylist />
                                <AccountEditForm/>
                                <BundleAddItem />
                                <WallAddItem />
                            </>
                        }
                    >
                        <Switch>
                            <Route path='/landing/addPlaylistSuccess/:pId/account/:aId' render={(p) => <AddPlaylistSuccess {...p} />} />
                            <Route path='/landing/addPlaylistError' render={(p) => <AddPlaylistSuccess {...p} error />} />
                            <Route path='/landing/addPlaylistAlready/:pId/account/:aId' render={(p) => <AddPlaylistSuccess {...p} allreadyAdded />} />
                            
                            <SecureRoute exact path='/' component={Discover} setLayoutButtons={this.setBtns}/>
                            <SecureRoute exact path='/apiTest' component={ApiTest} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/mygames' component={MyGames} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/firstvisit' component={FirstVisit} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/organization' component={Organization} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/playlist'component={PlayList} setLayoutButtons={this.setBtns}/>
                            
                            <SecureRoute path='/bundles/details/:id?' component={BundleDetails} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/bundles' component={BundleIndex} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/library/profile/:id' component={PublicProfile} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/library' component={Library} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/users' component={Users} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/wall/:id' component={WallMenu} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/wall' component={WallIndex} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/playlist/edit/:id' component={PlayListEdit} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/smackthefly/create' component={SmackTheFly} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/workshop/smackthefly/edit/:id?' component={SmackTheFly} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/quizninja/create' component={QuizNinja} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/workshop/quizninja/edit/:id?' component={QuizNinja} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/picturebook/create' component={PictureBook} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/workshop/picturebook/edit/:id?' component={PictureBook} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/dangerinthejungle/create' component={DangerInTheJungle} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/workshop/dangerinthejungle/edit/:id' component={DangerInTheJungle} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/memory/create' component={Memory} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/memory/edit/:id' component={Memory} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/supersorter/create' component={SuperSorterView} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/supersorter/edit/:id' component={SuperSorterView} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/presentation/create' component={PresentationView} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/presentation/edit/:id' component={PresentationView} setLayoutButtons={this.setBtns} />


                            <SecureRoute path='/workshop/SlimeBattle/create' component={SlimeBattle} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/SlimeBattle/edit/:id' component={SlimeBattle} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/SweetyWar/create' component={SweetyWar} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/SweetyWar/edit/:id' component={SweetyWar} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/GoalShow/create' component={GoalShow} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/GoalShow/edit/:id' component={GoalShow} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/CrazyBalloons/create' component={CrazyBalloons} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/CrazyBalloons/edit/:id' component={CrazyBalloons} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/CircusRace/create' component={CircusRace} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/CircusRace/edit/:id' component={CircusRace} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/BlockBuster/create' component={BlockBuster} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/BlockBuster/edit/:id' component={BlockBuster} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/splatterspace/edit/:id' component={SplatterSpace} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/bikerace/create' component={BikeRace} setLayoutButtons={this.setBtns}/>
                            <SecureRoute path='/workshop/bikerace/edit/:id' component={BikeRace} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/workshop/jigsawpuzzle/create' component={JigsawPuzzleView} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/jigsawpuzzle/edit/:id' component={JigsawPuzzleView} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/buzzit/create' component={BuzzIt} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/buzzit/edit/:id' component={BuzzIt} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/warofstrategy/create' component={WarOfStrategy} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/warofstrategy/edit/:id' component={WarOfStrategy} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/whackamole/create' component={WhackAMole} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/whackamole/edit/:id' component={WhackAMole} setLayoutButtons={this.setBtns} />

                            
                            <SecureRoute path='/workshop/meditation/create' component={Meditation} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/meditation/edit/:id' component={Meditation} setLayoutButtons={this.setBtns} />

                            
                            <SecureRoute path='/workshop/racecar/create' component={RaceCar} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/racecar/edit/:id' component={RaceCar} setLayoutButtons={this.setBtns} />

                            
                            <SecureRoute path='/workshop/feedthefox/create' component={FeedTheFox} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/feedthefox/edit/:id' component={FeedTheFox} setLayoutButtons={this.setBtns} />

                            
                            <SecureRoute path='/workshop/pizzaparty/create' component={PizzaParty} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/pizzaparty/edit/:id' component={PizzaParty} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/popit/create' component={PopIt} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/popit/edit/:id' component={PopIt} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/danceasloth/create' component={DanceASloth} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/danceasloth/edit/:id' component={DanceASloth} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/madmonsters/create' component={MadMonsters} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/madmonsters/edit/:id' component={MadMonsters} setLayoutButtons={this.setBtns} />

                            <SecureRoute path='/workshop/wordfinder/create' component={WordFinder} setLayoutButtons={this.setBtns} />
                            <SecureRoute path='/workshop/wordfinder/edit/:id' component={WordFinder} setLayoutButtons={this.setBtns} />


                            <SecureRoute path='/discover/org/:id' component={OrganizationProfile} setLayoutButtons={this.setBtns}/>

                            <SecureRoute path='/admin' component={AdminPage} setLayoutButtons={this.setBtns}/>

                            <Route component={NotFound}/>
                        </Switch>
                        <PopContainer />
                        <div className='version-pixel' onClick={props.showLog}>v.</div>
                        <Changelog closeFunc={props.hideLog} visible={props.isShowingLog} />
                    </Layout>
                </Switch>
            </>
        )
    }
}

export default MyWallApp;