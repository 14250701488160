import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as BikeRaceStore from '../../../store/games/BikeRaceStore';
import {bikeRaceTransformQuizToStyle, EditQuizType} from '../../../store/games/BikeRaceStore';
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {AppState} from "../../../store/configureStore";
import {
    CheckBox,
    ColorAndFont,
    ImageFormButton,
    InputField,
    Slider,
    SubmitButton,
    TextAlignmentFormGroup
} from "../../forms/FormGroup";
import SoundForm from "../../forms/games/SoundForm";
import {BikeRaceQuizPreview} from "./BikeRaceQuizPreview";
import ConfirmationForm from "../../forms/games/ConfirmationForm";
import {FormGeneralError} from "../../Error/FormGeneralError";
import {TextAlignment} from "../../../model/Game/TextType";
import {ErrorState} from "../../../store/Error";

interface Props {
    bikeRaceActions: typeof BikeRaceStore.actionCreators;
    bikeRaceState: BikeRaceStore.BikeRaceState;
    errors: ErrorState['bikeRaceState'];
}

const BikeRaceQuizForm = (props: Props) => {
    if (!props.bikeRaceState.ui.showQuizForm) {
        return null;
    }

    let formValid = true;

    const ui = props.bikeRaceState.ui;
    const quiz = props.bikeRaceState.editQuiz;
    const question = quiz.question;

    if(!quiz.question.image && !quiz.question.text.content) {
        formValid = false; //must have question
    }

    const confirmDelete = <ConfirmationForm
        text={"bike_race_quiz_confirm_delete"}
        confirmText={"yes"}
        cancelText={"no"}
        onConfirm={() => props.bikeRaceActions.deleteQuiz(quiz.index)}
        onCancel={() => props.bikeRaceActions.showQuizDelete(false)}/>

    const loading = props.bikeRaceState.isLoading.createQuiz || props.bikeRaceState.isLoading.updateQuiz || props.bikeRaceState.isLoading.deleteQuiz;

    if (ui.showAudioForm) {
        return(
          <Dialog
            onClose={() => props.bikeRaceActions.showAudioForm(false)}
            className={'bike-race-quiz-form-dialog'}
            loading={false}
          >
            <SoundForm onSubmit={sound => props.bikeRaceActions.setEditField('sound', sound)} />
          </Dialog>
        );
    }

    const onSubmit = (quiz: EditQuizType) => {
        if (quiz.isNew) {
            props.bikeRaceActions.createQuiz(quiz);
        } else {
            props.bikeRaceActions.updateQuiz(quiz, quiz.index);
        }
    };

    // TODO: Use ImageRadioGroup instead of CheckBox for answer
    return(
        <Dialog
            onClose={() => props.bikeRaceActions.showQuizForm(false)}
            className='bike-race-quiz-form-dialog'
            loading={loading}
            icon={quiz.isNew ? undefined : 'trash'}
            onIconClick={() => props.bikeRaceActions.showQuizDelete(true)}
            icons={[
                {icon: 'eye-dropper', onClick: () => props.bikeRaceActions.saveStyle(), hoverMsg: <div className='min-width'><Translate id='save_style' /></div>},
                {icon: 'fill-drip', onClick: () => props.bikeRaceActions.applyStyle(),
                    hoverMsg: (
                        <div>
                            <div><Translate id='apply_style'/></div>
                            <BikeRaceQuizPreview
                                quiz={bikeRaceTransformQuizToStyle(quiz, props.bikeRaceState.style)}
                            />
                        </div>
                    )
                }
            ]}
        >
            <h1><Translate id='bike_race_quiz_title' /></h1>
            <form onSubmit={e => e.preventDefault()}>
                <ImageFormButton
                    name={'bike_race_questionImage'}
                    imageSubmit={img => props.bikeRaceActions.setEditField('image', img)}
                    previewWidth={0}
                    previewHeight={225}
                    minRatio={1.0 / 150.0} // Min ratio is 1 pixel width in 150 height
                    maxRatio={290.0 / 150.0} // Max ratio is width / height
                    // eslint-disable-next-line
                    unsavedChange={question.image != quiz._o.question.image}
                />
                <InputField
                    placeholder={'bike_race_questionText'}
                    name={'content'}
                    type='text'
                    value={question.text.content || ""}
                    onChange={e => props.bikeRaceActions.setQuestionTextField('content', e.target.value)}
                    noLabel
                    tabIndex={1}
                    original={quiz._o.question.text.content || ""}
                />

                <Slider min={20} max={72}
                        name='fontSize'
                        value={question.text.fontSize}
                        onChange={e => props.bikeRaceActions.setQuestionTextField(e.target.name, e.target.value)}
                        original={quiz._o.question.text.fontSize}
                />

                <ColorAndFont
                    className={'small'}
                    colorValue={question.text.fontColorHex}
                    onColorChange={e => props.bikeRaceActions.setQuestionTextField('fontColorHex', e.target.value)}
                    fontFamilyValue={question.text.fontFamily}
                    onFontFamilyChange={e => props.bikeRaceActions.setQuestionTextField('fontFamily', e.target.value)}
                    originalFontFamily={quiz._o.question.text.fontFamily}
                    originalColor={quiz._o.question.text.fontColorHex}
                />
                <TextAlignmentFormGroup
                    name={'textAlignment'} value={question.text.textAlignment || TextAlignment.Center}
                    onChange={e => props.bikeRaceActions.setQuestionTextField('textAlignment', e.target.value)}
                    original={quiz._o.question.text.textAlignment || TextAlignment.Center}
                />
                <CheckBox
                    name={'correct'}
                    className={'checkbox-width'}
                    active={quiz.correctAnswer != null && quiz.correctAnswer}
                    onChange={e => props.bikeRaceActions.setAnswer(e.target.value)}
                    original={quiz._o.correctAnswer}
                />
                <div className='clear-fix' />
                <FormGeneralError error={props.errors.createQuiz}/>
                <FormGeneralError error={props.errors.updateQuiz}/>
                <SubmitButton text={'submit'} onClick={() => {onSubmit(quiz)}} disabled={!formValid} split/>
            </form>
            <h2><Translate id='bike_race_preview_title'/></h2>
            <BikeRaceQuizPreview quiz={quiz} scaleFactor={(2/3.0)}/>
            {props.bikeRaceState.ui.showQuizDelete && confirmDelete}
        </Dialog>
    );
};

export default connect(
    (state: AppState) => ({
        bikeRaceState: state.bikeRaceState,
        errors: state.errors.bikeRaceState
    }),
    dispatch => ({bikeRaceActions: bindActionCreators(BikeRaceStore.actionCreators, dispatch)})
)(BikeRaceQuizForm)