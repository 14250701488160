import React, {Component, ComponentType,ReactNode} from "react";
import {LayoutComponentProps} from "../Layout";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withLayoutButtons = <P extends LayoutComponentProps<any>>(WrappedComponent: ComponentType<P>, renderButtonFunc: (p: P) => ReactNode) => {
    return class WithLayoutButtons extends Component<P>{

        private latestButtons: string;

        constructor(props: P) {
            super(props);
            this.latestButtons = '';
        }

        componentDidMount(): void {
            const newButtons = renderButtonFunc(this.props);
            this.latestButtons = JSON.stringify(newButtons);
            this.props.setLayoutButtons(newButtons);
        }

        componentDidUpdate(): void {
            const newButtons = renderButtonFunc(this.props);
            const newButtonsJson = JSON.stringify(newButtons);
            if(this.latestButtons !== newButtonsJson){
                this.latestButtons = newButtonsJson;
                this.props.setLayoutButtons(newButtons);
            }
        }

        componentWillUnmount(): void {
            this.props.setLayoutButtons(undefined);
        }

        render(){
            return <WrappedComponent {...this.props} />;
        }
    }
};