import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators, MeState} from "../../../store/Me";

import 'react-image-crop/dist/ReactCrop.css';
import ImageForm from "../games/ImageForm";
import {AppState} from "../../../store/configureStore";

interface Props{
    me: MeState;
    meActions: typeof actionCreators;
}

const ProfileImageForm = (props: Props) => {
    return (
        <ImageForm onSubmit={props.meActions.updateImage} previewWidth={100} previewHeight={100} aspectRatio={1}/>
    );
};

export default connect(
    (state: AppState) => ({me: state.me}),
    dispatch => ({
        meActions: bindActionCreators(actionCreators, dispatch)
    })
)(ProfileImageForm);