import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {AppState} from '../../store/configureStore';
import * as AuthStore from '../../store/Auth';
import { Dialog } from '../Dialog';
import { SmartAdd } from '../../model/SmartAdd';
import { InputField, SelectList, SubmitButton } from '../forms/FormGroup';
import { FetchError, fetchGet, fetchPost, fetchPut } from '../../services/FetchHelper';
import { PlayListType } from '../../model/PlayListType';
import PlayListPreview from '../ModelPreview/PlayListPreview';

interface Props{
    item: SmartAdd;
    authState: AuthStore.AuthState;
    onClose: () => void;
    onSuccess: (item: SmartAdd) => void;
}

const SmartAddForm = (props: Props) => {

    const [edit, setEdit] = useState({...props.item});
    const [error, setError] = useState<FetchError>();
    const [loading, setLoading] = useState(false);
    const [pl, setPl] = useState<PlayListType|false>();

    useEffect(() => {
        if(edit.playlistId){
            setPl(undefined);
            fetchGet<PlayListType>(`api/playlist/${edit.playlistId}`)
                .then(setPl)
                .catch(_ => setPl(false));
        }

    }, [edit.playlistId]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError(undefined);
        setLoading(true);
        const onError = (e: FetchError) => {setError(e); setLoading(false);};
        if(!edit.id){
            fetchPost<SmartAdd>('api/smartAdd', edit)
                .then(props.onSuccess)
                .catch(onError);
        }
        else{
            fetchPut<SmartAdd>(`api/smartAdd/${edit.id}`, edit)
                .then(props.onSuccess)
                .catch(onError);
        }

    }

    return(
        <Dialog className='smart-add-form' onClose={props.onClose} loading={loading}>
            {error && <div className='form-error'>{error.status}{error.message}</div>}
            <form onSubmit={onSubmit}>
                <InputField labelWidth100 type='text' name='playlist_title' value={edit.playlistId} onChange={e => setEdit({ ...edit, playlistId: e.target.value })} />
                <SelectList 
                    labelWidth100
                    onChange={e => setEdit({...edit, row: parseInt(e.target.value)})} 
                    defaultValue={edit.row + ""} 
                    options={[{ name: "1", value: '0' }, { name: "2", value: '1' }, { name: "3", value: '2' }]}
                    name='row' 
                />
                <SubmitButton split text={'submit'} />
            </form>

            <div>
                {pl === false ? 'playlist not found...' : pl ? <PlayListPreview playlist={pl} noMenu onlyImage /> : 'loading...' }
            </div>
        </Dialog>
    )
};

export default connect(
    (state: AppState) => ({authState: state.auth})
)(SmartAddForm)