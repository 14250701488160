import React, { useEffect, useState } from 'react';
import * as JigsawStore from '../../../store/games/JigsawPuzzleStore';
import { actionCreators as myGamesActions } from "../../../store/MyGames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import GameDetailed from "../../ModelPreview/GameDetailed";
import EditGameComponent from '../../EditGameComponent';
import { AppState } from "../../../store/configureStore";
import { LayoutComponentProps } from "../../Layout";
import { ErrorState } from "../../../store/Error";
import { JigsawPuzzleImage } from "../../../model/Game/JigsawPuzzle/JigsawPuzzleImage";
import JigsawImageForm from "./JigsawImageForm";
import JigsawPreview from "./JigsawPreview";
import JigsawPuzzleSettings from "./JigsawPuzzleSettings";

interface State {
    editItem?: JigsawPuzzleImage & { index: number };
}

interface Props extends LayoutComponentProps<{ id: string }> {
    jigsawActions: typeof JigsawStore.actionCreators;
    myGamesActions: typeof myGamesActions;
    errors: ErrorState['jigsawPuzzleState'];
    jigsawState: JigsawStore.JigsawPuzzleState;
}

const JigsawPuzzleView = (props: Props) => {
    const game = props.jigsawState.game;

    useEffect(() => {
        props.jigsawActions.resetGame();
    }, [props.jigsawActions]);
    const [state, setState] = useState<State>({});

    const maxImagesReached = game && game.images.length >= 7;

    return (
        <EditGameComponent
            gameState={props.jigsawState}
            newGameElementFunction={() => setState({ ...state, editItem: { image: undefined, borderColor: "#AAAAAA", index: -1 } })}
            newGameElementText={maxImagesReached ? 'jigsaw_create_disabled' : 'jigsaw_create_image'}
            newGameElementDisabled={maxImagesReached}
            routeMatch={props.match}
            setLayoutButtons={props.setLayoutButtons}
            contentClass={"jigsaw"}
            gameType='JigsawPuzzle'
        >
            <GameDetailed
                game={game}
                gameType={'JigsawPuzzle'}
                tempDescription={
                    <div>
                        <p><Translate id='jigsaw_text' options={{ renderInnerHtml: true, renderToStaticMarkup: false }} /></p>
                    </div>
                }
                tempTitle={<Translate id='jigsaw_title' />}
                onClick={() => props.myGamesActions.showEditDialog(true, game)}
                gameStats={props.jigsawState.gameStats}
            />
            <div className='clear-fix' />
            {state.editItem &&
                <JigsawImageForm
                    item={state.editItem}
                    onSubmit={
                        state.editItem.index > -1
                            ? props.jigsawActions.updateItem
                            : props.jigsawActions.createItem
                    }
                    onClose={() => setState({ ...state, editItem: undefined })}
                    loading={!!(
                        props.jigsawState.isLoading.createItem ||
                        props.jigsawState.isLoading.updateItem ||
                        props.jigsawState.isLoading.deleteItem
                    )}
                    error={props.errors.createItem || props.errors.updateItem || props.errors.deleteItem}
                    onDelete={() => state.editItem ? props.jigsawActions.deleteItem(state.editItem) : undefined}
                />
            }
            <div className='preview-container'>
                {game && game.images && game.images.map((x, i) =>
                    <JigsawPreview key={i} jigsawImage={x} onClick={() => setState({ ...state, editItem: { ...x, index: i } })} />
                )}
            </div>
            <JigsawPuzzleSettings />
        </EditGameComponent>
    );
}
export default connect(
    (state: AppState) => ({
        jigsawState: state.jigsawPuzzleState,
        errors: state.errors.jigsawPuzzleState
    }),
    dispatch => ({
        jigsawActions: bindActionCreators(JigsawStore.actionCreators, dispatch),
        myGamesActions: bindActionCreators(myGamesActions, dispatch)
    })
)(JigsawPuzzleView);