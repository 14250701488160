import moment from "moment";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export class FetchError extends Error{
    public status: number;
    public isJson: boolean;
    public body?: {[key: string]: string[]};

    constructor(status: number, statusText: string, isJson: boolean, body?: { [key: string]: string[] }){
        super(statusText);
        this.status = status;
        this.isJson = isJson;
        this.body = body;
    }

    toString(){
        return JSON.stringify({status: this.status, isJson: this.isJson, body: this.body});
    }
}

type UseFetchResult<T> = [
    (url: string, body?: unknown) => void,
    boolean,
    FetchError | undefined,
    Dispatch<SetStateAction<((m: T) => void)|undefined>>,
];

export const useFetchGet = <T extends unknown>(onSuccess?: (m: T) => void) => useFetch<T>('GET', onSuccess);
export const useFetchPut = <T extends unknown>(onSuccess?: (m: T) => void) => useFetch<T>('PUT', onSuccess);
export const useFetchPost = <T extends unknown>(onSuccess?: (m: T) => void) => useFetch<T>('POST', onSuccess);
export const useFetchDelete = <T extends unknown>(onSuccess?: (m: T) => void) => useFetch<T>('DELETE', onSuccess);

interface INameable{
    name: string;
}

export const hasName = (obj: unknown): obj is INameable => {
    return (obj as INameable).name !== undefined
        && typeof (obj as INameable).name === "string";
}

const useFetch = <T extends unknown>(method: 'GET'|'POST'|'DELETE'|'PUT', onSuccess?: (m: T) => void): UseFetchResult<T> => {
    const [_onSuccces, setOnSuccess] = useState(() => onSuccess);
    const [loading, setLoading] = useState(() => false);
    const [error, setError] = useState<FetchError>();
    const [abortController] = useState(new AbortController());

    //make sure invoke function will only be created once
    const invoke = useCallback(async (url: string, body?: unknown) => {
        setLoading(true);
        setError(undefined);
        try{
            const r = await fireRequest<T>(method, abortController, url, body);
            setLoading(false);
            _onSuccces && _onSuccces(r);
        }
        catch(e: unknown){
            if (hasName(e) && e.name === 'AbortError') {
                 /* Ignore */ 
            }
            else{
                setLoading(false);
                if(e instanceof FetchError){
                    setError(e);
                    return e;
                }
            }
        }
    }, [method, abortController, _onSuccces]);

    //Cleanup
    useEffect(() => {
        return  () => {
            abortController.abort();
        }
    },[abortController]);
    
    return [invoke, loading, error, setOnSuccess];
}

const fireRequest = async <T extends unknown>(method: 'GET' | 'POST' | 'PUT' | 'DELETE', abortController: AbortController, url: string, body?: unknown) => {
    if (method === 'GET') {
        return await fetchGet<T>(url, abortController);
    }
    else if (method === 'POST') {
        return await fetchPost<T>(url, body, abortController);
    }
    else if (method === 'PUT') {
        return await fetchPut<T>(url, body, abortController);
    }
    else if (method === 'DELETE') {
        return await fetchDelete<T>(url, abortController);
    }
    else {
        throw new Error(`Unsupport HTTP Verb ${method}`);
    }
}

const handleResponse = async <T extends unknown> (r: Response) => {
    try {
        if (r.ok) {
            //Only try to serialzie if we get json back
            const contentType = r.headers.get("Content-Type");
            if (contentType && contentType.indexOf("/json") !== -1) {
                return r.json() as Promise<T>;
            }

            return {} as T; //return promise with empty object in resolve
        }
        const m = await r.json();
        throw new FetchError(r.status, r.statusText, true, m)
    }
    catch(e) {
        if(e instanceof FetchError){
            throw e;
        } 
        throw new FetchError(r.status, r.statusText, false);
    }
}

export const fetchGet = async <T extends unknown> (url: string, abortController?: AbortController) => {
    const token = getToken();

    const r = await fetch(url, {
        method: 'get',
        mode: 'same-origin',
        signal: abortController?.signal,
        headers: {
            Accept: 'application/json, text/plain, */*',
            ...(token && { Authorization: `Bearer ${token}` })
        },
    });
   
    return await handleResponse<T>(r);
}

export const fetchPost = async <T extends unknown>(url: string, body: unknown, abortController?: AbortController) => {
    const token = getToken();
    const r = await fetch(url, {
        method: 'post',
        mode: 'same-origin',
        body: JSON.stringify(body),
        signal: abortController?.signal,
        headers: {
            'Authorization' : `Bearer ${token}`,
            'Accept' : 'application/json, text/plain, */*',
            'Content-Type' : 'application/json'
        }
    });

    return await handleResponse<T>(r);
}

export const fetchPut = async <T extends unknown>(url: string, body: unknown, abortController?: AbortController) => {
    const token = getToken();
    const r = await fetch(url, {
        method: 'put',
        mode: 'same-origin',
        signal: abortController?.signal,
        body: JSON.stringify(body),
        headers: {
            'Authorization' : `Bearer ${token}`,
            'Accept' : 'application/json, text/plain, */*',
            'Content-Type' : 'application/json'
        }
    });
    return await handleResponse<T>(r);
}

export const fetchDelete = async <T extends unknown>(url: string, abortController?: AbortController) => {
    const token = getToken();
    const r = await fetch(url, {
            method: 'delete',
            mode: 'same-origin',
            signal: abortController?.signal,
            headers: {
                'Authorization' : `Bearer ${token}`,
                'Accept' : 'application/json, text/plain, */*',
            }
        }
    );

    return await handleResponse<T>(r);
}

const getToken = () => {
    const expireTimeString = localStorage.getItem("myfloorjwt_expire") || '';
    const expireTime = moment(expireTimeString);
    let token;
    if (expireTime && expireTime > moment()) {
        token = localStorage.getItem("myfloorjwt") || '';
    }
    return token;
}