import React from 'react';
import {WarOfStrategyGame} from "../../../model/Game/WarOfStrategy/WarOfStrategyGame";
import {WosQuiz} from "../../../model/Game/WarOfStrategy/WosQuiz";
import {Translate} from "react-localize-redux";
import {WarOfStrategyQuizPreview} from "./WarOfStrategyQuizPreview";

interface Props{
    game: WarOfStrategyGame;
    onQuizClick?: (quiz: WosQuiz, index: number) => void;
}

export const WosGamePreview = (props: Props) => {

    const game = props.game;

    return(
        <div className='wos-quizzes'>
            <h2><Translate id='wos_quizzes'/></h2>
            {game && game.quizzes.length > 0 ?
                <div className='wos-quizzes-container'>
                    {
                        game.quizzes.map((quiz, index) => {
                            return (<WarOfStrategyQuizPreview key={index} quiz={quiz} onClick={() => props.onQuizClick && props.onQuizClick(quiz, index)}/>)
                        })
                    }
                    <div className='clear-fix'/>
                </div> :
                <Translate id='wos_no_quizzes' />}
        </div>
    )
};