import {createWosQuizEditFormData, createWosQuizFormData} from "../../services/FormDataHelper";
import axios from "axios/index";
import {errorActions} from "../Error";
import {
    AbstractGameIsLoading,
    abstractGameReducerMethods,
    AbstractGameState,
    AbstractGameUi,
    actionCreators as gamesActions
} from "./Games";
import {history} from '../../index';
import {CreateAuthHeader} from "../../services/AxiosHelper";
import {WarOfStrategyGame} from "../../model/Game/WarOfStrategy/WarOfStrategyGame";
import {WosQuiz} from "../../model/Game/WarOfStrategy/WosQuiz";
import {WosAnswer} from "../../model/Game/WarOfStrategy/WosAnswer";
import {ChangeEvent, TA} from "../configureStore";
import {Reducer} from "redux";
import {SoundUploadType} from "../../model/SoundUploadType";
import {ImageUploadType} from "../../model/ImageUploadType";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import {getLocaleStorageItem, saveLocaleStorageItem} from "../../services/LocalStorageService";

export const reducerName = 'wosState';

const WOS_RESET_GAME = 'WOS_RESET_GAME';
const WOS_SET_EDIT_QUIZ = 'WOS_SET_EDIT_QUIZ';
const WOS_HIDE_QUIZ_FORM = 'WOS_HIDE_QUIZ_FORM';
const WOS_QUIZ_TEXT_CHANGE = 'WOS_QUIZ_TEXT_CHANGE';
const WOS_QUIZ_CORRECT_CHANGE = 'WOS_QUIZ_CORRECT_CHANGE';
const WOS_QUIZ_IMAGE_CHANGE = 'WOS_QUIZ_IMAGE_CHANGE';
const WOS_QUIZ_SHOW_COLOR_PICKER = 'WOS_QUIZ_SHOW_COLOR_PICKER';
const WOS_QUIZ_SHOW_IMAGE_FORM = 'WOS_QUIZ_SHOW_IMAGE_FORM';
const WOS_QUIZ_SHOW_SOUND_FORM = 'WOS_QUIZ_SHOW_SOUND_FORM';
const WOS_QUIZ_SET_SOUND = 'WOS_QUIZ_SET_SOUND';
const WOS_CREATE_QUIZ_REQUEST = 'WOS_CREATE_QUIZ_REQUEST';
const WOS_CREATE_QUIZ_RESPONSE = 'WOS_CREATE_QUIZ_RESPONSE';
const WOS_QUIZ_DIFFICULTY_CHANGE = 'WOS_QUIZ_DIFFICULTY_CHANGE';
const WOS_UPDATE_QUIZ_REQUEST =  'WOS_UPDATE_QUIZ_REQUEST';
const WOS_UPDATE_QUIZ_RESPONSE = 'WOS_UPDATE_QUIZ_RESPONSE';
const WOS_SHOW_CONFIRM_DELETE = 'WOS_SHOW_CONFIRM_DELETE';
const WOS_DELETE_QUIZ_REQUEST = 'WOS_DELETE_QUIZ_REQUEST';
const WOS_DELETE_QUIZ_RESPONSE = 'WOS_DELETE_QUIZ_RESPONSE';
const WOS_SET_FIELD = 'WOS_SET_FIELD';
const WOS_SAVE_STYLE = 'WOS_SAVE_STYLE';
const WOS_APPLY_STYLE = 'WOS_APPLY_STYLE';
const WOS_CONVERT_TO_DITJ_REQUEST = "WOS_CONVERT_TO_DITJ_REQUEST";
const WOS_CONVERT_TO_DITJ_RESPONSE = "WOS_CONVERT_TO_DITJ_RESPONSE";

export type WosContainer = 'question' | 'answer1' | 'answer2' | 'answer3';

const wosStyleKey = 'wos_style';

interface EditQuiz extends WosQuiz {
    newQuiz: boolean;
    index: number;
    _o: WosQuiz;
}

interface WosStyle{
    question: TextType;
    answer: TextType;
}

export interface WarOfStrategyState extends AbstractGameState {
    game?: WarOfStrategyGame;
    quiz: EditQuiz;
    isLoading: AbstractGameIsLoading & {
        createQuiz: boolean; updateQuiz: boolean; deleteQuiz: boolean;
    };
    ui: AbstractGameUi & {
        showConfirmDeleteQuiz: boolean;
        showQuizForm: boolean;
        quiz: {
            question: {showSoundForm: boolean};
        };
    };
    style: WosStyle;
}

const initialStyle = {
    question: {
        content: "",
        fontSize: 36,
        fontFamily: "Lato",
        fontColorHex: "#000000",
        textAlignment: TextAlignment.Center
    },
    answer:{
        content: "",
        fontSize: 36,
        fontFamily: "Lato",
        fontColorHex: "#000000",
        textAlignment: TextAlignment.Center
    }
};

export function wosTransformQuizToStyle(quiz: EditQuiz, style: WosStyle): EditQuiz{
    return({
        ...quiz,
        question: {
            ...quiz.question,
            text: {
                ...style.question,
                content: quiz.question.text.content
            }
        },
        answer1: {
            ...quiz.answer1,
            text: {
                ...style.answer,
                content: quiz.answer1.text.content
            }
        },
        answer2: {
            ...quiz.answer2,
            text: {
                ...style.answer,
                content: quiz.answer2.text.content
            }
        },
        answer3: {
            ...quiz.answer3,
            text: {
                ...style.answer,
                content: quiz.answer3.text.content
            }
        }
    });
}

const initialState = (): WarOfStrategyState => {
    const defaultStyle = getLocaleStorageItem<WosStyle>(wosStyleKey, initialStyle);
    const answer: WosAnswer = {
        image: null,
        correct: false,
        text: {...defaultStyle.answer, content:""},
        imageId: '',
    };

    return {
        game: undefined,
        gameStats: undefined,
        gameType: "WarOfStrategy",
        quiz: {
            newQuiz: true,
            index: -1,
            difficulty: 1,
            question: {
                image: null,
                sound: null,
                imageId: '',
                soundId: '',
                text: {...defaultStyle.question, content: ""},
            },
            answer1: {...answer},
            answer2: {...answer},
            answer3: {...answer},
            _o:{
                difficulty: 1,
                question: {
                    image: null,
                    sound: null,
                    imageId: '',
                    soundId: '',
                    text: {...defaultStyle.question, content: ""},
                },
                answer1: {...answer},
                answer2: {...answer},
                answer3: {...answer}
            }
        },
        isLoading: {
            getGame: false, createQuiz: false, updateQuiz: false, deleteQuiz: false, addAuthor: false, removeAuthor: false,
            transferOwner: false, updateSettings: false
        },
        ui: {
            showConfirmDeleteQuiz: false,
            showQuizForm: false,
            showSettings: false,
            quiz: {
                question:{ showSoundForm: false}
            }
        },
        style: defaultStyle
    };
};

export const actionCreators = {
    saveStyle: (): TA => async(dispatch, getState) => {
        const newStyle: WosStyle = {
            question: {...getState().wosState.quiz.question.text},
            answer: {...getState().wosState.quiz.answer1.text}
        };
        saveLocaleStorageItem(wosStyleKey, newStyle);
        dispatch({type: WOS_SAVE_STYLE, newStyle});
    },
    applyStyle: (): TA => async(dispatch, getState) => {
        dispatch({type: WOS_APPLY_STYLE});
    },
    setField: (e: ChangeEvent<string>|ChangeEvent<number>|ChangeEvent<boolean>): TA => async(dispatch) => {
        dispatch({type: WOS_SET_FIELD, name:e.target.name, value:e.target.value});
    },
    showConfirmDelete: (show: boolean): TA => async(dispatch) => {
        dispatch({type: WOS_SHOW_CONFIRM_DELETE, show});
    },
    getGame: (id: string): TA => async(dispatch) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        dispatch(gamesActions.getGame(id, true, 'WarOfStrategy'));
    },
    resetGame: (): TA => async(dispatch) => {
        dispatch({type: WOS_RESET_GAME});
    },
    setSound: (sound: SoundUploadType|undefined): TA => async(dispatch) => {
        dispatch({type: WOS_QUIZ_SET_SOUND, sound});
    },
    showSoundForm: (show: boolean): TA => async(dispatch) => {
        dispatch({type: WOS_QUIZ_SHOW_SOUND_FORM, show});
    },
    showNewQuiz: (): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        const curQ = getState().wosState.quiz;
        dispatch({type: WOS_SET_EDIT_QUIZ, quiz: curQ.newQuiz ? curQ : initialState().quiz});
    },
    openEditQuiz: (quiz: WosQuiz, index: number): TA => async(dispatch, getState) => {
        dispatch(errorActions.resetAllErrors(reducerName));
        const curQ = getState().wosState.quiz;
        const editQuiz: EditQuiz = { ...quiz, index, newQuiz: false, _o:{...quiz}};
        //if already editing the one with this ID, show current form.
        const quizToShow = curQ.index !== index ? editQuiz : curQ;
        
        dispatch({type: WOS_SET_EDIT_QUIZ, quiz: quizToShow})
    },
    hideQuizForm: (): TA => async(dispatch) => {
        dispatch({type: WOS_HIDE_QUIZ_FORM});
    },
    updateTextValue: (key: string, event: ChangeEvent<string>|ChangeEvent<number>): TA => async(dispatch) => {
        const field = event.target.name;
        const value = event.target.value;
        dispatch({type: WOS_QUIZ_TEXT_CHANGE, key: key,  field: field,  value: value});
    },
    updateDifficulty: (diff: number): TA => async(dispatch) => {
        dispatch({type: WOS_QUIZ_DIFFICULTY_CHANGE, value: diff});
    },
    showColorPicker: (key: string, show: boolean): TA => async(dispatch) => {
        dispatch({type: WOS_QUIZ_SHOW_COLOR_PICKER, key: key, show: show});
    },
    updateCorrect: (key: string, event: ChangeEvent<boolean>): TA => async(dispatch) => {
        const value = event.target.value;
        dispatch({type: WOS_QUIZ_CORRECT_CHANGE, key:key, correct:value});
    },
    updateImage: (key: string, image: ImageUploadType | undefined): TA => async(dispatch) => {
        dispatch({type: WOS_QUIZ_IMAGE_CHANGE, key, image});
        dispatch({type: WOS_QUIZ_SHOW_IMAGE_FORM, key, show: false});
    },
    createQuiz: (quiz: EditQuiz): TA => async(dispatch, getState) => {
        const game = getState().wosState.game;
        const gameId = game && game.id;
        dispatch({type: WOS_CREATE_QUIZ_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        const formData = createWosQuizFormData(quiz, gameId);

        const url = `api/workshop/warofstrategy/quiz`;
        axios.post(url, formData, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                if(!gameId){
                    dispatch(actionCreators.saveStyle());
                    history.push(`/workshop/warofstrategy/edit/${data.id}`);
                }
                dispatch({type: WOS_SET_EDIT_QUIZ, quiz: initialState().quiz});
                dispatch({type: WOS_CREATE_QUIZ_RESPONSE, data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'createQuiz', error));
            });
    },
    updateQuiz: (quiz: EditQuiz, quizIndex: number): TA => async(dispatch, getState) => {
        const game = getState().wosState.game;
        const gameId = game && game.id;
        if(!gameId) return;
        dispatch({type: WOS_UPDATE_QUIZ_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));

        const formData = createWosQuizEditFormData(quiz, quizIndex, gameId);
        const url = 'api/workshop/warofstrategy/quiz';
        axios.put(url, formData, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: WOS_UPDATE_QUIZ_RESPONSE, data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'updateQuiz', error));
            })
    },
    deleteQuiz: (quizIndex: number): TA => async(dispatch, getState) => {
        const game = getState().wosState.game;
        const gameId = game && game.id;
        if(!gameId) return;

        dispatch({type: WOS_DELETE_QUIZ_REQUEST});
        dispatch(errorActions.resetAllErrors(reducerName));
        const url = `api/workshop/warofstrategy/${gameId}/quiz/${quizIndex}`;
        axios.delete(url, CreateAuthHeader(getState))
            .then(response => {
                const data = response.data;
                dispatch({type: WOS_DELETE_QUIZ_RESPONSE, data});
            })
            .catch(error => {
                dispatch(errorActions.reportAxiosError(reducerName, 'deleteQuiz', error));
            })
    },
    convertToDitj: (): TA => async(dispatch, getState) => {
        const game = getState().wosState.game;
        const gameId = game && game.id;
        if(!gameId) return;

        dispatch({type: WOS_CONVERT_TO_DITJ_REQUEST});
        const url = `api/workshop/warofstrategy/${gameId}/ConvertToDitj`;
        axios.post(url, {}, CreateAuthHeader(getState))
            .then(r => {
                history.push(`/workshop/dangerinthejungle/edit/${r.data.id}`);
                dispatch({type: WOS_CONVERT_TO_DITJ_RESPONSE, data: r.data})
            });
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: WarOfStrategyState, action: any) => WarOfStrategyState} = {
    ...abstractGameReducerMethods,
    GAMES_SET_ACTIVE_TRANSLATION: (state) => {
        return{
            ...state,
            quiz: initialState().quiz
        }
    },
    WOS_APPLY_STYLE: (state, action) => {
        return{
            ...state,
            quiz: wosTransformQuizToStyle(state.quiz, state.style)
        }
    },
    WOS_SAVE_STYLE: (state, action) => {
        return{
            ...state,
            style: action.newStyle
        }
    },
    WOS_RESET_GAME: (state) => {
        return initialState();
    },
    WOS_SET_FIELD: (state, action) => {
        if(!state.game) return state;
        return{
            ...state,
            game:{
                ...state.game,
                [action.name]: action.value
            }
        }
    },
    WOS_DELETE_QUIZ_REQUEST: (state) => {
        return{
            ...state,
            isLoading:{
                ...state.isLoading, 
                deleteQuiz: true
            }
        }
    },
    WOS_DELETE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading:{
                ...state.isLoading,
                deleteQuiz: false
            },
            ui: {
                ...state.ui,
                showQuizForm: false
            }
        }
    },
    WOS_SHOW_CONFIRM_DELETE: (state, action) => {
        return{
            ...state, 
            ui:{
                ...state.ui,
                showConfirmDeleteQuiz: action.show
            }
        }
    },
    WOS_UPDATE_QUIZ_REQUEST: (state) => {
        return{
            ...state, 
            isLoading: {
                ...state.isLoading, 
                updateQuiz: true
            }
        }
    },
    WOS_UPDATE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state, 
            game: action.data,
            isLoading: {
                ...state.isLoading,
                updateQuiz: false
            },
            ui: {
                ...state.ui, 
                showQuizForm: false
            }
        }
    },
    WOS_CREATE_QUIZ_REQUEST: (state) => {
        return{
            ...state, 
            isLoading: {
                ...state.isLoading, 
                createQuiz: true
            }
        }
    },
    WOS_CREATE_QUIZ_RESPONSE: (state, action) => {
        return{
            ...state,
            game: action.data,
            isLoading: {
                ...state.isLoading, 
                createQuiz: false
            },
            ui: {
                ...state.ui,
                showQuizForm: false
            }
        }
    },
    WOS_QUIZ_DIFFICULTY_CHANGE: (state, action) => {
        return{
            ...state,
            quiz: {
                ...state.quiz, 
                difficulty: action.value
            }
        }
    },
    WOS_SET_EDIT_QUIZ: (state, action) => {
        return {
            ...state,
            quiz: action.quiz,
            ui: {
                ...state.ui,
                showQuizForm: true, 
            }
        }
    },
    WOS_HIDE_QUIZ_FORM: (state) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                showQuizForm: false,
            }
        }
    },
    WOS_QUIZ_TEXT_CHANGE: (state, action: {key: WosContainer; field: string; value: string|number}) => {
        return{
            ...state,
            quiz: {
                ...state.quiz,
                [action.key] : {
                    ...state.quiz[action.key],
                    text: {
                        ...state.quiz[action.key].text,
                        [action.field] : action.value
                    }
                   
                }
            }
        }
    },
    WOS_QUIZ_CORRECT_CHANGE: (state, action: {key: WosContainer; correct: boolean}) => {
        return{
            ...state, 
            quiz: {
                ...state.quiz, 
                [action.key] : {
                    ...state.quiz[action.key],
                    correct: action.correct
                }
            }
        }
    },
    WOS_QUIZ_IMAGE_CHANGE: (state, action: {key: WosContainer; image: ImageUploadType}) => {
        return{
            ...state,
            quiz: {
                ...state.quiz,
                [action.key]:{
                    ...state.quiz[action.key],
                    image: action.image
                }
            }
        };
    },
    WOS_QUIZ_SET_SOUND: (state, action) => {
        return{
            ...state,
            quiz:{
                ...state.quiz,
                question:{
                    ...state.quiz.question,
                    sound: action.sound
                }
            },
            ui:{
                ...state.ui,
                quiz:{
                    ...state.ui.quiz,
                    question:{
                        ...state.ui.quiz.question,
                        showSoundForm: false
                    }
                }
            }
        }
    },
    WOS_QUIZ_SHOW_SOUND_FORM: (state, action) => {
        return{
            ...state,
            ui: {
                ...state.ui,
                quiz: {
                    ...state.ui.quiz,
                    question : {
                        ...state.ui.quiz.question,
                        showSoundForm: action.show
                    }
                }

            }
        }
    },
    ERROR: (state, action) => {
        if (action.reducer !== 'wosState') return state;
        return {
            ...state,
            isLoading: {
                ...state.isLoading,
                [action.key]: false
            },
        };
    }
};

// eslint-disable-next-line
export const reducer: Reducer<WarOfStrategyState, any> = (state, action) => {
    state = state || initialState();
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};