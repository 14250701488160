import React from 'react';
import QuestionBoxImage from '../../../images/games/buzzit/question_neutral.png';
import {DbOrUploadImage, DbOrUploadSound} from '../../../services/ImageHelper';
import {BuzzItQuiz} from '../../../model/Game/BuzzIt/BuzzItQuiz';
import TextView from '../../TextView';

interface Props {
    quiz: BuzzItQuiz;
    onClick?: () => void;
}

export const BuzzItQuizPreview = (props: Props) => {

    const questionImageStyle = {left: props.quiz.question.text.content ? '22.5px' : '85px'};
    const questionTextStyle = {
        left: `${props.quiz.question.image ? '125px' : 0}`,
        width: props.quiz.question.image ? '125px' : '540px',
        display: props.quiz.question.text.content ? 'initial' : 'none'
    };

    const answerStyle = {
        height: props.quiz.answer1.image ? '25px' : '80px'
    };


    return(
        <div className={`buzz-it-preview-quiz${props.onClick ? ' clickable' : ''}`} onClick={props.onClick || undefined}>
            <img className='question-box-image' src={QuestionBoxImage} alt=''/>
            <div className='question'>
                <DbOrUploadImage image={props.quiz.question.image} alt='' realSize style={questionImageStyle} />
                <TextView element='p' text={props.quiz.question.text} style={questionTextStyle} scale={0.5} />
            </div>
            <DbOrUploadSound className='question-sound' audio={props.quiz.question.sound}/>
            <div className='answers'>
                <div className={`answer answer1${props.quiz.answer1.correct ? ' correct' : ''}`}>
                    <DbOrUploadImage image={props.quiz.answer1.image} alt='' className={props.quiz.answer1.text && props.quiz.answer1.text.content ? 'image-and-text' : ''} realSize/>
                    <TextView element='div' className='text' text={props.quiz.answer1.text} style={answerStyle} scale={0.5} />
                </div>
                <div className={`answer answer2${props.quiz.answer2.correct ? ' correct' : ''}`}>
                    <DbOrUploadImage image={props.quiz.answer2.image} alt='' className={props.quiz.answer2.text && props.quiz.answer2.text.content ? 'image-and-text' : ''} realSize/>
                    <TextView element='div' className='text' text={props.quiz.answer2.text} style={answerStyle} scale={0.5} />
                </div>
                <div className={`answer answer3${props.quiz.answer3.correct ? ' correct' : ''}`}>
                    <DbOrUploadImage image={props.quiz.answer3.image} alt='' className={props.quiz.answer3.text && props.quiz.answer3.text.content ? 'image-and-text' : ''} realSize/>
                    <TextView element='div' className='text' text={props.quiz.answer3.text} style={answerStyle} scale={0.5} />
                </div>
            </div>
        </div>
    )
};