import React, {useEffect, useState} from 'react';
import {SecureLayoutComponentProps} from "../Layout";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as playListActions} from "../../store/PlayListStore";
import {Translate} from "react-localize-redux";
import {DictGetValues} from "../../services/JsDict";
import PlayListForm from "../forms/PlayListForm";
import PlayListPreview from "../ModelPreview/PlayListPreview";
import TopMenuButton from "../Menu/TopMenuButton";
import { accountHasAnyPermission, AccountType } from '../../model/AccountType';
import ItemContainer from '../ModelPreview/ItemContainer';
import { useFetchGet } from '../../services/FetchHelper';
import { PlayListType } from '../../model/PlayListType';

interface Props extends SecureLayoutComponentProps{
    playListActions: typeof playListActions;
    me: AccountType;
}

const PlayList = (props: Props) => {
    const { me, setLayoutButtons, playListActions} = props;

    const [playlists, setPlaylists] = useState<PlayListType[]>([]);
    const [orgPlaylists, setOrgPlaylists] = useState<PlayListType[]>([]);
    const [showCreate, setShowCreate] = useState(false);

    const [getPlaylists, loadingGet, errorGet] = useFetchGet<{ [key: string]: PlayListType }>(r => setPlaylists(DictGetValues(r)));
    const [getPlaylistsOnOrg, loadingGetOnOrg, errorGetOnOrg] = useFetchGet<{ [key: string]: PlayListType }>(r => setOrgPlaylists(DictGetValues(r)));
    const error = errorGet || errorGetOnOrg;

    const meHasFloorPermission = me && accountHasAnyPermission(me, ['Floor', 'Admin']);

    useEffect(() => {
        getPlaylists('api/workshop/playlist');
        if (meHasFloorPermission) {
            getPlaylistsOnOrg(`api/organization/${me?.organizationId}/playlists`);
        }
    }, [getPlaylists, me, getPlaylistsOnOrg, meHasFloorPermission]);


    useEffect(() => {
        setLayoutButtons(<>
            <TopMenuButton action={() => setShowCreate(true)} icon={'plus'} hoverMsg={'playlist_new_list'} />
        </>);
        return () => setLayoutButtons(undefined);
    }, [setLayoutButtons, playListActions])

    return(
        <>
            <div className='playlists'>
                <ItemContainer
                    heading={<h1><Translate id='playlist_owner' /></h1>}
                    items={loadingGet ? [] : playlists.filter(x => x.ownerId === me.id)}
                    loading={loadingGet}
                    itemRender={value => <PlayListPreview key={value.id} playlist={value} />}
                    error={error}
                />
                <ItemContainer
                    heading={<h2><Translate id='playlist_is_author' /></h2>}
                    items={loadingGet ? [] : playlists.filter(x => x.authorIds && x.authorIds.includes(me.id))}
                    loading={loadingGet}
                    itemRender={value => <PlayListPreview key={value.id} playlist={value} />}
                    error={error}
                />

                {meHasFloorPermission &&
                    <ItemContainer
                        heading={<h2><Translate id='playlist_is_floor_org' /></h2>}
                        loading={loadingGetOnOrg}
                        items={loadingGetOnOrg ? [] : orgPlaylists}
                        itemRender={value => <PlayListPreview key={value.id} playlist={value} />}
                        error={error}
                    />
                }
            </div>
            {showCreate && <PlayListForm me={me} onClose={() => setShowCreate(false)} playlist={undefined} onSuccess={pl => setPlaylists(x => [...x, pl])} />}
        </>
    )
}

export default connect(
    () => ({}),
    dispatch => ({
        playListActions: bindActionCreators(playListActions, dispatch)
    })
)(PlayList);


