import React from "react";
import {MemoryGame} from "../../../model/Game/Memory/MemoryGame";
import {MemoryPair} from "../../../model/Game/Memory/MemoryPair";
import {Translate} from "react-localize-redux";
import {PairPreview} from "./PairPreview";

interface Props{
    game: MemoryGame;
    onPairClick?: (quiz: MemoryPair, index: number) => void;
}

export const MemoryGamePreview = (props: Props) => {

    const game = props.game;

    return(
        <div>
            <div className={'pairs'}>
                <h2><Translate id='memory_pairs'/></h2>
                <div className={'pair-container'}>
                    {game && game.pairs && game.pairs.length > 0 &&
                        game.pairs.map((p,i) => {
                            return <PairPreview
                                key={i}
                                pair={p}
                                onClick={() => props.onPairClick && props.onPairClick(p,i)}
                            />
                        })
                    }
                </div>
            </div>
        </div>
    )
};
