import {FeaturedGame} from "../model/FeaturedGame";
import {TA} from "./configureStore";
import axios from "axios";
import {CreateAuthHeader} from "../services/AxiosHelper";
import {errorActions} from "./Error";
import {Reducer} from "redux";

export const reducerName = 'featured_game_store';

const FEATURED_GAME_GET_RELEVANT_REQUEST = 'FEATURED_GAME_GET_RELEVANT_REQUEST';
const FEATURED_GAME_GET_RELEVANT_RESPONSE = 'FEATURED_GAME_GET_RELEVANT_RESPONSE';

export interface FeaturedGameState {
    relevant: FeaturedGame[];
    isLoading: { getRelevant?: boolean};
}

const initialState: FeaturedGameState = {
    relevant: [],
    isLoading: {}
}

export const actionCreators = {
    getRelevant: (): TA => async(dispatch, getState) => {
        dispatch({type: FEATURED_GAME_GET_RELEVANT_REQUEST});
        const url = 'api/featured/game';
        axios.get<FeaturedGame[]>(url, CreateAuthHeader(getState))
            .then(r => dispatch({type: FEATURED_GAME_GET_RELEVANT_RESPONSE, data: r.data}))
            .catch(e => dispatch(errorActions.reportAxiosError(reducerName, 'getRelevant', e)));
    }
};

// eslint-disable-next-line
const reducerMethods: {[key: string]: (state: FeaturedGameState, action: any) => FeaturedGameState} = {

    FEATURED_GAME_GET_RELEVANT_RESPONSE: (state, action) => ({
        ...state,
        relevant: action.data
    }),
    ERROR: (state, action) => (action.reducer !== reducerName ? state : {
        ...state,
        isLoading: {...state.isLoading, [action.key]: false}
    }),
};

// eslint-disable-next-line
export const reducer: Reducer<FeaturedGameState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};