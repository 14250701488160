import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Me';
import ResetPasswordForm from './forms/profile/ResetPasswordForm';
import Dialog from "./Dialog";
import {AuthState} from "../store/Auth";
import {AppState} from "../store/configureStore";
import * as queryString from "querystring";
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps{
    authState: AuthState;
    meActions: typeof actionCreators;
}

class ResetPassword extends Component<Props>{
    formValues: {token?: string; email: string};
    constructor(props: Props){
        super(props);
        const params = queryString.parse(props.location.search.slice(1));
        const token = params.token && (typeof params.token === "string" ? params.token : params.token[0]);
        this.formValues = { token: token, email: ''}
    }

    render(){
        return (
            <Dialog onClose={() => {}} loading={this.props.authState.waitingForReset}>
                <ResetPasswordForm initialValues={this.formValues} />
            </Dialog>
        );
    }
}

export default connect(
    (state: AppState) => ({authState: state.auth}),
    dispatch => ({
        meActions: bindActionCreators(actionCreators, dispatch)
    })
)(ResetPassword);
