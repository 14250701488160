import React, {FormEvent, useEffect, useState} from 'react';
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {InputField, SubmitButton} from "../../forms/FormGroup";
import {ErrorType} from "../../../store/Error";
import {FormGeneralError} from "../../Error/FormGeneralError";

interface Props{
    onClose: () => void;
    onSubmit: (dict: {[key: string]: string}) => void;
    initialCategories: string[] | undefined;
    loading?: boolean;
    errors?: ErrorType;
}

const RenameCategory = (props: Props) => {
    const calculateInitial = () => {
        const initial: {[key: string]: string} = {};
        props.initialCategories && props.initialCategories.forEach(x => initial[x] = x);
        return initial;
    }
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        props.onSubmit(categories);
        setSubmitted(true);
    }
    const onClose = props.onClose;
    const [categories, setCategories] = useState<{[key: string]: string}>(calculateInitial());
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if(submitted && !props.loading && !props.errors){
            onClose();
        }
    }, [submitted, props.loading, props.errors, onClose])

    if(!props.initialCategories) return null;

    return(
        <Dialog onClose={onClose}>
            <h1><Translate id={'ditj_rename_category'}/></h1>
            <form onSubmit={onSubmit}>
                {Object.entries(categories).map(x => {
                    const orig = x[0];
                    const replace = x[1];
                    return(
                        <div key={orig}>
                            <div dir='auto'>{orig}</div>
                            <InputField
                                noLabel
                                name={orig}
                                type={'text'}
                                value={replace}
                                onChange={(e) => setCategories({...categories, [e.target.name]: e.target.value})}
                            />
                        </div>
                    )
                    })}
                    <FormGeneralError error={props.errors} />
                <SubmitButton text={'submit'} split />
            </form>
        </Dialog>
    )
}

export default RenameCategory;