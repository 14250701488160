import React, {useEffect, useState} from 'react';
import {LayoutComponentProps} from "../Layout";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators as orgActions, OrganizationState} from "../../store/OrganizationStore";
import {AccountState, actionCreators as accountActions, TempAccount} from "../../store/Account";
import {DictGetValues} from "../../services/JsDict";
import {Loading} from "../Loading";
import {Translate} from "react-localize-redux";
import {DbOrUploadImage} from "../../services/ImageHelper";
import OrganizationChooser from "../forms/Organization/OrganizationChooser";
import CreateAccountForm from "../forms/profile/CreateAccountForm";
import profile from '../../images/missing-profile.png';
import AdminEditAccount from "../forms/profile/AdminEditAccount";
import {SortByFirstName} from '../../services/SortFunctions';
import {MeState} from "../../store/Me";
import {AppState} from "../../store/configureStore";
import {accountHasAnyPermission, accountIsChildAllowed, AccountType, isAccountType} from "../../model/AccountType";
import {isOrganizationType} from "../../model/Organization";
import TopMenuButton from "../Menu/TopMenuButton";
import {allPermissions} from "../../model/Permission/PermissionType";
import ItemContainer from '../ModelPreview/ItemContainer';
import { isAdmin } from '../../services/PermissionHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props extends LayoutComponentProps{
    organization: OrganizationState;
    orgActions: typeof orgActions;
    accountActions: typeof accountActions;
    me: MeState['account'];
    account: AccountState;
}

interface State{
    editAccount?: AccountType;
}

const Users = (props: Props) => {
    const {organization, orgActions, accountActions, me, account, setLayoutButtons} = props;

    const [state, setState] = useState<State>({});
    const [showOrgChoose, setShowOrgChoose] = useState(false);

    useEffect(() => {
        if(!organization.activeOrganization && me){
            orgActions.setActiveOrganization(me.organizationId);
        }
        orgActions.getAll(true);
        accountActions.getByOrganization(organization.activeOrganization);
    }, [me, organization.activeOrganization, orgActions, accountActions]);

    useEffect(() => {
        const showOrganizationSearch = accountIsChildAllowed(me);

        setLayoutButtons(
            <>
                {showOrganizationSearch &&
                    <TopMenuButton action={() => setShowOrgChoose(true)} icon={'building'} hoverMsg='organization_admin_for' />
                }
                {accountHasAnyPermission(me, ['Admin', 'Users', 'ChildOrganizations']) &&
                    <TopMenuButton action={() => accountActions.showCreateAccount(true)} icon={'plus'} />
                }
            </>
        );
        
        return (() => setLayoutButtons(undefined));
    }, [setLayoutButtons, me, setShowOrgChoose, accountActions])

    if(!me) return null;
    const visibleOrgId = organization.activeOrganization;
    const visibleOrg = organization.organizations[visibleOrgId];
    const organizationName = (isOrganizationType(visibleOrg) && visibleOrg.name) || '...';

    const activeOrg = organization.organizations[organization.activeOrganization];
    let visiblePermissions = allPermissions;
    //only show the child org permission if org has a child org
    const showChildPermission = isOrganizationType(activeOrg) && activeOrg.childOrganizationIds && activeOrg.childOrganizationIds.length > 0;
    const showAdminPermssion = isAdmin(me);
    if (!showChildPermission) {
        visiblePermissions = visiblePermissions.filter(x => x !== 'ChildOrganizations');
    }
    if (!showAdminPermssion) {
        visiblePermissions = visiblePermissions.filter(x => x !== 'Admin');
    }

    return(
        <>
            <Loading visible={props.organization.loading.getAll}/>
            <div className='users'>
                <ItemContainer
                    className='user-container'
                    loading={props.account.isLoading.getByOrganization}
                    heading={<h1><Translate id='users_title' data={{ org: organizationName }} /></h1>}
                    items={
                        DictGetValues<AccountType | TempAccount>(props.account.accounts)
                            .filter(isAccountType)
                            .filter(x => x.organizationId === visibleOrgId)
                            .sort(SortByFirstName)
                    }
                    itemRender={value => 
                        <AccountPreview
                            key={value.id}
                            account={value}
                            showAdmin={showAdminPermssion}
                            showChild={showChildPermission}
                            onClick={() => setState({ ...state, editAccount: value })}
                        />
                    }
                />
                
                <h2><Translate id={'permission_explain_title'}/></h2>
                <div>
                    {visiblePermissions.map(x => <div key={x}><b><Translate id={`permission_${x}`}/></b>: <Translate id={`permission_${x}_explain`}/></div>)}
                </div>
            </div>
            <OrganizationChooser onChoose={accountActions.setVisibleOrganization} onClose={setShowOrgChoose} visible={showOrgChoose} /> 
            { account.ui.showCreateAccount &&
                <CreateAccountForm />
            }
            { state.editAccount &&
                <AdminEditAccount
                    account={state.editAccount}
                    onClose={() => setState({...state, editAccount: undefined})}
                />
            }
        </>
    )
}

export default connect(
    (state: AppState) => ({ organization: state.organization, me: state.me.account, account: state.account }),
    dispatch => {
        return ({
            orgActions: bindActionCreators(orgActions, dispatch),
            accountActions: bindActionCreators(accountActions, dispatch)
        });
    }
)(Users);


interface PreviewProps{
    account: AccountType;
    showAdmin?: boolean;
    showChild?: boolean;
    onClick: () => void;
}

const AccountPreview = (props: PreviewProps) => {
    const account = props.account;
    return (
        <div className='user-card' onClick={props.onClick ? props.onClick : undefined}>
            <div className='profile-email'>{account.email}</div>
            <DbOrUploadImage image={account.profileImage} tempImage={profile} className='profile-img' />
            <div className='name'>
                <div className='profile-name'>{account.firstname} {account.lastname}</div>
            </div>
            <div className='clear-fix' />
            <div className='permissions-title'><Translate id='permissions' /></div>
            <div className='permissions'>
                {
                    account.permissions && allPermissions
                        .filter(x => props.showAdmin ? x : x !== 'Admin')
                        .filter(x => props.showChild || props.showAdmin ? x : x !== 'ChildOrganizations')
                        .map(x => {
                            return (
                                <div key={x} className={account.permissions?.includes(x) ? 'enabled' : 'disabled'}>
                                    <Translate id={`permission_${x}`} /> {account.permissions?.includes(x) ? <FontAwesomeIcon icon={'check'} /> : null}
                                </div>
                            )
                        })
                }
            </div>
        </div>
    );
};