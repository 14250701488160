import React, { useContext, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { history } from '../..';
import { GlobalActions } from '../../App';
import { Bundle } from '../../model/Bundle';
import { BaseGame } from '../../model/Game/BaseGame';
import { useFetchDelete, useFetchGet } from '../../services/FetchHelper';
import { SortByCustom } from '../../services/SortFunctions';
import { GeneralError } from '../Error/GeneralError';
import ConfirmationForm from '../forms/games/ConfirmationForm';
import { SecureLayoutComponentProps } from '../Layout';
import { Loading } from '../Loading';
import TopMenuButton from '../Menu/TopMenuButton';
import Author from '../ModelPreview/Author';
import BundleIcon from '../ModelPreview/BundleIcon';
import { CustomGameMenu } from '../ModelPreview/CustomGameMenu';
import Game from '../ModelPreview/Game';
import ItemContainer from '../ModelPreview/ItemContainer';
import PlayListPreview from '../ModelPreview/PlayListPreview';
import BundleEdit from './BundleEdit';

interface Props extends SecureLayoutComponentProps<{ id: string }>{

}

const BundleDetails = (props: Props) => {
    const {match, setLayoutButtons} = props;
    const [bundle, setBundle] = useState<Bundle>();
    const [deleteConfirm, setDeleteConfirm] = useState<{ itemId: string; type: string }>();
    const [getBundle, loadingGet, error] = useFetchGet<Bundle>(setBundle);
    const [deleteItem, loadingDeleteItem, errorDeleteItem ] = useFetchDelete<Bundle>(setBundle);

    const [showEdit, setShowEdit] = useState(false);

    const globalActions = useContext(GlobalActions);
    
    useEffect(() => {
        setLayoutButtons(
            <>
                <TopMenuButton icon='edit' action={() => setShowEdit(true)} hoverMsg={'bundle_edit_hover_msg'} />
                {bundle && <TopMenuButton icon='folder-plus' action={() => globalActions.wallAddItemForm(bundle)} hoverMsg={'wall_add_to'}/>}
            </>
        );

        return () => {setLayoutButtons(undefined)};
    }, [setLayoutButtons, bundle, globalActions]);

    useEffect(() => {
        getBundle(`api/bundle/${match.params.id}`);
    },[match.params.id, getBundle]);

    if(error) return <GeneralError fetchError={error} />;
    if (!bundle) return <div className='bundle-details'><Loading visible={true} /></div>;

    const items = [
        ...bundle.sortedGames.map(x => ({ ...x, type: 'game' })), 
        ...bundle.sortedPlaylists.map(x => ({ ...x, type: 'playlist' }))
    ].sort((a,b) => SortByCustom(a,b, "sortIndex", true));

    const onConfirmDelete = (item: { itemId: string; type: string }) => {
        setDeleteConfirm(undefined);
        deleteItem(`api/bundle/${bundle.id}/${item.type}/${item.itemId}`);
    }

    return(
        <div className='bundle-details'>
            <div className='bundle-info'>
                <h1>{bundle.text.content}</h1>
                <div className='icon-authors'>
                    <BundleIcon bundle={bundle} />
                    <div className='authors'>
                        <h2><Translate id='authors' /></h2>
                        <Author accountId={bundle.ownerId} className='owner-container' />
                        {bundle.authorIds && bundle.authorIds.map((x, i) =>
                            <Author key={i} accountId={x} className='author-img-container' hideName />
                        )}
                    </div>
                </div>
                <ItemContainer
                    className={'bundle-items-container'}
                    heading={<h2><Translate id='bundle_items'/></h2>}
                    loading={loadingDeleteItem||loadingGet}
                    items={items}
                    error={errorDeleteItem || error}
                    itemRender={x => {
                        if (x.type === 'playlist') {
                            return (
                                <div className='bundle-wrapper' key={x.itemId}>
                                    <PlayListPreview 
                                        className='bundle-item' 
                                        playlistId={x.itemId} 
                                        onlyImage 
                                        customMenu={
                                            <CustomGameMenu
                                                items={[
                                                    { key: 'edit', icon: 'edit', link: `/workshop/playlist/edit/${x.itemId}`, text: 'see_content' },
                                                    { key: 'remove', icon: 'trash', action: () => setDeleteConfirm(x), text: 'remove' }
                                                ]}
                                            />
                                        } 
                                    />
                                </div>
                            )
                        }
                        if (x.type === 'game') {
                            x.item = x.item as BaseGame;
                            return (
                                <div className='bundle-wrapper' key={x.itemId} >
                                    <Game 
                                        className='bundle-item' 
                                        game={x.item}
                                        onlyImage 
                                        customMenu={
                                            <CustomGameMenu 
                                                items={[
                                                    {key: 'edit', icon: 'edit', link: `/workshop/${x.item.gameType}/edit/${x.itemId}`, text: 'see_content'},
                                                    {key: 'remove', icon: 'trash', action: () => setDeleteConfirm(x), text: 'remove'}
                                                ]}
                                            />
                                        } 
                                    />
                                </div>
                            )
                        }
                        return null;
                    }}
                />
            </div>
            {deleteConfirm && 
                <ConfirmationForm 
                    text="bundle_remove_from_confirm" 
                    confirmText="yes" 
                    cancelText="no" 
                    onCancel={() => setDeleteConfirm(undefined)} 
                    onConfirm={() => onConfirmDelete({...deleteConfirm})} 
                />
            }
            {showEdit && 
                <BundleEdit 
                    onClose={() => setShowEdit(false)}
                    onSuccess={setBundle}
                    onDeleted={() => history.push("/bundles")}
                    bundle={bundle}
                />
            }
        </div>
    )
}

export default BundleDetails;