import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as BikeRaceStore from "../../../store/games/BikeRaceStore";
import * as GamesStore from "../../../store/games/Games";
import Dialog from "../../Dialog";
import {Translate} from "react-localize-redux";
import {SelectList, Slider, SubmitButton} from "../../forms/FormGroup";
import {AppState} from "../../../store/configureStore";

interface Props {
    gamesActions: typeof GamesStore.actionCreators;
    bikeRaceState: BikeRaceStore.BikeRaceState;
    bikeRaceActions: typeof BikeRaceStore.actionCreators;
}

const BikeRaceSettings = (props: Props) => {
    if (!props.bikeRaceState.ui.showSettings) {
        return null;
    }

    const game = props.bikeRaceState.game;
    if (!game) {
        return null;
    }

    const settings = {
        skin: game.skin || 'Default',
        answerTime: game.answerTime || 15
    };

    return(
      <Dialog
          className='settings'
          onClose={() => props.gamesActions.showSettings(game.gameType, false)}
          loading={props.bikeRaceState.isLoading.updateSettings}>
          <h2><Translate id='settings'/></h2>
          <form onSubmit={e => e.preventDefault()}>
              <label><Translate  id='skin'/></label>
              <SelectList
                  name='skin'
                  defaultValue={settings.skin}
                  options={[{name: "Default", value: "Default"}]}
                  onChange={props.bikeRaceActions.setSetting}
                  noLabel/>
              <label><Translate id='bike_race_answer_time'/></label>
              <Slider name='answerTime' min={5} max={60} value={settings.answerTime} onChange={props.bikeRaceActions.setSetting} hoverMsg='bike_race_answer_time_hint'/>

              <h3><Translate id='preview_skin'/></h3>
              <img src={`/images/skins/${game.gameType}/${settings.skin}.png`} alt='' className='skin-preview' />
              <div className='clear-fix' />
              <SubmitButton
                  text='submit'
                  onClick={() => props.gamesActions.updateSettings(game.gameType, game.id, settings)}
                  split
              />
          </form>
      </Dialog>
    );
};

export default connect(
    (state: AppState) => ({bikeRaceState: state.bikeRaceState}),
    dispatch => ({
        bikeRaceActions: bindActionCreators(BikeRaceStore.actionCreators, dispatch),
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch)
    })
)(BikeRaceSettings);