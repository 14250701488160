import React, {FormEvent, useEffect, useState} from 'react';
import {FeaturedGame} from "../../model/FeaturedGame";
import {AuthState} from "../../store/Auth";
import {connect} from "react-redux";
import {AppState, ChangeEvent} from "../../store/configureStore";
import * as GamesStore from '../../store/games/Games';
import * as PlaylistStore from '../../store/PlayListStore';
import * as AccountStore from '../../store/Account';
import Dialog from "../Dialog";
import {
    ColorAndFont, ColorPicker,
    DatePickerWrapped, FormButton,
    ImageFormButton,
    InputField,
    Slider,
    SubmitButton,
    TextAlignmentFormGroup, TextArea
} from "../forms/FormGroup";
import moment from "moment";
import {fetchDelete, fetchPost, fetchPut} from "../../services/FetchHelper";
import FeaturedGameView from "../Discover/FeaturedGameView";
import {ImageUploadType} from "../../model/ImageUploadType";
import {bindActionCreators} from "redux";
import {BaseGame} from "../../model/Game/BaseGame";
import {DbImage} from "../../model/DbImage";
import {isDbImage} from "../../services/ImageHelper";
import {TextAlignment, TextType} from "../../model/Game/TextType";
import {defaultEmptyText} from "../TextView";
import {Translate} from "react-localize-redux";
import {AccountType} from "../../model/AccountType";
import {PlayListType} from "../../model/PlayListType";

interface State{
    id?: string;
    startEpoch: number;
    endEpoch: number;
    image?: ImageUploadType | DbImage;
    languageCodes: string[];
    gameId: string;
    playlistId: string;
    accountId: string;
    labelText: TextType;
    labelColorHex: string;
    text: TextType;
    descriptionText: TextType;
}

interface Props{
    auth: AuthState;
    onClose: (game?: FeaturedGame) => void;
    onDelete?: () => void;
    gamesActions: typeof GamesStore.actionCreators;
    playlistActions: typeof PlaylistStore.actionCreators;
    playlistState: PlaylistStore.PlayListState;
    accountActions: typeof AccountStore.actionCreators;
    accountState: AccountStore.AccountState;
    gamesState: GamesStore.GamesState;
    edit?: FeaturedGame;
    countryList: string[];
}

const CreateFeaturedGame = (props: Props) => {
    const [state, setState] = useState<State>((props.edit && {
            ...props.edit,
            startEpoch: moment(props.edit.start).unix(),
            endEpoch: moment(props.edit.end).unix(),
            text: props.edit.text || defaultEmptyText,
            descriptionText: props.edit.descriptionText || defaultEmptyText,
            labelText: props.edit.labelText || {...defaultEmptyText, fontSize: 22, fontFamily: 'Shadows Into Light'},
            labelColorHex: props.edit.labelColorHex || "#211d70",
            gameId: props.edit.gameId || '',
            playlistId: props.edit.playlistId  || '',
            accountId: props.edit.accountId || ''
        })
        || {
            startEpoch: moment().startOf('day').unix(),
            endEpoch: moment().startOf('day').add('7', 'days').unix(),
            gameId: '',
            playlistId: '',
            accountId: '',
            languageCodes: [],
            text: defaultEmptyText,
            descriptionText: defaultEmptyText,
            labelText: {...defaultEmptyText, fontSize: 22, fontFamily: 'Shadows Into Light'},
            labelColorHex: "#211d70"
        }
    );
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(state.gameId) props.gamesActions.getGame(state.gameId, false);
    }, [state.gameId, props.gamesActions]);
    useEffect(() => {
        if(state.playlistId) props.playlistActions.getPlayList(state.playlistId);
    }, [state.playlistId, props.playlistActions]);
    useEffect(() => {
        if(state.accountId) props.accountActions.get(state.accountId, false);
    }, [state.accountId, props.accountActions]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        if(state.id){
            fetchPut<FeaturedGame>(
                `api/featured/game/${state.id}`,
                {...state, image: isDbImage(state.image) ? null : state.image, reuseImage: isDbImage(state.image)}
            ).then(data => props.onClose(data));
        }
        else{
            fetchPost<FeaturedGame>(
                'api/featured/game',
                state
            ).then(data => props.onClose(data));
        }
    }

    const onDelete = () => {
        setIsLoading(true);
        fetchDelete(`api/featured/game/${props.edit?.id}`)
            .then(_ => props.onDelete && props.onDelete())
    }

    const game = props.gamesState.games[state.gameId];
    const playlist = props.playlistState.playListsMap[state.playlistId];
    const account = props.accountState.accounts[state.accountId];

    const changeText = (e: ChangeEvent<string|number>) => {
        setState({...state, text: {...state.text, [e.target.name]: e.target.value}});
    }
    const changeDescription = (e: ChangeEvent<string|number>) => {
        setState({...state, descriptionText: {...state.descriptionText, [e.target.name]: e.target.value}});
    }
    const changeLabelText =  (e: ChangeEvent<string|number>) => {
        setState({...state, labelText: {...state.labelText, [e.target.name]: e.target.value}});
    }

    return(
        <Dialog
            className='admin-create-featured-dialog'
            onClose={() => props.onClose()}
            icon={props.onDelete ? 'trash' : undefined}
            onIconClick={props.onDelete ? onDelete : undefined}
            loading={isLoading}
        >
            <h2>Create featured item</h2>
            <form onSubmit={onSubmit}>
                <div className={'well'}>
                    <h2><Translate id={'showLabel'} /></h2>
                    <InputField type={'text'} name={'content'} value={state.labelText.content} onChange={changeLabelText} noLabel />
                    <ColorAndFont
                        colorValue={state.labelText.fontColorHex}
                        onColorChange={changeLabelText}
                        fontFamilyValue={state.labelText.fontFamily}
                        onFontFamilyChange={changeLabelText}
                    />
                    <Slider
                        min={22}
                        max={22}
                        name={'fontSize'}
                        value={state.labelText.fontSize}
                        onChange={changeLabelText}
                    />
                    <TextAlignmentFormGroup name={'textAlignment'} value={state.labelText.textAlignment || TextAlignment.Left} onChange={changeLabelText} />
                    <ColorPicker
                        colorValue={state.labelColorHex}
                        onColorChange={e => setState({...state, labelColorHex: e.target.value})}
                    />
                </div>

                <div className={'well'}>
                    <h2><Translate id='title' /></h2>
                    <InputField noLabel name={'content'} type={'text'} value={state.text.content} onChange={changeText} />
                    <ColorAndFont
                        colorValue={state.text.fontColorHex}
                        onColorChange={changeText}
                        fontFamilyValue={state.text.fontFamily}
                        onFontFamilyChange={changeText}
                    />
                    <Slider
                        min={4}
                        max={30}
                        name={'fontSize'}
                        value={state.text.fontSize}
                        onChange={changeText}
                    />
                    <TextAlignmentFormGroup name={'textAlignment'} value={state.text.textAlignment || TextAlignment.Left} onChange={changeText} />
                </div>


                <div className={'well'}>
                    <h2><Translate id={'description'} /></h2>
                    <TextArea name={'content'} value={state.descriptionText.content} onChange={changeDescription} noLabel />
                    <ColorAndFont
                        colorValue={state.descriptionText.fontColorHex}
                        onColorChange={changeDescription}
                        fontFamilyValue={state.descriptionText.fontFamily}
                        onFontFamilyChange={changeDescription}
                    />
                    <Slider
                        min={4}
                        max={30}
                        name={'fontSize'}
                        value={state.descriptionText.fontSize}
                        onChange={changeDescription}
                    />
                    <TextAlignmentFormGroup name={'textAlignment'} value={state.descriptionText.textAlignment || TextAlignment.Left} onChange={changeDescription} />
                </div>



                <div>
                    <DatePickerWrapped
                        name={'start'}
                        value={moment.unix(state.startEpoch)}
                        onChange={(e) => setState({...state, startEpoch: e.target.value.unix()})}
                    />
                    <DatePickerWrapped
                        name={'end'}
                        value={moment.unix(state.endEpoch)}
                        onChange={(e) => setState({...state, endEpoch: e.target.value.unix()})}
                    />
                </div>
                <div>
                    <ImageFormButton
                        name={'image'}
                        imageSubmit={e => setState({...state, image: e})}
                        previewWidth={960}
                        previewHeight={200}
                        forceFixedAspect
                        noRemove
                    />
                    <InputField name={'game_content'} type={'text'} value={state.gameId} onChange={e => setState({...state, gameId: e.target.value})} />
                    <InputField name={'playlist_content'} type={'text'} value={state.playlistId} onChange={e => setState({...state, playlistId: e.target.value})} />
                    <InputField name={'author'} type={'text'} value={state.accountId} onChange={e => setState({...state, accountId: e.target.value})} />
                    <InputField name={'lang_choose'} type={'text'} value={state.languageCodes.join(",")} onChange={e => setState({...state, languageCodes: e.target.value.split(",")})} />
                    <FormButton name={'all'} onClick={() => setState({...state, languageCodes: props.countryList})} />

                </div>
                <SubmitButton text={'submit'} split />
            </form>
            <div className='preview'>
                <FeaturedGameView fg={{
                    ...state,
                    id: "preview",
                    start: moment.unix(state.startEpoch).format(),
                    end: moment.unix(state.endEpoch).format(),
                    imageId: "preview-image",
                    game: game as BaseGame,
                    account: account as AccountType,
                    playlist: playlist as PlayListType
                }}
                />
            </div>
        </Dialog>
    )

};

export default connect(
    (state: AppState) => ({
        auth: state.auth,
        gamesState: state.gamesState,
        playlistState: state.playlist,
        accountState: state.account
    }),
    (dispatch) => ({
        gamesActions: bindActionCreators(GamesStore.actionCreators, dispatch),
        playlistActions: bindActionCreators(PlaylistStore.actionCreators, dispatch),
        accountActions: bindActionCreators(AccountStore.actionCreators, dispatch)
    })
)(CreateFeaturedGame);