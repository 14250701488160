import React, {useEffect, useState} from 'react';
import {FeaturedGame} from "../../model/FeaturedGame";
import {AuthState} from "../../store/Auth";
import {connect} from "react-redux";
import {AppState} from "../../store/configureStore";
import {DictGetValues} from "../../services/JsDict";
import CreateFeaturedGame from "./CreateFeaturedGame";
import {fetchDelete, fetchGet} from "../../services/FetchHelper";
import moment from "moment";
import {DbOrUploadImage} from "../../services/ImageHelper";
import {Button} from "../forms/FormGroup";
import {Loading} from "../Loading";
import ConfirmationForm from "../forms/games/ConfirmationForm";

interface Props{
    auth: AuthState;
}

const FeaturedGameAdmin = (props: Props) => {
    const [all, setAll] = useState<{[key: string]: FeaturedGame}>({});
    const [showCreate, setShowCreate] = useState(false);
    const [edit, setEdit] = useState<FeaturedGame>();
    const [isLoading, setIsLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState<string>();
    const [countryList, setCountryList] = useState<string[]>([]);


    useEffect(() => {
        fetchGet<string[]>('api/organization/unique/countries').then(setCountryList);
        fetchGet<{[key: string]: FeaturedGame}>('api/featured/game/all').then(setAll);
        //eslint-disable-next-line
    }, []);

    const onCloseForm = (fg?: FeaturedGame) => {
        if(fg){
            setAll({...all, [fg.id]: fg});
            setShowCreate(false);
            setEdit(undefined);
        }
        else{
            setShowCreate(false);
            setEdit(undefined);
        }
    }

    const onDelete = (id: string) => {
        setIsLoading(true);
        setConfirmDelete(undefined);
        fetchDelete(`api/featured/game/${id}`).then(_ => deleteComplete(id))
    }

    const deleteComplete = (id: string) => {
        const {[id]: deleted, ...rest} = all;
        setAll(rest);
        setEdit(undefined)
        setIsLoading(false);
    }

    return(
        <div>
            <h2>Featured games</h2>
            <Button icon='plus' name="new" onClick={() => setShowCreate(true)} />
            <div className='admin-table'>
                <Loading visible={isLoading} />
                <table>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>status</th>
                            <th>Start</th>
                            <th>End</th>
                            <th>Label</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Countries</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {DictGetValues(all).map(x => {
                        const start = moment(x.start);
                        const end = moment(x.end);
                        const ended = moment().isAfter(end);
                        const future = moment().isBefore(start);
                        const color = ended ? "#C22" : future ? "#06D" : "#5A5";
                        return(
                            <tr key={x.id}>
                                <td><DbOrUploadImage image={x.image} /></td>
                                <td style={{color: color}}>{ended ? 'ended' : future ? 'future' : "active"}</td>
                                <td>{start.format('DD/MM/yyyy')}</td>
                                <td>{end.format('DD/MM/yyyy')}</td>
                                <td>{x.labelText?.content}</td>
                                <td className='title'>{x.text?.content}</td>
                                <td className='description'>{x.descriptionText?.content}</td>
                                <td>{x.languageCodes.join(", ")}</td>
                                <td><Button name={'show'} onClick={() => setEdit(x)} /> <Button name={'delete'} onClick={() => setConfirmDelete(x.id)} /></td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
            </div>
            {confirmDelete &&
            <ConfirmationForm
                text={'delete'}
                confirmText={'yes'}
                cancelText={'no'}
                onConfirm={() => onDelete(confirmDelete)}
                onCancel={_ => setConfirmDelete(undefined)}
            />
            }
            {showCreate && <CreateFeaturedGame onClose={onCloseForm} countryList={countryList} />}
            {edit &&
            <CreateFeaturedGame
                edit={edit}
                onClose={onCloseForm}
                onDelete={() => {deleteComplete(edit.id)}}
                countryList={countryList}
            />
            }
        </div>
    );
};

export default connect(
    (state: AppState) => ({auth: state.auth}),
)(FeaturedGameAdmin);