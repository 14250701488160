import React, {Component} from 'react';
import ReactCrop, {Crop} from 'react-image-crop';
import Dropzone from 'react-dropzone';
import {Translate} from 'react-localize-redux';
import {getOrientation, resetOrientation} from "../../../services/ImageRotationService";

import 'react-image-crop/dist/ReactCrop.css';
import {Loading} from "../../Loading";
import {FormButton, CheckBox, SubmitButton} from "../FormGroup";
import {ImageUploadType} from '../../../model/ImageUploadType';

interface Props {
    aspectRatio: number;
    minRatio?: number;
    maxRatio?: number;
    onSubmit: (img: ImageUploadType) => void;
    previewWidth: number;
    previewHeight: number;
    forceFixedAspect?: boolean;
}
interface State {
    crop: Crop | undefined;
    fileEnding: string;
    cropsrc: string;
    fixedAspect: boolean;
    isLoading: boolean;
    image: HTMLImageElement | undefined;
    scale: number;
    forceFixedAspect?: boolean;
}


class ImageForm extends Component<Props,State>{
    aspect: number;

    constructor(props: Props){
        super(props);
        this.aspect = props.aspectRatio || 16/9;
        this.state = {
            image: undefined,
            crop: undefined,
            cropsrc: '',
            fileEnding: 'unknown',
            fixedAspect: true,
            isLoading: false,
            scale: 1,
            forceFixedAspect: props.forceFixedAspect
        };
    }

    submit = () => {
        if (this.state.crop && this.state.cropsrc && this.state.image){
            const crop = this.state.crop;

            if(crop.x === undefined || crop.y === undefined
                || !crop.width || !crop.height) return;

            const image = new Image();
            image.src = this.state.cropsrc;
            const data = {
                base64Image: this.state.cropsrc,
                x: Math.round(crop.x * this.state.scale) || 0,
                y: Math.round(crop.y * this.state.scale) || 0,
                width: Math.min(
                    Math.round(crop.width * this.state.scale) || 0,
                    this.state.image.naturalWidth
                ),
                height: Math.min(
                    Math.round(crop.height * this.state.scale) || 0,
                    this.state.image.naturalHeight
                ),
                fileEnding: this.state.fileEnding,
                aspectRatio: this.aspect,
                previewBlob: this.getCroppedPreview(image, crop),
                salt: Math.random()
            };

            const maxWidthFromX = this.state.image.naturalWidth - data.x;
            const maxHeightFromY = this.state.image.naturalHeight - data.y;
            data.width = Math.min(data.width, maxWidthFromX);
            data.height = Math.min(data.height, maxHeightFromY);

            this.props.onSubmit(data);
        }
    };

    cropChange = (crop: Crop) => {
        this.setState({ crop });
    };
    
    getCroppedPreview = (image: HTMLImageElement, crop: Crop | undefined) => {
        if (!crop || crop.width === undefined || crop.height === undefined) return '';
        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        if (ctx === null) return '';

        const currentAspect = crop.width / crop.height;
        let desiredWidth, desiredHeight;
        const isFixedAspect = this.props.previewHeight && this.props.previewWidth;
        const originalAspect = crop.width / crop.height;
        const isSmallerThanMinAspect = this.props.minRatio && this.props.minRatio > originalAspect;
        const isBiggerThanMaxAspect = this.props.maxRatio  && this.props.maxRatio < originalAspect;

        //find the correct aspect for the preview image
        const aspect = isFixedAspect ?
            this.props.aspectRatio :
            isSmallerThanMinAspect ?
                (this.props.minRatio || this.props.aspectRatio) :
                isBiggerThanMaxAspect ?
                    (this.props.maxRatio || this.props.aspectRatio) :
                        crop.width / crop.height;

        canvas.width = this.props.previewWidth;
        canvas.height = this.props.previewHeight;
        //adjust previewWidth if either width/height is auto
        if (this.props.previewHeight === 0)
        {
            canvas.height = Math.round(this.props.previewWidth / aspect);
        }
        else if(this.props.previewWidth === 0)
        {
            canvas.width = Math.round(this.props.previewHeight * aspect);
        }

        //set desired height width factor. defaults to const (1)
        if(isFixedAspect || isSmallerThanMinAspect || isBiggerThanMaxAspect){
            if (currentAspect < aspect)
            {
                desiredHeight = 1;
                const fullPixelHeight = crop.height;
                const fullPixelWidth = fullPixelHeight * aspect;
                desiredWidth = crop.width / fullPixelWidth;
            }
            else
            {
                desiredWidth = 1;
                const fullPixelWidth = crop.width;
                const fullPixelHeight = fullPixelWidth / aspect;
                desiredHeight = crop.height / fullPixelHeight;
            }
        }

        const x = (1 - (desiredWidth || 1)) / 2 * canvas.width;
        const y = (1 - (desiredHeight || 1)) / 2 * canvas.height;


        ctx.drawImage(
            image,
            Math.floor((crop.x || 0) * this.state.scale),
            Math.floor((crop.y || 0) * this.state.scale),
            Math.floor(crop.width * this.state.scale),
            Math.floor(crop.height * this.state.scale),
            Math.floor(x),
            Math.floor(y),
            Math.floor((desiredWidth || 1) * canvas.width),
            Math.floor((desiredHeight || 1) * canvas.height)
        );

        return canvas.toDataURL('image/png');
        
        //AWAIT BROWSER SUPPORT
        /*return new Promise((resolve, reject) => {
            canvas.toBlob(file => {
                file.name = fileName;
                resolve(file);
            }, 'image/jpeg');
        });*/
    };

    calculateMaxSize = (img: HTMLImageElement|undefined) => {
        if(!img) return {x: 0, y: 0, width: 1, height: 1};

        if (this.state.fixedAspect) {
            //calculate new cut
            let x = 0, y = 0;
            let height = img.height;
            let width = img.height * this.aspect;

            while (width - x >= img.width) {
                height = height * 0.99;
                width = height * this.aspect;
            }

            //center the crop
            x = Math.floor((img.width - width) / 2);
            y = Math.floor((img.height - height) / 2);
            return {x, y, width, height}
        }

        return {
            x: 0,
            y: 0,
            height: img.height,
            width: img.width,
        };
    }

    setMaxCropSize = () => {
        this.setState({crop: this.calculateMaxSize(this.state.image)})
    };

    onImageLoaded = (image: HTMLImageElement) => {
        const crop: Crop = {
            ...this.calculateMaxSize(image),
            aspect: this.state.fixedAspect ? this.aspect : undefined
        };

        const scale = image.naturalWidth / image.width;
        this.setState({
            image: image,
            crop: crop,
            isLoading: false,
            scale: scale
        });
        return false;//must return false when changing state in crop..
    };

    setFixedAspect = () => {

        if(this.props.forceFixedAspect) return;

        let height = this.state.crop && this.state.crop.height;
        let width = this.state.crop && this.state.crop.width;
        if(!this.state.fixedAspect){
            //calculate new cut
            if(this.state.image && this.state.crop && this.state.crop.width && this.state.crop.height){
                height = this.state.crop.height;
                width = height * this.aspect;
                while(width + (this.state.crop.x || 0) > this.state.image.width){
                    height = height * 0.99;
                    width = height * this.aspect;
                }
            }
        }

        const crop: Crop = {
            x: (this.state.crop && this.state.crop.x) || 0,
            y: (this.state.crop && this.state.crop.y) || 0,
            height: height,
            width: width,
            aspect: !this.state.fixedAspect ? this.aspect : undefined,
        };

        this.setState({
            fixedAspect: !this.state.fixedAspect,
            crop: crop
        });
    };

    fileDropped = (acceptedFiles: File[]) => {
        const reader = new FileReader();
        const file = acceptedFiles[0];
        if (file){
            reader.onload = (upload) => {
                const target = (upload.target as FileReader);
                getOrientation(file, (degree) => {
                    resetOrientation(target.result, degree, (blob) =>{
                        this.setState({ "cropsrc" : blob.toString(), fileEnding: file.name.split(".").pop() || 'unknown'});
                    })
                });
            };
            reader.readAsDataURL(file);
            this.setState({
                isLoading: true
            });
        }
    };


    render(){
        const disable = !this.state.crop || !this.state.cropsrc;
        return (
            <div>
                <Loading visible={this.state.isLoading}/>
                <h2><Translate id='image'/></h2>
                <div className='drop-container'>
                    <div>
                        <Dropzone onDrop={this.fileDropped} accept='image/*'>
                            {({ getRootProps, getInputProps, isDragActive }) => {
                                return (
                                    <div {...getRootProps()} className={`dropzone${isDragActive ? ' active' : ''} `}>
                                        <input {...getInputProps()} />
                                        <p><Translate id='dropzone_upload_text' /></p>
                                    </div>
                                )
                            }}
                        </Dropzone>
                    </div>
                </div>
                {this.state.cropsrc && !this.state.forceFixedAspect &&
                    <div>
                        <CheckBox name={'fixedAspect'} label={'image_fixedAspect'} active={this.state.fixedAspect} onChange={this.setFixedAspect}/>
                        <FormButton onClick={() => this.setMaxCropSize()} name={'image_setmax'} />
                    </div>
                }
                <div className='crop-container'>
                    {this.state.cropsrc &&
                        <ReactCrop src={this.state.cropsrc} onChange={this.cropChange}  crop={this.state.crop}  onImageLoaded={this.onImageLoaded} />
                    }
                </div>
                <SubmitButton split text={'submit'} onClick={this.submit} disabled={disable} />
            </div>

        );
    }
}

export default ImageForm;