import * as React from 'react';
import AccountEditForm from "./forms/profile/AccountEditForm";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators, MeState} from "../store/Me";
import {Translate} from "react-localize-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Loading} from "./Loading";
import {AppState} from '../store/configureStore';
import EULA from "./_MyWall/EULA";
import {FormButton} from "./forms/FormGroup";

interface Props {
    me: MeState;
    meActions: typeof actionCreators;
}

const FirstVisit = (props: Props) => {
    const currentEulaAccepted = props.me.account && props.me.eulaVersion === props.me.account.wallAcceptedEulaVersion;

    return (
        <>
            <div className='first-visit'>
                <div>
                    <h1><Translate id='first_visit_title'/></h1>
                    <div>
                        {
                            props.me.account &&
                            props.me.eulaVersion &&
                            props.me.eulaVersion !== props.me.account.wallAcceptedEulaVersion ?
                                <Translate id='first_visit_outdated_eula' /> :
                                <Translate id='first_visit_message'/>
                        }
                    </div>
                    <div className='eula'>
                        <EULA />
                        <div className={`btn${currentEulaAccepted ? ' accepted' : ''}`}
                             onClick={props.meActions.acceptEula}>
                            <FontAwesomeIcon icon={currentEulaAccepted ? 'check-double' : 'check'}/>
                            <Translate id={currentEulaAccepted ? 'eula_accepted' : 'eula_accept'}/>
                        </div>
                        <Loading visible={props.me.isLoading.acceptEula}/>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='first-visit-options'>
                <FormButton name='first_visit_create_profile'
                            onClick={() => props.meActions.setShowEditForm(true)}
                />
                <FormButton link='/' name={'first_visit_done'} />
                <div className='clear-fix' />
            </div>
            <AccountEditForm/>
        </>
    );

};

export default connect(
    (state: AppState) => ({me: state.me, errors: state.errors.me.update}),
    dispatch => ({meActions: bindActionCreators(actionCreators, dispatch)})
)(FirstVisit);