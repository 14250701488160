import React from "react";
import {BikeRaceQuizPreview} from "./BikeRaceQuizPreview";
import {BikeRaceGame} from "../../../model/Game/BikeRace/BikeRaceGame";
import {BikeRaceQuiz} from "../../../model/Game/BikeRace/BikeRaceQuiz";

interface Props{
    game: BikeRaceGame;
    onQuizClick?: (quiz: BikeRaceQuiz, index: number) => void;
}

export const BikeRaceGamePreview = (props: Props) => {

    const game = props.game;

    return(
        <div>
            <div className='bike-race-quizzes'>
                { game && game.quizzes && game.quizzes.map((q, i) =>
                    <BikeRaceQuizPreview
                        key={i}
                        quiz={q}
                        scaleFactor={0.5}
                        onClick={() => props.onQuizClick && props.onQuizClick(q, i)}
                    />
                )}
                <div className={'clear-fix'} />
            </div>
        </div>
    )
};