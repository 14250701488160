import { DbModel } from "./DbModel";
import {MinimalOrganization} from "./Response/MinimalOrganization";

export interface Organization extends DbModel {
    name: string;
    systemName: string;
    country: string;
    type: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    accountIds: string[];
    childOrganizationIds: string[];
}


export interface TempOrganization{
    id: string;
    loading: boolean;
}


export function isOrganizationType(arg: string | Organization | TempOrganization | undefined): arg is Organization {
    return !!arg &&
        typeof arg !== "string" &&
        !!arg.id &&
        "systemName" in arg &&
        "name" in arg;
}

export function isMinOrgType(arg: MinimalOrganization | TempOrganization | undefined): arg is MinimalOrganization {
    return !!arg &&
        !("loading" in arg) &&
        "name" in arg;
}