import React, { CSSProperties, ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { AccountType } from '../../model/AccountType';
import { Bundle } from '../../model/Bundle';
import { convertHexToRgba } from "../../services/ColorHelper";
import { getTextAlign } from "../../services/TextHelper";
import { AppState } from '../../store/configureStore';
import { CustomGameMenu } from './CustomGameMenu';
import Game from './Game';
import PlayListPreview from './PlayListPreview';
import * as GlobalStore from '../../store/GlobalComponentStore';
import { bindActionCreators } from 'redux';

interface Props {
    me: AccountType | null;
    bundle: Bundle;
    
    globalActions: typeof GlobalStore.actionCreators;
    onClick?: () => void;
    className?: string;
    style?: CSSProperties;
    width?: number;
    customMenu?: ReactNode;
    noMenu?: boolean;
}

const BundleIcon = (props: Props) => {
    const {bundle, me, globalActions, onClick, className, style, width, customMenu, noMenu} = props;
    const scale = width ? width / 220 : 1;
    const [menuVisible, setMenuVisible] = useState(false);

    if(!bundle || !me) return null;
    const items = [...bundle.sortedGames.map(x => ({...x, type: 'game'})), ...bundle.sortedPlaylists.map(x => ({...x, type: 'playlist'}))];

    const titleStyle = {
        fontSize: `${bundle.text.fontSize}px`,
        fontFamily: bundle.text.fontFamily,
        color: bundle.text.fontColorHex,
        textAlign: getTextAlign(bundle.text)
    };
    const topGradColor = convertHexToRgba(bundle.textBackgroundColorHex, 0.867);
    const botGradColor = convertHexToRgba(bundle.textBackgroundColorHex, 0);
    const labelStyle = {
        background: `${bundle.showTextBackground ? `linear-gradient(to bottom, ${topGradColor}, ${botGradColor}` : ''}`
    };

    const innerStyle = { transform: `scale(${scale})`, background: bundle.backgroundColorHex };

    return (
        <div className={`bundle-preview ${className || ''}${noMenu && !onClick ? '' : ' clickable'}`} onClick={onClick || (() => setMenuVisible(!menuVisible))} style={style}>
            <div className='icon' style={innerStyle}>
                {bundle.showText &&
                    <>
                        <div className='label' style={labelStyle} />
                        <div className='title' style={titleStyle} dir='auto'>{bundle.text.content}</div>
                    </>
                }
                {items.map(x => {
                    if (x.type === 'playlist') {
                        return <div className='bundle-wrapper' key={x.itemId}><PlayListPreview className='bundle-item' playlistId={x.itemId} onlyImage noMenu /></div>

                    }
                    if (x.type === 'game') {
                        return <div className='bundle-wrapper' key={x.itemId}><Game className='bundle-item' gameId={x.itemId} onlyImage noMenu /></div>
                    }
                    return null;
                })}
                {!noMenu &&
                    <div className={`icon-menu ${!menuVisible && 'hidden'}`}>
                        {
                            customMenu ? customMenu :
                                <CustomGameMenu
                                    items={
                                        [
                                            {
                                                key: 'edit',
                                                link: `/bundles/details/${bundle.id}`,
                                                icon: 'file-import',
                                                text: (bundle.ownerId === me.id || (bundle.authorIds && bundle.authorIds.find(x => x === me.id))) ? 'edit_content' : 'see_content'
                                            },
                                            bundle.isPublished ? {
                                                key: 'wall_add',
                                                action: () => globalActions.wallAddItemForm(bundle),
                                                icon: 'plus',
                                                text: 'wall_add_bundle'
                                            } : null
                                        ]
                                    }
                                />
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({me: state.me.account}),
    (dispatch) => ({globalActions: bindActionCreators(GlobalStore.actionCreators, dispatch)}) 
)(BundleIcon)