import {LanguageCode} from "../store/games/Games";

export const defaultLanguageList: LanguageCode[] =
    [
        {
            name: 'Afrikaans (Afrikaans)',
            value: 'af'
        },
        {
            name: 'Azərbaycan (Azerbaijani)',
            value: 'az'
        },
        {
            name: 'Bosanski (Bosnian)',
            value: 'bs'
        },
        {
            name: 'Català (Catalan)',
            value: 'ca'
        },
        {
            name: 'Čeština (Czech)',
            value: 'cs'
        },
        {
            name: 'Chishona (Shona)',
            value: 'sn'
        },
        {
            name: 'Cymraeg (Welsh)',
            value: 'cy'
        },
        {
            name: 'Dansk (Danish)',
            value: 'da'
        },
        {
            name: 'Deutsch (German)',
            value: 'de'
        },
        {
            name: 'Èdè Yorùbá (Yoruba)',
            value: 'yo'
        },
        {
            name: 'Eesti (Estonian)',
            value: 'et'
        },
        {
            name: 'English (English)',
            value: 'en'
        },
        {
            name: 'Español (Spanish)',
            value: 'es'
        },
        {
            name: 'Esperanto (Esperanto)',
            value: 'eo'
        },
        {
            name: 'Euskara (Basque)',
            value: 'eu'
        },
        {
            name: 'Français (French)',
            value: 'fr'
        },
        {
            name: 'Frysk (Western Frisian)',
            value: 'fy'
        },
        {
            name: 'Gaeilge (Irish)',
            value: 'ga'
        },
        {
            name: 'Gàidhlig (Scottish Gaelic)',
            value: 'gd'
        },
        {
            name: 'Galego (Galician)',
            value: 'gl'
        },
        {
            name: 'Hausa (Hausa)',
            value: 'ha'
        },
        {
            name: 'Hrvatski (Croatian)',
            value: 'hr'
        },
        {
            name: 'ʻōlelo Hawaiʻi (Hawaiian)',
            value: 'haw'
        },
        {
            name: 'Igbo (Igbo)',
            value: 'ig'
        },
        {
            name: 'Indonesia (Indonesian)',
            value: 'id'
        },
        {
            name: 'Isizulu (Zulu)',
            value: 'zu'
        },
        {
            name: 'Íslenska (Icelandic)',
            value: 'is'
        },
        {
            name: 'Italiano (Italian)',
            value: 'it'
        },
        {
            name: 'Kiswahili (Swahili)',
            value: 'sw'
        },
        {
            name: 'Latviešu (Latvian)',
            value: 'lv'
        },
        {
            name: 'Lëtzebuergesch (Luxembourgish)',
            value: 'lb'
        },
        {
            name: 'Lietuvių (Lithuanian)',
            value: 'lt'
        },
        {
            name: 'Magyar (Hungarian)',
            value: 'hu'
        },
        {
            name: 'Malagasy (Malagasy)',
            value: 'mg'
        },
        {
            name: 'Malti (Maltese)',
            value: 'mt'
        },
        {
            name: 'Melayu (Malay)',
            value: 'ms'
        },
        {
            name: 'Nederlands (Dutch)',
            value: 'nl'
        },
        {
            name: 'Norsk (Norwegian)',
            value: 'no'
        },
        {
            name: 'O‘Zbek (Uzbek)',
            value: 'uz'
        },
        {
            name: 'Polski (Polish)',
            value: 'pl'
        },
        {
            name: 'Português (Portuguese)',
            value: 'pt'
        },
        {
            name: 'Română (Romanian)',
            value: 'ro'
        },
        {
            name: 'Shqip (Albanian)',
            value: 'sq'
        },
        {
            name: 'Slovenčina (Slovak)',
            value: 'sk'
        },
        {
            name: 'Slovenščina (Slovenian)',
            value: 'sl'
        },
        {
            name: 'Soomaali (Somali)',
            value: 'so'
        },
        {
            name: 'Suomi (Finnish)',
            value: 'fi'
        },
        {
            name: 'Svenska (Swedish)',
            value: 'sv'
        },
        {
            name: 'Tiếng Việt (Vietnamese)',
            value: 'vi'
        },
        {
            name: 'Türkçe (Turkish)',
            value: 'tr'
        },
        {
            name: 'Ελληνικά (Greek)',
            value: 'el'
        },
        {
            name: 'Беларуская (Belarusian)',
            value: 'be'
        },
        {
            name: 'Български (Bulgarian)',
            value: 'bg'
        },
        {
            name: 'Кыргызча (Kyrgyz)',
            value: 'ky'
        },
        {
            name: 'Қазақ Тілі (Kazakh)',
            value: 'kk'
        },
        {
            name: 'Македонски (Macedonian)',
            value: 'mk'
        },
        {
            name: 'Монгол (Mongolian)',
            value: 'mn'
        },
        {
            name: 'Русский (Russian)',
            value: 'ru'
        },
        {
            name: 'Српски (Serbian)',
            value: 'sr'
        },
        {
            name: 'Тоҷикӣ (Tajik)',
            value: 'tg'
        },
        {
            name: 'Українська (Ukrainian)',
            value: 'uk'
        },
        {
            name: 'ქართული (Georgian)',
            value: 'ka'
        },
        {
            name: 'Հայերեն (Armenian)',
            value: 'hy'
        },
        {
            name: 'ייִדיש (Yiddish)',
            value: 'yi'
        },
        {
            name: 'עברית (Hebrew)',
            value: 'he'
        },
        {
            name: 'اردو (Urdu)',
            value: 'ur'
        },
        {
            name: 'العربية (Arabic)',
            value: 'ar'
        },
        {
            name: 'پښتو (Pashto)',
            value: 'ps'
        },
        {
            name: 'فارسی (Persian)',
            value: 'fa'
        },
        {
            name: 'አማርኛ (Amharic)',
            value: 'am'
        },
        {
            name: 'नेपाली (Nepali)',
            value: 'ne'
        },
        {
            name: 'मराठी (Marathi)',
            value: 'mr'
        },
        {
            name: 'हिन्दी (Hindi)',
            value: 'hi'
        },
        {
            name: 'বাংলা (Bangla)',
            value: 'bn'
        },
        {
            name: 'ਪੰਜਾਬੀ (Punjabi)',
            value: 'pa'
        },
        {
            name: 'ગુજરાતી (Gujarati)',
            value: 'gu'
        },
        {
            name: 'தமிழ் (Tamil)',
            value: 'ta'
        },
        {
            name: 'తెలుగు (Telugu)',
            value: 'te'
        },
        {
            name: 'ಕನ್ನಡ (Kannada)',
            value: 'kn'
        },
        {
            name: 'മലയാളം (Malayalam)',
            value: 'ml'
        },
        {
            name: 'සිංහල (Sinhala)',
            value: 'si'
        },
        {
            name: 'ไทย (Thai)',
            value: 'th'
        },
        {
            name: 'ລາວ (Lao)',
            value: 'lo'
        },
        {
            name: 'မြန်မာ (Burmese)',
            value: 'my'
        },
        {
            name: 'ខ្មែរ (Khmer)',
            value: 'km'
        },
        {
            name: '한국어 (Korean)',
            value: 'ko'
        },
        {
            name: '中文 (Chinese)',
            value: 'zh'
        },
        {
            name: '日本語 (Japanese)',
            value: 'ja'
        }
    ];