import React, {Component, MouseEvent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators, AuthState} from '../store/Auth';
import {Translate} from 'react-localize-redux';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import Dialog from './Dialog';
import RequestResetPassword from './forms/profile/RequestResetPassword';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import profile from '../images/missing-profile.png';
import {InputField, SubmitButton} from "./forms/FormGroup";
import {AppState, ChangeEvent} from "../store/configureStore";
import {MeState} from "../store/Me";
import * as queryString from "querystring";
import {ErrorState} from "../store/Error";

interface Props extends RouteComponentProps{
    authActions: typeof actionCreators;
    errors: ErrorState['auth'];
    auth: AuthState;
    me: MeState;
}
interface State{
    username: string;
    password: string;
}

class Login extends Component<Props,State> {
    returnUrl?: string | false;

    constructor(props: Props){
        super(props);
        this.returnUrl = '';
        this.state = {
            username: "",
            password: ""
        };

    }

    render() {
        const handleChange = (event: ChangeEvent<string>) => {
            if(event.target.name === 'username'){
                this.setState({ username : event.target.value || ''});
            }
            else if(event.target.name === 'password'){
                this.setState({ password : event.target.value || ''});
            }
        };

        const OnLogin = (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            this.props.authActions.requestToken(this.state.username, this.state.password);
        };

        const toggleRequestPassword= () =>{
            this.props.authActions.setRequestPassword(!this.props.auth.showRequestPassword);
        };

        const props = this.props;
        if(this.props.auth.activetoken){
            const params = queryString.parse(props.location.search.slice(1));
            this.returnUrl = params.returnUrl && typeof params.returnUrl === 'string' && params.returnUrl;
            if(this.returnUrl){
                return (<Redirect to={this.returnUrl} />);
            }
            else{
                return (<Redirect to="/" />);
            }
        }

        let loginError = null;
        if(props.errors.requestToken){

            if(props.errors.requestToken.status === 401){
                loginError = <div className='form-error'><Translate id='login_invalid' /></div>
            }
        }
        let resetPassword = null;
        if(props.auth.showRequestPassword){
            resetPassword = (
                <Dialog onClose={toggleRequestPassword} className='request-dialog'>
                    <h2><Translate id='forgot_password_title'/></h2>
                    <p><Translate id='forgot_password_text'/></p>
                    <RequestResetPassword />
                    {this.props.auth.waitingForRequestReset &&
                        <div className='loading-spinner'><FontAwesomeIcon icon='spinner' spin/></div>
                    }
                    {this.props.auth.resetRequested && 
                        <p><Translate id='forgot_password_requested'/></p>
                    }
                </Dialog>
            );
        }
        
        const profileSrc = this.props.me.lastUsedImage || profile;

        return (
            <div className='login-content'>
                <div className='login-form-container'>
                    <div className='profile'>
                        <img src={profileSrc} alt=''/>
                    </div>
                    <form className='login-form'>
                        <InputField
                            name='username'
                            errorField='UserName'
                            inputIcon='user'
                            type='email'
                            value={this.state.username}
                            onChange={handleChange}
                            placeholder='username'
                            errors={this.props.errors.requestToken}
                            noLabel
                        />
                        <InputField
                            name='password'
                            type='password'
                            value={this.state.password}
                            placeholder='password'
                            onChange={handleChange}
                            inputIcon='lock'
                            errors={this.props.errors.requestToken}
                            noLabel
                        />
                        {loginError}
                        <div className='forgot-password'>
                            <span onClick={toggleRequestPassword}><Translate id="forgot_password"/></span>
                        </div>
                        <div className='forgot-password'>
                            <a href='https://activefloor.com/da/2019/04/29/myfloor-upgrading/' target='_blank' rel='noopener noreferrer' >
                                <Translate id="missing_login"/>
                            </a>
                        </div>
                        <SubmitButton
                            text='Login'
                            onClick={OnLogin}
                            disabled={this.props.auth.waitingForToken}
                            className='btn-large'
                            split
                        />
                    </form>
                    {resetPassword}
                </div>
                <div className='login-form-container-bottom'>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: AppState) => ( { me: state.me, auth: state.auth, errors: state.errors.auth } ),
    dispatch => ({ authActions: bindActionCreators(actionCreators, dispatch) })
)(Login);