import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react'
import {Translate} from 'react-localize-redux';
import { FetchError } from '../../services/FetchHelper';
import {ErrorType} from '../../store/Error';

interface Props {
    error?: false | ErrorType;
    fetchError?: FetchError;
}

export const GeneralError = (props: Props) => {
    //Added fetch error after the fact, convert to known format is set..
    const { error: _error, fetchError } = props;
    const error: ErrorType | undefined = _error ? _error : fetchError ? { status: fetchError.status } : undefined; 

    if (!error) return null;
    if (!error.status) return null;

    let translateMsg = "unknown_error";

    if(error.status === 400){
        return null;
    }

    if(error.status === 401){
        translateMsg = 'unauthorized_error';
    }

    if(error.status === 404){
        translateMsg = 'notfound_error';
        return(
            <div className='general-error'><FontAwesomeIcon icon='exclamation' /><Translate id='notfound_error'/></div>
        )
    }

    if(error.status === 500){
        translateMsg = 'server_error';
    }

    return(
        <div className='form-error general-error'><FontAwesomeIcon icon='exclamation' /><Translate id={translateMsg}/></div>
    )
};