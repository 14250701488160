import React, { useEffect, useState } from 'react';
import { SmartAdd } from '../../model/SmartAdd';
import { fetchDelete, FetchError, fetchGet } from '../../services/FetchHelper';
import { Loading } from '../Loading';
import * as AuthStore from '../../store/Auth';
import { connect } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { Button } from '../forms/FormGroup';
import SmartAddForm from './SmartAddForm';
import { ConfirmationForm } from '../forms/games/ConfirmationForm';
import { copyToClipboard } from '../../services/CustomFunctions';
import * as MessageStore from '../../store/MessageStore';
import { bindActionCreators } from 'redux';
import PlayListPreview from '../ModelPreview/PlayListPreview';

interface Props {
    authState: AuthStore.AuthState;
    msgActions: typeof MessageStore.actionCreators;
}

const SmartAddAdmin = (props: Props) => {
    const [smartAdds, setSmartAdds] = useState<SmartAdd[]>();
    const [edit, setEdit] = useState<SmartAdd>();
    const [error, setError] = useState<FetchError>();
    const [confirmDelete, setConfirmDelete] = useState<string>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!smartAdds){
            setLoading(true);
            fetchGet<SmartAdd[]>("api/smartAdd")
                .then(setSmartAdds)
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [smartAdds]);

    const onDelete = (id: string) => {
        setLoading(true);
        setConfirmDelete(undefined);
        fetchDelete(`api/smartAdd/${id}`)
            .then(_ => deleteComplete(id))
            .catch(setError)
            .finally(() => setLoading(false));
    }

    const deleteComplete = (id: string) => {
        const rest = [...smartAdds || []].filter(x => x.id !== id);
        setSmartAdds(rest);
        setEdit(undefined)
    }

    const toClipboard = (msg: string) => {
        copyToClipboard(msg);
        props.msgActions.addMessage("pop_added", "pop_msg_clipboard_add");
    }

    return (
        <div className='smart-add-index'>
            <h2>Smart add - Elementer</h2>
            <Loading visible={loading}  />
            <Button name='new' icon='plus' onClick={() => setEdit({playlistId: "", row: 0})} />
            {error && <div className='form-error'>{error.status} {error.message}</div>}
            <div className='admin-table'>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Id</th>
                            <th>PlaylistId</th>
                            <th>Row</th>
                            <th>MailChimp - link</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {smartAdds && smartAdds.map(x => {
                            const mcLink = `${document.location.protocol}//${document.location.host}/api/smartadd/${x.id}/invoke?accountEmail=*|EMAIL|*`;
                            return (
                                <tr key={x.id}>
                                    <td>{x.playlist && <PlayListPreview playlist={x.playlist} noMenu onlyImage width={60} />}</td>
                                    <td>{x.id}</td>
                                    <td>{x.playlistId}</td>
                                    <td>{x.row === 0 ? 'Top' : x.row === 1 ? 'Mid' : 'Bot'}</td>
                                    <td onClick={() => toClipboard(mcLink)} >{mcLink}</td>
                                    <td><Button name={'show'} onClick={() => setEdit(x)} /> <Button name={'delete'} onClick={() => setConfirmDelete(x.id)} /></td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
            {edit && smartAdds && 
                <SmartAddForm 
                    item={edit} 
                    onClose={() => setEdit(undefined)} 
                    onSuccess={item => {
                        if(smartAdds.find(x => x.id === item.id)){
                            setSmartAdds(smartAdds.map(x => x.id === item.id ? item : x));
                        }
                        else{
                            setSmartAdds([...smartAdds, item]);
                        }
                        setEdit(undefined);
                    }}  
                /> 
            }
            {confirmDelete &&
                <ConfirmationForm
                    text={'delete'}
                    confirmText={'yes'}
                    cancelText={'no'}
                    onConfirm={() => onDelete(confirmDelete)}
                    onCancel={_ => setConfirmDelete(undefined)}
                />
            }
        </div>
    )
}

export default connect(
    (state: AppState) => ({ authState: state.auth }),
    (dispatch) => ({ msgActions: bindActionCreators(MessageStore.actionCreators, dispatch)}) 
)(SmartAddAdmin);